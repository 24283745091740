/**
 * @ngdoc controller
 * @name dwtModule.DwtDetailsHomeController
 * @description This is the parent controller for view event details, view claim
 *              details, view payment details and send to email to tax authority
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires $state for routing
 */
'use strict';

angular.module('dwtModule').controller('DwtDetailsHomeController', [
  '$scope',
  '$sessionStorage',
  '$state',
  '$stateParams',
  '$log',
  'userGroupService',
  'generalMeetingService',
  'ViewDwtDetailService',
  '$filter',
  function (
    $scope,
    $sessionStorage,
    $state,
    $stateParams,
    $log,
    userGroupService,
    generalMeetingService,
    ViewDwtDetailService,
    $filter
  ) {
    $scope.userType = $sessionStorage.usertype;
    $scope.eventId = $stateParams.eventId;
    $scope.taxAuthAgentFlag = $sessionStorage.isAdmin;
    $scope.taxReclaimIndexId = $sessionStorage.taxReclaimIndexId;
    $scope.eventName = $stateParams.eventName;
    $scope.eventStatus = $stateParams.eventStatus;
    $scope.flag = $stateParams.flag;
    $scope.sendMailToTaxAuthority = false;
    $scope.issuerId = $stateParams.issuerId;
    $scope.issuerName = $stateParams.issuerName;
    $scope.dwtPerSecurity = $stateParams.dwtPerSecurity;
    $scope.qrfEndDate = $stateParams.qrfEndDate;
    $scope.peEndDate = $stateParams.peEndDate;
    $scope.peFlag = $stateParams.peFlag;
    $scope.qrfFlag = $stateParams.qrfFlag;
    $scope.navFrom = $stateParams.navFrom;
    $scope.extReqFlag = $stateParams.extReqFlag;
    $scope.extensionReqFlagForQRF = $stateParams.extensionReqFlagForQRF;
    $scope.extensionReqFlagForPE = $stateParams.extensionReqFlagForPE;
    $scope.shareholderId = $stateParams.shareholderId;
    $scope.taSubmissionDate = $stateParams.taSubmissionDate;
    if ($scope.eventId) {
      ViewDwtDetailService.getDwtDetailsOverview(
        $scope.eventId,
        $sessionStorage.usertype,
        'EN'
      ).then(
        function (response) {
          if (response.data.requestPaymentDate) {
            var todaysDate = '';
            generalMeetingService
              .getCurrentCETTime()
              .query()
              .$promise.then(
                function (data) {
                  //$scope.todaysDate = data;
                  $scope.currentDateInGMTCET = data;
                  if (response.data.requestPaymentDate.timezoneId === 'CET') {
                    todaysDate = $filter('filter')(
                      $scope.currentDateInGMTCET,
                      { timezone: 'CET' },
                      true
                    );
                  } else if (
                    response.data.requestPaymentDate.timezoneId === 'GMT'
                  ) {
                    todaysDate = $filter('filter')(
                      $scope.currentDateInGMTCET,
                      { timezone: 'GMT' },
                      true
                    );
                  }
                  if (
                    todaysDate[0].currentMillis >=
                    response.data.requestPaymentDate.dateMillis
                  ) {
                    $scope.sendMailToTaxAuthority = true;
                  }
                },
                function (error) {
                  $log.debug(error);
                }
              );
          }
        },
        function (error) {
          $log.debug(error);
        }
      );
    }
    $scope.getIssuerLogo = function (issuerId) {
      if (issuerId) {
        userGroupService.getIssuerGroupLogo(issuerId).then(
          function (response) {
            $scope.issuerLogoPresent = false;
            $scope.issuerGroupDetails = response.data;
            // Do not change the below condition. It is for issuer logo display fix. REST response has ""
            if ($scope.issuerGroupDetails.logoBaseStr) {
              $scope.issuerLogoPresent = true;
            }
          },
          function (error) {
            $log.debug(error);
          }
        );
      }
    };
    $scope.getIssuerLogo($scope.issuerId);
    if (
      $scope.flag === 'claims' ||
      ($scope.userType === 'IS' && $scope.eventStatus === 'L')
    ) {
      $state.go('dwtDetails.dwtEventClaims');
    } else if ($scope.flag === 'authorize') {
      $state.go('dwtDetails.dwtToBeAuthorized');
    } else if ($scope.flag === 'sendEmail') {
      $state.go('dwtDetails.emailTaxAuthority');
    } else {
      $state.go('dwtDetails.dwtEventDetails');
    }

    $scope.$on('requestQRFPaymentSuccess', function () {
      $scope.sendMailToTaxAuthority = true;
    });
  },
]);
