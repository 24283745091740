/**
 * @ngdoc controller
 * @name dwtModule.SearchShareholderController
 * @description This controller is used for dwt module for searching share holders.
 * @requires $scope
 * @requires $filter
 * @requires searchShareholderService
 */
'use strict';

angular.module('dwtModule').controller('SearchShareholderController', [
  '$scope',
  '$log',
  '$sessionStorage',
  'searchShareholderService',
  'searchShareholderModal',
  'userGroupService',
  '$filter',
  '$state',
  'PaginationSvc',
  '$timeout',
  '$stateParams',
  'fileUploadSvc',
  'dwtFileDownloadSvc',
  'ModelWindowService',
  function (
    $scope,
    $log,
    $sessionStorage,
    searchShareholderService,
    searchShareholderModal,
    userGroupService,
    $filter,
    $state,
    PaginationSvc,
    $timeout,
    $stateParams,
    fileUploadSvc,
    dwtFileDownloadSvc,
    ModelWindowService
  ) {
    $scope.showshareholderDetails = false;
    $scope.downloadErrorConfig = {};
    $scope.otherFormToolTip =
      '<div>' +
      $filter('translate')('label_ortherFormtooltip1') +
      '</div>' +
      '<br>' +
      '<div>' +
      $filter('translate')('label_ortherFormtooltip3') +
      '</div>' +
      '<br>' +
      '<div>' +
      $filter('translate')('label_ortherFormtooltip2') +
      '</div>';
    $scope.failure = false;
    $scope.searchData = {};
    $scope.taxReclaimIndexId = $sessionStorage.taxReclaimIndexId;
    $scope.userType = $sessionStorage.usertype;
    $scope.userGroupID = $sessionStorage.groupId;
    userGroupService
      .getCountryList('EN')
      .query()
      .$promise.then(
        function (countries) {
          $scope.countryList = countries.countries;
        },
        function (error) {
          $scope.loading = false;
          return error;
        }
      );

    if ($scope.userType === 'AG') {
      searchShareholderService.getIntermediaryList().then(
        function (resp) {
          $scope.intermediaryList = resp.data;
        },
        function (error) {
          $scope.loading = false;
          return error;
        }
      );

      searchShareholderService
        .getIssuerList('DWT', $sessionStorage.userId)
        .then(
          function (resp) {
            $scope.issuerList = resp.data;
          },
          function (error) {
            $scope.loading = false;
            return error;
          }
        );
    }

    $scope.searchData = new searchShareholderModal();
    // function for pagination service
    $scope.setPage = function (page) {
      $scope.currentPage = page;
      $scope.pager = [];
      if (page < 1 || page > $scope.pager.totalPages) {
        return;
      }
      // get pager object from service
      $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
      $log.debug($scope.pager);
      // get current page of items
      $scope.shareholderDetails = $scope.dummyItems.slice(
        $scope.pager.startIndex,
        $scope.pager.endIndex + 1
      );
      $scope.beginIndex = $scope.pager.startIndex;
      $scope.pageNo = page;
    };

    $scope.successCallback = function (response) {
      $scope.message = response.data.message;
      $scope.loading = false;
      $scope.failure = false;
      $scope.success = true;
      if (undefined !== response.data) {
        $scope.showshareholderDetails = true;
        $scope.shareholderDetails = response.data.dwtShareholderVOList;
        // data backup for go back button from
        $sessionStorage.masterShareholderDetails =
          response.data.dwtShareholderVOList;
        $scope.dummyItems = $scope.shareholderDetails;
        $scope.pager = {};
        $scope.setPage(1);
      }
    };
    $scope.errorCallback = function (error) {
      $scope.loading = false;
      $scope.failure = true;
      $scope.errorMessage = error.data.errors[0].errorMessage;
    };

    $scope.searchShareholder = function (searchShWithInvalid6166, onload) {
      $scope.searchData.searchShareholderWithoutValid6166 = searchShWithInvalid6166;
      $scope.searchShWithInvalid6166 = searchShWithInvalid6166;
      if ($scope.searchData.issuer) {
        $scope.searchData.issuerId = $scope.searchData.issuer.issuerId;
      } else if ($scope.userType === 'IS') {
        $scope.searchData.issuerId = $scope.userGroupID;
      }
      if ($scope.searchData.intermediary) {
        $scope.searchData.intermediaryId =
          $scope.searchData.intermediary.intermediaryId;
      } else if ($scope.userType === 'IN') {
        $scope.searchData.intermediaryId = $scope.userGroupID;
      }
      //onload call the search share holder service for issuer login.
      if ($scope.userType === 'IS' && onload === 'Y') {
        $scope.selectCategory = false;
        $scope.searchData.searchShareholder(
          $scope.successCallback,
          $scope.errorCallback
        );
        $scope.loading = true;
      }
      //check for form validity and required fields.
      if (
        $scope.searchData.searchShareholderWithoutValid6166 !== 'Y' &&
        (!onload || onload === 'N') &&
        !$scope.searchData.country &&
        !$scope.searchData.issuer &&
        !$scope.searchData.intermediary &&
        !$scope.searchData.shareHolderName &&
        !$scope.searchData.city &&
        !$scope.searchData.tin &&
        !$scope.searchData.shareHolderSecurityAccNo
      ) {
        $scope.selectCategory = true;
        $timeout(function () {
          $scope.selectCategory = false;
        }, 2500);
      } else if (onload === 'N') {
        $scope.selectCategory = false;
        $scope.searchData.searchShareholder(
          $scope.successCallback,
          $scope.errorCallback
        );
        $scope.loading = true;
      }
    };
    // call for on load, when issuer logs in
    if ($scope.userType === 'IS') {
      $scope.searchShareholder('N', 'Y');
    }

    // to handle go back navigation from detail screen
    if ($stateParams.navigatedFrom === 'goBack') {
      $scope.shareholderDetails = $sessionStorage.masterShareholderDetails;
      $scope.dummyItems = $scope.shareholderDetails;
      $scope.showshareholderDetails = true;
      $scope.pager = {};
      $scope.setPage(1);
    }

    $scope.resetShareholder = function () {
      $scope.selectCategory = false;
      $scope.showshareholderDetails = false;
      $scope.searchData.country = null;
      $scope.searchData.issuer = null;
      $scope.searchData.intermediary = null;
      $scope.searchData.issuerId = null;
      $scope.searchData.intermediaryId = null;
      $scope.searchData.shareHolderName = '';
      $scope.searchData.city = '';
      $scope.searchData.tin = '';
      $scope.searchData.shareHolderSecurityAccNo = '';
      $scope.serachShareHolderForm.$setPristine();
    };

    //upload 6166 and IB92/96 forms
    $scope.addEditClaimForms = function (e, qrfClaim, formType) {
      $scope.fileList = e.files;
      if (
        'application/pdf' !== $scope.fileList[0].type &&
        'application/softgrid-pdf' !== $scope.fileList[0].type
      ) {
        qrfClaim.error = true;
        $timeout(function () {
          qrfClaim.error = false;
        }, 5000);
      } else if ($scope.fileList[0].name.length > 50) {
        qrfClaim.fileNameError = true;
        $timeout(function () {
          qrfClaim.fileNameError = false;
        }, 5000);
      } else {
        var startDate = new Date();
        var endDate = new Date(startDate.getFullYear(), 11, 31);
        var documentFormData = new FormData();
        documentFormData.append('formType', formType);
        documentFormData.append('File', $scope.fileList[0]);
        documentFormData.append('loginUserId', $sessionStorage.userId);
        documentFormData.append('eventId', qrfClaim.eventId);
        if (formType === 'FT_6166') {
          documentFormData.append('shareholderId', qrfClaim.shareHolderId);
          documentFormData.append(
            'startDate',
            $filter('date')(startDate, 'yyyy-MM-dd HH:mm:ss')
          );
          documentFormData.append(
            'endDate',
            $filter('date')(endDate, 'yyyy-MM-dd HH:mm:ss')
          );
        }
        var file = $scope.fileList;
        var uploadUrl = 'v1/claimforms/upload';
        fileUploadSvc.uploadFileToUrl(file, uploadUrl, documentFormData).then(
          function (data) {
            $scope.response = data.data.message;
            if (data.status != 200 && data.status != 201 && data.data.errors) {
              $scope.loading = false;
              ModelWindowService.showModelWindow('downloadError');
              $scope.downloadErrorConfig.modalHeader = 'Unable to upload !';
              $scope.downloadErrorConfig.modalBody =
                data.data.errors[0].errorMessage;
            } else {
              $('#successMsg').modal('show');
              $timeout(function () {
                $scope.searchShareholder($scope.searchShWithInvalid6166, 'N');
                $('#successMsg').modal('hide');
              }, 2000);
            }
          },
          function (error) {
            $log.debug(error);
          }
        );
      }
    };
    //function to download 6166Form
    $scope.downloadClaimForms = function (formType, formId) {
      var downloadUrl =
        'v1/claimforms/' + formId + '/' + formType + '/download';
      var a = document.createElement('a');
      document.body.appendChild(a);
      dwtFileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            var fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          downloadUrl = '';
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
  },
]);
