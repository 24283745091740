/**
 * @ngdoc controller
 * @name userMaintenanceModule.IssuerGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the list of issuer groups
 * @requires userGroupService is the service which is being used to interact with REST
 * @requires $scope for storing/passing information in scope
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter the group name and to translate messages from locale file
 * @requires PaginationSvc for pagination
 */

angular
  .module('userMaintenanceDwtModule')
  .controller('IssuerGroupDwtController', [
    '$state',
    '$scope',
    '$log',
    '$filter',
    'userGroupService',
    '$sessionStorage',
    'PaginationSvc',
    function (
      $state,
      $scope,
      $log,
      $filter,
      userGroupService,
      $sessionStorage,
      PaginationSvc
    ) {
      $scope.userGroup = 'issuer group';
      $scope.userType = 'IS';
      var currentPage = 1;
      //$scope.loading is used for spinner
      $scope.loading = true;
      $scope.prodType = $sessionStorage.productType;
      //call getIssuerGroup from userGroupService to fetch issuer groups
      $scope.getIssuerGroup = function () {
        userGroupService
          .getIssuerGroup($scope.prodType, 'EN')
          .query()
          .$promise.then(
            function (data) {
              data.sort((a, b) =>
                b.modifiedDatetime > a.modifiedDatetime ? 1 : -1
              );
              $scope.groupData = data;
              $scope.dummyItems = data;
              $scope.pager = {};
              $scope.setPage(currentPage);
              $scope.loading = false;
            },
            function (error) {
              $scope.loading = false;
              return error;
            }
          );
      };
      //Below function is to search the groups related to entry in that search box
      $scope.onGroupNameChange = function (val) {
        $scope.dummyItems = $filter('filter')($scope.groupData, {
          groupName: val,
        });
        $scope.pager = {};
        $scope.setPage(1);
      };
      //Below function is for pagination, it calls from HTML on clicking of each page
      $scope.setPage = function (page) {
        currentPage = page;
        $scope.pager = [];
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }

        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
        $log.debug($scope.pager);

        // get current page of items
        $scope.viewUserGroup = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
      };
      //To route to add issuer group screen
      $scope.addUserGrp = function () {
        $state.go('userMaintenanceForDWT.addIssuerGroupDwt');
      };
      //Below is function call from HTML for modal window.
      $scope.deleteModal = function (groupId, issuerName, status, userGroup) {
        if (status === 'Enabled') {
          $scope.statusAction = 'disable';
        } else if (status === 'Disabled') {
          $scope.statusAction = 'enable';
        }
        $scope.modalHeader =
          $filter('translate')('label_confirm') +
          ' ' +
          $scope.statusAction +
          ' ' +
          userGroup +
          '!';
        $scope.modalBody =
          $filter('translate')('label_areYouSureModal') +
          $scope.statusAction +
          $filter('translate')('label_thisGroup');
        $scope.modalFooter = '';
        $scope.modalLeftButton = $filter('translate')('label_confirm');
        $scope.modalRightButton = $filter('translate')('label_cancel');
        $scope.groupId = groupId;
        $scope.issuerName = issuerName;
        $scope.status = status;
      };
      //Below is the function call to enable issuer group.
      $scope.enableIssuerUserGroup = function (groupId) {
        $log.debug('enable issuer group');
        userGroupService.enableIssuerGroup(
          groupId,
          $scope.prodType,
          function (response) {
            $scope.userGroup = 'issuer group';
            $scope.success = true;
            $scope.message = response.message;
            $scope.getIssuerGroup();
          },
          function (error) {
            $scope.failure = true;
            $scope.errorMessage = error.errors[0].errorMessage;
          }
        );
      };
      //Below is the function call to disable issuer group.
      $scope.disableIssuerUserGroup = function (groupId) {
        $log.debug('disable issuer group');
        userGroupService.disableIssuerGroup(
          groupId,
          $scope.prodType,
          function (response) {
            $scope.userGroup = 'issuer group';
            $scope.success = true;
            $scope.message = response.message;
            $scope.getIssuerGroup();
          },
          function (error) {
            $scope.failure = true;
            $scope.errorMessage = error.errors[0].errorMessage;
          }
        );
      };
      // call the function for first time
      $scope.getIssuerGroup();
    },
  ]);
