/**
 * @ngdoc controller
 * @name userMaintenanceModule.EditUserController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with edit user details.
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $timeout
 * @requires $uibModal
 */

angular.module('userMaintenanceModule').controller('EditUserController', [
  '$scope',
  '$log',
  '$state',
  '$stateParams',
  'userService',
  '$timeout',
  '$uibModal',
  '$filter',
  '$sessionStorage',
  function (
    $scope,
    $log,
    $state,
    $stateParams,
    userService,
    $timeout,
    $uibModal,
    $filter,
    $sessionStorage
  ) {
    $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

    $scope.modalBody = $filter('translate')('confirmUser_editDetails');
    $scope.modalLeftButton = $filter('translate')('label_confirm');
    $scope.modalRightButton = $filter('translate')('label_cancel');
    $scope.userId = $stateParams.userId;
    $scope.userGroupId = $stateParams.groupId;
    var productCode = 'GM';
    if ($stateParams.userType === 'IP') {
      $scope.userType = 'IN';
    } else {
      $scope.userType = $stateParams.userType;
    }
    // Below is the service call to interact with REST and get
    // the user details
    userService
      .getViewUser(
        $scope.userId,
        $scope.userType,
        $scope.userGroupId,
        productCode
      )
      .query()
      .$promise.then(
        function (data) {
          $scope.editUserDetails = data;
          if (
            !$scope.editUserDetails.groupMail ||
            $scope.editUserDetails.groupMail === undefined ||
            $scope.editUserDetails.groupMail === 'N'
          ) {
            $scope.blockEmail = 'N';
          } else {
            $scope.blockEmail = 'Y';
          }
          if (
            !$scope.editUserDetails.admin ||
            $scope.editUserDetails.admin === undefined ||
            $scope.editUserDetails.admin === 'N'
          ) {
            $scope.admin = 'N';
          } else {
            $scope.admin = 'Y';
          }
        },
        function (error) {
          $log.debug(error);
        }
      );

    // The below function call is to store the data from UI
    // and pass it to REST call for updating user
    $scope.updateUser = function () {
      var updateUserData = {
        title: $scope.editUserDetails.title,
        firstName: $scope.editUserDetails.firstName,
        lastName: $scope.editUserDetails.lastName,
        personalPhone: $scope.editUserDetails.personalPhoneNo,
        workPhone: $scope.editUserDetails.officePhoneNo,
        emailAddress: $scope.editUserDetails.email,
        isAdmin: $scope.admin,
        blockGroupEmail: $scope.blockEmail,
        userType: $scope.userType,
        userGroupId: $scope.userGroupId,
        prodType: productCode,
        defaultLanguage: 'EN',
        status: $scope.editUserDetails.status,
        userId: $scope.userId,
        loggedInUserId: $sessionStorage.userId,
      };
      // initializing the stateUrl
      switch ($stateParams.userType) {
        case 'AG':
          $scope.stateURL = 'userMaintenance.agentUserList';
          break;
        case 'IS':
          $scope.stateURL = 'userMaintenance.issuerUserList';
          break;
        case 'IN':
          $scope.stateURL = 'userMaintenance.intermediaryUserList';
          break;
        case 'IP':
          $scope.stateURL = 'userMaintenance.proxyIntermediaryUserList';
          break;
        case 'VC':
          $scope.stateURL = 'userMaintenance.vcUserList';
          break;
        default:
          break;
      }
      // The editUser in userService calls the REST url for
      // updating user details
      userService.editUser(
        updateUserData.userId,
        updateUserData,
        'secureToken',
        function (response) {
          if (
            200 === response.httpStatusCode ||
            201 === response.httpStatusCode
          ) {
            $scope.editUserSuccessMsg = 'User account updated successfully!';
            $scope.timedout = $uibModal.open({
              templateUrl: 'success-dialog.html',
            });
            $timeout(function () {
              $scope.timedout.close();
              $scope.timedout = null;
              $state.go($scope.stateURL, {
                groupId: $scope.userGroupId,
                status: $stateParams.status,
                productCode: productCode,
                userType: $stateParams.userType,
                page: $stateParams.page,
                redirectedFrom: $stateParams.redirectedFrom,
                breadCrumbDetails: [$stateParams.breadCrumbDetails[0]],
              });
            }, 2000);
          } else {
            $scope.editUserError = true;
            if (response.message) {
              $scope.editUserErrorMsg = response.message;
            } else {
              $scope.editUserErrorMsg = response.data.errors[0].errorMessage;
            }
          }
        },
        function (error) {
          $scope.editUserError = true;
          if (error.message) {
            $scope.editUserErrorMsg = error.message;
          } else {
            $scope.editUserErrorMsg = error.data.errors[0].errorMessage;
          }
        }
      );
    };
  },
]);
