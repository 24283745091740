import { formatDateTime } from '@/lib/date';

/**
 * @ngDoc controller
 * @name shareHolderModule.VaRequestStep1Controller
 * @description This controller is used to register step1 details for Virtual Attendance Request
 * @requires
 */
angular.module('shareHolderModule').controller('VaRequestStep3Controller', [
  '$scope',
  '$stateParams',
  '$state',
  'VaRequestStateService',
  'shareHolderService',
  '$sessionStorage',
  '$interval',
  function (
    $scope,
    $stateParams,
    $state,
    vaRequestStateService,
    shareHolderService,
    $sessionStorage,
    $interval
  ) {
    $scope.formatDate = formatDateTime;

    init();

    var formValues = '';
    formValues = vaRequestStateService.getAllFormData();
    var meetingData = vaRequestStateService.getMeetingData();
    var virtualReqId = meetingData.virtualReqId;
    var requestStatus = meetingData.requestStatus;
    var requestObj = {};

    $scope.countdown = 0;

    let stop;
    $scope.startCounter = function () {
      $scope.countdown = 15;
      // Don't start a new startCounter if we are already started
      if (angular.isDefined(stop)) {
        return;
      }

      stop = $interval(function () {
        if ($scope.countdown > 0) {
          $scope.countdown = $scope.countdown - 1;
        } else {
          $scope.stopCounter();
        }
      }, 1000);
    };

    $scope.resetCounter = function () {
      $scope.countdown = 15;
    };

    $scope.stopCounter = function () {
      if (angular.isDefined(stop)) {
        $interval.cancel(stop);
        $scope.countdown = 0;
        stop = undefined;
      }
    };

    $scope.$on('$destroy', function () {
      // Make sure that the interval is destroyed too
      $scope.stopCounter();
    });

    $scope.save = function () {
      $scope.submitted = true;

      requestObj = {
        loginUserId: $sessionStorage.userId,
        meetingId: formValues.vaRequestStep1.meetingId,
        shareClassId: formValues.vaRequestStep1.shareClassId,
        shareholderId: $sessionStorage.groupId,
        intermediaryId: formValues.vaRequestStep1.intermediaryId,
        securityAccountNumber: formValues.vaRequestStep1.securityAccountNumber,
        phoneNumber: formValues.vaRequestStep2.phoneNumber,
        phoneCountryCode: formValues.vaRequestStep2.phoneCountryCode.dialCode,
        issuerId: formValues.vaRequestStep1.issuerId,
        noOfSecurities: formValues.vaRequestStep1.numberOfShares,
        langCode: $sessionStorage.selectedLang,
        otpRef: formValues.vaRequestStep2.otpRef,
        otpCode: $scope.otp,
        virtualAttendanceRequestId: virtualReqId,
      };
      if (requestStatus === 'edit') {
        editRequest();
      } else {
        registerRequest();
      }
    };

    var registerRequest = function () {
      shareHolderService.registerVirtualParticipationRequest(requestObj).then(
        function (response) {
          // re-enable submit button after the response has arrived
          $scope.submitted = false;
          $state.go('vaRequestConfirmation', {
            selectedIndex: 1,
            intermediaryEmail: response.data.intermediaryEmail,
            intermediaryName: response.data.intermediaryName,
            intermediaryPhone: response.data.intermediaryPhone,
          });
        },
        function (error) {
          $scope.submitted = false;
          $scope.otpSent = false;
          if (error.data.errors) {
            $scope.errorCode = error.data.errors[0].errorCode;
            $scope.errorMsg = error.data.errors[0].errorMessage;
          } else {
            $scope.errorMsg = error.errorMessage;
          }
        }
      );
    };
    var editRequest = function () {
      shareHolderService.updateVirtualParticipationRequest(requestObj).then(
        function (response) {
          // re-enable submit button after the response has arrived
          $scope.submitted = false;
          $state.go('vaRequestConfirmation', {
            selectedIndex: 0,
            intermediaryEmail: response.data.intermediaryEmail,
            intermediaryName: response.data.intermediaryName,
            intermediaryPhone: response.data.intermediaryPhone,
          });
        },
        function (error) {
          if (error.data.errors) {
            $scope.errorCode = error.data.errors[0].errorCode;
            $scope.errorMsg = error.data.errors[0].errorMessage;
          } else {
            $scope.errorMsg = error.errorMessage;
          }
        }
      );
    };

    //Below function will be called on click of send again link
    $scope.resendOtp = function () {
      var resendOtpObj = {
        meetingId: formValues.vaRequestStep1.meetingId,
        shareholderId: $sessionStorage.groupId,
        loginUserId: $sessionStorage.userId,
        phoneNumber: formValues.vaRequestStep2.phoneNumber,
        phoneCountryCode: parseInt(
          formValues.vaRequestStep2.phoneCountryCode.dialCode,
          10
        ),
      };
      $scope.resending = true;
      $scope.otpSent = false;
      $scope.startCounter();
      shareHolderService.resendOtp(resendOtpObj).then(
        function (response) {
          vaRequestStateService.setFormData('vaRequestStep2', {
            phoneCountryCode: { dialCode: response.data.phoneCountryCode },
            phoneNumber: response.data.phoneNumber,
            otpRef: response.data.otpRef,
          });
          $scope.resending = false;
          $scope.otpSent = true;
          $scope.errorCode = '';
          $scope.errorMessage = '';
        },
        function (error) {
          $scope.resending = false;
          if (error.data.errors) {
            $scope.errorCode = error.data.errors[0].errorCode;
            $scope.errorMsg = error.data.errors[0].errorMessage;
          } else {
            $scope.errorMsg = error.errorMessage;
          }
        }
      );
    };

    // Below function is to navigate my events or meeting page
    $scope.cancel = function () {
      if (requestStatus === 'edit') {
        $state.go('shMyVirtualParticipation', {
          selectedIndex: 0,
        });
      } else {
        if ($sessionStorage.usertype === 'SP') {
          $state.go('gmList', {
            selectedIndex: 0,
          });
        } else {
          $state.go('shGeneralMeetings', {
            selectedIndex: 1,
          });
        }
      }
    };

    function init() {
      let meetingData = vaRequestStateService.getMeetingData();

      $scope.meetingDate = meetingData.gmDateMillis;
      $scope.meetingTime = meetingData.meetingTime;
      $scope.timezoneStr = meetingData.timezoneStr;
      $scope.meetingDateTime = meetingData.meetingDateTime;
      $scope.issuerGroupDetails = meetingData.issuerDetails;

      $scope.submitted = false;

      // TODO call service to prefill phone number
    }
  },
]);
