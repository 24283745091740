/**
 * @ngdoc controller
 * @name userMaintenanceModule.AddUserController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with adding new user.
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 */

angular.module('userMaintenanceDwtModule').controller('AddAgentDwtController', [
  '$scope',
  '$log',
  '$state',
  '$stateParams',
  '$sessionStorage',
  '$timeout',
  'DwtUser',
  function (
    $scope,
    $log,
    $state,
    $stateParams,
    $sessionStorage,
    $timeout,
    DwtUser
  ) {
    $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

    $scope.user = 'agent';
    $scope.prodType = $sessionStorage.productType;
    $scope.addUserDetails = new DwtUser(
      undefined,
      $stateParams.userType,
      $stateParams.groupId,
      $sessionStorage.userId,
      $scope.prodType
    );
    $scope.successCallback = function (response) {
      if (201 === response.data.httpStatusCode) {
        $scope.success = true;
        $scope.message = response.data.message;
        $('#successMsg').modal('show');
        $timeout(function () {
          $('#successMsg').modal('hide');
          $('.modal-backdrop').remove();
          $state.go('userMaintenanceForDWT.agentUserListDwt', {
            groupId: '1',
          });
        }, 2000);
      } else {
        $scope.failure = true;
        $scope.errorMessage = response.data.message;
      }
    };
    $scope.errorCallback = function (error) {
      $scope.failure = true;
      $scope.errorMessage = error.data.errors[0].errorMessage;
    };
  },
]);
