/**
 * @ngdoc controller
 * @name userMaintenanceModule.EditIntermediaryGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with editing details of particular intermediary group
 * @requires userGroupService and fileUploadSvc are the service which are being
 *           used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter country and translate message from locale file
 * @requires $sessionStorage to get logged in userId from session storage
 */

angular
  .module('userMaintenanceDwtModule')
  .controller('EditIntermediaryGroupDwtController', [
    '$scope',
    '$log',
    '$stateParams',
    '$state',
    'userGroupService',
    '$sessionStorage',
    '$filter',
    '$timeout',
    'DwtIntermediaryGroup',
    function (
      $scope,
      $log,
      $stateParams,
      $state,
      userGroupService,
      $sessionStorage,
      $filter,
      $timeout,
      DwtIntermediaryGroup
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.intermediaryId = $stateParams.groupId;
      $scope.userGroupName = 'intermediary';
      $scope.prodType = $sessionStorage.productType;
      userGroupService
        .getViewIntermediaryGroup($scope.intermediaryId, 'DWT', 'EN')
        .query()
        .$promise.then(
          function (data) {
            $scope.userGroupDetails = new DwtIntermediaryGroup(
              data,
              $sessionStorage.userId
            );
          },
          function (error) {
            $log.debug(error);
          }
        );

      /* date picker */
      $scope.openStartDate = function () {
        $scope.startDate.opened = true;
      };
      $scope.openEndDate = function () {
        $scope.endDate.opened = true;
      };

      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };

      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];

      $scope.startDate = {
        opened: false,
      };
      $scope.endDate = {
        opened: false,
      };
      /* date picker */
      $scope.ibanClass = 'validation';
      $scope.successCallback = function (data) {
        if (data.status === 200) {
          $scope.success = true;
          $scope.errorMsg = null;
          $scope.successMsg = data.data.message;
          $('#successMsg').modal('show');
        } else {
          $scope.successMsg = null;
          $scope.error = true;
          $scope.errorMsg = data.data.errors[0].errorMessage;
          if ('CBP_ERR_490' === data.data.errors[0].errorCode) {
            $scope.ibanClass = 'validation ng-invalid ng-dirty';
          }
        }
      };
      $scope.callAddUser = function () {
        $state.go('userMaintenanceForDWT.addIntermediaryDwt', {
          userGroupId: $scope.intermediaryId,
        });
      };
      $scope.callUserList = function () {
        $state.go('userMaintenanceForDWT.intermediaryGroupDwt', {});
      };
      $scope.errorCallback = function (error) {
        $scope.error = true;
        $scope.errorMsg = error.data.errors[0].errorMessage;
      };
      $scope.removeRegForm = function () {
        $scope.showAddReg = false;
        $scope.logoFile = undefined;
        $scope.userGroupDetails.startDate = '';
        $scope.userGroupDetails.endDate = '';
      };
      // The below function call is to store the data from UI
      // and
      // pass it to REST call for updating user

      $scope.updateUserGroup = function () {
        var userId = $sessionStorage.userId;
        var uploadUrl = 'v1/intermediaries/' + $scope.intermediaryId;
        $scope.error = false;
        if (
          ($scope.logoFile === null || $scope.logoFile === undefined) &&
          ($scope.agreeFile === null || $scope.agreeFile === undefined)
        ) {
          $scope.userGroupDetails.updateGroup(
            null,
            null,
            uploadUrl,
            userId,
            $scope.prodType,
            $scope.intermediaryId,
            $scope.successCallback,
            $scope.errorCallback
          );
        } else if (
          $scope.logoFile !== null &&
          $scope.logoFile !== undefined &&
          ($scope.agreeFile === null || $scope.agreeFile === undefined)
        ) {
          if ($scope.logoFile !== null && $scope.logoFile !== undefined) {
            if (
              'image' !== $scope.logoFile.type.substring(0, 5) ||
              $scope.logoFile.size > 256000
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('imgfileSize_Error');
            } else if ($scope.errorCallback.error == true) {
              $scope.error = true;
              $scope.errorMsg = $scope.errorCallback.errorMsg;
            } else {
              $scope.error = false;
              $scope.userGroupDetails.updateGroup(
                $scope.logoFile,
                null,
                uploadUrl,
                userId,
                $scope.prodType,
                $scope.intermediaryId,
                $scope.successCallback,
                $scope.errorCallback
              );
            }
          }
        } else if (
          ($scope.logoFile === null || $scope.logoFile === undefined) &&
          $scope.agreeFile !== null &&
          $scope.agreeFile !== undefined
        ) {
          if (
            'Invalid Date' === $scope.userGroupDetails.startDate ||
            'Invalid Date' === $scope.userGroupDetails.endDate ||
            '' === $scope.userGroupDetails.startDate ||
            '' === $scope.userGroupDetails.endDate ||
            null === $scope.userGroupDetails.startDate ||
            null === $scope.userGroupDetails.endDate
          ) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('selectDate_error');
          } else {
            if (
              'pdf' !== $scope.agreeFile.type.slice(-3) ||
              $scope.agreeFile.size > 1000000
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('fileSize_1mbError');
            } else {
              if (
                new Date().getTime() > $scope.userGroupDetails.endDate.getTime()
              ) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')(
                  'label_error_agrPastDate'
                );
              } else if (
                $scope.userGroupDetails.startDate.getTime() >
                $scope.userGroupDetails.endDate.getTime()
              ) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')(
                  'label_error_agrDateRange'
                );
              } else {
                $scope.error = false;
                $scope.userGroupDetails.updateGroup(
                  null,
                  $scope.agreeFile,
                  uploadUrl,
                  userId,
                  $scope.prodType,
                  $scope.intermediaryId,
                  $scope.successCallback,
                  $scope.errorCallback
                );
              }
            }
          }
        } else if (
          $scope.logoFile !== null &&
          $scope.logoFile !== undefined &&
          $scope.agreeFile !== null &&
          $scope.agreeFile !== undefined
        ) {
          if (
            'image' !== $scope.logoFile.type.substring(0, 5) ||
            $scope.logoFile.size > 256000
          ) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('imgfileSize_Error');
          } else {
            if (
              'Invalid Date' === $scope.userGroupDetails.startDate ||
              'Invalid Date' === $scope.userGroupDetails.endDate ||
              '' === $scope.userGroupDetails.startDate ||
              '' === $scope.userGroupDetails.endDate ||
              null === $scope.userGroupDetails.startDate ||
              null === $scope.userGroupDetails.endDate
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('selectDate_error');
            } else {
              if (
                'pdf' !== $scope.agreeFile.type.slice(-3) ||
                $scope.agreeFile.size > 1000000
              ) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')('fileSize_1mbError');
              } else {
                if (
                  new Date().getTime() >
                  $scope.userGroupDetails.endDate.getTime()
                ) {
                  $scope.error = true;
                  $scope.errorMsg = $filter('translate')(
                    'label_error_agrPastDate'
                  );
                } else if (
                  $scope.userGroupDetails.startDate.getTime() >
                  $scope.userGroupDetails.endDate.getTime()
                ) {
                  $scope.error = true;
                  $scope.errorMsg = $filter('translate')(
                    'label_error_agrDateRange'
                  );
                } else {
                  $scope.error = false;
                  $scope.userGroupDetails.updateGroup(
                    $scope.logoFile,
                    $scope.agreeFile,
                    uploadUrl,
                    userId,
                    $scope.prodType,
                    $scope.intermediaryId,
                    $scope.successCallback,
                    $scope.errorCallback
                  );
                }
              }
            }
          }
        }
      };
    },
  ]);
