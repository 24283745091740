/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.ExtController
 * @description This controller is used for extension request functionality.
 * @requires userGroupService,AgentService is the service which is being used to interact with REST
 * @requires $scope
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is being fetched be service
 * @requires $state for state routing
 * @requires $stateParams for storing/passing information in scope/state
 * @requires $filter to translate messages which are declared in locale files
 * @requires PaginationSvc for pagination
 */
'use strict';

angular
  .module('generalMeetingHomeModule')
  .controller('ViewIssuerExtController', [
    '$scope',
    '$sessionStorage',
    '$stateParams',
    'AgentService',
    'userGroupService',
    '$filter',
    '$log',
    '$state',
    'PaginationSvc',
    'ModelWindowService',
    '$timeout',
    function (
      $scope,
      $sessionStorage,
      $stateParams,
      AgentService,
      userGroupService,
      $filter,
      $log,
      $state,
      PaginationSvc,
      ModelWindowService,
      $timeout
    ) {
      $scope.extensionRequests = '';
      $scope.issuerId = $sessionStorage.groupId;
      $scope.generalMeetingName = $stateParams.gmName;
      $scope.generalMeetingDate = $stateParams.gmDateTime;
      $scope.userType = $sessionStorage.userType;
      $scope.generalMeetingId = $stateParams.generalMeetingId;
      $scope.loading = true;
      $scope.gmStatus = $stateParams.gmStatus;
      $scope.hstep = 1;
      $scope.mstep = 1;
      $scope.ismeridian = false;
      var pageNo;
      var count = null;
      $scope.fiveHours = 18000000;
      $scope.mytime = new Date();
      $scope.mytimeList = {};
      $scope.newEndDateList = {};
      //for status buttons
      if ($sessionStorage.selectedLang === 'nl') {
        $scope.statusButtons = ['nl_Pending', 'nl_Accepted', 'nl_Rejected'];
      } else {
        $scope.statusButtons = ['Pending', 'Accepted', 'Rejected'];
      }

      $scope.extnStatusButton = ['P', 'A', 'R'];

      // Below is the function used to fetch issuer logo
      userGroupService.getIssuerGroupLogo($scope.issuerId).then(
        function (response) {
          $scope.issuerLogoDetails = response.data;
        },
        function (error) {
          $log.debug(error);
        }
      );

      // Below is the method to hide or show accordian
      $scope.showHideDetails = function (extnReq) {
        extnReq.showDetails = !extnReq.showDetails;
      };

      $scope.getExtensionRequests = function () {
        //to fetch the extension request list
        pageNo = 1;
        AgentService.getAllExtensionRequestForIssuerGM(
          pageNo,
          $scope.generalMeetingId,
          $scope.issuerId
        )
          .query()
          .$promise.then(
            function (data) {
              $scope.extensionRequestsList = data;
              $scope.extensionRequests = $filter('filter')(
                $scope.extensionRequestsList,
                {
                  status: $scope.extnStatusButton[0],
                }
              );
              $scope.extReqCount_0 = $filter('filter')(
                $scope.extensionRequestsList,
                {
                  status: $scope.extnStatusButton[0],
                }
              ).length;
              $scope.extReqCount_1 = $filter('filter')(
                $scope.extensionRequestsList,
                {
                  status: $scope.extnStatusButton[1],
                }
              ).length;
              $scope.extReqCount_2 = $filter('filter')(
                $scope.extensionRequestsList,
                {
                  status: $scope.extnStatusButton[2],
                }
              ).length;
              $scope.selectedButtonIndex = 0;
              if (data.length > 0) {
                count = data[0].count;
                count = count - 500;
              } else {
                count = 0;
              }
              $scope.dummyItems = $scope.extensionRequests;
              $scope.pager = {};
              $scope.setPage(1);
              $log.debug('success');
              $scope.loading = false;
            },
            function (error) {
              $log.debug(error);
              $scope.loading = false;
            }
          );
      };

      $scope.setPage = function (page) {
        $scope.pager = [];
        if ($scope.selectedButtonIndex === 0) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          if ($scope.extReqCount_0 / (page * 10) < 1 && count >= 1) {
            pageNo = pageNo + 1;
            AgentService.getAllExtensionRequestForIssuerGM(
              pageNo,
              $scope.generalMeetingId,
              $scope.issuerId
            )
              .query()
              .$promise.then(
                function (data) {
                  $scope.extensionRequestsList = $scope.extensionRequestsList.concat(
                    data
                  );
                  $scope.extensionRequests = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[0],
                    }
                  );
                  $scope.extReqCount_0 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[0],
                    }
                  ).length;
                  $scope.extReqCount_1 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[1],
                    }
                  ).length;
                  $scope.extReqCount_2 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[2],
                    }
                  ).length;
                  count = count - 500;
                  $scope.dummyItems = $scope.extensionRequests;
                  // get pager object from service
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );
                  $log.debug($scope.pager);

                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                },
                function (error) {
                  $log.debug(error);
                }
              );
          }
        } else if ($scope.selectedButtonIndex === 1) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          if ($scope.extReqCount_1 / (page * 10) < 1 && count >= 1) {
            pageNo = pageNo + 1;
            AgentService.getAllExtensionRequestForIssuerGM(
              pageNo,
              $scope.generalMeetingId,
              $scope.issuerId
            )
              .query()
              .$promise.then(
                function (data) {
                  $scope.extensionRequestsList = $scope.extensionRequestsList.concat(
                    data
                  );
                  $scope.extensionRequests = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[1],
                    }
                  );
                  $scope.extReqCount_0 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[0],
                    }
                  ).length;
                  $scope.extReqCount_1 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[1],
                    }
                  ).length;
                  $scope.extReqCount_2 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[2],
                    }
                  ).length;
                  count = count - 500;
                  $scope.dummyItems = $scope.extensionRequests;
                  // get pager object from service
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );
                  $log.debug($scope.pager);

                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                },
                function (error) {
                  $log.debug(error);
                }
              );
          }
        } else if ($scope.selectedButtonIndex === 2) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          if ($scope.extReqCount_2 / (page * 10) < 1 && count >= 1) {
            pageNo = pageNo + 1;
            AgentService.getAllExtensionRequestForIssuerGM(
              pageNo,
              $scope.generalMeetingId,
              $scope.issuerId
            )
              .query()
              .$promise.then(
                function (data) {
                  $scope.extensionRequestsList = $scope.extensionRequestsList.concat(
                    data
                  );
                  $scope.extensionRequests = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[2],
                    }
                  );
                  $scope.extReqCount_0 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[0],
                    }
                  ).length;
                  $scope.extReqCount_1 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[1],
                    }
                  ).length;
                  $scope.extReqCount_2 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[2],
                    }
                  ).length;
                  count = count - 500;
                  $scope.dummyItems = $scope.extensionRequests;
                  // get pager object from service
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );
                  $log.debug($scope.pager);

                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                },
                function (error) {
                  $log.debug(error);
                }
              );
          }
        }
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }

        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
        $log.debug($scope.pager);

        // get current page of items
        $scope.items = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
      };
      //on click of button, display the corresponding list based on status
      $scope.buttonClicked = function ($index) {
        $scope.selectedButtonIndex = $index;
        $scope.extensionRequests = $filter('filter')(
          $scope.extensionRequestsList,
          {
            status: $scope.extnStatusButton[$index],
          }
        );
        $scope.dummyItems = $scope.extensionRequests;
        $scope.pager = {};
        $scope.setPage(1);
      };
      //accept or reject extension request modal window
      $scope.approveReject = function (
        action,
        status,
        extReqId,
        newEndDate,
        index,
        meetingName,
        shareholderName,
        gmDate,
        issuerName,
        intermediaryId,
        shareholderPosition,
        intermediaryDeadlineDate,
        extenReqNewEndDate
      ) {
        $scope.newEndDate = new Date(newEndDate);
        $scope.newEndDateList[index] = new Date(newEndDate);
        $scope.mytime = newEndDate;
        $scope.action = action;
        $scope.extReqId = extReqId;
        $scope.meetingName = meetingName;
        $scope.shareholderName = shareholderName;
        $scope.gmDate = gmDate;
        $scope.issuerName = issuerName;
        $scope.intermediaryId = intermediaryId;
        $scope.shareholderPosition = shareholderPosition;
        $scope.extenReqNewEndDate = extenReqNewEndDate;
        $scope.modalValidationErrorMsg = '';
        $scope.erStatus = status;
        $scope.validateDate(
          $scope.newEndDate,
          intermediaryDeadlineDate,
          extenReqNewEndDate
        );
        if ($scope.action === 'Accept') {
          $scope.modalHeader = $filter('translate')('label_extReq');
          if (status === 'P') {
            $scope.modalBody = $filter('translate')(
              'label_acceptExtnReqConfirm'
            );
          } else {
            $scope.modalBody = $filter('translate')('label_acceptExtnReq');
          }
          $scope.modalFooter = '';
          $scope.modalLeftButton = $filter('translate')('label_accept');
          $scope.modalRightButton = $filter('translate')('label_cancel');
          $scope.modalNewEndDate = $filter('translate')('label_new_end_date');
          $scope.modalValidationErrorMsg = $filter('translate')(
            'label_approveExtReqError'
          );
        } else if ($scope.action === 'Reject') {
          $scope.modalHeader = $filter('translate')('label_extReq');
          if (status === 'P') {
            $scope.modalBody = $filter('translate')(
              'label_rejectExtnReqConfirm'
            );
          } else {
            $scope.modalBody = $filter('translate')('label_rejectExtnReq');
          }
          $scope.modalFooter = '';
          $scope.modalLeftButton = $filter('translate')('label_reject');
          $scope.modalLeftButton2 = $filter('translate')('label_rejectAll');
          $scope.modalRightButton = $filter('translate')('label_cancel');
          ModelWindowService.showModelWindow('rejectAlertBoxWithDoubleButton');
        }
      };

      $scope.dateOptions = {
        formatYear: 'yy',
        showWeeks: false,
      };
      // Initializing format array
      $scope.formats = [
        'dd-MM-yyyy',
        'yyyy/MM/dd HH:mm',
        'dd.MM.yyyy HH:mm',
        'shortDate',
      ];
      // Setting format
      $scope.format = $scope.formats[0];

      $scope.open1 = function () {
        $scope.popup1.opened = true;
      };
      $scope.popup1 = {
        opened: false,
      };
      $scope.open1 = function (index) {
        $scope.popup1List[index].opened = true;
      };
      $scope.popup1List = [];
      $scope.popup1 = {
        opened: false,
      };

      $scope.open1Modal = function () {
        $scope.popup1.opened = true;
      };
      // Method to populate NewEndDate
      $scope.populateNewEndDate = function (newEndDate, index) {
        $scope.popup1List[index] = {
          opened: false,
        };
        $scope.newEndDateList[index] = new Date(newEndDate);
        if (newEndDate == null) {
          $scope.newEndDateList[index] = null;
        }
      };

      $scope.validateDate = function (
        newEndDate,
        intermediaryDeadlineDate,
        extenReqNewEndDate
      ) {
        $scope.validationErrorFlag = false;
        if ($scope.intermediaryDeadlineDate === undefined) {
          $scope.intermediaryDeadlineDate = intermediaryDeadlineDate;
        }
        if ($scope.extenReqNewEndDate === undefined) {
          $scope.extenReqNewEndDate = extenReqNewEndDate;
        }
        if (newEndDate === undefined) {
          $scope.validationErrorFlag = true;
          return;
        }
        if (
          newEndDate.getTime() > new Date($scope.gmDate).getTime() ||
          new Date($scope.intermediaryDeadlineDate).getTime() >
            newEndDate.getTime() ||
          newEndDate.getTime() >
            new Date($scope.extenReqNewEndDate).getTime() ||
          newEndDate.getTime() <
            new Date($scope.extenReqNewEndDate).getTime() - $scope.fiveHours
        ) {
          $scope.validationErrorFlag = true;
        }
      };

      // reject single extension request
      $scope.approveRejectExtn = function (action, extReqId) {
        if ($scope.timeZone == 'GMT') {
          $scope.newEndDate = $scope.newEndDate.getTime() + 3600000;
        } else {
          $scope.newEndDate = $scope.newEndDate.getTime();
        }
        $scope.approveRejectExtensionRequest = {
          gmId: $stateParams.generalMeetingId,
          extReqId: $scope.extReqId,
          status: action,
          flag: 'ONE',
          newEndDate: $scope.newEndDate.toString(),
          name: $scope.meetingName,
          issuerName: $scope.issuerName,
          date: $scope.gmDate,
          shareholderName: $scope.shareholderName,
          intermediaryId: $scope.intermediaryId,
          shareholderPosition: $scope.shareholderPosition,
          issuerId: $scope.issuerId,
          rejectReason: $sessionStorage.extenRejectReason,
          rejectedBy: $sessionStorage.userId,
          userType: $sessionStorage.usertype,
        };
        AgentService.approveRejectForGM(
          $scope.approveRejectExtensionRequest,
          'secureToken',
          function (response) {
            if (action === 'Accept')
              $scope.extnReqSuccess = $filter('translate')(
                'label_approveExtnReq'
              );
            else if (action === 'Reject')
              $scope.extnReqSuccess = $filter('translate')(
                'label_rejectExtensionReq'
              );
            $('#successMsg').modal('show');
            $log.debug(response);
            $timeout(function () {
              $('#successMsg').modal('hide');
              $('.modal-backdrop').remove();
              $scope.getExtensionRequests();
            }, 2000);
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      // reject all extension requests belonging to an issuer.
      $scope.approveRejectAllExtn = function (action, extReqId) {
        if ($scope.timeZone == 'GMT') {
          $scope.newEndDate = $scope.newEndDate.getTime() + 3600000;
        } else {
          $scope.newEndDate = $scope.newEndDate.getTime();
        }
        $scope.approveRejectExtensionRequest = {
          gmId: $stateParams.generalMeetingId,
          extReqId: $scope.extReqId,
          status: action,
          flag: 'ALL',
          newEndDate: $scope.newEndDate.toString(),
          name: $scope.meetingName,
          issuerName: $scope.issuerName,
          date: $scope.gmDate,
          shareholderName: $scope.shareholderName,
          intermediaryId: $scope.intermediaryId,
          shareholderPosition: $scope.shareholderPosition,
          issuerId: $scope.issuerId,
          rejectReason: $sessionStorage.extenRejectReason,
          rejectedBy: $sessionStorage.userId,
          userType: $sessionStorage.usertype,
          currentStatus: $scope.erStatus,
        };
        AgentService.approveRejectForGM(
          $scope.approveRejectExtensionRequest,
          'secureToken',
          function (response) {
            $scope.extnReqSuccess = $filter('translate')(
              'label_rejectAllExtnRequests'
            );
            $('#successMsg').modal('show');
            $log.debug(response);
            $timeout(function () {
              $('#successMsg').modal('hide');
              $('.modal-backdrop').remove();
              $scope.getExtensionRequests();
            }, 2000);
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      //fetch the list for the first time
      $scope.getExtensionRequests();
    },
  ]);
