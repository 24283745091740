/**
 * @ngdoc controller
 * @name TaxReclaimAuditController
 * @description This controller is used for audit trail and templates. This mainly
 *              deals with viewing the list email audit trail, audit trail and templates. It also deals with editing templates.
 * @requires DWTAuditTrailService is the service which is being used to interact with REST
 * @requires $scope for storing information in scope
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 * @requires PaginationSvc for pagination
 */
'use strict';
angular.module('dwtModule').controller('TaxReclaimAuditController', [
  '$scope',
  '$sessionStorage',
  'DWTAuditTrailService',
  '$log',
  '$state',
  'PaginationSvc',
  'fileUploadSvc',
  '$filter',
  '$timeout',
  '$sanitize',
  'dwtFileDownloadSvc',
  function (
    $scope,
    $sessionStorage,
    DWTAuditTrailService,
    $log,
    $state,
    PaginationSvc,
    fileUploadSvc,
    $filter,
    $timeout,
    $sanitize,
    dwtFileDownloadSvc,
  ) {
    let maxCount = 0;
    let pageFrom = 1;
    let pageFromEmail = 1;
    $scope.selectedTrail = '';
    $scope.adminAuditLoading = true;
    $scope.emailAuditLoading = true;
    $scope.validationErrorFlag = false;
    $scope.validationErrorMsg = '';
    $scope.validationErrorFlagAuditTrail = false;
    $scope.validationErrorMsgAuditTrail = '';
    $sessionStorage.emailInitialData = null;
    $scope.uploadTemplates = false;
    $scope.editTemplate = false;
    $scope.emailDetailsTab = false;
    $scope.templateContent = [];
    $scope.trixContent = '';
    $scope.selectedType = '';
    $scope.title = '';
    $scope.templateSearchData = {};
    $sessionStorage.emailAudit = null;
    $scope.auditTrailSrchNoData = false;
    $scope.multiResFlag = false;
    $scope.disableAddSection = false;
    $scope.selectDropdownMsg = '--select issuer to list events--';
    $scope.taxReclaimIndexId = $sessionStorage.taxReclaimIndexId;

    $scope.selectedEmailAuditIssuerName = null;
    $scope.selectedAuditIssuerName = null;
    $scope.selectedEmailAuditEventName = null;
    $scope.selectedAuditEventName = null;
    $scope.selectedAction = null;

    DWTAuditTrailService.getAuditTrailDropdown().then(
      function (data) {
        $scope.issuerDropDown = data.data;
      },
      function (error) {
        $log.debug(error);
      }
    );
    // Below is the function to set pagination
    $scope.setPage = function (page) {
      $scope.pager = [];
      //Below condition is to call the service to get the next 100 records
      if (
        page % 10 === 0 &&
        maxCount / 100 > 1 &&
        page <= $scope.noOfPages &&
        !$scope.searchResult
      ) {
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }
        if ($scope.selectedTrail === 'Audit') {
          pageFrom = pageFrom + 1;
          $scope.adminAuditLoading = true;
          DWTAuditTrailService.getAllAuditTrailsData(pageFrom)
            .query()
            .$promise.then(
              function (data) {
                $scope.auditTrailsData = data;
                $scope.adminAuditLoading = false;
                if ($sessionStorage.emailInitialData !== null) {
                  maxCount = $sessionStorage.maxCount;
                  $sessionStorage.emailInitialData = $sessionStorage.emailInitialData.concat(
                    $scope.auditTrailsData
                  );
                } else {
                  $sessionStorage.emailInitialData = $scope.auditTrailsData;
                  $sessionStorage.maxCount = data[0].count;
                  maxCount = data[0].count;
                }
                $scope.dummyItems = $sessionStorage.emailInitialData;
                // get pager object from service
                $scope.pager = PaginationSvc.GetPager(
                  $scope.dummyItems.length,
                  page
                );
                $scope.noOfPages = $scope.pager.totalPages;
                $log.debug($scope.pager);

                // get current page of items
                $scope.items = $scope.dummyItems.slice(
                  $scope.pager.startIndex,
                  $scope.pager.endIndex + 1
                );
              },
              function (error) {
                $log.debug(error);
              }
            );
        } else if ($scope.selectedTrail === 'Email') {
          pageFromEmail = pageFromEmail + 1;
          $scope.emailAuditLoading = true;
          DWTAuditTrailService.getAllEmailAuditTrailsData(pageFromEmail).then(
            function (data) {
              $scope.emailAuditTrailsData = data.data;
              $scope.emailAuditLoading = false;
              if ($sessionStorage.emailAudit !== null) {
                maxCount = $sessionStorage.maxCount;
                $sessionStorage.emailAudit = $sessionStorage.emailAudit.concat(
                  $scope.emailAuditTrailsData
                );
              } else {
                $sessionStorage.emailAudit = $scope.emailAuditTrailsData;
                $sessionStorage.maxCount = data.data[0].count;
                maxCount = data.data[0].count;
              }
              $scope.dummyItems = $sessionStorage.emailAudit;
              // get pager object from service
              $scope.pager = PaginationSvc.GetPager(
                $scope.dummyItems.length,
                page
              );
              $scope.noOfPages = $scope.pager.totalPages;
              $log.debug($scope.pager);

              // get current page of items
              $scope.items = $scope.dummyItems.slice(
                $scope.pager.startIndex,
                $scope.pager.endIndex + 1
              );
            },
            function (error) {
              $log.debug(error);
            }
          );
        }
      } else {
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }
        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
        $log.debug($scope.pager);
        $scope.noOfPages = $scope.pager.totalPages;
        // get current page of items
        $scope.items = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
      }
    };
    // Below is the function call from HTML to reset the search criterias of audit trail list
    $scope.ClearSearchFields = function () {
      $scope.startDateModified = '';
      $scope.endDateModified = '';
      $scope.entityChanged = '';
      $scope.userName = '';
      $scope.selectedAuditIssuerName = null;
      $scope.selectedAuditEventName = null;
      $scope.selectedAction = null;
      $scope.selectDropdownMsg = '--select issuer to list events--';
    };
    $scope.aTResetDefaultTableData = function () {
      $scope.dummyItems = $scope.forSrchRestData;
      $scope.pager = {};
      $scope.setPage(1);
    };
    //Method to validate all the date inputs
    $scope.dateValidation = function () {
      $scope.validationErrorFlag = false;
      $scope.validationErrorMsg = '';
      $scope.validationErrorFlagAuditTrail = false;
      $scope.validationErrorMsgAuditTrail = '';
      if (
        new Date($scope.startDateModifiedEmailAudit) >
        new Date($scope.endDateModifiedEmailAudit)
      ) {
        $scope.validationErrorFlag = true;
        $scope.validationErrorMsg = $filter('translate')('selectToDate_error');
      }
      if (
        new Date($scope.startDateModified) > new Date($scope.endDateModified)
      ) {
        $scope.validationErrorFlagAuditTrail = true;
        $scope.validationErrorMsgAuditTrail = $filter('translate')(
          'selectToDate_error'
        );
      }
    };
    // Below is the function to get all the audit trails.
    $scope.getAuditTrailList = function () {
      $sessionStorage.emailAudit = null;
      $scope.validationErrorFlag = false;
      $scope.validationErrorMsg = '';
      $scope.uploadTemplates = false;
      $scope.selectedTrail = 'Audit';
      $scope.adminAuditLoading = true;
      DWTAuditTrailService.getActionDropDown()
        .query()
        .$promise.then(
          function (data) {
            $scope.actions = data;
          },
          function (error) {
            $log.debug(error);
          }
        );
      DWTAuditTrailService.getAllAuditTrailsData(pageFrom)
        .query()
        .$promise.then(
          function (data) {
            $scope.searchResult = null;
            $scope.auditTrailsData = data;
            $scope.forSrchRestData = $scope.auditTrailsData;
            if ($sessionStorage.emailInitialData !== null) {
              maxCount = $sessionStorage.maxCount;
              $sessionStorage.emailInitialData = $sessionStorage.emailInitialData.concat(
                $scope.auditTrailsData
              );
            } else {
              $sessionStorage.emailInitialData = $scope.auditTrailsData;
              $sessionStorage.maxCount = data[0].count;
              maxCount = data[0].count;
            }

            $scope.dummyItems = $sessionStorage.emailInitialData;
            $scope.pager = {};
            $scope.setPage(1);
            $scope.adminAuditLoading = false;
          },
          function (error) {
            $log.debug(error);
          }
        );
      $scope.ClearSearchFields();
    };
    // Below is the function call from HTML to reset the search criterias of email audit trail list
    $scope.ClearEmailSearchFields = function () {
      $scope.startDateModifiedEmailAudit = '';
      $scope.endDateModifiedEmailAudit = '';
      $scope.validationErrorMsg = '';
      $scope.entityChanged = '';
      $scope.userNameEmailAudit = '';
      $scope.selectedEmailAuditIssuerName = null;
      $scope.selectedEmailAuditEventName = null;
      $scope.selectDropdownMsg = '--select issuer to list events--';
      $scope.eventNameDropDown = [];
    };
    $scope.emailAudTrailResetDefaultTableData = function () {
      $scope.dummyItems = $scope.forEmailSrchRestData;
      $scope.pager = {};
      $scope.setPage(1);
    };
    // Below is the function to get all the email audit trails
    $scope.getEmailAuditTrailList = function () {
      $scope.selectedTrail = 'Email';
      $sessionStorage.emailInitialData = null;
      $scope.uploadTemplates = false;
      $scope.emailAuditLoading = true;
      $scope.emailDetailsTab = false;
      $scope.validationErrorFlagAuditTrail = false;
      $scope.validationErrorMsgAuditTrail = '';
      document.getElementById('emaildetails').style.display = 'none';
      document.getElementById('emailaudittrail').style.display = 'block';
      document.getElementById('emailaudittrail1').style.display = 'block';
      document.getElementById('emailaudittrail2').style.display = 'block';
      document.getElementById('pagenavigation').style.display = 'block';
      DWTAuditTrailService.getAllEmailAuditTrailsData(pageFromEmail).then(
        function (data) {
          $scope.searchResult = null;
          $scope.emailAuditTrailsData = data.data;
          $scope.forEmailSrchRestData = $scope.emailAuditTrailsData;
          $scope.emailAuditLoading = false;
          if ($sessionStorage.emailAudit !== null) {
            maxCount = $sessionStorage.maxCount;
            $sessionStorage.emailAudit = $sessionStorage.emailAudit.concat(
              $scope.emailAuditTrailsData
            );
          } else {
            $sessionStorage.emailAudit = $scope.emailAuditTrailsData;
            $sessionStorage.maxCount = data.data[0].count;
            maxCount = data.data[0].count;
          }

          $scope.dummyItems = $sessionStorage.emailAudit;
          $scope.pager = {};
          $scope.setPage(1);
          $scope.emailAuditLoading = false;
        },
        function (error) {
          $log.debug(error);
        }
      );
      $scope.ClearEmailSearchFields();
    };
    // Below is the function to go back to email audit trail after viewing an email audit details
    $scope.backToEmailAuditTrail = function () {
      document.getElementById('emaildetails').style.display = 'none';
      document.getElementById('emailaudittrail').style.display = 'block';
      document.getElementById('emailaudittrail1').style.display = 'block';
      document.getElementById('emailaudittrail2').style.display = 'block';
      document.getElementById('pagenavigation').style.display = 'block';
    };
    // Below is the function call to search for particular audit trail
    $scope.searchAuditTrail = function (
      startDate,
      endDate,
      actionCode,
      entityChanged,
      userName,
      eventName
    ) {
      $scope.searchAuditLoading = true;
      if (endDate) {
        let timeData = new Date(endDate);
        timeData.setMinutes(59);
        timeData.setHours(23);
        endDate = timeData.getTime();
      }
      if (startDate) {
        startDate = startDate.getTime();
      }
      $scope.auditSearchData = {
        startDateModified: startDate,
        endDateModified: endDate,
        actionCode: actionCode,
        afterValue: entityChanged,
        userName: userName,
        eventName: eventName,
      };
      DWTAuditTrailService.searchAuditTrail(
        $scope.auditSearchData,
        'secureToken'
      ).then(
        function (response) {
          $scope.searchResult = 1;
          $scope.auditTrailsData = response.data;
          $scope.dummyItems = $scope.auditTrailsData;
          $scope.pager = {};
          $scope.setPage(1);
          $scope.searchAuditLoading = false;
        },
        function (error) {
          $log.debug(error);
          $scope.searchAuditLoading = false;
        }
      );
    };

    // call to search for particular audit trail
    $scope.searchEmailAudits = function (
      startDate,
      endDate,
      entityChanged,
      userName,
      eventName
    ) {
      $scope.searchEmailAuditLoading = true;
      if (endDate) {
        let timeData = new Date(endDate);
        timeData.setMinutes(59);
        timeData.setHours(23);
        endDate = timeData.getTime();
      }
      if (startDate) {
        startDate = startDate.getTime();
      }
      $scope.emailAuditSearchData = {
        startDateModified: startDate,
        endDateModified: endDate,
        afterValue: entityChanged,
        userName: userName,
        eventName: eventName,
        prodCode: 'DWT',
      };
      DWTAuditTrailService.filterEmailAuditTrail(
        $scope.emailAuditSearchData
      ).then(
        function (response) {
          $scope.searchResult = 1;
          $scope.auditTrailsData = response.data;
          $scope.dummyItems = $scope.auditTrailsData;
          $scope.pager = {};
          $scope.setPage(1);
          $scope.searchEmailAuditLoading = false;
        },
        function (error) {
          $log.debug(error);
          $scope.searchEmailAuditLoading = false;
        }
      );
    };
    //Below is the function call on click of email id in email audit trail to view details
    $scope.showEmailDetails = function (
      emailBody,
      emailSubject,
      receiverAddressList,
      sentDate,
      attachmentVO,
      multiResFlag
    ) {
      $scope.receiverAddress = '';
      $scope.emailhtml = emailBody;
      $scope.emailSubject = emailSubject;
      if (multiResFlag) {
        angular.forEach(receiverAddressList, function (value) {
          $scope.receiverAddress =
            $scope.receiverAddress + value.sentTO + ';  ';
        });
        $scope.multiResFlag = true;
      } else {
        $scope.receiverAddress = receiverAddressList[0].sentTO;
        $scope.multiResFlag = false;
      }
      $scope.sentDate = sentDate;
      $scope.attachmentVO = attachmentVO;
      $scope.emailDetailsTab = true;
      document.getElementById('emaildetails').style.display = 'block';
      document.getElementById('emailaudittrail').style.display = 'none';
      document.getElementById('emailaudittrail1').style.display = 'none';
      document.getElementById('emailaudittrail2').style.display = 'none';
      document.getElementById('pagenavigation').style.display = 'none';
    };
    //Below is the function to navigate to templates tab and load options for templates type dropdown.
    $scope.getTemplates = function () {
      $scope.subjectSelected = false;
      $scope.uploadTemplates = false;
      $scope.editTemplate = false;
      $sessionStorage.emailInitialData = null;
      $sessionStorage.emailAudit = null;
      DWTAuditTrailService.getTemplateTypes().then(
        function (response) {
          $scope.templatetypes = response.data;
          angular.forEach($scope.templatetypes, function (value, key) {
            if (
              value.templateCode === null ||
              value.templateCode === undefined ||
              value.templateCode === 'CERT'
            ) {
              $scope.templatetypes.splice(key, 1);
            } else if (
              value.templateCode === null ||
              value.templateCode === undefined ||
              value.templateCode === 'UPLOAD'
            ) {
              if ($sessionStorage.usertype !== 'AG') {
                $scope.templatetypes.splice(key, 1);
              }
            }
          });
          $scope.emailTemplatesOptions = [];
          $scope.templateContent = [];
          $scope.dummyItems = [];
          $scope.pager = {};
          $scope.setPage(1);
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    //Below is the function call on change of template type option to load relevant options in subjects dropdown
    $scope.getSubjectOptions = function (selectedTemplateType) {
      if (selectedTemplateType === 'CERT') {
        $scope.uploadTemplates = false;
        $scope.uploadTemplates_Type = '';
        $scope.templateFile = {};
        $scope.selectedType = 'CERT';
        DWTAuditTrailService.getSubjectOptions().then(
          function (response) {
            $scope.emailTemplatesOptions = response.data;
          },
          function (error) {
            $log.debug(error);
          }
        );
      } else if (selectedTemplateType === 'EMAIL') {
        $scope.uploadTemplates = false;
        $scope.uploadTemplates_Type = '';
        $scope.templateFile = {};
        $scope.selectedType = 'EMAIL';
        DWTAuditTrailService.getEmailSubjectOptions().then(
          function (response) {
            $scope.emailTemplatesOptions = response.data.sort((a, b) => {
              if (a.type === 'ALL') {
                return -1; // 'ALL' comes before everything else
              } else if (b.type === 'ALL') {
                return 1; // Everything else comes after 'ALL'
              } else {
                return a.type.localeCompare(b.type); // Sort alphabetically
              }
            });
          },
          function (error) {
            $log.debug(error);
          }
        );
      } else if (selectedTemplateType === 'WEB') {
        $scope.uploadTemplates = false;
        $scope.uploadTemplates_Type = '';
        $scope.templateFile = {};
        $scope.selectedType = 'WEB';
        DWTAuditTrailService.getWebSubjectOptions().then(
          function (response) {
            $scope.emailTemplatesOptions = response.data;
            angular.forEach($scope.emailTemplatesOptions, function (
              value,
              key
            ) {
              if (
                value.type === null ||
                value.type === undefined ||
                value.type === 'Votecollector'
              ) {
                $scope.emailTemplatesOptions.splice(key, 1);
              }
            });
          },
          function (error) {
            $log.debug(error);
          }
        );
      } else if (selectedTemplateType === 'UPLOAD') {
        $scope.selectedType = 'UPLOAD';
        $scope.uploadTemplates = true;
        $scope.emailTemplatesOptions = [];
        $scope.userId = $sessionStorage.userId;
        $scope.eventName = 'DWT registration form';
        $scope.groupName = 'DWT';
        $scope.langCode = 'EN';
      }
    };

    $scope.fileClear = function (e) {
      e.value = '';
    };

    $scope.fileSelector = function (e) {
      $scope.templateFile = e.files[0];
      $timeout(function () {
        $scope.$apply();
      });
    };

    $scope.autoTimeout = function () {
      $timeout(function () {
        $scope.uploadTemplates_Type = '';
        $scope.templateFile = {};
        $scope.message = '';
      }, 5000);
    };
    $scope.uploadTemplateFile = function () {
      $scope.emailTemplatesOptions = [];
      let documentFormData = new FormData();
      documentFormData.append('loggedInUser', $sessionStorage.userId);
      documentFormData.append('eventName', $scope.selectedAuditEventName);
      documentFormData.append('groupName', $scope.groupName);
      documentFormData.append('langCode', $scope.langCode);
      if ($scope.templateFile) {
        $scope.fileName = $scope.templateFile.name;
        documentFormData.append('file', $scope.templateFile);
      }
      documentFormData.append('templateType', 'xls');

      let file = $scope.templateFile;
      let uploadUrl = 'v1/templates';
      fileUploadSvc.uploadFileToUrl(file, uploadUrl, documentFormData).then(
        function (data) {
          if (data.data.httpStatusCode === 200) {
            $scope.successFileUpload = true;
            $scope.failureFileUpload = false;
            $scope.message = data.data.message;
            $scope.autoTimeout();
          } else {
            $scope.successFileUpload = false;
            $scope.failureFileUpload = true;
            $scope.message = data.data.errors[0].errorMessage;
            $scope.autoTimeout();
          }
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    $scope.cancelTemplateUpload = function () {
      $scope.getTemplates();
    };
    //Below is function call from HTML for modal window.
    $scope.changeDeleteId = function (id, code) {
      $scope.deleteTempID = id;
      $scope.deleteTempCode = code;
      $scope.modalHeader = $filter('translate')('label_modalDelete');
      $scope.modalBody = $filter('translate')('label_modalConfirmDelTemplate');
      $scope.modalFooter = '';
      $scope.modalLeftButton = $filter('translate')('label_yes');
      $scope.modalRightButton = $filter('translate')('label_no');
    };
    //Below is the function call from HTML to search templates
    $scope.searchTemplate = function (
      templateCode,
      type,
      searchType,
      selectedSubject,
      selectedScreen,
      title
    ) {
      $scope.selectedScreen = selectedScreen;
      $scope.searchType = searchType;
      $scope.title = title;
      $scope.templateContent = [];
      if ($scope.selectedType === 'WEB') {
        $scope.templateSearchData = {
          templateCode: templateCode,
          dropDownOne: type,
          dropDownTwoId: searchType,
          screenName: selectedScreen,
          dropDownOneKey: selectedSubject,
          productCode: 'DWT',
        };
      } else {
        if (selectedSubject === 'ALL' && $scope.selectedType === 'EMAIL') {
          $scope.templateSearchData = {
            templateCode: templateCode,
            dropDownOne: type,
            dropDownTwoId: searchType,
            dropDownOneKey: selectedSubject,
            productCode: 'DWT',
          };
        } else {
          $scope.templateSearchData = {
            templateCode: templateCode,
            dropDownOne: type,
            dropDownTwoId: searchType,
            dropDownOneKey: selectedSubject,
          };
        }
      }

      DWTAuditTrailService.searchTemplate(
        $scope.templateSearchData,
        'secureToken'
      ).then(
        function (response) {
          $scope.templatesData = response.data;
          if ($scope.selectedType === 'WEB') {
            $scope.templateContent = $scope.templatesData;
          } else {
            angular.forEach($scope.templatesData, function (value, key) {
              $scope.templateContent = $scope.templateContent.concat([
                {
                  languageCode: response.data[key].languageCode,
                  templateId: response.data[key].templateId,
                  templateCode: response.data[key].templateCode,
                  sender: response.data[key].sender,
                  description1: response.data[key].description1,
                  issuerVO: response.data[key].issuerVO,
                  description2: response.data[key].description2,
                  tempDesc: response.data[key].description2,
                },
              ]);
            });
          }

          $scope.dummyItems = $scope.templatesData;
          $scope.pager = {};
          $scope.setPage(1);
        },
        function (error) {
          $log.debug(error);
        }
      );
    };

    // Navigate to edit template section and prepopulate the data.
    $scope.editEmail = function (
      option,
      issuerName,
      subject,
      sender,
      description,
      templateCode,
      templateId,
      issuerId,
      showIssuerFlag
    ) {
      $scope.showIssuerFlag = showIssuerFlag;
      $scope.successMessage = '';
      $scope.modalHeader = $filter('translate')('label_modalUpdateTemp');
      $scope.modalBody = $filter('translate')(
        'label_modalConfirmUpdateTemplate'
      );
      $scope.modalFooter = '';
      $scope.modalLeftButton = $filter('translate')('label_yes');
      $scope.modalRightButton = $filter('translate')('label_no');
      if (option === 'cancel') {
        $scope.editTemplate = false;
      } else if (issuerName) {
        $scope.editTemplate = true;
        $scope.templateIssuer = issuerName;
        $scope.templateSubject = subject;
        $scope.templateSender = sender;
        $scope.trixContent = description;
        $log.debug($scope.trixContent);
        $scope.templateCode = templateCode;
        $scope.templateId = templateId;
        $scope.templateIssuerId = issuerId;
      } else {
        DWTAuditTrailService.getIssuerList().then(
          function (response) {
            $scope.issuerList = response.data;
            $scope.editTemplate = true;
          },
          function (error) {
            $log.debug(error);
          }
        );
        $scope.templateIssuer = null;
        $scope.templateSubject = subject;
        $scope.templateSender = sender;
        $scope.trixContent = description;
        $log.debug($scope.trixContent);
        $scope.templateCode = templateCode;
        $scope.templateId = templateId;
      }
    };

    //Below is the function call from HTML to download the attachment in email details
    $scope.downloadEmailAttachment = function (attachmentId) {
      $scope.attachmentId = attachmentId;
      let downloadUrl =
        'v1/emailAuditTrail/downloadAttachment/' +
        $scope.attachmentId +
        '?authid=' +
        $sessionStorage.userId;
      let a = document.createElement('a');
      document.body.appendChild(a);
      dwtFileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            let fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          downloadUrl = '';
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    //Below is the function call to update the changes for a template.
    $scope.updateTemplate = function (
      subject,
      description,
      sender,
      selectedIssuerId,
      selectedSubject,
      showIssuerFlag
    ) {
      description = $sanitize(description);
      if ($scope.templateCode !== 'EMAIL') {
        sender = '';
      }
      if ($scope.templateIssuerId) {
        $scope.issuerId = $scope.templateIssuerId;
      } else if (selectedIssuerId) {
        $scope.issuerId = selectedIssuerId;
      }

      if ($scope.selectedType === 'WEB') {
        $scope.requestDescription = description;
        $scope.updateTemplateData = {
          templateTitle: $scope.title,
          userId: $sessionStorage.userId,
          description1: selectedSubject,
          description2: $scope.requestDescription,
          templateCode: $scope.templateCode,
          templateId: $scope.templateId,
        };
      } else {
        let descriptionString = description.toString();
        if (descriptionString.indexOf('<body') > -1) {
          $scope.requestDescription = descriptionString;
        } else {
          $scope.requestDescription =
            '<body text="#000000" link="#000000" alink="#000000" vlink="#000000">' +
            description +
            '</body>';
        }
        $scope.updateTemplateData = {
          templateTitle: $scope.title,
          userId: $sessionStorage.userId,
          description1: subject,
          description2: $scope.requestDescription,
          sender: sender,
          templateCode: $scope.selectedType,
          templateId: $scope.templateId,
        };
        if ($scope.issuerId) {
          $scope.updateTemplateData.issuerId = $scope.issuerId;
        }
      }

      DWTAuditTrailService.updateTemplate(
        $scope.updateTemplateData,
        'secureToken',
        showIssuerFlag
      ).then(
        function (response) {
          $scope.successMessage = response.data.message;
          $scope.templateContent = [];
          DWTAuditTrailService.searchTemplate(
            $scope.templateSearchData,
            'secureToken'
          ).then(
            function (response) {
              $scope.templatesData = response.data;
              if ($scope.selectedType === 'WEB') {
                $scope.templateContent = $scope.templatesData;
              } else {
                angular.forEach($scope.templatesData, function (value, key) {
                  $scope.templateContent = $scope.templateContent.concat([
                    {
                      languageCode: response.data[key].languageCode,
                      templateId: response.data[key].templateId,
                      templateCode: response.data[key].templateCode,
                      sender: response.data[key].sender,
                      description1: response.data[key].description1,
                      issuerVO: response.data[key].issuerVO,
                      description2: response.data[key].description2,
                      tempDesc: response.data[key].description2,
                    },
                  ]);
                });
              }

              $scope.dummyItems = $scope.templatesData;
              $scope.pager = {};
              $scope.setPage(1);
              $scope.editTemplate = false;
            },
            function (error) {
              $log.debug(error);
            }
          );
        },
        function (error) {
          $log.debug(error);
          $scope.successMessage = error.data.errors[0].errorMessage;
        }
      );
    };
    //Below is the function to call to delete the template from template list.
    $scope.deleteTemplate = function (type, id) {
      $scope.templateType = type;
      $scope.templateId = id;
      DWTAuditTrailService.deleteTemplate(
        $scope.templateType,
        $scope.templateId,
        'secureToken'
      ).then(
        function (response) {
          $scope.deleteSuccessMsg = response.data.message;
          $scope.searchTemplate(
            $scope.templateType,
            $scope.templateId,
            $scope.searchType
          );
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    //Below is the function call to search email audit trail when Enter key is pressed
    $scope.onEnterKey = function ($event) {
      if ($event.keyCode === 13) {
        if ($scope.searchReceiver) {
          $scope.searchEmailAudits($scope.searchReceiver);
        } else {
          $scope.getEmailAuditTrailList();
        }
      }
    };

    $scope.goBackFn = function () {
      $state.go(
        $state.current,
        {
          selectedIndex: 1,
        },
        {
          reload: true,
          location: false,
        }
      );
    };
    // fetch event By issuer Id
    $scope.getEventNameByIssuerId = function (issuer) {
      $scope.selectDropdownMsg = '--select event--';
      DWTAuditTrailService.getEventByIssuerId(issuer.issuerId).then(
        function (data) {
          $scope.eventNameDropDown = data.data;
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    // Re send mail function
    $scope.resendMail = function () {
      DWTAuditTrailService.resendEmail(
        $scope.emailObj.emailSentVOs[0].emailSentId
      ).then(
        function (data) {
          $scope.auditTrailPopUpSuccess = 'Email sent successfully !';
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
            $scope.getEmailAuditTrailList();
          }, 2000);
        },
        function (error) {
          $log.debug(error);
        }
      );
    };

    $scope.resendModal = function (email) {
      $scope.emailObj = email;
      $scope.modalHeader = 'Resend email';
      $scope.modalBody = 'Click on Send to resend the mail!';
      $scope.modalFooter = '';
      $scope.modalLeftButton = 'Send';
      $scope.modalRightButton = $filter('translate')('label_cancel');
    };

    /* date picker */

    $scope.inlineOptions = {
      customClass: getDayClass,
    };

    $scope.open1 = function () {
      $scope.popup1.opened = true;
    };
    $scope.open2 = function () {
      $scope.popup2.opened = true;
    };

    $scope.setDate = function (year, month, day) {
      $scope.dt = new Date(year, month, day);
    };

    $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[0];
    $scope.altInputFormats = ['M!/d!/yyyy'];

    $scope.popup1 = {
      opened: false,
    };
    $scope.popup2 = {
      opened: false,
    };
    // call the function for first time
    $scope.getEmailAuditTrailList();

    function getDayClass(data) {
      let date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        let dayToCheck = new Date(date).setHours(0, 0, 0, 0);

        for (let i = 0; i < $scope.events.length; i++) {
          let currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

          if (dayToCheck === currentDay) {
            return $scope.events[i].status;
          }
        }
      }
      return '';
    }

    /* date picker */

    /* time picker */
    $scope.example1 = {
      value: new Date(1970, 0, 1, 14, 57, 0),
    };
    $scope.example2 = {
      value: new Date(1970, 0, 1, 14, 57, 0),
    };
    /* time picker */
  },
]);
