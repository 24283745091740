/**
 * @ngdoc controller
 * @name actionHomeModule.ActionHomeIssuerDwtController
 * @description This controller is used for action home.
 * @requires generalMeetingService for rest calls related to general meeting
 * @requires voteCollectorService for rest calls related to vote collector
 *           services
 * @requires PaginationSvc for pagination
 * @requires $scope
 * @requires $log for debug logs
 * @requires $state for routing 
 * @requires $sessionStorage
 * @requires $stateParams
 * @requires AgentService
 * @requires $filter
 * @requires ModelWindowService
 */

angular.module('actionHomeModule').controller(
  'ActionHomeIssuerDwtController', ['$scope', '$log', '$state', '$stateParams', 'generalMeetingService', '$sessionStorage',
    'voteCollectorService', 'PaginationSvc', '$filter', 'AgentService', 'ModelWindowService',
    function ($scope, $log, $state, $stateParams, generalMeetingService, $sessionStorage,
      voteCollectorService, PaginationSvc, $filter, AgentService, ModelWindowService) {
      $scope.userType = $sessionStorage.usertype;
      $scope.agentName = $sessionStorage.userName;
      $scope.taxReclaimIndexId = $sessionStorage.taxReclaimIndexId;
      $scope.userId = $sessionStorage.userId;
      $scope.productTypes = $sessionStorage.productTypes;
      $scope.eventId = $stateParams.eventId;
      $scope.isAdmin = $sessionStorage.isAdmin;
      $scope.showGMActions = false;
      $scope.showDWTActions = false;
      $scope.loadingDwt = true;
      $scope.beginIndex = 0;
      $scope.currentPageDwt = 1;
      var dwtPage = 1;
      if ($stateParams.page !== undefined && $stateParams.page !== null) {
        dwtPage = $stateParams.page;
      }
      if ($sessionStorage.usertype === appConstant.IS) {
        var workStation = $scope.userType;
        var screenName = appConstant.IS_HOME_PAGE;
        var screenLocation = appConstant.BODY;
        var langCode = appConstant.OPTED_EN;
        $scope.productType = $sessionStorage[0];
        voteCollectorService.getVCStaticText(workStation, screenName,
          screenLocation, langCode).query().$promise.then(function (
          data) {
          $scope.landingPageText = data.templateList[0].templateText;
          $log.debug(data);
        }, function (error) {
          $log.debug(error);
        });
      }

      //Below function is to sort it records in the action home page for dwt
      $scope.dwtOnColumnClick = function (newValue) {
        if (newValue === '' || !newValue) {
          return;
        }
        if ($scope.oldValue === newValue) {
          $scope.sortType = $scope.oldValue;
          $scope.dummyItems = $filter('orderBy')($scope.dummyItems,
            newValue, true);
          $scope.sortReverse = !$scope.sortReverse;
          newValue = '';
        } else {
          $scope.sortReverse = false;
          $scope.sortType = newValue;
          $scope.dummyItems = $filter('orderBy')($scope.dummyItems,
            newValue);
        }
        $scope.dwtPager = {};
        $scope.setPageDWT($scope.currentPageDwt);
        $scope.oldValue = newValue;
      };

      if ($scope.productTypes.length > 0 && $scope.productTypes.indexOf('DWT') !== -1) {
        $scope.showDWTActions = true;
        $scope.loadingDwt = true;
        var pageNumber = 1;
        var size = 500;
        generalMeetingService.getDwtMeetingsAction(pageNumber, size).get().$promise.then(
          function (data) {
            $scope.loadingDwt = false;
            $scope.oldValue = 'qrfEndDate';
            $scope.dummyItems = $filter('orderBy')(data,
              'qrfEndDate');
            $scope.dwtPager = {};
            $scope.setPageDWT(dwtPage);
            $scope.loadingDwt = false;
          }, function (error) {
            $scope.loadingDwt = false;
            return error;
          });
      }
      // make the spinner flag false if the product is not available for the logged in user-
      if ($scope.productTypes.length > 0 && !($scope.productTypes.indexOf('DWT') !== -1)) {
        $scope.loadingDwt = false;
      }
      //Below function is for pagination, this calls from HTML on clicking of each page
      $scope.setPage = function (gmPage) {
        $scope.currentPageGm = gmPage;
        $scope.pager = $scope.pager || [];
        if (gmPage < 1 || gmPage > $scope.pager.totalPages) {
          return;
        }

        // get pager object from service
        $scope.pager = PaginationSvc.GetPager(
          $scope.gmDummyItems.length, gmPage);
        $log.debug($scope.pager);

        // get current page of items
        $scope.generalMeeting = $scope.gmDummyItems.slice(
          $scope.pager.startIndex, $scope.pager.endIndex + 1);
        $scope.beginIndex = $scope.pager.startIndex;
        $scope.pageNo = gmPage;
      };

      //Below function is for pagination, this calls from HTML on clicking of each page
      $scope.setPageDWT = function (dwtPage) {
        $scope.currentPageDwt = dwtPage;
        $scope.dwtPager = $scope.dwtPager || [];
        if (dwtPage < 1 || dwtPage > $scope.dwtPager.totalPages) {
          return;
        }

        // get pager object from service
        $scope.dwtPager = PaginationSvc.GetPager(
          $scope.dummyItems.length, dwtPage);
        $log.debug($scope.dwtPager);

        // get current page of items
        $scope.dwtEvents = $scope.dummyItems.slice(
          $scope.dwtPager.startIndex, $scope.dwtPager.endIndex + 1);
        $scope.beginIndex = $scope.dwtPager.startIndex;
        $scope.pageNo = dwtPage;
      };
      $scope.groupId = $sessionStorage.groupId;


      $scope.selectedActionDWT = function (meeting) {
        $sessionStorage.productType = 'DWT';
        if (appConstant.APPROVE_EVENT === meeting.actions ||
          appConstant.Submit_QRF_or_PE_Claims === meeting.actions || appConstant.VIEW_EVENT === meeting.actions) {
          //If clicked on "Approve event" link or If clicked on 'Submit QRF or PE Claims' or If clicked on 'View Event'
          $state.go('dwtDetails', {
            eventId: meeting.eventId,
            selectedIndex: $scope.taxReclaimIndexId,
            eventName: meeting.eventName,
            eventStatus: meeting.status,
            issuerId: meeting.issuerId,
            issuerName: meeting.issuerName,
            dwtPerSecurity: meeting.dwtPerSecurity,
            qrfEndDate: meeting.qrfEndDate,
            peFlag: meeting.peFlag,
            qrfFlag: meeting.qrfFlag,
            extensionReqFlagForQRF: meeting.extensionReqFlagForQRF,
            extensionReqFlagForPE: meeting.extensionReqFlagForPE,
            navFrom: 'home',
            extReqFlag: meeting.extensionReqFlag,
            shareholderId: meeting.shareholderId,
            breadCrumbDetails: [meeting.eventName]
          });
        }
        else if (appConstant.REMIND_DWT === meeting.actions) {
          //If clicked on 'Remind Issuer' Will be redirected to user list of issuer
          $state.go('userMaintenanceForDWT.issuerUserListDwt', {
            groupId: meeting.issuerId
          });
        }
        else if (appConstant.SUBMIT_QRF_CLAIMS === meeting.actions ||
          appConstant.CHECK_PE_CLAIMS === meeting.actions) {
          //If clicked on 'Submit the QRF claims' link or  clicked on 'Check  PE Claims' link
          $state.go('dwtDetails', {
            eventId: meeting.eventId,
            selectedIndex: $scope.taxReclaimIndexId,
            eventName: meeting.eventName,
            eventStatus: meeting.status,
            issuerId: meeting.issuerId,
            issuerName: meeting.issuerName,
            dwtPerSecurity: meeting.dwtPerSecurity,
            qrfEndDate: meeting.qrfEndDate,
            flag: 'claims',
            peFlag: meeting.peFlag,
            qrfFlag: meeting.qrfFlag,
            navFrom: 'home',
            extReqFlag: meeting.extensionReqFlag,
            shareholderId: meeting.shareholderId,
            breadCrumbDetails: [meeting.eventName]
          });
        }
      };
    }]);
