import getTermsOfUseUrl from '@/lib/getTermsOfUseUrl';

/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.virtualParticipationCtrl
 * @description This controller deals with fetching virtual participation request
 * @requires userGroupService, and attendanceRequestService are the
 *           services which are being used to interact with REST
 * @requires $scope for storing information in scope
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for fetching logged in user details from session storage
 */
angular.module('intermediaryModule').controller('virtualParticipationCtrl', [
  '$scope',
  '$log',
  '$state',
  '$filter',
  'userGroupService',
  'generalMeetingService',
  'ExtensionRequestService',
  'ModelWindowService',
  '$stateParams',
  'attendanceRequestService',
  'extReqResults',
  'voteInstructionService',
  '$sessionStorage',
  '$timeout',
  function (
    $scope,
    $log,
    $state,
    $filter,
    userGroupService,
    generalMeetingService,
    ExtensionRequestService,
    ModelWindowService,
    $stateParams,
    attendanceRequestService,
    extReqResults,
    voteInstructionService,
    $sessionStorage,
    $timeout
  ) {
    // -- StateParam input --
    var breadCrumbDetails = $stateParams.breadCrumbDetails;

    var gmId = $stateParams.gmId;
    var issuerId = $stateParams.issuerId;

    var generalMeetingDateMillis = $stateParams.generalMeetingDateMillis;
    var intermediaryDeadlineDateMillis =
      $stateParams.intermediaryDeadlineDateMillis;
    var intermediaryAccessDateMillis =
      $stateParams.intermediaryAccessDateMillis;
    var timeZone = $stateParams.timeZone;

    var generalMeetingDateStr = $filter('cbpDate')(
      generalMeetingDateMillis,
      timeZone,
      true
    );
    var intermediaryDeadlineDateStr = $filter('cbpDate')(
      intermediaryDeadlineDateMillis,
      timeZone,
      true
    );
    var intermediaryAccessDateStr = $filter('cbpDate')(
      intermediaryAccessDateMillis,
      timeZone,
      true
    );

    $scope.issuerName = $stateParams.issuerName;
    // --

    var intermediaryId = $sessionStorage.groupId;
    var userId = $sessionStorage.userId;

    $scope.pageSize = appConstant.PAGE_SIZE;
    $scope.pageNo = 1;
    $scope.vpStatus = appConstant.PENDING;
    var sortingOrder = appConstant.ASCENDING;
    var sortBy = appConstant.MODIFIED_DATETIME;
    $scope.tableHeaders = [];
    $scope.recordsData = [];
    $scope.extnRequestModelConfig = {};
    $scope.NoOfSharesErrorConfig = {};
    $scope.eventType = $filter('translate')('label_footerTermOfUse');
    $scope.productTypeToU = $filter('translate')('label_gmProductType');
    $scope.fileloading = true;
    $scope.disableExtReq = true;
    var todaysDate = '';
    var extReqStatus = '';
    var displayCreateExtn = '';
    var currentDate = '';
    var hasApprovedExtnRequest = '';
    var searchParam = '';
    var searchEvent = false;
    var extReqRejected = '';
    if (extReqResults.code) {
      extReqStatus = extReqResults.code;
    } else {
      extReqStatus = extReqResults.data.errors[0].errorCode;
    }

    var initExtRequestState = function () {
      generalMeetingService
        .getCurrentCETTime()
        .query()
        .$promise.then(function (data) {
          // Validate Intermediary deadline date and current date to toggle extension request button
          currentDate = data[0].currentMillis;

          //Service call for fetching extension request status is move inside timestamp service as there is dependency on currentDate
          ExtensionRequestService.getIntermediaryExtnReqStatus(
            gmId,
            $sessionStorage.groupId
          )
            .query()
            .$promise.then(
              function (data) {
                // Validate extension request status, validate intermediary deadline dates and general meeting date
                validateExtensionRequest(data);
              },
              function (error) {
                $scope.disableExtReq = true;
              }
            );
        });
    };

    // Validate extension request status, validate intermediary deadline dates and general meeting date
    var validateExtensionRequest = function (data) {
      if (
        data.isApprovedER === 'false' &&
        intermediaryDeadlineDateMillis < currentDate &&
        generalMeetingDateMillis > currentDate
      ) {
        $scope.disableExtReq = false;
        displayCreateExtn = true;
        hasApprovedExtnRequest = false;
      } else {
        $scope.disableExtReq = true;
        displayCreateExtn = false;
        hasApprovedExtnRequest = true;
      }
    };

    // Get list count on load with search parameter
    var virtualParticipationCount = function (searchParam) {
      attendanceRequestService
        .virtualParticipationSearchCount(gmId, intermediaryId, searchParam)
        .query()
        .$promise.then(
          function (data) {
            $scope.statusCount = data;
            $scope.fileloading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.fileloading = false;
          }
        );
    };

    function getVirtualParticipationRecords(
      gmId,
      intermediaryId,
      pageNo,
      pageSize,
      status,
      sortDirection,
      sortBy,
      searchParam,
      searchEvent
    ) {
      searchParam = searchParam === undefined ? '' : searchParam;
      $scope.pageNo = pageNo;
      if (searchEvent) {
        virtualParticipationCount(searchParam);
      }
      $scope.fileloading = true;
      attendanceRequestService
        .getVirtualParticipationRecords(
          gmId,
          intermediaryId,
          pageNo,
          pageSize,
          status,
          sortDirection,
          sortBy,
          searchParam
        )
        .query()
        .$promise.then(
          function (data) {
            $scope.recordsData = data.list;
            $scope.totalRecordCount = data.totalElements;
            $scope.fileloading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.fileloading = false;
          }
        );
    }

    // call this method on load and after accept and reject
    var loadVirtualParticipationCountAndRecords = function (
      searchParam,
      searchFlag
    ) {
      //call this method to get count for different status on load
      virtualParticipationCount(searchParam);

      // Call this method and followed by service call to get the list of requests
      getVirtualParticipationRecords(
        gmId,
        intermediaryId,
        $scope.pageNo,
        $scope.pageSize,
        $scope.vpStatus,
        sortingOrder,
        sortBy,
        searchParam,
        searchFlag
      );
    };

    // Modal input data with intermediary access date, deadline data and show model window
    var validateExtensionRequestStatus = function (
      todaysDate,
      registrationDetails,
      status
    ) {
      // call this method to set dispay extn flag
      setDisplayCreateExtnFlag(todaysDate);

      if (intermediaryAccessDateMillis > todaysDate) {
        $scope.extnRequestModelConfig.modalHeader = '';
        $scope.extnRequestModelConfig.modalBody =
          $filter('translate')('label_youCanAttend') +
          intermediaryAccessDateStr;
        ModelWindowService.showModelWindow('extReqAlertBox');
      } else if (
        displayCreateExtn === true &&
        (extReqStatus === 'CBP_MSG_51' ||
          extReqStatus === 'CBP_MSG_43' ||
          extReqResults.status === '422' ||
          (extReqStatus === 'CBP_ERR_134' && !hasApprovedExtnRequest))
      ) {
        extReqRejected = true;
        $scope.extnRequestModelConfig.modalHeader = $filter('translate')(
          'label_modalAttReqActionErrMsg'
        );
        $scope.extnRequestModelConfig.modalBody = $filter('translate')(
          'label_modalVirtualReqErrMsg'
        );
        ModelWindowService.showModelWindow('extReqAlertBox');
      } else if (
        (!registrationDetails.numberOfSecurities ||
          registrationDetails.numberOfSecurities == 0) && // TODO because the format directive used in the template is returning as string we need to compare it with double = instead of triple, the correct fix is to refactor the format directive to return a number instead of a string
        status !== 'R'
      ) {
        $scope.NoOfSharesErrorConfig.modalBody = $filter('translate')(
          'label_noOfSharesError'
        );
        ModelWindowService.showModelWindow('noOfSharesBox');
      } else {
        // call the method to update the shareholder registration details PUT object and lebals to display the confirmation pop
        setConfirmationPopWindowAndRegistrationDetailObject(
          registrationDetails,
          status
        );
      }
    };

    // set flag to set extension request flag and variable
    var setDisplayCreateExtnFlag = function (todaysDate) {
      if ($scope.disableExtReq == false) {
        displayCreateExtn = true;
      } else {
        if (intermediaryDeadlineDateMillis < todaysDate) {
          displayCreateExtn = true;
        } else {
          displayCreateExtn = false;
        }
      }
    };

    // call the method to update the shareholder registration details PUT object and lebals to display the confirmation pop
    var setConfirmationPopWindowAndRegistrationDetailObject = function (
      registrationDetails,
      status
    ) {
      $scope.type = 'v_attend';
      $scope.reqId = registrationDetails.reqId;
      $scope.shareClassId = registrationDetails.shareClassId;
      $scope.shareholderId = registrationDetails.shareholderId;
      $scope.status = 'A';
      $scope.currentStatus = registrationDetails.status;
      $scope.noOfSecurities = registrationDetails.numberOfSecurities;
      $scope.rightToSpeakForVote = registrationDetails.rightToSpeakForVote;
      if (status === 'A') {
        $scope.status = status;
        downloadToU();
        $scope.showTcModal = true;
      } else if (status === 'R') {
        $scope.status = status;
        $scope.statusAction = $filter('translate')('label_reject');
        //$scope.modalBody = '';
        ModelWindowService.showModelWindow('rejectAlertBox');
      }
    };

    function downloadToU() {
      $scope.termsOfUseUrl = getTermsOfUseUrl(
        $scope.productTypeToU,
        $sessionStorage.workstationData.selectedLang
      );
    }

    /**
     * On click functions starts here
     * --------------------------------------------------------------------------------------------
     */

    // Extension request page
    $scope.viewExtensionRequest = function () {
      extReqRejected = true;
      $state.go('createIntermediaryExtensionRequest', {
        intermediaryDeadlineDateStr: intermediaryDeadlineDateStr, // Needs to have the API string format "27 Jan 2019 12:00 GMT", TODO refactor to make use of millis
        gmName: breadCrumbDetails[0],
        gmDateTime: $filter('date')(
          generalMeetingDateMillis,
          'yyyy-MM-dd HH:mm:ss'
        ), // Needs to have the API string format, TODO refactor to make use of millis
        generalMeetingId: gmId,
        extReqRejected: extReqRejected,
        issuerId: issuerId,
        userType: $scope.userType,
        gmStatus: $scope.gmStatus,
        allExtnReqRejected: $scope.allExtnReqRejected,
        breadCrumbDetails: [breadCrumbDetails[0]],
        issuerName: $scope.issuerName,
      });
    };

    //below is the function call to get issuer Logo
    var getIssuerLogo = function (issuerId) {
      $scope.fileloading = true;
      userGroupService.getIssuerGroupLogo(issuerId).then(
        function (response) {
          $scope.issuerLogoPresent = false;
          $scope.issuerGroupDetails = response.data;
          $scope.fileloading = false;
          // Do not change the below condition. It is for issuer logo display fix. REST response has ""
          if ($scope.issuerGroupDetails.logoBaseStr) {
            $scope.issuerLogoPresent = true;
          }
        },
        function (error) {
          $log.debug(error);
          $scope.fileloading = false;
        }
      );
    };

    // This method happen when user clicks on different status(Pending, Accepted, Rejected and Cancelled) from UI
    $scope.getRecoredByStatus = function (status) {
      $scope.vpStatus = status;
      $scope.pageNo = 1;
      getVirtualParticipationRecords(
        gmId,
        intermediaryId,
        $scope.pageNo,
        $scope.pageSize,
        $scope.vpStatus,
        sortingOrder,
        sortBy,
        searchParam,
        searchEvent
      );
    };

    // Search event triggered on click search icon from virtual participation over view page
    $scope.searchVirtualParticipation = function (searchParamUserInput) {
      // Get the count for different status
      searchParam = searchParamUserInput;
      searchEvent = true;
      getVirtualParticipationRecords(
        gmId,
        intermediaryId,
        $scope.pageNo,
        $scope.pageSize,
        $scope.vpStatus,
        sortingOrder,
        sortBy,
        searchParam,
        searchEvent
      );
    };

    // Search event triggered on key press from virtual participation over view page
    $scope.searchOnEnterKey = function ($event, searchParamUserInput) {
      if ($event.keyCode === 13) {
        // Get the count for different status
        searchParam = searchParamUserInput;
        searchEvent = true;
        getVirtualParticipationRecords(
          gmId,
          intermediaryId,
          $scope.pageNo,
          $scope.pageSize,
          $scope.vpStatus,
          sortingOrder,
          sortBy,
          searchParam,
          searchEvent
        );
      }
    };

    // update shareholder status accept or reject
    $scope.openModal = function (event, registrationDetails) {
      if (!registrationDetails.numberOfSecurities) {
        registrationDetails.numberOfSecurities = '0';
      }

      var status = '';
      switch (event) {
        case 'accept':
          status = 'A';
          break;
        case 'reject':
          status = 'R';
          break;
        default:
          break;
      }

      generalMeetingService
        .getCurrentCETTime()
        .query()
        .$promise.then(function (data) {
          todaysDate = data[0].currentMillis;

          // Validate Intermediary deadline date and current date to toggle extension request button
          // Modal input data with intermediary access date, deadline data and show model window
          validateExtensionRequestStatus(
            todaysDate,
            registrationDetails,
            status
          );
        });
    };

    $scope.getByPage = function (page) {
      getVirtualParticipationRecords(
        gmId,
        intermediaryId,
        page,
        $scope.pageSize,
        $scope.vpStatus,
        sortingOrder,
        sortBy,
        searchParam,
        searchEvent
      );
    };

    // Below is the method to update the status of the request
    $scope.updateShareholderReq = function (
      type,
      reqId,
      status,
      noOfSecurities,
      currentStatus,
      shareClassId,
      shareholderId
    ) {
      $scope.type = type;
      $scope.requestId = reqId;
      var shareHolderdata = {
        meetingId: gmId,
        requestId: $scope.requestId,
        status: $scope.status,
        issueName: $scope.issuerGroupDetails.issuerName,
        gmDate: generalMeetingDateStr,
        rejectionReason: $sessionStorage.rejectReason,
        langCode: 'EN',
        loginUserId: userId,
        noOfSecurities: noOfSecurities,
        currentStatus: currentStatus,
        issuerId: issuerId,
      };
      attendanceRequestService
        .updateVirtualParticipationRegistration(shareHolderdata, 'secureToken')
        .then(
          function (response) {
            $scope.pageNo = 1;

            // call method to update update list and count
            loadVirtualParticipationCountAndRecords(searchParam, searchEvent);

            if (406 === response.data.httpStatusCode) {
              $scope.success = false;
              $scope.error = true;
              $scope.errorMessage = response.data.message;
              $timeout(function () {
                $scope.error = false;
                $scope.errorMessage = '';
              }, 8000);
            } else {
              $scope.success = true;
              $scope.error = false;
              $scope.errorMessage = '';
              $scope.successMessage = response.data.message;
              $timeout(function () {
                $scope.success = false;
                $scope.successMessage = '';
              }, 8000);
            }
          },
          function (error) {
            $log.debug(error);
            $scope.error = true;
            $scope.errorMessage = error.data.errors[0].errorMessage;
            //Below service call is added to send email to agent when the number of shares entered exceeds the total number of securities available
            sendEmailToAgent(
              error,
              'virtual participation',
              'IN',
              shareClassId,
              shareholderId
            );
            $timeout(function () {
              $scope.error = false;
              $scope.errorMessage = '';
            }, 8000);
          }
        );
    };

    var sendEmailToAgent = function (
      error,
      requestType,
      workstationType,
      shareClassId,
      shareholderId
    ) {
      if (error.data.errors[0].errorCode === 'CBP_ERR_520') {
        $scope.EmailData = {
          votingInstType: requestType,
          languageCode: 'EN',
          workStationType: workstationType,
          meetingId: $stateParams.gmId,
          shareClassId: shareClassId,
          intermediaryId: $sessionStorage.groupId,
          issuerId: $stateParams.issuerId,
          loginUserId: shareholderId,
        };
        voteInstructionService
          .sendSharesExceededMail($scope.EmailData, 'secureToken')
          .then(
            function (response) {
              $log.debug(response);
            },
            function (error) {
              $log.debug(error);
            }
          );
      }
    };

    /**
     * --------------------------------------------------------------------------------------------
     * On Click functions ends here
     */

    /**
     * On load functions
     * --------------------------------------------------------------------------------------------
     */

    //Call this service to check the extension request
    initExtRequestState();

    // Call this service to load issuer logo
    getIssuerLogo(issuerId);

    //call this method to get count for different status on load
    loadVirtualParticipationCountAndRecords('', false);

    /**
     * --------------------------------------------------------------------------------------------
     * On load function ends here
     */
  },
]);
