import appConstant from '@/global/constants';

export const getFormValidationErrorMessages = function () {
  let validationMessages = {};
  validationMessages.meetingName = [];
  validationMessages.meetingName.push({
    name: 'required',
    errorMessage: 'Meeting name is required.',
  });
  validationMessages.meetingName.push({
    name: 'pattern',
    errorMessage: 'Meeting name is wrong.',
  });

  validationMessages.meetingWebsite = [];
  validationMessages.meetingWebsite.push({
    name: 'required',
    errorMessage: 'Corporate website is required.',
  });
  validationMessages.meetingWebsite.push({
    name: 'pattern',
    errorMessage: 'Corporate website is wrong.',
  });

  validationMessages.locationName = [];
  validationMessages.locationName.push({
    name: 'required',
    errorMessage: 'Location Name is required.',
  });
  validationMessages.locationName.push({
    name: 'pattern',
    errorMessage: 'Location Name is wrong.',
  });

  validationMessages.locationTicket = [];
  validationMessages.locationTicket.push({
    name: 'required',
    errorMessage: 'Location Ticket is required.',
  });
  validationMessages.locationTicket.push({
    name: 'pattern',
    errorMessage: 'Location Ticket is wrong.',
  });

  validationMessages.street = [];
  validationMessages.street.push({
    name: 'required',
    errorMessage: 'Street is required.',
  });
  validationMessages.street.push({
    name: 'pattern',
    errorMessage: 'Street name is wrong.',
  });

  validationMessages.postalCode = [];
  validationMessages.postalCode.push({
    name: 'required',
    errorMessage: 'Postal Code is required.',
  });
  validationMessages.postalCode.push({
    name: 'pattern',
    errorMessage: 'Postal Code is wrong.',
  });

  validationMessages.postalCode = [];
  validationMessages.postalCode.push({
    name: 'required',
    errorMessage: 'Postal Code is required.',
  });
  validationMessages.postalCode.push({
    name: 'pattern',
    errorMessage: 'Postal Code is wrong.',
  });

  validationMessages.city = [];
  validationMessages.city.push({
    name: 'required',
    errorMessage: 'City is required.',
  });
  validationMessages.city.push({
    name: 'pattern',
    errorMessage: 'City is wrong.',
  });

  validationMessages.prefixNumber = [];
  validationMessages.prefixNumber.push({
    name: 'required',
    errorMessage: 'Prefix Number is wrong.',
  });

  validationMessages.isin = [];
  validationMessages.isin.push({
    name: 'required',
    errorMessage: 'ISIN Number is wrong.',
  });

  validationMessages.shareClassName = [];
  validationMessages.shareClassName.push({
    name: 'required',
    errorMessage: 'Share class name is wrong.',
  });

  validationMessages.totalEntitleVote = [];
  validationMessages.totalEntitleVote.push({
    name: 'required',
    errorMessage: 'Number entitled to vote is wrong.',
  });

  validationMessages.voteRightPerShare = [];
  validationMessages.voteRightPerShare.push({
    name: 'required',
    errorMessage: 'Vote rights per share is wrong.',
  });

  validationMessages.totalVotingRight = [];
  validationMessages.totalVotingRight.push({
    name: 'required',
    errorMessage: 'Total voting right is wrong.',
  });

  validationMessages.maxNumberOfARAllowed = [];
  validationMessages.maxNumberOfARAllowed.push({
    errorMessage: 'The number is wrong.',
  });

  validationMessages.serialNo = [];
  validationMessages.serialNo.push({
    name: 'required',
    errorMessage: 'Serial No is wrong.',
  });

  return validationMessages;
};

// Below function is to create/reset schedule section validation error messages
export const createDateErrorVO = function () {
  let dateErrorVO = {
    dateCheked1: false,
    dateCheked2: false,
    dateCheked3: false,
    dateCheked4: false,
    dateCheked5: false,
    dateCheked6: false,
    dateCheked7: false,
    dateCheked8: false,
    dateCheked9: false,
    validDates: true,
  };
  return dateErrorVO;
};

export const getNewGMData = function () {
  return {
    status: 'D',
    generalMeetingId: null,
    issuerId: null,
    meetingType: null,
    loggedInUserID: null,
    enableVotingResolPos: null,
    reqDisclOfBeneOwner: null,
    postalCode: null,
    meetingWebsite: null,
    languageCode: null,
    supportedLanguages: [],
    gmDetailVOList: [
      {
        languageCode: null,
        locationName: null,
        locationTicket: null,
        street: null,
        city: null,
        country: null,
        meetinName: null,
        meetingTime: null,
      },
    ],
    gmScheduleVO: null,
    gmFeaturesVO: null,
    gmAgendaVO: [],
    gmDocumentVO: [],
    gmShareClassVO: [],
  };
};

export const getEmptyGmScheduleVO = function () {
  return {
    timezone: null,
    convocationDate: null,
    publicationDate: null,
    intermediaryAccessDate: null,
    shareholderAccessDate: null,
    recordDate: null,
    intermediaryDeadlineDate: null,
    registrationReleaseDate: null,
    generalMeetingDate: null,
    shareholderDeadlineDate: null,
  };
};

export const getGmFeaturesVO = function () {
  return {
    splitVotingIntermdWrkst: true,
    rightToSpeak: false,
    shWrkstSecAccDiscReq: true,
    regTickBarcode: false,
    regTickAgentLogo: true,
    regTickIssuerLogo: false,
    regTickIncludePOA: false,
    regTickIncludeMeetingDoc: false,
    virtualMeetingAllowed: false,
    virtualMeetingId: null,
    virtualParticipationJoinDatetime: null,
    disableVirtualParticipationAllowed: true,
  };
};

// Service to verify if user have selected any vote collector checkboxes
export const isAuthorizedVcSelected = function (aVcList) {
  let selectedFound = false;
  angular.forEach(aVcList, function (aVcs) {
    if (
        !aVcs.isDeleted &&
        (aVcs.isIssuer || (!aVcs.isAgent && !aVcs.isIssuer))
    ) {
      selectedFound = true;
    }
  });
  return selectedFound;
};
//
export const allShareClassesVCsChecked = function (shareClasses) {
  let allVcsSelected = false;
  angular.forEach(shareClasses, function (shareClass) {
    if (shareClass.isAuthorizedVcSelected) {
      allVcsSelected = true;
    }
  });
  return allVcsSelected;
};
// Service function for getting supported language array
export const getSupportedLanguageArray = function (
    selectedEnglish,
    selectedDutch,
    selectedFrench,
    selectedGerman,
    selectedSpanish
) {
  let supportedLang = [];
  supportedLang.push({
    index: 1,
    isChecked: selectedEnglish,
    languageCode: appConstant.OPTED_EN,
  });
  supportedLang.push({
    index: 2,
    isChecked: selectedDutch,
    languageCode: appConstant.OPTED_NL,
  });
  supportedLang.push({
    index: 3,
    isChecked: selectedFrench,
    languageCode: appConstant.OPTED_FR,
  });
  supportedLang.push({
    index: 4,
    isChecked: selectedGerman,
    languageCode: appConstant.OPTED_DE,
  });
  supportedLang.push({
    index: 5,
    isChecked: selectedSpanish,
    languageCode: appConstant.OPTED_ES,
  });
  return supportedLang;
};

// Service function for creating gmScheduleVO and gmFeaturesVO data objects
export const getCreateScopeData = function () {
  return {
    postalCode: null,
    meetingWebsite: null,
    gmScheduleVO: {
      timezone: null,
      convocationDate: null,
      publicationDate: null,
      intermediaryAccessDate: null,
      shareholderAccessDate: null,
      recordDate: null,
      intermediaryDeadlineDate: null,
      registrationReleaseDate: null,
      generalMeetingDate: null,
      shareholderDeadlineDate: null,
    },
    gmFeaturesVO: {
      splitVotingIntermdWrkst: true,
      rightToSpeak: false,
      sendReportEuroclear: false,
      shWrkstSecAccDiscReq: true,
      //Below flag is added for linked shareclass meeting changes - SFCBP-2725
      shWrkstLinkMeeting: false,
      regTickBarcode: false,
      regTickAgentLogo: true,
      regTickIssuerLogo: false,
      regTickIncludePOA: false,
      regTickIncludeMeetingDoc: false,
    },
  };
};
// Service function for getting dynamic agenda items
export const getNewDynamicAgendaItems = function (lang) {
  return [
    {
      agendaId: 0,
      index: 0,
      agendaNo: 0,
      serialNo: '',
      isVoteable: false,
      isDeleted: false,
      agendaDetailVOList: [
        {
          languageCode: 'EN',
          agendaExplanation: '',
          agendaTitle: '',
          file: null,
        },
      ],
    },
  ];
};
// Service function for getting additional share classes
export const getNewAdditionalShareClasses = function (lang) {
  return [
    {
      isAuthorizedVcSelected: false,
      enrollId: null,
      shareClassId: null,
      csdId: null,
      prefixNumber: '',
      isin: 'Not Listed',
      totalEntitleVote: '',
      voteRightPerShare: 1,
      maxNumberOfARAllowed: '',
      votingInstructionIntermediary: true,
      votingInstructionShareholder: true,
      votingInstructionShareplanholder: false,
      votingByCorrespondenceShareholder: false,
      votingByCorrespondenceShareplanholder: false,
      virtualParticipationShareholder: false,
      virtualParticipationShareplanholder: false,
      requestAttendenceCardShareholder: true,
      requestAttendenceCardShareplanholder: false,
      regVoteCollectorWrkst: false,
      agentItself: false,
      issuerItself: false,
      scMeetingIntermediaryAssocVOList: [],
      authorizedVoteCollectorList: [],
      shareClassNameVoList: [
        {
          languageCode: lang,
          shareClassName: null,
        },
      ],
    },
  ];
};
// To make changes compatible with lgc:
// ensures that the vote collector list for the share classes returned from the backend
// is supplemented with isDeleted = false.
// voteCollectorList is optional. Is used when
export const ensureVoteCollectorListRemains = function (
    responseShareClasses,
    voteCollectorList
) {
  responseShareClasses.forEach((shareClass) => {
    const authorizedVcIds = shareClass.authorizedVoteCollectorList.map(
        (aVc) => aVc.vcUserIdWoSalt
    );
    const vcList = angular.copy(voteCollectorList);
    shareClass.authorizedVoteCollectorList = vcList.map((vc) => {
      vc.isDeleted = !authorizedVcIds.includes(vc.vcUserIdWoSalt);
      return vc;
    });
  });
  return responseShareClasses;
};

export const prepareAuthorizedVoteCollectors = function (shareClasses) {
  shareClasses.forEach((shareClass) => {
    shareClass.authorizedVoteCollectorList = shareClass.authorizedVoteCollectorList.filter(
        (vc) => !vc.isDeleted
    );
  });
  return shareClasses;
};

export const resetCheckBoxValues = function (shareClass) {
  shareClass.votingInstructionIntermediary = false;
  shareClass.votingInstructionShareholder = false;
  shareClass.votingInstructionShareplanholder = false;
  shareClass.votingByCorrespondenceShareholder = false;
  shareClass.votingByCorrespondenceShareplanholder = false;
  shareClass.virtualParticipationShareholder = false;
  shareClass.virtualParticipationShareplanholder = false;
  shareClass.requestAttendenceCardShareholder = false;
  shareClass.requestAttendenceCardShareplanholder = false;
};

// Service function for getting dynamic additional documents
export const getNewDynamicAdditionalDocuments = function (lang) {
  return [
    {
      meetingDocId: null,
      attachGmNotice: false,
      attachGmWebPage: false,
      attachCard: false,
      meetingDocAttachList: [
        {
          langCode: lang,
          attachedFile: '',
          fileName: '',
          logicalFileName: '',
          file: null,
        },
      ],
    },
  ];
};
// Service function for new agenda
export const addNewAgenda = function (dynamicAgendaItems, lang) {
  let indexPos = dynamicAgendaItems.length - 1;
  return dynamicAgendaItems.push({
    agendaId: null,
    index: indexPos,
    agendaNo: indexPos,
    serialNo: '',
    isVoteable: false,
    isDeleted: false,
    agendaDetailVOList: [
      {
        languageCode: lang,
        agendaExplanation: '',
        agendaTitle: '',
        file: null,
      },
    ],
  });
};
// Service function for adding new document
export const addNewDocument = function (lang) {
  return {
    meetingDocId: null,
    attachGmNotice: false,
    attachGmWebPage: false,
    attachCard: false,
    meetingDocAttachList: [
      {
        langCode: 'EN',
        attachedFile: '',
        fileName: '',
        fileSize: 0,
        logicalFileName: '',
        file: null,
      },
    ],
  };
};

export const reorderAgendaItems = function (agendaItemList, srcIndex, destIndex) {
  for (let k = 0; k < agendaItemList.length; k++) {
    let agendaList = agendaItemList[k];
    let item = agendaList[srcIndex];
    agendaList.splice(srcIndex, 1);
    agendaList.splice(destIndex, 0, item);
    for (let j = 0; j < agendaList.length; j++) {
      agendaList[j].index = j;
      agendaList[j].agendaNo = j;
    }
  }
};
export const prePopulateDate = function (gmScheduleVO, generalMeetingDate) {
  const DAYS = 86400000; //number of milliseconds in a day

  gmScheduleVO.generalMeetingDate = new Date(generalMeetingDate);
  gmScheduleVO.convocationDate = new Date(
      generalMeetingDate - 42 * DAYS
  ).setHours(0, 0, 0, 0);
  gmScheduleVO.publicationDate = new Date(
      generalMeetingDate - 42 * DAYS
  ).setHours(8, 0, 0, 0);
  gmScheduleVO.intermediaryAccessDate = new Date(
      generalMeetingDate - 27 * DAYS
  ).setHours(0, 0, 0, 0);
  gmScheduleVO.shareholderAccessDate = new Date(
      generalMeetingDate - 27 * DAYS
  ).setHours(0, 0, 0, 0);
  gmScheduleVO.shareholderDeadlineDate = new Date(
      generalMeetingDate - 7 * DAYS
  ).setHours(17, 30, 0, 0);
  gmScheduleVO.intermediaryDeadlineDate = new Date(
      generalMeetingDate - 6 * DAYS
  ).setHours(11, 0, 0, 0);
  gmScheduleVO.recordDate = new Date(
      generalMeetingDate - 28 * DAYS
  ).setHours(18, 0, 0, 0);
  gmScheduleVO.registrationReleaseDate = new Date(
      generalMeetingDate - 6 * DAYS
  ).setHours(13, 0, 0, 0);
  return gmScheduleVO;
};

// Below is the function to validate the dates against
// general meeting date, convocation date and others.
export const validateDate = function (gmScheduleVO, dateErrorVO) {
  if (
      gmScheduleVO.registrationReleaseDate >
      gmScheduleVO.generalMeetingDate ||
      new Date(gmScheduleVO.registrationReleaseDate) >
      new Date(gmScheduleVO.generalMeetingDate)
  ) {
    dateErrorVO.dateCheked1 = 1;
    dateErrorVO.validDates = false;
  } else if (
      gmScheduleVO.registrationReleaseDate <=
      gmScheduleVO.convocationDate ||
      new Date(gmScheduleVO.registrationReleaseDate) <=
      new Date(gmScheduleVO.convocationDate)
  ) {
    dateErrorVO.dateCheked1 = 2;
    dateErrorVO.validDates = false;
  } else if (
      gmScheduleVO.registrationReleaseDate <
      gmScheduleVO.shareholderDeadlineDate ||
      new Date(gmScheduleVO.registrationReleaseDate) <
      new Date(gmScheduleVO.shareholderDeadlineDate)
  ) {
    dateErrorVO.dateCheked1 = 3;
    dateErrorVO.validDates = false;
  } else if (
      gmScheduleVO.registrationReleaseDate <
      gmScheduleVO.intermediaryDeadlineDate ||
      new Date(gmScheduleVO.registrationReleaseDate) <
      new Date(gmScheduleVO.intermediaryDeadlineDate)
  ) {
    dateErrorVO.dateCheked1 = 5;
    dateErrorVO.validDates = false;
  }
  if (
      gmScheduleVO.intermediaryDeadlineDate >
      gmScheduleVO.generalMeetingDate ||
      new Date(gmScheduleVO.intermediaryDeadlineDate) >
      new Date(gmScheduleVO.generalMeetingDate)
  ) {
    dateErrorVO.dateCheked2 = 1;
    dateErrorVO.validDates = false;
  } else if (
      gmScheduleVO.intermediaryDeadlineDate <=
      gmScheduleVO.convocationDate ||
      new Date(gmScheduleVO.intermediaryDeadlineDate) <=
      new Date(gmScheduleVO.convocationDate)
  ) {
    dateErrorVO.dateCheked2 = 2;
    dateErrorVO.validDates = false;
  } else if (
      gmScheduleVO.intermediaryDeadlineDate <=
      gmScheduleVO.intermediaryAccessDate ||
      new Date(gmScheduleVO.intermediaryDeadlineDate) <=
      new Date(gmScheduleVO.intermediaryAccessDate)
  ) {
    dateErrorVO.dateCheked2 = 4;
    dateErrorVO.validDates = false;
  }
  if (
      gmScheduleVO.shareholderDeadlineDate >
      gmScheduleVO.generalMeetingDate ||
      new Date(gmScheduleVO.shareholderDeadlineDate) >
      new Date(gmScheduleVO.generalMeetingDate)
  ) {
    dateErrorVO.dateCheked3 = 1;
    dateErrorVO.validDates = false;
  } else if (
      gmScheduleVO.shareholderDeadlineDate <=
      gmScheduleVO.convocationDate ||
      new Date(gmScheduleVO.shareholderDeadlineDate) <=
      new Date(gmScheduleVO.convocationDate)
  ) {
    dateErrorVO.dateCheked3 = 2;
    dateErrorVO.validDates = false;
  } else if (
      gmScheduleVO.shareholderDeadlineDate <=
      gmScheduleVO.shareholderAccessDate ||
      new Date(gmScheduleVO.shareholderDeadlineDate) <=
      new Date(gmScheduleVO.shareholderAccessDate)
  ) {
    dateErrorVO.dateCheked3 = 4;
    dateErrorVO.validDates = false;
  }
  if (
      gmScheduleVO.recordDate > gmScheduleVO.generalMeetingDate ||
      new Date(gmScheduleVO.recordDate) >
      new Date(gmScheduleVO.generalMeetingDate)
  ) {
    dateErrorVO.dateCheked4 = 1;
  } else if (
      gmScheduleVO.recordDate <= gmScheduleVO.convocationDate ||
      new Date(gmScheduleVO.recordDate) <=
      new Date(gmScheduleVO.convocationDate)
  ) {
    dateErrorVO.dateCheked4 = 2;
    dateErrorVO.validDates = false;
  }
  if (
      gmScheduleVO.intermediaryAccessDate >
      gmScheduleVO.generalMeetingDate ||
      new Date(gmScheduleVO.intermediaryAccessDate) >
      new Date(gmScheduleVO.generalMeetingDate)
  ) {
    dateErrorVO.dateCheked5 = 1;
    dateErrorVO.validDates = false;
  } else if (
      gmScheduleVO.intermediaryAccessDate <=
      gmScheduleVO.convocationDate ||
      new Date(gmScheduleVO.intermediaryAccessDate) <=
      new Date(gmScheduleVO.convocationDate)
  ) {
    dateErrorVO.dateCheked5 = 2;
    dateErrorVO.validDates = false;
  }
  if (
      gmScheduleVO.shareholderAccessDate >
      gmScheduleVO.generalMeetingDate ||
      new Date(gmScheduleVO.shareholderAccessDate) >
      new Date(gmScheduleVO.generalMeetingDate)
  ) {
    dateErrorVO.dateCheked6 = 1;
    dateErrorVO.validDates = false;
  } else if (
      gmScheduleVO.shareholderAccessDate <=
      gmScheduleVO.convocationDate ||
      new Date(gmScheduleVO.shareholderAccessDate) <=
      new Date(gmScheduleVO.convocationDate)
  ) {
    dateErrorVO.dateCheked6 = 2;
    dateErrorVO.validDates = false;
  }
  if (
      gmScheduleVO.publicationDate > gmScheduleVO.generalMeetingDate ||
      new Date(gmScheduleVO.publicationDate) >
      new Date(gmScheduleVO.generalMeetingDate)
  ) {
    dateErrorVO.dateCheked7 = 1;
    dateErrorVO.validDates = false;
  } else if (
      gmScheduleVO.publicationDate < gmScheduleVO.convocationDate ||
      new Date(gmScheduleVO.publicationDate) <
      new Date(gmScheduleVO.convocationDate)
  ) {
    dateErrorVO.dateCheked7 = 2;
    dateErrorVO.validDates = false;
  }
  if (
      gmScheduleVO.convocationDate > gmScheduleVO.generalMeetingDate ||
      new Date(gmScheduleVO.convocationDate) >
      new Date(gmScheduleVO.generalMeetingDate)
  ) {
    dateErrorVO.dateCheked8 = 1;
    dateErrorVO.validDates = false;
  }
  return dateErrorVO;
};

