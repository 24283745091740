/**
 * @ngdoc controller
 * @name userMaintenanceModule.ViewIssuerGroupDetailsController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing details of particular issuer group.
 * @requires userGroupService is the service which is being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 */

angular.module('userMaintenanceModule').controller(
  'ViewIssuerGroupDetailsController',
  [
    '$scope',
    '$log',
    '$stateParams',
    'userGroupService',
    function($scope, $log, $stateParams, userGroupService) {
      userGroupService.getViewIssuerGroup($stateParams.groupId, 'GM',
        'EN').query().$promise.then(function(data) {

        $scope.userGroupDetails = data;
      }, function(error) {
        $log.debug(error);
      });
    }]);
