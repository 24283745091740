/**
 * @ngdoc controller
 * @name userMaintenanceModule.EditIssuerGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with editing details of particular issuer group
 * @requires userGroupService and fileUploadSvc are the service which are being
 *           used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter country and translate message from locale file
 * @requires $sessionStorage to get logged in userId from session storage
 */

angular
  .module('userMaintenanceDwtModule')
  .controller('EditIssuerGroupDwtController', [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$sessionStorage',
    'userGroupService',
    '$filter',
    '$timeout',
    'fileUploadSvc',
    'DwtIssuerGroup',
    function (
      $scope,
      $log,
      $state,
      $stateParams,
      $sessionStorage,
      userGroupService,
      $filter,
      $timeout,
      fileUploadSvc,
      DwtIssuerGroup
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.issuerId = $stateParams.groupId;
      $scope.userGroup = 'issuer';
      var userId = $sessionStorage.userId;
      $scope.prodType = $sessionStorage.productType;

      userGroupService
        .getViewIssuerGroup($scope.issuerId, $scope.prodType, 'EN')
        .query()
        .$promise.then(
          function (data) {
            $scope.userGroupDetailsDwt = data;
            $scope.dwtIssuerGroup = new DwtIssuerGroup(
              $scope.userGroupDetailsDwt,
              userId,
              $scope.prodType
            );
          },
          function (error) {
            $log.debug(error);
          }
        );

      /* date picker */
      $scope.openStartDate = function () {
        $scope.startDate.opened = true;
      };
      $scope.openEndDate = function () {
        $scope.endDate.opened = true;
      };
      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };
      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.startDate = {
        opened: false,
      };
      $scope.endDate = {
        opened: false,
      };
      /* date picker end */
      $scope.successCallback = function (data) {
        if (data.data.httpStatusCode === 200) {
          $scope.success = true;
          $scope.errorMsg = null;
          $scope.message = data.data.message;
          $('#successMsg').modal('show');
        } else {
          $scope.message = null;
          $scope.error = true;
          $scope.errorMsg = data.data.errors[0].errorMessage;
          if ('CBP_ERR_494' === data.data.errors[0].errorCode) {
            $scope.ibanPEClass = 'validation ng-invalid ng-dirty';
          } else {
            $scope.ibanPEClass = 'validation';
          }
          if ('CBP_ERR_495' === data.data.errors[0].errorCode) {
            $scope.ibanQRFClass = 'validation ng-invalid ng-dirty';
          } else {
            $scope.ibanQRFClass = 'validation';
          }
        }
      };
      $scope.callAddUser = function () {
        $state.go('userMaintenanceForDWT.addIssuerDwt', {
          userGroupId: $scope.issuerId,
        });
      };
      $scope.callUserList = function () {
        $state.go('userMaintenanceForDWT.issuerGroupDwt', {});
      };
      $scope.errorCallback = function (error) {
        $scope.message = null;
        $scope.error = true;
        $scope.errorMsg = error.data.errors[0].errorMessage;
      };
      // The below function call is to store the data from UI
      // and
      // pass it to REST call for updating user
      $scope.updateUserGroup = function () {
        var uploadUrl = 'v1/issuers/' + $scope.issuerId;
        $scope.error = false;
        if (
          ($scope.logoFile === null || $scope.logoFile === undefined) &&
          ($scope.agreeFile === null || $scope.agreeFile === undefined)
        ) {
          $scope.dwtIssuerGroup.updateGroup(
            null,
            null,
            uploadUrl,
            userId,
            $scope.issuerId,
            $scope.successCallback,
            $scope.errorCallback
          );
        } else if (
          $scope.logoFile !== null &&
          $scope.logoFile !== undefined &&
          ($scope.agreeFile === null || $scope.agreeFile === undefined)
        ) {
          if ($scope.logoFile !== null && $scope.logoFile !== undefined) {
            if (
              'image' !== $scope.logoFile.type.substring(0, 5) ||
              $scope.logoFile.size > 256000
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('imgfileSize_Error');
            } else if ($scope.errorCallback.error == true) {
              $scope.error = true;
              $scope.errorMsg = $scope.errorCallback.errorMsg;
            } else {
              $scope.error = false;
              $scope.dwtIssuerGroup.updateGroup(
                $scope.logoFile,
                null,
                uploadUrl,
                userId,
                $scope.issuerId,
                $scope.successCallback,
                $scope.errorCallback
              );
            }
          }
        } else if (
          ($scope.logoFile === null || $scope.logoFile === undefined) &&
          $scope.agreeFile !== null &&
          $scope.agreeFile !== undefined
        ) {
          if (
            'Invalid Date' === $scope.dwtIssuerGroup.startDate ||
            'Invalid Date' === $scope.dwtIssuerGroup.endDate ||
            '' === $scope.dwtIssuerGroup.startDate ||
            '' === $scope.dwtIssuerGroup.endDate ||
            null === $scope.dwtIssuerGroup.startDate ||
            null === $scope.dwtIssuerGroup.endDate
          ) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('selectDate_error');
          } else {
            if (
              'pdf' !== $scope.agreeFile.type.slice(-3) ||
              $scope.agreeFile.size > 1000000
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('fileSize_1mbError');
            } else {
              if (
                new Date().getTime() > $scope.dwtIssuerGroup.endDate.getTime()
              ) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')(
                  'label_error_regPastDate'
                );
              } else if (
                $scope.dwtIssuerGroup.startDate.getTime() >
                $scope.dwtIssuerGroup.endDate.getTime()
              ) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')(
                  'label_error_regDateRange'
                );
              } else {
                $scope.error = false;
                $scope.dwtIssuerGroup.updateGroup(
                  null,
                  $scope.agreeFile,
                  uploadUrl,
                  userId,
                  $scope.issuerId,
                  $scope.successCallback,
                  $scope.errorCallback
                );
              }
            }
          }
        } else if (
          $scope.logoFile !== null &&
          $scope.logoFile !== undefined &&
          $scope.agreeFile !== null &&
          $scope.agreeFile !== undefined
        ) {
          if (
            'image' !== $scope.logoFile.type.substring(0, 5) ||
            $scope.logoFile.size > 256000
          ) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('imgfileSize_Error');
          } else {
            if (
              'Invalid Date' === $scope.dwtIssuerGroup.startDate ||
              'Invalid Date' === $scope.dwtIssuerGroup.endDate ||
              '' === $scope.dwtIssuerGroup.startDate ||
              '' === $scope.dwtIssuerGroup.endDate ||
              null === $scope.dwtIssuerGroup.startDate ||
              null === $scope.dwtIssuerGroup.endDate
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('selectDate_error');
            } else {
              if (
                'pdf' !== $scope.agreeFile.type.slice(-3) ||
                $scope.agreeFile.size > 1000000
              ) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')('fileSize_1mbError');
              } else {
                if (
                  new Date().getTime() > $scope.dwtIssuerGroup.endDate.getTime()
                ) {
                  $scope.error = true;
                  $scope.errorMsg = $filter('translate')(
                    'label_error_regPastDate'
                  );
                } else if (
                  $scope.dwtIssuerGroup.startDate.getTime() >
                  $scope.dwtIssuerGroup.endDate.getTime()
                ) {
                  $scope.error = true;
                  $scope.errorMsg = $filter('translate')(
                    'label_error_regDateRange'
                  );
                } else {
                  $scope.error = false;
                  $scope.dwtIssuerGroup.updateGroup(
                    $scope.logoFile,
                    $scope.agreeFile,
                    uploadUrl,
                    userId,
                    $scope.issuerId,
                    $scope.successCallback,
                    $scope.errorCallback
                  );
                }
              }
            }
          }
        }
      };
    },
  ]);
