/**
 * @ngdoc controller
 * @name userMaintenanceModule.EditVCGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with editing details of particular vote collector group
 * @requires userGroupService is the service which is being used to interact
 *           with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter country
 * @requires $sessionStorage to get logged in userId from session storage
 * @requires $timeout
 * @requires $uibModal
 */

angular.module('userMaintenanceModule').controller('EditVCGroupController', [
  '$scope',
  '$log',
  '$state',
  '$stateParams',
  '$sessionStorage',
  'userGroupService',
  '$filter',
  '$timeout',
  '$uibModal',
  function (
    $scope,
    $log,
    $state,
    $stateParams,
    $sessionStorage,
    userGroupService,
    $filter,
    $timeout,
    $uibModal
  ) {
    $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

    $scope.vcId = $stateParams.groupId;
    $log.debug('vcId', $scope.vcId);
    $scope.modalBody = $filter('translate')('confirmGroup_editDetails');
    $scope.modalLeftButton = $filter('translate')('label_confirm');
    $scope.modalRightButton = $filter('translate')('label_cancel');
    $scope.userGroup = 'vote collector';
    // Below is the service call to get list of countries
    userGroupService
      .getCountryList('EN')
      .query()
      .$promise.then(
        function (countries) {
          $log.debug('success user view');
          $scope.countries = countries.countries;
          userGroupService
            .getViewVCGroup($scope.vcId, 'GM', 'EN')
            .query()
            .$promise.then(
              function (data) {
                $log.debug('success user view');
                $scope.userGroupDetails = data;
                $scope.selectedCountry = $filter('filter')(
                  $scope.countries,
                  {
                    countryName: $scope.userGroupDetails.country,
                  },
                  true
                );
              },
              function (error) {
                $log.debug(error);
              }
            );
        },
        function (error) {
          $log.debug(error);
        }
      );
    // The below function call is to store the data from UI
    // and pass it to REST call for updating user
    $scope.updateUserGroup = function () {
      let updateUserData = {
        voteCollectorName: $scope.userGroupDetails.groupName,
        userId: $sessionStorage.userId,
        city: $scope.userGroupDetails.city,
        country: $scope.selectedCountry[0].isoCountryCode,
        productCode: 'GM',
        voteCollectorId: $scope.vcId,
        status: $scope.userGroupDetails.status,
      };
      // The editUser in userService calls the REST url for
      // updating user details
      userGroupService.editVCGroup($scope.vcId, updateUserData).then(
        function (response) {
          $scope.success = true;
          $scope.timedout = $uibModal.open({
            templateUrl: 'success-dialog.html',
          });
          $timeout(function () {
            $scope.timedout.close();
            $scope.timedout = null;
            $state.go('userMaintenance.voteCollectorGroup', {
              pageNo: $stateParams.pageNo,
            });
          }, 2000);
        },
        function (error) {
          $scope.error = true;
          $scope.errorMsg = error.data.errors[0].errorMessage;
        }
      );
    };
  },
]);
