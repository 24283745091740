/**
 * @ngdoc controller
 * @name dwtModule.DwtToBeAuthorizedController
 */
'use strict';

angular.module('dwtModule').controller('DwtToBeAuthorizedController', [
  '$scope',
  '$sessionStorage',
  '$state',
  '$stateParams',
  '$log',
  'ViewDwtDetailService',
  '$filter',
  'PaginationSvc',
  '$timeout',
  'dwtFileDownloadSvc',
  function (
    $scope,
    $sessionStorage,
    $state,
    $stateParams,
    $log,
    ViewDwtDetailService,
    $filter,
    PaginationSvc,
    $timeout,
    dwtFileDownloadSvc
  ) {
    $scope.userType = $sessionStorage.usertype;
    $scope.userId = $sessionStorage.userId;
    $scope.groupId = $sessionStorage.groupId;
    $scope.peCLaimsList = [];
    $scope.qrfCLaimsList = [];
    $scope.selectedQRFCount = 0;
    $scope.selectedPECount = 0;
    $scope.claimsListToSubmit = [];
    $scope.pageNo = 1;
    $scope.modalLeftButton = $filter('translate')('label_confirm');
    $scope.modalRightButton = $filter('translate')('label_cancel');
    $scope.modalHeader = $filter('translate')('label_modalConfirmAction');
    $scope.otherFormToolTip =
      '<div>' +
      $filter('translate')('label_ortherFormtooltip1') +
      '</div>' +
      '<br>' +
      '<div>' +
      $filter('translate')('label_ortherFormtooltip3') +
      '</div>' +
      '<br>' +
      '<div>' +
      $filter('translate')('label_ortherFormtooltip2') +
      '</div>';
    $scope.ibFormToolTip =
      '<div>' +
      $filter('translate')('label_ibFormtooltip1') +
      '</div>' +
      '<br>' +
      '<div>' +
      $filter('translate')('label_ibFormtooltip2') +
      '</div>';

    $scope.getDwtToBeAuthorizedData = function (pageNo) {
      if ($scope.eventId) {
        $scope.loading = true;
        ViewDwtDetailService.getDwtToBeAuthorizedData(
          $scope.eventId,
          $scope.userType,
          $scope.groupId,
          pageNo
        ).then(
          function (response) {
            $scope.currency = response.data.claimsToBeAuthorized.currency;
            $scope.totalCount =
              response.data.claimsToBeAuthorized.allClaimsCount;
            if (!$scope.peCLaimsList) {
              $scope.peCLaimsList = response.data.claimsToBeAuthorized.peClaims;
            } else if (
              response.data.claimsToBeAuthorized.peClaims &&
              $scope.peCLaimsList
            ) {
              $scope.peCLaimsList = $scope.peCLaimsList.concat(
                response.data.claimsToBeAuthorized.peClaims
              );
            }
            if (!$scope.qrfCLaimsList) {
              $scope.qrfCLaimsList =
                response.data.claimsToBeAuthorized.qrfClaims;
            } else if (
              response.data.claimsToBeAuthorized.qrfClaims &&
              $scope.qrfCLaimsList
            ) {
              $scope.qrfCLaimsList = $scope.qrfCLaimsList.concat(
                response.data.claimsToBeAuthorized.qrfClaims
              );
            }
            if ($scope.peCLaimsList) {
              $scope.dummyPEItems = $scope.peCLaimsList;
              $scope.pePager = {};
              $scope.setPEPage(1);
            }
            if ($scope.qrfCLaimsList) {
              $scope.dummyQRFItems = $scope.qrfCLaimsList;
              $scope.qrfPager = {};
              $scope.setQRFPage(1);
            }
            $scope.loading = false;
          },
          function (error) {
            if (error.data.httpStatusCode === 404) {
              $scope.peCLaimsList = error.data.claimsToBeAuthorized.peClaims;
              $scope.qrfCLaimsList = error.data.claimsToBeAuthorized.qrfClaims;
              if ($scope.peCLaimsList) {
                $scope.dummyPEItems = $scope.peCLaimsList;
                $scope.pePager = {};
                $scope.setPEPage(1);
              }
              if ($scope.qrfCLaimsList) {
                $scope.dummyQRFItems = $scope.qrfCLaimsList;
                $scope.qrfPager = {};
                $scope.setQRFPage(1);
              }
            }
            $scope.loading = false;
            $log.debug(error);
          }
        );
      }
    };

    $scope.getDwtToBeAuthorizedData(1);

    // below is the function call for PE claims table pagination
    $scope.setPEPage = function (page) {
      $scope.pageNoForPE = page;
      $scope.pePager = [];
      if (page < 1 || page > $scope.pePager.totalPages) {
        return;
      }
      // get pager object from service
      $scope.pePager = PaginationSvc.GetPager($scope.dummyPEItems.length, page);
      // get current page of items
      $scope.peItems = $scope.dummyPEItems.slice(
        $scope.pePager.startIndex,
        $scope.pePager.endIndex + 1
      );
      if ($scope.totalCount / 500 > 1) {
        $scope.pageNo = $scope.pageNo + 1;
        if ($scope.pageNo <= Math.ceil($scope.totalCount / 500)) {
          $scope.getDwtToBeAuthorizedData($scope.pageNo);
        }
      }
    };

    // below is the function call for QRF claims table pagination
    $scope.setQRFPage = function (page) {
      $scope.pageNoForQRF = page;
      $scope.qrfPager = [];
      if (page < 1 || page > $scope.qrfPager.totalPages) {
        return;
      }
      // get pager object from service
      $scope.qrfPager = PaginationSvc.GetPager(
        $scope.dummyQRFItems.length,
        page
      );
      // get current page of items
      $scope.qrfItems = $scope.dummyQRFItems.slice(
        $scope.qrfPager.startIndex,
        $scope.qrfPager.endIndex + 1
      );
      if ($scope.totalCount / 500 > 1) {
        $scope.pageNo = $scope.pageNo + 1;
        if ($scope.pageNo <= Math.ceil($scope.totalCount / 500)) {
          $scope.getDwtToBeAuthorizedData($scope.pageNo);
        }
      }
    };

    //function call to check all the PE claims which are in sent to issuer status
    $scope.checkAllPE = function () {
      $scope.peClaimsNotselectedErrorMsg = '';
      $scope.qrfClaimsNotselectedErrorMsg = '';
      angular.forEach($scope.peCLaimsList, function (peClaim) {
        peClaim.select = $scope.selectAllPEClaims;
        if (peClaim.select === true) {
          $scope.selectedPECount = $scope.peCLaimsList.length;
        } else {
          $scope.selectedPECount = 0;
        }
      });
    };

    //function call to check the particular PE claims which are in sent to issuer status
    $scope.selectPEClaim = function () {
      $scope.peClaimsNotselectedErrorMsg = '';
      $scope.qrfClaimsNotselectedErrorMsg = '';
      $scope.selectAllPE = false;
      var count = 0;
      angular.forEach($scope.peCLaimsList, function (peClaim) {
        if (peClaim.select === true) {
          count++;
        }
      });
      $scope.selectedPECount = count;
    };

    //function call to check all the QRF claims which are in sent to issuer status
    $scope.checkAllQRF = function () {
      $scope.peClaimsNotselectedErrorMsg = '';
      $scope.qrfClaimsNotselectedErrorMsg = '';
      angular.forEach($scope.qrfCLaimsList, function (qrfClaim) {
        qrfClaim.select = $scope.selectAllQrfClaims;
        if (qrfClaim.select === true) {
          $scope.selectedQRFCount = $scope.qrfCLaimsList.length;
        } else {
          $scope.selectedQRFCount = 0;
        }
      });
    };

    //function call to check all the particular claims which are in sent to issuer status
    $scope.selecteQRFClaim = function () {
      $scope.peClaimsNotselectedErrorMsg = '';
      $scope.qrfClaimsNotselectedErrorMsg = '';
      $scope.selectAllQRF = false;
      var count = 0;
      angular.forEach($scope.qrfCLaimsList, function (qrfClaim) {
        if (qrfClaim.select === true) {
          count++;
        }
      });
      $scope.selectedQRFCount = count;
    };

    //function call to submit all PE claims which are in sent to issuer status
    $scope.submitAllPeClaims = function () {
      $scope.authorizeAllFlag = true;
      $scope.peClaimsNotselectedErrorMsg = '';
      $scope.selectAllPE = true;
      $scope.modalPEClaim = true;
      $scope.modalBody = $filter('translate')('label_authorizeAllPEModal');
      $scope.checkAllPE();
      $scope.peClaimIdArray = [];
      angular.forEach($scope.peCLaimsList, function (peClaim) {
        $scope.peClaimIdArray.push(peClaim.claimId);
      });
    };

    //function call to submit all selected PE claims which are in sent to issuer status
    $scope.submitSelectedPeClaims = function () {
      $scope.authorizeAllFlag = false;
      $scope.peClaimsNotselectedErrorMsg = '';
      $scope.peClaimIdArray = [];
      angular.forEach($scope.peCLaimsList, function (peClaim) {
        if (peClaim.select === true) {
          $scope.peClaimIdArray.push(peClaim.claimId);
        }
      });
      if ($scope.peClaimIdArray.length === 0) {
        $scope.peClaimsNotselectedErrorMsg = $filter('translate')(
          'label_claimsNotselected'
        );
      } else {
        $scope.modalPEClaim = true;
        $scope.modalBody = $filter('translate')(
          'label_authorizeSelectedPEModal'
        );
      }
    };

    //function call to submit all QRF claims which are in sent to issuer status
    $scope.submitAllQrfClaims = function () {
      $scope.authorizeAllFlag = true;
      $scope.qrfClaimsNotselectedErrorMsg = '';
      $scope.selectAllQRF = true;
      $scope.modalQRFClaim = true;
      $scope.modalBody = $filter('translate')('label_authorizeAllQRFModal');
      $scope.checkAllQRF();
      $scope.qrfClaimIdArray = [];
      angular.forEach($scope.qrfCLaimsList, function (qrfClaim) {
        $scope.qrfClaimIdArray.push(qrfClaim.claimId);
      });
    };

    //function call to submit all selected QRF claims which are in sent to issuer status
    $scope.submitSelectedQrfClaims = function () {
      $scope.authorizeAllFlag = false;
      $scope.qrfClaimsNotselectedErrorMsg = '';
      $scope.qrfClaimIdArray = [];
      angular.forEach($scope.qrfCLaimsList, function (qrfClaim) {
        if (qrfClaim.select === true) {
          $scope.qrfClaimIdArray.push(qrfClaim.claimId);
        }
      });
      if ($scope.qrfClaimIdArray.length === 0) {
        $scope.qrfClaimsNotselectedErrorMsg = $filter('translate')(
          'label_claimsNotselected'
        );
      } else {
        $scope.modalQRFClaim = true;
        $scope.modalBody = $filter('translate')(
          'label_authorizeSelectedQRFModal'
        );
      }
    };

    //function to download 6166Form or IB92/96 Form
    $scope.downloadClaimForms = function (formType, formId) {
      var downloadUrl =
        'v1/claimforms/' + formId + '/' + formType + '/download';
      var a = document.createElement('a');
      document.body.appendChild(a);
      dwtFileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            var fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          downloadUrl = '';
        },
        function (error) {
          $log.debug(error);
        }
      );
    };

    //function call to invoke the submit claim service
    $scope.submitClaims = function (claimsIdList, claimTypeVal) {
      angular.forEach(claimsIdList, function (claimId) {
        $scope.claimsListToSubmit.push({ claimId: claimId });
      });
      var claimsToSubmit = {
        dwtClaims: $scope.claimsListToSubmit,
        dwtEventId: $scope.eventId,
        loggedInUserId: $scope.userId,
        workstation: $scope.userType,
        userGroupId: $scope.groupId,
        userType: $scope.userType,
        claimType: claimTypeVal,
        authorizeAll: $scope.authorizeAllFlag,
      };
      if ($scope.eventId) {
        $scope.loading = true;
        ViewDwtDetailService.toBeAuthorizeSubmitClaims(
          claimsToSubmit,
          $scope.eventId,
          'secureToken'
        ).then(
          function (response) {
            $scope.loading = false;
            $scope.success = true;
            $scope.failure = false;
            $scope.errorMessage = null;
            $scope.message = response.data.message;
            $scope.selectAllQRF = false;
            $scope.modalPEClaim = false;
            $scope.modalQRFClaim = false;
            $scope.claimsListToSubmit = [];
            $scope.peClaimIdArray = [];
            $scope.qrfClaimIdArray = [];
            $scope.selectedQRFCount = 0;
            $scope.selectedPECount = 0;
            $('#successMsg').modal('show');
            $scope.peCLaimsList = [];
            $scope.qrfCLaimsList = [];
            $scope.pageNo = 1;
            $scope.getDwtToBeAuthorizedData(1);
            $timeout(function () {
              $('#successMsg').modal('hide');
              $('.modal-backdrop').remove();
            }, 2000);
          },
          function (error) {
            $scope.loading = false;
            $scope.success = false;
            $scope.message = null;
            $scope.failure = true;
            $scope.errorMessage = error.data.errors[0].errorMessage;
          }
        );
      }
    };

    //function call on click of delete icon to update modal window
    $scope.deleteModal = function (claimId) {
      $scope.deleteClaimId = claimId;
      $scope.modalDelete = true;
      $scope.modalBody = $filter('translate')('label_modalDeleteBody');
    };

    //function call on click of confirm in modal window  to invoke delete service
    $scope.deleteClaim = function (claimId) {
      $scope.loading = true;
      ViewDwtDetailService.deleteClaim(
        $scope.eventId,
        claimId,
        'secureToken'
      ).then(
        function (response) {
          $scope.loading = false;
          $scope.message = response.data.message;
          $scope.modalDelete = false;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
            $scope.peCLaimsList = [];
            $scope.qrfCLaimsList = [];
            $scope.pageNo = 1;
            $scope.getDwtToBeAuthorizedData(1);
          }, 2000);
        },
        function (error) {
          $scope.loading = false;
          $scope.error = error.data.errors[0].errorMessage;
        }
      );
    };
  },
]);
