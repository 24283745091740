'use strict';

/**
 * @ngdoc controller
 * @name : date picker controller
 * @returns : date picker pop up for schedule section in create general meeting
 * @description : controller to enable date picker in create and edit GM module
 */
angular
  .module('generalMeetingMainModule')
  .controller('createGmDatePickerCtrl', [
    '$scope',
    '$log',
    function ($scope, $log) {
      function disabled(data) {
        var date, mode;
        date = data.date;
        mode = data.mode;
        $log.debug(date);
        $log.debug(mode);
      }
      $scope.inlineOptions = {
        customClass: getDayClass,
      };
      // Setting scope variable to specified date options
      $scope.gmDateOptions = {
        dateDisabled: disabled,
        formatYear: 'yy',
        maxDate: new Date(2120, 5, 22),
        minDate: new Date(),
        showWeeks: false,
      };

      $scope.updateMaxDate = function (maxDateValue) {
        $scope.virtualMeetingDateOptions = {
          dateDisabled: disabled,
          formatYear: 'yy',
          maxDate: new Date(2120, 5, 22),
          minDate: new Date(),
          showWeeks: false,
        };
        $scope.dateOptions = {
          dateDisabled: disabled,
          formatYear: 'yy',
          maxDate: maxDateValue,
          showWeeks: false,
        };
      };
      //setting Maximun and Minimun date for virtual meeting
      $scope.setVirtualMeetingMaxAndMinDate = function (maxDate, minDate) {
        if (maxDate || minDate) {
          $scope.virtualMeetingDateOptions = {
            dateDisabled: disabled,
            formatYear: 'yy',
            maxDate: new Date(maxDate),
            minDate: new Date(minDate),
            showWeeks: false,
          };
        }
      };
      // Setting Minimum Date
      $scope.toggleMin = function () {
        $scope.minDate = $scope.minDate ? null : new Date();
      };
      // Fetching Minimum Date
      $scope.toggleMin();
      // Start of functions for setting popup's opened variable to true
      $scope.open1 = function () {
        $scope.popup1.opened = true;
      };
      $scope.open2 = function () {
        $scope.popup2.opened = true;
      };
      $scope.open3 = function () {
        $scope.popup3.opened = true;
      };
      $scope.open4 = function () {
        $scope.popup4.opened = true;
      };
      $scope.open5 = function () {
        $scope.popup5.opened = true;
      };
      $scope.open6 = function () {
        $scope.popup6.opened = true;
      };
      $scope.open7 = function () {
        $scope.popup7.opened = true;
      };
      $scope.open8 = function () {
        $scope.popup8.opened = true;
      };
      $scope.open9 = function () {
        $scope.popup9.opened = true;
      };
      $scope.updateMaxDate(new Date(2120, 5, 22));
      // End of functions for setting popup's opened variable to true
      // Function for setting Date to given inputs
      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };
      // Initializing format array
      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      // Setting format
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];
      // Start of Initializing Objects for popups
      $scope.popup1 = {
        opened: false,
      };
      $scope.popup2 = {
        opened: false,
      };
      $scope.popup3 = {
        opened: false,
      };
      $scope.popup4 = {
        opened: false,
      };
      $scope.popup5 = {
        opened: false,
      };
      $scope.popup6 = {
        opened: false,
      };
      $scope.popup7 = {
        opened: false,
      };
      $scope.popup8 = {
        opened: false,
      };
      $scope.popup9 = {
        opened: false,
      };
      // End of Initializing Objects for popups
      // Function for getting day class
      function getDayClass(data) {
        var date = data.date,
          mode = data.mode;
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(
              0,
              0,
              0,
              0
            );
            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }
        return '';
      }
      /* date picker */
    },
  ]);
