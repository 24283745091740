/**
 * @ngDoc controller
 * @name intermediaryModule.ViewIntermediaryExtController
 * @description This controller is used for extension request functionality.
 * @requires userGroupService,AgentService is the service which is being used to interact with REST
 * @requires $scope
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is being fetched be service
 * @requires $state for state routing
 * @requires $stateParams for storing/passing information in scope/state
 * @requires $filter to translate messages which are declared in locale files
 * @requires PaginationSvc for pagination
 */
'use strict';

angular
  .module('intermediaryModule')
  .controller(
    'ViewIntermediaryExtController',
    [
      '$scope',
      '$sessionStorage',
      '$stateParams',
      'AgentService',
      'userGroupService',
      '$filter',
      '$log',
      '$state',
      'PaginationSvc',
      '$timeout',
      'ExtensionRequestService',
      function($scope, $sessionStorage,$stateParams,AgentService,userGroupService, $filter,
        $log, $state, PaginationSvc,$timeout,ExtensionRequestService) {
        $scope.extensionRequests = '';
        $scope.issuerId = $stateParams.issuerId;
        $scope.generalMeetingName = $stateParams.gmName;
        $scope.generalMeetingDate = $stateParams.gmDateTime;
        $scope.userType = $stateParams.userType;
        $scope.generalMeetingId = $stateParams.generalMeetingId;
        $scope.extReqRejected = $stateParams.extReqRejected;
        $scope.intermediaryDeadlineDateStr = $stateParams.intermediaryDeadlineDateStr;
        $scope.allExtnReqRejected = $stateParams.allExtnReqRejected;
        $scope.loading = true;
        $scope.gmStatus = $stateParams.gmStatus;
        $scope.disableExtnReq = false;
        var pageNo;
        var count = null;

        //for status buttons
        if ($sessionStorage.selectedLang === 'nl') {
          $scope.statusButtons = ['nl_Pending', 'nl_Accepted',
            'nl_Rejected','nl_Cancelled'];
        } else {
          $scope.statusButtons = ['Pending', 'Accepted',
            'Rejected','Cancelled'];
        }

        ExtensionRequestService.getIntermediaryExtnReqStatus($scope.generalMeetingId,
          $sessionStorage.groupId).query().$promise
          .then(
            function(data) {
              if (data.isApprovedER === 'false') {
                                        	$scope.disableExtnReq = false;
              } else {
                                        	$scope.disableExtnReq = true;
              }
            }, function(error) {
              $scope.disableExtnReq = false;
              $log.debug(error);
            });

        $scope.extnStatusButton = ['P', 'A', 'R','C'];

        // Below is the function used to fetch issuer logo
        userGroupService.getIssuerGroupLogo($scope.issuerId)
          .then(function(response) {
            $scope.issuerLogoDetails = response.data;
          }, function(error) {
            $log.debug(error);
          });

        // Below is the method to hide or show accordian
        $scope.showHideDetails = function(extnReq) {
          extnReq.showDetails = !extnReq.showDetails;
        };

        $scope.getExtensionRequests = function() {
          //to fetch the extension request list
          pageNo = 1;
          AgentService.getAllExtensionRequestForGM(pageNo,$scope.generalMeetingId).query().$promise
            .then(
              function(data) {
                $scope.extensionRequestsList = data;
                $scope.extensionRequests = $filter(
                  'filter')($scope.extensionRequestsList,
                  {
                    status: $scope.extnStatusButton[0]
                  });
                $scope.extReqCount_0 = $filter(
                  'filter')($scope.extensionRequestsList,
                  {
                    status: $scope.extnStatusButton[0]
                  }).length;
                $scope.extReqCount_1 = $filter(
                  'filter')($scope.extensionRequestsList,
                  {
                    status: $scope.extnStatusButton[1]
                  }).length;
                $scope.extReqCount_2 = $filter(
                  'filter')( $scope.extensionRequestsList,
                  {
                    status: $scope.extnStatusButton[2]
                  }).length;
                $scope.extReqCount_3 = $filter(
                  'filter')( $scope.extensionRequestsList,
                  {
                    status: $scope.extnStatusButton[3]
                  }).length;
                $scope.selectedButtonIndex = 0;
                if (data.length > 0) {
                  count = data[0].count;
                  count = count - 500;
                } else {
                  count = 0;
                }
                $scope.dummyItems = $scope.extensionRequests;
                $scope.pager = {};
                $scope.setPage(1);
                $log.debug('success');
                $scope.loading = false;
              }, function(error) {
                $log.debug(error);
                $scope.loading = false;
              });
        };

        $scope.setPage = function(page) {
          $scope.pager = [];
          if($scope.selectedButtonIndex === 0){
            if (page < 1 || page > $scope.pager.totalPages) {
              return;
            }
            if($scope.extReqCount_0 / (page*10) < 1 && count >= 1){
              pageNo = pageNo+1;
              AgentService.getAllExtensionRequestForGM(pageNo,$scope.generalMeetingId).query().$promise.then(function(data) {
                $scope.extensionRequestsList = $scope.extensionRequestsList.concat(data);
                $scope.extensionRequests = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[0]
                  });
                $scope.extReqCount_0 =  $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[0]
                  }).length;
                $scope.extReqCount_1 = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[1]
                  }).length;
                $scope.extReqCount_2 = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[2]
                  }).length;
                $scope.extReqCount_3 = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[3]
                  }).length;
                count = count - 500;
                $scope.dummyItems = $scope.extensionRequests;
                // get pager object from service
                $scope.pager = PaginationSvc.GetPager(
                  $scope.dummyItems.length, page);
                $log.debug($scope.pager);
                // get current page of items
                $scope.items = $scope.dummyItems.slice(
                  $scope.pager.startIndex, $scope.pager.endIndex + 1);
              }, function(error) {
                $log.debug(error);
              });
            }
          }else if($scope.selectedButtonIndex === 1){
            if (page < 1 || page > $scope.pager.totalPages) {
              return;
            }
            if($scope.extReqCount_1 / (page*10) < 1 && count >= 1){
              pageNo = pageNo+1;
              AgentService.getAllExtensionRequestForGM(pageNo,$scope.generalMeetingId).query().$promise.then(function(data) {
                $scope.extensionRequestsList = $scope.extensionRequestsList.concat(data);
                $scope.extensionRequests = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[1]
                  });
                $scope.extReqCount_0 =  $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[0]
                  }).length;
                $scope.extReqCount_1 = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[1]
                  }).length;
                $scope.extReqCount_2 = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[2]
                  }).length;
                $scope.extReqCount_3 = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[3]
                  }).length;
                count = count - 500;
                $scope.dummyItems = $scope.extensionRequests;
                // get pager object from service
                $scope.pager = PaginationSvc.GetPager(
                  $scope.dummyItems.length, page);
                $log.debug($scope.pager);
                // get current page of items
                $scope.items = $scope.dummyItems.slice(
                  $scope.pager.startIndex, $scope.pager.endIndex + 1);
              }, function(error) {
                $log.debug(error);
              });
            }
          }else if($scope.selectedButtonIndex === 2){
            if (page < 1 || page > $scope.pager.totalPages) {
              return;
            }
            if($scope.extReqCount_2 / (page*10) < 1 && count >= 1){
              pageNo = pageNo+1;
              AgentService.getAllExtensionRequestForGM(pageNo,$scope.generalMeetingId).query().$promise.then(function(data) {
                $scope.extensionRequestsList = $scope.extensionRequestsList.concat(data);
                $scope.extensionRequests = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[2]
                  });
                $scope.extReqCount_0 =  $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[0]
                  }).length;
                $scope.extReqCount_1 = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[1]
                  }).length;
                $scope.extReqCount_2 = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[2]
                  }).length;
                $scope.extReqCount_3 = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[3]
                  }).length;
                count = count - 500;
                $scope.dummyItems = $scope.extensionRequests;
                // get pager object from service
                $scope.pager = PaginationSvc.GetPager(
                  $scope.dummyItems.length, page);
                $log.debug($scope.pager);

                // get current page of items
                $scope.items = $scope.dummyItems.slice(
                  $scope.pager.startIndex, $scope.pager.endIndex + 1);
              }, function(error) {
                $log.debug(error);
              });
            }
          }else if($scope.selectedButtonIndex === 3){
            if (page < 1 || page > $scope.pager.totalPages) {
              return;
            }
            if($scope.extReqCount_3 / (page*10) < 1 && count >= 1){
              pageNo = pageNo+1;
              AgentService.getAllExtensionRequestForGM(pageNo,$scope.generalMeetingId).query().$promise.then(function(data) {
                $scope.extensionRequestsList = $scope.extensionRequestsList.concat(data);
                $scope.extensionRequests = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[3]
                  });
                $scope.extReqCount_0 =  $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[0]
                  }).length;
                $scope.extReqCount_1 = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[1]
                  }).length;
                $scope.extReqCount_2 = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[2]
                  }).length;
                $scope.extReqCount_3 = $filter('filter')(
                  $scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[3]
                  }).length;
                count = count - 500;
                $scope.dummyItems = $scope.extensionRequests;
                // get pager object from service
                $scope.pager = PaginationSvc.GetPager(
                  $scope.dummyItems.length, page);
                $log.debug($scope.pager);

                // get current page of items
                $scope.items = $scope.dummyItems.slice(
                  $scope.pager.startIndex, $scope.pager.endIndex + 1);
              }, function(error) {
                $log.debug(error);
              });
            }
          }
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }

          // get pager object from service
          $scope.pager = PaginationSvc.GetPager(
            $scope.dummyItems.length, page);
          $log.debug($scope.pager);

          // get current page of items
          $scope.items = $scope.dummyItems.slice(
            $scope.pager.startIndex,
            $scope.pager.endIndex + 1);
        };
        //on click of button, display the corresponding list based on status
        $scope.buttonClicked = function($index) {

          $scope.selectedButtonIndex = $index;
          $scope.extensionRequests = $filter('filter')(
            $scope.extensionRequestsList, {
              status: $scope.extnStatusButton[$index]
            });
          $scope.dummyItems = $scope.extensionRequests;
          $scope.pager = {};
          $scope.setPage(1);

        };
        //accept or reject extension request modal window
        $scope.approveReject = function(action,
          extReqId,index, meetingName,
          shareholderName, gmDate, issuerName,
          intermediaryId,shareholderPosition) {
          $scope.action = action;
          $scope.extReqId = extReqId;
          $scope.meetingName = meetingName;
          $scope.shareholderName = shareholderName;
          $scope.gmDate = gmDate;
          $scope.issuerName = issuerName;
          $scope.intermediaryId = intermediaryId;
          $scope.shareholderPosition = shareholderPosition;
          if ($scope.action === 'Accept') {
            $scope.modalHeader = $filter('translate')('label_extReq');
            $scope.modalBody = $filter('translate')('label_acceptExtnReqConfirm');
            $scope.modalFooter = '';
            $scope.modalLeftButton = $filter('translate')('label_accept');
            $scope.modalRightButton = $filter('translate')('label_cancel');
            $scope.modalNewEndDate = $filter('translate')('label_new_end_date');
          } else if ($scope.action === 'Reject') {
            $scope.modalHeader = $filter('translate')('label_extReq');
            $scope.modalBody = $filter('translate')('label_rejectExtnReqConfirm');
            $scope.modalFooter = '';
            $scope.modalLeftButton = $filter('translate')('label_reject');
            $scope.modalLeftButton2 = $filter('translate')('label_rejectAll');
            $scope.modalRightButton = $filter('translate')('label_cancel');
          }
        };

        $scope.approveRejectExtn = function(action,
          extReqId) {
          $scope.approveRejectExtensionRequest = {
            'gmId': $stateParams.generalMeetingId,
            'extReqId' : $scope.extReqId,
            'status': action,
            'flag': 'ONE',
            'name': $scope.meetingName,
            'issuerName': $scope.issuerName,
            'date': $scope.gmDate,
            'shareholderName': $scope.shareholderName,
            'intermediaryId': $scope.intermediaryId,
            'userType': $sessionStorage.usertype,
            'shareholderPosition': $scope.shareholderPosition,
            'issuerId': $scope.issuerId
          };
          AgentService.approveRejectForGM(
            $scope.approveRejectExtensionRequest,
            'secureToken', function(response) {
              $log.debug(response);
              $scope.getExtensionRequests();
            }, function(error) {
              $log.debug(error);
            });

        };
        // reject all extension requests belonging to an issuer.
        $scope.approveRejectAllExtn = function(action, extReqId) {
          $scope.approveRejectExtensionRequest = {
            'gmId': $stateParams.generalMeetingId,
            'extReqId': $scope.extReqId,
            'status': action,
            'flag': 'ONE',
            'name': $scope.meetingName,
            'issuerName': $scope.issuerName,
            'date': $scope.gmDate,
            'shareholderName': $scope.shareholderName,
            'intermediaryId': $scope.intermediaryId,
            'userType': $sessionStorage.usertype,
            'shareholderPosition': $scope.shareholderPosition,
            'issuerId': $scope.issuerId
          };
          AgentService.approveRejectForGM(
            $scope.approveRejectExtensionRequest,
            'secureToken', function(response) {
              $log.debug(response);
              $scope.getExtensionRequests();
            }, function(error) {
              $log.debug(error);
            });
        };

        $scope.createExtensionRequest = function(){
          if($scope.allExtnReqRejected === true){
            $scope.cancelExtnSuccess = $filter('translate')('label_notAllowedExtnReq');
            $('#errorMsg').modal('show');
            $timeout(function() {
              $('#errorMsg').modal('hide');
            }, 2000);
          }else{
            $state.go('intermediaryCreateExtensionRequest',
              {intermediaryDeadlineDateStr:$scope.intermediaryDeadlineDateStr,
                gmName:$scope.generalMeetingName,
                gmDateTime:$scope.generalMeetingDate,
                generalMeetingId:$scope.generalMeetingId,
                extReqRejected:$scope.extReqRejected,
                userType:$scope.userType,
                issuerId:$scope.issuerId,
                issuerName:$stateParams.issuerName
              });
          }
        };

        $scope.editPendingExtensionRequest = function(shareholderName,position,reason,extensionRequestId){
          $state.go('intermediaryCreateExtensionRequest',
            {intermediaryDeadlineDateStr:$scope.intermediaryDeadlineDateStr,
              gmName:$scope.generalMeetingName,
              gmDateTime:$scope.generalMeetingDate,
              generalMeetingId:$scope.generalMeetingId,
              extReqRejected:$scope.extReqRejected,
              userType:$scope.userType,
              issuerId:$scope.issuerId,
              shareholderName:shareholderName,
              position:position,
              reason:reason,
              extensionRequestId : extensionRequestId,
              issuerName:$stateParams.issuerName
            });
        };

        $scope.cancelExtensionRequest = function(extReqId){
          AgentService.cancelExtensionRequest(extReqId,
            'secureToken', function(response) {
              $scope.cancelExtnSuccess = $filter('translate')('label_cancelExtnReq');
              $('#successMsg').modal('show');
              $log.debug(response);
              $timeout(function() {
                $('#successMsg').modal('hide');
                $('.modal-backdrop').remove();
                $scope.getExtensionRequests();
              }, 2000);
            }, function(error) {
              $log.debug(error);
            });
        };

        //fetch the list for the first time
        $scope.getExtensionRequests();

      }]);
