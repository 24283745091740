export default {
  controller: [
    '$state',
    function ($state) {
      this.handleBack = () => {
        window.history.back();
      };

      this.handleWorkstationSelection = () => {
        $state.go('home');
      };
    },
  ],
  templateUrl: 'features/modules/login/notFoundPage/notFoundPage.template.html',
};
