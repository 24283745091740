/**
 * @ngdoc controller
 * @name userMaintenanceModule.IssuerUserListController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the list of users belonging to particular issuer group
 * @requires userService is the service which is being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $filter to filter the firstName and to translate messages from locale file
 * @requires PaginationSvc for pagination
 */

angular
  .module('userMaintenanceDwtModule')
  .controller(
    'ShareholderListDwtController',
    [
      '$scope',
      '$log',
      '$stateParams',
      '$filter',
      '$sessionStorage',
      'userService',
      'PaginationSvc',
      function($scope, $log, $stateParams, $filter,
        $sessionStorage, userService, PaginationSvc) {

        $scope.groupId = $stateParams.groupId;
        $scope.shareclassId = $stateParams.shareclassId;
        $scope.groupStatus = $stateParams.status;
        $scope.userId = $sessionStorage.userId;
        $scope.user = 'Shareholder';
        //$scope.loading is used for spinner
        $scope.loading = false;
        //call getUserList from userService to fetch user list belonging to the particular issuer group
        $scope.showShareclassError = false;
        $scope.getDataOnload = function(message) {
          userService
            .getDefaultIsin($scope.groupId,
              $scope.shareclassId, 'DWT', 'EN',
              $scope.userId).query().$promise
            .then(
              function(data) {
                userService.getShareholderList(
                  $scope.groupId, 'DWT', 'EN',
                  $scope.userId).query().$promise
                  .then(
                    function(data) {
                      $log
                        .debug('success user view');
                      $scope.showShareclassError = false;
                      $scope.userData = data.shareHolderViewVO;
                      $scope.dummyItems = data.shareHolderViewVO;
                      $scope.pager = {};
                      $scope.setPage(1);
                      $scope.loading = false;
                      if (undefined !== message) {
                        $scope.success = true;
                        $scope.failure = false;
                        $scope.message = message;
                      }
                    },
                    function(error) {
                      $scope.loading = false;
                      return error;
                    });
              },
              function(error) {
                if ('CBP_ERR_463' === error.data.errors[0].errorCode) {
                  $scope.showShareclassError = true;
                }
              });

        };
        //Below function is to search the users related to entry in that search box
        $scope.onFirstNameChange = function(val) {
          $scope.dummyItems = $filter('filter')($scope.userData,
            {
              shareHolderName: val
            });
          $scope.pager = {};
          $scope.setPage(1);
        };

        $scope.removeShareholder = function(shareholderId,
          shareclassId) {
          userService
            .deleteShareholder(shareholderId, shareclassId,
              $scope.userId, 'secureToken')
            .then(
              function(response) {
                $scope
                  .getDataOnload(response.data.message);
              },
              function(error) {
                $scope.success = false;
                $scope.failure = true;
                $scope.errorMessage = error.data.errors[0].errorMessage;
              });
        };

        //Below function is for pagination, this calls from HTML on clicking of each page
        $scope.setPage = function(page) {
          $scope.pager = [];
          if (page < 1 || page > $scope.pager.totalPages) { return; }

          // get pager object from service
          $scope.pager = PaginationSvc.GetPager(
            $scope.dummyItems.length, page);
          $log.debug($scope.pager);

          // get current page of items
          $scope.viewUserListDwt = $scope.dummyItems.slice(
            $scope.pager.startIndex,
            $scope.pager.endIndex + 1);
        };
        //Below is function call from HTML for modal window.
        $scope.deleteModal = function(userId) {
          $scope.modalHeader = $filter('translate')(
            'label_confirm_delete')
                                + ' ' + $scope.user + '!';
          $scope.modalBody = $filter('translate')(
            'label_delete_confirmation');
          $scope.modalFooter = '';
          $scope.modalLeftButton = $filter('translate')(
            'label_confirm');
          $scope.modalRightButton = $filter('translate')(
            'label_cancel');
          $scope.userId = userId;
        };
        //Below is the function call for deleting an issuer user.
        $scope.deleteUserList = function(userId) {
          $log.debug('delete shareholder');
          userService
            .deleteShareholder(
              userId,
              'DWT',
              'secureToken',
              function(response) {
                $scope.user = 'Shareholder';
                $scope.getDataOnload();
                $scope.message = response.message;
              },
              function(error) {
                $scope.failure = true;
                $scope.errorMessage = error.errors[0].errorMessage;
              });
        };
        // call the function for first time
        $scope.getDataOnload();
      }]);
