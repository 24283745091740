/**
 * @ngdoc controller
 * @name userMaintenanceModule.ViewUserController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the individual user details.
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 */

angular.module('userMaintenanceModule').controller(
  'ViewUserController',
  [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    'userService',
    '$sessionStorage',
    function($scope, $log, $state, $stateParams, userService,
      $sessionStorage) {

      $scope.userId = $stateParams.userId;
      $scope.userType = $stateParams.userType;
      if($scope.userType === 'AG') {
        $scope.groupId = $sessionStorage.groupId;
      } else {
        $scope.groupId = $stateParams.groupId;
      }
      var productCode = 'GM';
      $scope.groupName = $stateParams.breadCrumbDetails[0];
      // Below is the call to interact with service and get user
      // details.
      userService.getViewUser($scope.userId, $scope.userType,
        $scope.groupId, productCode).query().$promise
        .then(function(data) {
          $log.debug('success user view');
          $scope.viewUserDetails = data;

        }, function(error) {
          $log.debug(error);
        });

    }]);
