import {
  pr_time_calendar as createGmIcon,
  pr_time_stopwatch_running as extensionRequestIcon,
  pr_profiles_family as userManagementIcon,
  pr_documents_search as auditTemplateIcon,
  pr_calendar as liveMeetingsOverviewIcon,
} from '@aab/sc-aab-icon-set';

/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.GmListController
 * @description This controller deals with fetching general meeting list
 * @requires generalMeetingService, and generalMeetingDataService are the
 *           services which are being used to interact with REST
 * @requires $scope for storing information in scope
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for fetching logged in user details from session storage
 */
angular.module('generalMeetingHomeModule').controller('GmListController',
  ['$scope', '$log', '$filter', 'AgentService', 'ModelWindowService',
    '$state','generalMeetingService', '$sessionStorage',
    function ($scope, $log, $filter, AgentService, ModelWindowService, $state,
      generalMeetingService, $sessionStorage) {

      $scope.createGmIcon = createGmIcon;
      $scope.extensionRequestIcon = extensionRequestIcon;
      $scope.userManagementIcon = userManagementIcon;
      $scope.auditTemplateIcon = auditTemplateIcon;
      $scope.liveMeetingsOverviewIcon = liveMeetingsOverviewIcon;

      $scope.userType = $sessionStorage.usertype;
      $sessionStorage.isAdmin = $sessionStorage.isAdminGM;
      $scope.isAdmin = $sessionStorage.isAdminGM;
      $scope.loading = true;
      $scope.generalMeetingData = [];
      $scope.pageSize = appConstant.PAGE_SIZE;
      $scope.searchParam = '';
      $scope.pageNumber = 1;
      $scope.status = appConstant.LIVE;
      if ($sessionStorage.gmParams) {
        $scope.status = $sessionStorage.gmParams.gmStatus;
        $scope.pageNumber = $sessionStorage.gmParams.gmPageNumber;
        $scope.searchParam = $sessionStorage.gmParams.gmSearchParam;
      }else{
        $sessionStorage.gmParams = {};
      }
      //TO-DO change the sortBY to var instead of attaching to scope
      if($sessionStorage.gmSortParams && $sessionStorage.gmSortParams.gmSortBy){
        $scope.sortDirection = $sessionStorage.gmSortParams.gmSortDirection;
        $scope.sortBy = $sessionStorage.gmSortParams.gmSortBy;
        $scope.sortItem = $scope.sortBy;
      }else{
        $sessionStorage.gmSortParams = {};
        $scope.sortBy = appConstant.GENERAL_MEETING_DATE;
        $scope.sortDirection = appConstant.ASCENDING;
      }
      $scope.modalLeftButton = $filter('translate')('label_confirm');
      $scope.modalRightButton = $filter('translate')('label_cancel');
      $scope.inviteShareplanModelConfig = {};

      $scope.searchMeetings = function (searchParam) {
        getGeneralMeetings(1, $scope.pageSize, appConstant.ASCENDING,
          appConstant.GENERAL_MEETING_DATE, searchParam, true);
      };

      $scope.searchOnEnterKey = function ($event, searchParam) {
        if ($event.keyCode === 13) {
          $scope.searchMeetings(searchParam);
        }
      };

      $scope.getByStatus = function (status) {
        $scope.status = status;
        getGeneralMeetings(1, $scope.pageSize, $scope.sortDirection,
          $scope.sortBy, $scope.searchParam, false);
      };

      $scope.getByPage = function (page) {
        getGeneralMeetings(page, $scope.pageSize, $scope.sortDirection,
          $scope.sortBy, $scope.searchParam, false);
      };

      $scope.setSortBy = function (column, sortDirection) {
        $scope.sortDirection = sortDirection;
        $scope.sortBy = column;
        $sessionStorage.gmSortParams.gmSortDirection = sortDirection;
        $sessionStorage.gmSortParams.gmSortBy = column;
        getGeneralMeetings($scope.pageNumber, $scope.pageSize, sortDirection,
          column, $scope.searchParam, false);
      };

      $scope.performAction = function (event, generalMeeting) {
        switch (event) {
        case 'edit':
          actionEdit(generalMeeting);
          break;
        case 'delete':
          actionDelete(generalMeeting);
          break;
        case 'cancel':
          actionCancel(generalMeeting);
          break;
        case 'notification':
          actionNotification(generalMeeting);
          break;
        case 'share':
          actionShare(generalMeeting);
          break;
        case 'createExtensionRequest':
          actionCreateExtensionRequest(generalMeeting);
          break;
        case 'sendEmailEuroclear':
        case 'reSendEmailEuroclear':
          actionEmailEuroClear(generalMeeting);
          break;
        default:
          break;
        }
      };
      //Below function is for deleting general meeting
      $scope.deleteGeneralMeeting = function (meetingId, status) {
        generalMeetingService.deleteGeneralMeeting(meetingId, status,
          'secureToken', function () {
            getGeneralMeetings($scope.pageNumber, $scope.pageSize,
              $scope.sortDirection, $scope.sortBy, $scope.searchParam,true);
          }, function (error) {
            $log.debug(error);
          });
      };

      // call the function for first time
      getStatusCounts($scope.searchParam);
      getGeneralMeetings($scope.pageNumber, $scope.pageSize,
        $scope.sortDirection, $scope.sortBy, $scope.searchParam);

      //-- Private functions --

      function getGeneralMeetings(pageNumber, pageSize,
        sortDirection, sortBy, searchParam, searchEvent) {
        searchParam = (searchParam === undefined) ? '' : searchParam;
        $scope.searchParam = searchParam;
        $scope.pageNumber = pageNumber;
        $sessionStorage.gmParams.gmPageNumber = pageNumber;
        $sessionStorage.gmParams.gmSearchParam = $scope.searchParam;
        $sessionStorage.gmParams.gmStatus = $scope.status;
        if (searchEvent) {
          getStatusCounts($scope.searchParam);
        }
        $scope.loading = true;
        generalMeetingService.getGeneralMeetings(pageNumber, pageSize,
          $scope.status, sortDirection, sortBy,
          searchParam).query().$promise
          .then(
            function (data) {
              $scope.generalMeetingData = data.list;
              $scope.totalRecordCount = data.totalElements;
              $scope.loading = false;
            }, function (error) {
              $scope.loading = false;
              $log.debug(error);
            });
      }

      function getStatusCounts(searchParam) {
        generalMeetingService.getGeneralMeetingsStatusCount(
          searchParam).query().$promise.then(
          function (data) {
            $scope.statusCount = data;
          }, function (error) {
            $scope.loading = false;
            $log.debug(error);
          });
      }

      function actionEdit(generalMeeting) {
        $state.go('editGM', {
          selectedIndex: 1,
          issuerId: generalMeeting.issuerId,
          meetingStatus: generalMeeting.generalMeetingId,
          tabId: null,
          breadCrumbDetails: [generalMeeting.meetingName]
        });
      }

      function actionDelete(generalMeeting) {
        $scope.deleteGmID = generalMeeting.generalMeetingId;
        $scope.modalBody = $filter('translate')('areSureDelete_modal');
        $scope.actionStatus = 'D';
      }

      function actionCancel(generalMeeting) {
        $scope.deleteGmID = generalMeeting.generalMeetingId;
        $scope.modalBody = $filter('translate')('areSureCancel_modal');
        $scope.actionStatus = 'C';
      }

      function actionNotification(generalMeeting) {
        $state.go('notification',
          {
            selectedIndex: 1,
            meetingId: generalMeeting.generalMeetingId,
            breadCrumbDetails: [generalMeeting.meetingName],
            issuerId: generalMeeting.issuerId
          });
      }

      function actionShare(generalMeeting) {
        AgentService
          .importDocFiles(generalMeeting.generalMeetingId)
          .then(
            function (response) {
              $log.debug(response);
              $state.go('inviteSharePlan',
                {
                  selectedIndex: 1,
                  meetingStatus: generalMeeting.generalMeetingId,
                  issuerId: generalMeeting.issuerId,
                  gmDate: generalMeeting.generalMeetingDateStr,
                  issuerName: generalMeeting.issuerName,
                  documents: response.data.gmDocumentVO,
                  emailSubject: response.data.emailVO.emailSubject,
                  emailBody: response.data.emailVO.emailBody,
                  breadCrumbDetails: [generalMeeting.meetingName]
                });
            }, function (error) {
              $log.debug(error);
              if (error.status === 422) {
                $scope.inviteShareplanModelConfig.modalBody = error.data.errors[0].errorMessage;
                ModelWindowService.showModelWindow(
                  'inviteSharePlanAlert');
              }
            });
      }

      function actionCreateExtensionRequest(generalMeeting) {
        $scope.extReqRejected = true;
        $state.go('createExtensionRequest',
          {
            intermediaryDeadlineDateStr: generalMeeting.intermediaryDeadlineDateStr,
            gmName: generalMeeting.meetingName,
            gmDateTime: generalMeeting.generalMeetingDateStr,
            generalMeetingId: generalMeeting.generalMeetingId,
            extReqRejected: $scope.extReqRejected,
            userType: $scope.userType,
            issuerId: generalMeeting.issuerId,
            gmStatus: generalMeeting.status,
            breadCrumbDetails: [generalMeeting.meetingName]
          });
      }

      function actionEmailEuroClear(generalMeeting) {
        $sessionStorage.currentTab = 'sendEmailEuroclear';
        $state.go('generalDetailsView', {
          meetingId: generalMeeting.generalMeetingId,
          issuerId: generalMeeting.issuerId,
          issuerName: generalMeeting.issuerName,
          gmDateTime: generalMeeting.generalMeetingDateStr,
          status: generalMeeting.status,
          sendRegViEuroclear: generalMeeting.sendRegViEuroclear,
          intermediaryDate: generalMeeting.intermediaryDeadlineDate,
          breadCrumbDetails: [generalMeeting.meetingName]
        });
      }
    }]);
