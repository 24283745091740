/**
 * @ngDoc controller
 * @name shareHolderModule.MyVaRegistrationsController
 * @description This controller is used to control the my va registration screen
 * @requires
 */
import moment from 'moment-timezone';
import { formatDate, formatDateTime } from '@/lib/date';

angular.module('shareHolderModule').controller('MyVaRegistrationsController', [
  '$scope',
  '$stateParams',
  '$state',
  '$filter',
  '$sessionStorage',
  'myVoteService',
  'generalMeetingService',
  '$log',
  function (
    $scope,
    $stateParams,
    $state,
    $filter,
    $sessionStorage,
    myVoteService,
    generalMeetingService,
    $log
  ) {
    $scope.showConfirmedVotes = true;
    $scope.selectedRequestStatus = 'ALL';
    $scope.userType = $sessionStorage.usertype;
    $scope.virtualParticipationReqList = [];
    $scope.vpReqListForSearch = [];
    $scope.loadingRequests = false;
    var todaysDate = '';
    var currentDateWithTimeZone = '';
    var VirtualMeetingDateAndTime = '';
    var GmDateTime = '';

    if ($scope.userType !== 'SP') {
      if ($stateParams.selectedRequestStatus) {
        $scope.selectedRequestStatus = $stateParams.selectedRequestStatus;
      }
    }

    $scope.handleRequestTypeRadioSelection = function (value) {
      $sessionStorage.dropdownVal = $scope.selectedRequestStatus;
      $sessionStorage.radioVal = value;
      let indexOfSelectedTab = 0;
      if ($scope.userType === 'SP') {
        indexOfSelectedTab = 1;
      }
      $state.go('shMyVotes', {
        type: value,
        selectedIndex: indexOfSelectedTab,
        filterBy: 'ALL',
        radioVal: value,
      });
    };

    $scope.showIntermediaryDetails = function (identificationNumber, type) {
      myVoteService
        .getIntermediaryDetails(identificationNumber, type)
        .query()
        .$promise.then(
          function (response) {
            $scope.intermediaryName = response.intermediaryName;
            $scope.intermediaryEmail = response.intermediaryEmail;
            $scope.intermediaryPhone = response.intermediaryPhone;
            $('#intermediaryContactDetails').modal('show');
          },
          function (error) {
            $log.debug(error);
          }
        );
    };

    function init() {
      $scope.loadingRequests = true;
      generalMeetingService
        .getCurrentCETTime()
        .query()
        .$promise.then(
          function (data) {
            currentDateWithTimeZone = data;
          },
          function (error) {
            $log.debug(error);
          }
        );

      myVoteService.getVirtualParticipationReqList().then(
        function (response) {
          $scope.virtualParticipationReqList = response.data.list;
          $scope.vpReqListForSearch = response.data.list;
          if (
            $scope.selectedRequestStatus &&
            $scope.selectedRequestStatus !== 'ALL'
          ) {
            $scope.onRequestStatusSelection($scope.selectedRequestStatus);
          }
          $scope.loadingRequests = false;
        },
        function (error) {
          $log.debug(error);
          $scope.loadingRequests = false;
        }
      );
    }

    $scope.getIssuerGroupLogoUrl = function (issuerLogoUrl) {
      return process.env.API_BASE_URL + issuerLogoUrl;
    };

    //Below functions are called to check the status of the request
    $scope.isPending = function (request) {
      if (request && request.status) {
        return request.status === 'P';
      }
      return false;
    };
    $scope.isApproved = function (request) {
      //below function will call to set hover text for join meeting button
      getVirtualMeetingAndGmDateAndTime(request);
      if (request && request.status) {
        return request.status === 'A';
      }
      return false;
    };
    $scope.isRejected = function (request) {
      if (request && request.status) {
        return request.status === 'R';
      }
      return false;
    };
    $scope.isCancelled = function (request) {
      if (request && request.status) {
        return request.status === 'C';
      }
      return false;
    };

    $scope.isNumberOfSharesZero = function (numberOfSecurities) {
      return numberOfSecurities === 0 || numberOfSecurities === '0';
    };

    function filterRequestList(issuerName, selectedRequestStatus) {
      $scope.issuerName = !issuerName ? '' : issuerName;
      if (selectedRequestStatus === 'ALL') {
        $scope.virtualParticipationReqList = $filter('filter')(
          $scope.vpReqListForSearch,
          {
            issuerName: issuerName,
          }
        );
      } else {
        $scope.virtualParticipationReqList = $filter('filter')(
          $scope.vpReqListForSearch,
          {
            issuerName: issuerName,
            status: selectedRequestStatus,
          }
        );
      }
    }

    //Below function is called when a value keyed in search field
    $scope.onSearchByIssuerName = function (issuerName, selectedRequestStatus) {
      if ($scope.vpReqListForSearch.length > 0) {
        filterRequestList(issuerName, selectedRequestStatus);
      }
    };

    //Below function is called when an option is selected in status drop down
    $scope.onRequestStatusSelection = function (selectedRequestStatus) {
      if ($scope.vpReqListForSearch.length > 0) {
        filterRequestList('', selectedRequestStatus);
      }
    };

    //Below function is called to check whether the shareholder deadline date is passed
    $scope.isShDeadlinePassed = function (request) {
      if (request.gmDate.indexOf('CET') > -1 || request.timeZone === 'CET') {
        todaysDate = $filter('filter')(
          currentDateWithTimeZone,
          { timezone: 'CET' },
          true
        );
      } else if (
        request.gmDate.indexOf('GMT') > -1 ||
        request.timeZone === 'GMT'
      ) {
        todaysDate = $filter('filter')(
          currentDateWithTimeZone,
          { timezone: 'GMT' },
          true
        );
      }
      return request.shDeadlineDate < todaysDate[0].currentMillis;
    };

    //Below function is for navigating to dual factor authentication page
    $scope.joinMeeting = function (request) {
      $sessionStorage.issuerId = request.issuerId;
      $sessionStorage.gmId = request.meetingId;
      $sessionStorage.virtualReqId = request.virtualAttendanceRequestId;
      $state.go('2FAuthentication');
    };

    //Below function is for editing virtual attendance request
    $scope.editVirtualParticipationRequest = function (request) {
      $state.go(
        'editVaRequestStep1',
        {
          selectedIndex: 0,
          issuerId: request.issuerId,
          gmId: request.meetingId,
          virtualReqId: request.virtualAttendanceRequestId,
        },
        {
          location: true,
        }
      );
    };
    // when language changes, following piece of code is executed from header-controller.js
    $scope.$on('onLanguageChangeShareHolder', function (event, lang) {
      setJoinButtonHoverText(VirtualMeetingDateAndTime, GmDateTime);
    });
    //Below function is to get Virtual Meeting And GM DateAndTime
    var getVirtualMeetingAndGmDateAndTime = function (response) {
      const virtualMeetingDateAndTime = formatDateTime(
        response.virtualParticipationJoinDateTime,
        response.timeZone
      );
      const gmDateTime = formatDate(response.gmDate);
      setJoinButtonHoverText(virtualMeetingDateAndTime, gmDateTime);
    };

    //below function is to set join button hover text
    var setJoinButtonHoverText = function (virtualMeetingDate, meetingDate) {
      var joinButtonHoverText1 = $filter('translate')(
        'label_join_meeting_disable_hover_text1'
      );
      var joinButtonHoverText2 = $filter('translate')(
        'label_join_meeting_disable_hover_text2'
      );
      var combinedJoinButtonHoverText =
        joinButtonHoverText1 +
        ' ' +
        virtualMeetingDate +
        ' ' +
        joinButtonHoverText2 +
        ' ' +
        meetingDate;
      $scope.joinMeetingDisableHoverText = combinedJoinButtonHoverText;
      $scope.joinMeetingEnableHoverText = $filter('translate')(
        'label_join_meeting_enable_hover_text'
      );
    };

    //checks whether a shareholder can join the meeting
    $scope.canJoinMeeting = function (request) {
      let start = moment(request.virtualParticipationJoinDateTime);
      let end = moment(request.gmDate).endOf('day');

      return moment().isBetween(start, end);
    };

    init();
  },
]);
