/**
 * @ngdoc controller
 * @name userMaintenanceDwtModule.AddIntermediaryDwtController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with adding new intermediary user.
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 */

angular
  .module('userMaintenanceDwtModule')
  .controller('EditPeShareholderDwtController', [
    '$scope',
    '$log',
    '$state',
    '$filter',
    '$stateParams',
    '$sessionStorage',
    '$timeout',
    'userService',
    'DwtShareholderUser',
    'DwtIntermediaryGroup',
    'fileDownloadSvc',
    function (
      $scope,
      $log,
      $state,
      $filter,
      $stateParams,
      $sessionStorage,
      $timeout,
      userService,
      DwtShareholderUser,
      DwtIntermediaryGroup,
      fileDownloadSvc
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.userGroupId = $stateParams.userGroupId;
      $scope.shareholderId = $stateParams.shareholderId;
      $scope.eventId = $stateParams.eventId;
      $scope.userId = $sessionStorage.userId;
      $scope.navFrom = $stateParams.navFrom;

      // Call Constructor of DWT Intermediary User Modal
      userService
        .getShareholder(
          $scope.userGroupId,
          $scope.shareholderId,
          'DWT',
          $scope.userId
        )
        .query()
        .$promise.then(
          function (data) {
            $scope.editUserDetails = new DwtShareholderUser(
              data,
              $scope.userGroupId,
              $scope.userId,
              $scope.shareholderId,
              null,
              null,
              $scope.eventId
            );
          },
          function (error) {
            $log.debug(error);
          }
        );
      $scope.downloadshIntIdPDF = function (shIntId) {
        var downloadUrl =
          'v1/peshareholders/intermediary/entitlement/' +
          shIntId +
          '/' +
          $scope.userId +
          '/download';
        var a = document.createElement('a');
        document.body.appendChild(a);
        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            let fileVO = response;
            if (window.navigator.msSaveOrOpenBlob) {
              a.onclick = function () {
                window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
              };
              a.click();
            } else {
              var fileURL = URL.createObjectURL(fileVO.file);
              a.href = fileURL;
              a.download = fileVO.fileName;
              a.click();
            }
            downloadUrl = '';
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      $scope.successCallback = function (response) {
        $scope.success = true;
        $scope.message = response.data.message;
        if ($scope.navFrom) {
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
            document.getElementById('label_goBack').click();
          }, 2000);
        } else {
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
            $state.go('userMaintenanceForDWT.peShareholderListDwt', {
              groupId: response.data.groupId,
              successMsg: $scope.message,
            });
          }, 2000);
        }
      };
      $scope.errorCallback = function (error) {
        $scope.failure = true;
        if (
          'CBP_ERR_485' === error.data.code ||
          'CBP_ERR_147' === error.data.code
        ) {
          $scope.errorMessage = error.data.message;
        } else if (error.data.errors.length > 0) {
          $scope.errorMessage = error.data.errors[0].errorMessage;
        } else {
          $scope.errorMessage = error.data;
        }
      };

      $scope.successCallbackForIntermedairy = function (response) {
        if (response.status == 200) {
          $scope.successIntermedairy = true;
          $scope.failureIntermedairy = false;
          $scope.messageIntermedairy = response.data.message;
          $scope.editUserDetails.getIntermediaryList();
          $scope.dismissIntermedairyModal();
          $scope.interList = $scope.editUserDetails.intermediaryList;
          angular.forEach($scope.interList, function (value, key) {
            if (value.intermediaryId === '') {
              return (value.intermediaryId = response.data.groupId);
            }
          });
        } else {
          $scope.failureIntermedairy = true;
          $scope.successIntermedairy = false;
          if (response.data.errors.length > 0) {
            $scope.messageIntermedairy = response.data.errors[0].errorMessage;
          } else {
            $scope.messageIntermedairy = response.data;
          }
        }
      };
      $scope.errorCallbackForIntermedairy = function (error) {
        $scope.failureIntermedairy = true;
        $scope.successIntermedairy = false;
        if (error.data.errors.length > 0) {
          $scope.messageIntermedairy = error.data.errors[0].errorMessage;
        } else {
          $scope.messageIntermedairy = error.data;
        }
      };
      $scope.callIntermedairyModal = function () {
        $scope.addInterForm.$setPristine();
        $scope.addIntermediaryGroup = new DwtIntermediaryGroup(
          undefined,
          $scope.userId
        );
        $scope.successIntermedairy = false;
        $scope.failureIntermedairy = false;
        $('#createIntermediary').modal('show');
      };
      $scope.dismissIntermedairyModal = function () {
        $scope.successIntermedairy = false;
        $scope.failureIntermedairy = false;
        $('#createIntermediary').modal('hide');
      };
      $scope.addIntermediaryGroupMethod = function () {
        var userId = $sessionStorage.userId;
        var uploadUrl = 'v1/intermediaries';
        var prodType = $sessionStorage.productType;
        $scope.addIntermediaryGroup.selectedCountry = [];
        $scope.addIntermediaryGroup.selectedCountry[0] = {
          isoCountryCode: 'NLD',
        };
        $scope.addIntermediaryGroup.city = 'N/A';
        $scope.addIntermediaryGroup.bankIdentifierCode = 'N/A';
        $scope.addIntermediaryGroup.ibanNumber = 'N/A';
        $scope.addIntermediaryGroup.validateIBAN = 'N';
        $scope.addIntermediaryGroup.addGroup(
          null,
          null,
          uploadUrl,
          userId,
          prodType,
          $scope.successCallbackForIntermedairy,
          $scope.errorCallbackForIntermedairy
        );
      };

      $scope.editUser = function (intermediaryList) {
        var errorFlag = false;
        var errorCode = '0';
        if (null !== intermediaryList && 0 !== intermediaryList.length) {
          angular.forEach(intermediaryList, function (value) {
            if ('' === value.intermediaryId || null === value.intermediaryId) {
              errorFlag = true;
              errorCode = '1';
            } else {
              if ('0' === value.intermediaryId && !value.intermediaryName) {
                errorFlag = true;
                errorCode = '2';
              }
              if (
                '0' === value.intermediaryId &&
                !value.intermediaryEmailAddress
              ) {
                errorFlag = true;
                errorCode = '3';
              }
            }
          });
          if (errorFlag === false) {
            $scope.editUserDetails.updateUser(
              $scope.successCallback,
              $scope.errorCallback
            );
          } else {
            $scope.failure = true;
            $scope.success = false;
            switch (errorCode) {
              case '1':
                $scope.errorMessage = $filter('translate')(
                  'label_error_emptyIntermediarySH'
                );
                break;
              case '2':
                $scope.errorMessage = $filter('translate')(
                  'label_error_emptyNotListedIntermediarySH'
                );
                break;
              case '3':
                $scope.errorMessage = $filter('translate')(
                  'label_error_emptyIntermediaryEmailSH'
                );
                break;
            }
          }
        } else {
          $scope.failure = true;
          $scope.success = false;
          $scope.errorMessage = $filter('translate')(
            'label_error_intermediaryListSH'
          );
        }
      };
    },
  ]);
