/**
 * @ngdoc controller
 * @name commonModule.tou-controller
 * @description This controller controls the load of tou
 *
 */
'use strict';
angular.module('commonModule').controller(
  'termsAndConditionsCtrl',
  [
    '$scope',
    '$location',
    '$sessionStorage',
    'touService',
    'AuthService',
    function ($scope, $location, $sessionStorage, touService, AuthService) {
      const GM_PRODUCT_TYPE = 'GM';
      const DWT_PRODUCT_TYPE = 'DWT';
      let selectedLanguage = getActiveLanguage();
      let userType = $sessionStorage.usertype;

      if (AuthService.publicURL() && (userType !== 'AG' && userType !== 'IN'
            && userType !== 'IS')) { // Only SH has public pages
        setAvailableTOUOnlyGM();
      } else { // Logged in
        switch (userType) {
        case 'AG':
        case 'IS':
        case 'IN':
          setAvailableTOUBasedOnProductTypes();
          break;
        case 'SH':
        case 'SP':
        case 'VC':
        default:
          setAvailableTOUOnlyGM();
        }
      }

      $scope.$watch('activeProduct', function (value) {
        $scope.touUrl = touService.getTouUrl(value, selectedLanguage);
      });

      $scope.$on('onLanguageSwitch', function () {
        selectedLanguage = getActiveLanguage();
        $scope.touUrl = touService.getTouUrl($scope.activeProduct,
          selectedLanguage);
      });

      $scope.openTou = function (productType) {
        $scope.activeProduct = productType;
      };

      function setAvailableTOUBasedOnProductTypes() {
        let productTypes = $sessionStorage.productTypes;
        let pathname = window.location.pathname;
        $scope.showGmTerms = hasProduct(productTypes, GM_PRODUCT_TYPE);
        $scope.showDwtTerms = hasProduct(productTypes, DWT_PRODUCT_TYPE);
        $scope.activeProduct = getActiveProductType(pathname,
          $scope.showGmTerms, $scope.showDwtTerms);
      }

      function setAvailableTOUOnlyGM() {
        $scope.showGmTerms = true;
        $scope.showDwtTerms = false;
        if ($sessionStorage.usertype === 'AG' || $sessionStorage.usertype
              === 'IN' || $sessionStorage.usertype === 'IS') {
          $scope.showDwtTerms = true;
        }
        $scope.activeProduct = GM_PRODUCT_TYPE;
        $scope.ShowNavBar = false;
      }

      function hasProduct(productTypes, type) {
        let result;

        if (productTypes === undefined) {
          result = null;
        } else {
          result = productTypes.indexOf(type);
          return result > -1;
        }

        return result != null;
      }

      function getActiveProductType(pathname, hasGmProduct, hasDwtProduct) {
        let activeProduct;
        if (hasGmProduct && hasDwtProduct) {
          //TODO change logic to determine active product
          let pathList = pathname.split('/');
          if (pathList[1] === 'taxReclaimHome') {
            activeProduct = DWT_PRODUCT_TYPE;
          } else {
            activeProduct = GM_PRODUCT_TYPE;
          }
        } else if (hasDwtProduct) {
          activeProduct = DWT_PRODUCT_TYPE;
        } else {
          activeProduct = GM_PRODUCT_TYPE;
        }
        return activeProduct;
      }

      function getActiveLanguage() {
        let activeLanguage = $sessionStorage.selectedLang;

        if (activeLanguage === undefined) {
          activeLanguage = $sessionStorage.workstationData.selectedLang;
        }

        return activeLanguage;
      }

    }]);
