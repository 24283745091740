/**
 * @ngdoc controller
 * @name userMaintenanceModule.IntermediaryGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the list of intermediary groups
 * @requires userGroupService is the service which is being used to interact with REST
 * @requires $scope,$sessionStorage for storing/passing information in scope/session
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter the group name and translate messages from locale file
 * @requires PaginationSvc for pagination
 */

angular
  .module('userMaintenanceDwtModule')
  .controller(
    'ViewAllUsersDwtController',
    [
      '$state',
      '$stateParams',
      '$scope',
      '$log',
      '$sessionStorage',
      '$filter',
      'userService',
      'PaginationSvc',
      function($state, $stateParams, $scope, $log,
        $sessionStorage, $filter, userService,
        PaginationSvc) {
        let userId = $sessionStorage.userId;
        let prodType = $sessionStorage.productType;
        let userData = [];
        $scope.message = $stateParams.successMsg;
        $scope.userType = 'IN';
        $scope.productCode = $sessionStorage.productType;
        $scope.intermediary = true;
        $scope.loading = false;

        let getUserList = function() {
          $scope.loading = true;
          userService.getAllUserList(userId, prodType).query().$promise
            .then(function(data) {
              userData = data;
              $scope.pager = {};
              $scope.setPage(1);
              $scope.loading = false;
            }, function(error) {
              $scope.loading = false;
              return error;
            });
        };

        let searchFilter = function(row) {
          let lowerCaseQuery = $scope.query ? $scope.query.toLocaleLowerCase() : '';

          return (row.name.toLocaleLowerCase().indexOf(lowerCaseQuery) !== -1 ||
                            row.emailAddress.toLocaleLowerCase().indexOf(lowerCaseQuery) !== -1);
        };

        //Below function is to search the users related to entry in that search box
        $scope.$watch('query', function(val) {
          if (val != '' && null != val) {
            $scope.showRecordLabel = true;
          } else {
            $scope.showRecordLabel = false;
          }

          $scope.pager = {};
          $scope.setPage(1);
        });

        //Below function is for pagination, it calls from HTML on clicking of each page
        $scope.setPage = function(page) {
          $scope.pager = [];
          if (page < 1 || page > $scope.pager.totalPages) { return; }

          let items = $filter('filter')(userData, searchFilter);

          $scope.searchCount = items.length;

          // get pager object from service
          $scope.pager = PaginationSvc.GetPager(items.length, page);

          // get current page of items
          $scope.viewUserGroup = items.slice($scope.pager.startIndex, $scope.pager.endIndex + 1);
        };

        // call the function for first time
        getUserList();
      }]);
