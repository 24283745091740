/**
 * @ngdoc controller
 * @name userMaintenanceModule.AddUserController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with adding new user.
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $state for state routing
 * @requires $sessionStorage
 */

angular.module('userMaintenanceModule').controller('AddUserController', [
  '$scope',
  '$state',
  '$stateParams',
  'userService',
  '$sessionStorage',
  '$uibModal',
  '$timeout',
  function (
    $scope,
    $state,
    $stateParams,
    userService,
    $sessionStorage,
    $uibModal,
    $timeout
  ) {
    $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

    $scope.showConfirmCreateIssuer = false;
    $scope.blockGroupEmail = 'N';
    $scope.isAdmin = 'N';
    if ($stateParams.userType === 'IP') {
      $scope.userType = 'IN';
    } else {
      $scope.userType = $stateParams.userType;
    }
    if ($scope.userType === 'AG') {
      $scope.userGroupId = '1';
    } else {
      $scope.userGroupId = $stateParams.userGroupId;
    }

    // The below function call is to store the data from UI
    // and
    // pass it to REST call for adding a new user
    $scope.addUser = async function () {
      // If issuer do additional check
      if ($stateParams.userType === 'IS') {
        let isVC = await isVoteCollector($scope.emailAddress);
        if (isVC) {
          $scope.showConfirmCreateIssuer = true;
        } else {
          $scope.saveUser();
        }
      } else {
        $scope.saveUser();
      }

      async function isVoteCollector(email) {
        let response = await userService.users().query({ email: email })
          .$promise;
        if (response.list.length > 0) {
          let user = response.list[0];
          let gmRoles = user.productRoles.GM;
          if (gmRoles.length > 0 && gmRoles.indexOf('VC') >= 0) {
            return true;
          }
        }
        return false;
      }

      $scope.$apply();
    };

    $scope.saveUser = function () {
      // Initializing the state URL
      let stateURL;
      switch ($stateParams.userType) {
        case 'AG':
          stateURL = 'userMaintenance.agentUserList';
          break;
        case 'IS':
          stateURL = 'userMaintenance.issuerUserList';
          break;
        case 'IN':
          stateURL = 'userMaintenance.intermediaryUserList';
          break;
        case 'IP':
          stateURL = 'userMaintenance.proxyIntermediaryUserList';
          break;
        case 'VC':
          stateURL = 'userMaintenance.vcUserList';
          break;
        default:
          break;
      }

      let firstName = $scope.firstName;
      if (null === firstName || undefined === firstName) {
        firstName = ' ';
      }
      let createUserData = {
        title: $scope.title,
        firstName: firstName,
        lastName: $scope.lastName,
        personalPhone: $scope.personalPhone,
        workPhone: $scope.workPhone,
        emailAddress: $scope.emailAddress,
        isAdmin: $scope.isAdmin,
        blockGroupEmail: $scope.blockGroupEmail,
        userType: $scope.userType,
        userGroupId: $scope.userGroupId,
        prodType: 'GM',
        defaultLanguage: 'EN',
        status: 'P',
        userId: $sessionStorage.userId,
        loggedInUserId: $sessionStorage.userId,
      };

      // The createNewUser in userService calls the REST url
      // for adding new user
      userService.createNewUser(createUserData, 'secureToken').then(
        function (response) {
          if (response.data.httpStatusCode === 451) {
            $scope.failure = true;
            $scope.errorMessage = response.data.message;
          } else {
            $scope.success = true;
            $scope.failure = false;
            $scope.timedout = $uibModal.open({
              templateUrl: 'success-dialog.html',
            });
            $timeout(function () {
              $scope.timedout.close();
              $scope.timedout = null;
              $state.go(stateURL, {
                groupId: $scope.userGroupId,
                status: $stateParams.groupStatus,
                userType: $stateParams.userType,
              });
            }, 2000);
          }
        },
        function (error) {
          $scope.failure = true;
          if (error.data.errors && error.data.errors.length > 0) {
            $scope.errorMessage = error.data.errors[0].errorMessage;
          } else {
            $scope.errorMessage = error.data;
          }
        }
      );
    };
  },
]);
