/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.GeneralMeetingNotificationController
 * @description This controller is used for attendance request meeting related functionalities
 * @requires $scope,$stateParams,$sessionStorage for storing/passing information in scope/state/session
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 * @requires generalMeetingService for REST service calls
 */
angular
  .module('generalMeetingHomeModule')
  .controller('GeneralMeetingNotificationController', [
    '$scope',
    '$sessionStorage',
    '$stateParams',
    '$log',
    '$state',
    '$filter',
    'generalMeetingService',
    'MyAccountsService',
    'fileDownloadSvc',
    'cbpDownloadBlob',
    function (
      $scope,
      $sessionStorage,
      $stateParams,
      $log,
      $state,
      $filter,
      generalMeetingService,
      MyAccountsService,
      fileDownloadSvc,
      cbpDownloadBlob
    ) {
      $scope.modalHeader = $filter('translate')('notificationModal_header');
      $scope.modalBody = $filter('translate')('notificationModal_body');
      $scope.modalFooter = '';
      $scope.loading = true;
      $scope.modalLeftButton = $filter('translate')('label_yes');
      $scope.modalRightButton = $filter('translate')(
        'notificationModal_rightButton'
      );
      $scope.gmMeetingId = $stateParams.meetingId;
      $scope.issuerId = $stateParams.issuerId;
      $log.debug('meetingID', $scope.gmMeetingId);
      $scope.notificationShareClass = [];
      $scope.notificationDocumentList = [];
      $scope.languageCode = $sessionStorage.workstationData.selectedLang;
      // Below is the function  used to fetch issuer logo
      MyAccountsService.getIssuerGroupDetails(
        $scope.issuerId,
        $scope.languageCode
      ).then(
        function (response) {
          $scope.issuerLogoPresent = false;
          $scope.issuerGroupDetails = response.data;
          if ($scope.issuerGroupDetails.logoBaseStr) {
            $scope.issuerLogoPresent = true;
          }
        },
        function (error) {
          $log.debug(error);
        }
      );
      //Below is the function to get data related to notification on load of page
      generalMeetingService
        .getNotificationInfo(
          $scope.gmMeetingId,
          $scope.languageCode,
          'secureToken'
        )
        .then(
          function (response) {
            $scope.notificationData = response.data;
            $log.debug($scope.notificationData);
            angular.forEach(
              $scope.notificationData.isinAndShareClassName,
              function (value, key) {
                $scope.notificationShareClass.push({
                  shareClassName:
                    $scope.notificationData.isinAndShareClassName[key]
                      .shareClassName,
                  isin: $scope.notificationData.isinAndShareClassName[key].iSIN,
                });
              }
            );
            angular.forEach($scope.notificationData.gmNotiDocuments, function (
              value,
              key
            ) {
              $scope.notificationDocumentList.push({
                documentName:
                  $scope.notificationData.gmNotiDocuments[key].docName,
                meetingDocId:
                  $scope.notificationData.gmNotiDocuments[key].meetingDocId,
                checked: true,
              });
            });
            $scope.Narrative =
              $scope.notificationData.customerMessages.Narrative;
            $scope.Other_Reqr_For_parti =
              $scope.notificationData.customerMessages.Other_Reqr_For_parti;
            $scope.Adnl_Inf_Noti_Of_Prt =
              $scope.notificationData.customerMessages.Adnl_Inf_Noti_Of_Prt;
            $scope.Avail_Means_Of_Parti =
              $scope.notificationData.customerMessages.Avail_Means_Of_Parti;
            $scope.emailTemplateShareHolder =
              $scope.notificationData.emailTemplates[1];
            $scope.emailTemplateIntermediary =
              $scope.notificationData.emailTemplates[0];
            $scope.loading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.loading = false;
          }
        );

      //Below is the function to download the documents uploaded during creation of General meeting
      $scope.downloadDocumentPreview = function (docId) {
        var pathList = window.location.pathname.split('/');
        if (pathList[1] === 'cbp') {
          $scope.downloadDocumentNotiPreview =
            window.location.origin +
            '/' +
            pathList[1] +
            '/v1/generalmeetingdetails/documents/notification/preview?meetingDocId=' +
            docId +
            '&languageCd=EN' +
            '&authid=' +
            $sessionStorage.userId;
        } else {
          $scope.downloadDocumentNotiPreview =
            window.location.origin +
            '/v1/generalmeetingdetails/documents/notification/preview?meetingDocId=' +
            docId +
            '&languageCd=EN' +
            '&authid=' +
            $sessionStorage.userId;
        }
      };

      $scope.downloadNotificationPreview = function () {
        let downloadUrl =
          `/v1/generalmeetingdetails/${$scope.gmMeetingId}/notification/preview` +
          `?msgOne=${$scope.Avail_Means_Of_Parti}` +
          `&msgTwo=${$scope.Adnl_Inf_Noti_Of_Prt}` +
          `&msgThree=${$scope.Narrative}` +
          `&msgFour=${$scope.Other_Reqr_For_parti}` +
          `&iSIN=${$scope.ShareClass.isin}` +
          `&shareClassName=${$scope.ShareClass.shareClassName}` +
          `&recepient=${$scope.selectedRecipient}` +
          `&authid=${$sessionStorage.userId}`;

        try {
          fileDownloadSvc.downloadFile(downloadUrl).then((response) => {
            cbpDownloadBlob(response.file, response.fileName);
          });
        } catch (err) {
          $log.debug(err);
        }
      };

      //Below is the function to update the customized message for future use
      $scope.updateMessageInDB = function (
        Narrative,
        Adnl_Inf_Noti_Of_Prt,
        Avail_Means_Of_Parti,
        Other_Reqr_For_parti
      ) {
        $scope.customMsgUpdateFlag = 'Y';
        $scope.customisedMessageData = {
          customerMessages: {
            Narrative: Narrative,
            Other_Reqr_For_parti: Other_Reqr_For_parti,
            Adnl_Inf_Noti_Of_Prt: Adnl_Inf_Noti_Of_Prt,
            Avail_Means_Of_Parti: Avail_Means_Of_Parti,
          },
          customMsgUpdateFlag: $scope.customMsgUpdateFlag,
        };
        generalMeetingService
          .updateNotificationCustomisedMessageInDB(
            $scope.gmMeetingId,
            $scope.customisedMessageData,
            'secureToken'
          )
          .then(
            function () {
              $scope.successMsg = $filter('translate')(
                'notification_successMsg1'
              );
            },
            function (error) {
              $log.debug(error);
            }
          );
      };

      //Below is the function to update the customized message for only one instance
      $scope.updateMessageInSheet = function (
        Narrative,
        Adnl_Inf_Noti_Of_Prt,
        Avail_Means_Of_Parti,
        Other_Reqr_For_parti
      ) {
        $scope.customMsgUpdateFlag = 'N';
        $scope.customisedMessageData = {
          customerMessages: {
            Narrative: Narrative,
            Other_Reqr_For_parti: Other_Reqr_For_parti,
            Adnl_Inf_Noti_Of_Prt: Adnl_Inf_Noti_Of_Prt,
            Avail_Means_Of_Parti: Avail_Means_Of_Parti,
          },
          customMsgUpdateFlag: $scope.customMsgUpdateFlag,
        };
        generalMeetingService
          .updateNotificationCustomisedMessageInSheet(
            $scope.gmMeetingId,
            $scope.customisedMessageData,
            'secureToken'
          )
          .then(
            function () {
              $scope.successMsg = $filter('translate')(
                'notification_successMsg2'
              );
            },
            function (error) {
              $log.debug(error);
            }
          );
      };

      //Below is the function call on change of drop down to get email template
      $scope.onRecipientSelect = function (selectedRecipient) {
        $log.debug(selectedRecipient);
        $scope.recepientType = selectedRecipient;
        if ($scope.recepientType === 'INTERMEDIARY') {
          $scope.emailBodyFromUI = $filter('htmlDecode')(
            $scope.notificationData.emailTemplates[0]
          ).toString();
        }
        $log.debug($scope.emailBodyFromUI);
      };

      //Below is the function call to send notification
      $scope.notificationGeneralMeeting = function (
        meetingId,
        Narrative,
        Adnl_Inf_Noti_Of_Prt,
        Avail_Means_Of_Parti,
        Other_Reqr_For_parti
      ) {
        $scope.errorMessage = '';
        $scope.disableSendBtn = true;
        //$scope.productType = $sessionStorage.productTypes;
        $scope.productType = 'GM';
        $scope.documentIdArray = [];
        angular.forEach($scope.notificationDocumentList, function (docList) {
          if (docList.checked) {
            $scope.documentIdArray.push({
              meetingDocId: docList.meetingDocId,
            });
          }
        });
        $scope.sendData = {
          customerMessages: {
            Narrative: Narrative,
            Other_Reqr_For_parti: Other_Reqr_For_parti,
            Adnl_Inf_Noti_Of_Prt: Adnl_Inf_Noti_Of_Prt,
            Avail_Means_Of_Parti: Avail_Means_Of_Parti,
          },
          recepientType: $scope.recepientType,
          emailBodyFromUI: $scope.emailBodyFromUI,
          gmNotiDocuments: $scope.documentIdArray,
          languageCode: $scope.languageCode,
        };
        $log.debug($scope.sendData);
        generalMeetingService
          .sendNotificationGeneralMeeting(
            meetingId,
            $scope.sendData,
            'secureToken'
          )
          .then(
            function () {
              $scope.disableSendBtn = false;
              $scope.responseMessage = $filter('translate')(
                'notification_successMsg3'
              );
            },
            function (error) {
              $scope.disableSendBtn = false;
              $scope.errorMsg = true;
              $scope.errorMessage = error.data.errors[0].errorMessage;
              $log.debug(error);
            }
          );
      };
    },
  ]);
