/**
 * Checks if there are any validation errors reported in the input param
 *
 * @param response the validation response to examine
 */
export const hasPreSubmitValidationErrors = (response) => {
  return (
    response['errors'].length > 0 ||
    response['typeAndLocationErrors'].length > 0 ||
    response['agendaErrors'].length > 0 ||
    response['featureErrors'].length > 0 ||
    response['scheduleErrors'].length > 0 ||
    response['shareclassErrors'].length > 0 ||
    response['documentErrors'].length > 0
  );
};

/**
 * Gets the error messages of the specified section
 *
 * @param errors complete set of errors
 * @param section the error section to fetch
 * @returns {[boolean, []]} true if there are errors, list of error messages
 */
export const getSectionalErrors = (errors, section) => {
  let errorMessages = [];
  angular.forEach(errors[section], function (err) {
    errorMessages.push(err['errorMessage']);
  });
  return [errorMessages.length > 0, errorMessages];
};
