import formatLocationString from '@/lib/formatLocationString';
import getTermsOfUseUrl from '@/lib/getTermsOfUseUrl';

/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.VotingInstructionIntermediaryController
 * @description This controller is used for voting instruction. This mainly
 *              deals with submitting of voting instruction.
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 * @requires userGroupService
 * @requires voteInstructionService
 */
angular
  .module('generalMeetingHomeModule')
  .controller('editVotingReqIntermediaryController', [
    '$scope',
    '$filter',
    '$sessionStorage',
    '$stateParams',
    'voteInstructionService',
    'GeneralMeetingDataLanguageService',
    '$log',
    'userGroupService',
    'fileDownloadSvc',
    function (
      $scope,
      $filter,
      $sessionStorage,
      $stateParams,
      voteInstructionService,
      GeneralMeetingDataLanguageService,
      $log,
      userGroupService,
      fileDownloadSvc
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      var votingInstDetails = $stateParams.votingInstDetails;
      $scope.userType = $sessionStorage.usertype;
      $scope.proxyType = $sessionStorage.proxyType;
      $scope.shareClassName = $stateParams.shareclassName;
      $scope.shareClassId = $stateParams.shareclassId;
      $scope.intermediaryId = $sessionStorage.groupId;
      $scope.shareholderId = $stateParams.shareholderId;
      $scope.instructionId = $stateParams.instructionId;
      $scope.showAllFor = true;
      $scope.issuerName = $stateParams.issuerName.toLowerCase();
      if ($scope.issuerName == 'shell plc') {
        $scope.showAllFor = false;
      }
      $scope.action = 'edit';
      $scope.newFileUploadedVI = false;
      $scope.status = $stateParams.status;
      $scope.createdBy = $stateParams.createdBy;
      // Below is the function used to fetch issuer logo
      userGroupService.getIssuerGroupLogo($stateParams.issuerId).then(
        function (response) {
          $scope.issuerLogoPresent = false;
          $scope.issuerGroupDetails = response.data;
          // Do not change the below condition. It is for issuer logo display fix. REST response has ""
          if ($scope.issuerGroupDetails.logoBaseStr) {
            $scope.issuerLogoPresent = true;
          }
        },
        function (error) {
          $log.debug(error);
        }
      );
      $scope.holdingRadio = 'Y';
      $scope.shareHolderVO = {};
      $scope.noOfSharesErrorFlag = 0;
      $scope.flag = 0;
      $scope.loading = false;
      $scope.loadingSpin = false;
      $scope.loadingFileSpin = false;
      $scope.duplicateRequest = false;
      $scope.eventType = 'Footer Term of Use';
      $scope.availableVC = [];
      $scope.languageCode =
        $sessionStorage.workstationData.selectedLang || 'EN';
      $scope.modalHeader = 'Information';
      $scope.modalBody = 'Are you sure u want to continue';
      $scope.modalLeftButton = $filter('translate')('label_confirm');
      $scope.modalRightButton = $filter('translate')('label_cancel');
      // Below value is hard coded to GM because VI and AR
      // functionalities will be there only for GM users.
      // Using productType array from session storage was
      // causing issue in case of integrated users, so it is
      // relpaced with below value.
      $scope.productTypeToU = 'GM';
      /* date picker */
      $scope.inlineOptions = {
        customClass: getDayClass,
      };
      $scope.open1 = function () {
        $scope.popup1.opened = true;
      };
      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };
      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.popup1 = {
        opened: false,
      };
      function disabled(data) {
        var date, mode;
        date = data.date;
        mode = data.mode;
        $log.debug(date);
        $log.debug(mode);
      }
      $scope.dateOptions = {
        dateDisabled: disabled,
        formatYear: 'yy',
        maxDate: new Date(),
        // removed min validation for date picker -SFCBP-1977
        showWeeks: false,
      };
      function getDayClass(data) {
        var date = data.date,
          mode = data.mode;
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(
              0,
              0,
              0,
              0
            );
            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }
        return '';
      }
      /* date picker end */
      $scope.dateEnter = function (dob) {
        var currentdate = new Date().setHours(0, 0, 0, 0);
        if (dob > currentdate) {
          $scope.dobError = true;
        } else {
          $scope.dobError = false;
        }
      };
      $scope.changeUserType = function () {
        $scope.shareHolderType = [];
        $scope.shareHolderType[0] = $scope.profileListData.shareholderTypeVO[0];
      };
      $scope.selectedSHType = function () {
        if ($scope.shareHolderType[0].type === 'LGL') {
          $scope.shTitleRequired = false;
        } else {
          $scope.shTitleRequired = true;
        }
      };
      $scope.onTitleChange = function () {
        $scope.shTitleRequired = true;
      };
      // Below is the function call on drop-down change for
      // country
      $scope.onCountrySelect = function (selectedCountry) {
        $scope.selectedCountry = selectedCountry;
      };

      if (votingInstDetails.length > 1) {
        $scope.moreThanOneSC = true;
        $scope.shareClassList = votingInstDetails;
        $scope.selectedSecurityClass = $scope.shareClassList[0];
        /*
         * Below changes are added to display votecollector name
         * along with its group name in the drop down
         */
        $scope.voteCollectorList = [];
        angular.forEach(
          $scope.selectedSecurityClass.vcShareclassAssocVO,
          function (key) {
            key.nameOfVC =
              key.title +
              ' ' +
              key.firstName +
              ' ' +
              key.lastName +
              ' ' +
              $filter('translate')('vi_shareholder_disclaimer2') +
              ' ' +
              key.vcName;
            $scope.voteCollectorList.push(key);
            if ($scope.voteCollectorList.length === 1) {
              $scope.selectedVC = $scope.voteCollectorList[0];
            }
          }
        );
      } else if (votingInstDetails.length === 1) {
        $scope.moreThanOneSC = false;
        $scope.shareClassList = votingInstDetails;
        $scope.selectedSecurityClass = $scope.shareClassList[0];
        /*
         * Below changes are added to display vote collector
         * name along with its group name in the drop down
         */
        $scope.voteCollectorList = [];
        angular.forEach(
          $scope.selectedSecurityClass.vcShareclassAssocVO,
          function (key) {
            key.nameOfVC =
              key.title +
              ' ' +
              key.firstName +
              ' ' +
              key.lastName +
              ' ' +
              $filter('translate')('vi_shareholder_disclaimer2') +
              ' ' +
              key.vcName;
            $scope.voteCollectorList.push(key);
            if ($scope.voteCollectorList.length === 1) {
              $scope.selectedVC = $scope.voteCollectorList[0];
            }
          }
        );
      } else {
        $scope.noShareClass = true;
      }
      // on change of votecollector in dropdown
      $scope.onVCChange = function (selectedVC) {
        $scope.selectedVC = selectedVC;
      };
      // on security class change in dropdown
      $scope.onSecurityClassChange = function (selectedSecurityClass) {
        $scope.selectedSecurityClass = selectedSecurityClass;
        /*
         * Below changes are added to display vote collector
         * name along with its group name in the drop down
         */
        $scope.voteCollectorList = [];
        angular.forEach(
          $scope.selectedSecurityClass.vcShareclassAssocVO,
          function (key) {
            key.nameOfVC =
              key.title +
              ' ' +
              key.firstName +
              ' ' +
              key.lastName +
              ' ' +
              $filter('translate')('vi_shareholder_disclaimer2') +
              ' ' +
              key.vcName;
            $scope.voteCollectorList.push(key);
            if ($scope.voteCollectorList.length === 1) {
              $scope.selectedVC = $scope.voteCollectorList[0];
            }
          }
        );
        $scope.selectedVC = $scope.voteCollectorList[0];
      };
      /**
       * The following code is an important one. For shareholder
       * ws, during creation of VI, if the selected security
       * class has more than one intermediary, then a dropdown
       * needs to be shown. Else, no dropdown. Also, when there
       * is more than one intermediary (All intermediaries) and
       * the shareholder has selected preferred intermediary
       * while registration, then the preferred intermediary
       * should be selected by default. If the agent has created
       * GM with shareclass having only one intermediary, then
       * it takes precedence over the shareholder preference.
       */

      // Below is the function call on drop-down change (code is
      // removed for VC)
      $scope.onintermediaryChange = function (selectedIntermediary) {
        $scope.selectedIntermediary = selectedIntermediary;
        $scope.intermediaryid = selectedIntermediary.intermediaryId;
        $scope.getVISubmit();
      };
      // ALM - 10528 autopopulating field when 'veb' is keyed in
      // for name filed for legal person
      $scope.autoPopulate = function () {
        if (
          $scope.shareHolderType[0].type === 'LGL' &&
          ($scope.profileDetails.lastName.toLowerCase() === 'veb' ||
            $scope.profileDetails.lastName
              .toLowerCase()
              .indexOf('vereniging van effectenbezitters') > -1)
        ) {
          $scope.profileDetails.address = 'Amaliastraat 7';
          $scope.profileDetails.emailAddress = 'info@veb.net';
          $scope.profileDetails.city = 'Den Haag';
          $scope.selectedCountryArray = $filter('filter')(
            $scope.profileListData.countryCodeVO,
            {
              isoCountryCode: 'NLD',
            },
            true
          );
          $scope.selectedCountry = $scope.selectedCountryArray[0];
        }
      };

      $scope.getProxyTextParagraph2 = function () {
        if ($scope.issuerName === 'telenet group holding nv') {
          return $filter('translate')(
            'vi_shareholder_disclaimer8_with_right_of_substitution'
          );
        } else {
          return $filter('translate')('vi_shareholder_disclaimer8');
        }
      };

      // On load of page, the following function is called,
      // which makes service call to get shareclass details.
      $scope.onLoadVI = function () {
        voteInstructionService
          .getShareClassListWithVotes(
            $stateParams.meetingId,
            $stateParams.shareholderId,
            $scope.languageCode,
            $sessionStorage.usertype,
            $stateParams.instructionId,
            $stateParams.status
          )
          .query()
          .$promise.then(
            function (data) {
              // if chosen language does not have data, then switch to English, else
              // fetch the existing details.
              if (data.length <= 0 && $scope.languageCode !== 'EN') {
                $scope.languageCode = 'EN';
                $scope.onLoadVI();
              } else {
                $scope.selectedQuantityNoOfShares = data[0].noOfSecurities;
                $scope.voteCollectorList = [];
                $scope.shareClassList = [];
                $scope.votingInstructionDetails = GeneralMeetingDataLanguageService.getLanguageSpecificData(
                  data,
                  $scope.languageCode
                );
                angular.forEach($scope.votingInstructionDetails, function (
                  item
                ) {
                  $scope.shareClassList.push(item);
                  if (item.selectedShareClassId === item.shareclassIdWOSalt) {
                    $scope.votingInstDetails = item;
                    $scope.selectedSecurityClass = item;
                    $scope.shareClassId = item.shareclassId;
                    // for Intermediary WS, we are directly
                    // sending IntermediaryID from previous
                    // page. Hence, removed the code for this part.
                    angular.forEach(item.vcShareclassAssocVO, function (item3) {
                      item3.nameOfVC =
                        item3.title +
                        ' ' +
                        item3.firstName +
                        ' ' +
                        item3.lastName +
                        ' ' +
                        $filter('translate')('vi_shareholder_disclaimer2') +
                        ' ' +
                        item3.vcName;
                      $scope.voteCollectorList.push(item3);
                      if (item.selectedVCUserId === item3.vcUserIdWoSalt) {
                        $scope.selectedVC = item3;
                      }
                    });
                  }
                });
              }
              // SFCBP-2919 : not to call agenda service
              if ($scope.createdBy === 'SH') {
                $scope.getShareHolderDetailsVI();
              } else if ($scope.isAllowedToSeeAgenda()) {
                $scope.agenda();
              }
            },
            function (error) {
              $scope.error = error;
            }
          );
      };

      $scope.isAllowedToSeeAgenda = function () {
        return (
          $scope.createdBy === 'MI' ||
          $scope.createdBy === 'IA' ||
          $scope.createdBy === 'IB' ||
          $scope.createdBy === 'PI' ||
          $scope.createdBy === 'SP'
        );
      };

      //Below is the function call to fetch the linked intermediaries for a proxy intermediary and populate the select intermediary dropdown.
      $scope.fetchLinkedIntermediaries = function () {
        if ($sessionStorage.proxyType === 'P') {
          $scope.prxoyIntermediaryFlag = 'Y';
          voteInstructionService
            .getLinkedIntermediaries($sessionStorage.groupId)
            .query()
            .$promise.then(
              function (data) {
                //Intermediary dropdownlist assignment
                $scope.linkedIntermediaryList = data;
                //Selected intermediary will be assigned in the dropdown on edit
                $scope.selectedLinkedInt = $filter('filter')(
                  $scope.linkedIntermediaryList,
                  {
                    intermediaryName: $scope.linkedIntermediaryName,
                  },
                  true
                )[0];
              },
              function (error) {
                $log.debug(error);
              }
            );
        }
      };

      //Below is the function call on select of Intemr
      $scope.onLinkedIntChange = function (selectedIntermediary) {
        $scope.selectedLinkedInt = selectedIntermediary;
      };

      // Below is the function call to fetch agenda items
      $scope.agenda = function () {
        $scope.message = '';
        $scope.totalNumberSharesError = '';
        $scope.errormessage = '';
        voteInstructionService
          .getAgendaDetails(
            $stateParams.meetingId,
            $scope.languageCode,
            $stateParams.shareholderId,
            $scope.shareClassId,
            $scope.intermediaryId,
            $stateParams.userType
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.agendaCollection = data;
              // if chosen language does not have data, then switch to English, else
              // fetch the existing details.
              if (data.length <= 0 && $scope.languageCode !== 'EN') {
                $scope.languageCode = 'EN';
                $scope.agenda();
              } else if (data.length > 0) {
                //Below code is taken out of the for each loop since function call was happening repeatedly
                if (!$scope.linkedIntermediaryName) {
                  $scope.linkedIntermediaryName =
                    $scope.agendaCollection[0].linkedIntermediaryName;
                }
                if (!$scope.linkedIntermediaryList) {
                  $scope.fetchLinkedIntermediaries();
                }
                angular.forEach($scope.agendaCollection, function (item) {
                  $scope.entireHolding = item.entireHolding;
                  $scope.noOfSecurities = item.noOfSecurities;
                  $scope.registrationNo = item.registrationNo;
                  item.totalSplitVotes = item.noOfSecurities;

                  if (
                    item.splitVoteAllwdFrmInter === 'Y' &&
                    item.splitVote === 'Y'
                  ) {
                    item.splitVotes = true;
                    item.disableSplitVote = true;
                  } else {
                    item.splitVotes = false;
                    item.disableSplitVote = false;
                    item.disableRadio = true;
                    if (item.voteFor !== 0) {
                      item.selectedVote = 'for';
                    } else {
                      item.voteFor = 0;
                    }
                    if (item.voteAgainst !== 0) {
                      item.selectedVote = 'against';
                    } else {
                      item.voteAgainst = 0;
                    }
                    if (item.voteAbstain !== 0) {
                      item.selectedVote = 'abstained';
                    } else {
                      item.voteAbstain = 0;
                    }
                  }
                });
                angular.forEach($scope.agendaCollection, function (key2) {
                  if (key2.isVotable === 'Y') {
                    $scope.isVotableFlag = true;
                  }
                  return $scope.isVotableFlag;
                });
              }
              $scope.getShareHolderDetailsVI();
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      $scope.getShareHolderDetailsVI = function () {
        voteInstructionService
          .getShareHolderDetailsIntWS(
            $stateParams.shareholderId,
            $stateParams.instructionId,
            $stateParams.userType
          )
          .then(
            function (response) {
              $scope.profileDetails = [];
              $scope.viDetails = response.data;
              if ($scope.viDetails) {
                $scope.securityAccountNumber =
                  $scope.viDetails.securityAccountNumber;
                $scope.profileDetails = $scope.viDetails;
                if ($scope.profileDetails.boDocFlag === 'Y') {
                  $scope.uploadedFileName = $scope.profileDetails.boDocName;
                }
                if ($scope.profileDetails) {
                  $scope.shareHolderVO.initials =
                    $scope.profileDetails.initials;
                }
                $scope.shareHolderType = $filter('filter')(
                  $scope.profileListData.shareholderTypeVO,
                  {
                    type: $scope.profileDetails.shType,
                  },
                  true
                );
                $scope.selectedCountryArray = $filter('filter')(
                  $scope.profileListData.countryCodeVO,
                  {
                    isoCountryCode: $scope.profileDetails.isoCountryCode,
                  },
                  true
                );
                $scope.selectedCountry = $scope.selectedCountryArray[0];
                if ($scope.profileDetails.title) {
                  $scope.shTitleRequired = true;
                }
                $scope.shareHolderVO.selectedTitle = $filter('filter')(
                  $scope.profileListData.shareHolderTitleVO,
                  {
                    title: $scope.profileDetails.title,
                  },
                  true
                );
                $scope.selectedUserType = $filter('filter')(
                  $scope.profileListData.owneTypeVO,
                  {
                    userType: $scope.profileDetails.owneType,
                  },
                  true
                );
                $scope.loading = false;
                $scope.getProxy(
                  $scope.securityAccountNumber,
                  $scope.selectedSecurityClass
                );
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      $scope.processVotingInstructionData = function () {
        if (
          undefined !== votingInstDetails.length &&
          votingInstDetails.length >= 1
        ) {
          $scope.votingInsructionData = votingInstDetails;
          if (votingInstDetails.length === 1) {
            $scope.onSecurityClassChange(votingInstDetails[0]);
            $scope.selectedSecurityClass = votingInstDetails[0];
          } else if (votingInstDetails.length >= 1) {
            $scope.onSecurityClassChange(votingInstDetails[0], 'onLoadofPage');
          }
        } else {
          $scope.votingInsructionData = '';
        }
      };
      $scope.processVotingInstructionData();

      if (votingInstDetails.length > 1) {
        $scope.moreThanOneShareClass = true;
      }
      if (null !== $scope.shareClassId) {
        angular.forEach($scope.votingInsructionData, function (value, key) {
          if (
            $scope.shareClassName ===
              $scope.votingInsructionData[key].shareClassName &&
            key + 1 !== $scope.votingInsructionData.length
          ) {
            $scope.selectedSecurityClass = $scope.votingInsructionData[key + 1];
            $scope.onSecurityClassChange($scope.selectedSecurityClass);
          } else if (
            $scope.shareClassName ===
              $scope.votingInsructionData[key].shareClassName &&
            key + 1 === $scope.votingInsructionData.length
          ) {
            $scope.selectedSecurityClass = $scope.votingInsructionData[0];
            $scope.onSecurityClassChange($scope.selectedSecurityClass);
          }
        });
      }
      voteInstructionService
        .getProfileDropDownList($scope.languageCode, $scope.userType)
        .query()
        .$promise.then(
          function (data) {
            $scope.profileListData = data;
            angular.forEach($scope.profileListData.countryCodeVO, function (
              item
            ) {
              if (item.isoCountryCode === 'NLD') {
                $scope.selectedCountry = item;
              }
            });
          },
          function (error) {
            $log.debug(error);
          }
        );
      // below is the function call on change of radio button
      $scope.holdingRadioChange = function (val) {
        $scope.holdingRadio = val;
      };
      /*
       * Below is the function to check whether the entered
       * Securities account number is already present or not in
       * intermediary workstations
       */
      $scope.getProxy = function (
        securityAccountNumber,
        selectedSecurityClass
      ) {
        if (undefined !== securityAccountNumber) {
          $scope.loading = true;
          $scope.selectedCountryArray = [];
          voteInstructionService
            .getVotingProxyList(
              $stateParams.meetingId,
              securityAccountNumber,
              selectedSecurityClass.shareclassId,
              '0',
              $scope.userType,
              $sessionStorage.groupId
            )
            .query()
            .$promise.then(
              function (data) {
                $scope.errorMessage = null;
                $scope.proxyData = data;
                $scope.profileDetails = $scope.proxyData.shareHolderVO;
                $scope.registrationList =
                  $scope.proxyData.shareholderRegistrationDetailVO;
                if ($scope.profileDetails) {
                  $scope.shareHolderVO.initials =
                    $scope.profileDetails.initials;
                }
                $scope.shareHolderType = $filter('filter')(
                  $scope.profileListData.shareholderTypeVO,
                  {
                    type: $scope.profileDetails.shareHolderType,
                  },
                  true
                );
                $scope.selectedCountryArray = $filter('filter')(
                  $scope.profileListData.countryCodeVO,
                  {
                    isoCountryCode: $scope.profileDetails.country,
                  },
                  true
                );
                $scope.selectedCountry = $scope.selectedCountryArray[0];
                $scope.shareHolderVO.selectedTitle = $filter('filter')(
                  $scope.profileListData.shareHolderTitleVO,
                  {
                    title: $scope.profileDetails.title,
                  },
                  true
                );
                $scope.selectedUserType = $filter('filter')(
                  $scope.profileListData.owneTypeVO,
                  {
                    userType: $scope.profileDetails.owneType,
                  },
                  true
                );
                $scope.loading = false;
                if ($scope.profileDetails.shareholderId !== '0') {
                  $scope.shareholderId = $scope.profileDetails.shareholderId;
                } else {
                  $scope.shareholderId = '0';
                }
              },
              function (error) {
                $scope.loading = false;
                $scope.errorMessage = error.data.errors[0].errorMessage;
              }
            );
        }
      };

      // Below is the function to validate the entered number of
      // shares when split votes is enabled
      $scope.CalculateSumOfShares = function (item) {
        $scope.message = '';
        $scope.errormessage = '';
        item.totalNumberSharesError = '';
        item.voteFor = parseInt(item.voteFor, 10);
        item.voteAgainst = parseInt(item.voteAgainst, 10);
        item.voteAbstain = parseInt(item.voteAbstain, 10);
        if (
          parseInt(item.voteFor, 10) +
            parseInt(item.voteAgainst, 10) +
            parseInt(item.voteAbstain, 10) >
          parseInt(item.totalSplitVotes, 10)
        ) {
          item.totalNumberSharesError = $filter('translate')(
            'noOfShares_error1'
          );
          $scope.flag = 1;
          $scope.noVotePatternErr = true;
          return;
        } else if (
          parseInt(item.voteFor, 10) +
            parseInt(item.voteAgainst, 10) +
            parseInt(item.voteAbstain, 10) !==
          parseInt(item.totalSplitVotes, 10)
        ) {
          item.totalNumberSharesError = $filter('translate')(
            'noOfShares_error2'
          );
          $scope.noVotePatternErr = true;
          $scope.flag = 1;
          return;
        } else {
          $scope.flag = 0;
          $scope.noVotePatternErr = false;
          item.totalNumberSharesError = '';
        }
      };
      // Below is the function call on selection radio buttons
      // to enable or disable particular section
      $scope.selectAllRadio = function () {
        $scope.flag = 0;
        $scope.message = '';
        $scope.errormessage = null;
        $scope.allRadio = true;
        angular.forEach($scope.agendaCollection, function (item) {
          item.totalNumberSharesError = '';
          var voteValue = item.totalSplitVotes;
          if ($scope.selectedVote === 'Allfor') {
            item.selectedVote = 'for';
            item.voteFor = voteValue;
            item.voteAgainst = 0;
            item.voteAbstain = 0;
            item.disableSplitVote = false;
            item.splitVotes = false;
            item.disableRadio = false;
            $scope.noVotePatternErr = false;
          } else if ($scope.selectedVote === 'AllAgainst') {
            item.selectedVote = 'against';
            item.voteAgainst = voteValue;
            item.voteFor = 0;
            item.voteAbstain = 0;
            item.disableSplitVote = false;
            item.splitVotes = false;
            item.disableRadio = false;
            $scope.noVotePatternErr = false;
          } else if ($scope.selectedVote === 'AllAbstained') {
            item.selectedVote = 'abstained';
            item.voteAbstain = voteValue;
            item.voteAgainst = 0;
            item.voteFor = 0;
            item.disableSplitVote = false;
            item.splitVotes = false;
            item.disableRadio = false;
            $scope.noVotePatternErr = false;
          } else {
            $scope.noVotePatternErr = false;
            if (item.splitVoteAllwdFrmInter === 'N') {
              item.disableSplitVote = false;
            } else {
              item.disableSplitVote = true;
            }
            item.disableRadio = true;
          }
        });
      };
      /*
       * Below is the function call on selection radio buttons
       * to check whether the voted item is for or against or
       * abstained when none radio button is selected
       */
      $scope.individualRadioChange = function (item) {
        $scope.individualRadio = true;
        $scope.flag = 0;
        $scope.errormessage = null;
        var voteValue = item.noOfSecurities;
        if (item.selectedVote === 'for') {
          item.voteFor = voteValue;
          item.voteAgainst = 0;
          item.voteAbstain = 0;
          $scope.noVotePatternErr = false;
        } else if (item.selectedVote === 'against') {
          item.voteAgainst = voteValue;
          item.voteFor = 0;
          item.voteAbstain = 0;
          $scope.noVotePatternErr = false;
        } else if (item.selectedVote === 'abstained') {
          item.voteAbstain = voteValue;
          item.voteAgainst = 0;
          item.voteFor = 0;
          $scope.noVotePatternErr = false;
        }
      };

      $scope.spilitVoteChange = function (item) {
        item.totalNumberSharesError = '';
        if (item.splitVotes === true) {
          item.selectedVote = false;
          $scope.noVotePatternErr = false;
          $scope.errormessage = '';
          $scope.CalculateSumOfShares(item);
        } else {
          item.disableRadio = true;
          $scope.errormessage = $filter('translate')(
            'votingInstruction_error_msg'
          );
          $scope.noVotePatternErr = true;
        }
      };

      // Below is the function to validate the entered number of
      // shares
      $scope.enterShares = function (selectedQuantityNoOfShares) {
        $scope.selectedQuantityErrorMessage = '';
        angular.forEach($scope.agendaCollection, function (item) {
          if (
            selectedQuantityNoOfShares != null &&
            selectedQuantityNoOfShares !== ''
          ) {
            item.totalSplitVotes = selectedQuantityNoOfShares;
            if (item.isVotable !== 'N' && item.splitVotes === true) {
              $scope.CalculateSumOfShares(item);
            }
          } else {
            item.totalSplitVotes = $scope.noOfShares;
          }
          if ($scope.allRadio) {
            $scope.selectAllRadio();
          } else if ($scope.individualRadio) {
            $scope.individualRadioChange(item);
          } else if (item.splitVotes) {
            $scope.spilitVoteChange(item);
          }
        });
      };

      $scope.termsOfUseUrl = getTermsOfUseUrl(
        'GM',
        $sessionStorage.workstationData.selectedLang
      );

      // Below method call from HTML on click of upload share
      // plan data.
      $scope.onFileSelect = function (e) {
        $scope.fileStatus = '';
        $scope.loadingFileSpin = true;
        var filesList = e.files;
        var fd = new FormData();
        fd.append('file', filesList[0]);
        $scope.fileType = filesList[0].type;

        $scope.errorHere = false;
        voteInstructionService.uploadBOFile(fd, 'secureToken').then(
          function (response) {
            $scope.loadingFileSpin = false;
            $scope.newFileUploadedVI = true;
            if (response.status === 200) {
              $scope.saveDisabled = false;
              $scope.filePath = response.data.filePath;
              $scope.fileStatus = response.data.fileStatus;
              $scope.fileName = response.data.fileName;
              $scope.errorHere = false;
              $scope.errorMsg = '';
              $scope.saveStatus = '';
            } else if (response.data.errors) {
              if (response.data.errors[0].errorCode === 'CBP_ERR_513') {
                $scope.errorHere = true;
                $scope.errorMsg = response.data.errors[0].errorMessage;
                $scope.saveDisabled = true;
                $scope.fileName = response.data.fileName;
                $scope.fileStatus = response.data.fileStatus;
                $log.debug('error');
                $scope.saveStatus = '';
              }
            } else {
              $scope.errorHere = true;
              $scope.errorMsg = response.data.fileStatus;
              $scope.saveDisabled = true;
              $scope.fileName = response.data.fileName;
              $scope.fileStatus = response.data.fileStatus;
              $log.debug('error');
              $scope.saveStatus = '';
            }
          },
          function (error) {
            $scope.loadingFileSpin = false;
            $scope.newFileUploadedVI = true;
            $scope.errorHere = true;
            $scope.errorMsg = $filter('translate')('label_file_format_xlsx');
            $scope.saveDisabled = true;
            $scope.fileName = '';
            $scope.fileStatus = error.fileStatus;
            $log.debug('error');
            $scope.saveStatus = '';
          }
        );
      };
      $scope.continueVoting = function () {
        if ($scope.status === 'A') {
          $scope.confirmVoting();
        } else {
          if (
            $scope.selectedUserType[0].userType !== 'NOMI' &&
            $scope.selectedUserType[0].userType !== 'TRUS'
          ) {
            $scope.showTcOwneDialog = true;
          } else if (
            $scope.selectedUserType[0].userType === 'NOMI' ||
            $scope.selectedUserType[0].userType === 'TRUS'
          ) {
            $scope.showTcNomiTrustDialog = true;
          }
        }
      };
      $scope.intermediaryConfirmVI = function () {
        $scope.duplicateRequest = false;
        $scope.errormessage = '';
        //Check if selected linked intermediary is Expired or Non Expired. For expired , throw error message
        if (
          $sessionStorage.proxyType === 'P' &&
          $scope.selectedLinkedInt.invalidPOAcandidates === 'Y'
        ) {
          $scope.errormessage = $filter('translate')('label_linkedIntError');
        }
        //For user type NOMI and TRUS, uploading BO file is mandatory.
        else if (
          $scope.selectedUserType[0].userType === 'NOMI' ||
          $scope.selectedUserType[0].userType === 'TRUS'
        ) {
          if (
            $scope.filePath === null ||
            ($scope.filePath === undefined && $scope.newFileUploadedVI)
          ) {
            $scope.errorHere = true;
            $scope.errorMsg = $filter('translate')('uploadFile_error');
          } else {
            $scope.errorHere = false;
            if (
              $scope.fileType !==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
              $scope.newFileUploadedVI
            ) {
              $scope.errorHere = true;
              $scope.errorMsg = $filter('translate')('label_file_format_xlsx');
            } else {
              $scope.errorHere = false;
              // Service call to check whether the voting instruction is already submitted for the
              // current combination of share class, intermediary and number of shares
              voteInstructionService
                .checkVotingRegistrationList(
                  $stateParams.meetingId,
                  $scope.selectedSecurityClass.shareclassId,
                  $sessionStorage.groupId,
                  $scope.profileDetails.lastName,
                  $scope.selectedQuantityNoOfShares,
                  $scope.instructionId
                )
                .then(
                  function (response) {
                    $log.debug('success user view');
                    if (response.data.length > 0) {
                      $scope.duplicateRequest = true;
                      $scope.modalBody =
                        'There is already a voting instruction registered for ' +
                        response.data[0].shareholderName +
                        ' for ' +
                        $scope.selectedQuantityNoOfShares +
                        ' securities. Are you sure you want to continue as this might be a duplicate entry?';
                      $scope.showAlertBoxDuplicateEntry = true;
                    } else {
                      if ($scope.status === 'A') {
                        $scope.confirmVoting();
                      } else {
                        if (
                          $scope.selectedUserType[0].userType !== 'NOMI' &&
                          $scope.selectedUserType[0].userType !== 'TRUS'
                        ) {
                          $scope.showTcOwneDialog = true;
                        } else if (
                          $scope.selectedUserType[0].userType === 'NOMI' ||
                          $scope.selectedUserType[0].userType === 'TRUS'
                        ) {
                          $scope.showTcNomiTrustDialog = true;
                        }
                      }
                    }
                  },
                  function (error) {
                    $log.debug(error);
                  }
                );
            }
          }
        } else {
          $scope.errorHere = false;
          // Service call to check whether the voting instruction is already submitted for the current
          // combination of share class, intermediary and number of shares
          voteInstructionService
            .checkVotingRegistrationList(
              $stateParams.meetingId,
              $scope.selectedSecurityClass.shareclassId,
              $sessionStorage.groupId,
              $scope.profileDetails.lastName,
              $scope.selectedQuantityNoOfShares,
              $scope.instructionId
            )
            .then(
              function (response) {
                if (response.data.length > 0) {
                  $scope.duplicateRequest = true;
                  $scope.modalBody =
                    'There is already a voting instruction registered for ' +
                    response.data[0].shareholderName +
                    ' for ' +
                    $scope.selectedQuantityNoOfShares +
                    ' securities. Are you sure you want to continue as this might be a duplicate entry?';
                  $scope.showAlertBoxDuplicateEntry = true;
                } else {
                  if ($scope.status === 'A') {
                    $scope.confirmVoting();
                  } else {
                    if (
                      $scope.selectedUserType[0].userType !== 'NOMI' &&
                      $scope.selectedUserType[0].userType !== 'TRUS'
                    ) {
                      $scope.showTcOwneDialog = true;
                    } else if (
                      $scope.selectedUserType[0].userType === 'NOMI' ||
                      $scope.selectedUserType[0].userType === 'TRUS'
                    ) {
                      $scope.showTcNomiTrustDialog = true;
                    }
                  }
                }
              },
              function (error) {
                $log.debug(error);
              }
            );
        }
      };
      // Below is the function to submit voting instructions in different workstations
      $scope.confirmVoting = function () {
        var splitVoteError = false;
        if ($scope.shTitleRequired) {
          $scope.shTitle = $scope.shareHolderVO.selectedTitle[0].title;
        } else {
          $scope.shTitle = null;
        }
        $scope.saveAgendaCollection = [];
        if ($scope.selectedVote === 'none') {
          angular.forEach($scope.agendaCollection, function (item) {
            if (item.selectedVote === undefined && item.isVotable !== 'N') {
              if (item.splitVotes === true) {
                $scope.CalculateSumOfShares(item);
                $scope.errormessage = '';
                return;
              } else {
                $scope.flag = 1;
                $scope.errormessage = $filter('translate')(
                  'votingInstruction_error_msg'
                );
              }
            }
          });
        }
        if ($scope.flag === 0 && $scope.noOfSharesErrorFlag === 0) {
          $scope.loadingSpin = true;
          if (
            $scope.selectedQuantityNoOfShares != null &&
            $scope.selectedQuantityNoOfShares !== ''
          ) {
            $scope.noOfSecurities = $scope.selectedQuantityNoOfShares;
            $scope.entireHoldingRadio = 'N';
          } else {
            $scope.noOfSecurities = $scope.noOfShares;
            $scope.entireHoldingRadio = 'Y';
          }

          $scope.selectedIntermediary = null;

          angular.forEach($scope.agendaCollection, function (item) {
            if (item.splitVotes === true) {
              item.splitVote = 'Y';
            } else {
              item.splitVote = 'N';
            }
            item.noOfSecurities = item.totalSplitVotes;
            if (
              item.voteFor === 0 &&
              item.voteAgainst === 0 &&
              item.voteAbstain === 0 &&
              !$scope.noVotePatternErr
            ) {
              if (item.isVotable !== 'N') {
                $scope.errormessage = $filter('translate')(
                  'votingInstruction_error_msg'
                );
                $scope.noVotePatternErr = true;
                $scope.loadingSpin = false;
              }
            } else if (
              item.totalNumberSharesError !== '' &&
              item.totalNumberSharesError &&
              item.splitVote === 'Y' &&
              item.isVotable !== 'N'
            ) {
              $scope.noVotePatternErr = true;
              splitVoteError = true;
              $scope.loadingSpin = false;
            } else {
              //push only those agenda items which are votable. Else, it impacts the reports.
              if (item.isVotable !== 'N') {
                if (item.splitVote === 'N') {
                  if (item.voteFor > 0) {
                    item.voteFor = item.totalSplitVotes;
                  } else if (item.voteAgainst) {
                    item.voteAgainst = item.totalSplitVotes;
                  } else if (item.voteAbstain) {
                    item.voteAbstain = item.totalSplitVotes;
                  }
                }
              }
              //push only those agenda items which are votable. Else, it impacts the reports.
              if (item.isVotable !== 'N') {
                $scope.saveAgendaCollection.push({
                  agendaId: item.agendaId,
                  splitVote: item.splitVote,
                  voteFor: item.voteFor,
                  voteAgainst: item.voteAgainst,
                  voteAbstain: item.voteAbstain,
                  voteId: item.voteId,
                });
              }
            }
          });
          if (!$scope.noVotePatternErr) {
            //SFCBP-2489 confirm/save button not enabled for normal intermediary
            var linkedIntermediaryId = '';
            var intermediaryName = '';
            if ($scope.selectedLinkedInt) {
              linkedIntermediaryId = $scope.selectedLinkedInt.intermediaryId;
              intermediaryName = $scope.selectedLinkedInt.intermediaryName;
            }
            $scope.VotingInstructionConfirmationVO = {
              meetingId: $stateParams.meetingId,
              workStationType: $scope.userType,
              instructionId: $scope.instructionId,
              shareholderId: $scope.shareholderId,
              shareClassId: $scope.selectedSecurityClass.shareclassId,
              linkedIntermediaryId: linkedIntermediaryId,
              intermediaryName: intermediaryName,
              prxoyIntermediaryFlag: $scope.prxoyIntermediaryFlag,
              intermediaryId: $scope.intermediaryId,
              registrationNo: $scope.registrationNo,
              enitireHolding: $scope.entireHoldingRadio,
              nosOfSecurities: parseInt($scope.noOfSecurities, 10),
              voteCollectorId: $scope.selectedVC.vcID,
              userVcType: $scope.selectedVC.userVcType,
              filePath: $scope.filePath,
              fileStatus: $scope.fileStatus,
              fileName: $scope.fileName,
              editStatus: $scope.status,
              registrationSrc: $stateParams.createdBy,
              languageCode: 'EN',
              shareHolderVo: {
                shareholderId: $scope.shareholderId,
                title: $scope.shTitle,
                initials: $scope.shareHolderVO.initials,
                firstName: $scope.profileDetails.firstName,
                lastName: $scope.profileDetails.lastName,
                contactPerson: $scope.profileDetails.contactPerson,
                legalEntityIdentifier:
                  $scope.profileDetails.legalEntityIdentifier,
                dob: $scope.profileDetails.dob,
                address: $scope.profileDetails.address,
                emailAddress: $scope.profileDetails.emailAddress,
                postalCode: $scope.profileDetails.postalCode,
                phoneNumber: $scope.profileDetails.phoneNumber,
                city: $scope.profileDetails.city,
                country: $scope.selectedCountry.isoCountryCode,
                isin: $scope.voteisin,
                securityAccountNumber: $scope.securityAccountNumber,
                proxyId: 0,
                shareHolderType: $scope.shareHolderType[0].type,
                owneType: $scope.selectedUserType[0].userType,
                shareholderPostition: $scope.shareholderPostition,
              },
              loginUserId: $sessionStorage.userId,
              votingInstAgendaVOlist: $scope.saveAgendaCollection,
              issuerId: $stateParams.issuerId,
            };

            voteInstructionService
              .updateVotingInstruction(
                $scope.VotingInstructionConfirmationVO,
                'intermediary'
              )
              .then(
                function (response) {
                  if (response) {
                    $scope.loadingSpin = false;
                    document.getElementById('backButton').click();
                  }
                },
                function (error) {
                  $scope.message = null;
                  $scope.loadingSpin = false;
                  $scope.errormessage = error.data.errors[0].errorMessage;
                  $scope.modalBody = error.data.errors[0].errorMessage;
                  $scope.disableModalBtn = true;
                  //Below service call is added to send email to agent when the number of shares entered exceeds the total number of securities available
                  if (error.data.errors[0].errorCode === 'CBP_ERR_520') {
                    $scope.EmailData = {
                      votingInstType: 'vote',
                      languageCode: 'EN',
                      workStationType: $scope.userType,
                      meetingId: $stateParams.meetingId,
                      shareClassId: $scope.selectedSecurityClass.shareclassId,
                      intermediaryId: $scope.intermediaryId,
                      issuerId: $stateParams.issuerId,
                      loginUserId: $sessionStorage.userId,
                    };
                    voteInstructionService
                      .sendSharesExceededMail($scope.EmailData, 'secureToken')
                      .then(
                        function (response) {
                          $log.debug(response);
                        },
                        function (error) {
                          $log.debug(error);
                        }
                      );
                  }
                }
              );
          } else {
            if (!splitVoteError) {
              $scope.errormessage = $filter('translate')(
                'votingInstruction_error_msg'
              );
            }
            $scope.loadingSpin = false;
          }
        }
      };
      /* download BO File starts here */
      $scope.downloadBOFile = function () {
        var downloadUrl =
          '/v2/gm/intermediary/voting-instructions/' +
          $scope.instructionId +
          '/bo-document';
        var a = document.createElement('a');
        document.body.appendChild(a);
        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            let fileVO = response;
            if (window.navigator.msSaveOrOpenBlob) {
              a.onclick = function () {
                window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
              };
              a.click();
            } else {
              var fileURL = URL.createObjectURL(fileVO.file);
              a.href = fileURL;
              a.download = fileVO.fileName;
              a.click();
            }
            downloadUrl = '';
          },
          function (error) {
            $log.debug(error);
          }
        );
      };

      /* download BO File ends here */
      // Call the function for first time.
      $scope.onLoadVI();

      $scope.formattedLocationString = () => {
        return formatLocationString([
          $scope.selectedSecurityClass?.locationOnTicket,
          $scope.selectedSecurityClass?.street,
          $scope.selectedSecurityClass?.postalCode,
          $scope.selectedSecurityClass?.city,
        ]);
      };
    },
  ]);
