import './workstation-selection.scss';

export default {
  controller: [
    function () {
      this.workstations = [
        {
          userType: 'AG',
          label: 'Agent',
          icon: 'pr_profiles_wealth',
          href: './agent',
        },
        {
          userType: 'IS',
          label: 'Issuer',
          icon: 'pr_building_appartment',
          href: './issuer',
        },
        {
          userType: 'IN',
          label: 'Intermediary',
          icon: 'pr_addressbooks_addressbook_open_lines',
          href: './intermediary',
        },
        {
          userType: 'VC',
          label: 'Vote collector',
          icon: 'pr_insurances_vault',
          href: './votecollector',
        },
        {
          userType: 'SP',
          label: 'Shareplan holder',
          icon: 'pr_paper',
          href: './shareplan',
        },
        {
          userType: 'SH',
          label: 'Shareholder',
          icon: 'pr_investments_securities',
          href: './shareholder',
        },
      ];
    },
  ],
  templateUrl: 'features/modules/login/workstation-selection.template.html',
};
