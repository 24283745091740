/**
 * @ngdoc controller
 * @name userMaintenanceModule.IntermediaryGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the list of intermediary groups
 * @requires userGroupService is the service which is being used to interact
 *           with REST
 * @requires $scope,$sessionStorage for storing/passing information in
 *           scope/session
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter the group name and translate messages from locale
 *           file
 * @requires PaginationSvc for pagination
 * @requires $stateParams
 */

angular
  .module('userMaintenanceModule')
  .controller('IntermediaryGroupController', [
    '$state',
    '$scope',
    '$log',
    '$sessionStorage',
    '$filter',
    'userGroupService',
    'PaginationSvc',
    '$stateParams',
    function (
      $state,
      $scope,
      $log,
      $sessionStorage,
      $filter,
      userGroupService,
      PaginationSvc,
      $stateParams
    ) {
      $scope.userGroup = 'intermediary group';
      $scope.userType = 'IN';
      $scope.productCode = $sessionStorage.productType;
      $scope.intermediary = true;
      // $scope.loading is used for spinner
      $scope.loading = false;
      var page = 1;
      if ($stateParams.pageNo !== undefined && $stateParams.pageNo !== null) {
        page = $stateParams.pageNo;
      }
      // call getIntermediaryGroup from userGroupService to
      // fetch intermediary groups
      $scope.getIntermediaryGroup = function () {
        $scope.loading = true;
        $scope.groupData = [];
        userGroupService
          .getIntermediaryGroup('GM', 'EN')
          .query()
          .$promise.then(
            function (data) {
              data.sort((a, b) =>
                b.modifiedDatetime > a.modifiedDatetime ? 1 : -1
              );
              angular.forEach(data, function (intermediary) {
                if (intermediary.intermediaryVO.proxyCode !== 'P') {
                  $scope.groupData.push(intermediary);
                }
              });
              $scope.dummyItems = $scope.groupData;
              $scope.pager = {};
              $scope.setPage(page);
              $scope.loading = false;
            },
            function (error) {
              $scope.loading = false;
              return error;
            }
          );
      };
      // Below function is to search the groups related to entry
      // in that search box
      $scope.onGroupNameChange = function (val) {
        $scope.dummyItems = $filter('filter')($scope.groupData, {
          groupName: val,
        });
        $scope.pager = {};
        $scope.setPage(page);
      };
      // Below function is for pagination, it calls from HTML on
      // clicking of each page
      $scope.setPage = function (page) {
        $scope.pager = [];
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }

        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
        $log.debug($scope.pager);
        // get current page of items
        $scope.viewUserGroup = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
        $scope.pageNo = page;
      };
      // To route to add intermediary group screen
      $scope.addUserGrp = function () {
        $state.go('userMaintenance.addIntermediaryGroup');
      };
      // Below is function call from HTML for modal window.
      $scope.deleteModal = function (groupId, groupName, status, userGroup) {
        if (status === 'Enabled') {
          $scope.statusAction = 'disable';
        } else if (status === 'Disabled') {
          $scope.statusAction = 'enable';
        }
        $scope.modalHeader =
          $filter('translate')('label_confirm') +
          ' ' +
          $scope.statusAction +
          ' ' +
          userGroup +
          '!';
        $scope.modalBody =
          $filter('translate')('label_areYouSureModal') +
          $scope.statusAction +
          $filter('translate')('label_thisGroup');
        $scope.modalFooter = '';
        $scope.modalLeftButton = $filter('translate')('label_confirm');
        $scope.modalRightButton = $filter('translate')('label_cancel');
        $scope.groupId = groupId;
        $scope.issuerName = groupName;
        $scope.status = status;
      };
      // Below is the function call to enable intermediary group.
      $scope.enableUserGroup = function (groupId) {
        $log.debug('disable intermediary group');
        userGroupService.enableIntermediaryGroup(
          groupId,
          'GM',
          function (response) {
            $scope.userGroup = 'intermediary group';
            $scope.getIntermediaryGroup();
            $scope.success = true;
            $scope.message = response.message;
          },
          function (error) {
            $scope.failure = true;
            $scope.errorMessage = error.errors[0].errorMessage;
          }
        );
      };
      // Below is the function call to disable intermediary group.
      $scope.disableUserGroup = function (groupId) {
        $log.debug('disable intermediary group');
        userGroupService.disableIntermediaryGroup(
          groupId,
          'GM',
          function (response) {
            $scope.userGroup = 'intermediary group';
            $scope.getIntermediaryGroup();
            $scope.success = true;
            $scope.message = response.message;
          },
          function (error) {
            $scope.failure = true;
            $scope.errorMessage = error.errors[0].errorMessage;
          }
        );
      };
      // call the function for first time
      $scope.getIntermediaryGroup();
    },
  ]);
