/**
 * @ngDoc controller
 * @name shareHolderModule.dualFactorAuthenticationCtrl
 * @description This controller is used for dual factor authentication for Virtual Attendance Request
 * @requires
 */
angular.module('shareHolderModule').controller('dualFactorAuthenticationCtrl', [
  '$scope',
  '$state',
  'shareHolderService',
  '$sessionStorage',
  'attendanceRequestService',
  'userGroupService',
  '$filter',
  '$interval',
  function (
    $scope,
    $state,
    shareHolderService,
    $sessionStorage,
    attendanceRequestService,
    userGroupService,
    $filter,
    $interval
  ) {
    var issuerId = $sessionStorage.issuerId;
    var otpRef = '';
    var meetingId = '';

    function init() {
      $scope.onLoadSpinner = true;
      $scope.submitted = false;
      meetingId = $sessionStorage.gmId;
      var lang = $sessionStorage.selectedLang;

      // Below is the function used to fetch issuer logo
      userGroupService.getIssuerGroupLogo(issuerId).then(
        function (response) {
          $scope.issuerGroupDetails = response.data;
        },
        function (error) {
          $log.debug(error);
        }
      );

      //Below function is to get share class list of respective language
      attendanceRequestService
        .getAttendanceRequestView(
          meetingId,
          lang,
          $sessionStorage.groupId,
          $sessionStorage.usertype
        )
        .query()
        .$promise.then(
          function (response) {
            $scope.meetingDate = response.generalMeetingDateMiilis;
            $scope.meetingTime = response.generalMeetingDate.split(' ')[3];
            $scope.timezoneStr = response.timezoneStr;
          },
          function (error) {
            $scope.onLoadSpinner = false;
            return error;
          }
        );

      //getting OTP on page load
      var twoFaOtpObj = {
        virtualAttendanceRequestId: $sessionStorage.virtualReqId,
        shareholderId: $sessionStorage.groupId,
        meetingId: meetingId,
        loginUserId: $sessionStorage.userId,
      };
      $scope.onLoadSpinner = true;
      $scope.startCounter();
      shareHolderService.getAuthenticationOtp(twoFaOtpObj).then(
        function (response) {
          otpRef = response.data.otpRef;
          $scope.onLoadSpinner = false;
          $scope.otpSent = true;
          $scope.resending = false;
          $scope.errorCode = '';
          $scope.errorMessage = '';
        },
        function (error) {
          if (error.data.errors) {
            $scope.errorCode = error.data.errors[0].errorCode;
            $scope.errorMessage = error.data.errors[0].errorMessage;
          } else {
            $scope.errorMessage = error.errorMessage;
          }
          $scope.otpSent = false;
          $scope.resending = false;
          $scope.onLoadSpinner = false;
        }
      );
    }

    //Below function will be called on click of send again link
    $scope.resendOtp = function () {
      $scope.startCounter();
      var twoFaReSendOtpObj = {
        virtualAttendanceRequestId: $sessionStorage.virtualReqId,
        shareholderId: $sessionStorage.groupId,
        meetingId: meetingId,
        loginUserId: $sessionStorage.userId,
      };
      $scope.resending = true;
      $scope.otpSent = false;
      shareHolderService.authenticationResendOtp(twoFaReSendOtpObj).then(
        function (response) {
          otpRef = response.data.otpRef;
          $scope.resending = false;
          $scope.otpSent = true;
          $scope.errorCode = '';
          $scope.errorMessage = '';
        },
        function (error) {
          $scope.resending = false;
          $scope.otpSent = false;
          if (error.data.errors) {
            $scope.errorCode = error.data.errors[0].errorCode;
            $scope.errorMessage = error.data.errors[0].errorMessage;
          } else {
            $scope.errorMessage = error.errorMessage;
          }
        }
      );
    };

    $scope.countdown = 0;

    let stop;
    $scope.startCounter = function () {
      $scope.countdown = 15;
      // Don't start a new startCounter if we are already started
      if (angular.isDefined(stop)) {
        return;
      }
      stop = $interval(function () {
        if ($scope.countdown > 0) {
          $scope.countdown = $scope.countdown - 1;
        } else {
          $scope.stopCounter();
        }
      }, 1000);
    };

    $scope.resetCounter = function () {
      $scope.countdown = 15;
    };

    $scope.stopCounter = function () {
      if (angular.isDefined(stop)) {
        $interval.cancel(stop);
        $scope.countdown = 0;
        stop = undefined;
      }
    };

    $scope.$on('$destroy', function () {
      // Make sure that the interval is destroyed too
      $scope.stopCounter();
    });

    //Below function is to join meeting
    $scope.joinMeeting = function () {
      $scope.submitted = true;
      $scope.startCounter();
      $sessionStorage.gmId = null;
      var joinObj = {
        meetingId: meetingId,
        virtualAttendanceRequestId: $sessionStorage.virtualReqId,
        otpRef: otpRef,
        otpCode: $scope.otpCode,
        loginUserId: $sessionStorage.userId,
      };
      shareHolderService.authenticationVerifyOtp(joinObj).then(
        function (response) {
          const redirectToVirtualParticipation =
            'oauth/authorize?response_type=&client_id=&redirect_uri=&' +
            'uuid=' +
            response.data.uuid +
            '&meetingId=' +
            response.data.virtualMeetingId +
            '&userId=' +
            $sessionStorage.userId;
          $scope.virtualParticipationProvider =
            window.location.origin + '/' + redirectToVirtualParticipation;

          $scope.showConditionsModal = true;
          $scope.resending = false;
          $scope.otpSent = false;
          $scope.stopCounter();
        },
        function (error) {
          $scope.submitted = false;
          $scope.resending = false;
          $scope.otpSent = false;
          $scope.errorMessage = $filter('translate')(
            'label_authentication_generic_message'
          );
          if (error.data.errors) {
            $scope.errorCode = error.data.errors[0].errorCode;
          }
        }
      );
    };

    $scope.navigateToVirtualParticipationProvider = function () {
      window.open($scope.virtualParticipationProvider, '_blank');
      $state.go(
        '2FaConfirmation',
        {
          selectedIndex: 0,
        },
        {
          location: true,
        }
      );
    };

    init();
  },
]);
