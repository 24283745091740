/**
 * @ngdoc controller
 * @name userMaintenanceModule.ViewIssuerController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the individual user details of an issuer
 *              group.
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 */

angular.module('userMaintenanceDwtModule').controller(
  'ViewIssuerDwtController',
  [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$sessionStorage',
    'userService',
    function($scope, $log, $state, $stateParams, $sessionStorage,
      userService) {

      var userId = $stateParams.userId;
      var userType = $stateParams.userType;
      var groupId = $stateParams.groupId;
      $scope.prodType = $sessionStorage.productType;
      $scope.user = 'issuer';
      // Below is the call to interact with service and get user
      // details.
      userService.getViewUser(userId, userType, groupId,
        $scope.prodType).query().$promise.then(function(data) {
        $log.debug('success user view');
        $scope.viewUserDetails = data;
      }, function(error) {
        $log.debug(error);
      });

    }]);
