/**
 * @ngdoc AgentGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with agent group.
 * @requires agentGroup is a variable passed from userMaintenanceModule where
 *           there is a service call to userGroupService
 * @requires $scope for storing information in scope
 */

angular.module('userMaintenanceModule').controller('AgentGroupController',['$scope', 'agentGroup',
  function($scope, agentGroup) {
    return agentGroup;

  }]);