/**
 * @ngdoc controller
 * @name userMaintenanceDwtModule.AddIntermediaryDwtController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with adding new intermediary user.
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 */

angular
  .module('userMaintenanceDwtModule')
  .controller(
    'viewPeShareholderDwtController',
    [
      '$scope',
      '$stateParams',
      '$sessionStorage',
      'userService',
      '$log',
      'fileDownloadSvc',
      function($scope, $stateParams, $sessionStorage,
        userService, $log, fileDownloadSvc) {
        $scope.userGroupId = $stateParams.userGroupId;
        $scope.shareholderId = $stateParams.shareholderId;
        $scope.userId = $sessionStorage.userId;
        userService.getShareholder($scope.userGroupId,
          $scope.shareholderId, 'DWT', $scope.userId)
          .query().$promise.then(function(data) {
            $scope.viewUserDetails = data;
          }, function(error) {
            $log.debug(error);
          });
        $scope.downloadshIntIdPDF = function(shIntId) {
          var downloadUrl = 'v1/peshareholders/intermediary/entitlement/'
                                + shIntId + '/' + $scope.userId + '/download';
          var a = document.createElement('a');
          document.body.appendChild(a);
          fileDownloadSvc.downloadFile(downloadUrl).then(
            function(response) {
              let fileVO = response;
              if (window.navigator.msSaveOrOpenBlob) {
                a.onclick = (function() {
                  window.navigator.msSaveOrOpenBlob(
                    fileVO.file, fileVO.fileName);
                });
                a.click();
              } else {
                var fileURL = URL
                  .createObjectURL(fileVO.file);
                a.href = fileURL;
                a.download = fileVO.fileName;
                a.click();
              }
              downloadUrl = '';
            }, function(error) {
              $log.debug(error);
            });
        };
      }]);
