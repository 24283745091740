/**
 * @ngdoc controller
 * @name generalMeetingHomeModule.InviteSharePlanController
 * @description This controller is used for general meeting home module. This
 *              mainly deals with sending share plan invitation to shareholders
 *              from agent.
 * @requires AgentService,MyAccountsService and $http are the services which are
 *           being used to interact with REST
 * @requires $state for state routing
 * @requires $scope,$stateParams for storing/passing information in scope/state
 .
 * @requires $sessionStorage for fetching the logged in user details from
 *           session storage
 */
'use strict';

import './invite-share-plan.scss';

angular
  .module('generalMeetingHomeModule')
  .controller('InviteSharePlanController', [
    '$scope',
    'AgentService',
    '$http',
    '$filter',
    '$stateParams',
    '$sessionStorage',
    'MyAccountsService',
    'AuditTrailService',
    function (
      $scope,
      AgentService,
      $http,
      $filter,
      $stateParams,
      $sessionStorage,
      MyAccountsService,
      AuditTrailService
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      /* download */
      $scope.isLoading = true;
      $scope.gmId = $stateParams.meetingStatus;
      $scope.issuerId = $stateParams.issuerId;
      $scope.gmDate = $stateParams.gmDate;
      //$scope.fromAddress = $stateParams.issuerName;
      $scope.documents = [];
      $scope.documentsfiles = [];
      $scope.checkedFiles = {};
      $scope.includeUploadedFiles = {};
      $scope.saveDisabled = true;
      $scope.languageCode = $sessionStorage.workstationData.selectedLang;
      $scope.inviteSharePlan = 'inviteSharePlan';
      $scope.documents = $stateParams.documents;
      $scope.emailSubject = $stateParams.emailSubject;
      $scope.emailBody = $stateParams.emailBody;
      $scope.templateData = '';
      let issuerId = null;
      let templateSender = null;
      let templateId = null;
      let templateTitle = null;
      // Below is the service call for first time to load issuer
      // logo.
      MyAccountsService.getIssuerGroupDetails(
        $scope.issuerId,
        $scope.languageCode
      ).then(
        function (response) {
          $scope.fromAddress = response.data.groupEmail;
          $scope.issuerLogoNotAvailable = true;
          $scope.issuerGroupDetails = response.data;
          issuerId = response.data.groupId;
          if ($scope.issuerGroupDetails.logoBaseStr) {
            $scope.issuerLogoNotAvailable = false;
          }
          $scope.isLoading = false;
        },
        function () {
          $scope.issuerLogoNotAvailable = true;
          $scope.loading = false;
        }
      );
      // Below is the function call form HTML to download the
      // share plan template.
      $scope.downloadSharePlanTemplate = function () {
        var pathList = window.location.pathname.split('/');
        if (pathList[1] === 'cbp') {
          $scope.downloadSharePlan =
            window.location.origin +
            '/' +
            pathList[1] +
            '/v1/sharePlan' +
            '?authid=' +
            $sessionStorage.userId;
        } else {
          $scope.downloadSharePlan =
            window.location.origin +
            '/v1/sharePlan' +
            '?authid=' +
            $sessionStorage.userId;
        }
      };
      /* download */
      /* upload */
      // Below method call from HTML on click of upload share
      // plan data.
      $scope.onFileSelect = function (e) {
        $scope.errorStatus = false;
        $scope.saveStatus = null;
        $scope.fileStatus = '';
        let filesList = e.files;
        let file = filesList[0];
        if (file.size > 5242880) {
          $scope.agendaFileSizeError =
            'This file size is: ' +
            (filesList[0].size / 1024 / 1024).toFixed(2) +
            ' MB. Please upload a file less than 5 MB.';
          $scope.saveDisabled = true;
        } else {
          $scope.fileName = file.name;
          $scope.file = file;
          $scope.saveDisabled = false;
          $scope.$apply();
        }
      };

      // Below is the function call from HTML on submitting
      // invite share plan form.
      $scope.saveInviteSharePlan = function () {
        $scope.saveDisabled = true;
        let file = $scope.file;
        let inviteShareplanEmailVO = createEmailRequest();
        $scope.recordCount = 0;
        $scope.isLoading = true;
        $scope.parseErrors = null;

        AgentService.uploadSharePlan(file, inviteShareplanEmailVO).then(
          function (response) {
            $scope.isLoading = false;
            $scope.startInviteProgressBar = true;
            $scope.serviceurl =
              'v1/jobs/' + response.data.jobExecutionId + '/stepStatus';
            $scope.jobExecutionId = response.data.jobExecutionId;
            $scope.fileName = file.name;
            if (response.data.recordCount) {
              $scope.recordCount = response.data.recordCount - 1;
            }
          },
          function (error) {
            if (Array.isArray(error.data)) {
              /**
               * 'Expected' error, data is [{ errorCode,
               * errorMessage, rowNumber }], where errorMessage is the
               * erroneous value
               */
              $scope.parseErrors = error.data;
              $scope.isLoading = false;
              $scope.success = false;
              $scope.errorStatus = true;
            } else {
              // 'Unexpected' error, fallback to original behaviour
              $scope.isLoading = false;
              $scope.success = false;
              $scope.recordCount = 0;
              $scope.saveStatus = error.data.errors[0].errorMessage;
              $scope.errorStatus = true;
            }
          }
        );
      };

      /* upload */

      $scope.selectedDocument = function () {
        // Below change to send English code in every request
        $scope.langCode = 'EN'; //selected.meetingDocAttachList[0].langCode;
      };

      $scope.$watch('templateFile', function (file) {
        if (file) {
          let reader = new FileReader();
          reader.readAsText(file, 'UTF-8');
          reader.onload = function (evt) {
            $scope.templateBody = evt.target.result;
            $scope.$apply();
          };
        }
      });

      $scope.enableEditMode = function () {
        $scope.isLoading = true;
        $scope.templateData = '';

        let enableEditMode = function (templateData) {
          templateId = templateData.data[0].templateId;
          templateSender = templateData.data[0].sender;
          templateTitle = templateData.data[0].templateTitle;
          $scope.templateCode = templateData.data[0].templateCode;
          $scope.templateSubject = templateData.data[0].description1;
          $scope.templateBody = templateData.data[0].description2;
          $scope.issuerIdWOSalt = templateData.data[0].issuerId;

          $scope.isLoading = false;
          $scope.isEditMode = true;
        };

        let templateRequest = {
          templateCode: appConstant.TEMPLATE_CODE_EMAIL,
          dropDownOneKey:
            appConstant.TEMPLATE_TYPE_SHAREPLAN_NEW_USER_NOTIFICATION,
          dropDownTwoId: issuerId,
        };
        AuditTrailService.searchTemplate(templateRequest, 'secureToken').then(
          function (issuerTemplateResponse) {
            if (issuerTemplateResponse.data.length === 0) {
              // Retry without issuer id
              templateRequest.dropDownTwoId = null;
              AuditTrailService.searchTemplate(
                templateRequest,
                'secureToken'
              ).then(
                function (defaultTemplateResponse) {
                  enableEditMode(defaultTemplateResponse);
                },
                function () {
                  $scope.isLoading = false;
                }
              );
            } else {
              enableEditMode(issuerTemplateResponse);
            }
          },
          function () {
            $scope.isLoading = false;
          }
        );
      };

      $scope.cancelEditTemplate = function () {
        $scope.isEditMode = false;
        $scope.templateFile = '';
      };

      $scope.saveTemplate = function () {
        $scope.saveError = '';
        $scope.isLoading = true;
        let subject = $scope.templateSubject;
        if (subject === undefined || subject === null || subject === '') {
          $scope.isLoading = false;
          $scope.saveError = 'Subject text is mandatory';
        } else {
          let updateTemplateData = {
            description1: $scope.templateSubject,
            description2: $scope.templateBody,
            sender: templateSender,
            templateCode: appConstant.TEMPLATE_CODE_EMAIL,
            templateId: templateId,
            issuerId: $scope.issuerIdWOSalt,
            templateTitle: templateTitle,
            languageCode: appConstant.OPTED_EN,
          };
          AuditTrailService.updateTemplate(
            updateTemplateData,
            'secureToken'
          ).then(
            function () {
              AgentService.importDocFiles($scope.gmId).then(
                function (response) {
                  $scope.emailSubject = response.data.emailVO.emailSubject;
                  $scope.emailBody = response.data.emailVO.emailBody;

                  $scope.isLoading = false;
                  $scope.saveConfirmedDialogCtrl.open().then(function () {
                    $scope.isEditMode = false;
                  });
                },
                function () {
                  $scope.isLoading = false;
                }
              );
            },
            function (response) {
              $scope.isLoading = false;
              if (400 === response.status) {
                $scope.saveError = 'Email body text is invalid';
              } else {
                $scope.saveError = response.data.errors[0].errorMessage;
              }
            }
          );
        }
      };

      $scope.confirmSendPreview = function () {
        $scope.isLoading = true;
        let inviteSharePlanData = createEmailRequest();

        inviteSharePlanData.testEmailAddress = $scope.toPreviewAddress;
        AgentService.sendInviteSharePlanPreview(inviteSharePlanData).then(
          function (response) {
            $scope.isLoading = false;
            $scope.checkPreviewStatus = true;
            $scope.previewServiceUrl =
              'v1/jobs/' + response.data.jobExecutionId + '/stepStatus';
            $scope.saveDisabled = true;
          },
          function (error) {
            $scope.isLoading = false;
            $scope.success = false;
            $scope.saveStatus = error.data.errors[0].errorMessage;
            $scope.errorStatus = true;
          }
        );
      };

      $scope.onPreviewStatusCheckSuccess = function (data) {
        if (data.data.batchStatus === 'COMPLETED') {
          $scope.previewStatusMessage = $filter('translate')(
            'label_modal_confirm_preview_sent'
          );
        } else {
          $scope.previewStatusMessage = $filter('translate')(
            'label_modal_failed_sent_failed'
          );
        }
      };

      $scope.onPreviewStatusCheckError = function (data) {
        $scope.previewStatusMessage = $filter('translate')(
          'label_modal_failed_sent_failed'
        );
      };

      function createEmailRequest() {
        //Below line is added to reset the documents array to avoid adding duplicates.-ALM-10983
        let documentsfiles = [];
        angular.forEach($scope.documents, function (docList) {
          if (docList.checked) {
            documentsfiles.push(docList.meetingDocId);
          }
        });
        return {
          userId: $sessionStorage.userId,
          fromAddress: $scope.fromAddress,
          emailSubject: $scope.emailSubject,
          emailBody: $filter('htmlDecode')($scope.emailBody).toString(),
          filePath: $scope.filePath,
          langCode: $scope.langCode,
          meetingDocId: documentsfiles,
          issuerId: $scope.issuerId,
          gmId: $scope.gmId,
        };
      }
    },
  ]);
