export function getVirtualParticipationButtonLabel(gm) {
  const labelMap = { pharming: 'label_virtual_participation_no_voting' };

  if (gm && gm.issuerName) {
    const foundIssuer = Object.keys(labelMap).find((i) =>
      new RegExp(i, 'i').test(gm.issuerName)
    );
    if (foundIssuer) {
      return labelMap[foundIssuer];
    }
  }
  return 'label_virtual_participation';
}
