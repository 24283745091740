import formatLocationString from '@/lib/formatLocationString';
import getTermsOfUseUrl from '@/lib/getTermsOfUseUrl';
import htmlDecode from '@/lib/htmlDecode';
import './voting-instructions.scss';

/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.VotingInstructionController
 * @description This controller is used for voting instruction. This mainly
 *              deals with submitting of voting instruction.
 * @requires voteInstructionService,fileUploadSvc are the
 *           service which is being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 * @requires userGroupService
 */
angular
  .module('generalMeetingHomeModule')
  .controller('VotingInstructionController', [
    '$scope',
    '$state',
    '$filter',
    '$sessionStorage',
    '$stateParams',
    'fileUploadSvc',
    'voteInstructionService',
    'participationMethodService',
    '$log',
    'GeneralMeetingDataLanguageService',
    'LanguagePreferenceService',
    'generalMeetingService',
    '$timeout',
    'ModelWindowService',
    '$uibModal',
    'myVoteService',
    'userGroupService',
    'attendanceRequestService',
    'AuthService',
    '$window',
    function (
      $scope,
      $state,
      $filter,
      $sessionStorage,
      $stateParams,
      fileUploadSvc,
      voteInstructionService,
      participationMethodService,
      $log,
      GeneralMeetingDataLanguageService,
      LanguagePreferenceService,
      generalMeetingService,
      $timeout,
      ModelWindowService,
      $uibModal,
      myVoteService,
      userGroupService,
      attendanceRequestService,
      AuthService,
      $window
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      // Below code is added for default selection of Intermeiary
      //and share class for linked meeting SFCBP-2725
      var votingInstDetails = $stateParams.votingInstDetails;
      var linkedISIN = $stateParams.linkedISIN;
      var selectedIntermediaryName = $stateParams.selectedIntermediaryName;
      if (
        $stateParams.votingInstDetails != null &&
        $stateParams.votingInstDetails[0]
      ) {
        var countryVO = $stateParams.votingInstDetails[0].countryCodeVO;
        var countryVOFR = $stateParams.votingInstDetails[0].countryCodeVOFR;
        var countryVONL = $stateParams.votingInstDetails[0].countryCodeVONL;
      }
      var singleIntermediaryName = '';
      $scope.securityAccountValidation = validationRegex.SECURITY_ACCOUNT_NR;
      $scope.disableConfirm = false;
      $scope.userType = $sessionStorage.usertype;
      $scope.shareClassName = $stateParams.shareClassName;
      $scope.fromPage = $stateParams.fromPage;
      $scope.radioVal = $stateParams.radioVal;
      $scope.dropdownVal = $stateParams.dropdownVal;
      $scope.meetingNameChange = false;
      $scope.showAllFor = true;
      $scope.linkedMeeting = false;
      $scope.allowedParticipationMethodForShareClass = [];
      $scope.availablePartcipationMethods =
          participationMethodService.getVotingParticipationMethods();
      $scope.enableParticipationMethodSelection = false;
      if ($stateParams.action === undefined) {
        $scope.isParticipationMethodSelected = false;
      } else {
        $scope.isParticipationMethodSelected = $stateParams.action === 'edit';
      }
      $scope.selectedParticipationMethod = null;
      if ($stateParams.issuerName) {
        $scope.issuerName = $stateParams.issuerName.toLowerCase();
      }
      $scope.landingThrough = $stateParams.landingThrough;
      // Below is the function used to fetch issuer logo
      userGroupService.getIssuerGroupLogo($stateParams.issuerId).then(
        function (response) {
          $scope.issuerLogoPresent = false;
          $scope.issuerGroupDetails = response.data;
          // Do not change the below condition. It is for
          // issuer logo display fix. REST response has ""
          if ($scope.issuerGroupDetails.logoBaseStr) {
            $scope.issuerLogoPresent = true;
          }
        },
        function (error) {
          $log.debug(error);
        }
      );
      $scope.numberOfSecurities = $stateParams.numberOfSecurities;
      $scope.disableFields = false;
      $scope.holdingRadio = 'Y';
      $scope.showIntermediary = true;
      $scope.shareHolderVO = {};
      $scope.noOfSharesErrorFlag = 0;
      $scope.flag = 0;
      $scope.loading = false;
      $scope.votingExist = false;
      $scope.loadingSpin = false;
      $scope.onLoadSpinner = false;
      $scope.disableConfirm = false;
      $scope.intRequestId = null;
      $scope.disableAddSection = false;
      $scope.eventType = 'Footer Term of Use';
      $scope.availableVC = [];
      $scope.action = $stateParams.action;
      $scope.reqStatus = $stateParams.status;
      $scope.throughUrl = $stateParams.throughUrl;
      $scope.noOfShares = 0;
      $scope.isVirtualMeetingEnabled = $stateParams.virtualMeetingFlag;
      $scope.isAttendEnable = false;
      // set flag to enable disable fields based on the action
      // (view/edit etc)
      if ($stateParams.disabled) {
        $scope.disabled = $stateParams.disabled;
      }

      $scope.languageCode =
        $sessionStorage.workstationData.selectedLang || 'EN';
      // Below value is hard coded to GM because VI and AR
      // functionalities will be there only for GM users.
      // Using productType array from session storage was
      // causing issue in case of integrated users, so it is
      // relpaced with below value.
      $scope.productTypeToU = 'GM';
      // Below if condition handles the shareplan holder related
      // functionalities
      if (
        $stateParams.meetingId &&
        $stateParams.issuerId &&
        $sessionStorage.usertype === 'SP'
      ) {
        $scope.onLoadSpinner = true;
        generalMeetingService
          .getGeneralMeetingView($stateParams.meetingId)
          .query()
          .$promise.then(
            function (data) {
              $scope.onLoadSpinner = false;
              $scope.generalMeetingView = data;
              $scope.isVirtualMeetingEnabled =
                data.gmFeaturesVO.virtualMeetingAllowed;
              $scope.timeZoneString =
                $scope.generalMeetingView.gmScheduleVO.timezoneStr;
              var todaysDate = '';
              generalMeetingService
                .getCurrentCETTime()
                .query()
                .$promise.then(
                  function (data) {
                    if ($scope.timeZoneString === 'CET') {
                      todaysDate = $filter('filter')(
                        data,
                        {
                          timezone: 'CET',
                        },
                        true
                      );
                    } else if ($scope.timeZoneString === 'GMT') {
                      todaysDate = $filter('filter')(
                        data,
                        {
                          timezone: 'GMT',
                        },
                        true
                      );
                    }
                    todaysDate = todaysDate[0].currentMillis;

                    var shareholderAccessDate =
                      $scope.generalMeetingView.gmScheduleVO
                        .shareholderAccessDateMiilis;
                    var shareholderDeadlineDate =
                      $scope.generalMeetingView.gmScheduleVO
                        .shareholderDeadlineDateMiilis;
                    if (shareholderAccessDate > todaysDate) {
                      $scope.disableConfirm = true;
                      $scope.requestError =
                        $filter('translate')('label_youCanAttend') +
                        $scope.generalMeetingView.gmScheduleVO
                          .shareholderAccessDate +
                        ' ' +
                        $scope.timeZoneString;
                    } else if (shareholderDeadlineDate < todaysDate) {
                      $scope.disableConfirm = true;
                      $scope.requestError =
                        $filter('translate')('label_dealineDateError') +
                        $scope.generalMeetingView.gmScheduleVO
                          .shareholderDeadlineDate +
                        ' ' +
                        $scope.timeZoneString;
                    }
                    if ($scope.disableConfirm) {
                      $scope.modalHeader = '';
                      $scope.modalBody = $scope.requestError;
                      ModelWindowService.showModelWindow('openAlertModal');
                    }
                  },
                  function (error) {
                    $log.debug(error);
                  }
                );
            },
            function (error) {
              $log.debug(error);
              $scope.onLoadSpinner = false;
            }
          );
      }
      $scope.goToDetailsPage = function () {
        $state.go('generalMeetings', {
          selectedIndex: 0,
          meetingId: $sessionStorage.gmId,
        });
      };
      /* date picker */
      $scope.inlineOptions = {
        customClass: getDayClass,
      };
      $scope.open1 = function () {
        $scope.popup1.opened = true;
      };
      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };
      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.popup1 = {
        opened: false,
      };

      function disabled(data) {
        var date, mode;
        date = data.date;
        mode = data.mode;
        $log.debug(date);
        $log.debug(mode);
      }

      $scope.dateOptions = {
        dateDisabled: disabled,
        formatYear: 'yy',
        maxDate: new Date(),
        //removed min validation for date picker -SFCBP-1977
        showWeeks: false,
      };

      function getDayClass(data) {
        var date = data.date,
          mode = data.mode;
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(
              0,
              0,
              0,
              0
            );
            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }
        return '';
      }

      /* date picker end */
      $scope.selectedSHType = function () {
        if ($scope.shareHolderType[0].type === 'LGL') {
          $scope.shTitleRequired = false;
        } else {
          $scope.shTitleRequired = true;
        }
      };
      $scope.onTitleChange = function () {
        $scope.shTitleRequired = true;
      };
      // below function call to show modal window for binkbank/alex intermediary
      $scope.getModalWindow = function () {
        $scope.modalHeader = $filter('translate')('label_votingInsCreatErr');
        $scope.modalLeftButton = $filter('translate')('label_cancel');
        $scope.modalRightButton =
          $filter('translate')('label_proceedToLogout') +
          singleIntermediaryName;
        $scope.modalBody =
          $filter('translate')('label_asACustomerOf') +
          singleIntermediaryName +
          $filter('translate')('label_alex_binkBank_warning') +
          ' ' +
          singleIntermediaryName;
        $('#logoutOrRedirect').modal('show');
      };

      function getLanguage(selectedSecurityClass) {
        switch ($sessionStorage.workstationData.selectedLang) {
          case appConstant.OPTED_EN:
            return selectedSecurityClass.gmDatesBasedOnLanguage[0]
              .generalMeetingDateLang;
          case appConstant.OPTED_NL:
            return selectedSecurityClass.gmDatesBasedOnLanguage[2]
              .generalMeetingDateLang;
          case appConstant.OPTED_FR:
            return selectedSecurityClass.gmDatesBasedOnLanguage[1]
              .generalMeetingDateLang;
          default:
        }
        return appConstant.OPTED_EN;
      }

      $scope.getParticipationMethods = () => {
        participationMethodService.getParticipationMethods(
            $stateParams.meetingId, $scope.shareclassId)
        .then(
            function (response) {
              // Reset the ParticipationMethod dropdown value when shareclass is changed
              $scope.selectedParticipationMethod = null;
              $scope.allowedParticipationMethodForShareClass = response.data.participationMethods;
              const isVoteByCorrespondence = $scope.allowedParticipationMethodForShareClass.includes(
                  'EVOT');
              const isParticipationByProxy = $scope.allowedParticipationMethodForShareClass.includes(
                  'PRXY');
              $scope.isAttendEnabled = $scope.allowedParticipationMethodForShareClass.includes(
                  'PHYS');
              // It's essential to maintain state, to enable ParticipationMethodSelection dropdown
              $scope.enableParticipationMethodSelection = isVoteByCorrespondence
                  && isParticipationByProxy;
              if (!$scope.enableParticipationMethodSelection) {
                $scope.participationMethod = isVoteByCorrespondence ? 'EVOT'
                    : 'PRXY';
                // isParticipationMethodSelected is set true if Shareclass doesn't have multiple participation methods
                $scope.isParticipationMethodSelected = true;
              }
            },
            function (error) {
              $log.debug(error);
              $scope.errorCode = error.data.errors[0].errorCode;
            });
      };

      // Below is the function call on drop-down change
      $scope.onSecurityClassChange = function (selectedSecurityClass) {
        $scope.disableConfirm = false;
        $scope.errorForAlexBink = '';

        if (selectedSecurityClass) {
          // Get the language based date
          if (selectedSecurityClass.gmDatesBasedOnLanguage.length > 1) {
            $scope.langDate = getLanguage(selectedSecurityClass);
          }
          if (selectedSecurityClass.vcShareclassAssocVO.length > 1) {
            $scope.morethanOneVC = true;
          } else if (selectedSecurityClass.vcShareclassAssocVO.length === 1) {
            $scope.morethanOneVC = false;
            $scope.selectedVC = selectedSecurityClass.vcShareclassAssocVO[0];
          } else {
            // Vote by correspondence case
            $scope.morethanOneVC = false;
            $scope.shareclassId = selectedSecurityClass.shareclassId;
            $scope.shareclassIdWOSalt =
                selectedSecurityClass.shareclassIdWOSalt;
          }
          $scope.availableVoteCollectors = [];
          angular.forEach(selectedSecurityClass.vcShareclassAssocVO, function (
              key
          ) {
            key.nameOfVC =
                key.title +
                ' ' +
                key.firstName +
                ' ' +
                key.lastName +
                ' ' +
                $filter('translate')('vi_shareholder_disclaimer2') +
                ' ' +
                key.vcName;
            $scope.availableVoteCollectors.push(key);
            if (
                $scope.availableVoteCollectors.length === 1 &&
                !$scope.vcPresent
            ) {
              $scope.selectedVC = $scope.availableVoteCollectors[0];
            }
            $scope.shareclassId = selectedSecurityClass.shareclassId;
            $scope.shareclassIdWOSalt =
                selectedSecurityClass.shareclassIdWOSalt;

          });
          $scope.getParticipationMethods();
        }
        /**
         * The following code is an important one. For shareholder ws, during creation of VI, if the selected security class has more than one
         * intermediary, then a dropdown needs to be shown. Else, no dropdown. Also, when there is more than one intermediary (All intermediaries)
         * and the shareholder has selected preferred intermediary while registration, then the preferred intermediary should be selected by default.
         * If the agent has created GM with shareclass having only one intermediary, then it takes precedence over the shareholder preference.
         */
        if (
          selectedSecurityClass &&
          selectedSecurityClass.intermediaryVO &&
          $sessionStorage.usertype === 'SH' &&
          $scope.action !== 'edit'
        ) {
          if (selectedSecurityClass.intermediaryVO.length <= 1) {
            $scope.morethanOneIntermediary = false;
            $scope.oneIntermediary = true;
            $scope.selectedIntermediary =
              selectedSecurityClass.intermediaryVO[0];
            $scope.intermediaryid =
              selectedSecurityClass.intermediaryVO[0].intermediaryId;
            singleIntermediaryName =
              selectedSecurityClass.intermediaryVO[0].intermediaryName;
          } else {
            $scope.morethanOneIntermediary = true;
            $scope.oneIntermediary = false;
            // Below code is added for default selection of Intermeiary
            //and share class for linked meeting SFCBP-2725
            if (
              linkedISIN &&
              selectedIntermediaryName &&
              $scope.userType === 'SH'
            ) {
              if (selectedSecurityClass) {
                $scope.selectedIntermediary = $filter('filter')(
                  selectedSecurityClass.intermediaryVO,
                  {
                    intermediaryName: selectedIntermediaryName,
                  },
                  true
                )[0];
                singleIntermediaryName =
                  $scope.selectedIntermediary.intermediaryName;
              }
            } else {
              $scope.selectedIntermediary =
                selectedSecurityClass.intermediaryVO[0];
              singleIntermediaryName =
                selectedSecurityClass.intermediaryVO[0].intermediaryName;
            }
            $scope.intermediaryid = $scope.selectedIntermediary.intermediaryId;
          }
        }
        $scope.availableIntermediaries = [];
        if (
          $scope.morethanOneIntermediary === true &&
          $scope.oneIntermediary === false
        ) {
          angular.forEach(selectedSecurityClass.intermediaryVO, function (
            key2
          ) {
            $scope.availableIntermediaries.push(key2);
            // Below code is added for default selection of Intermediary
            //and share class for linked meeting SFCBP-2725
            //!linkedISIN && !selectedIntermediaryName -  added this condition to prevent going inside that if condition when any one value is there
            if (
              linkedISIN &&
              selectedIntermediaryName &&
              $scope.userType === 'SH'
            ) {
              if (selectedIntermediaryName) {
                $scope.selectedIntermediary = $filter('filter')(
                  selectedSecurityClass.intermediaryVO,
                  {
                    intermediaryName: selectedIntermediaryName,
                  },
                  true
                )[0];
                selectedIntermediaryName = '';
              }
            } else if (
              $scope.availableIntermediaries.length === 1 &&
              !$scope.intermediaryPresent &&
              !linkedISIN &&
              !selectedIntermediaryName
            ) {
              $scope.selectedIntermediary = $scope.availableIntermediaries[0];
            } else if (
              selectedSecurityClass.intermediaryIdWoSalt ===
                key2.intermediaryIdWoSalt &&
              !linkedISIN &&
              !selectedIntermediaryName
            ) {
              $scope.selectedIntermediary = key2;
              singleIntermediaryName =
                $scope.selectedIntermediary.intermediaryName;
            }
            $scope.intermediaryid = $scope.selectedIntermediary.intermediaryId;
          });
        }
        $scope.getVISubmit = function () {
          voteInstructionService
            .getRegistrationList(
              $stateParams.meetingId,
              null,
              $scope.shareclassId,
              $sessionStorage.groupId,
              $scope.intermediaryid,
              $scope.userType
            )
            .then(
              function (response) {
                $scope.registrationList =
                  response.data.shareholderRegistrationDetailVO;
                $scope.votingExist = response.data.votingExist;
                $scope.securityAccountNumber =
                  response.data.shareHolderVO.securityAccountNumber;
                $scope.agenda();
              },
              function (error) {
                $log.debug(error);
                $scope.errorCode = error.data.errors[0].errorCode;
              }
            );
        };
        $scope.getVISubmit();
        $scope.selectedQuantityNoOfShares = null;
        $scope.entireHoldingRadioVal = false;
        angular.forEach($scope.votingInsructionData, function (item) {
          if (item.shareClassName === selectedSecurityClass.shareClassName) {
            $scope.voteisin = item.isin;
            if (item.shareholderPosition) {
              $scope.noOfShares = item.shareholderPosition;
            }
            $scope.intermediaryVO = item.intermediaryVO;
            $scope.shareClassId = item.shareclassId;
            $scope.instructionId = item.instructionId;
            $scope.shareholderPostition = item.shareholderPostition;
            $scope.abnIntermediaryId = item.abnIntermdiaryId;
          }
        });
        if (
          null != $scope.selectedIntermediary &&
          $scope.selectedIntermediary !== '' &&
          $scope.action !== 'edit'
        ) {
          $scope.intermediaryId = $scope.selectedIntermediary.intermediaryId;
        }
        //For Shareplan intermediary dropdwon will not be there. so below is the call to check whether voting is existing.
        if (
          $scope.userType === 'SP' &&
          $scope.action !== 'edit' &&
          $scope.action !== 'view'
        ) {
          var selectedIntermediary = { intermediaryId: null };
          $scope.onintermediaryChange(selectedIntermediary);
        }
        if (
          singleIntermediaryName === appConstant.INTERMEDIARY_BINCKBANK ||
          singleIntermediaryName === appConstant.INTERMEDIARY_ALEX
        ) {
          $scope.getModalWindow();
        }
      };
      //Below method will logout and redirect the user to particular intermediary websites
      $scope.logoutAndRedirect = function () {
        /**
         * Below function is for logout process and reset the
         * session storage and workstation related data
         * and navigates to the login page
         */
        $scope.logout = function (reloadUrl, website) {
          AuthService.logout().then(function (data) {
            if (data.status === 200) {
              $timeout(function () {
                $window.location.href = website;
              }, 100);
              $sessionStorage.workstationData = {};
              $sessionStorage.workstationData.selectedTab = 0;
              $sessionStorage.workstationData.selectedLang = 'EN';
              $sessionStorage.jobData = [];
              stop();
              delete $sessionStorage.groupName;
              $sessionStorage.$reset();
            } else {
              $log.debug(data.data.errorMessage);
            }
          });
        };
        if (singleIntermediaryName === appConstant.INTERMEDIARY_BINCKBANK) {
          $scope.logout(
            $scope.logoutURL,
            appConstant.INTERMEDIARY_BINCKBANK_WEBISTE
          );
        } else if (singleIntermediaryName === appConstant.INTERMEDIARY_ALEX) {
          $scope.logout(
            $scope.logoutURL,
            appConstant.INTERMEDIARY_ALEX_WEBISTE
          );
        }
      };
      // Below is the function call on drop-down change
      $scope.onVCChange = function (selectedVC) {
        $scope.selectedVC = selectedVC;
      };
      $scope.onParticipationMethodChange = function (selectedParticipationMethod) {
        $scope.isParticipationMethodSelected = true;
        $scope.selectedParticipationMethod = selectedParticipationMethod;
        $scope.participationMethod = selectedParticipationMethod.value;
      };
      $scope.onintermediaryChange = function (selectedIntermediary) {
        $scope.selectedIntermediary = selectedIntermediary;
        $scope.intermediaryid = selectedIntermediary.intermediaryId;
        singleIntermediaryName = $scope.selectedIntermediary.intermediaryName;
        if (
          $scope.selectedIntermediary.intermediaryName ===
            appConstant.INTERMEDIARY_BINCKBANK ||
          $scope.selectedIntermediary.intermediaryName ===
            appConstant.INTERMEDIARY_ALEX
        ) {
          $scope.getModalWindow();
        } else {
          $scope.getVISubmit();
        }
      };

      // Below is the function call to fetch agenda items
      $scope.agenda = function () {
        $scope.message = '';
        //to reset the number of share's in partial holding when user changes intermediary.
        $scope.selectedQuantityNoOfShares = null;
        $scope.totalNumberSharesError = '';
        $scope.errormessage = '';
        if (!$scope.shareholderId) {
          $scope.shareholderId = $sessionStorage.groupId;
        }
        voteInstructionService
          .getAgendaDetails(
            $stateParams.meetingId,
            $scope.languageCode,
            $scope.shareholderId,
            $scope.shareClassId,
            $scope.intermediaryid,
            $scope.userType
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.agendaCollection = data;
              if (data.length <= 0 && $scope.languageCode !== 'EN') {
                $scope.languageCode = 'EN';
                $scope.agenda();
              } else {
                angular.forEach($scope.agendaCollection, function (item) {
                  if (item.noOfSecurities && item.entireHolding === 'Y') {
                    $scope.noOfShares = item.noOfSecurities;
                  } else if (
                    item.noOfSecurities &&
                    item.entireHolding === 'N'
                  ) {
                    $scope.entireHoldingRadioVal = true;
                    $scope.selectedQuantityNoOfShares = item.noOfSecurities;
                  } else {
                    $scope.entireHoldingRadioVal = false;
                  }
                  if ($scope.noOfShares) {
                    item.totalSplitVotes = $scope.noOfShares;
                  } else {
                    item.totalSplitVotes = 0;
                  }
                  item.splitVotes = false;
                  $scope.selectedVote = 'none';
                  item.disableSplitVote = true;
                  item.disableRadio = true;
                  $scope.instructionId = item.instructionId;
                  $scope.registrationNo = item.registrationNo;
                  if (item.splitVoteAllwdFrmInter === 'N') {
                    item.disableSplitVote = false;
                  }
                  if ($scope.userType !== 'IN') {
                    if (item.voteFor !== 0) {
                      item.selectedVote = 'for';
                    } else {
                      item.voteFor = 0;
                    }
                    if (item.voteAgainst !== 0) {
                      item.selectedVote = 'against';
                    } else {
                      item.voteAgainst = 0;
                    }
                    if (item.voteAbstain !== 0) {
                      item.selectedVote = 'abstained';
                    } else {
                      item.voteAbstain = 0;
                    }
                  }
                });
                angular.forEach($scope.agendaCollection, function (key2) {
                  if (key2.isVotable === 'Y') {
                    $scope.isVotableFlag = true;
                  }
                  return $scope.isVotableFlag;
                });
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };

      $scope.processVotingInstructionData = function () {
        if (
          undefined !== votingInstDetails.length &&
          votingInstDetails.length >= 1
        ) {
          $scope.votingInsructionData = votingInstDetails;
          if (
            $scope.userType === 'SP' &&
            undefined !== $stateParams.sharePlanloginShareClassId &&
            null !== $stateParams.sharePlanloginShareClassId
          ) {
            angular.forEach($scope.votingInsructionData, function (value, key) {
              if (
                $stateParams.sharePlanloginShareClassId ===
                $scope.votingInsructionData[key].shareclassIdWOSalt
              ) {
                $scope.selectedSecurityClass = $scope.votingInsructionData[key];
                $scope.onSecurityClassChange($scope.selectedSecurityClass);
              }
            });
          } else if ($scope.fromPage === 'GM' && $scope.userType !== 'IN') {
            $scope.disableFields = true;
            angular.forEach($scope.votingInsructionData, function (value, key) {
              if (
                $scope.votingInsructionData[key].selectedShareClassId ===
                $scope.votingInsructionData[key].shareclassIdWOSalt
              ) {
                $scope.selectedSecurityClass = $scope.votingInsructionData[key];
                $scope.selectedIntermediaryId =
                  $scope.votingInsructionData[key].selectedIntermediaryId;
                $scope.selectedIntermediaryName =
                  $scope.votingInsructionData[key].selectedIntermediaryName;
                $scope.selectedVoteCollectorId =
                  $scope.votingInsructionData[key].selectedVCUserId;
                $scope.intermediaryVO =
                  $scope.votingInsructionData[key].intermediaryVO;
                $scope.votecollectorVO =
                  $scope.votingInsructionData[key].vcShareclassAssocVO;
                $scope.participationMethod = $scope.votingInsructionData[key].participationMethod;
                angular.forEach($scope.intermediaryVO, function (val, keyy) {
                  if (
                    $scope.selectedIntermediaryId ===
                    $scope.intermediaryVO[keyy].intermediaryIdWoSalt
                  ) {
                    $scope.selectedIntermediary = $scope.intermediaryVO[keyy];
                    $scope.intermediaryPresent = true;
                  }
                });

                if ($scope.action === 'edit') {
                  angular.forEach(
                    $scope.selectedSecurityClass.intermediaryVO,
                    function (key) {
                      if (
                        $scope.selectedSecurityClass.selectedIntermediaryId ===
                        key.intermediaryIdWoSalt
                      ) {
                        $scope.intermediaryid = key.intermediaryId;
                        $scope.selectedIntermediary = key;
                      }
                    }
                  );
                }
                angular.forEach($scope.votecollectorVO, function (val, keyy) {
                  if (
                    $scope.selectedVoteCollectorId ===
                    $scope.votecollectorVO[keyy].vcUserIdWoSalt
                  ) {
                    $scope.selectedVC = $scope.votecollectorVO[keyy];
                    $scope.vcPresent = true;
                  }
                });
              }
              if (undefined !== $scope.selectedSecurityClass) {
                $scope.onSecurityClassChange($scope.selectedSecurityClass);
              }
            });
          } else if (votingInstDetails.length === 1) {
            $scope.onSecurityClassChange(votingInstDetails[0]);
            $scope.selectedSecurityClass = votingInstDetails[0];
          } else if (votingInstDetails.length >= 1) {
            // Below code is added for default selection of Intermediary
            //and share class for linked meeting SFCBP-2725
            if (
              linkedISIN &&
              selectedIntermediaryName &&
              $scope.userType === 'SH'
            ) {
              $scope.selectedSecurityClass = $filter('filter')(
                votingInstDetails,
                {
                  isin: linkedISIN,
                },
                true
              )[0];
              if ($scope.selectedSecurityClass) {
                $scope.selectedIntermediary = $filter('filter')(
                  $scope.selectedSecurityClass.intermediaryVO,
                  {
                    intermediaryName: selectedIntermediaryName,
                  },
                  true
                )[0];
              }
            }
            // Below code is added for default selection of Intermediary
            //and share class for linked meeting SFCBP-2725
            if (votingInstDetails[0].vcShareclassAssocVO.length > 1) {
              $scope.morethanOneVC = true;
            } else {
              $scope.morethanOneVC = false;
              $scope.selectedVC = votingInstDetails[0].vcShareclassAssocVO[0];
            }
            $scope.availableVoteCollectors = [];
            angular.forEach(votingInstDetails[0].vcShareclassAssocVO, function (
              key
            ) {
              key.nameOfVC =
                key.title +
                ' ' +
                key.firstName +
                ' ' +
                key.lastName +
                ' ' +
                $filter('translate')('vi_shareholder_disclaimer2') +
                ' ' +
                key.vcName;
              $scope.availableVoteCollectors.push(key);
              if (
                $scope.availableVoteCollectors.length === 1 &&
                !$scope.vcPresent
              ) {
                $scope.selectedVC = $scope.availableVoteCollectors[0];
              }
              $scope.shareclassId = votingInstDetails[0].shareclassId;
              $scope.shareclassIdWOSalt =
                votingInstDetails[0].shareclassIdWOSalt;
            });
          }
        } else {
          $scope.votingInsructionData = [];
        }
        if ($scope.languageCode === 'NL') {
          $scope.countryCode = countryVONL;
        } else if ($scope.languageCode === 'FR') {
          $scope.countryCode = countryVOFR;
        } else {
          $scope.countryCode = countryVO;
        }
      };
      $scope.processVotingInstructionData();

      // for edit screen language change.
      $scope.onLoadVIEditLangChange = function () {
        if ($sessionStorage.usertype !== 'SH' &&
            $sessionStorage.usertype !== 'SP') {
          voteInstructionService
          .getShareClassListWithVotes(
              $stateParams.meetingId,
              $scope.shareholderId,
              $scope.languageCode,
              $scope.userType,
              $scope.instructionId,
              $scope.reqStatus
          )
          .query()
          .$promise.then(
              function (data) {
                // if chosen language does not have data, then switch to English, else
                // fetch the existing details.
                $scope.meetingNameChange = true;
                if (data.length <= 0 && $scope.languageCode !== 'EN') {
                  $scope.languageCode = 'EN';
                  $scope.onLoadVIEditLangChange();
                } else {
                  $scope.votingInstructionDetails = GeneralMeetingDataLanguageService.getLanguageSpecificData(
                      data,
                      $scope.languageCode
                  );
                  angular.forEach($scope.votingInstructionDetails, function (
                      item
                  ) {
                    if ($scope.shareclassIdWOSalt === item.shareclassIdWOSalt) {
                      $scope.selectedSecurityClass = item;
                    }
                  });
                }
                $scope.agenda();
              },
              function (error) {
                $scope.error = error;
              }
          );
        } else {
          voteInstructionService.getVotingInstructionWithVotesAndAgenda(
              $stateParams.instructionId,
              $scope.languageCode,
          )
          .query()
          .$promise.then(
              function (data) {
                $scope.meetingNameChange = true;
                if ($scope.languageCode !== 'EN') {
                  $scope.languageCode = 'EN';
                  $scope.onLoadVIEditLangChange();
                } else {
                  $scope.selectedSecurityClass = data;
                  $scope.votingInstDetails = data;
                  $scope.shareClassId = data.shareClassId;
                  $scope.intermediaryId = data.intermediaryId;
                  $scope.selectedVC = {
                    vcName: data.voteCollectorName
                  };
                }
                // Agenda Assignment
                $scope.agendaCollection = data.votes;
                $scope.message = '';
                //to reset the number of share's in partial holding when user changes intermediary.
                $scope.selectedQuantityNoOfShares = null;
                $scope.totalNumberSharesError = '';
                $scope.errormessage = '';
                if (!$scope.shareholderId) {
                  $scope.shareholderId = $sessionStorage.groupId;
                }
                // if chosen language does not have
                // data, then switch to English, else
                // fetch the existing details.
                if ($scope.languageCode !== 'EN') {
                  $scope.languageCode = 'EN';
                  $scope.onLoadVIEditLangChange();
                } else {
                  angular.forEach($scope.agendaCollection, function (item) {
                    if (item.noOfSecurities && item.entireHolding === 'Y') {
                      $scope.noOfShares = item.noOfSecurities;
                    } else if (
                        item.noOfSecurities &&
                        item.entireHolding === 'N'
                    ) {
                      $scope.entireHoldingRadioVal = true;
                      $scope.selectedQuantityNoOfShares = item.noOfSecurities;
                    } else {
                      $scope.entireHoldingRadioVal = false;
                    }
                    if ($scope.noOfShares) {
                      item.totalSplitVotes = $scope.noOfShares;
                    } else {
                      item.totalSplitVotes = 0;
                    }
                    item.splitVotes = false;
                    $scope.selectedVote = 'none';
                    item.disableSplitVote = true;
                    item.disableRadio = true;
                    $scope.instructionId = item.instructionId;
                    $scope.registrationNo = item.registrationNo;
                    if (item.splitVoteAllwdFrmInter === 'N') {
                      item.disableSplitVote = false;
                    }
                    if ($scope.userType !== 'IN') {
                      if (item.voteFor !== 0) {
                        item.selectedVote = 'for';
                      } else {
                        item.voteFor = 0;
                      }
                      if (item.voteAgainst !== 0) {
                        item.selectedVote = 'against';
                      } else {
                        item.voteAgainst = 0;
                      }
                      if (item.voteAbstain !== 0) {
                        item.selectedVote = 'abstained';
                      } else {
                        item.voteAbstain = 0;
                      }
                    }
                  });
                  angular.forEach($scope.agendaCollection, function (key2) {
                    if (key2.isVotable === 'Y') {
                      $scope.isVotableFlag = true;
                    }
                    return $scope.isVotableFlag;
                  });
                }
              },
              function (error) {
                $log.debug(error);
              }
          );
        }
      };
      // when language changes, following piece of code is executed from header-controller.js
      $scope.$on('onLanguageChangeVotingInstruction', function (event, lang) {
        $scope.languageCode = lang;
        $scope.selectedParticipationMethod = null;
        if ($scope.action !== 'edit') {
          $scope.scListForLangChange(
            $stateParams.meetingId,
            $scope.shareholderId,
            $scope.languageCode,
            $sessionStorage.usertype
          );
        } else {
          $scope.onLoadVIEditLangChange();
        }
      });
      //Added this function to fix the issue with language change in liked meeting scenario -SFCBP-2725
      $scope.scListForLangChange = function (
        meetingId,
        shareholderId,
        languageCode,
        workStation
      ) {
        voteInstructionService
          .getShareClassList(
            meetingId,
            shareholderId,
            languageCode,
            workStation
          )
          .query()
          .$promise.then(
            function (data) {
              if (data.length > 0) {
                votingInstDetails = data;
                $scope.meetingName = data[0].meetingName;
                $scope.processVotingInstructionData();
                $scope.agenda();
              } else {
                if (languageCode !== appConstant.OPTED_EN) {
                  $scope.scListForLangChange(
                      $stateParams.meetingId,
                      $scope.shareholderId,
                      appConstant.OPTED_EN,
                      workStation
                  );
                }

              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      if (votingInstDetails.length > 1) {
        $scope.moreThanOneShareClass = true;
      }

      if (null != $scope.votingInsructionData[0]) {
        $scope.meetingName = $scope.votingInsructionData[0].meetingName;
      }

      if (null !== $scope.shareClassId) {
        angular.forEach($scope.votingInsructionData, function (value, key) {
          if (
            $scope.shareClassName ===
              $scope.votingInsructionData[key].shareClassName &&
            key + 1 !== $scope.votingInsructionData.length
          ) {
            $scope.selectedSecurityClass = $scope.votingInsructionData[key + 1];
            $scope.onSecurityClassChange($scope.selectedSecurityClass);
          } else if (
            $scope.shareClassName ===
              $scope.votingInsructionData[key].shareClassName &&
            key + 1 === $scope.votingInsructionData.length
          ) {
            $scope.selectedSecurityClass = $scope.votingInsructionData[0];
            $scope.onSecurityClassChange($scope.selectedSecurityClass);
          }
        });
      }

      // below is the function call on change of radio button
      $scope.holdingRadioChange = function (val) {
        $scope.holdingRadio = val;
      };

      // Below is the function call on selection radio buttons to enable or disable particular section
      $scope.selectAllRadio = function () {
        $scope.flag = 0;
        $scope.message = '';
        $scope.errormessage = null;
        angular.forEach($scope.agendaCollection, function (item) {
          item.totalNumberSharesError = '';
          var voteValue = item.totalSplitVotes;
          if ($scope.holdingRadio === 'Y' && $scope.userType === 'SH') {
            voteValue = 1;
          }
          if ($scope.selectedVote === 'Allfor') {
            item.selectedVote = 'for';
            item.voteFor = voteValue;
            item.voteAgainst = 0;
            item.voteAbstain = 0;
            item.disableSplitVote = false;
            item.splitVotes = false;
            item.disableRadio = false;
          } else if ($scope.selectedVote === 'AllAgainst') {
            item.selectedVote = 'against';
            item.voteAgainst = voteValue;
            item.voteFor = 0;
            item.voteAbstain = 0;
            item.disableSplitVote = false;
            item.splitVotes = false;
            item.disableRadio = false;
          } else if ($scope.selectedVote === 'AllAbstained') {
            item.selectedVote = 'abstained';
            item.voteAbstain = voteValue;
            item.voteAgainst = 0;
            item.voteFor = 0;
            item.disableSplitVote = false;
            item.splitVotes = false;
            item.disableRadio = false;
          } else {
            item.disableSplitVote = item.splitVoteAllwdFrmInter !== 'N';
            item.disableRadio = true;
          }
        });
      };
      /*
       * Below is the function call on selection radio buttons to check whether the voted item is for or against or
       * abstained when none radio button is selected
       */
      $scope.individualRadioChange = function (item) {
        $scope.flag = 0;
        $scope.errormessage = null;
        var voteValue = item.totalSplitVotes;
        if ($scope.holdingRadio === 'Y' && $scope.userType === 'SH') {
          voteValue = 1;
        }
        if (item.selectedVote === 'for') {
          item.voteFor = voteValue;
          item.voteAgainst = 0;
          item.voteAbstain = 0;
        } else if (item.selectedVote === 'against') {
          item.voteAgainst = voteValue;
          item.voteFor = 0;
          item.voteAbstain = 0;
        } else if (item.selectedVote === 'abstained') {
          item.voteAbstain = voteValue;
          item.voteAgainst = 0;
          item.voteFor = 0;
        }
      };
      // Below is the function to validate the entered number of shares
      $scope.enterShares = function () {
        $scope.selectedQuantityErrorMessage = '';
        if (
          null != $scope.selectedQuantityNoOfShares &&
          null != $scope.noOfShares &&
          $scope.userType === 'SP'
        ) {
          if (
            parseInt($scope.selectedQuantityNoOfShares, 10) >
            parseInt($scope.noOfShares, 10)
          ) {
            $scope.selectedQuantityErrorMessage = $filter('translate')(
              'noOfShares_error3'
            );
            $scope.noOfSharesErrorFlag = 1;
            $scope.sharesForm.$invalid = true;
          } else {
            $scope.noOfSharesErrorFlag = 0;
            $scope.sharesForm.$invalid = false;
          }
        } else {
          $scope.selectedQuantityErrorMessage = '';
        }

        angular.forEach($scope.agendaCollection, function (item) {
          if (
            $scope.selectedQuantityNoOfShares != null &&
            $scope.selectedQuantityNoOfShares !== ''
          ) {
            item.totalSplitVotes = $scope.selectedQuantityNoOfShares;
          } else {
            item.totalSplitVotes = $scope.noOfShares;
          }
        });
      };
      $scope.shMyVotesList = function () {
        if (!$scope.errormessage) {
          $state.go('shMyVotes', {
            type: 'vote',
            selectedIndex: 0,
            radioVal: 'voteIns',
            dropdownVal: $scope.dropdownVal,
            landingThrough: 'addVote',
          });
        }
      };

      $scope.termsOfUseUrl = getTermsOfUseUrl(
        'GM',
        $sessionStorage.workstationData.selectedLang
      );

      /**
       * Below function call is to invoke modal window for confirmation
       * while submitting voting instruction from intermediary workstation
       **/
      $scope.intermediaryConfirmVI = function () {
        $scope.modalHeader = $filter('translate')('label_modalConfirmAction');
        $scope.modalFooter = '';
        $scope.modalLeftButton = $filter('translate')('label_confirm');
        $scope.modalRightButton = $filter('translate')('label_cancel');
        $scope.modalBody = '';
        $scope.modalBody1 = $filter('translate')('intermediary_modalcheck1');
        $scope.modalBody2 = $filter('translate')('intermediary_modalcheck2');
        $scope.modalBody3 = $filter('translate')('intermediary_VI_modalcheck3');
        $scope.modalBody4 = $filter('translate')('intermediary_modalcheck4');
        $scope.modalBody5 = $filter('translate')(
          'intermediaryAcceptVoteCheckbox5'
        );
        $scope.modalBody6 = $filter('translate')('label_termsUse');
        $scope.modalBody7 = $filter('translate')(
          'intermediaryAcceptVoteCheckbox6'
        );
      };
      $scope.showPolicy = function () {
        if ($scope.userType === 'SH' || $scope.userType === 'IN') {
          voteInstructionService
            .getIssuerPolicyText(
              $stateParams.meetingId,
              $scope.languageCode,
              $scope.userType
            )
            .then(function (response) {
              if (response.status == 204) {
                $scope.confirmVoting();
              } else {
                $scope.policyVisible = true;
                $scope.policyText = response.data.policyText;
              }
            });
        } else {
          $scope.confirmVoting();
        }
      };
      $scope.popUpOpen = function () {
        $timeout(function () {
          var confirlLink = document.getElementById('confirmVote');
          confirlLink.href = '#pop12';
          confirlLink.click();
        }, 1000);
      };
      // Below is the function to submit voting instructions in different workstations
      $scope.confirmVoting = function () {
        $scope.errorCode = null;
        if ($scope.shTitleRequired) {
          $scope.shTitle = $scope.shareHolderVO.selectedTitle[0].title;
        } else {
          $scope.shTitle = null;
        }
        if ($scope.userType === 'SH' || $scope.userType === 'SP') {
          if (null != $scope.selectedIntermediary) {
            $scope.intermediaryId = $scope.selectedIntermediary.intermediaryId;
          } else if (
            $scope.intermediaryVO &&
            $scope.intermediaryVO.length === 1
          ) {
            $scope.intermediaryId = $scope.intermediaryVO[0].intermediaryId;
          } else {
            $scope.intermediaryId = 0;
          }
          $scope.shareholderId = $sessionStorage.groupId;
        }
        $scope.saveAgendaCollection = [];
        if ($scope.selectedVote === 'none') {
          angular.forEach($scope.agendaCollection, function (item) {
            if (item.selectedVote === undefined && item.isVotable !== 'N') {
              if (item.splitVotes === true) {
                $scope.CalculateSumOfShares(item);
                $scope.errormessage = '';
                return;
              } else {
                $scope.flag = 1;
                $scope.errormessage = $filter('translate')(
                  'votingInstruction_error_msg'
                );
              }
            }
          });
        }
        if ($scope.flag === 0 && $scope.noOfSharesErrorFlag === 0) {
          $scope.loadingSpin = true;
          if (
            $scope.selectedQuantityNoOfShares != null &&
            $scope.selectedQuantityNoOfShares !== '' &&
            $scope.entireHoldingRadioVal
          ) {
            $scope.noOfSecurities = $scope.selectedQuantityNoOfShares;
            $scope.entireHoldingRadio = 'N';
          } else {
            $scope.noOfSecurities = $scope.noOfShares;
            $scope.entireHoldingRadio = 'Y';
          }

          angular.forEach($scope.agendaCollection, function (item) {
            if (item.splitVotes === true) {
              item.splitVote = 'Y';
            } else {
              item.splitVote = 'N';
            }
            item.noOfSecurities = item.totalSplitVotes;
            //push only those agenda items which are votable. Else, it impacts the reports.
            if (item.isVotable !== 'N') {
              $scope.saveAgendaCollection.push({
                agendaId: item.agendaId,
                splitVote: item.splitVote,
                voteFor: item.voteFor,
                voteAgainst: item.voteAgainst,
                voteAbstain: item.voteAbstain,
                voteId: item.voteId,
                isVotable: item.isVotable,
              });
            }
          });

          if ($scope.userType === 'SH' || $scope.userType === 'SP') {
            var requestStatus = null;
            if ($scope.action === 'edit') {
              requestStatus = $stateParams.status;
            }
            if ($scope.addNewIntermediary) {
              $scope.intermediaryId =
                $scope.selectedSecurityClass.abnIntermdiaryId;
            }
            $scope.VotingInstructionConfirmationVO = {
              meetingId: $stateParams.meetingId,
              intermediaryAdditionRequest: $scope.intRequestId,
              shareClassId: $scope.selectedSecurityClass.shareclassId,
              intermediaryId: $scope.intermediaryId,
              enitireHolding: $scope.entireHoldingRadio,
              nosOfSecurities: parseInt($scope.noOfSecurities, 10),
              voteCollectorId: $scope.selectedVC?.vcID,
              userVcType: ($scope.selectedVC === null || $scope.selectedVC === undefined) ? 'IS'
              : $scope.selectedVC?.userVcType,
              languageCode: 'EN',
              votingInstAgendaVOlist: $scope.saveAgendaCollection,
              isRegisterShareclass:
                $scope.selectedSecurityClass.isRegisterShareclass,
              securityAccountNumber: $scope.securityAccountNumber,
              registrationNo: $scope.registrationNo,
              participationMethod: $scope.participationMethod
            };
            if ($scope.instructionId !== null) {
              $scope.VotingInstructionConfirmationVO.instructionId = $scope.instructionId;
            }
          }

          saveVotingInstruction(
            $scope.VotingInstructionConfirmationVO,
            'shareholder'
          ).then(
            function (response) {
              $scope.errormessage = '';
              $scope.loadingSpin = false;
              if ($scope.userType === 'SH') {
                var phoneNo = '';
                var intermediaryName = '';
                var intEmailAdress = '';
                $scope.emailNotificationVo = {
                  meetingId: $stateParams.meetingId,
                  instructionId: response.data.intermediaryVO.requestId,
                  shareholderId: $scope.shareholderId,
                  instructionType: 'VI',
                  intermediaryId: $scope.intermediaryId,
                  issuerId: $stateParams.issuerId,
                  issuerName: $stateParams.issuerName,
                  meetingName: $scope.meetingName,
                };
                myVoteService
                  .getIntermediaryDetails(
                    response.data.intermediaryVO.identificationNumber,
                    'vote'
                  )
                  .query()
                  .$promise.then(
                    function (data) {
                      if (
                        ($scope.action === 'edit' && requestStatus === 'A') ||
                        $scope.selectedSecurityClass.isRegisterShareclass
                      ) {
                        $scope.shMyVotesList();
                      } else {
                        $scope.selectedIntermediaryName = data.intermediaryName;
                        phoneNo = data.intermediaryPhone;
                        intermediaryName = data.intermediaryName;
                        intEmailAdress = data.emailAddress;
                        $scope.modalHeader = $filter('translate')(
                          'label_confirmVI_registration_header'
                        );
                        $scope.modalFooter = '';
                        $scope.modalLeftButton = $filter('translate')(
                          'label_ok'
                        );
                        $scope.modalRightButton = $filter('translate')(
                          'label_cancel'
                        );
                        if (data.agentAsIntermediay === 'Y') {
                          $scope.modalBody =
                            $filter('translate')(
                              'callIntermediary_SorryModalbody1'
                            ) +
                            phoneNo +
                            $filter('translate')(
                              'callIntermediary_SorryModalbody2'
                            );
                          $scope.modalBody1 = ' ';
                          $scope.modalBody2 = ' ';
                          $scope.modalBody3 = ' ';
                          $scope.modalBody4 = ' ';
                          $scope.modalBody5 = ' ';
                          $scope.modalBody6 = ' ';
                          $scope.modalBody7 = ' ';
                        } else {
                          $scope.modalBody = $filter('translate')(
                            'label_callIntermediary_VI_registration'
                          );
                          if (
                            response.data.intermediaryVO.identificationNumber
                          ) {
                            $scope.modalBody1 =
                              $filter('translate')(
                                'label_ask_provideIdentification_VI'
                              ) +
                              ' ' +
                              response.data.intermediaryVO.identificationNumber;
                          }
                          $scope.modalBody2 = $filter('translate')(
                            'label_sh_vi_modalBody2'
                          );
                          $scope.modalBody3 = $filter('translate')(
                            'label_sh_vi_modalBody3'
                          );
                          $scope.modalBody4 =
                            $filter('translate')('lable_contactDetails') +
                            $filter('translate')('label_colon');
                          if (intermediaryName) {
                            $scope.modalBody5 =
                              $filter('translate')('label_intermediary') +
                              $filter('translate')('label_colon') +
                              ' ' +
                              intermediaryName;
                          }
                          if (intEmailAdress) {
                            $scope.modalBody6 =
                              $filter('translate')('label_addresseeEmail') +
                              $filter('translate')('label_colon') +
                              ' ' +
                              intEmailAdress;
                          }
                          if (phoneNo) {
                            $scope.modalBody7 =
                              $filter('translate')('label_telephoneNumber') +
                              $filter('translate')('label_colon') +
                              ' ' +
                              phoneNo;
                          }
                          //Below if condition is added to prevent the service call and pop changes for edit VI screen
                          if ($scope.action !== 'edit') {
                            attendanceRequestService
                              .getLinkedMeetingList(
                                $stateParams.meetingId,
                                $scope.shareClassId,
                                $scope.intermediaryId,
                                'VI',
                                $sessionStorage.selectedLang
                              )
                              .then(
                                function (response) {
                                  if (response.data.linkedMeeting === 'Y') {
                                    $scope.linkedMeeting = true;
                                    $scope.likedMeetingId =
                                      response.data.meetingId;
                                    $scope.likedMeetingIssuerId =
                                      response.data.issuerId;
                                    $scope.linkedMeetingIssuerName =
                                      response.data.issuerName;
                                    $scope.modalLeftButton = $filter(
                                      'translate'
                                    )('label_no');
                                    $scope.modalRightButton = $filter(
                                      'translate'
                                    )('label_yes');
                                    $scope.modalBody8 =
                                      $filter('translate')(
                                        'linkedMeeting_vote_label'
                                      ) +
                                      ' ' +
                                      response.data.issuerName +
                                      ' ' +
                                      response.data.meetingName +
                                      ', ';
                                  }
                                },
                                function (error) {
                                  $log.debug(error);
                                }
                              );
                          }
                        }
                        $scope.popUpOpen();
                      }
                    },
                    function (error) {
                      $log.debug(error);
                    }
                  );
              } else {
                $timeout(function () {
                  if ($scope.userType === 'SP') {
                    $state.go('thankYou', {
                      issuerId: $stateParams.issuerId,
                      shareClassName:
                        $scope.selectedSecurityClass.shareClassName,
                      gmId: $stateParams.meetingId,
                      moreThanOneShareClass: $scope.moreThanOneShareClass,
                      breadCrumbDetails: [votingInstDetails[0].meetingName],
                    });
                  } else {
                    document.getElementById('backButton').click();
                  }
                }, 2000);
              }
            },
            function (error) {
              $scope.message = null;
              $scope.loadingSpin = false;
              $scope.errorCode = error.data.errors[0].errorCode;
              $scope.errormessage = error.data.errors[0].errorMessage;
              $scope.modalBody = error.data.errors[0].errorMessage;
              $scope.disableModalBtn = true;
              //Below service call is added to send email to agent when the number of shares entered exceeds the total number of securities available
              if (
                error.data.errors[0].errorCode === 'CBP_ERR_520' &&
                ($scope.userType === 'SH' || $scope.userType === 'IN')
              ) {
                if ($scope.userType === 'IN') {
                  $scope.EmailData = {
                    votingInstType: 'vote',
                    languageCode: $scope.languageCode,
                    workStationType: $scope.userType,
                    meetingId: $stateParams.meetingId,
                    shareClassId: $scope.selectedSecurityClass.shareclassId,
                    intermediaryId: $scope.intermediaryId,
                    issuerId: $stateParams.issuerId,
                    loginUserId: $sessionStorage.userId,
                  };
                } else {
                  $scope.EmailData = {
                    votingInstType: 'vote',
                    languageCode: $scope.languageCode,
                    workStationType: $scope.userType,
                    meetingId: $stateParams.meetingId,
                    shareClassId: $scope.selectedSecurityClass.shareclassId,
                    shareholderId: $scope.shareholderId,
                    issuerId: $stateParams.issuerId,
                    loginUserId: $sessionStorage.userId,
                  };
                }
              }
              voteInstructionService
                .sendSharesExceededMail($scope.EmailData, 'secureToken')
                .then(
                  function (response) {
                    $log.debug(response);
                  },
                  function (error) {
                    $log.debug(error);
                  }
                );
            }
          );
        }
      };

      // call this function to save a voting instruction
      // decides to create a new voting instruction or update an existing one
      //based on the presence of a votinginstructionId
      function saveVotingInstruction(votingInstructionVo, userType) {
        if (votingInstructionVo.instructionId === undefined) {
          return voteInstructionService.createVotingInstruction(
            votingInstructionVo,
            userType
          );
        } else {
          return voteInstructionService.updateVotingInstruction(
            votingInstructionVo,
            userType
          );
        }
      }
      //Below method is to navigate to the linked meeting AR page-SFCBP-2725
      $scope.goToLinkedMeeting = function () {
        voteInstructionService
          .getShareClassList(
            $scope.likedMeetingId,
            $sessionStorage.groupId,
            $scope.languageCode,
            'SH'
          )
          .query()
          .$promise.then(
            function (data) {
              $state.go('linkedMeetingVotingInstruction', {
                meetingId: $scope.likedMeetingId,
                issuerId: $scope.likedMeetingIssuerId,
                issuerName: $scope.linkedMeetingIssuerName,
                linkedISIN: $scope.voteisin,
                selectedIntermediaryName: $scope.selectedIntermediaryName,
                shareHolderId: $sessionStorage.groupId,
                selectedIndex: 1,
                votingInstDetails: data,
              });
            },
            function (error) {
              return error;
            }
          );
      };
      // Below function is to disable intermediary on click of add intermediary
      $scope.intermediary = function () {
        if (!$scope.disableIntermediary && !$scope.disabled) {
          $scope.disableIntermediary = true;
          $scope.selectedIntermediary = '';
        } else {
          $scope.disableIntermediary = false;
        }
      };
      // Below function is to disable intermediary on click of cancel add intermediary
      $scope.closeIntermediary = function () {
        if (!$scope.disableIntermediary) {
          $scope.disableIntermediary = true;
          $scope.selectedIntermediary = '';
        } else {
          $scope.disableIntermediary = false;
        }
      };

      // Below is the function to send add intermediary request in share holder workstation
      $scope.addNewIntermediary = false;
      $scope.addIntermediary = function () {
        $scope.addNewIntermediary = true;
        $scope.intermediary = $filter('filter')(
          $scope.intermediaryVO,
          {
            intermediaryName: 'ABN AMRO Registrar',
          },
          true
        );
        $scope.intermediaryId = $scope.abnIntermediaryId;
        var documentFormData = new FormData();
        documentFormData.append(
          'country',
          $scope.selectedIntemediaryCountry.isoCountryCode
        );
        documentFormData.append('intermediaryId', $scope.intermediaryId);
        documentFormData.append('intermediaryName', $scope.intermediaryName);
        documentFormData.append('phoneNo', $scope.phoneNo);
        documentFormData.append('emailAddress', $scope.emailAddress);
        documentFormData.append('contactName', $scope.contactName);
        documentFormData.append('meetingId', $stateParams.meetingId);
        documentFormData.append('userName', $sessionStorage.userName);
        documentFormData.append('langCode', $scope.languageCode);
        documentFormData.append('requestType', 'voting instruction');
        if ($scope.poaFormFile) {
          $scope.fileName = $scope.poaFormFile.name;
          documentFormData.append('proofOfEntitlement', $scope.poaFormFile);
          documentFormData.append(
            'proofOfEntitlementFileName',
            $scope.fileName
          );
        }
        documentFormData.append('userId', $sessionStorage.userId);
        var file = $scope.poaFormFile;
        var uploadUrl = 'v1/shareholder/addIntermediaryRequest';
        fileUploadSvc.uploadFileToUrl(file, uploadUrl, documentFormData).then(
          function (data) {
            if (
              data.status === 500 &&
              data.data.errors[0].errorCode === 'CBP_ERR_407'
            ) {
              $scope.message = data.data.errors[0].errorMessage;
            } else {
              $scope.message = data.data.message;
              $scope.intRequestId = data.data.requestId;
            }
            if (data.status === 200) {
              $scope.showIntermediary = false;
              $scope.selectedIntermediary = true;
              $scope.disableAddSection = true;
            } else {
              $scope.showIntermediary = false;
              $scope.selectedIntermediary = true;
            }
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      //Below method is to route the 'i would like to attend the meeting' button to respective pages, as part of SFCBP-1520
      $scope.attendanceRequestCreation = function () {
        var selectedIndex = 1;
        $scope.shareClassModelConfig = {};
        if ($scope.userType === 'SP') {
          selectedIndex = 0;
          $scope.shareplanId = $sessionStorage.groupId;
        } else if ($scope.userType === 'SH') {
          $scope.shareplanId = 0;
        } else {
          $scope.shareplanId = null;
        }
        var stateUrl = '';
        if ($scope.action === 'edit') {
          stateUrl = 'editGoToAttendanceReq';
        } else {
          stateUrl = 'goToAttendanceReq';
        }
        attendanceRequestService
          .getAttendanceRequestView(
            $stateParams.meetingId,
            $scope.languageCode,
            $scope.shareholderId,
            $scope.userType
          )
          .query()
          .$promise.then(
            function (attendData) {
              if (attendData && attendData.shareClassISINVO.length > 0) {
                $state.go(stateUrl, {
                  issuerId: $stateParams.issuerId,
                  issuerName: $scope.issuerGroupDetails.issuerName,
                  gmId: $stateParams.meetingId,
                  sharePlanId: $scope.shareplanId,
                  selectedIndex: selectedIndex,
                  attendanceDataDeatils: attendData,
                  virtualMeetingFlag: $scope.isVirtualMeetingEnabled,
                  breadCrumbDetails: [attendData.meetingName],
                });
              } else if (
                attendData.shareClassISINVO.length <= 0 &&
                $scope.languageCode !== 'EN'
              ) {
                $scope.languageCode = 'EN';
                $scope.attendanceRequestCreation();
              } else {
                showAttendDisabledPopup($scope.issuerGroupDetails.issuerName);
              }
            },
            function (error) {
              return error;
            }
          );
      };
      $scope.goToPreviousPage = function () {
        if (!$scope.morethanOneIntermediary) {
          $scope.disableConfirm = true;
          $scope.errorForAlexBink = $filter('translate')(
            'label_reloadCancelError'
          );
        } else {
          $scope.disableConfirm = false;
          $scope.errorForAlexBink = '';
          $scope.selectedIntermediary = $scope.availableIntermediaries[0];
          $scope.onintermediaryChange($scope.selectedIntermediary);
        }
      };
      //Below function is for register virtual attendance request
      $scope.createVirtualParticipationRequest = function () {
        $state.go(
          'vaRequestStep1',
          {
            selectedIndex: 1,
            issuerId: $stateParams.issuerId,
            gmId: $stateParams.meetingId,
          },
          {
            location: true,
          }
        );
      };

      $scope.getProxyTextParagraph2 = function () {
        if ($scope.issuerName === 'telenet group holding nv') {
          return $filter('translate')(
            'vi_shareholder_disclaimer8_with_right_of_substitution'
          );
        } else {
          return $filter('translate')('vi_shareholder_disclaimer8');
        }
      };

      /**
       * Angular-translate escapes all htmlentities, so if we want to
       * render html in the popup modal, we need to decode them again
       *
       * See {@link @/lib/htmlDecode.js}
       */
      function showAttendDisabledPopup(issuerName) {
        $scope.shareClassModelConfig.modalHeader = $filter('translate')(
          'label_modalAttReqCreatErr'
        );

        $scope.shareClassModelConfig.modalBody =
          $filter('translate')('label_attendNotEnabled1').toString() +
          ' ' +
          issuerName +
          '. ' +
          $filter('translate')('label_attendNotEnabled2').toString() +
          '.';
        ModelWindowService.showModelWindow('shareClassAlertBox');
      }

      if (
        $scope.selectedSecurityClass &&
        $scope.selectedSecurityClass.requestAttendenceCardShareplanholder ===
          'N' &&
        $scope.userType === 'SP'
      ) {
        $scope.isAttendEnabled = true;
      }

      let openAgendas = [];
      if (/philips/i.test($stateParams.issuerName)) {
        openAgendas = [...Array(5).keys()];
      }

      $scope.toggleAgendaItemExplanation = (agendaIndex) => {
        const index = openAgendas.findIndex((i) => i === agendaIndex);
        if (index > -1) {
          openAgendas.splice(index, 1);
        } else {
          openAgendas.push(agendaIndex);
        }
      };

      $scope.isAgendaExplanationOpen = (index) => {
        return openAgendas.includes(index);
      };

      $scope.formattedLocationString = () => {
        return formatLocationString([
          $scope.selectedSecurityClass?.locationOnTicket,
          $scope.selectedSecurityClass?.street,
          $scope.selectedSecurityClass?.postalCode,
          $scope.selectedSecurityClass?.city,
        ]);
      };

      $scope.shCustodianMessage = () =>
        htmlDecode(
          $filter('translate')('label_sh_different_custodian_message')
        );
    },
  ]);
