/**
 * @ngdoc controller
 * @name userMaintenanceModule.EditProxyGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with editing details of particular proxy group
 * @requires userGroupService and fileUploadSvc are the service which are being
 *           used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter country and translate message from locale file
 * @requires $sessionStorage to get logged in userId from session storage
 * @requires $timeout
 * @requires $uibModal
 * @requires $state
 */

angular.module('userMaintenanceModule').controller('EditProxyGroupController', [
  '$scope',
  '$log',
  '$stateParams',
  'userGroupService',
  '$sessionStorage',
  '$filter',
  'fileUploadSvc',
  '$timeout',
  '$uibModal',
  '$state',
  function (
    $scope,
    $log,
    $stateParams,
    userGroupService,
    $sessionStorage,
    $filter,
    fileUploadSvc,
    $timeout,
    $uibModal,
    $state
  ) {
    $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

    $scope.intermediaryId = $stateParams.groupId;
    $scope.userGroupName = 'proxy';
    $scope.modalBody = $filter('translate')('confirmGroup_editDetails');
    $scope.modalLeftButton = $filter('translate')('label_confirm');
    $scope.modalRightButton = $filter('translate')('label_cancel');
    // Below is the service call to get list of countries
    userGroupService
      .getCountryList('EN')
      .query()
      .$promise.then(
        function (countries) {
          $log.debug('success user view');
          $scope.countries = countries.countries;
          userGroupService
            .getViewIntermediaryGroup($scope.intermediaryId, 'GM', 'EN')
            .query()
            .$promise.then(
              function (data) {
                $scope.userGroupDetails = data;
                $scope.selectedCountry = $filter('filter')(
                  $scope.countries,
                  {
                    countryName: $scope.userGroupDetails.country,
                  },
                  true
                );
                $log.debug('success user view', $scope.userGroupDetails);
              },
              function (error) {
                $log.debug(error);
              }
            );
        },
        function (error) {
          $log.debug(error);
        }
      );
    /* download registration form starts here */
    $scope.downloadRegistrationForm = function () {
      var pathList = window.location.pathname.split('/');
      if (pathList[1] === 'cbp') {
        $scope.downloadRegistrationFormPdf =
          window.location.origin +
          '/' +
          pathList[1] +
          '/v1/intermediaries/agreementforms/download' +
          '?authid=' +
          $sessionStorage.userId;
      } else {
        $scope.downloadRegistrationFormPdf =
          window.location.origin +
          '/v1/intermediaries/agreementforms/download' +
          '?authid=' +
          $sessionStorage.userId;
      }
    };
    /* download registration ends starts here */
    /* date picker */
    $scope.inlineOptions = {
      customClass: getDayClass,
    };

    $scope.openStartDate = function () {
      if ($scope.startDate) {
        $scope.startDate.opened = true;
      } else {
        $scope.startDate = new Date();
        $scope.startDate.opened = true;
      }
    };
    $scope.openEndDate = function () {
      if ($scope.endDate) {
        $scope.endDate.opened = true;
      } else {
        $scope.endDate = new Date();
        $scope.endDate.opened = true;
      }
    };
    $scope.setDate = function (year, month, day) {
      $scope.dt = new Date(year, month, day);
    };
    $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[0];
    $scope.altInputFormats = ['M!/d!/yyyy'];
    $scope.startDate = {
      opened: false,
    };
    $scope.endDate = {
      opened: false,
    };
    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
        for (var i = 0; i < $scope.events.length; i++) {
          var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);
          if (dayToCheck === currentDay) {
            return $scope.events[i].status;
          }
        }
      }
      return '';
    }
    /* date picker end */

    /* upload file starts here */
    // Below is the function call from HTML on selecting a file to upload
    $scope.onFileSelect = function (e) {
      var filesList = e.files;
      var fd = new FormData();
      fd.append('file', filesList[0]);
      if ((filesList[0].size / 1024 / 1024) * 5 > 5) {
        $log.debug(
          'This file size is: ' +
            (filesList[0].size / 1024 / 1024).toFixed(2) +
            ' MB. Please upload a file less than 5 MB.'
        );
        $scope.agendaFileSizeError =
          'This file size is: ' +
          (filesList[0].size / 1024 / 1024).toFixed(2) +
          ' MB. Please upload a file less than 5 MB.';
        return;
      }
      var fileReaderList = [];
      fileReaderList.push(new FileReader());
      fileReaderList[0].readAsDataURL(filesList[0]);
      fileReaderList[0].onload = function () {
        $scope.fileName = filesList[0].name;
        $scope.file = filesList[0];
      }.bind(fileReaderList[0]);
    };
    // The below function call is to store the data from UI
    // and pass it to REST call for updating user
    $scope.updateUserGroup = function () {
      if (undefined !== $scope.newIntermediryForm.registrationFile) {
        $scope.file = $scope.newIntermediryForm.registrationFile;
        $scope.fileName = $scope.newIntermediryForm.registrationFile.name;
      }
      $scope.error = false;
      $scope.success = false;
      $log.debug('$scope.username', $scope.userName);
      var documentFormData = new FormData();
      documentFormData.append('groupId', $scope.intermediaryId);
      documentFormData.append('userId', $sessionStorage.userId);
      documentFormData.append('groupName', $scope.userGroupDetails.groupName);
      documentFormData.append(
        'bankIdentifierCode',
        $scope.userGroupDetails.bankIdentifierCode
      );
      documentFormData.append('groupEmail', $scope.userGroupDetails.groupEmail);
      documentFormData.append('city', $scope.userGroupDetails.city);
      documentFormData.append(
        'country',
        $scope.selectedCountry[0].isoCountryCode
      );
      documentFormData.append('productCode', 'GM');
      documentFormData.append('status', $scope.userGroupDetails.status);
      documentFormData.append('intermediaryVO.proxyCode', 'P');
      documentFormData.append(
        'intermediaryVO.processAssignedSc',
        $scope.userGroupDetails.intermediaryVO.processAssignedSc
      );
      documentFormData.append(
        'intermediaryVO.processVotingInstruction',
        $scope.userGroupDetails.intermediaryVO.processVotingInstruction
      );
      documentFormData.append(
        'intermediaryVO.visibleInShWorkStation',
        $scope.userGroupDetails.intermediaryVO.visibleInShWorkStation
      );
      documentFormData.append(
        'intermediaryVO.processAttendanceRequest',
        $scope.userGroupDetails.intermediaryVO.processAttendanceRequest
      );
      documentFormData.append(
        'intermediaryVO.processCustomTemplate',
        $scope.userGroupDetails.intermediaryVO.processCustomTemplate
      );
      documentFormData.append(
        'intermediaryVO.requiresMeetingNotice',
        $scope.userGroupDetails.intermediaryVO.requiresMeetingNotice
      );
      // 8820 and 8910 Fix
      if (undefined !== $scope.file) {
        documentFormData.append('intermediaryVO.agreementForm', $scope.file);
      }
      if (undefined !== $scope.fileName) {
        documentFormData.append(
          'intermediaryVO.agreementFormName',
          $scope.fileName
        );
      }
      var startDate = new Date($scope.startDate);
      var endDate = new Date($scope.endDate);
      documentFormData.append(
        'intermediaryVO.effectiveEndDate',
        $filter('date')(endDate, 'yyyy-MM-dd HH:mm:ss')
      );
      documentFormData.append(
        'intermediaryVO.effectiveStartDate',
        $filter('date')(startDate, 'yyyy-MM-dd HH:mm:ss')
      );
      var file = $scope.file;
      var uploadUrl = 'v1/intermediaries/' + $scope.intermediaryId;
      if (undefined !== $scope.file) {
        if (
          'Invalid Date' === startDate ||
          'Invalid Date' === endDate ||
          null === $scope.startDate ||
          null === $scope.endDate
        ) {
          $scope.error = true;
          $scope.errorMsg = $filter('translate')('selectDate_error');
        } else {
          if (
            ('application/pdf' !== $scope.file.type ||
              'application/softgrid-pdf' !== $scope.file.type) &&
            $scope.file.size > 1000000
          ) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('fileSize_1mbError');
          } else {
            if (startDate.getTime() > endDate.getTime()) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('selectToDate_error');
            } else {
              /*
               * Call the upload directive to upload file and
               * FormData
               */
              if (
                $scope.newIntermediryForm.userName.$valid &&
                $scope.newIntermediryForm.emailAddress.$valid &&
                $scope.newIntermediryForm.city.$valid &&
                $scope.newIntermediryForm.bankIdentifierCode.$valid
              ) {
                fileUploadSvc
                  .uploadFileToUrl(file, uploadUrl, documentFormData)
                  .then(
                    function (data) {
                      $scope.success = true;
                      $scope.timedout = $uibModal.open({
                        templateUrl: 'success-dialog.html',
                      });
                      $timeout(function () {
                        $scope.timedout.close();
                        $scope.timedout = null;
                        $state.go('userMaintenance.proxyIntermediaryGroup', {
                          pageNo: $stateParams.pageNo,
                        });
                      }, 2000);
                    },
                    function (error) {
                      $log.debug(error);
                      $scope.error = true;
                      $scope.errorMsg = error.data.errors[0].errorMessage;
                    }
                  );
              }
            }
          }
        }
      } else {
        //Call the upload directive to upload file and FormData
        if (
          $scope.newIntermediryForm.userName.$valid &&
          $scope.newIntermediryForm.emailAddress.$valid &&
          $scope.newIntermediryForm.city.$valid &&
          $scope.newIntermediryForm.bankIdentifierCode.$valid
        ) {
          fileUploadSvc.uploadFileToUrl(file, uploadUrl, documentFormData).then(
            function (data) {
              $scope.success = true;
              $scope.timedout = $uibModal.open({
                templateUrl: 'success-dialog.html',
              });
              $timeout(function () {
                $scope.timedout.close();
                $scope.timedout = null;
                $state.go('userMaintenance.proxyIntermediaryGroup', {
                  pageNo: $stateParams.pageNo,
                });
              }, 2000);
            },
            function (error) {
              $log.debug(error);
              $scope.error = true;
              $scope.errorMsg = error.data.errors[0].errorMessage;
            }
          );
        }
      }
    };
  },
]);
