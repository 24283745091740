/**
 * @ngdoc controller
 * @name generalMeetingHomeModule.reinviteSharePlanController
 * @description This controller is used for general meeting home module. This
 *              mainly deals with sending share plan invitation to shareholders
 *              from agent.
 * @requires AgentService,ModelWindowService and MyAccountsService are the services which are
 *           being used to interact with REST
 * @requires $state for state routing
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.n
 * @requires $sessionStorage for fetching the logged in user details from
 *           session storage
 * @ModelWindowService used for modal windows
 * This is done as part of -SFCBP-2642
 */
'use strict';

angular
  .module('generalMeetingHomeModule')
  .controller(
    'reinviteSharePlanController',
    [
      '$scope',
      'AgentService',
      '$log',
      '$stateParams',
      '$sessionStorage',
      'MyAccountsService',
      '$filter',
      'ModelWindowService',
      '$state',
      function($scope, AgentService, $log,
        $stateParams, $sessionStorage, MyAccountsService,$filter,ModelWindowService,$state) {
        if($stateParams.resendEmailAddress){
          $scope.toAddress = [$stateParams.resendEmailAddress];
        }else{
          $scope.toAddress = $stateParams.selectedSharePlanList;
        }
        $scope.meetingName = $stateParams.meetingName;
        $scope.status = $sessionStorage.status;
        $scope.gmDate = $stateParams.gmDate;
        $scope.gmDateTime = $stateParams.gmDateTime;
        $scope.onLoadSpinnerReinvite = true;
        // Below is the service call for first time to load issuer logo
        MyAccountsService
          .getIssuerGroupDetails($stateParams.issuerId,
            $sessionStorage.gmLang)
          .then(
            function(response) {
              $scope.issuerLogoNotAvailable = true;
              $scope.issuerGroupDetails = response.data;
              if ($scope.issuerGroupDetails.logoBaseStr) {
                $scope.issuerLogoNotAvailable = false;
              }
            }, function(error) {
              $scope.issuerLogoNotAvailable = true;
            });
        //Below service will be called on load of page to fetch email details
        AgentService.getInviteSPDeatails($stateParams.meetingId,$stateParams.emailLogId).then(
          function(response) {
            $scope.reInviteEmailDetails = response.data.emailVO;
            $scope.gmDocumentVO = response.data.gmDocumentVO;
            $scope.fromAddress = response.data.fromAddress;
            $scope.onLoadSpinnerReinvite = false;
          }, function(error) {
            $log.debug(error);
          });
        //Below function will be called when send button is clicked from HTML
        $scope.reinviteSharePlan = function(){
          $scope.errorMessage = '';
          $scope.disableSend = true;
          //Below line is added to reset the documents array to avoid adding duplicates.-ALM-10983 
          $scope.documentsfiles = [];
          angular.forEach($scope.gmDocumentVO,
            function(docList) {
              if (docList.checked) {
                $scope.documentsfiles.push(docList.meetingDocId);
              }
            });
          var reinviteRequestObj={
            'emailLogId': $stateParams.emailLogId,
            'emailAddress': $scope.toAddress,
            'fromAddress': $scope.fromAddress,
            'meetingId' : $stateParams.meetingId,
            'meetingDocId': $scope.documentsfiles
          };
          AgentService.reinviteSharePlan(reinviteRequestObj, 'secureToken').then(
            function(success) {
              $scope.modalHeader = $filter('translate')('label_inviteSharePlan');
              $scope.modalBody = success.data.message;
              ModelWindowService.showModelWindow('reinviteSuccessMsg');
            }, function(error) {
              $scope.disableSend = false;
              $scope.errorMessage = error.data.errors[0].errorMessage;;
            });
        };
                          
        //Below function call is for navigating to registration tab when 'OK' button clicked in success pop up
        $scope.goToRegistrationTab = function(){
          $state.go('generalDetailsView.registrations',{'meetingId':$stateParams.meetingId,'status':$scope.status,'gmDateTime':$scope.gmDateTime});
        };
      }]);