/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.ViewVotingPatternController
 * @description This controller is used for view details of individual voting instruction in Agent workstation
 * @requires generalMeetingService is the service which is being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 */
'use strict';

angular
  .module('generalMeetingHomeModule')
  .controller(
    'ViewVotingPatternController',
    [
      '$scope',
      'generalMeetingService',
      '$log',
      '$stateParams',
      '$state',
      function($scope,  generalMeetingService,
        $log,$stateParams,$state) {
        //This implementation is a part of - SFCBP-2312
        $scope.issuerLogo = $stateParams.issuerLogo;
        $scope.shareholderDetails = $stateParams.shareholderDetails;
        $scope.onLoadSpinner = true;
        //Below service call is to navigate to individual voting result page
        generalMeetingService.getVotingResult($scope.shareholderDetails.instructionId, 'EN').then(
          function(response) {
            $scope.onLoadSpinner = false;
            $scope.votingResults = response.data;
            $scope.agendaCollection = $scope.votingResults.votingInstAgendaVOlist;

            angular
              .forEach(
                $scope.agendaCollection,
                function(item) {
                  $scope.selectedVote = appConstant.NONE;
                  $scope.entireHolding = item.entireHolding;
                  $scope.noOfSecurities = item.noOfSecurities;
                  $scope.registrationNo = item.registrationNo;
                  if (item.voteFor !== 0 && item.splitVote !== 'Y') {
                    item.selectedVote = appConstant.FOR;
                  }
                  if (item.voteAgainst !== 0 && item.splitVote !== 'Y') {
                    item.selectedVote = appConstant.AGAINST;
                  } 
                  if (item.voteAbstain !== 0 && item.splitVote !== 'Y') {
                    item.selectedVote = appConstant.ABSTAINED;
                  }
                });
          }, function(error) {
            $scope.onLoadSpinner = false;
            $log.debug(error);
          });
      }]);
