/**
 * @ngdoc controller
 * @name userMaintenanceModule.EditIntermediaryGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with editing details of particular intermediary group
 * @requires userGroupService and fileUploadSvc are the service which are being
 *           used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter country and translate message from locale file
 * @requires $sessionStorage to get logged in userId from session storage
 * @requires $uibModal for success modal window
 * @requires $state for state routing
 */

angular
  .module('userMaintenanceModule')
  .controller('EditIntermediaryGroupController', [
    '$scope',
    '$log',
    '$stateParams',
    'userGroupService',
    '$sessionStorage',
    '$filter',
    '$timeout',
    'fileUploadSvc',
    'fileDownloadSvc',
    'cbpDownloadBlob',
    '$uibModal',
    '$state',
    function (
      $scope,
      $log,
      $stateParams,
      userGroupService,
      $sessionStorage,
      $filter,
      $timeout,
      fileUploadSvc,
      fileDownloadSvc,
      cbpDownloadBlob,
      $uibModal,
      $state
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.intermediaryId = $stateParams.groupId;
      $log.debug('intermediaryId', $scope.intermediaryId);
      $scope.modalBody = $filter('translate')('confirmGroup_editDetails');
      $scope.modalLeftButton = $filter('translate')('label_confirm');
      $scope.modalRightButton = $filter('translate')('label_cancel');
      $scope.userGroupName = 'intermediary';

      /* date picker */
      /* download registration form starts here */
      $scope.downloadRegistrationForm = function () {
        let downloadUrl = '/v1/intermediaries/agreementforms/download';
        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            cbpDownloadBlob(response.file, response.fileName);
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      /* download registration ends starts here */
      $scope.inlineOptions = {
        customClass: getDayClass,
      };

      $scope.openStartDate = function () {
        if ($scope.startDate) {
          $scope.startDate.opened = true;
        } else {
          $scope.startDate = new Date();
          $scope.startDate.opened = true;
        }
      };
      $scope.openEndDate = function () {
        if ($scope.endDate) {
          $scope.endDate.opened = true;
        } else {
          $scope.endDate = new Date();
          $scope.endDate.opened = true;
        }
      };
      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };
      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.startDate = {
        opened: false,
      };
      $scope.endDate = {
        opened: false,
      };
      function getDayClass(data) {
        var date = data.date,
          mode = data.mode;
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(
              0,
              0,
              0,
              0
            );

            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }
        return '';
      }
      /* date picker */
      // Below is the service call to get list of countries
      userGroupService
        .getCountryList('EN')
        .query()
        .$promise.then(
          function (response) {
            $scope.countries = response.countries;
            // list of non proxies list - SFCBP-2666
            userGroupService
              .getNonProxiesList('GM', $stateParams.groupName)
              .query()
              .$promise.then(
                function (data) {
                  $scope.nonProxies = data;
                  userGroupService
                    .getViewIntermediaryGroup($scope.intermediaryId, 'GM', 'EN')
                    .query()
                    .$promise.then(
                      function (response) {
                        $scope.userGroupDetails = response;
                        $scope.selectedCountry = $filter('filter')(
                          $scope.countries,
                          {
                            countryName: $scope.userGroupDetails.country,
                          },
                          true
                        );
                        $scope.selectedClearingIntermediary = $filter('filter')(
                          $scope.nonProxies,
                          {
                            intermediaryName:
                              $scope.userGroupDetails.custodianName,
                          },
                          true
                        )[0];
                      },
                      function (error) {
                        $log.debug(error);
                      }
                    );
                },
                function (error) {
                  $log.debug(error);
                }
              );
            // SFCBP-2666
          },
          function (error) {
            $log.debug(error);
          }
        );

      // The below function call is to store the data from UI
      // and pass it to REST call for updating user
      $scope.updateUserGroup = function () {
        let registrationFile = $scope.newIntermediryForm.registrationFile;

        $scope.error = false;
        $scope.success = false;

        var documentFormData = new FormData();
        if ($scope.selectedClearingIntermediary) {
          documentFormData.append(
            'custodianId',
            $scope.selectedClearingIntermediary.intermediaryId
          );
        }
        documentFormData.append('groupId', $scope.intermediaryId);
        documentFormData.append('userId', $sessionStorage.userId);
        documentFormData.append('groupName', $scope.userGroupDetails.groupName);
        documentFormData.append(
          'bankIdentifierCode',
          $scope.userGroupDetails.bankIdentifierCode
        );
        documentFormData.append(
          'groupEmail',
          $scope.userGroupDetails.groupEmail
        );
        if (
          undefined !== $scope.userGroupDetails.phoneNo &&
          null !== $scope.userGroupDetails.phoneNo
        ) {
          documentFormData.append('phoneNo', $scope.userGroupDetails.phoneNo);
        }
        documentFormData.append('city', $scope.userGroupDetails.city);
        documentFormData.append(
          'country',
          $scope.selectedCountry[0].isoCountryCode
        );
        documentFormData.append('productCode', 'GM');
        documentFormData.append('status', $scope.userGroupDetails.status);
        // it was coming from scope
        documentFormData.append('intermediaryVO.proxyCode', 'N');
        documentFormData.append(
          'intermediaryVO.processAssignedSc',
          $scope.userGroupDetails.intermediaryVO.processAssignedSc
        );
        documentFormData.append(
          'intermediaryVO.processVotingInstruction',
          $scope.userGroupDetails.intermediaryVO.processVotingInstruction
        );
        documentFormData.append(
          'intermediaryVO.visibleInShWorkStation',
          $scope.userGroupDetails.intermediaryVO.visibleInShWorkStation
        );
        documentFormData.append(
          'intermediaryVO.processAttendanceRequest',
          $scope.userGroupDetails.intermediaryVO.processAttendanceRequest
        );
        documentFormData.append(
          'intermediaryVO.processCustomTemplate',
          $scope.userGroupDetails.intermediaryVO.processCustomTemplate
        );
        documentFormData.append(
          'intermediaryVO.requiresMeetingNotice',
          $scope.userGroupDetails.intermediaryVO.requiresMeetingNotice
        );

        if (registrationFile) {
          documentFormData.append(
            'intermediaryVO.agreementForm',
            registrationFile
          );
          documentFormData.append(
            'intermediaryVO.agreementFormName',
            registrationFile.name
          );
        }
        var startDate = new Date($scope.startDate);
        var endDate = new Date($scope.endDate);
        documentFormData.append(
          'intermediaryVO.effectiveEndDate',
          $filter('date')(endDate, 'yyyy-MM-dd HH:mm:ss')
        );
        documentFormData.append(
          'intermediaryVO.effectiveStartDate',
          $filter('date')(startDate, 'yyyy-MM-dd HH:mm:ss')
        );
        var uploadUrl = 'v1/intermediaries/' + $scope.intermediaryId;

        if (registrationFile) {
          if (
            'Invalid Date' === startDate ||
            'Invalid Date' === endDate ||
            null === $scope.startDate ||
            null === $scope.endDate
          ) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('selectDate_error');
          } else {
            if (
              ('application/pdf' !== registrationFile.type ||
                'application/softgrid-pdf' !== registrationFile.type) &&
              registrationFile.size > 1000000
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('fileSize_1mbError');
            } else {
              if (startDate.getTime() > endDate.getTime()) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')('selectToDate_error');
              } else {
                // Call the upload directive to upload file and FormData
                if (
                  $scope.newIntermediryForm.userName.$valid &&
                  $scope.newIntermediryForm.emailAddress.$valid &&
                  $scope.newIntermediryForm.city.$valid &&
                  $scope.newIntermediryForm.bankIdentifierCode.$valid
                ) {
                  fileUploadSvc
                    .uploadFileToUrl(
                      registrationFile,
                      uploadUrl,
                      documentFormData
                    )
                    .then(
                      function (data) {
                        if (data.status === 200 || data.status === 201) {
                          $scope.success = true;
                          $scope.timedout = $uibModal.open({
                            templateUrl: 'success-dialog.html',
                          });
                          $timeout(function () {
                            $scope.timedout.close();
                            $scope.timedout = null;
                            $state.go('userMaintenance.intermediaryGroup', {
                              pageNo: $stateParams.pageNo,
                            });
                          }, 2000);
                        } else {
                          $log.debug('error');
                          $scope.successMsg = null;
                          $scope.error = true;
                          $scope.errorMsg = data.data.errors[0].errorMessage;
                        }
                      },
                      function (error) {
                        $log.debug(error);
                        $scope.error = true;
                        $scope.errorMsg = error.data.errors[0].errorMessage;
                      }
                    );
                }
              }
            }
          }
        } else {
          if (
            $scope.newIntermediryForm.userName.$valid &&
            $scope.newIntermediryForm.emailAddress.$valid &&
            $scope.newIntermediryForm.city.$valid &&
            $scope.newIntermediryForm.bankIdentifierCode.$valid
          ) {
            fileUploadSvc
              .uploadFileToUrl(registrationFile, uploadUrl, documentFormData)
              .then(
                function (data) {
                  if (data.status === 200 || data.status === 201) {
                    $scope.success = true;
                    $scope.timedout = $uibModal.open({
                      templateUrl: 'success-dialog.html',
                    });
                    $timeout(function () {
                      $scope.timedout.close();
                      $scope.timedout = null;
                      $state.go('userMaintenance.intermediaryGroup', {
                        pageNo: $stateParams.pageNo,
                      });
                    }, 2000);
                  } else {
                    $log.debug('error');
                    $scope.successMsg = null;
                    $scope.error = true;
                    $scope.errorMsg = data.data.errors[0].errorMessage;
                  }
                },
                function (error) {
                  $log.debug(error);
                  $scope.error = true;
                  $scope.errorMsg = error.data.errors[0].errorMessage;
                }
              );
          }
        }
      };
    },
  ]);
