/**
 * @ngDoc controller
 * @name shareHolderModule.ThankYouController
 * @description This controller is used to display static thank you page
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires issuerLogo is a variable passed from sharePlanModule where there is
 *           a service call to userGroupService
 * @requires $state
 */
angular
  .module('shareHolderModule')
  .controller(
    'ThankYouController',
    [
      '$scope',
      '$sessionStorage',
      '$stateParams',
      'issuerLogo',
      'voteInstructionService',
      '$state',
      function($scope, $sessionStorage, $stateParams, issuerLogo,
        voteInstructionService, $state) {
        $scope.issuerLogo = issuerLogo.data;
        $scope.shareClassName = $stateParams.shareClassName;
        $scope.issuerId = $stateParams.issuerId;
        $scope.gmId = $stateParams.gmId;
        $scope.userType = $sessionStorage.usertype;
        $scope.languageCode = $sessionStorage.workstationData.selectedLang;
        $scope.moreThanOneShareClass = $stateParams.moreThanOneShareClass;
        $sessionStorage.thankYouPage = true;

        if ($scope.userType === 'SP' || $scope.userType === 'SH') {
          $scope.shareholderId = $sessionStorage.groupId;
        } else {
          $scope.shareholderId = 0;
        }
        $scope.votingInstructionView = function() {
          voteInstructionService.getShareClassList($scope.gmId,
            $scope.shareholderId, $scope.languageCode,
            $sessionStorage.usertype).query().$promise
            .then(function(data) {
              $state.go('votingInstructions', {
                meetingId: $scope.gmId,
                issuerId: $scope.issuerId,
                shareHolderId: $scope.shareholderId,
                'selectedIndex': 1,
                votingInstDetails: data,
                shareClassName: $scope.shareClassName,
                breadCrumbDetails: []
              });
            }, function(error) {
              return error;
            });
        };
      }]);
