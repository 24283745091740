/**
 * @ngdoc controller
 * @name userMaintenanceDwtModule.AddIntermediaryDwtController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with adding new intermediary user.
 * @requires userService is the service which is being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $state for state routing
 */

angular
  .module('userMaintenanceDwtModule')
  .controller('AddPeShareholderDwtController', [
    '$scope',
    '$filter',
    '$state',
    '$stateParams',
    '$sessionStorage',
    '$timeout',
    'userService',
    'DwtShareholderUser',
    'DwtIntermediaryGroup',
    function (
      $scope,
      $filter,
      $state,
      $stateParams,
      $sessionStorage,
      $timeout,
      userService,
      DwtShareholderUser,
      DwtIntermediaryGroup
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.userGroupId = $stateParams.userGroupId;
      $scope.shareclassId = $stateParams.shareclassId;
      $scope.navFrom = $stateParams.navFrom;
      $scope.userId = $sessionStorage.userId;
      //Call Constructor of DWT Intermediary User Modal
      $scope.callOnLoad = function (accType) {
        $scope.addUserDetails = new DwtShareholderUser(
          undefined,
          $scope.userGroupId,
          $scope.userId,
          '',
          $scope.shareclassId,
          accType,
          $stateParams.eventId
        );
      };

      $scope.successCallback = function (response) {
        $scope.success = true;
        $scope.failure = false;
        $scope.message = response.data.message;
        $('#successMsg').modal('show');
        $timeout(function () {
          $('#successMsg').modal('hide');
          $('.modal-backdrop').remove();
          if ($scope.navFrom) {
            document.getElementById('label_goBack').click();
          } else {
            $state.go('userMaintenanceForDWT.peShareholderListDwt', {
              groupId: response.data.groupId,
              successMsg: $scope.message,
            });
          }
          $('.modal-backdrop').remove();
        }, 2000);
      };
      $scope.errorCallback = function (error) {
        $scope.failure = true;
        $scope.success = false;
        if (
          'CBP_ERR_485' === error.data.code ||
          'CBP_ERR_147' === error.data.code
        ) {
          $scope.errorMessage = error.data.message;
        } else if (error.data.errors.length > 0) {
          $scope.errorMessage = error.data.errors[0].errorMessage;
        } else {
          $scope.errorMessage = error.data;
        }
      };
      $scope.successCallbackForIntermedairy = function (response) {
        if (response.status == 200) {
          $scope.successIntermedairy = true;
          $scope.failureIntermedairy = false;
          $scope.messageIntermedairy = response.data.message;
          $scope.addUserDetails.getIntermediaryList();
          $scope.dismissIntermedairyModal();
          $scope.interList = $scope.addUserDetails.intermediaryList;
          angular.forEach($scope.interList, function (value, key) {
            if (value.intermediaryId === '') {
              return (value.intermediaryId = response.data.groupId);
            }
          });
        } else {
          $scope.failureIntermedairy = true;
          $scope.successIntermedairy = false;
          if (response.data.errors.length > 0) {
            $scope.messageIntermedairy = response.data.errors[0].errorMessage;
          } else {
            $scope.messageIntermedairy = response.data;
          }
        }
      };
      $scope.errorCallbackForIntermedairy = function (error) {
        $scope.failureIntermedairy = true;
        $scope.successIntermedairy = false;
        if (error.data.errors.length > 0) {
          $scope.messageIntermedairy = error.data.errors[0].errorMessage;
        } else {
          $scope.messageIntermedairy = error.data;
        }
      };
      $scope.callIntermedairyModal = function () {
        $scope.addInterForm.$setPristine();
        $scope.addIntermediaryGroup = new DwtIntermediaryGroup(
          undefined,
          $scope.userId
        );
        $scope.successIntermedairy = false;
        $scope.failureIntermedairy = false;
        $('#createIntermediary').modal('show');
      };
      $scope.dismissIntermedairyModal = function () {
        $scope.successIntermedairy = false;
        $scope.failureIntermedairy = false;
        $('#createIntermediary').modal('hide');
      };
      $scope.addIntermediaryGroupMethod = function () {
        var userId = $sessionStorage.userId;
        var uploadUrl = 'v1/intermediaries';
        var prodType = $sessionStorage.productType;
        $scope.addIntermediaryGroup.selectedCountry = [];
        $scope.addIntermediaryGroup.selectedCountry[0] = {
          isoCountryCode: 'NLD',
        };
        $scope.addIntermediaryGroup.city = 'N/A';
        $scope.addIntermediaryGroup.bankIdentifierCode = 'N/A';
        $scope.addIntermediaryGroup.ibanNumber = 'N/A';
        $scope.addIntermediaryGroup.validateIBAN = 'N';
        $scope.addIntermediaryGroup.addGroup(
          null,
          null,
          uploadUrl,
          userId,
          prodType,
          $scope.successCallbackForIntermedairy,
          $scope.errorCallbackForIntermedairy
        );
      };
      $scope.addUser = function (intermediaryList) {
        var errorFlag = false;
        var errorCode = '0';
        if (null !== intermediaryList && 0 !== intermediaryList.length) {
          angular.forEach(intermediaryList, function (value) {
            if ('' === value.intermediaryId || null === value.intermediaryId) {
              errorFlag = true;
              errorCode = '1';
            } else {
              if ('0' === value.intermediaryId && !value.intermediaryName) {
                errorFlag = true;
                errorCode = '2';
              }
              if (
                '0' === value.intermediaryId &&
                !value.intermediaryEmailAddress
              ) {
                errorFlag = true;
                errorCode = '3';
              }
            }
          });
          if (errorFlag === false) {
            $scope.addUserDetails.createUser(
              $scope.successCallback,
              $scope.errorCallback
            );
          } else {
            $scope.failure = true;
            $scope.success = false;
            switch (errorCode) {
              case '1':
                $scope.errorMessage = $filter('translate')(
                  'label_error_emptyIntermediarySH'
                );
                break;
              case '2':
                $scope.errorMessage = $filter('translate')(
                  'label_error_emptyNotListedIntermediarySH'
                );
                break;
              case '3':
                $scope.errorMessage = $filter('translate')(
                  'label_error_emptyIntermediaryEmailSH'
                );
                break;
            }
          }
        } else {
          $scope.failure = true;
          $scope.success = false;
          $scope.errorMessage = $filter('translate')(
            'label_error_intermediaryListSH'
          );
        }
      };
      $scope.removeErrorMessage = function () {
        $scope.errorMessage = '';
      };
      $scope.callOnLoad('LGL');
    },
  ]);
