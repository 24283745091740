import formatLocationString from '@/lib/formatLocationString';

/**
 * @ngDoc controller
 * @name shareHolderModule.ViewVotingInstructionController
 * @description This controller is used to view the voting instruction.
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires userGroupService
 * @requires voteInstructionService
 */
angular
  .module('shareHolderModule')
  .controller('ViewVotingInstructionController', [
    '$scope',
    '$filter',
    '$sessionStorage',
    '$stateParams',
    'GeneralMeetingDataLanguageService',
    'userGroupService',
    'voteInstructionService',
    function (
      $scope,
      $filter,
      $sessionStorage,
      $stateParams,
      GeneralMeetingDataLanguageService,
      userGroupService,
      voteInstructionService
    ) {
      $scope.userType = $sessionStorage.usertype;
      // Below is the function used to fetch issuer logo
      userGroupService.getIssuerGroupLogo($stateParams.issuerId).then(
        function (response) {
          $scope.issuerLogoPresent = false;
          $scope.issuerGroupDetails = response.data;
          // Do not change the below condition. It is for
          // issuer logo display fix. REST response has ""
          if ($scope.issuerGroupDetails.logoBaseStr) {
            $scope.issuerLogoPresent = true;
          }
        },
        function (error) {
          $scope.issuerLogoError = error;
        }
      );
      $scope.radioVal = $stateParams.radioVal;
      $scope.dropdownVal = $stateParams.dropdownVal;
      if ($stateParams.dropdownVal && $stateParams.radioVal) {
        $sessionStorage.dropdownVal = $stateParams.dropdownVal;
        $sessionStorage.radioVal = $stateParams.radioVal;
      } else {
        $sessionStorage.dropdownVal = 'ALL';
        $sessionStorage.radioVal = 'voteIns';
      }
      // Below if condition is added to display hard coded proxy
      // text for telenet issuer
      $scope.showAllFor = true;
      if ($stateParams.issuerName) {
        $scope.issuerName = $stateParams.issuerName.toLowerCase();
        if ($scope.issuerName == 'shell plc') {
          $scope.showAllFor = false;
        }
      }
      // when language changes, following piece of code is
      // executed from header-controller.js
      $scope.languageCode =
        $sessionStorage.workstationData.selectedLang || 'EN';
      $scope.$on('onLanguageChangeVotingInstruction', function (event, lang) {
        $scope.languageCode = lang;
        $scope.onLoadVI();
      });
      // On load of page, the following function is called,
      // which makes service call to get shareclass details.
      $scope.onLoadVI = function () {
        if ($sessionStorage.usertype !== 'SH' &&
            $sessionStorage.usertype !== 'SP') {
          voteInstructionService
          .getShareClassListWithVotes(
              $stateParams.meetingId,
              $stateParams.shareholderId,
              $scope.languageCode,
              $sessionStorage.usertype,
              $stateParams.instructionId,
              $stateParams.status
          )
          .query()
          .$promise.then(
              function (data) {
                // if chosen language does not have
                // data, then switch to English, else
                // fetch the existing details.
                if (data.length <= 0 && $scope.languageCode !== 'EN') {
                  $scope.languageCode = 'EN';
                  $scope.onLoadVI();
                } else {
                  $scope.votingInstructionDetails = GeneralMeetingDataLanguageService.getLanguageSpecificData(
                      data,
                      $scope.languageCode
                  );
                  angular.forEach($scope.votingInstructionDetails, function (
                      item
                  ) {
                    if (item.selectedShareClassId === item.shareclassIdWOSalt) {
                      $scope.votingInstDetails = item;
                      $scope.shareClassId = item.shareclassId;
                      angular.forEach(item.intermediaryVO, function (item2) {
                        if (
                            item.selectedIntermediaryId ===
                            item2.intermediaryIdWoSalt
                        ) {
                          $scope.intermediaryId = item2.intermediaryId;
                        }
                      });
                      angular.forEach(item.vcShareclassAssocVO, function (item3) {
                            if (item.selectedVCUserId === item3.vcUserIdWoSalt) {
                              $scope.selectedVC = item3;
                            }
                          });
                    }
                  });
                }
                $scope.agenda();
              },
              function (error) {
                $scope.error = error;
              }
          );
        } else {
          voteInstructionService.getVotingInstructionWithVotesAndAgenda(
              $stateParams.instructionId,
              $scope.languageCode,
          )
          .query()
          .$promise.then(
              function (data) {
                if ($scope.languageCode !== 'EN') {
                  $scope.languageCode = 'EN';
                  $scope.onLoadVI();
                } else {
                  $scope.votingInstDetails = data;
                  $scope.votingInstDetails.selectedIntermediaryName = data.intermediaryName;
                  $scope.votingInstDetails.shareholderPosition = data.numberOfSecurities;
                  $scope.shareClassId = data.shareClassId;
                  $scope.intermediaryId = data.intermediaryId;
                  $scope.selectedVC = {
                    vcName: data.voteCollectorName
                  };
                }
                // Agenda Assignment
                $scope.agendaCollection = data.votes;
                // if chosen language does not have
                // data, then switch to English, else
                // fetch the existing details.
                if ($scope.languageCode !== 'EN') {
                  $scope.languageCode = 'EN';
                  $scope.onLoadVI();
                } else {
                  angular.forEach($scope.agendaCollection, function (item) {
                    $scope.selectedVote = 'none';
                    $scope.entireHolding = item.entireHolding;
                    $scope.noOfSecurities = item.noOfSecurities;
                    $scope.registrationNo = item.registrationNo;
                    $scope.securityAccountNumber = data.securityAccountNumber;
                    if (item.voteFor !== 0) {
                      item.selectedVote = 'for';
                    } else {
                      item.voteFor = 0;
                    }
                    if (item.voteAgainst !== 0) {
                      item.selectedVote = 'against';
                    } else {
                      item.voteAgainst = 0;
                    }
                    if (item.voteAbstain !== 0) {
                      item.selectedVote = 'abstained';
                    } else {
                      item.voteAbstain = 0;
                    }
                  });
                  angular.forEach($scope.agendaCollection, function (key2) {
                    if (key2.isVotable === 'Y') {
                      $scope.isVotableFlag = true;
                    }
                    return $scope.isVotableFlag;
                  });
                }
              },
              function (error) {
                $log.debug(error);
              }
          );
        }
      };

      $scope.getProxyTextParagraph2 = function () {
        if ($scope.issuerName === 'telenet group holding nv') {
          return $filter('translate')(
            'vi_shareholder_disclaimer8_with_right_of_substitution'
          );
        } else {
          return $filter('translate')('vi_shareholder_disclaimer8');
        }
      };

      // Below is the function call to fetch agenda items
      $scope.agenda = function () {
        // Call method based on user type
        voteInstructionService
          .getAgendaDetailsShView(
            $stateParams.meetingId,
            $scope.languageCode,
            $stateParams.shareholderId,
            $scope.shareClassId,
            $scope.intermediaryId,
            $stateParams.userType,
            $stateParams.status
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.agendaCollection = data;
              // if chosen language does not have
              // data, then switch to English, else
              // fetch the existing details.
              if (data.length <= 0 && $scope.languageCode !== 'EN') {
                $scope.languageCode = 'EN';
                $scope.agenda();
              } else {
                angular.forEach($scope.agendaCollection, function (item) {
                  $scope.selectedVote = 'none';
                  $scope.entireHolding = item.entireHolding;
                  $scope.noOfSecurities = item.noOfSecurities;
                  $scope.registrationNo = item.registrationNo;
                  $scope.securityAccountNumber = item.securityAccountNumber;
                  if (item.voteFor !== 0) {
                    item.selectedVote = 'for';
                  } else {
                    item.voteFor = 0;
                  }
                  if (item.voteAgainst !== 0) {
                    item.selectedVote = 'against';
                  } else {
                    item.voteAgainst = 0;
                  }
                  if (item.voteAbstain !== 0) {
                    item.selectedVote = 'abstained';
                  } else {
                    item.voteAbstain = 0;
                  }
                });
                angular.forEach($scope.agendaCollection, function (key2) {
                  if (key2.isVotable === 'Y') {
                    $scope.isVotableFlag = true;
                  }
                  return $scope.isVotableFlag;
                });
              }
            },
            function (error) {
              $scope.error = error;
            }
          );
      };
      $scope.onLoadVI();

      $scope.formattedLocationString = () => {
        return formatLocationString([
          $scope.votingInstDetails?.locationOnTicket,
          $scope.votingInstDetails?.street,
          $scope.votingInstDetails?.postalCode,
          $scope.votingInstDetails?.city,
        ]);
      };
    },
  ]);
