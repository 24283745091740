/**
 * @ngdoc ShareholderEmailController
 * @description This controller is used for shareholder module.
 * @requires ShareholderEmailController is to send email to shareholders
 * @requires $scope
 * @requires $log to debug logs
 * @requires $sessionStorage to store/pass data in session
 */
'use strict';

angular.module('intermediaryModule').controller('ShareholderEmailController', [
  '$scope',
  'voteInstructionService',
  'fileUploadSvc',
  '$log',
  '$stateParams',
  '$sessionStorage',
  '$filter',
  function (
    $scope,
    voteInstructionService,
    fileUploadSvc,
    $log,
    $stateParams,
    $sessionStorage,
    $filter
  ) {
    $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

    $scope.languageCode = $sessionStorage.workstationData.selectedLang;
    $scope.userName = $sessionStorage.userName;
    $scope.issuerName = $stateParams.issuerName;
    $scope.gmDateTime = $stateParams.gmDateTime;
    $scope.gmDateTimeStamp = $stateParams.gmDateTimeStamp;
    $scope.meetingType = $stateParams.meetingType;
    $scope.checkboxChecked = 'Y';
    $scope.emailBody = '';
    //setting the shareholderId as zero for user type other than shareholder and shareplan users.
    if (
      $sessionStorage.usertype === 'SH' ||
      $sessionStorage.usertype === 'SP'
    ) {
      $scope.shareholderId = $sessionStorage.groupId;
    } else {
      $scope.shareholderId = 0;
    }
    //Service call to get subject, template body and share class list
    voteInstructionService
      .getIntRegistrationDetails(
        $stateParams.meetingId,
        $sessionStorage.groupId,
        $scope.languageCode,
        $scope.issuerName,
        $scope.meetingType,
        $scope.gmDateTimeStamp
      )
      .then(
        function (response) {
          $log.debug('success user view');
          $scope.shareClassList = response.data.shareClassVO;
          $scope.emailSubject = response.data.emailSubject;
          $scope.emailBody = response.data.emailBody;
        },
        function (error) {
          $log.debug(error);
        }
      );
    var readFile = this;
    readFile.documents = [
      {
        fileVo: [
          {
            file: '',
            fileName: '',
          },
        ],
      },
    ];
    //setting the data for multipart file upload
    $scope.sendRegistrationCertificate = function () {
      $log.debug('$scope.checkboxChecked', $scope.checkboxChecked);
      $log.debug('inside send reg certificate');
      var documentFormData = new FormData();
      documentFormData.append('meetingId', $stateParams.meetingId);
      documentFormData.append('intermediaryId', $sessionStorage.groupId);
      documentFormData.append('langCode', $scope.languageSelected);
      documentFormData.append(
        'shareclassId',
        $scope.selectedShareClassVC.shareclassId
      );
      documentFormData.append('sendEmail', $scope.checkboxChecked);
      documentFormData.append('emailSubject', $scope.emailSubject);
      documentFormData.append(
        'emailBody',
        $filter('htmlDecode')($scope.emailBody).toString()
      );
      $scope.error = false;
      $scope.errorEmail = false;
      $scope.errorFile = false;
      $log.debug('Uploading file with FormData');
      $log.debug('bulk file', $scope.bulkFile);
      if ($scope.emailBody === null || $scope.emailBody === undefined) {
        $scope.errorEmail = true;
        $scope.errorEmailMsg = $filter('translate')('label_emailEmptyError');
      } else {
        $scope.errorEmail = false;
      }
      if (
        $scope.checkboxChecked === 'N' &&
        ($scope.bulkFile === null || $scope.bulkFile === undefined)
      ) {
        $scope.errorFile = true;
        $scope.errorFileMsg = $filter('translate')('uploadFile_error');
      } else if ($scope.checkboxChecked === 'N') {
        $scope.errorFile = false;
        $log.debug('file', $scope.bulkFile);
        documentFormData.append('file', $scope.bulkFile);
        documentFormData.append('recipientAddress', $scope.recipientAddress);
      }
      if (
        $scope.error !== true &&
        $scope.errorFile !== true &&
        $scope.errorEmail !== true
      ) {
        $scope.loadingSpin = true;
        var file = $scope.bulkFile;
        var uploadUrl =
          'v1/generalmeetings/intermediary/sendShareholderRegcertificate';
        /*
         * Call the upload directive to upload file and
         * FormData
         */
        $scope.success = false;
        $scope.error = false;
        fileUploadSvc.uploadFileToUrl(file, uploadUrl, documentFormData).then(
          function (success) {
            $scope.status = success.status;
            if ($scope.status === 200) {
              $scope.loadingSpin = false;
              $scope.success = true;
              $scope.error = false;
              $scope.messageSuccess = success.data.message;
              $log.debug(' $scope.message', $scope.message);
            } else {
              $scope.loadingSpin = false;
              $scope.error = true;
              $scope.success = false;
              $scope.message = success.data.errors[0].errorMessage;
              $log.debug(' $scope.message', $scope.message);
            }
          },
          function (error) {
            $scope.loadingSpin = false;
            $log.debug(error);
            $scope.success = false;
            $scope.error = true;
            $scope.errorFailed = error;
          }
        );
      }
    };
  },
]);
