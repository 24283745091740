/**
 * @ngdoc controller
 * @name loginModule.ResetPasswordController
 * @description This controller is used for password reset. This mainly
 *              deals with forgot password functionality
 * @requires TokenSvc and MyAccountsService are the services which are being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $filter to translate messages from locale file
 * @requires $window to get location of application
 * @requires $state for state routing
 * @requires $sessionStorage
 */
'use strict';

angular.module('loginModule').controller('ResetPasswordController', [
  '$scope',
  '$state',
  '$timeout',
  '$stateParams',
  '$window',
  'TokenSvc',
  '$filter',
  '$sessionStorage',
  'MyAccountsService',
  'voteInstructionService',
  'attendanceRequestService',
  'shareHolderService',
  'generalMeetingService',
  'AuthService',
  'menuService',
  '$log',
  'userGroupService',
  'User',
  function (
    $scope,
    $state,
    $timeout,
    $stateParams,
    $window,
    TokenSvc,
    $filter,
    $sessionStorage,
    MyAccountsService,
    voteInstructionService,
    attendanceRequestService,
    shareHolderService,
    generalMeetingService,
    AuthService,
    menuService,
    $log,
    userGroupService,
    User
  ) {
    $scope.password;
    $scope.rePassword;
    $scope.resetProgress = false;
    $scope.resetSuccess = false;
    $scope.isResetDone = false;
    $scope.passwordMisMatch = false;
    $scope.resetPasswordError = false;
    $sessionStorage.isPublicPage = true;
    $scope.shareplanUrl = $stateParams.location;
    $scope.resetNewPassword = resetNewPassword;
    $scope.sharePlanloginShareClassId = $sessionStorage.scid;
    //Below is the variable and function call which are used for updating password
    $scope.passwordExpired = $stateParams.passwordExpired;

    $scope.updatePassword = function () {
      $scope.pwrdErrorMessage = '';
      if ($scope.oldPassword === $scope.newPassword) {
        $scope.pwrdErrorMessage = $filter('translate')('newPwd_oldPwdError');
        $scope.successMessage = '';
      } else if ($scope.newPassword === $scope.confirmPassword) {
        let updatePasswordRequest = {
          emailAddress: $stateParams.passwordResetData.username,
          password: $scope.oldPassword,
          newPassword: $scope.newPassword,
          confirmPassword: $scope.confirmPassword,
        };
        MyAccountsService.updateExpiredPassword(
          updatePasswordRequest,
          'secureToken'
        ).then(
          function (response) {
            $log.debug(response);
            $scope.showSuccess = true;
            $timeout(function () {
              $scope.showSuccess = false;
              $state.go('intermediary');
            }, 10000);
          },
          function (error) {
            if (error.data && error.data.httpStatusCode === 400) {
              $scope.pwrdErrorMessage = error.data.errors[0].errorMessage;
            } else if (error.data && error.data.message) {
              $scope.pwrdErrorMessage = error.data.message;
            } else {
              $scope.pwrdErrorMessage = error;
            }
            $timeout(function () {
              $scope.pwrdErrorMessage = '';
            }, 10000);
          }
        );
      } else {
        $scope.pwrdErrorMessage = $filter('translate')('lable_newReenterError');
        $scope.successMessage = '';
        $timeout(function () {
          $scope.pwrdErrorMessage = '';
        }, 10000);
      }
    };

    // The below function call is to store the data from UI and
    // pass it to REST call to reset the password and navigates to the particular
    //login page on resetting password successfully
    function resetNewPassword() {
      if ($scope.password !== $scope.rePassword) {
        $scope.passwordMisMatch = true;
        $scope.resetPasswordError = $filter('translate')(
          'password_renterMatchError'
        );
      } else {
        $scope.passwordMisMatch = false;
        $scope.resetProgress = true;
        $scope.isResetDone = false;
        if ($stateParams.passwordResetData) {
          $stateParams.passwordResetData.newPassword = $scope.password;
        }
        TokenSvc.resetNewPassword($stateParams.passwordResetData).then(
          function (resp) {
            $scope.resetPasswordError = false;
            $scope.resetProgress = false;
            if (resp && resp.data) {
              var userScreenPageLink = '';
              //The below condition is to decide the login page based on the user type
              switch ($stateParams.passwordResetData.userType) {
                case 'AG':
                  userScreenPageLink = 'agent';
                  break;
                case 'IS':
                  userScreenPageLink = 'issuer';
                  break;
                case 'IN':
                  userScreenPageLink = 'intermediary';
                  break;
                case 'SP':
                  userScreenPageLink = 'shareplan';
                  break;
                case 'SPP':
                  userScreenPageLink = 'e-vest';
                  break;
                case 'SH':
                  userScreenPageLink = 'shareholderlogin';
                  break;
                case 'VC':
                  userScreenPageLink = 'votecollector';
                  break;
                default:
                  userScreenPageLink = 'agent';
                  break;
              }
              $scope.isResetDone = true;
              $scope.resetSuccess = true;

              $timeout(function () {
                //Below variable forms the url
                if (
                  userScreenPageLink === 'shareplan' &&
                  $sessionStorage.gmId &&
                  $sessionStorage.issuerId
                ) {
                  $scope.loading = true;
                  let formData = {
                    username: $stateParams.passwordResetData.emailAddress,
                    password: $scope.password,
                    userType: 'SP',
                  };

                  // following is a service call to authenticate the logged in user.
                  AuthService.login(formData).then(
                    async function (data) {
                      if (data.status === 200) {
                        $sessionStorage.groupId = data.data.userGroupId;
                        $sessionStorage.userId = data.data.userId;
                        $sessionStorage.isPublicPage = false;
                        if (!data.data.firstName) {
                          $sessionStorage.userName = data.data.lastName;
                        } else {
                          $sessionStorage.userName =
                            data.data.firstName + ' ' + data.data.lastName;
                        }
                        $sessionStorage.productType = data.data.prodType;
                        $sessionStorage.agentId = data.data.userId;
                        $sessionStorage.emailAddress = data.data.emailAddress;
                        let prodTypeArray = [];
                        if (data.data.productTypes) {
                          for (
                            let i = 0;
                            i < data.data.productTypes.length;
                            i++
                          ) {
                            prodTypeArray[i] =
                              data.data.productTypes[i].productType;
                            if (
                              'DWT' === data.data.productTypes[i].productType
                            ) {
                              $sessionStorage.isAdminDWT =
                                data.data.productTypes[i].isAmdin;
                            } else if (
                              'GM' === data.data.productTypes[i].productType
                            ) {
                              $sessionStorage.isAdminGM =
                                data.data.productTypes[i].isAmdin;
                            }
                          }
                        }
                        $sessionStorage.productTypes = prodTypeArray;
                        var selectedlang;
                        if (!$sessionStorage.workstationData) {
                          selectedlang = $sessionStorage.selectedLang
                            ? $sessionStorage.selectedLang
                            : 'EN';
                        } else {
                          selectedlang =
                            $sessionStorage.workstationData.selectedLang;
                        }

                        let menuData = menuService.getMenuItemsByWorkStationId(
                          $sessionStorage.usertype,
                          selectedlang,
                          $sessionStorage.gmId,
                          $sessionStorage.issuerId,
                          $sessionStorage.productTypes,
                          $sessionStorage.isAdminDWT
                        );
                        $scope.$root.$broadcast('onLogin', menuData);
                        if (
                          $sessionStorage.gmId &&
                          $sessionStorage.issuerId &&
                          $sessionStorage.navToVote
                        ) {
                          // If the logged in user is shareplan user, he is redirected to voting instruction page instead
                          // of action home page
                          voteInstructionService
                            .getShareClassList(
                              $sessionStorage.gmId,
                              $sessionStorage.groupId,
                              'EN',
                              $sessionStorage.usertype
                            )
                            .query()
                            .$promise.then(
                              function (data) {
                                $state.go('votingInstructions', {
                                  selectedIndex: 0,
                                  meetingId: $sessionStorage.gmId,
                                  issuerId: $sessionStorage.issuerId,
                                  shareHolderId: $sessionStorage.groupId,
                                  sharePlanloginShareClassId:
                                    $scope.sharePlanloginShareClassId,
                                  votingInstDetails: data,
                                  fromPage: 'throughUrl',
                                  breadCrumbDetails: [],
                                });
                              },
                              function (error) {
                                $scope.loading = false;
                                return error;
                              }
                            );
                        } else if (
                          $sessionStorage.gmId &&
                          $sessionStorage.issuerId &&
                          $sessionStorage.navToAttend
                        ) {
                          attendanceRequestService
                            .getAttendanceRequestView(
                              $sessionStorage.gmId,
                              'EN',
                              $sessionStorage.groupId,
                              $sessionStorage.usertype
                            )
                            .query()
                            .$promise.then(
                              function (data) {
                                // Below is the function used to fetch issuer name and navigation
                                userGroupService
                                  .getIssuerGroupLogo($scope.issuerId)
                                  .then(
                                    function (response) {
                                      $state.go('attendanceRequest', {
                                        issuerId: $sessionStorage.issuerId,
                                        gmId: $sessionStorage.gmId,
                                        sharePlanId: $sessionStorage.groupId,
                                        issuerName: response.data.issuerName,
                                        selectedIndex: 0,
                                        attendanceDataDeatils: data,
                                        fromPage: 'throughUrl',
                                        breadCrumbDetails: [data.meetingName],
                                      });
                                    },
                                    function (error) {
                                      $log.debug(error);
                                    }
                                  );
                              },
                              function (error) {
                                $scope.loading = false;
                                return error;
                              }
                            );
                        } else {
                          shareHolderService
                            .getSHGeneralMeetings()
                            .query()
                            .$promise.then(
                              function (data) {
                                $scope.generalMeetinglist = data;
                                if ($scope.generalMeetinglist.length === 0) {
                                  $state.go('shMyVotes', {
                                    type: 'attend',
                                    radioVal: 'confvotes',
                                    dropdownVal: 'L',
                                    selectedIndex: 1,
                                  });
                                } else if (
                                  $scope.generalMeetinglist.length > 0
                                ) {
                                  generalMeetingService
                                    .getCurrentCETTime()
                                    .query()
                                    .$promise.then(
                                      function (data) {
                                        let cetDate = $filter('filter')(
                                          data,
                                          { timezone: 'CET' },
                                          true
                                        );
                                        let gmtDate = $filter('filter')(
                                          data,
                                          { timezone: 'GMT' },
                                          true
                                        );
                                        $scope.generalMeetingSPlist = [];
                                        var curDate = '';
                                        angular.forEach(
                                          $scope.generalMeetinglist,
                                          function (item) {
                                            if (
                                              item.generalMeetingDateStr.indexOf(
                                                'CET'
                                              ) > -1
                                            ) {
                                              curDate =
                                                cetDate[0].currentMillis;
                                            } else {
                                              curDate =
                                                gmtDate[0].currentMillis;
                                            }
                                            if (
                                              item.shareholderAccessDateMiilis <
                                                curDate &&
                                              item.shareholderDeadlineDateMiilis >
                                                curDate &&
                                              item.status === 'L'
                                            ) {
                                              $scope.generalMeetingSPlist.push(
                                                item
                                              );
                                            }
                                          }
                                        );
                                        if (
                                          $scope.generalMeetingSPlist.length ===
                                          1
                                        ) {
                                          let shareholderId =
                                            $sessionStorage.groupId;
                                          let languageCode = 'EN';
                                          voteInstructionService
                                            .getShareClassList(
                                              $scope.generalMeetingSPlist[0]
                                                .generalMeetingId,
                                              shareholderId,
                                              languageCode,
                                              $sessionStorage.usertype
                                            )
                                            .query()
                                            .$promise.then(
                                              function (data) {
                                                if (data.length > 0) {
                                                  $state.go(
                                                    'votingInstructions',
                                                    {
                                                      selectedIndex: 0,
                                                      meetingId:
                                                        $scope
                                                          .generalMeetingSPlist[0]
                                                          .generalMeetingId,
                                                      issuerId:
                                                        $scope
                                                          .generalMeetingSPlist[0]
                                                          .issuerId,
                                                      issuerName:
                                                        $scope
                                                          .generalMeetingSPlist[0]
                                                          .issuerName,
                                                      shareHolderId:
                                                        $sessionStorage.groupId,
                                                      votingInstDetails: data,
                                                      status:
                                                        $scope
                                                          .generalMeetingSPlist[0]
                                                          .status,
                                                      intermediaryDateTimeStamp:
                                                        $scope
                                                          .generalMeetingSPlist[0]
                                                          .intermediaryDeadlineDate,
                                                      intermediaryDate:
                                                        $scope
                                                          .generalMeetingSPlist[0]
                                                          .intermediaryDeadlineDateStr,
                                                      breadCrumbDetails: [],
                                                    }
                                                  );
                                                } else {
                                                  $state.go('shMyVotes', {
                                                    type: 'attend',
                                                    radioVal: 'confvotes',
                                                    dropdownVal: 'L',
                                                    selectedIndex: 1,
                                                  });
                                                }
                                              },
                                              function (error) {
                                                return error;
                                              }
                                            );
                                        } else if (
                                          $scope.generalMeetingSPlist.length > 1
                                        ) {
                                          $state.go('gmList', {
                                            selectedIndex: 0,
                                          });
                                        } else {
                                          $state.go('shMyVotes', {
                                            type: 'attend',
                                            radioVal: 'confvotes',
                                            dropdownVal: 'L',
                                            selectedIndex: 1,
                                          });
                                        }
                                      },
                                      function (error) {
                                        $log.debug(error);
                                      }
                                    );
                                }
                              },
                              function (error) {
                                $scope.onLoadSpinner = false;
                                return error;
                              }
                            );
                        }
                      } else {
                        // If the authentication fails
                        $scope.loading = false;
                        $log.debug('Authentication is failed');
                        $scope.errorMessage = $filter('translate')(
                          'label_enterValidCredentials'
                        );
                      }
                    },
                    function (error) {
                      $scope.loading = false;
                      $scope.resetPasswordError =
                        error.data.errors[0].errorMessage;
                      $scope.isResetDone = false;
                      $scope.resetProgress = false;
                    }
                  );
                } else {
                  var pathNameList = $window.location.pathname.split('/');
                  pathNameList[pathNameList.length - 1] = userScreenPageLink;
                  $window.location.replace(pathNameList.join('/'));
                }
              }, 2000);
            } else {
              $scope.isResetDone = true;
              $scope.resetSuccess = false;
            }
          },
          function (error) {
            if (error.data.errors) {
              $scope.resetPasswordError = error.data.errors[0].errorMessage;
            } else {
              $scope.resetPasswordError = $filter('translate')(
                'label_login_failed'
              );
            }
            $scope.isResetDone = false;
            $scope.resetProgress = false;
          }
        );
      }
    }
  },
]);
