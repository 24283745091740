/**
 * @ngdoc controller
 * @name dwtModule.DwtEventDetailsController
 * @description This controller is used to view the details of DWT event created
 *              in create DWT event page
 * @requires ViewDwtDetailService and createEditDwtService are the service which
 *           is being used to interact with REST
 * @requires DwtProgressService is the service which is being used for schedule
 *           progress chart
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires $filter to translate messages which are declared in locale files
 */
'use strict';

angular.module('dwtModule').controller('DwtEventDetailsController', [
  '$scope',
  '$sessionStorage',
  '$log',
  '$filter',
  '$state',
  'viewDwtDetailModal',
  'ViewDwtDetailService',
  'DwtProgressService',
  '$timeout',
  'createEditDwtService',
  'userService',
  'PaginationSvc',
  'ModelWindowService',
  function (
    $scope,
    $sessionStorage,
    $log,
    $filter,
    $state,
    viewDwtDetailModal,
    ViewDwtDetailService,
    DwtProgressService,
    $timeout,
    createEditDwtService,
    userService,
    PaginationSvc,
    ModelWindowService
  ) {
    $scope.userType = $sessionStorage.usertype;
    $scope.userId = $sessionStorage.userId;
    $scope.langCode = 'EN';
    $scope.groupId = $sessionStorage.groupId;
    $scope.showModalWindow = false;
    $scope.deleteModalWindow = false;
    $scope.dwtEventFormDetailsIsComplete = false;
    if ($scope.userType === 'IN') {
      $scope.intermediaryId = $sessionStorage.groupId;
    }
    $scope.modalHeader = $filter('translate')('label_modalConfirmAction');
    $scope.modalLeftButton = $filter('translate')('label_confirm');
    $scope.modalRightButton = $filter('translate')('label_cancel');
    $scope.peshareHolders = [];

    // function call to invoke the service to fetch the view event
    // details
    $scope.getViewDetails = function () {
      if ($scope.eventId) {
        $scope.loading = true;
        ViewDwtDetailService.getDwtDetailsOverview(
          $scope.eventId,
          $scope.userType,
          $scope.langCode
        ).then(
          function (response) {
            $scope.dwtProgressData = DwtProgressService.getDWTScheduleProgressData(
              response.data.dwtScheduleVO
            );
            $scope.viewDwtDetailModal = new viewDwtDetailModal(response.data);
            $scope.type = $scope.viewDwtDetailModal.dividendDetails.type;
            $scope.addedComment = $scope.viewDwtDetailModal.eventComments;
            if ($scope.userType !== 'IN') {
              ViewDwtDetailService.getShareholderList(
                $scope.issuerId,
                'DWT',
                'EN',
                $scope.userId,
                $scope.eventId
              )
                .query()
                .$promise.then(
                  function (data) {
                    $scope.peshareHolders = data.shareHolderViewVO;
                    $scope.dummyItems = data.shareHolderViewVO;
                    $scope.pager = {};
                    $scope.setPage(1);
                  },
                  function (error) {
                    $scope.loading = false;
                    return error;
                  }
                );
            }
            if ($scope.userType === 'IN') {
              $scope.intermediaryId = $sessionStorage.groupId;
            }
            $scope.loading = false;
          },
          function (error) {
            $scope.loading = false;
            $log.debug(error);
          }
        );
      }
    };

    // function call to assign the style to progress bar
    $scope.getProgressClass = function (isPastDue, index) {
      return DwtProgressService.getProgressClass(isPastDue, index);
    };

    // function for pagination service
    $scope.setPage = function (page) {
      $scope.currentPage = page;
      $scope.pager = [];
      if (page < 1 || page > $scope.pager.totalPages) {
        return;
      }
      // get pager object from service
      $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
      $log.debug($scope.pager);
      // get current page of items
      $scope.peshareHolders = $scope.dummyItems.slice(
        $scope.pager.startIndex,
        $scope.pager.endIndex + 1
      );
      $scope.beginIndex = $scope.pager.startIndex;
      $scope.pageNo = page;
    };
    // function call to save comments
    $scope.saveComment = function (comment) {
      var eventType = 'DW_' + $scope.userType;
      var commentData = {
        eventId: $scope.eventId,
        userId: $scope.userId,
        comment: comment,
        type: eventType,
        name: $sessionStorage.userName,
        userType: $scope.userType,
        issuerName: $scope.issuerName,
        eventName: $scope.viewDwtDetailModal.issuerDetails.eventName,
      };
      $scope.loading = true;
      ViewDwtDetailService.updateEventComments(commentData, 'secureToken').then(
        function (response) {
          $scope.response = response.data.message;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
            $scope.getViewDetails();
            $scope.commentForm.$setPristine();
            $scope.showCommentBox = false;
            $scope.newComment = null;
            $scope.loading = false;
          }, 2000);
        },
        function (error) {
          $scope.loading = false;
          $scope.error = error.data.errors[0].errorMessage;
        }
      );
    };

    $scope.ValidateEventDetails = function () {
      var issEvenCurrDtflag = false;
      var peDirectDebitFlag = false;
      var qrfDirectDebitFlag = false;
      var peDateFlag = false;
      var qrfDateFlag = false;
      var restDateFlag = false;
      var securityClassFlag = false;
      var dividentFlag = false;
      // check for issuer name, event name, divident type,
      // currency,
      // rsin
      if (
        $scope.dwtObj.issuerName !== ' ' &&
        $scope.dwtObj.eventName !== ' ' &&
        $scope.dwtObj.currency !== ' ' &&
        $scope.dwtObj.dividendType !== ' ' &&
        $scope.dwtObj.rsin !== ' '
      ) {
        issEvenCurrDtflag = true;
      }
      // check for PE direct debit fields
      if ($scope.dwtObj.peReclaim) {
        peDateFlag = true;
      }
      if (!$scope.dwtObj.peReclaim) {
        peDateFlag = true;
      }

      if ($scope.dwtObj.directDebitPe && $scope.dwtObj.ibanPe !== ' ') {
        peDirectDebitFlag = true;
      } else if (!$scope.dwtObj.directDebitPe) {
        peDirectDebitFlag = true;
      }
      // check for Qrf direct debit fields
      if (!$scope.dwtObj.directDebitQrf) {
        qrfDirectDebitFlag = true;
      }
      if ($scope.dwtObj.directDebitQrf && $scope.dwtObj.ibanQrf !== ' ') {
        qrfDirectDebitFlag = true;
      }
      // check for security class
      if (
        $scope.dwtObj.isin !== ' ' &&
        $scope.dwtObj.shareclassName !== ' ' &&
        $scope.dwtObj.tdNumber !== ' '
      ) {
        securityClassFlag = true;
      }
      // check for divident
      if (
        $scope.dwtObj.dwtPercent >= 0 &&
        $scope.dwtObj.dividendGross >= 0 &&
        $scope.dwtObj.dividendNet >= 0 &&
        $scope.dwtObj.dwtPerSecurity >= 0
      ) {
        dividentFlag = true;
      }
      // check for pe dates
      if ($scope.dwtObj.peReclaim) {
        if (
          $scope.dwtObj.peStartDate !== '01 Jan 1970' &&
          $scope.dwtObj.peEndDate !== '01 Jan 1970 00:00'
        ) {
          peDateFlag = true;
        }
      }
      // check for qrf dates
      if (
        $scope.dwtObj.qrfStartDate !== '01 Jan 1970' &&
        $scope.dwtObj.qrfEndDate !== '01 Jan 1970 00:00'
      ) {
        qrfDateFlag = true;
      }
      // check for divident record and payment date, td date
      if (
        $scope.dwtObj.dividendRecordDate !== '01 Jan 1970' &&
        $scope.dwtObj.dividendPaymentDate !== '01 Jan 1970' &&
        $scope.dwtObj.taSubmissionDate !== '01 Jan 1970'
      ) {
        restDateFlag = true;
      }

      if (
        issEvenCurrDtflag &&
        peDirectDebitFlag &&
        qrfDirectDebitFlag &&
        securityClassFlag &&
        dividentFlag &&
        peDateFlag &&
        qrfDateFlag &&
        restDateFlag
      ) {
        $scope.dwtEventFormDetailsIsComplete = true;
      } else {
        $scope.dwtEventFormDetailsIsComplete = false;
        $scope.errorMessage =
          'Mandatory fields are not filled. Please click on Edit Dwt Event button to fill the details';
      }
    };

    // function call to update confirmation modal document
    $scope.updateModal = function (status) {
      $scope.actionStatus = status;
      if (
        $scope.viewDwtDetailModal.issuerDetails.eventStatus === 'Draft' ||
        $scope.viewDwtDetailModal.issuerDetails.eventStatus === 'Rejected'
      ) {
        $scope.loading = true;
        createEditDwtService.getDwtEventDetails($scope.eventId).then(
          function (resp) {
            $scope.dwtObj = resp.data.dwtEvent;
            if ($scope.dwtObj) {
              $scope.ValidateEventDetails();
              if (
                status === 'Pending_For_Approval' &&
                $scope.dwtEventFormDetailsIsComplete
              ) {
                if (!$scope.viewDwtDetailModal.issuerDetails.activeMembers) {
                  $scope.modalHeader = $filter('translate')(
                    'label_createReqModalSendToIssuer'
                  );
                  $scope.modalBody = $filter('translate')(
                    'label_no_active_user_for_issuer'
                  );
                  $scope.modalFooter = '';
                  $scope.modalLeftButton = $filter('translate')('label_no');
                  $scope.modalRightButton = $filter('translate')('label_yes');
                } else {
                  $('#sendDraft').trigger('click');
                  $scope.modalBody = $filter('translate')(
                    'label_dwtModalConfirmCreateTemplate'
                  );
                  $scope.modalLeftButton = $filter('translate')('label_cancel');
                  $scope.modalRightButton = $filter('translate')(
                    'label_confirm'
                  );
                }
                ModelWindowService.showModelWindow('alertBox');
              }
            }
            $scope.loading = false;
          },
          function (error) {
            $scope.loading = false;
            return error;
          }
        );
      } else if (status === 'Approved') {
        $scope.showModalWindow = true;
        $scope.modalBody = $filter('translate')('label_dwtModalApproveEvent');
        $scope.modalLeftButton = $filter('translate')('label_cancel');
        $scope.modalRightButton = $filter('translate')('label_confirm');
        ModelWindowService.showModelWindow('alertBox');
      } else if (status === 'Rejected') {
        $scope.showModalWindow = true;
        $scope.modalBody = $filter('translate')('label_dwtModalRejectEvent');
        ModelWindowService.showModelWindow('rejectAlertBox');
      }
    };

    // function call to send to issuer
    $scope.eventAction = function (actionStatus) {
      if (actionStatus === 'Rejected') {
        $scope.actionData = {
          loggedInUserId: $scope.userId,
          eventId: $scope.eventId,
          status: actionStatus,
          workstation: $scope.userType,
          comment: $sessionStorage.rejectReason,
        };
      } else {
        $scope.actionData = {
          loggedInUserId: $scope.userId,
          eventId: $scope.eventId,
          status: actionStatus,
          workstation: $scope.userType,
        };
      }
      $scope.loading = true;
      createEditDwtService
        .dwtEventOperations($scope.eventId, $scope.actionData, 'secureToken')
        .then(
          function (response) {
            $scope.loading = false;
            $scope.response = response.data.message;
            $('#successMsg').modal('show');
            $timeout(function () {
              $('#successMsg').modal('hide');
              $('.modal-backdrop').remove();
              $state.go('taxReclaimHome', {
                selectedIndex: $scope.taxReclaimIndexId,
              });
            }, 2000);
          },
          function (error) {
            $log.debug(error);
            $scope.loading = false;
            $scope.errorMessage = error.data.errorMessage;
          }
        );
    };

    // function to update modal delete PE shareholders
    $scope.removePEShareholder = function (shareholderId, shareClassId) {
      $scope.deleteModalWindow = true;
      $scope.peShareholderId = shareholderId;
      $scope.peShareclassId = shareClassId;
      $scope.modalBody = $filter('translate')('label_delete_confirmation');
    };
    // function call to Delete PE Shareholders
    $scope.deleteShareholder = function (shareholderId, shareClassId) {
      $scope.loading = true;
      userService
        .deleteEventShareholder(
          $scope.eventId,
          shareholderId,
          shareClassId
        )
        .then(
          function (response) {
            $scope.loading = false;
            $scope.deleteModalWindow = false;
            $scope.response = response.data.message;
            $('#successMsg').modal('show');
            $timeout(function () {
              $('#successMsg').modal('hide');
              $('.modal-backdrop').remove();
              $state.reload();
            }, 2000);
          },
          function (error) {
            $scope.loading = false;
            $scope.errorMessage = error.data.errors[0].errorMessage;
          }
        );
    };
    // Below is the method to hide or show accordian
    $scope.showHideDetails = function (peDetails) {
      peDetails.showDetails = !peDetails.showDetails;
    };
    // function call to invoke service on load for the first time
    $scope.getViewDetails();
  },
]);
