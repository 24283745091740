/**
 * @ngdoc controller
 * @name userMaintenanceModule.IssuerUserListController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the list of users belonging to particular issuer group
 * @requires userService is the service which is being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $filter to filter the firstName and to translate messages from locale file
 * @requires PaginationSvc for pagination
 */

angular.module('userMaintenanceDwtModule').controller(
  'ShareclassListDwtController',
  [
    '$scope',
    '$log',
    '$stateParams',
    '$filter',
    'userService',
    'PaginationSvc',
    '$sessionStorage',
    function($scope, $log, $stateParams, $filter, userService,
      PaginationSvc, $sessionStorage) {

      $scope.groupId = $stateParams.groupId;
      $scope.groupStatus = $stateParams.status;
      $scope.userId = $sessionStorage.userId;
      $scope.user = 'Shareclass';
      //$scope.loading is used for spinner
      $scope.loading = false;
      //call getUserList from userService to fetch user list belonging to the particular issuer group
      $scope.getShareClassList = function(message) {
        userService.getShareclassList($scope.groupId, 'DWT', 'EN',
          $scope.userId).query().$promise.then(function(data) {
          $log.debug('success user view');
          $scope.userData = data;
          $scope.dummyItems = data;
          $scope.pager = {};
          $scope.setPage(1);
          $scope.loading = false;
          if (undefined !== message) {
            $scope.success = true;
            $scope.failure = false;
            $scope.message = message;
          }
        }, function(error) {
          $scope.loading = false;
          return error;
        });
      };
      $scope.removeShareclass = function(shareclassId) {
        userService.deleteShareclass(shareclassId, 'DWT', 'EN',
          $scope.userId, 'secureToken').then(function(response) {
          $scope.getShareClassList(response.data.message);
        }, function(error) {
          $scope.success = false;
          $scope.failure = true;
          $scope.errorMessage = error.data.errors[0].errorMessage;
        });
      };

      //Below function is to search the users related to entry in that search box
      $scope.onFirstNameChange = function(val) {
        $scope.dummyItems = $filter('filter')($scope.userData, {
          shareclassName: val
        });
        $scope.pager = {};
        $scope.setPage(1);
      };
      //Below function is for pagination, this calls from HTML on clicking of each page
      $scope.setPage = function(page) {
        $scope.pager = [];
        if (page < 1 || page > $scope.pager.totalPages) { return; }

        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length,
          page);
        $log.debug($scope.pager);

        // get current page of items
        $scope.viewUserListDwt = $scope.dummyItems.slice(
          $scope.pager.startIndex, $scope.pager.endIndex + 1);
      };
      // call the function for first time
      $scope.getShareClassList();
    }]);
