/**
 * @ngdoc controller
 * @name intermediaryModule.CreateExtensionRequestController
 * @description This controller is used for Creating extension request.
 * @requires ExtensionRequestService and MyAccountsService are the services which are being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for fetching the logged in user details from session storage
 * @requires $filter to translate messages which are declared in locale files
 */
angular
  .module('intermediaryModule')
  .controller('CreateExtensionRequestController', [
    '$scope',
    '$sessionStorage',
    '$stateParams',
    '$log',
    'ExtensionRequestService',
    'MyAccountsService',
    '$filter',
    '$timeout',
    function (
      $scope,
      $sessionStorage,
      $stateParams,
      $log,
      ExtensionRequestService,
      MyAccountsService,
      $filter,
      $timeout
    ) {
      $scope.createExtnSuccess = null;
      $scope.createExtnErrRes = null;
      $scope.createExtnRequest = false;
      $scope.extReqRejected = $stateParams.extReqRejected;
      $scope.meetingName = $stateParams.gmName;
      $scope.gmDateTime = $stateParams.gmDateTime;
      $scope.issuerId = $stateParams.issuerId;
      $scope.shareholdeName = $stateParams.shareholderName;
      if ($stateParams.position == null) {
        $scope.shareholderPosition = null;
      } else {
        $scope.shareholderPosition = Number($stateParams.position);
      }
      $scope.reasonForDelay = $stateParams.reason;
      $scope.languageCode = $sessionStorage.workstationData.selectedLang;
      $scope.options = { hstep: [1, 2, 3], mstep: [1, 5, 10, 15, 25, 30] };
      $scope.ismeridian = false;
      $scope.hstep = 1;
      $scope.mstep = 1;
      //Below is the service call for first time to load issuer logo
      MyAccountsService.getIssuerGroupDetails(
        $scope.issuerId,
        $scope.languageCode
      ).then(
        function (response) {
          $scope.issuerGroupDetails = response.data;
          if (
            $scope.issuerGroupDetails.logoBaseStr === undefined ||
            $scope.issuerGroupDetails.logoBaseStr === null
          ) {
            $scope.issuerLogoNotAvailable = true;
          }
        },
        function (error) {
          $log.debug(error);
          $scope.issuerLogoNotAvailable = true;
        }
      );
      //Below is the function call from HTML to send the extension request
      $scope.sendExtensionRequest = function () {
        var timeData = new Date($scope.example.value);
        var dateTime = new Date($scope.endDateModified);
        dateTime.setMinutes(timeData.getMinutes());
        dateTime.setHours(timeData.getHours());
        $scope.endDate = dateTime.getTime();
        $scope.intermediaryDeadlineDate = new Date(
          $stateParams.intermediaryDeadlineDateStr.substring(0, 17)
        );
        $scope.intermediaryDeadlineDate = $scope.intermediaryDeadlineDate.getTime();

        $scope.endDateString = $filter('date')(
          $scope.endDate,
          'yyyy-MM-dd HH:mm:ss'
        );
        $scope.getGmDateTime = new Date($scope.gmDateTime.substring(0, 17)); // TODO this is wrong! this will take the browser as the timezone
        $scope.getGmDateTime = $scope.getGmDateTime.getTime();
        if (
          $scope.endDate < $scope.intermediaryDeadlineDate ||
          $scope.endDate >= $scope.getGmDateTime
        ) {
          $scope.createExtnErrRes = $filter('translate')(
            'label_create_extn_error'
          );
        } else {
          $scope.intermediaryDeadlineDate = $filter('date')(
            $scope.intermediaryDeadlineDate,
            'yyyy-MM-dd HH:mm:ss'
          );
          $scope.createExtensionRequest = {
            gmId: $stateParams.generalMeetingId,
            userId: $sessionStorage.userId,
            reason: $scope.reasonForDelay,
            intermediaryDeadlineDate: $scope.intermediaryDeadlineDate,
            endDate: $scope.endDate,
            endDateString: $scope.endDateString,
            shareholderPosition: $scope.shareholderPosition,
            shareholderName: $scope.shareholdeName,
            gmDate: $scope.gmDateTime,
            issuerId: $scope.issuerId,
            extReqId: $stateParams.generalMeetingId,
            extensionRequestId: $stateParams.extensionRequestId,
            issuerName: $stateParams.issuerName,
          };
          if ($scope.createExtensionRequest.extensionRequestId == null) {
            //create extension request
            ExtensionRequestService.createExtensionRequest(
              $scope.createExtensionRequest,
              'secureToken'
            ).then(
              function (response) {
                $log.debug(response);
                $scope.createExtnSuccess = response.data.message;
                $scope.createExtnErrRes = null;
                $scope.createExtnRequest = true;
                $('#successMsg').modal('show');
                $timeout(function () {
                  $('#successMsg').modal('hide');
                  $('.modal-backdrop').remove();
                  document.getElementById('goback').click();
                }, 6000);
              },
              function (error) {
                $log.debug(error);
                $scope.createExtnErrRes = error.data.errors[0].errorMessage;
                $scope.createExtnSuccess = null;
              }
            );
          } else {
            //update extension request
            ExtensionRequestService.updateExtensionRequest(
              $scope.createExtensionRequest.extensionRequestId,
              $scope.createExtensionRequest,
              'secureToken'
            ).then(
              function (response) {
                $log.debug(response);
                $scope.createExtnSuccess = response.data.message;
                $scope.createExtnErrRes = null;
                $scope.createExtnRequest = true;
                $('#successMsg').modal('show');
                $timeout(function () {
                  $('#successMsg').modal('hide');
                  $('.modal-backdrop').remove();
                  document.getElementById('goback').click();
                }, 6000);
              },
              function (error) {
                $log.debug(error);
                $scope.createExtnErrRes = error.data.errors[0].errorMessage;
                $scope.createExtnSuccess = null;
              }
            );
          }
        }
      };
      /* date picker */

      $scope.inlineOptions = {
        customClass: getDayClass,
      };
      $scope.dateOptions = {
        minDate: new Date(),
      };
      $scope.open1 = function () {
        $scope.popup1.opened = true;
      };
      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };
      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.popup1 = {
        opened: false,
      };
      function getDayClass(data) {
        var date = data.date,
          mode = data.mode;
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(
              0,
              0,
              0,
              0
            );
            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }
        return '';
      }
      $scope.dateEnter = function (date) {
        var currentdate = new Date().setHours(0, 0, 0, 0);
        if (date < currentdate) {
          $scope.dobError = true;
        } else {
          $scope.dobError = false;
        }
      };
      /* date picker */

      /* time picker */
      $scope.example = {
        value: new Date(1970, 0, 1, 0, 0, 0),
      };
      /* time picker */

      $scope.onCancel = function (event) {
        event.preventDefault();
        document.getElementById('goback').click();
      };
    },
  ]);
