/**
 * @ngdoc controller
 * @name dwtModule.DwtSendEmailController
 * @description This controller is used to view the details of DWT event created
 *              in create DWT event page
 * @requires ViewDwtDetailService is the service which is being used to interact
 *           with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 */
'use strict';

angular.module('dwtModule').controller('DwtSendEmailController', [
  '$scope',
  '$stateParams',
  'ViewDwtDetailService',
  '$log',
  '$filter',
  '$sessionStorage',
  'dwtFileDownloadSvc',
  function (
    $scope,
    $stateParams,
    ViewDwtDetailService,
    $log,
    $filter,
    $sessionStorage,
    dwtFileDownloadSvc
  ) {
    $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;
    $scope.eventId = $stateParams.eventId;
    $scope.userType = $sessionStorage.usertype;
    $scope.userId = $sessionStorage.userId;
    $scope.showSendTo = false;
    $scope.qrfEndDate = $stateParams.qrfEndDate;
    $scope.qrfEndDateNew = new Date($stateParams.qrfEndDate);
    $scope.dateToday = new Date();
    if ($scope.dateToday > $scope.qrfEndDateNew) {
      $scope.showSendTo = true;
    }
    $scope.getEmailData = function () {
      // Below is the function call to service to interact with
      // service and get email data like body,subject and ToUser.
      ViewDwtDetailService.getDwtGetEmailData($scope.eventId).then(
        function (response) {
          $scope.emailBody = response.data.taxAuthorityEmail.template.emailBody;
          $scope.subject =
            response.data.taxAuthorityEmail.template.emailSubject;
          $scope.emailToUsersArr = response.data.taxAuthorityEmail.emailToUsers;
          $scope.downloadExcelFlag =
            response.data.taxAuthorityEmail.downloadExcelFlag;
          $scope.emailCcArr = response.data.taxAuthorityEmail.emailCcUsers;
          $scope.taxAuthorityEmailSentDate =
            response.data.taxAuthorityEmail.dwtEvent.taxAuthorityEmailSentDate;
          $scope.emailArr = [];
          $scope.emailCcUser = [];
          for (var i = 0; i < $scope.emailToUsersArr.length; i++) {
            $scope.emailArr.push($scope.emailToUsersArr[i].emailAddress);
          }
          $scope.emailAddress = $scope.emailArr.join(';');
          for (var k = 0; k < $scope.emailCcArr.length; k++) {
            $scope.emailCcUser.push($scope.emailCcArr[k].emailAddress);
          }
          $scope.emailAddressCc = $scope.emailCcUser.join(';');
        },
        function (error) {
          $scope.loading = false;
          $log.debug(error);
        }
      );
    };
    // Below is function call to download claim overview document
    $scope.downloadClaimOverview = function () {
      var downloadUrl =
        'v1/dwtevents/' + $scope.eventId + '/sendtotaxauthority/download';
      var a = document.createElement('a');
      document.body.appendChild(a);
      dwtFileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            var fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          downloadUrl = '';
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    // Below is function call to open default mailbox of the
    // user when he clicks on submit with the email data from
    // HTML.
    $scope.submitEmail = function () {
      ViewDwtDetailService.sendDWTEmail(
        $scope.eventId,
        $sessionStorage.userId
      ).then(
        function (response) {
          $scope.response = response.data.message;
          $('#successMsg').modal('show');
        },
        function (error) {
          $scope.loading = false;
          $scope.error = error.data.errors[0].errorMessage;
        }
      );
    };
    $scope.getEmailData();
  },
]);
