/**
 * @ngDoc controller
 * @name shareHolderModule.VaRequestStep1Controller
 * @description This controller is used to register step1 details for Virtual Attendance Request
 * @requires
 */
angular
  .module('shareHolderModule')
  .controller(
    'VaRequestConfirmationController',
    [
      '$scope',
      '$stateParams',
      '$state',
      'VaRequestStateService',
      function ($scope, $stateParams, $state,
        VaRequestStateService) {
        init();

        $scope.gotoMyEvents = function() {
          $state.go('shMyVirtualParticipation',{
            selectedIndex: 0, //selectedIndex - For highlighting selected tab in header
            selectedRequestStatus: 'P'
          });
        };


        function init() {
          $scope.intermediaryEmail= $stateParams.intermediaryEmail;
          $scope.intermediaryName= $stateParams.intermediaryName;
          $scope.intermediaryPhone= $stateParams.intermediaryPhone;
        }
      }]);
