/**
 * @ngdoc controller
 * @name userMaintenanceModule.EditIssuerGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with editing details of particular issuer group
 * @requires userGroupService and fileUploadSvc are the service which are being
 *           used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter country and translate message from locale file
 * @requires $sessionStorage to get logged in userId from session storage
 * @requires $timeout
 * @requires $uibModal
 */

angular
  .module('userMaintenanceModule')
  .controller('EditIssuerGroupController', [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$sessionStorage',
    'userGroupService',
    '$filter',
    'fileUploadSvc',
    '$timeout',
    '$uibModal',
    function (
      $scope,
      $log,
      $state,
      $stateParams,
      $sessionStorage,
      userGroupService,
      $filter,
      fileUploadSvc,
      $timeout,
      $uibModal
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.issuerId = $stateParams.groupId;
      $scope.userGroup = 'issuer';
      $log.debug('issuerId', $scope.issuerId);
      $scope.modalBody = $filter('translate')('confirmGroup_editDetails');
      $scope.modalLeftButton = $filter('translate')('label_confirm');
      $scope.modalRightButton = $filter('translate')('label_cancel');
      // Below is the service call to get list of countries
      userGroupService
        .getCountryList('EN')
        .query()
        .$promise.then(
          function (countries) {
            $log.debug('success user view');
            $scope.countries = countries.countries;
            userGroupService
              .getViewIssuerGroup($scope.issuerId, 'GM', 'EN')
              .query()
              .$promise.then(
                function (data) {
                  $scope.userGroupDetails = data;
                  $scope.selectedCountry = $filter('filter')(
                    $scope.countries,
                    {
                      countryName: $scope.userGroupDetails.country,
                    },
                    true
                  );
                },
                function (error) {
                  $log.debug(error);
                }
              );
          },
          function (error) {
            $log.debug(error);
          }
        );

      // The below function call is to store the data from UI
      // and pass it to REST call for updating user
      $scope.updateUserGroup = function () {
        if ($scope.logoFile === null || $scope.logoFile === undefined) {
          var documentFormData = new FormData();
          documentFormData.append(
            'groupName',
            $scope.userGroupDetails.groupName
          );
          documentFormData.append('userId', $sessionStorage.userId);
          documentFormData.append('groupId', $scope.issuerId);
          documentFormData.append(
            'groupEmail',
            $scope.userGroupDetails.groupEmail
          );
          documentFormData.append('city', $scope.userGroupDetails.city);
          documentFormData.append(
            'country',
            $scope.selectedCountry[0].isoCountryCode
          );
          documentFormData.append('status', $scope.userGroupDetails.status);
          documentFormData.append('productCode', 'GM');
          var file = $scope.logoFile;
          var uploadUrl = 'v1/issuers/' + $scope.issuerId;
          /*
           * Call the upload directive to upload file and
           * FormData
           */
          fileUploadSvc.uploadFileToUrl(file, uploadUrl, documentFormData).then(
            function (data) {
              if (
                data.data.httpStatusCode !== 200 &&
                data.data.errors.length > 0
              ) {
                $scope.error = true;
                $scope.errorMsg = data.data.errors[0].errorMessage;
              } else {
                $scope.success = true;
                $scope.timedout = $uibModal.open({
                  templateUrl: 'success-dialog.html',
                });
                $timeout(function () {
                  $scope.timedout.close();
                  $scope.timedout = null;
                  $state.go('userMaintenance.issuerGroup', {
                    pageNo: $stateParams.pageNo,
                  });
                }, 2000);
              }
            },
            function (error) {
              $log.debug(error);
              $scope.error = true;
              $scope.errorMsg = error.data.errors[0].errorMessage;
            }
          );
        } else {
          if ('image' !== $scope.logoFile.type.substring(0, 5)) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('label_errorImgFile');
          } else if ($scope.logoFile.size > 256000) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('label_errorFileSize');
          } else {
            var documentFormDataSec = new FormData();
            documentFormDataSec.append(
              'groupName',
              $scope.userGroupDetails.groupName
            );
            documentFormDataSec.append('userId', $sessionStorage.userId);
            documentFormDataSec.append('groupId', $scope.issuerId);
            documentFormDataSec.append(
              'groupEmail',
              $scope.userGroupDetails.groupEmail
            );
            documentFormDataSec.append('city', $scope.userGroupDetails.city);
            documentFormDataSec.append(
              'country',
              $scope.selectedCountry[0].isoCountryCode
            );
            documentFormDataSec.append(
              'status',
              $scope.userGroupDetails.status
            );
            documentFormDataSec.append('productCode', 'GM');
            documentFormDataSec.append('logo', $scope.logoFile);
            $scope.error = false;
            var file1 = $scope.logoFile;
            var uploadUrl1 = 'v1/issuers/' + $scope.issuerId;
            /*
             * Call the upload directive to upload file and
             * FormData
             */
            fileUploadSvc
              .uploadFileToUrl(file1, uploadUrl1, documentFormDataSec)
              .then(
                function (data) {
                  if (
                    data.data.httpStatusCode !== 200 &&
                    data.data.errors.length > 0
                  ) {
                    $scope.error = true;
                    $scope.errorMsg = data.data.errors[0].errorMessage;
                  } else {
                    $scope.success = true;
                    $scope.timedout = $uibModal.open({
                      templateUrl: 'success-dialog.html',
                    });
                    $timeout(function () {
                      $scope.timedout.close();
                      $scope.timedout = null;
                      $state.go('userMaintenance.issuerGroup', {
                        pageNo: $stateParams.pageNo,
                      });
                    }, 2000);
                  }
                },
                function (error) {
                  $log.debug(error);
                  $scope.error = true;
                  if (error.data) {
                    $scope.errorMsg = error.data.errors[0].errorMessage;
                  } else {
                    $scope.errorMsg = error.errors[0].errorMessage;
                  }
                }
              );
          }
        }
      };
    },
  ]);
