/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.ExtensionRequestController
 * @description This controller is used for extension request functionality.
 * @requires AgentService is the service which is being used to interact with REST
 * @requires $scope
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 * @requires PaginationSvc for pagination
 */
'use strict';

angular
  .module('generalMeetingHomeModule')
  .controller('ExtensionRequestController', [
    '$scope',
    '$sessionStorage',
    'AgentService',
    '$filter',
    '$log',
    '$state',
    'PaginationSvc',
    'ModelWindowService',
    '$timeout',
    function (
      $scope,
      $sessionStorage,
      AgentService,
      $filter,
      $log,
      $state,
      PaginationSvc,
      ModelWindowService,
      $timeout
    ) {
      $scope.extensionRequests = '';
      $scope.request = '';
      var count = null;
      var pageNo;
      //for status buttons
      if ($sessionStorage.selectedLang === 'nl') {
        $scope.statusButtons = ['nl_Pending', 'nl_Accepted', 'nl_Rejected'];
      } else {
        $scope.statusButtons = ['Pending', 'Accepted', 'Rejected'];
      }

      $scope.extnStatusButton = ['P', 'A', 'R'];
      $scope.newEndDateList = {};
      $scope.getExtensionRequests = function () {
        //to fetch the extension request list
        pageNo = 1;
        $scope.loading = true;
        AgentService.getExtensionRequests(pageNo)
          .query()
          .$promise.then(
            function (data) {
              $scope.extensionRequestsList = data;
              $scope.extensionRequests = $filter('filter')(
                $scope.extensionRequestsList,
                {
                  status: $scope.extnStatusButton[0],
                }
              );
              $scope.extReqCount_0 = $filter('filter')(
                $scope.extensionRequestsList,
                {
                  status: $scope.extnStatusButton[0],
                }
              ).length;
              $scope.extReqCount_1 = $filter('filter')(
                $scope.extensionRequestsList,
                {
                  status: $scope.extnStatusButton[1],
                }
              ).length;
              $scope.extReqCount_2 = $filter('filter')(
                $scope.extensionRequestsList,
                {
                  status: $scope.extnStatusButton[2],
                }
              ).length;
              $scope.selectedButtonIndex = 0;
              if (data.length > 0) {
                count = data[0].count;
                count = count - 500;
              } else {
                count = 0;
              }
              $scope.dummyItems = $scope.extensionRequests;
              $scope.pager = {};
              $scope.setPage(1);
              $log.debug('success');
              $scope.loading = false;
            },
            function (error) {
              $log.debug(error);
              $scope.loading = false;
            }
          );
      };

      $scope.setPage = function (page) {
        $scope.pager = [];
        if ($scope.selectedButtonIndex === 0) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          if ($scope.extReqCount_0 / (page * 10) < 1 && count >= 1) {
            pageNo = pageNo + 1;
            AgentService.getExtensionRequests(pageNo)
              .query()
              .$promise.then(
                function (data) {
                  $scope.extensionRequestsList = $scope.extensionRequestsList.concat(
                    data
                  );
                  $scope.extensionRequests = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[0],
                    }
                  );
                  $scope.extReqCount_0 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[0],
                    }
                  ).length;
                  $scope.extReqCount_1 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[1],
                    }
                  ).length;
                  $scope.extReqCount_2 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[2],
                    }
                  ).length;
                  count = count - 500;
                  $scope.dummyItems = $scope.extensionRequests;
                  // get pager object from service
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );
                  $log.debug($scope.pager);

                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                },
                function (error) {
                  $log.debug(error);
                }
              );
          }
        } else if ($scope.selectedButtonIndex === 1) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          if ($scope.extReqCount_1 / (page * 10) < 1 && count >= 1) {
            pageNo = pageNo + 1;
            AgentService.getExtensionRequests(pageNo)
              .query()
              .$promise.then(
                function (data) {
                  $scope.extensionRequestsList = $scope.extensionRequestsList.concat(
                    data
                  );
                  $scope.extensionRequests = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[1],
                    }
                  );
                  $scope.extReqCount_0 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[0],
                    }
                  ).length;
                  $scope.extReqCount_1 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[1],
                    }
                  ).length;
                  $scope.extReqCount_2 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[2],
                    }
                  ).length;
                  count = count - 500;
                  $scope.dummyItems = $scope.extensionRequests;
                  // get pager object from service
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );
                  $log.debug($scope.pager);

                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                },
                function (error) {
                  $log.debug(error);
                }
              );
          }
        } else if ($scope.selectedButtonIndex === 2) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          if ($scope.extReqCount_2 / (page * 10) < 1 && count >= 1) {
            pageNo = pageNo + 1;
            AgentService.getExtensionRequests(pageNo)
              .query()
              .$promise.then(
                function (data) {
                  $scope.extensionRequestsList = $scope.extensionRequestsList.concat(
                    data
                  );
                  $scope.extensionRequests = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[2],
                    }
                  );
                  $scope.extReqCount_0 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[0],
                    }
                  ).length;
                  $scope.extReqCount_1 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[1],
                    }
                  ).length;
                  $scope.extReqCount_2 = $filter('filter')(
                    $scope.extensionRequestsList,
                    {
                      status: $scope.extnStatusButton[2],
                    }
                  ).length;
                  count = count - 500;
                  $scope.dummyItems = $scope.extensionRequests;
                  // get pager object from service
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );
                  $log.debug($scope.pager);

                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                },
                function (error) {
                  $log.debug(error);
                }
              );
          }
        }
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }

        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
        $log.debug($scope.pager);

        // get current page of items
        $scope.items = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
      };
      //on click of button, display the corresponding list based on status
      $scope.buttonClicked = function ($index) {
        $scope.selectedButtonIndex = $index;
        $scope.extensionRequests = $filter('filter')(
          $scope.extensionRequestsList,
          {
            status: $scope.extnStatusButton[$index],
          }
        );
        $scope.dummyItems = $scope.extensionRequests;
        $scope.pager = {};
        $scope.setPage(1);
      };
      // Below is the method to hide or show accordian
      $scope.showHideDetails = function (extnReq) {
        extnReq.showDetails = !extnReq.showDetails;
      };

      //accept or reject extension request modal window
      $scope.approveReject = function (
        action,
        index,
        extReqId,
        status,
        newEndDate,
        intermediaryDeadlineDate,
        gmDate
      ) {
        $scope.action = action;
        $scope.extReqId = extReqId;
        $scope.status = status;
        $scope.intermediaryDeadlineDate = intermediaryDeadlineDate;
        $scope.gmDate = gmDate;
        $scope.newEndDateList[index] =
          newEndDate == null ? new Date() : new Date(newEndDate);
        $scope.newEndDate = $scope.newEndDateList[index];

        if ($scope.action === 'Accept') {
          $scope.modalHeader = $filter('translate')('label_extReq');
          if (status === 'P') {
            $scope.modalBody = $filter('translate')(
              'label_acceptExtnReqConfirm'
            );
          } else {
            $scope.modalBody = $filter('translate')('label_acceptExtnReq');
          }
          $scope.modalFooter = '';
          $scope.modalLeftButton = $filter('translate')('label_accept');
          $scope.modalRightButton = $filter('translate')('label_cancel');
          $scope.modalNewEndDate = $filter('translate')('label_new_end_date');
          $scope.modalValidationErrorMsg = $filter('translate')(
            'label_create_extn_error'
          );
        } else if ($scope.action === 'Reject') {
          $scope.modalHeader = $filter('translate')('label_extReq');
          if (status === 'P') {
            $scope.modalBody = $filter('translate')(
              'label_rejectExtnReqConfirm'
            );
          } else {
            $scope.modalBody = $filter('translate')('label_rejectExtnReq');
          }
          $scope.modalFooter = '';
          $scope.modalLeftButton = $filter('translate')('label_reject');
          $scope.modalLeftButton2 = $filter('translate')('label_rejectAll');
          $scope.modalRightButton = $filter('translate')('label_cancel');
          ModelWindowService.showModelWindow('rejectAlertBoxWithDoubleButton');
        }
      };
      $scope.dateOptions = {
        formatYear: 'yy',
        showWeeks: false,
      };
      // Initializing format array
      $scope.formats = [
        'dd-MM-yyyy',
        'yyyy/MM/dd HH:mm',
        'dd.MM.yyyy HH:mm',
        'shortDate',
      ];
      // Setting format
      $scope.format = $scope.formats[0];

      $scope.open1 = function (index) {
        $scope.popup1List[index].opened = true;
      };

      $scope.popup1List = [];
      $scope.popup1 = {
        opened: false,
      };
      $scope.open1Modal = function () {
        $scope.popup1.opened = true;
      };
      //Method to populate NewEndDate
      $scope.populateNewEndDate = function (newEndDate, index) {
        $scope.popup1List[index] = {
          opened: false,
        };
        $scope.newEndDateList[index] = new Date(newEndDate);
        if (newEndDate == null) {
          $scope.newEndDateList[index] = null;
        }
      };

      $scope.validateDate = function (
        newEndDate,
        intermediaryDeadlineDate,
        gmDate
      ) {
        $scope.validationErrorFlag = false;
        if ($scope.intermediaryDeadlineDate === undefined) {
          $scope.intermediaryDeadlineDate = intermediaryDeadlineDate;
        }
        if ($scope.gmDate === undefined) {
          $scope.gmDate = gmDate;
        }
        if (
          newEndDate.getTime() > new Date($scope.gmDate).getTime() ||
          new Date($scope.intermediaryDeadlineDate).getTime() >
            newEndDate.getTime()
        ) {
          $scope.validationErrorFlag = true;
        }
      };

      //reject single extension request
      $scope.approveRejectExtn = function (action, extReqId) {
        $scope.request = $filter('filter')($scope.extensionRequestsList, {
          extReqId: extReqId,
        });
        if ($scope.request[0].timeZone == 'GMT') {
          $scope.newEndDate = $scope.newEndDate.getTime() + 3600000;
        } else {
          $scope.newEndDate = $scope.newEndDate.getTime();
        }
        if (action === 'Accept') {
          $scope.rejectExtensionData = {
            gmId: $scope.request[0].gmId,
            name: $scope.request[0].meetingName,
            issuerName: $scope.request[0].issuerName,
            date: $scope.request[0].gmDate,
            shareholderName: $scope.request[0].shareholderName,
            intermediaryId: $scope.request[0].intermediaryId,
            shareholderPosition: $scope.request[0].shareholderPosition,
            rejectedBy: $sessionStorage.userId,
            userType: $sessionStorage.usertype,
            status: action,
            flag: 'ONE',
            extReqId: extReqId,
            rejectReason: $sessionStorage.extenRejectReason,
            newEndDate: $scope.newEndDate.toString(),
          };
        } else {
          $scope.rejectExtensionData = {
            gmId: $scope.request[0].gmId,
            name: $scope.request[0].meetingName,
            issuerName: $scope.request[0].issuerName,
            date: $scope.request[0].gmDate,
            shareholderName: $scope.request[0].shareholderName,
            intermediaryId: $scope.request[0].intermediaryId,
            shareholderPosition: $scope.request[0].shareholderPosition,
            rejectedBy: $sessionStorage.userId,
            userType: $sessionStorage.usertype,
            status: action,
            flag: 'ONE',
            extReqId: extReqId,
            rejectReason: $sessionStorage.extenRejectReason,
            newEndDate: null,
          };
        }
        AgentService.approveRejectForGM(
          $scope.rejectExtensionData,
          'secureToken',
          function (response) {
            if (action === 'Accept') {
              $scope.extnReqSuccess = $filter('translate')(
                'label_approveExtnReq'
              );
            } else {
              $scope.extnReqSuccess = $filter('translate')(
                'label_rejectExtensionReq'
              );
            }
            $('#successMsg').modal('show');
            $log.debug(response);
            $timeout(function () {
              $('#successMsg').modal('hide');
              $('.modal-backdrop').remove();
              $scope.getExtensionRequests();
            }, 2000);
          },
          function (error) {
            $log.debug(error);
          }
        );
      };

      //reject all extension requests belonging to an issuer.
      $scope.approveRejectAllExtn = function (action, extReqId) {
        $scope.request = $filter('filter')($scope.extensionRequestsList, {
          extReqId: extReqId,
        });
        $scope.rejectExtensionData = {
          gmId: $scope.request[0].gmId,
          name: $scope.request[0].meetingName,
          issuerName: $scope.request[0].issuerName,
          date: $scope.request[0].gmDate,
          shareholderName: $scope.request[0].shareholderName,
          intermediaryId: $scope.request[0].intermediaryId,
          shareholderPosition: $scope.request[0].shareholderPosition,
          rejectedBy: $sessionStorage.userId,
          userType: $sessionStorage.usertype,
          status: action,
          flag: 'ALL',
          extReqId: extReqId,
          rejectReason: $sessionStorage.extenRejectReason,
          newEndDateTime: null,
          currentStatus: $scope.status,
        };
        AgentService.approveRejectForGM(
          $scope.rejectExtensionData,
          'secureToken',
          function (response) {
            $scope.extnReqSuccess = $filter('translate')(
              'label_rejectAllExtnRequests'
            );
            $('#successMsg').modal('show');
            $log.debug(response);
            $timeout(function () {
              $('#successMsg').modal('hide');
              $('.modal-backdrop').remove();
              $scope.getExtensionRequests();
            }, 2000);
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      //fetch the list for the first time
      $scope.getExtensionRequests();
    },
  ]);
