/**
 * @ngDoc controller
 * @name shareHolderModule.editVaRequestStep1Controller
 * @description This controller is used to edit step1 details for Virtual Attendance Request
 * @requires
 */
angular.module('shareHolderModule').controller('editVaRequestStep1Controller', [
  '$scope',
  '$stateParams',
  '$log',
  '$state',
  '$filter',
  'shareHolderService',
  'VaRequestStateService',
  'userGroupService',
  '$sessionStorage',
  '$timeout',
  '$window',
  function (
    $scope,
    $stateParams,
    $log,
    $state,
    $filter,
    shareHolderService,
    VaRequestStateService,
    userGroupService,
    $sessionStorage,
    $timeout,
    $window
  ) {
    $scope.securityAccountValidation = validationRegex.SECURITY_ACCOUNT_NR;
    $scope.meetingId = '';
    var issuerId = '';
    var requestStatus = 'edit';
    var virtualReqId = '';
    var meetingId;
    //Below function is to validate SAN and navigating to next page
    $scope.next = function () {
      $scope.errorMsg = '';
      var validateReqData = {
        securityAccountNumber: $scope.securityAccountNumber,
        loginUserId: $sessionStorage.userId,
        shareholderId: $sessionStorage.groupId,
        languageCode: $sessionStorage.selectedLang,
        noOfSecurities: $scope.numberOfShares,
        meetingId: meetingId,
        issuerId: issuerId,
        shareClassId: $scope.shareclassId,
        intermediaryId: $scope.intermediaryId,
        workStationType: $sessionStorage.usertype,
        virtualAttendanceRequestId: virtualReqId,
      };

      shareHolderService
        .validateVirtualParticipationRequest(validateReqData)
        .then(
          function (response) {
            VaRequestStateService.setFormData('vaRequestStep1', {
              shareClassId: $scope.shareclassId,
              intermediaryId: $scope.intermediaryId,
              holding: $scope.holding,
              numberOfShares: $scope.numberOfShares,
              securityAccountNumber: $scope.securityAccountNumber,
              selectedIntermediary: $scope.selectedIntermediary,
              issuerId: issuerId,
              meetingId: meetingId,
              selectedShareClass: $scope.selectedShareClass,
            });
            $state.go('vaRequestStep2', {
              selectedIndex: 0,
              phoneCountryCode: $scope.shPhoneCountryCode,
              phoneNumber: $scope.shPhoneNumber,
            });
          },
          function (error) {
            if (error.data.errors) {
              $scope.errorCode = error.data.errors[0].errorCode;
              $scope.errorMsg = error.data.errors[0].errorMessage;
            } else {
              $scope.errorCode = error.data.httpStatusCode;
              $scope.errorMsg = error.statusText;
            }
          }
        );
    };
    // when language changes, following piece of code is executed from header-controller.js
    $scope.$on('onLanguageChangeShareHolder', function (event, lang) {
      getVirtualParticipationRequestDetails(lang);
    });

    //Below function is to get registered meeting details for respective language
    function getVirtualParticipationRequestDetails(lang) {
      $scope.onLoadSpinner = true;
      shareHolderService
        .editVirtualAttendanceRequest($stateParams.virtualReqId, lang)
        .then(
          function (response) {
            $scope.selectedShareClass = response.data.shareClassName;
            $scope.selectedIntermediary = response.data.intermediaryName;
            $scope.numberOfShares = response.data.noOfSecurities;
            if ($scope.numberOfShares === 0) {
              $scope.holding = 'entire';
              $scope.numberOfShares = null;
            } else {
              $scope.holding = 'partial';
            }
            $scope.isinValue = response.data.isin;
            $scope.shareclassId = response.data.shareClassId;
            $scope.intermediaryId = response.data.intermediaryId;
            $scope.securityAccountNumber = response.data.securityAcctNo;
            $scope.meetingDate = response.data.generalMeetingDateMiilis;
            $scope.meetingTime = response.data.generalMeetingDate.split(' ')[3];
            $scope.timezoneStr = response.data.timezoneStr;
            $scope.shPhoneNumber = response.data.phoneNumber;
            $scope.shPhoneCountryCode = {
              dialCode: response.data.phoneNumberCountryCode,
            };
            VaRequestStateService.setMeetingData(
              $scope.meetingDate,
              $scope.meetingTime,
              $scope.timezoneStr,
              $scope.meetingDateTime,
              meetingId,
              requestStatus,
              virtualReqId
            );
            $scope.onLoadSpinner = false;
          },
          function (error) {
            $scope.onLoadSpinner = false;
            return error;
          }
        );
    }

    function init() {
      if ($stateParams.fromPageStep2 !== 'vaRequestStep2') {
        VaRequestStateService.resetData(); // when not coming from step2 to assume a new request is started.
        meetingId = $stateParams.gmId;
        issuerId = $stateParams.issuerId;
        virtualReqId = $stateParams.virtualReqId;
        getVirtualParticipationRequestDetails($sessionStorage.selectedLang);
        //Below is the function used to fetch issuer logo
        userGroupService.getIssuerGroupLogo(issuerId).then(
          function (response) {
            $scope.issuerGroupDetails = response.data;
            VaRequestStateService.setIssuer($scope.issuerGroupDetails);
          },
          function (error) {
            $log.debug(error);
          }
        );
      } else {
        //when come back from vaRequestStep2 page
        var meetingData = VaRequestStateService.getMeetingData();
        $scope.selectedLang = $sessionStorage.selectedLang;
        $scope.meetingDate = meetingData.gmDateMillis;
        $scope.meetingTime = meetingData.meetingTime;
        $scope.timezoneStr = meetingData.timezoneStr;
        virtualReqId = meetingData.virtualReqId;
        meetingId = meetingData.meetingId;
        $scope.issuerGroupDetails = meetingData.issuerDetails;
        var formData = VaRequestStateService.getFormData('vaRequestStep1');
        if (formData) {
          issuerId = formData.issuerId;
          ($scope.selectedShareClass = formData.selectedShareClass),
            ($scope.intermediaryId = formData.intermediaryId),
            ($scope.shareclassId = formData.shareClassId),
            ($scope.holding = formData.holding);
          $scope.numberOfShares = formData.numberOfShares;
          $scope.securityAccountNumber = formData.securityAccountNumber;
          $scope.selectedIntermediary = formData.selectedIntermediary;
        }
      }
    }

    //call the below function on load
    init();
  },
]);
