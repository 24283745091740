/**
 * @ngdoc controller
 * @name userMaintenanceModule.VCUserListController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the list of users belonging to particular
 *              vote collector group
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter the firstName and to translate messages from
 *           locale file
 * @requires PaginationSvc for pagination
 */

angular.module('userMaintenanceModule').controller('VCUserListController', [
  '$scope',
  '$log',
  '$state',
  '$stateParams',
  '$filter',
  'userService',
  '$sessionStorage',
  'PaginationSvc',
  function (
    $scope,
    $log,
    $state,
    $stateParams,
    $filter,
    userService,
    $sessionStorage,
    PaginationSvc
  ) {
    $scope.groupId = $stateParams.groupId;
    $scope.groupStatus = $stateParams.status;
    $scope.productCode = $stateParams.productCode;
    $scope.groupName = $stateParams.breadCrumbDetails[0];
    $scope.redirectedFrom = $stateParams.redirectedFrom;
    $scope.gmId = $stateParams.gmId;
    var page = 1;
    if ($stateParams.page !== undefined && $stateParams.page !== null) {
      page = $stateParams.page;
    }
    $scope.user = 'vote collector';
    // $scope.loading is used for spinner
    $scope.loading = true;
    // call getUserList from userService to fetch user list
    // belonging to the particular vote collector group
    $scope.getVCList = function () {
      userService
        .getUserList($scope.groupId, 'VC', 'GM')
        .query()
        .$promise.then(
          function (data) {
            $log.debug('success user view');
            $scope.userData = data;
            $scope.dummyItems = data;
            $scope.pager = {};
            $scope.setPage(page);
            $scope.loading = false;
          },
          function (error) {
            $scope.loading = false;
            return error;
          }
        );
    };
    // call getActionUserList from userService to fetch user
    // list users yet to accept their roles
    $scope.getActionVCList = function () {
      userService
        .getActionUserList($stateParams.gmId)
        .query()
        .$promise.then(
          function (data) {
            $log.debug('success user view');
            $scope.userData = data;
            $scope.dummyItems = data;
            $scope.pager = {};
            $scope.setPage(page);
            $scope.loading = false;
          },
          function (error) {
            $scope.loading = false;
            return error;
          }
        );
    };
    // Below function is to search the users related to entry
    // in that search box
    $scope.onFirstNameChange = function (val) {
      $log.debug(val);
      $scope.dummyItems = $filter('filter')(
        $scope.userData,
        $scope.searchByName
      );
      $scope.pager = {};
      $scope.setPage(page);
    };
    $scope.searchByName = function (item) {
      if (item.firstName) {
        if (
          !$scope.lastName ||
          item.lastName.toLowerCase().indexOf($scope.lastName.toLowerCase()) !==
            -1 ||
          item.firstName
            .toLowerCase()
            .indexOf($scope.lastName.toLowerCase()) !== -1
        ) {
          return true;
        }
      } else {
        if (
          !$scope.lastName ||
          item.lastName.toLowerCase().indexOf($scope.lastName.toLowerCase()) !==
            -1
        ) {
          return true;
        }
      }
      return false;
    };
    // Below function is for pagination, this calls from HTML
    // on clicking of each page
    $scope.setPage = function (page) {
      $scope.pager = [];
      if (page < 1 || page > $scope.pager.totalPages) {
        return;
      }

      // get pager object from service
      $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
      $log.debug($scope.pager);

      // get current page of items
      $scope.viewUserList = $scope.dummyItems.slice(
        $scope.pager.startIndex,
        $scope.pager.endIndex + 1
      );
      $scope.pageNo = page;
    };
    // Below is function call from HTML for modal window.
    $scope.deleteModal = function (userId, status) {
      if (status === 'Enabled' || status === 'Pending') {
        $scope.statusAction = 'disable';
      } else if (status === 'Disabled') {
        $scope.statusAction = 'enable';
      }
      $scope.modalHeader =
        $filter('translate')('label_confirm') +
        ' ' +
        $scope.statusAction +
        ' ' +
        $scope.user +
        '!';
      $scope.modalBody =
        $filter('translate')('label_areYouSureModal') +
        $scope.statusAction +
        $filter('translate')('label_thisUser');
      $scope.modalFooter = '';
      $scope.modalLeftButton = $filter('translate')('label_confirm');
      $scope.modalRightButton = $filter('translate')('label_cancel');
      $scope.userId = userId;
      $scope.status = status;
    };
    // Below is the function call for deleting an vote
    // collector user.

    $scope.deleteUserList = function (userId) {
      userService.disableUser(
        userId,
        'GM',
        'VC',
        $scope.groupId,
        $sessionStorage.userId,
        'secureToken',
        function (response) {
          $scope.userGroup = 'vote collector';
          $scope.getVCList();
          $scope.success = true;
          $scope.message = response.message;
        },
        function (error) {
          $scope.failure = true;
          $scope.errorMessage = error.errors[0].errorMessage;
        }
      );
    };
    if ($stateParams.redirectedFrom === 'action') {
      // call the function for first time if the tab is redirected from action page
      $scope.getActionVCList();
    } else {
      // call the function for first time if the tab is redirected from user maintenance section
      $scope.getVCList();
    }
  },
]);
