/**
 * @ngdoc sharePlanHolderController
 * @description This controller is used for general meeting module.
 * @requires generalMeetingHomeModule where there is a service call to
 *           sharePlanHolderGMList
 * @requires $scope and $log
 */

angular
  .module('generalMeetingHomeModule')
  .controller('sharePlanHolderController', [
    '$scope',
    '$log',
    '$state',
    'shareHolderService',
    'generalMeetingService',
    '$filter',
    function (
      $scope,
      $log,
      $state,
      shareHolderService,
      generalMeetingService,
      $filter
    ) {
      $scope.onLoadSpinner = true;
      shareHolderService
        .getSHGeneralMeetings()
        .query()
        .$promise.then(
          function (data) {
            $scope.gmList = [];
            $scope.generalMeetinglist = data;
            angular.forEach($scope.generalMeetinglist, function (item) {
              if (item.status === 'L') {
                $scope.gmList.push(item);
              }
            });
            $scope.onLoadSpinner = false;
          },
          function (error) {
            $scope.onLoadSpinner = false;
            return error;
          }
        );
      $scope.goGMDetails = function (
        meetingId,
        meetingName,
        issuerId,
        issuerName,
        generalMeetingDateStr,
        status,
        intermediaryDeadlineDate,
        intermediaryDeadlineDateStr
      ) {
        $state.go('generalMeetings', {
          selectedIndex: 0,
          meetingId: meetingId,
          issuerId: issuerId,
          issuerName: issuerName,
          status: status,
          intermediaryDateTimeStamp: intermediaryDeadlineDate,
          intermediaryDate: intermediaryDeadlineDateStr,
          breadCrumbDetails: [],
        });
      };
    },
  ]);
