/**
 * @ngdoc controller
 * @name dwtModule.DwtPaymentDetailsController
 */
'use strict';

angular.module('dwtModule').controller('DwtPaymentDetailsController', [
  '$scope',
  '$log',
  'ViewTaxReclaimModal',
  'ViewDwtDetailService',
  '$sessionStorage',
  '$timeout',
  'dwtFileDownloadSvc',
  function (
    $scope,
    $log,
    ViewTaxReclaimModal,
    ViewDwtDetailService,
    $sessionStorage,
    $timeout,
    dwtFileDownloadSvc
  ) {
    $scope.userId = $sessionStorage.userId;
    $scope.userType = $sessionStorage.usertype;
    // To get data for payment detail summary
    $scope.getPaymentDetails = function () {
      ViewDwtDetailService.getPaymentDetailsOverview($scope.eventId).then(
        function (data) {
          $scope.paymentDetailsOverview = data;
          var dwtPaymentSummary =
            $scope.paymentDetailsOverview.data.dwtPaymentSummary;
          if (dwtPaymentSummary) {
            for (var i = 0; i < dwtPaymentSummary.length; i++) {
              if (dwtPaymentSummary[i].claimType === 'QRF') {
                $scope.totalAmtQRF = dwtPaymentSummary[i].totalClaimAmount;
              }
            }
          }
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    $scope.paymentCompleted = function (paymentClaimId, paymentType) {
      if (paymentType === 'PE') {
        var claimIds = [{ claimId: paymentClaimId }];
      } else {
        var claimIds;
        claimIds = paymentClaimId;
      }
      var commentsData = {
        workstation: 'AG',
        eventId: $scope.eventId,
        loginUserId: $scope.userId,
        dwtClaims: claimIds,
        paymentType: paymentType,
      };
      ViewDwtDetailService.paymentCompletedAction(commentsData).then(
        function (data) {
          $scope.paymentCompletedAction = data;
          $scope.paymentSuccess = data.data.message;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
          }, 2000);
          $scope.getPaymentDetails();
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    $scope.paymentReceived = function (paymentClaimId, paymentType) {
      if (paymentType === 'PE') {
        var claimIds = [{ claimId: paymentClaimId }];
      } else {
        var claimIds;
        claimIds = paymentClaimId;
      }
      var commentsData = {
        workstation: 'AG',
        eventId: $scope.eventId,
        loginUserId: $scope.userId,
        dwtClaims: claimIds,
      };
      ViewDwtDetailService.paymentReceivedAction(commentsData).then(
        function (data) {
          $scope.paymentReceivedAction = data;
          $scope.paymentSuccess = data.data.message;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
          }, 2000);
          $scope.getPaymentDetails();
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    // Below is the function call from HTML to download pdf
    $scope.downloadInvoicePDF = function (taxInvoiceId) {
      var downloadUrl = 'v1/claimforms/taxinvoice/' + taxInvoiceId;
      var a = document.createElement('a');
      document.body.appendChild(a);
      dwtFileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            var fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          downloadUrl = '';
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    //function to download EXCEL report for ref number.
    $scope.downloadRefExcelReport = function (refNumber, event, str) {
      $scope.loading = true;
      var downloadUrl =
        'v1/dwtpayments/' +
        $scope.eventId +
        '/' +
        refNumber +
        '/' +
        str +
        '/refNumberExcel';
      var a = document.createElement('a');
      document.body.appendChild(a);
      dwtFileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            var fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          downloadUrl = '';
          $scope.loading = false;
        },
        function (error) {
          $log.debug(error);
          $scope.loading = false;
        }
      );
    };
    //function to download EXCEL report
    $scope.downloadExcelReport = function () {
      $scope.loading = true;
      var downloadUrl = 'v1/dwtpayments/' + $scope.eventId + '/qrfPaymentExcel';
      var a = document.createElement('a');
      document.body.appendChild(a);
      dwtFileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            var fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          downloadUrl = '';
          $scope.loading = false;
        },
        function (error) {
          $log.debug(error);
          $scope.loading = false;
        }
      );
    };
    // call first time
    $scope.getPaymentDetails();
  },
]);
