/**
 * @ngdoc controller
 * @name generalMeetingHomeModule.CreateExtensionRequestController
 * @description This controller is used for Creating extension request.
 * @requires ExtensionRequestService and MyAccountsService are the services which are being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for fetching the logged in user details from session storage
 * @requires $filter to translate messages which are declared in locale files
 */
angular
  .module('dwtCreateExtensionModule')
  .controller('CreateDwtExtensionRequestController', [
    '$scope',
    '$sessionStorage',
    '$stateParams',
    'userGroupService',
    'CreateDwtExtensionReqService',
    'DwtCreateExtn',
    '$log',
    '$timeout',
    '$state',
    function (
      $scope,
      $sessionStorage,
      $stateParams,
      userGroupService,
      CreateDwtExtensionReqService,
      DwtCreateExtn,
      $log,
      $timeout,
      $state
    ) {
      $scope.createExtnSuccess = null;
      $scope.createExtnErrRes = null;
      $scope.createExtnRequest = false;
      $scope.extReqRejected = $stateParams.extReqRejected;
      $scope.issuerId = $stateParams.issuerId;
      $scope.peExtReqFlag = $stateParams.peExtReqFlag;
      $scope.qrfExtReqFlag = $stateParams.qrfExtReqFlag;
      $scope.eventName = $stateParams.eventName;
      $scope.issuerName = $stateParams.issuerName;
      $scope.eventId = $stateParams.eventId;
      $scope.shareClassName = $stateParams.shareClassName;
      $scope.isin = $stateParams.isin;
      $scope.qrfEndDate = $stateParams.qrfEndDate;
      $scope.peEndDate = $stateParams.peEndDate;
      $scope.peFlag = $stateParams.peFlag;
      $scope.qrfFlag = $stateParams.qrfFlag;
      $scope.taSubmissionDate = $stateParams.taSubmissionDate;
      $scope.taxReclaimIndexId = $sessionStorage.taxReclaimIndexId;
      $scope.languageCode = 'EN';
      $scope.claimTypeDropDown = [];
      $scope.loading = false;
      $scope.options = { hstep: [1, 2, 3], mstep: [1, 5, 10, 15, 25, 30] };
      $scope.ismeridian = false;
      $scope.hstep = 1;
      $scope.mstep = 1;
      var today = new Date();
      if (
        $scope.peEndDate < today.getTime() &&
        $scope.peEndDate !== 0 &&
        ($scope.peExtReqFlag || $stateParams.dwtType === 'PE')
      ) {
        $scope.claimTypeDropDown.push('PE');
        if ($stateParams.peEndDateStr !== null) {
          $scope.eventEndDate =
            $stateParams.peEndDateStr.substring(0, 17) + ' CET';
        }
      }
      if (
        $scope.qrfEndDate < today.getTime() &&
        ($scope.qrfExtReqFlag || $stateParams.dwtType === 'QRF')
      ) {
        $scope.claimTypeDropDown.push('QRF');
        $scope.eventEndDate =
          $stateParams.qrfEndDateStr.substring(0, 17) + ' CET';
      }
      if (
        $scope.peEndDate < today.getTime() &&
        $scope.qrfEndDate < today.getTime()
      ) {
        if ($scope.peEndDate < $scope.qrfEndDate) {
          $scope.eventEndDate =
            $stateParams.qrfEndDateStr.substring(0, 17) + ' CET';
        } else {
          $scope.eventEndDate =
            $stateParams.peEndDateStr.substring(0, 17) + ' CET';
        }
      }
      // $scope.claimTypeDropDown = ['PE','QRF'];
      //Below is the service call for first time to load issuer logo
      userGroupService.getIssuerGroupLogo($scope.issuerId).then(
        function (response) {
          $scope.issuerGroupDetails = response.data;
          if (
            $scope.issuerGroupDetails.logoBaseStr === undefined ||
            $scope.issuerGroupDetails.logoBaseStr === null
          ) {
            $scope.issuerLogoNotAvailable = true;
          }
        },
        function (error) {
          $log.debug(error);
          $scope.issuerLogoNotAvailable = true;
        }
      );
      //Below is the function call from HTML to send the extension request
      $scope.successCallback = function (response) {
        $scope.loading = false;
        $scope.createExtnSuccess = response.data.message;
        $scope.createExtnErrRes = null;
        $scope.createExtnRequest = true;
        $('#successMsg').modal('show');
        $timeout(function () {
          $('#successMsg').modal('hide');
          $('.modal-backdrop').remove();
          if ($stateParams.dwtType) {
            document.getElementById('goback').click();
          } else {
            $state.go('taxReclaimHome', {
              selectedIndex: $scope.taxReclaimIndexId,
            });
          }
        }, 2000);
      };
      $scope.errorCallback = function (error) {
        $scope.loading = false;
        $scope.createExtnReq.sendDisabled = false;
        $scope.createExtnErrRes = error.data.errors[0].errorMessage;
        $scope.createExtnSuccess = null;
      };
      $scope.submitLoading = function () {
        $scope.loading = true;
      };

      /* date picker */

      $scope.inlineOptions = {
        customClass: getDayClass,
      };
      $scope.dateOptions = {
        minDate: new Date(),
      };
      $scope.open1 = function () {
        $scope.popup1.opened = true;
      };
      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };
      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.popup1 = {
        opened: false,
      };
      $scope.example = {
        value: new Date(1970, 0, 1, 0, 0, 0),
      };
      $scope.createExtnReq = new DwtCreateExtn($stateParams, $sessionStorage);
      function getDayClass(data) {
        var date = data.date,
          mode = data.mode;
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(
              0,
              0,
              0,
              0
            );
            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }
        return '';
      }
      /* date picker */

      /* time picker */
      /* time picker */
    },
  ]);
