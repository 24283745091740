/**
 * @ngdoc controller
 * @name dwtModule.SearchShareholderDetailsController
 * @description This controller is used for dwt module for searching share holders.
 * @requires $scope
 * @requires $filter
 * @requires searchShareholderService
 */
'use strict';

angular
  .module('dwtModule')
  .controller('SearchShareholderDetailsController', [
    '$scope',
    '$log',
    '$sessionStorage',
    'searchShareholderService',
    '$state',
    '$stateParams',
    '$filter',
    '$timeout',
    'PaginationSvc',
    'fileUploadSvc',
    'dwtFileDownloadSvc',
    function (
      $scope,
      $log,
      $sessionStorage,
      searchShareholderService,
      $state,
      $stateParams,
      $filter,
      $timeout,
      PaginationSvc,
      fileUploadSvc,
      dwtFileDownloadSvc
    ) {
      $scope.otherFormToolTip =
        '<div>' +
        $filter('translate')('label_ortherFormtooltip1') +
        '</div>' +
        '<br>' +
        '<div>' +
        $filter('translate')('label_ortherFormtooltip3') +
        '</div>' +
        '<br>' +
        '<div>' +
        $filter('translate')('label_ortherFormtooltip2') +
        '</div>';
      $scope.ibFormToolTip =
        '<div>' +
        $filter('translate')('label_ibFormtooltip1') +
        '</div>' +
        '<br>' +
        '<div>' +
        $filter('translate')('label_ibFormtooltip2') +
        '</div>';
      $scope.shareHolderId = $stateParams.shareHolderId;
      $scope.provideFileUpload = $stateParams.provideFileUpload;
      $scope.userGroupID = $sessionStorage.groupId;
      $scope.userType = $sessionStorage.usertype;
      $scope.loading = true;
      // function for pagination service
      $scope.setPage = function (page) {
        $scope.currentPage = page;
        $scope.pager = [];
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }
        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
        $log.debug($scope.pager);
        // get current page of items
        $scope.ShareHolderDwtEventClaims = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
        $scope.beginIndex = $scope.pager.startIndex;
        $scope.pageNo = page;
      };
      //upload 6166 and IB92/96 forms
      $scope.addEditClaimForms = function (e, qrfClaim, formType) {
        $scope.fileList = e.files;
        if (
          'application/pdf' !== $scope.fileList[0].type &&
          'application/softgrid-pdf' !== $scope.fileList[0].type
        ) {
          qrfClaim.error = true;
          $timeout(function () {
            qrfClaim.error = false;
          }, 5000);
        } else if ($scope.fileList[0].name.length > 50) {
          qrfClaim.fileNameError = true;
          $timeout(function () {
            qrfClaim.fileNameError = false;
          }, 5000);
        } else {
          var startDate = new Date();
          var endDate = new Date(startDate.getFullYear(), 11, 31);
          var documentFormData = new FormData();
          documentFormData.append('formType', formType);
          documentFormData.append('File', $scope.fileList[0]);
          documentFormData.append('loginUserId', $sessionStorage.userId);
          documentFormData.append('eventId', qrfClaim.eventId);
          if (formType === 'FT_6166') {
            documentFormData.append(
              'shareholderId',
              $scope.ShareHolderDetails.shareHolderId
            );
            documentFormData.append(
              'startDate',
              $filter('date')(startDate, 'yyyy-MM-dd HH:mm:ss')
            );
            documentFormData.append(
              'endDate',
              $filter('date')(endDate, 'yyyy-MM-dd HH:mm:ss')
            );
          }
          var file = $scope.fileList;
          var uploadUrl = 'v1/claimforms/upload';
          fileUploadSvc.uploadFileToUrl(file, uploadUrl, documentFormData).then(
            function (data) {
              $scope.response = data.data.message;
              $('#successMsg').modal('show');
              $timeout(function () {
                $state.reload();
                $('#successMsg').modal('hide');
                $('.modal-backdrop').remove();
              }, 2000);
            },
            function (error) {
              $log.debug(error);
            }
          );
        }
      };

      //function to download 6166Form or IB92/96 Form
      $scope.downloadClaimForms = function (formType, formId) {
        var downloadUrl =
          'v1/claimforms/' + formId + '/' + formType + '/download';
        var a = document.createElement('a');
        document.body.appendChild(a);
        dwtFileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            let fileVO = response;
            if (window.navigator.msSaveOrOpenBlob) {
              a.onclick = function () {
                window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
              };
              a.click();
            } else {
              var fileURL = URL.createObjectURL(fileVO.file);
              a.href = fileURL;
              a.download = fileVO.fileName;
              a.click();
            }
            downloadUrl = '';
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      searchShareholderService
        .getShareholderClaimDetails(
          $scope.shareHolderId,
          $sessionStorage.usertype,
          $sessionStorage.userId,
          'DWT',
          $scope.userGroupID
        )
        .then(
          function (resp) {
            $scope.ShareHolderDetails = resp.data.dwtShareholderVOList[0];
            $scope.ShareHolderDwtEventClaims =
              $scope.ShareHolderDetails.claimResultVoList;
            if ($scope.ShareHolderDwtEventClaims) {
              $scope.dummyItems = $scope.ShareHolderDwtEventClaims;
              $scope.pager = {};
              $scope.setPage(1);
            }
            $scope.loading = false;
          },
          function (error) {
            $scope.loading = false;
            return error;
          }
        );
    },
  ])
  .filter('removeUnderscore', function () {
    return function (value) {
      return !value ? '' : value.replace(/_/g, ' ');
    };
  });
