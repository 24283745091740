/**
 * @ngdoc controller
 * @name userMaintenanceModule.ViewUserController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the individual user details.
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 */

angular.module('userMaintenanceDwtModule').controller(
  'ViewAgentDwtController',
  [
    '$scope',
    '$log',
    '$state',
    '$sessionStorage',
    '$stateParams',
    'userService',
    function($scope, $log, $state, $sessionStorage, $stateParams,
      userService) {

      var userId = $stateParams.userId;
      var userType = $stateParams.userType;
      $scope.prodType = $sessionStorage.productType;
      $scope.user = 'agent';
      // Below is the call to interact with service and get user
      // details.
      userService.getViewUser(userId, userType, '1', $scope.prodType)
        .query().$promise.then(function(data) {
          $log.debug('success user view');
          $scope.viewUserDetails = data;

        }, function(error) {
          $log.debug(error);
        });

    }]);
