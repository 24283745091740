/**
 * @ngdoc controller
 * @name userMaintenanceDwtModule.AddIntermediaryGroupDwtController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with adding new issuer group.
 * @requires $http, userGroupService and fileUploadSvc are the service which are
 *           being used to interact with REST
 * @requires $scope for storing information in scope
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for fetching logged in userId from session storage
 * @requires $filter for date conversion
 */

angular
  .module('userMaintenanceDwtModule')
  .controller('AddIntermediaryGroupDwtController', [
    '$scope',
    '$sessionStorage',
    '$log',
    '$state',
    '$filter',
    '$timeout',
    'DwtIntermediaryGroup',
    'userGroupService',
    function (
      $scope,
      $sessionStorage,
      $log,
      $state,
      $filter,
      $timeout,
      DwtIntermediaryGroup,
      userGroupService
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.userGroupName = 'intermediary';
      $scope.prodType = $sessionStorage.productType;
      $scope.intermediaryFilterValue = '';
      $scope.intermediaryFilterOptions = [];
      $scope.comboboxOptions = [];
      $scope.comboboxText = '';
      $scope.addIntermediaryGroup = new DwtIntermediaryGroup(
        undefined,
        $sessionStorage.userId
      );

      this.$onInit = async () => {
        await userGroupService
          .getAllNonProxiesList()
          .query()
          .$promise.then(
            function (data) {
              $scope.intermediaryFilterOptions = [
                ...data.map((i) => ({
                  name: i.intermediaryName,
                  value: i.intermediaryId,
                })),
              ];
            },
            function (error) {
              $log.debug(error);
            }
          );
      };

      /* date picker */
      $scope.openStartDate = function () {
        $scope.startDate.opened = true;
      };
      $scope.openEndDate = function () {
        $scope.endDate.opened = true;
      };
      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };
      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.startDate = {
        opened: false,
      };
      $scope.endDate = {
        opened: false,
      };
      /* date picker end */
      $scope.ibanClass = 'validation';
      // The below function call is to store the data from UI
      // and pass it to
      // REST call for creating intermediary group
      $scope.successCallback = function (data) {
        if (data.status === 200) {
          $scope.success = true;
          $scope.errorMsg = null;
          $scope.successMsg = data.data.message;
          $scope.intermediaryGrpId = data.data.groupId;

          $('#successMsg').modal('show');
        } else {
          $scope.successMsg = null;
          $scope.error = true;
          $scope.errorMsg = data.data.errors[0].errorMessage;
          if ('CBP_ERR_490' === data.data.errors[0].errorCode) {
            $scope.ibanClass = 'validation ng-invalid ng-dirty';
          }
        }
      };
      $scope.callAddUser = function () {
        $state.go('userMaintenanceForDWT.addIntermediaryDwt', {
          userGroupId: $scope.intermediaryGrpId,
        });
      };
      $scope.callUserList = function () {
        $state.go('userMaintenanceForDWT.intermediaryGroupDwt', {});
      };
      $scope.errorCallback = function (error) {
        $scope.successMsg = null;
        $scope.error = true;
        $scope.errorMsg = error.message;
      };
      $scope.removeRegForm = function () {
        $scope.showAddReg = false;
        $scope.logoFile = undefined;
        $scope.addIntermediaryGroup.startDate = '';
        $scope.addIntermediaryGroup.endDate = '';
      };

      $scope.handleComboboxTextChange = (text) => {
        if (!text) {
          $scope.comboboxOptions = [];
        } else {
          $scope.comboboxOptions = $scope.intermediaryFilterOptions.filter(
            (option) => option.name.toLowerCase().includes(text.toLowerCase())
          );
        }
        $scope.intermediaryFilterValue = text;
      };

      $scope.handleComboboxClear = () => {
        $scope.intermediaryFilterValue = '';
        $scope.comboboxText = '';
      };

      $scope.handleOptionSelected = (option) => {
        $scope.comboboxText = option.name;
        $scope.comboboxOptions = [];
        $scope.intermediaryFilterValue = option.name;
      };

      userGroupService
        .getNonProxiesList('DWT')
        .query()
        .$promise.then(
          function (data) {
            $scope.nonProxies = data;
            $scope.editInterLoading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.editInterLoading = false;
          }
        );

      $scope.addIntermediaryGroupMethod = function () {
        var userId = $sessionStorage.userId;
        var uploadUrl = 'v1/intermediaries';
        $scope.error = false;
        if (
          ($scope.logoFile === null || $scope.logoFile === undefined) &&
          ($scope.agreeFile === null || $scope.agreeFile === undefined)
        ) {
          $scope.addIntermediaryGroup.addGroup(
            null,
            null,
            uploadUrl,
            userId,
            $scope.intermediaryFilterValue,
            $scope.prodType,
            $scope.successCallback,
            $scope.errorCallback
          );
        } else if (
          $scope.logoFile !== null &&
          $scope.logoFile !== undefined &&
          ($scope.agreeFile === null || $scope.agreeFile === undefined)
        ) {
          if ($scope.logoFile !== null && $scope.logoFile !== undefined) {
            if (
              'image' !== $scope.logoFile.type.substring(0, 5) ||
              $scope.logoFile.size > 256000
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('imgfileSize_Error');
            } else {
              $scope.error = false;
              $scope.addIntermediaryGroup.addGroup(
                $scope.logoFile,
                null,
                uploadUrl,
                userId,
                $scope.intermediaryFilterValue,
                $scope.prodType,
                $scope.successCallback,
                $scope.errorCallback
              );
            }
          }
        } else if (
          ($scope.logoFile === null || $scope.logoFile === undefined) &&
          $scope.agreeFile !== null &&
          $scope.agreeFile !== undefined
        ) {
          if (
            'Invalid Date' === $scope.addIntermediaryGroup.startDate ||
            'Invalid Date' === $scope.addIntermediaryGroup.endDate ||
            '' === $scope.addIntermediaryGroup.startDate ||
            '' === $scope.addIntermediaryGroup.endDate ||
            null === $scope.addIntermediaryGroup.startDate ||
            null === $scope.addIntermediaryGroup.endDate
          ) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('selectDate_error');
          } else {
            if (
              'pdf' !== $scope.agreeFile.type.slice(-3) ||
              $scope.agreeFile.size > 1000000
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('fileSize_1mbError');
            } else {
              if (
                $scope.addIntermediaryGroup.startDate.getTime() >
                $scope.addIntermediaryGroup.endDate.getTime()
              ) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')(
                  'label_error_agrDateRange'
                );
              } else if (
                new Date().getTime() >
                $scope.addIntermediaryGroup.endDate.getTime()
              ) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')(
                  'label_error_agrPastDate'
                );
              } else {
                $scope.error = false;
                $scope.addIntermediaryGroup.addGroup(
                  null,
                  $scope.agreeFile,
                  uploadUrl,
                  userId,
                  $scope.intermediaryFilterValue,
                  $scope.prodType,
                  $scope.successCallback,
                  $scope.errorCallback
                );
              }
            }
          }
        } else if (
          $scope.logoFile !== null &&
          $scope.logoFile !== undefined &&
          $scope.agreeFile !== null &&
          $scope.agreeFile !== undefined
        ) {
          if (
            'image' !== $scope.logoFile.type.substring(0, 5) ||
            $scope.logoFile.size > 256000
          ) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('imgfileSize_Error');
          } else {
            if (
              'Invalid Date' === $scope.addIntermediaryGroup.startDate ||
              'Invalid Date' === $scope.addIntermediaryGroup.endDate ||
              '' === $scope.addIntermediaryGroup.startDate ||
              '' === $scope.addIntermediaryGroup.endDate ||
              null === $scope.addIntermediaryGroup.startDate ||
              null === $scope.addIntermediaryGroup.endDate
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('selectDate_error');
            } else {
              if (
                'pdf' !== $scope.agreeFile.type.slice(-3) ||
                $scope.agreeFile.size > 1000000
              ) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')('fileSize_1mbError');
              } else {
                if (
                  $scope.addIntermediaryGroup.startDate.getTime() >
                  $scope.addIntermediaryGroup.endDate.getTime()
                ) {
                  $scope.error = true;
                  $scope.errorMsg = $filter('translate')(
                    'label_error_agrDateRange'
                  );
                } else if (
                  new Date().getTime() >
                  $scope.addIntermediaryGroup.endDate.getTime()
                ) {
                  $scope.error = true;
                  $scope.errorMsg = $filter('translate')(
                    'label_error_agrPastDate'
                  );
                } else {
                  $scope.error = false;
                  $scope.addIntermediaryGroup.addGroup(
                    $scope.logoFile,
                    $scope.agreeFile,
                    uploadUrl,
                    userId,
                    $scope.intermediaryFilterValue,
                    $scope.prodType,
                    $scope.successCallback,
                    $scope.errorCallback
                  );
                }
              }
            }
          }
        }
      };
      $scope.errorCallback = function (error) {
        $scope.successMsg = null;
        $scope.failure = true;
        $scope.error = true;
        if (error.data.errors.length > 0) {
          $scope.errorMsg = error.data.errors[0].errorMessage;
        } else {
          $scope.errorMsg = error.data;
        }
      };
    },
  ]);
