/**
 * @ngdoc controller
 * @name userMaintenanceModule.ViewIssuerGroupDetailsController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing details of particular issuer group.
 * @requires userGroupService is the service which is being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 */

angular.module('userMaintenanceDwtModule').controller(
  'ViewIssuerGroupDetailsDwtController',
  [
    '$scope',
    '$log',
    '$stateParams',
    '$sessionStorage',
    'userGroupService',
    function($scope, $log, $stateParams, $sessionStorage,
      userGroupService) {
      $scope.prodType = $sessionStorage.productType;
      userGroupService.getViewIssuerGroup($stateParams.groupId,
        $scope.prodType, 'EN').query().$promise.then(function(
        data) {
        $scope.dwtIssuerGroup = data;
        if (null !== $scope.dwtIssuerGroup.logoBaseStr
                        && '' !== $scope.dwtIssuerGroup.logoBaseStr) {
          $scope.showLogo = true;
        } else {
          $scope.showLogo = false;
        }
      }, function(error) {
        $log.debug(error);
      });
    }]);
