/**
 * @ngdoc controller
 * @name dwtModule.DetailedViewClaimController
 * @description This controller is used to view the details of PE or QRF claims
 * @requires ViewDwtDetailService and userGroupService are the service which is being used to interact
 *           with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires ModelWindowService for modal window
 * @requires $filter to translate messages which are declared in locale files
 *           and filter the array based on particular value
 * @requires $state for routing
 */
'use strict';

angular.module('dwtModule').controller('DetailedViewClaimController', [
  '$scope',
  '$sessionStorage',
  '$stateParams',
  '$timeout',
  '$state',
  '$filter',
  'ViewDwtDetailService',
  'ModelWindowService',
  'userGroupService',
  function (
    $scope,
    $sessionStorage,
    $stateParams,
    $timeout,
    $state,
    $filter,
    ViewDwtDetailService,
    ModelWindowService,
    userGroupService
  ) {
    $scope.userType = $sessionStorage.usertype;
    var userId = $sessionStorage.userId;
    var userGroupId = $sessionStorage.groupId;
    $scope.taxReclaimIndexId = $sessionStorage.taxReclaimIndexId;
    $scope.taxAuthAgentFlag = $sessionStorage.isAdmin;
    $scope.flag = $stateParams.flag;
    $scope.claimId = $stateParams.claimId;
    $scope.eventId = $stateParams.eventId;
    $scope.issuerId = $stateParams.issuerId;
    $scope.navFrom = $stateParams.navFrom;
    $scope.issuerName = $stateParams.issuerName;
    $scope.eventStatus = $stateParams.eventStatus;
    $scope.countNumber = $stateParams.countNo;
    $scope.claimsList = $stateParams.claimsArray;
    $scope.eventName = $stateParams.eventName;
    $scope.dwtPerSecurity = $stateParams.dwtPerSecurity;
    $scope.qrfEndDate = $stateParams.qrfEndDate;
    $scope.peEndDate = $stateParams.peEndDate;
    $scope.peFlag = $stateParams.peFlag;
    $scope.qrfFlag = $stateParams.qrfFlag;
    $scope.extReqFlag = $stateParams.extReqFlag;
    $scope.extensionReqFlagForQRF = $stateParams.extensionReqFlagForQRF;
    $scope.extensionReqFlagForPE = $stateParams.extensionReqFlagForPE;
    $scope.showBForm = true;
    $scope.showIBOther = false;
    $scope.loading = true;
    $scope.formType = 'FT_6166';
    $scope.hidepdf = false;

    $scope.timeZoneString = 'CET';
    var todaysDate = '';
    ViewDwtDetailService.getCurrentCETTime()
      .query()
      .$promise.then(
        function (data) {
          if ($scope.timeZoneString === 'CET') {
            todaysDate = $filter('filter')(data, { timezone: 'CET' }, true);
          } else if ($scope.timeZoneString === 'GMT') {
            todaysDate = $filter('filter')(data, { timezone: 'GMT' }, true);
          }
          if (todaysDate[0].currentMillis > $scope.qrfEndDate) {
            $scope.endDateCrossed = true;
          } else {
            $scope.endDateCrossed = false;
          }
          if (todaysDate[0].currentMillis > $scope.peEndDate) {
            $scope.peEndDateCrossed = true;
          } else {
            $scope.peEndDateCrossed = false;
          }
        },
        function (error) {
          $log.debug(error);
        }
      );

    // function call to get issuer logo
    $scope.getIssuerLogo = function (issuerId) {
      if (issuerId) {
        userGroupService.getIssuerGroupLogo(issuerId).then(
          function (response) {
            $scope.issuerLogoPresent = false;
            $scope.issuerGroupDetails = response.data;
            // Do not change the below condition. It is for issuer logo display fix. REST response has ""
            if ($scope.issuerGroupDetails.logoBaseStr) {
              $scope.issuerLogoPresent = true;
            }
          },
          function (error) {
            $scope.loading = false;
            return error;
          }
        );
      }
    };
    $scope.viewForms = function (formId, formType) {
      if (formId) {
        /*ViewDwtDetailService.viewClaimForms(
                                userId, formId, formType).then(
                                        function() {*/
        var pathList = window.location.pathname.split('/');
        if (pathList[1] === 'cbp') {
          $scope.docUrlsrc =
            window.location.origin +
            '/' +
            pathList[1] +
            '/v1/claimforms/' +
            userId +
            '/' +
            formId +
            '/' +
            formType +
            '/viewcalimsform?wmode=opaque';
        } else {
          $scope.docUrlsrc =
            window.location.origin +
            '/v1/claimforms/' +
            userId +
            '/' +
            formId +
            '/' +
            formType +
            '/viewcalimsform?wmode=opaque';
        }
        /*}, function(error) {
                                          $scope.loading = false;
                                          return error;
                                        });*/
      }
    };
    //function call to invoke the service to fetch the detailed view of a claim
    $scope.getClaimDetails = function () {
      ViewDwtDetailService.getDwtClaimsDetailsView(
        $scope.claimId,
        $scope.userType,
        $scope.eventId
      ).then(
        function (data) {
          $scope.viewDetailsData = data.data;
          $scope.status = $scope.viewDetailsData.claimsVO.status;
          $scope.claimType = $scope.viewDetailsData.type;
          $scope.disableUndoReject = false;

          $scope.form6166Id = $scope.viewDetailsData.claimsVO.form6166Id;
          $scope.formIB9296Id = $scope.viewDetailsData.claimsVO.formIB9296Id;

          if ($scope.claimType === 'PE' && $scope.peEndDateCrossed) {
            if ($scope.peFlag) {
              $scope.disableUndoReject = false;
            } else {
              $scope.disableUndoReject = true;
            }
          } else if ($scope.claimType === 'QRF' && $scope.endDateCrossed) {
            if ($scope.qrfFlag) {
              $scope.disableUndoReject = false;
            } else {
              $scope.disableUndoReject = true;
            }
          }
          if ($scope.userType === 'IS') {
            if ($scope.showBForm) {
              $scope.viewForms($scope.form6166Id, $scope.formType);
            } else {
              $scope.viewForms($scope.formIB9296Id, $scope.formType);
            }
          }
          $scope.getIssuerLogo($scope.issuerId);
          $scope.loading = false;
        },
        function (error) {
          $scope.loading = false;
          return error;
        }
      );
    };

    // function call to submit claim
    $scope.submitClaims = function (claimId, intermediaryId, submit) {
      if (submit === 1) {
        $scope.hidepdf = true;
      }
      $scope.claimIdArray = [];
      $scope.claimIdArray.push({
        claimId: claimId,
        intermediaryId: intermediaryId,
      });
      var claimList = {
        claimIntermediaryIDVOs: $scope.claimIdArray,
        eventId: $scope.eventId,
        issuerName: $scope.issuerName,
        issuerId: $scope.issuerId,
        userId: userId,
        userGroupId: userGroupId,
        userType: $scope.userType,
        eventName: $scope.eventName,
      };
      ViewDwtDetailService.submitClaims(claimList, 'secureToken').then(
        function (response) {
          $scope.response = response.data.message;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();

            if ($scope.userType === 'AG') {
              $state.go('dwtDetails', {
                selectedIndex: $scope.taxReclaimIndexId,
                eventId: $scope.eventId,
                flag: $scope.flag,
                issuerId: $scope.issuerId,
                issuerName: $scope.issuerName,
                eventStatus: $scope.eventStatus,
                claimsList: $scope.claimsArray,
                eventName: $scope.eventName,
                dwtPerSecurity: $scope.dwtPerSecurity,
                qrfEndDate: $scope.qrfEndDate,
                peEndDate: $scope.peEndDate,
                peFlag: $scope.peFlag,
                qrfFlag: $scope.qrfFlag,
                breadCrumbDetails: [$scope.eventName],
              });
            } else {
              if (
                $scope.claimsList[$scope.countNumber - 1].countNumber !==
                $scope.claimsList.length
              ) {
                $scope.nextClaim();
              } else {
                $state.reload();
              }
            }
            $('.modal-backdrop').remove();
          }, 2000);
        },
        function (error) {
          $scope.loading = false;
          $scope.error = error.data.errors[0].errorMessage;
        }
      );
    };

    //function call to undo submit or undo reject
    $scope.undoSubmitOrReject = function (claimId, status, undo) {
      if (undo === 1) {
        $scope.hidepdf = true;
      }
      var undoData = {
        claimIdForUndo: claimId,
        eventId: $scope.eventId,
        userType: $scope.userType,
        statusFlag: status,
      };
      ViewDwtDetailService.undoSubmitOrReject(undoData, 'secureToken').then(
        function (response) {
          $scope.response = response.data.message;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
            if ($scope.userType === 'AG') {
              $state.go('dwtDetails', {
                selectedIndex: $scope.taxReclaimIndexId,
                eventId: $scope.eventId,
                flag: $scope.flag,
                issuerId: $scope.issuerId,
                issuerName: $scope.issuerName,
                eventStatus: $scope.eventStatus,
                claimsList: $scope.claimsArray,
                eventName: $scope.eventName,
                dwtPerSecurity: $scope.dwtPerSecurity,
                qrfEndDate: $scope.qrfEndDate,
                peEndDate: $scope.peEndDate,
                peFlag: $scope.peFlag,
                qrfFlag: $scope.qrfFlag,
                breadCrumbDetails: [$scope.eventName],
              });
            } else {
              if (
                $scope.claimsList[$scope.countNumber - 1].countNumber !==
                $scope.claimsList.length
              ) {
                $scope.nextClaim();
              } else {
                $state.reload();
              }
            }
          }, 2000);
        },
        function (error) {
          $scope.loading = false;
          $scope.error = error.data.message;
          $('#errorMsg').modal('show');
        }
      );
    };

    //function call to update the modal window on click of reject button
    $scope.modalRejectClaim = function (
      claimId,
      intermediaryId,
      claimType,
      reject
    ) {
      if (reject === 1) {
        $scope.hidepdf = true;
      }
      $scope.claimIdArray = [];
      $scope.type = claimType;
      $scope.claimIdArray.push({
        claimId: claimId,
        intermediaryId: intermediaryId,
      });
      $scope.modalLeftButton = $filter('translate')('label_rejectClaim');
      $scope.modalBodyTitle = $filter('translate')('label_rejectionComments');
      $scope.modalBody = $filter('translate')('areSureRejectClaim_modal');
      $scope.modalRightButton = $filter('translate')('label_cancel');
      $scope.modalHeader = $filter('translate')('label_modalConfirmAction');
      ModelWindowService.showModelWindow('commentAlertBox');
    };

    //function call to invoke the reject claim service
    $scope.rejectClaim = function (reject) {
      if (reject === 1) {
        $scope.hidepdf = true;
      }
      $scope.loading = true;
      var rejectData = {
        claimIntermediaryIDVOs: $scope.claimIdArray,
        rejectComment: $sessionStorage.comments,
        claimType: $scope.type,
        eventId: $scope.eventId,
        issuerName: $scope.issuerName,
        userId: userId,
        userType: $scope.userType,
        eventName: $scope.eventName,
      };
      ViewDwtDetailService.rejectClaim(rejectData, 'secureToken').then(
        function (response) {
          $scope.loading = false;
          $scope.response = response.data.message;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
            if ($scope.userType === 'AG') {
              $state.go('dwtDetails', {
                selectedIndex: $scope.taxReclaimIndexId,
                eventId: $scope.eventId,
                flag: $scope.flag,
                issuerId: $scope.issuerId,
                issuerName: $scope.issuerName,
                eventStatus: $scope.eventStatus,
                claimsList: $scope.claimsArray,
                eventName: $scope.eventName,
                dwtPerSecurity: $scope.dwtPerSecurity,
                qrfEndDate: $scope.qrfEndDate,
                peEndDate: $scope.peEndDate,
                peFlag: $scope.peFlag,
                qrfFlag: $scope.qrfFlag,
                breadCrumbDetails: [$scope.eventName],
              });
            } else {
              if (
                $scope.claimsList[$scope.countNumber - 1].countNumber !==
                $scope.claimsList.length
              ) {
                $scope.nextClaim();
              } else {
                $state.reload();
              }
            }
          }, 2000);
        },
        function (error) {
          $scope.loading = false;
          $scope.error = error.data.errors[0].errorMessage;
        }
      );
    };

    //function call to go to the next claim on click of next icon
    $scope.nextClaim = function () {
      $scope.hidepdf = false;
      $scope.loading = true;
      $scope.countNumber = $scope.countNumber + 1;
      var claimIdList = $filter('filter')(
        $scope.claimsList,
        {
          countNumber: $scope.countNumber,
        },
        true
      );
      $scope.claimId = claimIdList[0].claimId;
      $scope.getClaimDetails();
    };

    //function call to go to the previous claim on click of previous icon
    $scope.previousClaim = function () {
      $scope.loading = true;
      $scope.countNumber = $scope.countNumber - 1;
      var claimIdList = $filter('filter')(
        $scope.claimsList,
        {
          countNumber: $scope.countNumber,
        },
        true
      );
      $scope.claimId = claimIdList[0].claimId;
      $scope.getClaimDetails();
    };

    // function call to show IB92/96 form and hide 6166/other form
    $scope.showOtherForm = function () {
      $scope.showBForm = false;
      $scope.showIBOther = true;
      $scope.formType = 'FT_other';
      $scope.viewForms($scope.formIB9296Id, $scope.formType);
    };

    // function call to show 6166/other form and hide IB92/96 form
    $scope.show6166Form = function () {
      $scope.showBForm = true;
      $scope.showIBOther = false;
      $scope.formType = 'FT_6166';
      $scope.viewForms($scope.form6166Id, $scope.formType);
    };

    // function call to submit claim
    $scope.authorizeClaim = function (claimId) {
      $scope.claimIdArray = [];
      $scope.claimIdArray.push({ claimId: claimId });
      var claimsToAuthorize = {
        dwtClaims: $scope.claimIdArray,
        dwtEventId: $scope.eventId,
        loggedInUserId: userId,
        workstation: $scope.userType,
        claimType: $scope.claimType,
      };
      if ($scope.eventId) {
        ViewDwtDetailService.toBeAuthorizeSubmitClaims(
          claimsToAuthorize,
          $scope.eventId,
          'secureToken'
        ).then(
          function (response) {
            $scope.response = response.data.message;
            $('#successMsg').modal('show');
            $timeout(function () {
              $('#successMsg').modal('hide');
              $('.modal-backdrop').remove();
              $state.go('dwtDetails', {
                selectedIndex: $scope.taxReclaimIndexId,
                eventId: $scope.eventId,
                flag: $scope.flag,
                issuerId: $scope.issuerId,
                issuerName: $scope.issuerName,
                eventStatus: $scope.eventStatus,
                claimsList: $scope.claimsArray,
                eventName: $scope.eventName,
                dwtPerSecurity: $scope.dwtPerSecurity,
                qrfEndDate: $scope.qrfEndDate,
                peEndDate: $scope.peEndDate,
                peFlag: $scope.peFlag,
                qrfFlag: $scope.qrfFlag,
                breadCrumbDetails: [$scope.eventName],
              });
            }, 2000);
          },
          function (error) {
            $scope.loading = false;
            $scope.errorMessage = error.data.errors[0].errorMessage;
          }
        );
      }
    };
    //function call to invoke service on load for the first time
    $scope.getClaimDetails();
  },
]);
