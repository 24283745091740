/**
 * @ngdoc controller
 * @name userMaintenanceModule.AddProxyIntermediaryGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with adding new proxy intermediary group.
 * @requires userGroupService,$http and fileUploadSvc are the service which are
 *           being used to interact with REST
 * @requires $scope for storing information in scope
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for fetching logged in userId from session storage
 * @requires $filter for date conversion and message translation from locale
 *           file *
 * @requires $timeout
 * @requires $uibModal
 * @requires $stateParams
 * @requires $state
 */

angular
  .module('userMaintenanceModule')
  .controller('AddProxyIntermediaryGroupController', [
    '$scope',
    '$sessionStorage',
    '$log',
    '$http',
    '$filter',
    'userGroupService',
    'fileUploadSvc',
    '$timeout',
    '$uibModal',
    '$stateParams',
    '$state',
    function (
      $scope,
      $sessionStorage,
      $log,
      $http,
      $filter,
      userGroupService,
      fileUploadSvc,
      $timeout,
      $uibModal,
      $stateParams,
      $state
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;
      $scope.intermediaryFilterValue = '';
      $scope.userGroupName = 'proxy';
      userGroupService
        .getCountryList('EN')
        .query()
        .$promise.then(
          function (countries) {
            $log.debug('success user view');
            $scope.countries = countries.countries;
          },
          function (error) {
            $log.debug(error);
          }
        );
      $scope.onCountrySelect = function (selectedCountry) {
        $scope.country = selectedCountry;
        $log.debug('country code', $scope.country.isoCountryCode);
      };

      /* date picker */

      $scope.inlineOptions = {
        customClass: getDayClass,
      };

      $scope.openStartDate = function () {
        if ($scope.startDate) {
          $scope.startDate.opened = true;
        } else {
          $scope.startDate = new Date();
          $scope.startDate.opened = true;
        }
      };
      $scope.openEndDate = function () {
        if ($scope.endDate) {
          $scope.endDate.opened = true;
        } else {
          $scope.endDate = new Date();
          $scope.endDate.opened = true;
        }
      };

      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };

      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];

      $scope.startDate = {
        opened: false,
      };
      $scope.endDate = {
        opened: false,
      };

      function getDayClass(data) {
        var date = data.date,
          mode = data.mode;
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(
              0,
              0,
              0,
              0
            );

            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }

        return '';
      }

      /* date picker end */

      /* download registration form starts here */
      $scope.downloadRegistrationForm = function () {
        var pathList = window.location.pathname.split('/');
        if (pathList[1] === 'cbp') {
          $scope.downloadRegistrationFormPdf =
            window.location.origin +
            '/' +
            pathList[1] +
            '/v1/intermediaries/agreementforms/download' +
            '?authid=' +
            $sessionStorage.userId;
        } else {
          $scope.downloadRegistrationFormPdf =
            window.location.origin +
            '/v1/intermediaries/agreementforms/download' +
            '?authid=' +
            $sessionStorage.userId;
        }
      };
      /* download registration form ends here */

      /* upload file starts here */

      $scope.onFileSelect = function (e) {
        var filesList = e.files;
        var fd = new FormData();
        fd.append('file', filesList[0]);
        if ((filesList[0].size / 1024 / 1024) * 5 > 5) {
          $log.debug(
            'This file size is: ' +
              (filesList[0].size / 1024 / 1024).toFixed(2) +
              ' MB. Please upload a file less than 5 MB.'
          );
          $scope.agendaFileSizeError =
            'This file size is: ' +
            (filesList[0].size / 1024 / 1024).toFixed(2) +
            ' MB. Please upload a file less than 5 MB.';
          return;
        }
        var fileReaderList = [];

        fileReaderList.push(new FileReader());
        fileReaderList[0].readAsDataURL(filesList[0]);

        fileReaderList[0].onload = function () {
          $scope.fileName = filesList[0].name;
          $scope.file = filesList[0];
        }.bind(fileReaderList[0]);
      };

      $scope.handleComboboxTextChange = (text) => {
        $scope.intermediaryFilterValue = text;
      };

      // The below function call is to store the data from UI
      // and pass it to REST call for adding new group
      $scope.addIntermediaryGroup = function () {
        if (undefined !== $scope.logoFile) {
          $scope.file = $scope.logoFile;
          $scope.fileName = $scope.logoFile.name;
        }
        $scope.error = false;
        $scope.success = false;
        $scope.processAssignedSc = !$scope.processAssignedSc ? 'N' : 'Y';
        $scope.processVotingInstruction = !$scope.processVotingInstruction
          ? 'N'
          : 'Y';
        $scope.visibleInShWorkStation = !$scope.visibleInShWorkStation
          ? 'N'
          : 'Y';
        $scope.processAttendanceRequest = !$scope.processAttendanceRequest
          ? 'N'
          : 'Y';
        $scope.processCustomTemplate = !$scope.processCustomTemplate
          ? 'N'
          : 'Y';
        $scope.requiresMeetingNotice = !!$scope.requiresMeetingNotice;

        $log.debug('$scope.username', $scope.userName);
        var documentFormData = new FormData();
        documentFormData.append('groupName', $scope.intermediaryFilterValue);
        documentFormData.append('userId', $sessionStorage.userId);
        documentFormData.append(
          'bankIdentifierCode',
          $scope.bankIdentifierCode
        );
        documentFormData.append('groupEmail', $scope.emailAddress);
        documentFormData.append('city', $scope.city);
        documentFormData.append('country', $scope.country.isoCountryCode);
        documentFormData.append('productCode', 'GM');
        documentFormData.append('intermediaryVO.proxyCode', 'P');
        documentFormData.append(
          'intermediaryVO.processAssignedSc',
          $scope.processAssignedSc
        );
        documentFormData.append(
          'intermediaryVO.processVotingInstruction',
          $scope.processVotingInstruction
        );
        documentFormData.append(
          'intermediaryVO.visibleInShWorkStation',
          $scope.visibleInShWorkStation
        );
        documentFormData.append(
          'intermediaryVO.processAttendanceRequest',
          $scope.processAttendanceRequest
        );
        documentFormData.append(
          'intermediaryVO.processCustomTemplate',
          $scope.processCustomTemplate
        );
        documentFormData.append(
          'intermediaryVO.requiresMeetingNotice',
          $scope.requiresMeetingNotice
        );
        // 8820 and 8910 Fix
        if (undefined !== $scope.file) {
          documentFormData.append('intermediaryVO.agreementForm', $scope.file);
        }
        if (undefined !== $scope.fileName) {
          documentFormData.append(
            'intermediaryVO.agreementFormName',
            $scope.fileName
          );
        }
        var startDate = new Date($scope.startDate);
        var endDate = new Date($scope.endDate);
        documentFormData.append(
          'intermediaryVO.effectiveEndDate',
          $filter('date')(endDate, 'yyyy-MM-dd HH:mm:ss')
        );
        documentFormData.append(
          'intermediaryVO.effectiveStartDate',
          $filter('date')(startDate, 'yyyy-MM-dd HH:mm:ss')
        );

        if (undefined !== $scope.file) {
          if (
            'Invalid Date' === startDate ||
            'Invalid Date' === endDate ||
            null === $scope.startDate ||
            null === $scope.endDate
          ) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('selectDate_error');
          } else {
            if (
              ('application/pdf' !== $scope.file.type ||
                'application/softgrid-pdf' !== $scope.file.type) &&
              $scope.file.size > 1000000
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('fileSize_1mbError');
            } else {
              if (startDate.getTime() > endDate.getTime()) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')('selectToDate_error');
              } else {
                if (
                  $scope.newIntermediryForm.emailAddress.$valid &&
                  $scope.newIntermediryForm.city.$valid &&
                  $scope.newIntermediryForm.bankIdentifierCode.$valid
                ) {
                  var file = $scope.file;
                  var uploadUrl = 'v1/intermediaries';
                  fileUploadSvc
                    .uploadFileToUrl(file, uploadUrl, documentFormData)
                    .then(
                      function (data) {
                        if (data.status === 200) {
                          $scope.success = true;
                          $scope.errorMsg = null;
                          $scope.timedout = $uibModal.open({
                            templateUrl: 'success-dialog.html',
                          });
                          $timeout(function () {
                            $scope.timedout.close();
                            $scope.timedout = null;
                            $state.go(
                              'userMaintenance.proxyIntermediaryGroup',
                              {
                                pageNo: $stateParams.pageNo,
                              }
                            );
                          }, 2000);
                        } else {
                          $log.debug('error');
                          $scope.error = true;
                          $scope.successMsg = null;
                          $scope.errorMsg = data.data.errors[0].errorMessage;
                        }
                      },
                      function (error) {
                        $log.debug(error);
                        $scope.successMsg = null;
                        $scope.error = true;
                        $scope.errorMsg = error.data.errors[0].errorMessage;
                      }
                    );
                }
              }
            }
          }
        } else {
          $scope.error = true;
          $scope.errorMsg = $filter('translate')('selectRegForm_error');
        }
      };
    },
  ]);
