import { formatDateTime } from '@/lib/date';

/**
 * @ngDoc controller
 * @name shareHolderModule.VaRequestStep2Controller
 * @description This controller is used to register step1 details for Virtual Attendance Request
 * @requires
 */
angular.module('shareHolderModule').controller('VaRequestStep2Controller', [
  '$scope',
  '$stateParams',
  '$log',
  '$state',
  '$filter',
  'VaRequestStateService',
  '$sessionStorage',
  '$timeout',
  'shareHolderService',
  'touService',
  'issuerPreferencesService',
  function (
    $scope,
    $stateParams,
    $log,
    $state,
    $filter,
    vaRequestStateService,
    $sessionStorage,
    $timeout,
    shareHolderService,
    touService,
    issuerPreferencesService
  ) {
    let productTypeToU = $filter('translate')('label_gmProductType'); // TODO value should not be in translations
    $scope.termsOfUseUrl = touService.getTouUrl(
      productTypeToU,
      $sessionStorage.workstationData.selectedLang
    );

    // AngularJS is acting funny with transclusion, it needs to be in an
    // object in order to be able to communicate with scope variables
    // inside a transcluded template
    $scope.conditionsChecks = {
      condition1: false,
      condition2: false,
      condition3: false,
    };
    $scope.hasPolicyLink = false;
    $scope.formatDate = formatDateTime;

    let requestStatus = '';
    let virtualReqId = '';
    let issuerId;

    function init() {
      let meetingData = vaRequestStateService.getMeetingData();

      $scope.submitted = false;
      $scope.meetingDate = meetingData.gmDateMillis;
      $scope.meetingTime = meetingData.meetingTime;
      $scope.timezoneStr = meetingData.timezoneStr;
      $scope.meetingDateTime = meetingData.meetingDateTime;
      $scope.issuerGroupDetails = meetingData.issuerDetails;
      $scope.meetingId = meetingData.meetingId;
      requestStatus = meetingData.requestStatus;
      virtualReqId = meetingData.virtualReqId;

      // set initial phone details by param value

      let formData = vaRequestStateService.getFormData('vaRequestStep2');
      if (formData) {
        $scope.phoneCountry = formData.phoneCountryCode;
        $scope.phoneNumber = formData.phoneNumber;
        $scope.countryRepeat = formData.countryRepeat;
        $scope.phoneNumberRepeat = formData.phoneNumberRepeat;
      } else {
        $scope.phoneCountry = $stateParams.phoneCountryCode;
        $scope.phoneNumber = $stateParams.phoneNumber;
        $scope.countryRepeat = { dialCode: 31 };
      }

      issuerId = vaRequestStateService.getFormData('vaRequestStep1').issuerId;
    }

    $scope.next = function () {
      $scope.submitted = true;
      let virtualRequestId = null;
      if (requestStatus === 'edit') {
        virtualRequestId = virtualReqId;
      }
      let acknowledgeObj = {
        meetingId: $scope.meetingId,
        shareholderId: $sessionStorage.groupId,
        loginUserId: $sessionStorage.userId,
        phoneNumber: $scope.phoneNumber,
        phoneCountryCode: parseInt($scope.phoneCountry.dialCode, 10),
        virtualAttendanceRequestId: virtualRequestId,
      };
      shareHolderService
        .acknowledgeVirtualParticipationRequest(acknowledgeObj)
        .then(
          function (response) {
            $scope.submitted = false;
            vaRequestStateService.setFormData('vaRequestStep2', {
              phoneCountryCode: { dialCode: response.data.phoneCountryCode },
              phoneNumber: $scope.phoneNumber,
              countryRepeat: { dialCode: response.data.phoneCountryCode },
              phoneNumberRepeat: $scope.phoneNumber,
              otpRef: response.data.otpRef,
            });

            // Subject to language change, hence always load on next button click
            getIssuerPolicyUrl(issuerId, function () {
              $scope.showConditionsModal = true;
            });
          },
          function (error) {
            $scope.submitted = false;
            if (error.data.errors) {
              $scope.errorCode = error.data.errors[0].errorCode;
              $scope.errorMsg = error.data.errors[0].errorMessage;
            } else {
              $scope.errorMsg = error.errorMessage;
            }
          }
        );
    };

    $scope.navigateToOtp = function () {
      let selectedIndex = 1;
      if (requestStatus === 'edit') {
        selectedIndex = 0;
      }

      $state.go('vaRequestStep3', {
        selectedIndex: selectedIndex,
      });
    };

    // Below function is to retain mobile number in session storage
    $scope.back = function () {
      vaRequestStateService.setFormData('vaRequestStep2', {
        phoneCountryCode: $scope.phoneCountry,
        phoneNumber: $scope.phoneNumber,
        phoneNumberRepeat: $scope.phoneNumberRepeat,
        countryRepeat: $scope.countryRepeat,
      });
      if (requestStatus === 'edit') {
        $state.go('editVaRequestStep1', {
          fromPageStep2: 'vaRequestStep2',
        });
      } else {
        $state.go('vaRequestStep1', {
          fromPageStep2: 'vaRequestStep2',
        });
      }
    };

    // Below function is to navigate my events or meeting page
    $scope.cancel = function () {
      if (requestStatus === 'edit') {
        $state.go('shMyVirtualParticipation', {
          selectedIndex: 0,
        });
      } else {
        if ($sessionStorage.usertype === 'SP') {
          $state.go('gmList', {
            selectedIndex: 0,
          });
        } else {
          $state.go('shGeneralMeetings', {
            selectedIndex: 1,
          });
        }
      }
    };

    $scope.isValidPhoneNumber = function () {
      if ($scope.phoneCountry.dialCode == '31') {
        // the starting 0 in dutch mobile telephone numbers must be omitted
        let regNlMob = new RegExp(/^((?!0))(\d{9})$/);
        if (!regNlMob.test($scope.phoneNumber)) {
          return false;
        }
      }
      return true;
    };

    $scope.canContinue = function () {
      return (
        $scope.isValidPhoneNumber() &&
        $scope.phoneNumber == $scope.phoneNumberRepeat &&
        $scope.phoneCountry.dialCode == $scope.countryRepeat.dialCode &&
        !$scope.submitted
      );
    };

    function getIssuerPolicyUrl(issuerId, onSuccess) {
      issuerPreferencesService
        .policy(issuerId)
        .get()
        .$promise.then(
          function (policyPref) {
            $scope.issuerPolicyUrl = policyPref.value;
            $scope.hasPolicyLink = true;
            if (onSuccess !== undefined) {
              onSuccess();
            }
          },
          function (error) {
            if (error.status === 404) {
              $scope.hasPolicyLink = false;
              // if no condition url is set, let check pass and proceed
              if (onSuccess !== undefined) {
                onSuccess();
              }
            }
          }
        );
    }

    init();
  },
]);
