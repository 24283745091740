/**
 * @ngdoc controller
 * @name generalMeetingHomeModule.BulkUploadController
 * @description This controller is used for bulk upload module. There is a
 *              functionality to upload bulk records in few of the screens.
 * @requires AuditTrailService is the service which is being used to interact
 *           with REST
 * @requires $scope for storing information in scope
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage and $stateParams to store/pass data from/to
 *           session/state
 * @requires fileUploadSvc directive for file upload
 */
'use strict';
angular.module('dwtQrfBulkUploadModule').controller('DwtBulkUploadController', [
  '$scope',
  '$sessionStorage',
  '$stateParams',
  '$log',
  '$filter',
  '$timeout',
  'userGroupService',
  'QrfBulkUploadService',
  'dwtFileDownloadSvc',
  function (
    $scope,
    $sessionStorage,
    $stateParams,
    $log,
    $filter,
    $timeout,
    userGroupService,
    QrfBulkUploadService,
    dwtFileDownloadSvc
  ) {
    $scope.bulkUpload = 'bulkUplaod';
    $scope.startProgressBar = false;
    $scope.gmId = $stateParams.eventId;
    $scope.intermediaryId = $sessionStorage.groupId;
    $scope.userId = $sessionStorage.userId;
    $scope.issuerId = $stateParams.issuerId;
    $scope.issuerName = $stateParams.issuerName;
    $scope.eventName = $stateParams.eventName;
    $scope.isin = $stateParams.isin;
    $scope.eventId = $stateParams.eventId;
    $scope.shareholderId = $stateParams.shareholderId;
    $scope.dwtPerSecurity = $stateParams.dwtPerSecurity;
    $scope.extensionReqFlagForQRF = $stateParams.extensionReqFlagForQRF;
    $scope.extensionReqFlagForPE = $stateParams.extensionReqFlagForPE;
    $scope.qrfEndDate = $stateParams.qrfEndDate;
    $scope.peFlag = $stateParams.peFlag;
    $scope.qrfFlag = $stateParams.qrfFlag;
    $scope.extReqFlag = $stateParams.extReqFlag;
    $scope.eventStatus = $stateParams.eventStatus;
    $scope.flag = $stateParams.flag;
    $scope.taxReclaimIndexId = $sessionStorage.taxReclaimIndexId;
    $scope.shareClassName = $stateParams.shareClassName;
    $scope.shareClassId = $stateParams.shareClassId;
    //$scope.actionTaken ='dwtQrfBulkAction';
    $scope.dwtQrfBulkAction = 'dwtQrfBulkAction';
    $scope.claimStateParms = {
      selectedIndex: $scope.taxReclaimIndexId,
      eventId: $scope.eventId,
      flag: $scope.flag,
      issuerId: $scope.issuerId,
      issuerName: $scope.issuerName,
      eventStatus: $scope.eventStatus,
      claimsList: $scope.claimsArray,
      eventName: $scope.eventName,
      dwtPerSecurity: $scope.dwtPerSecurity,
      qrfEndDate: $scope.qrfEndDate,
      peFlag: $scope.peFlag,
      qrfFlag: $scope.qrfFlag,
      extReqFlag: $scope.extReqFlag,
      extensionReqFlagForQRF: $scope.extensionReqFlagForQRF,
      extensionReqFlagForPE: $scope.extensionReqFlagForPE,
      navFrom: 'qrfClaim',
      breadCrumbDetails: [$scope.eventName],
    };
    $scope.disableSavebtn = true;
    var readFile = this;
    readFile.documents = [
      {
        fileVo: [
          {
            file: '',
            fileName: '',
          },
        ],
      },
    ];
    // Below is the function used to fetch issuer logo
    if (null !== $scope.issuerId) {
      userGroupService.getIssuerGroupLogo($scope.issuerId).then(
        function (response) {
          $scope.issuerGroupDetails = response.data;
        },
        function (error) {
          $log.debug(error);
        }
      );
    }
    // Below is the function used to fetch intermediary name and payment Reference Number
    QrfBulkUploadService.getBulkQrfLandingPageData(
      $scope.intermediaryId,
      $scope.eventId
    ).then(
      function (response) {
        $scope.intermediaryName = response.data.intermediaryName;
        $scope.paymentRefNumber = response.data.paymentRefNumber;
      },
      function (error) {
        $log.debug(error);
      }
    );
    /* download registration form starts here */
    $scope.downloadTemplate = function () {
      var downloadUrl = 'v1/bulkupload';
      var a = document.createElement('a');
      document.body.appendChild(a);
      dwtFileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            var fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          downloadUrl = '';
        },
        function (error) {
          $log.debug(error);
        }
      );
    };

    $scope.onFileSelect = function (e) {
      $scope.fileStatus = '';
      var filesList = e.files;
      if (filesList[0].size > 5242880) {
        $scope.error = true;
        $scope.errorMsg =
          'This file size is: ' +
          (filesList[0].size / 1024 / 1024).toFixed(2) +
          ' MB. Please upload a file less than 5 MB.';
        return;
      } else {
        var fd = new FormData();
        fd.append('file', filesList[0]);
        QrfBulkUploadService.uploadQRFBulkUpload(fd).then(
          function (response) {
            if (response.status === 200) {
              $scope.error = false;
              $scope.saveDisabled = false;
              $scope.filePath = response.data.filePath;
              $scope.fileStatus = response.data.fileStatus;
              $scope.fileName = response.data.fileName;
              $scope.saveStatus = '';
              $scope.disableSavebtn = false;
              e.value = null;
            } else {
              $scope.error = true;
              $scope.saveDisabled = true;
              $scope.fileName = response.data.fileName;
              $scope.errorMsg = response.data.fileStatus;
              $log.debug('error');
              $scope.saveStatus = '';
              $scope.disableSavebtn = true;
            }
          },
          function (error) {
            $scope.error = true;
            $scope.saveDisabled = true;
            $scope.fileName = '';
            if (error.fileStatus != undefined) {
              $scope.errorMsg = error.fileStatus;
            } else {
              $scope.errorMsg =
                'Something went wrong while uploading excel, please try again';
            }
            $log.debug('error');
            $scope.saveStatus = '';
            $scope.disableSavebtn = true;
          }
        );
      }
    };
    /* download registration ends starts here */
    $scope.bulkFileUploadFunction = function () {
      $scope.saveQrfBulkData = {
        userId: $scope.userId,
        filePath: $scope.filePath,
        fileName: $scope.fileName,
        intermediaryId: $scope.intermediaryId,
        eventId: $stateParams.eventId,
        issuerId: $scope.issuerId,
        issuerName: $scope.issuerName,
        eventName: $scope.eventName,
        paymentRefNo: $scope.paymentRefNumber,
        intermediaryName: $scope.intermediaryName,
      };
      QrfBulkUploadService.saveBulkUploadQrf(
        $scope.saveQrfBulkData,
        'secureToken'
      ).then(
        function (response) {
          $scope.startProgressBar = true;
          var fileNameStr = response.config.data.fileName;
          var fileNameExt = fileNameStr.replace('.', '&middot');
          $scope.fileName = fileNameExt;
          $scope.serviceurl =
            'v1/jobs/' + response.data.jobExecutionId + '/' + $scope.fileName;
          $scope.saveDisabled = true;
          $scope.disableSavebtn = false;
          document.getElementById('btnAddUser').blur();
        },
        function (error) {
          $scope.disableSavebtn = false;
          $scope.errorHere = true;
          $scope.errorMsg = error.message;
          $timeout(function () {
            $scope.errorHere = false;
          }, 3000);
        }
      );
    };
  },
]);
