/**
 * @ngdoc controller
 */
angular
  .module('dwtCreatePEClaimModule')
  .controller('CreateDwtPERequestController', [
    '$scope',
    '$sessionStorage',
    '$stateParams',
    'CreateDwtPEReqService',
    'DwtCreatePE',
    '$log',
    '$filter',
    '$timeout',
    '$state',
    'userGroupService',
    '$anchorScroll',
    '$location',
    function (
      $scope,
      $sessionStorage,
      $stateParams,
      CreateDwtPEReqService,
      DwtCreatePE,
      $log,
      $filter,
      $timeout,
      $state,
      userGroupService,
      $anchorScroll,
      $location
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.peClaimAgree = false;
      $scope.taxReclaimIndexId = $sessionStorage.taxReclaimIndexId;
      $scope.eventId = $stateParams.eventId;
      $scope.eventName = $stateParams.eventName;
      $scope.eventStatus = $stateParams.eventStatus;
      $scope.flag = $stateParams.flag;
      $scope.issuerId = $stateParams.issuerId;
      $scope.issuerName = $stateParams.issuerName;
      $scope.dwtPerSecurity = $stateParams.dwtPerSecurity;
      $scope.qrfEndDate = $stateParams.qrfEndDate;
      $scope.peFlag = $stateParams.peFlag;
      $scope.qrfFlag = $stateParams.qrfFlag;
      $scope.extReqFlag = $stateParams.extReqFlag;
      $scope.shareholderId = $stateParams.shareholderId;
      $scope.shareClassName = $stateParams.shareClassName;
      $scope.extensionReqFlagForQRF = $stateParams.extensionReqFlagForQRF;
      $scope.extensionReqFlagForPE = $stateParams.extensionReqFlagForPE;
      $scope.isin = $stateParams.isin;
      $scope.languageCode = 'EN';
      $scope.rsinMandatory = false;
      // Below is the function used to fetch issuer logo
      userGroupService.getIssuerGroupLogo($scope.issuerId).then(
        function (response) {
          $scope.issuerGroupDetails = response.data;
        },
        function (error) {
          $log.debug(error);
        }
      );

      // The name of the country that the user is creating or editing
      // It is overwritten when the pe claim is fetched from the backend (on editing a PE claim)
      $scope.peClaimCountryName = null;

      userGroupService
        .getCountryList($scope.languageCode)
        .query()
        .$promise.then(
          function (countries) {
            $scope.countryList = countries.countries;
            if ($scope.peClaimCountryName) {
              $scope.selectedCountry = $filter('filter')(
                $scope.countryList,
                { countryName: $scope.peClaimCountryName },
                true
              );
              $scope.createPEReqModal.country =
                $scope.selectedCountry[0].isoCountryCode;
            } else {
              // default to NLD when creating a new claim
              $scope.selectedCountry = $filter('filter')(
                $scope.countryList,
                { isoCountryCode: 'NLD' },
                true
              );
            }

            $scope.evaluateRsinMandatory();
          },
          function (error) {
            $log.debug(error);
          }
        );

      //below function to get payment reference number
      if ($scope.eventId) {
        CreateDwtPEReqService.getPaymentRefrenceNumber(
          $sessionStorage.groupId,
          $scope.eventId
        ).then(
          function (data) {
            if (data.data.paymentRefNo === 'null') {
              $scope.createPEReqModal.paymentReference = '';
            } else {
              $scope.createPEReqModal.paymentReference = data.data.paymentRefNo;
            }
          },
          function (error) {
            $log.debug(error);
          }
        );
      }
      //Below is the function call from HTML to send the extension request
      $scope.successCallback = function (response) {
        $scope.loading = false;
        if ($scope.createPEReqModal.searchclickedflag) {
          $scope.createPEReqModal.rsinNumber = response.data.rsinNumber;
          $scope.createPEReqModal.tin = response.data.tin;
          $scope.createPEReqModal.securityAccountNo =
            response.data.securityAccountNo;
          $scope.createPEReqModal.firstName = response.data.firstName;
          if (
            response.data.firstName === '' ||
            response.data.firstName === null ||
            response.data.firstName === undefined
          ) {
            $scope.createPEReqModal.lastName = response.data.lastName;
          } else {
            $scope.createPEReqModal.lastName =
              response.data.firstName + ' ' + response.data.lastName;
          }
          $scope.createPEReqModal.postalCode = response.data.postalCode;
          $scope.createPEReqModal.email = response.data.email;
          $scope.createPEReqModal.city = response.data.city;
          $scope.createPEReqModal.country = response.data.country;
          $scope.createPEReqModal.phoneNumber = response.data.phoneNumber;
          $scope.createPEReqModal.address = response.data.address;
          $scope.createPEReqModal.paymentReference =
            response.data.paymentReference;
          $scope.createPEReqModal.fundManager = response.data.fundManager;
          $scope.createPEclaimSuccess = '';
          $scope.createPEclaimErrRes = '';
          $scope.createPESearchclaimErrRes = '';
        } else if ($scope.createPEReqModal.onloadFlag) {
          $scope.createPEReqModal.securityAccountNo =
            response.data.claimsVO.securityAccNumber;
          $scope.createPEReqModal.rsinNumber = response.data.rsin;
          $scope.createPEReqModal.tin = response.data.tin;
          $scope.createPEReqModal.lastName =
            response.data.claimsVO.beneficalOwnerName;
          $scope.createPEReqModal.postalCode = response.data.postalCode;
          $scope.createPEReqModal.email = response.data.email;
          $scope.createPEReqModal.city = response.data.city;
          $scope.createPEReqModal.intermediaryId =
            response.data.claimsVO.intermediaryId;
          $scope.createPEReqModal.phoneNumber = response.data.phoneNo;
          $scope.createPEReqModal.address = response.data.address;
          $scope.createPEReqModal.paymentReference =
            response.data.paymentReference;
          $scope.createPEReqModal.position = parseInt(
            response.data.claimsVO.position,
            10
          );
          $scope.createPEReqModal.fundManager =
            response.data.claimsVO.fundManager;
          $scope.shareClassName = response.data.claimsVO.shareclassName;
          $scope.createPEclaimSuccess = '';
          $scope.createPEclaimErrRes = '';
          $scope.createPESearchclaimErrRes = '';

          $scope.peClaimCountryName = response.data.country;
          if ($scope.countryList) {
            $scope.selectedCountry = $filter('filter')(
              $scope.countryList,
              { countryName: response.data.country },
              true
            );
            $scope.createPEReqModal.country =
              $scope.selectedCountry[0].isoCountryCode;
            $scope.evaluateRsinMandatory();
          }
        } else {
          $scope.createPEclaimSuccess = response.data.message;
          $scope.createPEclaimErrRes = '';
          $scope.createPESearchclaimErrRes = '';
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
            $state.go('dwtDetails', {
              selectedIndex: $scope.taxReclaimIndexId,
              eventId: $scope.eventId,
              flag: $scope.flag,
              issuerId: $scope.issuerId,
              issuerName: $scope.issuerName,
              eventStatus: $scope.eventStatus,
              claimsList: $scope.claimsArray,
              eventName: $scope.eventName,
              dwtPerSecurity: $scope.dwtPerSecurity,
              qrfEndDate: $scope.qrfEndDate,
              peFlag: $scope.peFlag,
              qrfFlag: $scope.qrfFlag,
              extensionReqFlagForQRF: $scope.extensionReqFlagForQRF,
              extensionReqFlagForPE: $scope.extensionReqFlagForPE,
              extReqFlag: $scope.extReqFlag,
              navFrom: 'peClaim',
              breadCrumbDetails: [$scope.eventName],
            });
          }, 2000);
        }
      };
      $scope.errorCallback = function (error) {
        $scope.loading = false;
        if (!$scope.createPEReqModal.searchclickedflag) {
          if (error.data.message !== null && undefined !== error.data.message) {
            $scope.createPEclaimErrRes = error.data.message;
          } else {
            $scope.createPEclaimErrRes = error.data.errors[0].errorMessage;
          }
          $scope.createPEclaimSuccess = '';
          $scope.createPESearchclaimErrRes = '';
        } else {
          $scope.createPESearchclaimErrRes =
            'No existing claim found for this input!';
        }
      };
      $scope.createDwtPERequestLoading = function () {
        $scope.loading = true;
        $location.hash('peClaim');
        $anchorScroll();
      };
      $scope.changeCountry = function (selectedCountry) {
        $scope.createPEReqModal.country = selectedCountry.isoCountryCode;
        $scope.peClaimCountryName = selectedCountry.countryName;
        $scope.evaluateRsinMandatory();
      };
      $scope.createPEReqModal = new DwtCreatePE(
        $stateParams,
        $stateParams.intermediaryId
      );
      if ($stateParams.peClaimId) {
        $scope.peClaimAgree = true;
        $scope.createPEReqModal.viewDWTPERequest(
          $scope.successCallback,
          $scope.errorCallback
        );
      }
      $scope.cancelClaimModal = function () {
        $scope.modalHeader = 'Cancel claim';
        $scope.modalBody = 'Are you sure you wanted to cancel?';
        $scope.modalFooter = '';
        $scope.modalLeftButton = 'Yes';
        $scope.modalRightButton = 'No';
      };
      $scope.navToOverviewPage = function () {
        $state.go('taxReclaimHome', {
          selectedIndex: $scope.taxReclaimIndexId,
        });
      };

      $scope.checkPosition = function () {
        if ($scope.createPEReqModal.position === 0) {
          $scope.peClaimForm.position.$setDirty();
          $scope.peClaimForm.position.$setValidity('pattern', false);
        }
      };
      $scope.evaluateRsinMandatory = function () {
        if ($scope.selectedCountry[0].isoCountryCode === 'NLD') {
          $scope.rsinMandatory = true;
        } else {
          $scope.rsinMandatory = false;
        }
      };
    },
  ]);
