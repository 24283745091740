/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.GeneralMeetingController
 * @description This controller deals with fetching general meeting list
 *              and functionalities of options available for particular general meeting
 * @requires generalMeetingService, ExtensionRequestService, voteCollectorService, GeneralMeetingDataService and AgentService are the
 *           services which are being used to interact with REST
 * @requires ModelWindowService for modal window
 * @requires $scope for storing information in scope
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for fetching logged in user details from session storage
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 */
angular
  .module('generalMeetingHomeModule')
  .controller('GeneralMeetingController', [
    '$scope',
    '$sessionStorage',
    '$state',
    'GeneralMeetingDataService',
    'voteCollectorService',
    '$log',
    'generalMeetingService',
    'ExtensionRequestService',
    'ModelWindowService',
    'AgentService',
    '$filter',
    '$timeout',
    'fileDownloadSvc',
    function (
      $scope,
      $sessionStorage,
      $state,
      GeneralMeetingDataService,
      voteCollectorService,
      $log,
      generalMeetingService,
      ExtensionRequestService,
      ModelWindowService,
      AgentService,
      $filter,
      $timeout,
      fileDownloadSvc
    ) {
      $scope.userType = $sessionStorage.usertype;
      $scope.agentName = $sessionStorage.userName;
      $scope.userId = $sessionStorage.userId;
      $scope.userName = $sessionStorage.userName;
      $scope.groupId = $sessionStorage.groupId;
      $sessionStorage.isAdmin = $sessionStorage.isAdminGM;
      $scope.isAdmin = $sessionStorage.isAdminGM;
      $sessionStorage.gmShareclassList = null;
      $scope.langCode = 'EN';
      $scope.loading = true;
      $scope.generalMeetingData = [];
      $scope.issuerName = '';
      $scope.modalLeftButton = $filter('translate')('label_confirm');
      $scope.modalRightButton = $filter('translate')('label_cancel');
      $scope.issuerSearch = {};
      $scope.extnRequestModelConfig = {};
      $scope.inviteShareplanModelConfig = {};
      $scope.voteConfirmAlertConfig = {};
      if ($scope.userType === 'VC') {
        var workStation = $scope.userType;
        var screenName = 'VC Home Page';
        var screenLocation = 'Body';
        var langCode = 'EN';
        //Below service call is to interact with REST and get static text for vote collector WS
        voteCollectorService
          .getVCStaticText(workStation, screenName, screenLocation, langCode)
          .query()
          .$promise.then(
            function (data) {
              $scope.loading = false;
              $scope.landingPageText = data.templateList[0].templateText;
              $log.debug(data);
            },
            function (error) {
              $log.debug(error);
            }
          );
      }
      //Below function call is to get general meeting list from back end
      $scope.getGeneralMeetingData = function () {
        GeneralMeetingDataService.getGeneralMeetingData($scope.userType)
          .query()
          .$promise.then(
            function (data) {
              $scope.generalMeetingData = data;
              $scope.loading = false;
            },
            function (error) {
              $scope.loading = false;
              $log.debug(error);
            }
          );
      };
      // General Meeting Option click callback
      $scope.optionClickCallback = function (
        workStationId,
        event,
        generalMeeting
      ) {
        var issuerName = generalMeeting.issuerName;
        switch (event) {
        case 'edit':
          $state.go('editGM', {
            selectedIndex: 1,
            issuerId: generalMeeting.issuerId,
            meetingStatus: generalMeeting.generalMeetingId,
            tabId: null,
            breadCrumbDetails: [generalMeeting.meetingName],
          });
          break;
        case 'view':
          $state.go('generalDetailsView', {
            meetingId: generalMeeting.generalMeetingId,
            issuerId: generalMeeting.issuerId,
            issuerName: generalMeeting.issuerName,
            gmDateTime: generalMeeting.generalMeetingDateStr,
            status: generalMeeting.status,
            intermediaryDate: generalMeeting.intermediaryDeadlineDate,
            sendRegViEuroclear: generalMeeting.sendRegViEuroclear,
            flag: 1,
            breadCrumbDetails: [generalMeeting.meetingName]
          });
          break;
        case 'delete':
          $scope.deleteGmID = generalMeeting.generalMeetingId;
          $scope.modalBody = $filter('translate')('areSureDelete_modal');
          $scope.status = 'D';
          break;
        case 'cancel':
          $scope.deleteGmID = generalMeeting.generalMeetingId;
          $scope.modalBody = $filter('translate')('areSureCancel_modal');
          $scope.status = 'C';
          break;
        case 'notification':
          $state.go('notification', {
            selectedIndex: 1,
            meetingId: generalMeeting.generalMeetingId,
            breadCrumbDetails: [generalMeeting.meetingName],
            issuerId: generalMeeting.issuerId,
          });
          break;
        case 'share':
          AgentService.importDocFiles(generalMeeting.generalMeetingId).then(
            function (response) {
              $log.debug(response);
              $state.go('inviteSharePlan', {
                selectedIndex: 1,
                meetingStatus: generalMeeting.generalMeetingId,
                issuerId: generalMeeting.issuerId,
                gmDate: generalMeeting.generalMeetingDateStr,
                issuerName: generalMeeting.issuerName,
                documents: response.data.gmDocumentVO,
                emailSubject: response.data.emailVO.emailSubject,
                emailBody: response.data.emailVO.emailBody,
                breadCrumbDetails: [generalMeeting.meetingName],
              });
            },
            function (error) {
              $log.debug(error);
              if (error.status === 422) {
                $scope.inviteShareplanModelConfig.modalBody =
                    error.data.errors[0].errorMessage;
                ModelWindowService.showModelWindow('inviteSharePlanAlert');
              }
            }
          );
          break;
        case 'vote':
          $sessionStorage.intSelectedButtonIndex = null;
          $state.go('votingInfo', {
            type: 'vote',
            gmId: generalMeeting.generalMeetingId,
            issuerId: generalMeeting.issuerId,
            intermediaryDateTimeStamp:
                generalMeeting.intermediaryDeadlineDate,
            intermediaryDate: generalMeeting.intermediaryDeadlineDateStr,
            intermediaryAccessDate: generalMeeting.intermediaryAccessDate,
            intermediaryAccessDateStr:
                generalMeeting.intermediaryAccessDateStr,
            newEndDateTimeStamp: generalMeeting.newEndDateTime,
            issuerName: generalMeeting.issuerName,
            gmDate: generalMeeting.generalMeetingDateStr,
            allExtnReqRejected: generalMeeting.allExtReqsRejected,
            langCode: $scope.langCode,
            gmStatus: generalMeeting.status,
            breadCrumbDetails: [generalMeeting.meetingName],
          });
          break;
        case 'attend':
          $state.go('attendanceReqMeetingInfo', {
            type: 'attend',
            gmId: generalMeeting.generalMeetingId,
            issuerId: generalMeeting.issuerId,
            issuerName: generalMeeting.issuerName,
            intermediaryDateTimeStamp:
                generalMeeting.intermediaryDeadlineDate,
            intermediaryDate: generalMeeting.intermediaryDeadlineDateStr,
            intermediaryAccessDate: generalMeeting.intermediaryAccessDate,
            intermediaryAccessDateStr:
                generalMeeting.intermediaryAccessDateStr,
            newEndDateTimeStamp: generalMeeting.newEndDateTime,
            gmDate: generalMeeting.generalMeetingDateStr,
            allExtnReqRejected: generalMeeting.allExtReqsRejected,
            langCode: $scope.langCode,
            gmStatus: generalMeeting.status,
            breadCrumbDetails: [generalMeeting.meetingName],
          });
          break;
        case 'shareholderEmail':
          $state.go('shareholderEmail', {
            meetingId: generalMeeting.generalMeetingId,
            issuerName: issuerName,
            gmDateTime: generalMeeting.generalMeetingDateStr,
            gmDateTimeStamp: generalMeeting.generalMeetingDate,
            meetingType: generalMeeting.meetingType,
            breadCrumbDetails: [generalMeeting.meetingName],
          });
          break;
        case 'createExtensionRequest':
          $scope.extReqRejected = true;
          $state.go('createExtensionRequest', {
            intermediaryDeadlineDateStr:
                generalMeeting.intermediaryDeadlineDateStr,
            gmName: generalMeeting.meetingName,
            gmDateTime: generalMeeting.generalMeetingDateStr,
            generalMeetingId: generalMeeting.generalMeetingId,
            extReqRejected: $scope.extReqRejected,
            userType: $scope.userType,
            issuerId: generalMeeting.issuerId,
            gmStatus: generalMeeting.status,
            breadCrumbDetails: [generalMeeting.meetingName],
          });
          break;
        case 'createIntermediaryExtensionRequest':
          $scope.extReqRejected = true;
          $state.go('createIntermediaryExtensionRequest', {
            intermediaryDeadlineDateStr:
                generalMeeting.intermediaryDeadlineDateStr,
            gmName: generalMeeting.meetingName,
            allExtnReqRejected: generalMeeting.allExtReqsRejected,
            gmDateTime: generalMeeting.generalMeetingDateStr,
            generalMeetingId: generalMeeting.generalMeetingId,
            extReqRejected: $scope.extReqRejected,
            issuerId: generalMeeting.issuerId,
            userType: $scope.userType,
            gmStatus: generalMeeting.status,
            breadCrumbDetails: [generalMeeting.meetingName],
            issuerName: generalMeeting.issuerName,
          });
          break;
        case 'createIssuerExtensionRequest':
          $scope.extReqRejected = true;
          $state.go('createIssuerExtensionRequest', {
            intermediaryDeadlineDateStr:
                generalMeeting.intermediaryDeadlineDateStr,
            gmName: generalMeeting.meetingName,
            gmDateTime: generalMeeting.generalMeetingDateStr,
            generalMeetingId: generalMeeting.generalMeetingId,
            extReqRejected: $scope.extReqRejected,
            issuerId: generalMeeting.issuerId,
            userType: $scope.userType,
            gmStatus: generalMeeting.status,
            breadCrumbDetails: [generalMeeting.meetingName],
          });
          break;
        case 'downloadIntermediaryInstructions':
          /* download Intermediary instructions certificate starts here */
          $scope.fileloading = true;
          var pathList = window.location.pathname.split('/');
          if (pathList[1] === 'cbp') {
            $scope.downloadTemplateFile =
                window.location.origin +
                '/' +
                pathList[1] +
                '/v1/generalmeetings/intermediary/' +
                generalMeeting.generalMeetingId +
                '/' +
                $sessionStorage.userId +
                '/' +
                $sessionStorage.usertype +
                '/registrations/reports/excel';
          } else {
            $scope.downloadTemplateFile =
                window.location.origin +
                '/v1/generalmeetings/intermediary/' +
                generalMeeting.generalMeetingId +
                '/' +
                $sessionStorage.userId +
                '/' +
                $sessionStorage.usertype +
                '/registrations/reports/excel';
          }
          //window.open($scope.downloadTemplateFile, '_blank','');
          var a = document.createElement('a');
          document.body.appendChild(a);
          fileDownloadSvc.downloadFile($scope.downloadTemplateFile).then(
            function (response) {
              let fileVO = response;
              if (window.navigator.msSaveOrOpenBlob) {
                a.onclick = function () {
                  window.navigator.msSaveOrOpenBlob(
                    fileVO.file,
                    fileVO.fileName
                  );
                };
                a.click();
              } else {
                var fileURL = URL.createObjectURL(fileVO.file);
                a.href = fileURL;
                a.download = fileVO.fileName;
                a.click();
              }
              $scope.downloadTemplateFile = '';
              $scope.fileloading = false;
            },
            function (error) {
              $log.debug(error);
              $scope.fileloading = false;
            }
          );
          //    ModelWindowService.showModelWindow('downloadCertificateIntermediary');

          break;
        case 'downloadCertificate':
          /* download Intermediary registration certificate starts here */
          $scope.downloadIntCertificate = function () {
            $scope.fileloading = true;
            var pathList = window.location.pathname.split('/');
            if (pathList[1] === 'cbp') {
              $scope.downloadTemplateFile =
                  window.location.origin +
                  '/' +
                  pathList[1] +
                  '/v1/generalmeetings/intermediary/' +
                  generalMeeting.generalMeetingId +
                  '/attendances/' +
                  $sessionStorage.groupId +
                  '/' +
                  $sessionStorage.userId +
                  '/' +
                  $sessionStorage.usertype +
                  '/';
              $sessionStorage.languageSelected;
            } else {
              $scope.downloadTemplateFile =
                  window.location.origin +
                  '/v1/generalmeetings/intermediary/' +
                  generalMeeting.generalMeetingId +
                  '/attendances/' +
                  $sessionStorage.groupId +
                  '/' +
                  $sessionStorage.userId +
                  '/' +
                  $sessionStorage.usertype +
                  '/' +
                  $sessionStorage.languageSelected;
            }
            //window.open($scope.downloadTemplateFile, '_blank','');

            var a = document.createElement('a');
            document.body.appendChild(a);
            fileDownloadSvc.downloadFile($scope.downloadTemplateFile).then(
              function (response) {
                let fileVO = response;
                if (window.navigator.msSaveOrOpenBlob) {
                  a.onclick = function () {
                    window.navigator.msSaveOrOpenBlob(
                      fileVO.file,
                      fileVO.fileName
                    );
                  };
                  a.click();
                } else {
                  var fileURL = URL.createObjectURL(fileVO.file);
                  a.href = fileURL;
                  a.download = fileVO.fileName;
                  a.click();
                }
                $scope.downloadTemplateFile = '';
                $scope.fileloading = false;
              },
              function (error) {
                $log.debug(error);
                $scope.fileloading = false;
              }
            );
          };
          ModelWindowService.showModelWindow(
            'downloadCertificateIntermediary'
          );
          break;
        case 'rejectVC':
          $scope.rejectGMId = generalMeeting.generalMeetingId;
          $scope.gmName = generalMeeting.meetingName;
          $scope.gmIssuerName = generalMeeting.issuerName;
          $scope.decision = 'no';
          $scope.modalBody = $filter('translate')('areSureDecline_modal');
          $scope.modalLeftButton = $filter('translate')('label_confirm');
          $scope.modalRightButton = $filter('translate')('label_cancel');
          break;
        case 'approveVC':
          $scope.rejectGMId = generalMeeting.generalMeetingId;
          $scope.gmName = generalMeeting.meetingName;
          $scope.gmIssuerName = generalMeeting.issuerName;
          $scope.decision = 'yes';
          $scope.modalBody = $filter('translate')('areSureAccept_modalVc');
          $scope.modalLeftButton = $filter('translate')('label_confirm');
          $scope.modalRightButton = $filter('translate')('label_cancel');
          break;
        case 'sendEmailEuroclear':
        case 'reSendEmailEuroclear':
          $sessionStorage.currentTab = 'sendEmailEuroclear';
          $state.go('generalDetailsView', {
            meetingId: generalMeeting.generalMeetingId,
            issuerId: generalMeeting.issuerId,
            issuerName: generalMeeting.issuerName,
            gmDateTime: generalMeeting.generalMeetingDateStr,
            status: generalMeeting.status,
            sendRegViEuroclear: generalMeeting.sendRegViEuroclear,
            intermediaryDate: generalMeeting.intermediaryDeadlineDate,
            breadCrumbDetails: [generalMeeting.meetingName],
          });
          break;
        case 'viewVirtualParticiation':
          $state.go('virtualParticipationView', {
            issuerId: generalMeeting.issuerId,
            issuerName: generalMeeting.issuerName,
            gmId: generalMeeting.generalMeetingId,
            generalMeetingDateMillis: generalMeeting.generalMeetingDateMiilis,
            intermediaryDeadlineDateMillis:
                generalMeeting.intermediaryDeadlineDateMiilis,
            intermediaryAccessDateMillis:
                generalMeeting.intermediaryAccessDateMiilis,
            timeZone: generalMeeting.timeZone,
            breadCrumbDetails: [generalMeeting.meetingName],
          });
          break;
        default:
          break;
        }
      };
      //Below function calls from HTML to approve/reject from vote collector WS for particular general meeting
      $scope.gmVCApproveReject = function (
        meetingId,
        decision,
        meetingName,
        issuerName
      ) {
        voteCollectorService.gmVCApproveReject(
          meetingId,
          decision,
          'secureToken',
          function (response) {
            $log.debug(response);
            var actionTaken = $filter('translate')('label_acceptedVc');
            if (decision === 'no') {
              actionTaken = $filter('translate')('label_declinedVc');
            }
            $scope.getGeneralMeetingData();
            $scope.actionSuccess = true;
            $scope.actionSuccessMessage =
              $filter('translate')('label_youHave') +
              actionTaken +
              $filter('translate')('label_roleOfVC') +
              meetingName +
              $filter('translate')('label_ofIssuer') +
              issuerName +
              '.';
            $timeout(function () {
              $scope.actionSuccess = false;
            }, 8000);
          }
        );
      };
      //Below function is to search general meeting based on the issuer name entered in search box
      $scope.onIssuerSearchChange = function () {
        $scope.issuerSearch.edit = true;
      };
      //Below function is for deleting general meeting
      $scope.deleteGeneralMeeting = function (meetingId, status) {
        generalMeetingService.deleteGeneralMeeting(
          meetingId,
          status,
          'secureToken',
          function () {
            $scope.getGeneralMeetingData();
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      // call the function for first time
      $scope.getGeneralMeetingData();
    },
  ]);
