/**
 * @ngdoc AgentGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with agent group.
 * @requires agentGroup is a variable passed from userMaintenanceModule where
 *           there is a service call to userGroupService
 * @requires $scope for storing information in scope
 */

angular
  .module('userMaintenanceDwtModule')
  .controller(
    'AgentGroupDwtController',
    [
      '$scope',
      '$log',
      'userGroupService',
      function($scope, $log, userGroupService) {
        userGroupService.getAgentGroup('1', 'DWT', 'EN').query().$promise
          .then(function(data) {
            $scope.agentGroupIssuerDwt = data;
          }, function(error) {
            $log.debug(error);
          });

        userGroupService.getAgentGroup('1', 'DWTIN', 'EN')
          .query().$promise.then(function(data) {
            $scope.agentGroupIntermediaryDwt = data;
          }, function(error) {
            $log.debug(error);
          });
      }]);