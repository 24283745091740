/**
 * @ngdoc controller
 * @name loginModule.TokenController
 * @description This controller is to validate the token received for password reset
 * @requires TokenSvc is the service which is being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $window to get location of application
 * @requires $sessionStorage to fetch selected language from session storage
 * @requires $state for state routing
 */
'use strict';

angular.module('loginModule').controller(
  'TokenController',['$scope','$state', '$sessionStorage', '$log', '$timeout', '$window',
    'TokenSvc',
    function($scope, $state, $sessionStorage, $log, $timeout, $window,
      TokenSvc) {

      $log.debug('inside token cntrl');
      if ($sessionStorage.selectedLang === 'NL') {
        $scope.activeValue = 'NL';
      } else {
        $scope.activeValue = 'EN';
      }
      $sessionStorage.isPublicPage = true;
      $scope.resetToken = '';
      $scope.linkValidationProgress = false;
      $scope.isLinkValid = false;
      $log.debug('inside token cntrl 2');
      var resetToken = '';
      if ($window.location.search) {
        var queryList = $window.location.search.split('=');
        resetToken = (queryList.length === 2) ? queryList[1] : null;
      }
      $log.debug('resetToken', resetToken);
      if (resetToken) {
        $scope.linkValidationProgress = true;
        $scope.resetToken = resetToken;
        /**
             * Checking the token which is received in email is valid or not
             */
        TokenSvc.checkPasswordResetUrl($scope.resetToken).then(
          function(resp) {
            $scope.linkValidationProgress = false;
            $log.debug('resp', resp);
            /**
                       * If reset token is valid, please let him navigate to
                       * reset password page for entering new password
                       */
            if (resp && resp.data && resp.data.emailAddress) {
              $log.debug(resp.data);
              $scope.isLinkValid = true;
              $timeout(function() {
                $state.go('resetPassword', {
                  selectedIndex: 0,
                  passwordResetData: resp.data
                });
              }, 1000);
            }
            /**
                       * If reset token is invalid then show error that this
                       * link is expired or invalid
                       */
            else {

              $scope.isLinkValid = false;
            }
          }, function(err) {
            $log.debug(err);
            $scope.linkValidationProgress = false;
            $scope.isLinkValid = false;
          });
      } else {
        $scope.linkValidationProgress = false;
        $scope.isLinkValid = false;
      }

    }]);
