/**
 * @ngdoc controller
 * @name ContactDetailsController
 * @description This controller mainly deals with registration of new
 *              shareholder
 * @requires TokenSvc is the service which is being used to interact with REST
 * @requires $scope for storing information in scope
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $filter to translate messages from locale file
 */
'use strict';

angular.module('loginModule')
  .controller(
    'ContactDetailsController',
    [
      '$scope',
      'userGroupService',
      '$log',
      '$sessionStorage',
      '$stateParams',
      function($scope, userGroupService, $log, $sessionStorage,
        $stateParams) {
        $scope.userType = $stateParams.userType;
        if ('intermediary' === $scope.userType) {
          $scope.userType = 'IN';
        } else if ('agent' === $scope.userType) {
          $scope.userType = 'AG';
        } else if ('issuer' === $scope.userType) {
          $scope.userType = 'IS';
        } else if ('votecollector' === $scope.userType) {
          $scope.userType = 'VC';
        } else if ('shareholderlogin' === $scope.userType
                              || 'shareholder' === $scope.userType) {
          $scope.userType = 'SH';
        } else {
          $scope.userType = $sessionStorage.usertype;
        }
        userGroupService.getContactDetails($scope.userType)
          .query().$promise.then(function(data) {
            $scope.contactDetails = data;
          }, function(error) {
            $log.debug(error);
          });
      }]);
