/**
 * @ngdoc controller
 * @name userMaintenanceDwtModule.AddIssuerDwtController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with adding new issuer user.
 * @requires is the service which is being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 */
angular
  .module('userMaintenanceDwtModule')
  .controller('AddIssuerDwtController', [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$sessionStorage',
    '$timeout',
    'DwtIssuerUser',
    function (
      $scope,
      $log,
      $state,
      $stateParams,
      $sessionStorage,
      $timeout,
      DwtIssuerUser
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.loggedInId = $sessionStorage.userId;
      $scope.groupId = $stateParams.userGroupId;
      $scope.prodType = $sessionStorage.productType;
      $scope.callOnLoad = function (accType) {
        $scope.addIssuerUserDetails = new DwtIssuerUser(
          undefined,
          $scope.groupId,
          $sessionStorage.userId,
          $scope.loggedInId,
          accType,
          $scope.prodType
        );
      };
      $scope.successCallback = function (response) {
        if (201 === response.data.httpStatusCode) {
          $scope.success = true;
          $scope.message = response.data.message;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
            $state.go('userMaintenanceForDWT.issuerUserListDwt', {
              groupId: $scope.groupId,
            });
          }, 2000);
        } else {
          $scope.failure = true;
          $scope.errorMessage = response.data.message;
        }
      };
      $scope.errorCallback = function (error) {
        $scope.failure = true;
        if (error.data.errors.length > 0) {
          $scope.errorMessage = error.data.errors[0].errorMessage;
        } else {
          $scope.errorMessage = error.data;
        }
      };
      $scope.callOnLoad('U');
    },
  ]);
