/**
 * @ngdoc controller
 * @name userMaintenanceModule.AddIssuerGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with adding new issuer group.
 * @requires userGroupService and fileUploadSvc are the service which are being
 *           used to interact with REST
 * @requires $scope for storing information in scope
 * @requires $state for state routing
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for fetching logged in userId from session storage
 * @requires $filter for message translation from locale file
 * @requires $timeout
 * @requires $uibModal
 * @requires $stateParams
 */

angular.module('userMaintenanceModule').controller('AddIssuerGroupController', [
  '$scope',
  '$log',
  '$state',
  '$sessionStorage',
  '$filter',
  'userGroupService',
  'fileUploadSvc',
  '$timeout',
  '$uibModal',
  '$stateParams',
  'AG_IssuerRepository',
  function (
    $scope,
    $log,
    $state,
    $sessionStorage,
    $filter,
    userGroupService,
    fileUploadSvc,
    $timeout,
    $uibModal,
    $stateParams,
    issuerRepository
  ) {
    $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

    $scope.userGroupName = 'issuer';
    $scope.comboboxOptions = [];
    $scope.comboboxText = '';
    $scope.issuerFilterValue = '';
    $scope.issuerFilterOptions = [];
    $scope.issuerGroupNameEmpty = false;

    var readFile = this;
    readFile.documents = [
      {
        fileVo: [
          {
            file: '',
            fileName: '',
          },
        ],
      },
    ];
    this.$onInit = async () => {
      $scope.issuerFilterOptions = [];
      await this.fetchIssuers();
    };

    $scope.handleComboboxTextChange = (text) => {
      if (!text) {
        $scope.comboboxOptions = [];
      } else {
        $scope.comboboxOptions = $scope.issuerFilterOptions.filter((option) =>
          option.name.toLowerCase().includes(text.toLowerCase())
        );
      }
      $scope.issuerFilterValue = text;
    };

    $scope.handleComboboxClear = () => {
      $scope.issuerFilterValue = '';
      $scope.comboboxText = '';
    };

    $scope.handleOptionSelected = (option) => {
      $scope.comboboxText = option.name;
      $scope.comboboxOptions = [];
      $scope.issuerFilterValue = option.name;
    };

    function compareIssuers(a, b) {
      return a.id === b.id;
    }

    this.fetchIssuers = async () => {
      this.isFetchingIssuers = true;
      try {
        let issuers = await issuerRepository.listAll();

        issuers = issuers.filter((obj, index, self) => {
          return self.findIndex((t) => compareIssuers(t, obj)) === index;
        });

        $scope.issuerFilterOptions = [
          ...issuers.map((i) => ({
            name: i.name,
            value: i.id,
          })),
        ];

        if (this.issuerFilterValue) {
          this.comboboxText =
            $scope.issuerFilterOptions &&
            $scope.issuerFilterOptions.find(
              (option) => option.value === this.issuerFilterValue
            )?.name;
        }
      } finally {
        this.isFetchingIssuers = false;
        // $scope.$apply();
      }
    };

    /*
     * Below is the service call to interact with REST and get
     * list of countries
     */
    userGroupService
      .getCountryList('EN')
      .query()
      .$promise.then(
        function (countries) {
          $log.debug('success user view');
          $scope.countries = countries.countries;
        },
        function (error) {
          $log.debug(error);
        }
      );
    // Below is the function call from HTML on change of
    // option in country drop down.
    $scope.onCountrySelect = function (selectedCountry) {
      $scope.country = selectedCountry;
      $log.debug('country code', $scope.country.isoCountryCode);
    };
    // The below function call is to store the data from UI
    // and pass it to REST call for adding new group
    $scope.addGroupUser = function () {
      if (!$scope.isDirectDebit || $scope.isDirectDebit === undefined) {
        $scope.isDirectDebit = 'N';
      } else {
        $scope.isDirectDebit = 'Y';
      }

      if ($scope.issuerFilterValue === '') {
        $scope.issuerGroupNameEmpty = true;
        return;
      }

      $log.debug('$scope.username', $scope.issuerFilterValue);
      if ($scope.logoFile === null || $scope.logoFile === undefined) {
        var documentFormData = new FormData();
        documentFormData.append('groupName', $scope.issuerFilterValue);
        documentFormData.append('userId', $sessionStorage.userId);
        documentFormData.append('groupEmail', $scope.emailAddress);
        documentFormData.append('city', $scope.city);
        documentFormData.append('country', $scope.country.isoCountryCode);
        documentFormData.append('productCode', 'GM');
        var file = $scope.logoFile;
        var uploadUrl = 'v1/issuers';
        /*
         * Call the upload directive to upload file and
         * FormData
         */
        fileUploadSvc.uploadFileToUrl(file, uploadUrl, documentFormData).then(
          function (data) {
            if (
              data.data.httpStatusCode !== 200 &&
              data.data.errors.length > 0
            ) {
              $scope.error = true;
              $scope.errorMsg = data.data.errors[0].errorMessage;
              $timeout(function () {
                $scope.error = false;
              }, 3000);
            } else {
              $scope.success = true;
              $scope.timedout = $uibModal.open({
                templateUrl: 'success-dialog.html',
              });
              $timeout(function () {
                $scope.timedout.close();
                $scope.timedout = null;
                $state.go('userMaintenance.issuerGroup', {
                  pageNo: $stateParams.pageNo,
                });
              }, 2000);
            }
          },
          function (error) {
            $log.debug(error);
            $scope.error = true;
            $scope.errorMsg = error.data.errors[0].errorMessage;
          }
        );
      } else {
        if ('image' !== $scope.logoFile.type.substring(0, 5)) {
          $scope.error = true;
          $scope.errorMsgFile = $filter('translate')('label_errorImgFile');
          $timeout(function () {
            $scope.error = false;
          }, 3000);
        } else if ($scope.logoFile.size > 256000) {
          $scope.error = true;
          $scope.errorMsgFile = $filter('translate')('label_errorFileSize');
          $timeout(function () {
            $scope.error = false;
          }, 3000);
        } else {
          var documentFormDataSec = new FormData();
          documentFormDataSec.append('groupName', $scope.issuerFilterValue);
          documentFormDataSec.append('userId', $sessionStorage.userId);
          documentFormDataSec.append('groupEmail', $scope.emailAddress);
          documentFormDataSec.append('city', $scope.city);
          documentFormDataSec.append('country', $scope.country.isoCountryCode);
          documentFormDataSec.append('productCode', 'GM');
          documentFormDataSec.append('logo', $scope.logoFile);
          $scope.error = false;
          var file1 = $scope.logoFile;
          var uploadUrl1 = 'v1/issuers';
          /*
           * Call the upload directive to upload file and
           * FormData
           */
          fileUploadSvc
            .uploadFileToUrl(file1, uploadUrl1, documentFormDataSec)
            .then(
              function (data) {
                if (
                  data.data.httpStatusCode !== 200 &&
                  data.data.errors.length > 0
                ) {
                  $scope.error = true;
                  $scope.errorMsg = data.data.errors[0].errorMessage;
                  $timeout(function () {
                    $scope.error = false;
                  }, 3000);
                } else {
                  $scope.success = true;
                  $scope.timedout = $uibModal.open({
                    templateUrl: 'success-dialog.html',
                  });
                  $timeout(function () {
                    $scope.timedout.close();
                    $scope.timedout = null;
                    $state.go('userMaintenance.issuerGroup', {
                      pageNo: $stateParams.pageNo,
                    });
                  }, 2000);
                }
              },
              function (error) {
                $log.debug(error);
                $scope.error = true;
                $scope.errorMsg = error.errors[0].errorMessage;
                $timeout(function () {
                  $scope.error = false;
                }, 3000);
              }
            );
        }
      }
    };
  },
]);
