/**
 * @ngdoc controller
 * @name actionHomeModule.ActionHomeController
 * @description This controller is used for action home.
 * @requires generalMeetingService for rest calls related to general meeting
 * @requires voteCollectorService for rest calls related to vote collector
 *           services
 * @requires PaginationSvc for pagination
 * @requires $scope
 * @requires $log for debug logs
 * @requires $state for routing 
 * @requires $sessionStorage
 * @requires $stateParams
 * @requires AgentService
 * @requires $filter
 * @requires ModelWindowService
 */

angular.module('actionHomeModule').controller(
  'ActionHomeController',['$scope', '$log', '$state', '$stateParams', 'generalMeetingService', '$sessionStorage',
    'voteCollectorService', 'PaginationSvc','$filter','AgentService', 'ModelWindowService',
    function($scope, $log, $state, $stateParams, generalMeetingService, $sessionStorage,
      voteCollectorService, PaginationSvc,$filter,AgentService,ModelWindowService) {
      $scope.userType = $sessionStorage.usertype;
      $scope.agentName = $sessionStorage.userName;
      $scope.taxReclaimIndexId = $sessionStorage.taxReclaimIndexId;
      $scope.userId = $sessionStorage.userId;
      $scope.productTypes = $sessionStorage.productTypes;
      $scope.eventId = $stateParams.eventId;
      $scope.isAdmin = $sessionStorage.isAdmin;
      $scope.showGMActions = false;
      $scope.showDWTActions = false;
      $scope.loading = true;
      $scope.loadingGm = true;
      $scope.loadingDwt = true;
      $scope.beginIndex = 0;
      $scope.currentPageGm = 1;
      $scope.currentPageDwt = 1;
      var gmPage = 1;
      var dwtPage = 1;
      if ($stateParams.page !==undefined && $stateParams.page !== null){
        gmPage = $stateParams.page;
        dwtPage = $stateParams.page;
      }
      if ($sessionStorage.usertype === appConstant.IS) {
        var workStation = $scope.userType;
        var screenName = appConstant.IS_HOME_PAGE;
        var screenLocation = appConstant.BODY;
        var langCode = appConstant.OPTED_EN;
        $scope.productType = $sessionStorage[0];
        voteCollectorService.getVCStaticText(workStation, screenName,
          screenLocation, langCode).query().$promise.then(function(
          data) {
          $scope.landingPageText = data.templateList[0].templateText;
          $log.debug(data);
        }, function(error) {
          $log.debug(error);
        });
      }
      //Below function is to sort it records in the action home page
      $scope.onColumnClick = function(newValue) {
        if (newValue === '' || !newValue) {
          return;
        }
        if ($scope.oldValue === newValue) {
          $scope.sortType = $scope.oldValue;
          $scope.gmDummyItems = $filter('orderBy')($scope.gmDummyItems,
            newValue, true);
          $scope.sortReverse = !$scope.sortReverse;
          newValue = '';
        } else {
          $scope.sortReverse = false;
          $scope.sortType = newValue;
          $scope.gmDummyItems = $filter('orderBy')($scope.gmDummyItems,
            newValue);
        }
        $scope.pager = {};
        $scope.setPage($scope.currentPageGm);
        $scope.oldValue = newValue;
      };
      //Below function is to sort it records in the action home page for dwt
      $scope.dwtOnColumnClick = function(newValue) {
        if (newValue === '' || !newValue) {
          return;
        }
        if ($scope.oldValue === newValue) {
          $scope.sortType = $scope.oldValue;
          $scope.dummyItems = $filter('orderBy')($scope.dummyItems,
            newValue, true);
          $scope.sortReverse = !$scope.sortReverse;
          newValue = '';
        } else {
          $scope.sortReverse = false;
          $scope.sortType = newValue;
          $scope.dummyItems = $filter('orderBy')($scope.dummyItems,
            newValue);
        }
        $scope.dwtPager = {};
        $scope.setPageDWT($scope.currentPageDwt);
        $scope.oldValue = newValue;
      };
      //Below service call will happen only when logged in user have access to GM product
      if($scope.productTypes.length>0 && $scope.productTypes.indexOf('GM') !== -1) {
        $scope.showGMActions = true;
        $scope.loading = true;
        $scope.loadingGm = true;
        generalMeetingService.getGeneralMeetingsAction().get().$promise.then(
          function(data) {
            $scope.loading = false;
            $scope.loadingGm = false;
            $scope.oldValue = 'generalMeetingDate';
            $scope.gmDummyItems = $filter('orderBy')(data,
              'generalMeetingDate');
            $scope.pager = {};
            $scope.setPage(gmPage);
          }, function(error) {
            $scope.loading = false;
            $scope.loadingGm = false;
            return error;
          });
      }
      if($scope.productTypes.length>0 && $scope.productTypes.indexOf('DWT') !== -1) {
        $scope.showDWTActions = true;
        $scope.loading = true;
        $scope.loadingDwt = true;
        var pageNumber = 1;
        var size = 500;
        generalMeetingService.getDwtMeetingsAction(pageNumber,size).get().$promise.then(
          function(data) {
            $scope.loading = false;
            $scope.oldValue = 'qrfEndDate';
            $scope.dummyItems = $filter('orderBy')(data,
              'qrfEndDate');
            $scope.dwtPager = {};
            $scope.setPageDWT(dwtPage);
            $scope.loadingDwt = false;
          }, function(error) {
            $scope.loading = false;
            $scope.loadingDwt = false;
            return error;
          });
      }
      // make the spinner flag false if the product is not available for the logged in user-
      if($scope.productTypes.length>0 && !($scope.productTypes.indexOf('GM') !== -1)) {
        $scope.loadingGm = false;
      }
      if($scope.productTypes.length>0 && !($scope.productTypes.indexOf('DWT') !== -1)) {
        $scope.loadingDwt = false;
      }
      //Below function is for pagination, this calls from HTML on clicking of each page
      $scope.setPage = function(gmPage) {
        $scope.currentPageGm=gmPage;
        $scope.pager = $scope.pager || [];
        if (gmPage < 1 || gmPage > $scope.pager.totalPages) {
          return;
        }

        // get pager object from service
        $scope.pager = PaginationSvc.GetPager(
          $scope.gmDummyItems.length, gmPage);
        $log.debug($scope.pager);

        // get current page of items
        $scope.generalMeeting = $scope.gmDummyItems.slice(
          $scope.pager.startIndex, $scope.pager.endIndex + 1);
        $scope.beginIndex = $scope.pager.startIndex;
        $scope.pageNo = gmPage;
      };

      //Below function is for pagination, this calls from HTML on clicking of each page
      $scope.setPageDWT = function(dwtPage) {
        $scope.currentPageDwt=dwtPage;
        $scope.dwtPager = $scope.dwtPager || [];
        if (dwtPage < 1 || dwtPage > $scope.dwtPager.totalPages) {
          return;
        }

        // get pager object from service
        $scope.dwtPager = PaginationSvc.GetPager(
          $scope.dummyItems.length, dwtPage);
        $log.debug($scope.dwtPager);

        // get current page of items
        $scope.dwtEvents = $scope.dummyItems.slice(
          $scope.dwtPager.startIndex, $scope.dwtPager.endIndex + 1);
        $scope.beginIndex = $scope.dwtPager.startIndex;
        $scope.pageNo = dwtPage;
      };
      $scope.groupId = $sessionStorage.groupId;

      // if clicked on "Approve n Voting Instructions"
      $scope.goToVotingInstructionsOverviewPage = function(meeting) {
        $sessionStorage.productType = 'GM';
        $state.go('votingInfoHome', {
          type: 'vote',
          gmId: meeting.generalMeetingId,
          issuerId: meeting.issuerId,
          issuerName: meeting.issuerName,
          gmDate: meeting.generalMeetingDateStr,
          intermediaryDateTimeStamp: meeting.intermediaryDeadlineDate,
          intermediaryDate: meeting.intermediaryDeadlineDateStr,
          newEndDateTimeStamp: meeting.newEndDate,
          langCode: langCode,
          breadCrumbDetails:['Meeting name: ' + meeting.meetingName]
        });
      };

      // if clicked on "Approve n Attendance Requests"
      $scope.goToAttendanceRequestsOverviewPage = function(meeting) {
        $sessionStorage.productType = 'GM';
        $state.go('attendReqInfoHome', {
          type: 'attend',
          gmId: meeting.generalMeetingId,
          issuerId: meeting.issuerId,
          issuerName: meeting.issuerName,
          gmDate: meeting.generalMeetingDateStr,
          intermediaryDateTimeStamp: meeting.intermediaryDeadlineDate,
          intermediaryDate: meeting.intermediaryDeadlineDateStr,
          newEndDateTimeStamp: meeting.newEndDate,
          langCode: langCode,
          breadCrumbDetails:['Meeting name: ' + meeting.meetingName]
        });
      };

      // Navigate to virtual participation overview page
      $scope.goToVirtualParticipationOverviewPage = function(meeting) {
        $state.go('virtualParticipationView', {
          'gmId': meeting.generalMeetingId,
          'issuerId': meeting.issuerId,
          'issuerName': meeting.issuerName,
          'generalMeetingDateMillis': meeting.generalMeetingDateMiilis,
          'intermediaryDeadlineDateMillis' : meeting.intermediaryDeadlineDateMiilis,
          'intermediaryAccessDateMillis': meeting.intermediaryAccessDateMiilis,
          'timeZone': meeting.timeZone,
          'breadCrumbDetails':[meeting.meetingName]
        });
      };

      // handle user clicked on "Download registration list"
      $scope.onDownloadRegistrationList = function(meeting) {
        $sessionStorage.currentTab = 'registrations';
        $sessionStorage.actionFor = 'regList';
        $state.go('generalDetailsView', {
          meetingId: meeting.generalMeetingId,
          issuerId: meeting.issuerId,
          issuerName: meeting.issuerName,
          gmDateTime: meeting.generalMeetingDateStr,
          status: meeting.status,
          intermediaryDate: meeting.intermediaryDeadlineDate,
          sendRegViEuroclear: meeting.sendRegViEuroclear,
          flag: 1,
          breadCrumbDetails: ['Issuer: ' + meeting.issuerName]
        });
      };

      // Redirect the user to the respective page based on the action type
      $scope.selectedAction = function(actionType, issuerId, issuerName,
        generalMeetingId, status, gmDate, intermediaryDeadlineDateMillis, intermediaryDeadlineDate,meetingName,sendRegViEuroclear,newEndDate) {
        $sessionStorage.productType = 'GM';
        // if clicked on "Remind Issuer"/"Contact issuer for extension request"
        switch(actionType) {
        case (appConstant.REMIND || appConstant.CONTACT_EXTN_REQ):
          $state.go('userMaintenance.issuerUserList', {
            groupId: issuerId,
            userType: 'IS',
            breadCrumbDetails:['Issuer: '+issuerName]
          });
          break;
        case appConstant.SET_SHARE_PLAN :
          // if clicked on "Set-up share plan"
          AgentService.importDocFiles(generalMeetingId).then(
            function(response) {
              $log.debug(response);
              $state.go('inviteSharePlanHome',
                {
                  selectedIndex: 1,
                  meetingStatus: generalMeetingId,
                  issuerId: issuerId,
                  gmDate: gmDate,
                  issuerName: issuerName,
                  documents: response.data.gmDocumentVO,
                  emailSubject: response.data.emailVO.emailSubject,
                  emailBody: response.data.emailVO.emailBody,
                  breadCrumbDetails: [meetingName]
                });
            },
            function(error) {
              $log.debug(error);
              if (error.status === 422) {
                $scope.inviteShareplanModelConfig.modalBody = error.data.errors[0].errorMessage;
                ModelWindowService
                  .showModelWindow('inviteSharePlanAlert');
              }
            });
          break;
        case appConstant.CREATE :
          // if clicked on "Create Intermediary"
          $state.go('userMaintenance.addIntermediary', {
            userGroupId: $scope.groupId
          });
          break;
        case (appConstant.VC_USR_ROLE || appConstant.VOTE_CNF_SENT) :
          // if clicked on "Vote Collector Users yet to accept their role"/ "Vote Confirmations to be sent out"
          $state.go('userMaintenance.vcUserList', {
            status: status,
            redirectedFrom:'action',
            gmId: generalMeetingId
          });
          break;
        case appConstant.REVIEW_EXT :
          // if clicked on Review/Approve Extension Request
          $state.go('extensionRequest');
          break;
        default:
          break;
        }
        if ((appConstant.EDIT_GM === actionType || appConstant.REVIEW_GM === actionType) && $scope.userType !=='IS'){
          // if clicked on "Edit GM"/"Review/Approve GM"
          $state.go('editGM', {
            selectedIndex: 1,
            meetingStatus: generalMeetingId,
            tabId: null
          });
        }
        else if (appConstant.REVIEW_GM === actionType && $scope.userType ==='IS' ) {
          // if clicked on "Review/Approve GM"
          $sessionStorage.currentTab = null;
          $state.go('generalDetailsView', {
            meetingId: generalMeetingId,
            issuerId: issuerId,
            issuerName: issuerName,
            gmDateTime: gmDate,
            status: status,
            intermediaryDate: intermediaryDeadlineDate,
            sendRegViEuroclear: sendRegViEuroclear,
            flag : 1,
            breadCrumbDetails:'Issuer: '+[issuerName]
          });
        } else if (appConstant.REVIEW_EXT === actionType && $scope.userType ==='IS') {
          // if clicked on Approve Extension Request
          $state.go('createIssuerExtensionRequest',
            {intermediaryDeadlineDateStr:intermediaryDeadlineDate,
              gmName:meetingName,
              gmDateTime:gmDate,
              generalMeetingId:generalMeetingId,
              extReqRejected:true,
              issuerId:issuerId,
              userType:$sessionStorage.usertype,
              gmStatus:status,
              breadCrumbDetails:[meetingName]
            });
        }
      };

      $scope.selectedActionDWT = function(meeting){
        $sessionStorage.productType = 'DWT';
        if (appConstant.APPROVE_EVENT === meeting.actions ||
                    appConstant.Submit_QRF_or_PE_Claims === meeting.actions || appConstant.VIEW_EVENT === meeting.actions) {
          //If clicked on "Approve event" link or If clicked on 'Submit QRF or PE Claims' or If clicked on 'View Event'
          $state.go('dwtDetails', {
            eventId:meeting.eventId,
            selectedIndex:$scope.taxReclaimIndexId,
            eventName : meeting.eventName,
            eventStatus : meeting.status,
            issuerId : meeting.issuerId,
            issuerName : meeting.issuerName,
            dwtPerSecurity : meeting.dwtPerSecurity,
            qrfEndDate: meeting.qrfEndDate,
            peFlag : meeting.peFlag,
            qrfFlag : meeting.qrfFlag,
            extensionReqFlagForQRF:meeting.extensionReqFlagForQRF,
            extensionReqFlagForPE:meeting.extensionReqFlagForPE,
            navFrom :'home',
            extReqFlag : meeting.extensionReqFlag,
            shareholderId : meeting.shareholderId,
            breadCrumbDetails:[meeting.eventName]
          });
        }
        else if (appConstant.REMIND_DWT === meeting.actions) {
          //If clicked on 'Remind Issuer' Will be redirected to user list of issuer
          $state.go('userMaintenanceForDWT.issuerUserListDwt', {
            groupId: meeting.issuerId
          });
        }
        else if (appConstant.SUBMIT_QRF_CLAIMS === meeting.actions ||
                    appConstant.CHECK_PE_CLAIMS === meeting.actions) {
          //If clicked on 'Submit the QRF claims' link or  clicked on 'Check  PE Claims' link
          $state.go('dwtDetails', {
            eventId:meeting.eventId,
            selectedIndex:$scope.taxReclaimIndexId,
            eventName : meeting.eventName,
            eventStatus : meeting.status,
            issuerId : meeting.issuerId,
            issuerName : meeting.issuerName,
            dwtPerSecurity : meeting.dwtPerSecurity,
            qrfEndDate: meeting.qrfEndDate,
            flag:'claims',
            peFlag : meeting.peFlag,
            qrfFlag : meeting.qrfFlag,
            navFrom :'home',
            extReqFlag : meeting.extensionReqFlag,
            shareholderId : meeting.shareholderId,
            breadCrumbDetails:[meeting.eventName]
          });
        }
      };
    }]);
