import formatLocationString from '@/lib/formatLocationString';
import getTermsOfUseUrl from '@/lib/getTermsOfUseUrl';

/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.VotingInstructionIntermediaryController
 * @description This controller is used for voting instruction. This mainly
 *              deals with submitting of voting instruction.
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 * @requires userGroupService
 * @requires voteInstructionService
 * @requires attendanceRequestService to get the linked meeting list
 */
angular
  .module('generalMeetingHomeModule')
  .controller('VotingInstructionIntermediaryController', [
    '$scope',
    '$sessionStorage',
    '$state',
    '$filter',
    '$stateParams',
    'voteInstructionService',
    '$log',
    'userGroupService',
    'attendanceRequestService',
    '$timeout',
    'intermediaryService',
    'ModelWindowService',
    function (
      $scope,
      $sessionStorage,
      $state,
      $filter,
      $stateParams,
      voteInstructionService,
      $log,
      userGroupService,
      attendanceRequestService,
      $timeout,
      intermediaryService,
      ModelWindowService
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      var votingInstDetails = $stateParams.votingInstDetails;
      $scope.proxyType = $sessionStorage.proxyType;
      $scope.userType = $sessionStorage.usertype;
      $scope.shareClassName = $stateParams.shareClassName;
      $scope.showAllFor = true;
      $scope.issuerName = $stateParams.issuerName.toLowerCase();

      if ($scope.issuerName == 'shell plc') {
        $scope.showAllFor = false;
      }
      // Below is the function used to fetch issuer logo
      userGroupService.getIssuerGroupLogo($stateParams.issuerId).then(
        function (response) {
          $scope.issuerLogoPresent = false;
          $scope.issuerGroupDetails = response.data;
          // Do not change the below condition. It is for issuer logo display fix. REST response has ""
          if ($scope.issuerGroupDetails.logoBaseStr) {
            $scope.issuerLogoPresent = true;
          }
        },
        function (error) {
          $log.debug(error);
        }
      );
      $scope.holdingRadio = 'Y';
      $scope.shareHolderVO = {};
      $scope.noOfSharesErrorFlag = 0;
      $scope.flag = 0;
      $scope.loading = false;
      $scope.loadingSpin = false;
      $scope.loadingFileSpin = false;
      $scope.duplicateRequest = false;
      $scope.allRadio = false;
      $scope.individualRadio = false;
      $scope.eventType = 'Footer Term of Use';
      $scope.availableVC = [];
      $scope.languageCode =
        $sessionStorage.workstationData.selectedLang || 'EN';
      $scope.modalHeader = 'Information';
      $scope.modalBody = 'Are you sure u want to continue';
      $scope.modalLeftButton = $filter('translate')('label_confirm');
      $scope.modalRightButton = $filter('translate')('label_cancel');
      //Below value is hard coded to GM because VI and AR functionalities will be there only for GM users.
      //Using productType array from session storage was causing issue in case of integrated users, so it is replaced with below value.
      $scope.productTypeToU = 'GM';
      /* date picker */
      $scope.inlineOptions = {
        customClass: getDayClass,
      };
      $scope.open1 = function () {
        $scope.popup1.opened = true;
      };
      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };
      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.popup1 = {
        opened: false,
      };

      function disabled(data) {
        var date, mode;
        date = data.date;
        mode = data.mode;
        $log.debug(date);
        $log.debug(mode);
      }

      $scope.dateOptions = {
        dateDisabled: disabled,
        formatYear: 'yy',
        maxDate: new Date(),
        //removed min validation for date picker -SFCBP-1977
        showWeeks: false,
      };

      function getDayClass(data) {
        var date = data.date,
          mode = data.mode;
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(
              0,
              0,
              0,
              0
            );
            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }
        return '';
      }

      $scope.loading = true;
      voteInstructionService
        .getProfileDropDownList($scope.languageCode, $scope.userType)
        .query()
        .$promise.then(
          function (data) {
            $scope.profileListData = data;
            angular.forEach($scope.profileListData.countryCodeVO, function (
              item
            ) {
              if (item.isoCountryCode === 'NLD') {
                $scope.selectedCountry = item;
              }
            });
            if (
              $stateParams.isLinkedMeeting &&
              $stateParams.linkedMeetingData.length > 0
            ) {
              setLinkedMeetingParams();
            }
            $scope.loading = false;
          },
          function (error) {
            $scope.loading = false;
          }
        );

      /* date picker end */
      $scope.dateEnter = function (dob) {
        var currentdate = new Date().setHours(0, 0, 0, 0);
        if (dob > currentdate) {
          $scope.dobError = true;
        } else {
          $scope.dobError = false;
        }
      };

      /*
       * Note: the $scope.profileDetails contains all details needed for the profileForm in the DOM.
       * The profileDetails are initially set by the getProxy method when a search on securities
       * account number is performed and a shareHolderVO is returned by the BE.
       * To load all shareholder details in the DOM of the page of the next linked meeting,
       * the persisted shareholderVO is passed as a stateParam to the next UIRouter state to again
       * set the $scope.profileDetails. The persisted shareholderVO is an attribute of the VotingInstructionConfirmationVO,
       * which is the data transfer object used for persistence of the voting instruction.
       */
      function setLinkedMeetingParams() {
        $stateParams.linkedMeetingData.shift();
        $scope.isLinkedMeeting = $stateParams.isLinkedMeeting;
        $scope.linkedMeetingData = $stateParams.linkedMeetingData;
        $scope.registrationList = $stateParams.shareholderRegistrationDetailVO;
        $scope.securityAccountNumber = $stateParams.securityAccountNumber;
        $scope.profileDetails = $stateParams.shareHolderVO;
        $scope.profileListData = $stateParams.profileListData;
        $scope.shTitleRequired = $stateParams.shTitleRequired;
        $scope.selectedSecurityClass = $stateParams.selectedSecurityClass;
        fillShareholderProfile();
        $scope.breadCrumbDetails = $stateParams.breadCrumbDetails;
      }

      $scope.changeUserType = function () {
        $scope.shareHolderType = [];
        $scope.shareHolderType[0] = $scope.profileListData.shareholderTypeVO[0];
      };

      $scope.selectedSHType = function () {
        if ($scope.shareHolderType[0].type === 'LGL') {
          $scope.shTitleRequired = false;
        } else {
          $scope.shTitleRequired = true;
        }
      };

      $scope.onTitleChange = function () {
        $scope.shTitleRequired = true;
      };

      // Below is the function call on drop-down change for country
      $scope.onCountrySelect = function (selectedCountry) {
        $scope.selectedCountry = selectedCountry;
      };

      if (votingInstDetails.length > 1) {
        $scope.moreThanOneSC = true;
        $scope.shareClassList = votingInstDetails;
        $scope.selectedSecurityClass = $scope.shareClassList[0];
        /* Below changes are added to display votecollector name along with its group name in the drop down */
        $scope.voteCollectorList = [];
        angular.forEach(
          $scope.selectedSecurityClass.vcShareclassAssocVO,
          function (key) {
            key.nameOfVC =
              key.title +
              ' ' +
              key.firstName +
              ' ' +
              key.lastName +
              ' ' +
              $filter('translate')('vi_shareholder_disclaimer2') +
              ' ' +
              key.vcName;
            $scope.voteCollectorList.push(key);
            if ($scope.voteCollectorList.length === 1) {
              $scope.selectedVC = $scope.voteCollectorList[0];
            }
          }
        );
      } else if (votingInstDetails.length === 1) {
        $scope.moreThanOneSC = false;
        $scope.shareClassList = votingInstDetails;
        $scope.selectedSecurityClass = $scope.shareClassList[0];
        /* Below changes are added to display vote collector name along with its group name in the drop down */
        $scope.voteCollectorList = [];
        angular.forEach(
          $scope.selectedSecurityClass.vcShareclassAssocVO,
          function (key) {
            key.nameOfVC =
              key.title +
              ' ' +
              key.firstName +
              ' ' +
              key.lastName +
              ' ' +
              $filter('translate')('vi_shareholder_disclaimer2') +
              ' ' +
              key.vcName;
            $scope.voteCollectorList.push(key);
            if ($scope.voteCollectorList.length === 1) {
              $scope.selectedVC = $scope.voteCollectorList[0];
            }
          }
        );
      } else {
        $scope.noShareClass = true;
      }
      //on change of votecollector in dropdown
      $scope.onVCChange = function (selectedVC) {
        $scope.selectedVC = selectedVC;
      };
      //on security class change in dropdown
      $scope.onSecurityClassChange = function (selectedSecurityClass) {
        $scope.selectedSecurityClass = selectedSecurityClass;
        /* Below changes are added to display vote collector name along with its group name in the drop down */
        $scope.voteCollectorList = [];
        angular.forEach(
          $scope.selectedSecurityClass.vcShareclassAssocVO,
          function (key) {
            key.nameOfVC =
              key.title +
              ' ' +
              key.firstName +
              ' ' +
              key.lastName +
              ' ' +
              $filter('translate')('vi_shareholder_disclaimer2') +
              ' ' +
              key.vcName;
            $scope.voteCollectorList.push(key);
            if ($scope.voteCollectorList.length === 1) {
              $scope.selectedVC = $scope.voteCollectorList[0];
            }
          }
        );
        $scope.selectedVC = $scope.voteCollectorList[0];
      };
      /**
       * The following code is an important one. For shareholder ws, during creation of VI, if the selected security class has more than one
       * intermediary, then a dropdown needs to be shown. Else, no dropdown. Also, when there is more than one intermediary (All intermediaries)
       * and the shareholder has selected preferred intermediary while registration, then the preferred intermediary should be selected by default.
       * If the agent has created GM with shareclass having only one intermediary, then it takes precedence over the shareholder preference.
       */

      // Below is the function call on drop-down change
      $scope.onVCChange = function (selectedVC) {
        $scope.selectedVC = selectedVC;
      };
      $scope.onintermediaryChange = function (selectedIntermediary) {
        $scope.selectedIntermediary = selectedIntermediary;
        $scope.intermediaryid = selectedIntermediary.intermediaryId;
        $scope.getVISubmit();
      };
      //ALM - 10528 autopopulating field when 'veb'  is keyed in for name filed for legal person
      $scope.autoPopulate = function () {
        if (
          $scope.shareHolderType[0].type === 'LGL' &&
          ($scope.profileDetails.lastName.toLowerCase() === 'veb' ||
            $scope.profileDetails.lastName
              .toLowerCase()
              .indexOf('vereniging van effectenbezitters') > -1)
        ) {
          $scope.profileDetails.address = 'Amaliastraat 7';
          $scope.profileDetails.emailAddress = 'info@veb.net';
          $scope.profileDetails.city = 'Den Haag';
          $scope.selectedCountryArray = $filter('filter')(
            $scope.profileListData.countryCodeVO,
            { isoCountryCode: 'NLD' },
            true
          );
          $scope.selectedCountry = $scope.selectedCountryArray[0];
        }
      };

      //Below is the function call to fetch the linked intermediaries for a proxy intermediary and populate the select intermediary dropdown.
      $scope.fetchLinkedIntermediaries = function () {
        if ($sessionStorage.proxyType === 'P') {
          $scope.prxoyIntermediaryFlag = 'Y';
          voteInstructionService
            .getLinkedIntermediaries($sessionStorage.groupId)
            .query()
            .$promise.then(
              function (data) {
                $scope.linkedIntermediaryList = data;
              },
              function (error) {
                $log.debug(error);
              }
            );
        }
      };

      //Below is the function call on select of Intemr
      $scope.onLinkedIntChange = function (selectedIntermediary) {
        $scope.selectedLinkedInt = selectedIntermediary;
      };

      $scope.fetchLinkedIntermediaries();

      // Below is the function call to fetch agenda items
      function agenda() {
        $scope.message = '';
        $scope.totalNumberSharesError = '';
        $scope.errormessage = '';
        if (!$scope.shareholderId) {
          $scope.shareholderId = $sessionStorage.groupId;
        }
        voteInstructionService
          .getAgendaDetails(
            $stateParams.meetingId,
            $scope.languageCode,
            $scope.shareholderId,
            $scope.shareClassId,
            $scope.intermediaryid,
            $scope.userType
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.agendaCollection = data;
              angular.forEach($scope.agendaCollection, function (item) {
                if (
                  item.noOfSecurities &&
                  item.entireHolding === 'Y' &&
                  undefined === $scope.noOfShares
                ) {
                  $scope.noOfShares = item.noOfSecurities;
                } else if (item.noOfSecurities && item.entireHolding === 'N') {
                  $scope.entireHoldingRadioVal = true;
                  $scope.selectedQuantityNoOfShares = item.noOfSecurities;
                } else {
                  $scope.entireHoldingRadioVal = false;
                }
                if ($scope.noOfShares) {
                  item.totalSplitVotes = $scope.noOfShares;
                } else {
                  item.totalSplitVotes = 0;
                }
                item.splitVotes = false;
                $scope.selectedVote = 'none';
                item.disableSplitVote = true;
                item.disableRadio = true;
                $scope.instructionId = item.instructionId;
                $scope.registrationNo = item.registrationNo;
                if (item.splitVoteAllwdFrmInter === 'N') {
                  item.disableSplitVote = false;
                }
              });
              angular.forEach($scope.agendaCollection, function (key2) {
                if (key2.isVotable === 'Y') {
                  $scope.isVotableFlag = true;
                }
                return $scope.isVotableFlag;
              });
            },
            function (error) {
              $log.debug(error);
            }
          );
      }

      $scope.processVotingInstructionData = function () {
        if (
          undefined !== votingInstDetails.length &&
          votingInstDetails.length >= 1
        ) {
          $scope.votingInsructionData = votingInstDetails;
          if (votingInstDetails.length === 1) {
            $scope.onSecurityClassChange(votingInstDetails[0]);
            $scope.selectedSecurityClass = votingInstDetails[0];
          } else if (votingInstDetails.length >= 1) {
            $scope.onSecurityClassChange(votingInstDetails[0], 'onLoadofPage');
          }
        } else {
          $scope.votingInsructionData = '';
        }
      };
      $scope.processVotingInstructionData();

      if (votingInstDetails.length > 1) {
        $scope.moreThanOneShareClass = true;
      }
      if (null !== $scope.shareClassId) {
        angular.forEach($scope.votingInsructionData, function (value, key) {
          if (
            $scope.shareClassName ===
              $scope.votingInsructionData[key].shareClassName &&
            key + 1 !== $scope.votingInsructionData.length
          ) {
            $scope.selectedSecurityClass = $scope.votingInsructionData[key + 1];
            $scope.onSecurityClassChange($scope.selectedSecurityClass);
          } else if (
            $scope.shareClassName ===
              $scope.votingInsructionData[key].shareClassName &&
            key + 1 === $scope.votingInsructionData.length
          ) {
            $scope.selectedSecurityClass = $scope.votingInsructionData[0];
            $scope.onSecurityClassChange($scope.selectedSecurityClass);
          }
        });
      }

      // below is the function call on change of radio button
      $scope.holdingRadioChange = function (val) {
        $scope.holdingRadio = val;
      };
      /*
       * Below is the function to check whether the entered Securities account number is already present or not in
       * intermediary workstations
       */
      $scope.getProxy = function (
        securityAccountNumber,
        selectedSecurityClass
      ) {
        $scope.loading = true;
        $scope.selectedCountryArray = [];
        voteInstructionService
          .getVotingProxyList(
            $stateParams.meetingId,
            securityAccountNumber,
            selectedSecurityClass.shareclassId,
            '0',
            $scope.userType,
            $sessionStorage.groupId
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.errorMessage = null;
              $scope.registrationList = data.shareholderRegistrationDetailVO;
              $scope.profileDetails = data.shareHolderVO;
              fillShareholderProfile();
            },
            function (error) {
              $scope.loading = false;
              $scope.errorMessage = error.data.errors[0].errorMessage;
            }
          );
      };
      // Below is the function to validate the entered number of shares when split votes is enabled
      $scope.CalculateSumOfShares = function (item) {
        $scope.message = '';
        $scope.errormessage = '';
        item.totalNumberSharesError = '';
        item.voteFor = parseInt(item.voteFor, 10);
        item.voteAgainst = parseInt(item.voteAgainst, 10);
        item.voteAbstain = parseInt(item.voteAbstain, 10);
        if (
          parseInt(item.voteFor, 10) +
            parseInt(item.voteAgainst, 10) +
            parseInt(item.voteAbstain, 10) >
          parseInt(item.totalSplitVotes, 10)
        ) {
          item.totalNumberSharesError = $filter('translate')(
            'noOfShares_error1'
          );
          $scope.noVotePatternErr = true;
          $scope.flag = 1;
          return;
        } else if (
          parseInt(item.voteFor, 10) +
            parseInt(item.voteAgainst, 10) +
            parseInt(item.voteAbstain, 10) !==
          parseInt(item.totalSplitVotes, 10)
        ) {
          item.totalNumberSharesError = $filter('translate')(
            'noOfShares_error2'
          );
          $scope.noVotePatternErr = true;
          $scope.flag = 1;
          return;
        } else {
          $scope.flag = 0;
          $scope.noVotePatternErr = false;
          item.totalNumberSharesError = '';
        }
      };

      //this function fills the shareholder profile details for both linked meetings and security accountnumber search
      function fillShareholderProfile() {
        $scope.shareHolderVO.initials = $scope.profileDetails.initials;
        $scope.shareHolderType = $filter('filter')(
          $scope.profileListData.shareholderTypeVO,
          {
            type: $scope.profileDetails.shareHolderType,
          },
          true
        );
        $scope.selectedCountryArray = $filter('filter')(
          $scope.profileListData.countryCodeVO,
          {
            isoCountryCode: $scope.profileDetails.country,
          },
          true
        );
        $scope.selectedCountry = $scope.selectedCountryArray[0];
        $scope.shareHolderVO.selectedTitle = $filter('filter')(
          $scope.profileListData.shareHolderTitleVO,
          {
            title: $scope.profileDetails.title,
          },
          true
        );
        $scope.selectedUserType = $filter('filter')(
          $scope.profileListData.owneTypeVO,
          {
            userType: $scope.profileDetails.owneType,
          },
          true
        );
        $scope.loading = false;
        if ($scope.profileDetails.shareholderId !== '0') {
          $scope.shareholderId = $scope.profileDetails.shareholderId;
          agenda();
        } else {
          $scope.shareholderId = '0';
        }
      }

      $scope.getProxyTextParagraph2 = function () {
        if ($scope.issuerName === 'telenet group holding nv') {
          return $filter('translate')(
            'vi_shareholder_disclaimer8_with_right_of_substitution'
          );
        } else {
          return $filter('translate')('vi_shareholder_disclaimer8');
        }
      };

      // Below is the function call on selection radio buttons to enable or disable particular section
      $scope.selectAllRadio = function () {
        $scope.flag = 0;
        $scope.message = '';
        $scope.errormessage = null;
        $scope.allRadio = true;
        angular.forEach($scope.agendaCollection, function (item) {
          item.totalNumberSharesError = '';
          var voteValue = item.totalSplitVotes;
          if ($scope.selectedVote === 'Allfor') {
            item.selectedVote = 'for';
            item.voteFor = voteValue;
            item.voteAgainst = 0;
            item.voteAbstain = 0;
            item.disableSplitVote = false;
            item.splitVotes = false;
            item.disableRadio = false;
            $scope.noVotePatternErr = false;
          } else if ($scope.selectedVote === 'AllAgainst') {
            item.selectedVote = 'against';
            item.voteAgainst = voteValue;
            item.voteFor = 0;
            item.voteAbstain = 0;
            item.disableSplitVote = false;
            item.splitVotes = false;
            item.disableRadio = false;
            $scope.noVotePatternErr = false;
          } else if ($scope.selectedVote === 'AllAbstained') {
            item.selectedVote = 'abstained';
            item.voteAbstain = voteValue;
            item.voteAgainst = 0;
            item.voteFor = 0;
            item.disableSplitVote = false;
            item.splitVotes = false;
            item.disableRadio = false;
            $scope.noVotePatternErr = false;
          } else {
            $scope.noVotePatternErr = false;
            if (item.splitVoteAllwdFrmInter === 'N') {
              item.disableSplitVote = false;
            } else {
              item.disableSplitVote = true;
            }
            item.disableRadio = true;
          }
        });
      };
      /*
       * Below is the function call on selection radio buttons to check whether the voted item is for or against or
       * abstained when none radio button is selected
       */
      $scope.individualRadioChange = function (item) {
        $scope.individualRadio = true;
        $scope.flag = 0;
        $scope.errormessage = null;
        var voteValue = item.totalSplitVotes;
        if (item.selectedVote === 'for') {
          item.voteFor = voteValue;
          item.voteAgainst = 0;
          item.voteAbstain = 0;
          $scope.noVotePatternErr = false;
        } else if (item.selectedVote === 'against') {
          item.voteAgainst = voteValue;
          item.voteFor = 0;
          item.voteAbstain = 0;
          $scope.noVotePatternErr = false;
        } else if (item.selectedVote === 'abstained') {
          item.voteAbstain = voteValue;
          item.voteAgainst = 0;
          item.voteFor = 0;
          $scope.noVotePatternErr = false;
        }
      };

      $scope.spilitVoteChange = function (item) {
        item.totalNumberSharesError = '';
        if (item.splitVotes === true) {
          item.selectedVote = false;
          $scope.noVotePatternErr = false;
          $scope.errormessage = '';
          $scope.CalculateSumOfShares(item);
        } else {
          $scope.errormessage = $filter('translate')(
            'votingInstruction_error_msg'
          );
          $scope.noVotePatternErr = true;
          item.voteAgainst = 0;
          item.voteFor = 0;
          item.voteAbstain = 0;
        }
      };

      // Below is the function to validate the entered number of shares
      $scope.enterShares = function (selectedQuantityNoOfShares) {
        $scope.selectedQuantityErrorMessage = '';

        angular.forEach($scope.agendaCollection, function (item) {
          if (
            selectedQuantityNoOfShares != null &&
            selectedQuantityNoOfShares !== ''
          ) {
            item.totalSplitVotes = selectedQuantityNoOfShares;
            if (item.isVotable !== 'N' && item.splitVotes === true) {
              $scope.CalculateSumOfShares(item);
            }
          } else {
            item.totalSplitVotes = $scope.noOfShares;
          }
          if ($scope.allRadio) {
            $scope.selectAllRadio();
          } else if ($scope.individualRadio) {
            $scope.individualRadioChange(item);
          } else if (item.splitVotes) {
            $scope.spilitVoteChange(item);
          }
        });
      };

      $scope.termsOfUseUrl = getTermsOfUseUrl(
        'GM',
        $sessionStorage.workstationData.selectedLang
      );

      // Below method call from HTML on click of upload share plan data.
      $scope.onFileSelect = function (e) {
        $scope.fileStatus = '';
        $scope.loadingFileSpin = true;
        var filesList = e.files;
        var fd = new FormData();
        fd.append('file', filesList[0]);
        $scope.fileType = filesList[0].type;

        $scope.errorHere = false;
        voteInstructionService.uploadBOFile(fd, 'secureToken').then(
          function (response) {
            $scope.loadingFileSpin = false;
            if (response.status === 200) {
              $scope.saveDisabled = false;
              $scope.filePath = response.data.filePath;
              $scope.fileStatus = response.data.fileStatus;
              $scope.fileName = response.data.fileName;
              $scope.errorHere = false;
              $scope.errorMsg = '';
              $scope.saveStatus = '';
            } else if (response.data.errors) {
              if (response.data.errors[0].errorCode === 'CBP_ERR_513') {
                $scope.errorHere = true;
                $scope.errorMsg = response.data.errors[0].errorMessage;
                $scope.saveDisabled = true;
                $scope.fileName = response.data.fileName;
                $scope.fileStatus = response.data.fileStatus;
                $log.debug('error');
                $scope.saveStatus = '';
              }
            } else {
              $scope.errorHere = true;
              $scope.errorMsg = $filter('translate')('label_file_format_xlsx');
              $scope.errorMsg = response.data.fileStatus;
              $scope.saveDisabled = true;
              $scope.fileName = response.data.fileName;
              $scope.fileStatus = response.data.fileStatus;
              $log.debug('error');
              $scope.saveStatus = '';
            }
          },
          function (error) {
            $scope.loadingFileSpin = false;
            $scope.errorHere = true;
            $scope.errorMsg = $filter('translate')('label_file_format_xlsx');
            $scope.saveDisabled = true;
            $scope.fileName = '';
            $scope.fileStatus = error.fileStatus;
            $log.debug('error');
            $scope.saveStatus = '';
          }
        );
      };
      $scope.continueVoting = function () {
        if (!$scope.noVotePatternErr) {
          if (
            $scope.selectedUserType[0].userType !== 'NOMI' &&
            $scope.selectedUserType[0].userType !== 'TRUS'
          ) {
            $scope.showTcOwneDialog = true;
          } else if (
            $scope.selectedUserType[0].userType === 'NOMI' ||
            $scope.selectedUserType[0].userType === 'TRUS'
          ) {
            $scope.showTcNomiTrustDialog = true;
          }
        }
      };
      $scope.intermediaryConfirmVI = function () {
        $scope.duplicateRequest = false;
        $scope.errormessage = '';
        //Check if selected linked intermediary is Expired or Non Expired. For expired , throw error message
        if (
          $sessionStorage.proxyType === 'P' &&
          $scope.selectedLinkedInt.invalidPOAcandidates === 'Y'
        ) {
          // $scope.errorHere = true;
          $scope.errormessage = $filter('translate')('label_linkedIntError');
        }
        //For user type NOMI and TRUS, uploading BO file is mandatory.
        else if (
          $scope.selectedUserType[0].userType === 'NOMI' ||
          $scope.selectedUserType[0].userType === 'TRUS'
        ) {
          if ($scope.filePath === null || $scope.filePath === undefined) {
            $scope.errorHere = true;
            $scope.errorMsg = $filter('translate')('uploadFile_error');
          } else {
            $scope.errorHere = false;
            if (
              $scope.fileType !==
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
              $scope.errorHere = true;
              $scope.errorMsg = $filter('translate')('label_file_format_xlsx');
            } else {
              $scope.errorHere = false;
              // Service call to check whether the voting instruction is already submitted for the
              // current combination of share class, intermediary and number of shares
              voteInstructionService
                .checkVotingRegistrationList(
                  $stateParams.meetingId,
                  $scope.selectedSecurityClass.shareclassId,
                  $sessionStorage.groupId,
                  $scope.profileDetails.lastName,
                  $scope.selectedQuantityNoOfShares,
                  0
                )
                .then(
                  function (response) {
                    $log.debug('success user view');
                    if (response.data.length > 0) {
                      $scope.duplicateRequest = true;
                      $scope.modalBody =
                        'There is already a voting instruction registered for ' +
                        response.data[0].shareholderName +
                        ' for ' +
                        $scope.selectedQuantityNoOfShares +
                        ' securities. Are you sure you want to continue as this might be a duplicate entry?';
                      $scope.showAlertBoxDuplicateEntry = true;
                    } else {
                      $scope.noVotePatternErr = false;
                      if ($scope.selectedVote === 'none') {
                        angular.forEach($scope.agendaCollection, function (
                          item
                        ) {
                          if (
                            (item.selectedVote === undefined ||
                              item.selectedVote === false) &&
                            item.isVotable !== 'N'
                          ) {
                            if (item.splitVotes === true) {
                              $scope.CalculateSumOfShares(item);
                              $scope.errormessage = '';
                            } else {
                              $scope.flag = 1;
                              $scope.errormessage = $filter('translate')(
                                'votingInstruction_error_msg'
                              );
                              $scope.noVotePatternErr = true;
                            }
                          }
                        });
                      }
                      if (!$scope.noVotePatternErr) {
                        if (
                          $scope.selectedUserType[0].userType !== 'NOMI' &&
                          $scope.selectedUserType[0].userType !== 'TRUS'
                        ) {
                          $scope.showTcOwneDialog = true;
                        } else if (
                          $scope.selectedUserType[0].userType === 'NOMI' ||
                          $scope.selectedUserType[0].userType === 'TRUS'
                        ) {
                          $scope.showTcNomiTrustDialog = true;
                        }
                      }
                    }
                  },
                  function (error) {
                    $log.debug(error);
                  }
                );
            }
          }
        } else {
          $scope.errorHere = false;
          // Service call to check whether the voting instruction is already submitted for the current
          // combination of share class, intermediary and number of shares
          voteInstructionService
            .checkVotingRegistrationList(
              $stateParams.meetingId,
              $scope.selectedSecurityClass.shareclassId,
              $sessionStorage.groupId,
              $scope.profileDetails.lastName,
              $scope.selectedQuantityNoOfShares,
              0
            )
            .then(
              function (response) {
                if (response.data.length > 0) {
                  $scope.duplicateRequest = true;
                  $scope.modalBody =
                    'There is already a voting instruction registered for ' +
                    response.data[0].shareholderName +
                    ' for ' +
                    $scope.selectedQuantityNoOfShares +
                    ' securities. Are you sure you want to continue as this might be a duplicate entry?';
                  $scope.showAlertBoxDuplicateEntry = true;
                } else {
                  $scope.noVotePatternErr = false;
                  if ($scope.selectedVote === 'none') {
                    angular.forEach($scope.agendaCollection, function (item) {
                      if (
                        (item.selectedVote === undefined ||
                          item.selectedVote === false) &&
                        item.isVotable !== 'N'
                      ) {
                        if (item.splitVotes === true) {
                          $scope.CalculateSumOfShares(item);
                          $scope.errormessage = '';
                        } else {
                          $scope.flag = 1;
                          $scope.errormessage = $filter('translate')(
                            'votingInstruction_error_msg'
                          );
                          $scope.noVotePatternErr = true;
                        }
                      }
                    });
                  }
                  if (!$scope.noVotePatternErr) {
                    if (
                      $scope.selectedUserType[0].userType !== 'NOMI' &&
                      $scope.selectedUserType[0].userType !== 'TRUS'
                    ) {
                      $scope.showTcOwneDialog = true;
                    } else if (
                      $scope.selectedUserType[0].userType === 'NOMI' ||
                      $scope.selectedUserType[0].userType === 'TRUS'
                    ) {
                      $scope.showTcNomiTrustDialog = true;
                    }
                  }
                }
              },
              function (error) {
                $log.debug(error);
              }
            );
        }
      };
      // Below is the function to submit voting instructions in different workstations
      $scope.confirmVoting = function () {
        var splitVoteError = false;
        if ($scope.shTitleRequired) {
          $scope.shTitle = $scope.shareHolderVO.selectedTitle[0].title;
        } else {
          $scope.shTitle = null;
        }
        $scope.intermediaryId = $sessionStorage.groupId;
        $scope.shareholderId = 0;
        $scope.saveAgendaCollection = [];

        if ($scope.flag === 0 && $scope.noOfSharesErrorFlag === 0) {
          $scope.loadingSpin = true;
          if (
            $scope.selectedQuantityNoOfShares != null &&
            $scope.selectedQuantityNoOfShares !== ''
          ) {
            $scope.noOfSecurities = $scope.selectedQuantityNoOfShares;
            $scope.entireHoldingRadio = 'N';
          } else {
            $scope.noOfSecurities = $scope.noOfShares;
            $scope.entireHoldingRadio = 'Y';
          }

          $scope.selectedIntermediary = null;

          angular.forEach($scope.agendaCollection, function (item) {
            if (item.splitVotes === true) {
              item.splitVote = 'Y';
            } else {
              item.splitVote = 'N';
            }
            item.noOfSecurities = item.totalSplitVotes;
            if (
              item.voteFor === 0 &&
              item.voteAgainst === 0 &&
              item.voteAbstain === 0 &&
              !$scope.noVotePatternErr
            ) {
              if (item.isVotable !== 'N') {
                $scope.errormessage = $filter('translate')(
                  'votingInstruction_error_msg'
                );
                $scope.noVotePatternErr = true;
                $scope.loadingSpin = false;
              }
            } else if (
              item.totalNumberSharesError !== '' &&
              item.totalNumberSharesError &&
              item.splitVote === 'Y' &&
              item.isVotable !== 'N'
            ) {
              $scope.noVotePatternErr = true;
              splitVoteError = true;
              $scope.loadingSpin = false;
            } else {
              //push only those agenda items which are votable. Else, it impacts the reports.
              if (item.isVotable !== 'N') {
                $scope.saveAgendaCollection.push({
                  agendaId: item.agendaId,
                  splitVote: item.splitVote,
                  voteFor: item.voteFor,
                  voteAgainst: item.voteAgainst,
                  voteAbstain: item.voteAbstain,
                  voteId: item.voteId,
                  isVotable: item.isVotable,
                });
              }
            }
          });
          if (!$scope.noVotePatternErr) {
            //SFCBP-2489 confirm/save button not enabled for normal intermediary
            var linkedIntermediaryId = '';
            if ($scope.selectedLinkedInt) {
              linkedIntermediaryId = $scope.selectedLinkedInt.intermediaryId;
            }
            $scope.VotingInstructionConfirmationVO = {
              meetingId: $stateParams.meetingId,
              instructionId: $scope.instructionId,
              shareholderId: $scope.shareholderId,
              shareClassId: $scope.selectedSecurityClass.shareclassId,
              linkedIntermediaryId: linkedIntermediaryId,
              prxoyIntermediaryFlag: $scope.prxoyIntermediaryFlag,
              registrationNo: $scope.registrationNo,
              enitireHolding: $scope.entireHoldingRadio,
              nosOfSecurities: parseInt($scope.noOfSecurities, 10),
              voteCollectorId: $scope.selectedVC.vcID,
              userVcType: $scope.selectedVC.userVcType,
              filePath: $scope.filePath,
              fileStatus: $scope.fileStatus,
              fileName: $scope.fileName,
              languageCode: 'EN',
              participationMethod: 'PRXY',
              shareHolderVo: {
                shareholderId: $scope.shareholderId,
                title: $scope.shTitle,
                initials: $scope.shareHolderVO.initials,
                firstName: $scope.profileDetails.firstName,
                lastName: $scope.profileDetails.lastName,
                contactPerson: $scope.profileDetails.contactPerson,
                legalEntityIdentifier:
                  $scope.profileDetails.legalEntityIdentifier,
                dob: $scope.profileDetails.dob,
                address: $scope.profileDetails.address,
                emailAddress: $scope.profileDetails.emailAddress,
                postalCode: $scope.profileDetails.postalCode,
                phoneNumber: $scope.profileDetails.phoneNumber,
                city: $scope.profileDetails.city,
                country: $scope.selectedCountry.isoCountryCode,
                isin: $scope.voteisin,
                securityAccountNumber: $scope.securityAccountNumber,
                proxyId: 0,
                shareHolderType: $scope.shareHolderType[0].type,
                owneType: $scope.selectedUserType[0].userType,
                shareholderPostition: $scope.shareholderPostition,
              },
              votingInstAgendaVOlist: $scope.saveAgendaCollection,
              issuerId: $stateParams.issuerId,
            };

            voteInstructionService
              .createVotingInstruction(
                $scope.VotingInstructionConfirmationVO,
                'intermediary'
              )
              .then(
                function (response) {
                  $scope.loadingSpin = false;
                  if ($stateParams.isLinkedMeeting) {
                    if ($scope.linkedMeetingData.length === 0) {
                      $scope.goToVotingInfo();
                    } else {
                      $scope.hasLinkedMeeting = true;
                      $scope.modalBody =
                        $filter('translate')('linkedMeeting_vote_label') +
                        ' ' +
                        $stateParams.linkedMeetingData[0].issuerName +
                        +' ' +
                        data[0].meetingName +
                        ', ' +
                        $filter('translate')('label_plsClick_Yes_toContinue');
                    }
                  } else {
                    getLinkedMeetingData();
                  }
                },
                function (error) {
                  $scope.message = null;
                  $scope.loadingSpin = false;
                  $scope.errormessage = error.data.errors[0].errorMessage;
                  $scope.modalBody = error.data.errors[0].errorMessage;
                  $scope.disableModalBtn = true;
                  //Below service call is added to send email to agent when the number of shares entered exceeds the total number of securities available
                  if (error.data.errors[0].errorCode === 'CBP_ERR_520') {
                    $scope.EmailData = {
                      votingInstType: 'vote',
                      languageCode: 'EN',
                      workStationType: $scope.userType,
                      meetingId: $stateParams.meetingId,
                      shareClassId: $scope.selectedSecurityClass.shareclassId,
                      intermediaryId: $scope.intermediaryId,
                      issuerId: $stateParams.issuerId,
                      loginUserId: $sessionStorage.userId,
                    };
                    voteInstructionService
                      .sendSharesExceededMail($scope.EmailData, 'secureToken')
                      .then(
                        function (response) {
                          $log.debug(response);
                        },
                        function (error) {
                          $log.debug(error);
                        }
                      );
                  }
                }
              );
          } else {
            if (!splitVoteError) {
              $scope.errormessage = $filter('translate')(
                'votingInstruction_error_msg'
              );
            }
            $scope.loadingSpin = false;
          }
        }
      };

      function getLinkedMeetingData() {
        $scope.loadingSpin = true;
        intermediaryService
          .getIntermediaryLinkedMeetings(
            $scope.VotingInstructionConfirmationVO.meetingId,
            $scope.VotingInstructionConfirmationVO.shareClassId
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.loadingSpin = false;
              if (data.length > 0) {
                $scope.linkedMeetingData = data;
                $scope.hasLinkedMeeting = true;
                $scope.modalBody =
                  $filter('translate')('linkedMeeting_vote_label') +
                  ' ' +
                  data[0].issuerName +
                  ' ' +
                  data[0].meetingName +
                  ', ' +
                  $filter('translate')('label_plsClick_Yes_toContinue');
              } else {
                $scope.goToVotingInfo();
              }
            },
            function (error) {
              $scope.loadingSpin = false;
            }
          );
      }

      $scope.goToVotingInfo = function () {
        $state.go($sessionStorage.fromState.name, $sessionStorage.fromParms);
      };

      $scope.goToLinkedMeeting = function () {
        voteInstructionService
          .getShareClassList(
            $scope.linkedMeetingData[0].meetingId,
            $scope.shareholderId,
            $scope.languageCode,
            'IN'
          )
          .query()
          .$promise.then(function (data) {
            $state.go('votingInstructionsIntermediaryLinkedMeeting', {
              linkedMeetingData: $scope.linkedMeetingData,
              issuerName: $scope.linkedMeetingData[0].issuerName,
              meetingId: $scope.linkedMeetingData[0].meetingId,
              issuerId: $scope.linkedMeetingData[0].issuerId,
              votingInstDetails: data,
              isLinkedMeeting: true,
              selectedSecurityClass: $scope.selectedSecurityClass,
              shareholderRegistrationDetailVO: $scope.registrationList,
              profileListData: $scope.profileListData,
              securityAccountNumber: $scope.securityAccountNumber,
              shareHolderVO:
                $scope.VotingInstructionConfirmationVO.shareHolderVo,
              shTitleRequired: $scope.shTitleRequired,
              breadCrumbDetails: [data[0].meetingName],
            });
          });
      };
      // Call the function for first time.
      agenda();

      $scope.formattedLocationString = () => {
        return formatLocationString([
          $scope.selectedSecurityClass?.locationOnTicket,
          $scope.selectedSecurityClass?.street,
          $scope.selectedSecurityClass?.postalCode,
          $scope.selectedSecurityClass?.city,
        ]);
      };
    },
  ]);
