/**
 * @ngdoc controller
 * @name generalMeetingHomeModule.ViewAttendanceRequestController
 * @description This controller is used general meeting module. This
 *              deals with attendance request related functionalities
 * @requires attendanceRequestService is the service which is being used to interact with REST
 * @requires ModelWindowService is used for modal window calls.
 * @requires $scope,$sessionStorage,$stateParams for storing/passing information in scope/session/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $filter to use the locale file
 */
angular
  .module('generalMeetingHomeModule')
  .controller('ViewAttendanceRequestController', [
    '$scope',
    '$sessionStorage',
    '$stateParams',
    '$log',
    '$filter',
    'attendanceRequestService',
    'fileDownloadSvc',
    'ModelWindowService',
    '$state',
    function (
      $scope,
      $sessionStorage,
      $stateParams,
      $log,
      $filter,
      attendanceRequestService,
      fileDownloadSvc,
      ModelWindowService,
      $state
    ) {
      $scope.status = $stateParams.status;
      $scope.gmId = $stateParams.gmId;
      $scope.issuerId = $stateParams.issuerId;
      $scope.issuerName = $stateParams.issuerName;
      $scope.gmDate = $stateParams.gmDate;
      $scope.reqId = $stateParams.attendanceReqId;
      $scope.langCode = 'EN';
      $scope.userId = $sessionStorage.userId;
      //$scope.loading is used for spinner
      $scope.loading = true;

      if ($sessionStorage.usertype === 'SP') {
        $scope.shareplanId = $sessionStorage.groupId;
        $scope.shareholderId = $sessionStorage.groupId;
      } else if ($sessionStorage.usertype === 'SH') {
        $scope.shareplanId = 0;
        $scope.shareholderId = $sessionStorage.groupId;
      } else {
        $scope.shareplanId = 0;
        $scope.shareholderId = 0;
      }

      //Below service call is to get the details of an attendance request.
      attendanceRequestService
        .getAttendanceRequest($stateParams.attendanceReqId)
        .query()
        .$promise.then(
          function (data) {
            $scope.attendanceReqDetails = data;
            $scope.loading = false;
          },
          function (error) {
            $scope.loading = false;
            $log.debug(error);
          }
        );

      /* download BO File starts here */
      $scope.downloadBOFile = function () {
        var downloadUrl =
          '/v2/gm/intermediary/attendance-requests/' +
          $scope.reqId +
          '/bo-document';
        var a = document.createElement('a');
        document.body.appendChild(a);
        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            let fileVO = response;
            if (window.navigator.msSaveOrOpenBlob) {
              a.onclick = function () {
                window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
              };
              a.click();
            } else {
              var fileURL = URL.createObjectURL(fileVO.file);
              a.href = fileURL;
              a.download = fileVO.fileName;
              a.click();
            }
            downloadUrl = '';
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      /* download BO File ends here */
    },
  ]);
