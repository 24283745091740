/**
 * @ngdoc controller
 * @name dwtModule.CreateDWTEventController
 * @description This controller is used for dwt module for create and edit tax reclaim events.
 * @requires $scope
 * @requires $stateParams to obtain page clicked (create or edit)
 * @requires dwtEvent to load the modal object for create and edit dwt events
 * @requires $filter
 * @requires createEditDwtService
 */
'use strict';

angular.module('dwtModule').controller('CreateDWTEventController', [
  '$scope',
  '$log',
  '$sessionStorage',
  'createEditDwtService',
  'dwtEvent',
  '$filter',
  '$state',
  '$stateParams',
  '$timeout',
  'ModelWindowService',
  'PaginationSvc',
  'userService',
  function (
    $scope,
    $log,
    $sessionStorage,
    createEditDwtService,
    dwtEvent,
    $filter,
    $state,
    $stateParams,
    $timeout,
    ModelWindowService,
    PaginationSvc,
    userService
  ) {
    $scope.editPage = false;
    $scope.flag = 0;
    $scope.loading = true;
    $scope.isinSet = false;
    $scope.validationErrorFlag = false;
    $scope.qrfStartvalErrorFlag = false;
    $scope.qrfEndvalErrorFlag = false;
    $scope.peStartvalErrorFlag = false;
    $scope.peEndvalErrorFlag = false;
    $scope.deleteModalWindow = false;
    $scope.taSubmissionErrorFlag = false;
    if ($sessionStorage.dwtCheckEventStatus) {
      $scope.status = $sessionStorage.dwtCheckEventStatus;
    } else {
      $scope.status = 'Draft';
    }
    $scope.count = 0;
    $scope.usertype = $sessionStorage.usertype;
    $scope.userId = $sessionStorage.userId;
    $scope.myQrfEndTime = new Date();
    $scope.myQrfEndTime.setHours(17);
    $scope.myQrfEndTime.setMinutes(0);
    $scope.myPeEndTime = new Date();
    $scope.myPeEndTime.setHours(17);
    $scope.myPeEndTime.setMinutes(0);
    $scope.hstep = 1;
    $scope.mstep = 1;
    $scope.options = { hstep: [1, 2, 3], mstep: [1, 5, 10, 15, 25, 30] };
    $scope.ismeridian = false;
    $scope.sendToIssuerReqFields = false;
    $scope.showPEData = false;
    $scope.eventStatus = $stateParams.eventStatus;
    $scope.tooltipText =
      '<div>' +
      $filter('translate')('label_createDwttooltip1') +
      '</div>' +
      '<div>' +
      $filter('translate')('label_createDwttooltip2') +
      '</div>' +
      '<div>' +
      $filter('translate')('label_createDwttooltip3') +
      '</div>' +
      '<div>' +
      $filter('translate')('label_createDwttooltip4') +
      '</div>' +
      '<div>' +
      $filter('translate')('label_createDwttooltip5') +
      '</div>' +
      '<div>' +
      $filter('translate')('label_createDwttooltip6') +
      '</div>';

    $scope.modalHeader = $filter('translate')(
      'label_createReqModalSendToIssuer'
    );
    $scope.modalBody = $filter('translate')(
      'label_dwtModalConfirmCreateTemplate'
    );
    $scope.modalFooter = '';
    $scope.modalLeftButton = $filter('translate')('label_confirm');
    $scope.modalRightButton = $filter('translate')('label_cancel');

    if (undefined !== $stateParams.dwtPage) {
      $scope.editPage = true;
    }
    //Method called on change of issuer name to populate corresponding isin and share class name
    $scope.changeIssuerName = function () {
      $scope.errorMessage = null;
      $scope.showPEData = true;
      createEditDwtService
        .getselectedIssuerShareClass(
          $sessionStorage.userId,
          $scope.dwtProfile.issuerName.issuerId
        )
        .then(
          function (response) {
            var issuerFilter = $filter('filter')(
              response.data.commonData.issuers,
              {
                isBinder: $scope.dwtProfile.issuerName.isBinder,
              },
              true
            );
            $scope.dwtProfile.isBinder = $scope.dwtProfile.issuerName.isBinder;
            $scope.issuerFilterData = issuerFilter[0];
            $scope.dwtProfile.issuerId = $scope.issuerFilterData.issuerId;
            if (
              undefined !== $scope.issuerFilterData.commentText &&
              null !== $scope.issuerFilterData.commentText &&
              '' !== $scope.issuerFilterData.commentText &&
              'null' !== $scope.dwtProfile.commentText
            ) {
              $scope.dwtProfile.commentText =
                $scope.issuerFilterData.commentText;
            } else {
              $scope.dwtProfile.commentText = '';
            }
            $scope.isins = issuerFilter[0].shareClassVOs;
            $scope.isins_temp = issuerFilter[0].shareClassVOs;
            if (
              undefined !== issuerFilter[0].shareClassVOs[0] &&
              !$sessionStorage.createData
            ) {
              $scope.dwtProfile.isin = issuerFilter[0].shareClassVOs[0];
              $scope.dwtProfile.shareclassId =
                issuerFilter[0].shareClassVOs[0].shareClassId;
              $scope.shareClassNames =
                issuerFilter[0].shareClassVOs[0].shareClassNameVoList;
              if (
                undefined !==
                issuerFilter[0].shareClassVOs[0].shareClassNameVoList[0]
              ) {
                $scope.dwtProfile.shareClassName =
                  issuerFilter[0].shareClassVOs[0].shareClassNameVoList[0].shareClassName;
              } else {
                $scope.dwtProfile.isin = null;
                $scope.dwtProfile.shareClassName = null;
              }
            } else if ($sessionStorage.createData) {
              if ($sessionStorage.createData.isin) {
                var isinsFil = $filter('filter')(
                  $scope.isins,
                  {
                    isin: $scope.dwtProfile.isin.isin,
                  },
                  true
                );
                $scope.dwtProfile.isin = isinsFil[0];
                $scope.dwtProfile.shareclassId = isinsFil[0].shareClassId;
                $scope.dwtProfile.shareClassName =
                  isinsFil[0].shareClassNameVoList[0].shareClassName;
              } else {
                $scope.dwtProfile.isin = null;
                $scope.dwtProfile.shareClassName = null;
                $scope.dwtProfile.newISIN =
                  $sessionStorage.createData.dwtEvent.newISIN;
                $scope.dwtProfile.newSecurityClassName =
                  $sessionStorage.createData.dwtEvent.newSecurityClassName;
              }
            } else {
              $scope.dwtProfile.isin = null;
              $scope.shareClassNames = '';
              $scope.dwtProfile.shareClassName = null;
            }

            if (null != issuerFilter[0].ibanQrf) {
              $scope.dwtProfile.ibanQrf = issuerFilter[0].ibanQrf;
              $scope.dwtProfile.directDebitQrf = true;
            } else if ($sessionStorage.createData) {
              $scope.dwtProfile.ibanQrf = $sessionStorage.createData.ibanQrf;
              $scope.dwtProfile.directDebitQrf =
                $sessionStorage.createData.directDebitQrf;
            } else {
              $scope.dwtProfile.ibanQrf = '';
              $scope.dwtProfile.directDebitQrf = false;
            }
            if (null != issuerFilter[0].ibanPe) {
              $scope.dwtProfile.ibanPe = issuerFilter[0].ibanPe;
              $scope.dwtProfile.directDebitPe = true;
            } else if ($sessionStorage.createData) {
              $scope.dwtProfile.ibanPe = $sessionStorage.createData.ibanPe;
              $scope.dwtProfile.directDebitPe =
                $sessionStorage.createData.directDebitPe;
            } else {
              $scope.dwtProfile.ibanPe = '';
              $scope.dwtProfile.directDebitPe = false;
            }
            if (!$sessionStorage.createData) {
              $scope.dwtProfile.rsin = issuerFilter[0].tdNumber;
              $scope.getTaxDeclarationNumber();
            } else if ($sessionStorage.createData) {
              $scope.dwtProfile.rsin = $sessionStorage.createData.rsin;
              $scope.dwtProfile.tdNumber = $sessionStorage.createData.tdNumber;
            }
            $scope.getPEShareholderDataDetails();
          },
          function (error) {
            $scope.loading = false;
            return error;
          }
        );
    };
    $scope.createDwtOnLoad = function () {
      //create DWT page logic
      createEditDwtService.getCreateDwtData($sessionStorage.userId).then(
        function (response) {
          $scope.dwtProfile = new dwtEvent(response.data);

          if (undefined !== response.data) {
            $scope.currencyOptions = response.data.commonData.currencyCodes;
            $scope.dividendOptions = response.data.commonData.dividendTypeCodes;
            if (
              undefined === $sessionStorage.createData ||
              null === $sessionStorage.createData
            ) {
              var currencyObject = $filter('filter')($scope.currencyOptions, {
                isoCurrencyCode: 'EUR',
              });
              $scope.dwtProfile.currency = currencyObject[0];

              var dividendObject = $filter('filter')($scope.dividendOptions, {
                dividendDescription: 'Cash',
              });
              $scope.dwtProfile.dividendType = dividendObject[0];
            }
            $scope.issuers = response.data.commonData.issuers;
            if (undefined !== $scope.dwtProfile.issuerName) {
              var issuerFil = $filter('filter')($scope.issuers, {
                isBinder: $scope.dwtProfile.issuerName.isBinder,
              });
              $scope.dwtProfile.issuerName = issuerFil[0];
              $scope.changeIssuerName();
            }
          }
          $scope.loading = false;
          if (
            undefined !== $sessionStorage.createData &&
            null !== $sessionStorage.createData
          ) {
            var currencyObj = $filter('filter')($scope.currencyOptions, {
              isoCurrencyCode: $scope.dwtProfile.currency.isoCurrencyCode,
            });
            $scope.dwtProfile.currency = currencyObj[0];

            var dividendObj = $filter('filter')($scope.dividendOptions, {
              dividendDescription:
                $scope.dwtProfile.dividendType.dividendDescription,
            });
            //ALM-11043 Time is resetting when we come back from add PE shareholder page
            $scope.myPeEndTime = new Date($sessionStorage.createData.peEndDate);
            $scope.myQrfEndTime = new Date(
              $sessionStorage.createData.qrfEndDate
            );
            //ALM-11043 end
            $scope.dwtProfile.dividendType = dividendObj[0];
            $scope.showPEData = true;
            $scope.getPEShareholderDataDetails();
          }
        },
        function (error) {
          $scope.loading = false;
          return error;
        }
      );
    };

    $scope.setDwtProfileObject = function (dataObject) {
      if (undefined !== dataObject.dwtEvent) {
        //Start - ALM-10952 - Default time for PE end date should be 17:00 if date is not given while crating
        if (dataObject.dwtEvent.peEndDate) {
          $scope.myPeEndTime = new Date(dataObject.dwtEvent.peEndDate);
        }
        //End - ALM-10952
        //for setting the time, in edit page starts here.
        $scope.myQrfEndTime = new Date(dataObject.dwtEvent.qrfEndDate);
        //for setting the time, in edit page ends here.
        $scope.dwtProfile = new dwtEvent(dataObject);
        $scope.dwtProfile.dividendGross = dataObject.dwtEvent.dividendGrossStr;
        $scope.dwtProfile.dividendNet = dataObject.dwtEvent.dividendNetStr;
        $scope.dwtProfile.dwtPerSecurity =
          dataObject.dwtEvent.dwtPerSecurityStr;
        $scope.dwtProfile.tempStatus = dataObject.dwtEvent.status;
        $scope.status = dataObject.dwtEvent.status;
        $sessionStorage.dwtCheckEventStatus = dataObject.dwtEvent.status;
        var currencyObject = $filter('filter')($scope.currencyOptions, {
          isoCurrencyCode: dataObject.dwtEvent.currency,
        });
        $scope.dwtProfile.currency = currencyObject[0];
        var dividendObject = $filter('filter')($scope.dividendOptions, {
          dividendDescription: dataObject.dwtEvent.dividendType,
        });
        $scope.dwtProfile.dividendType = dividendObject[0];
        if (dataObject.dwtEvent.isBinder) {
          var issuerOption = $filter('filter')($scope.issuers, {
            isBinder: dataObject.dwtEvent.isBinder,
          });
        } else {
          issuerOption = $filter('filter')($scope.issuers, {
            issuerId: dataObject.dwtEvent.issuerId,
          });
        }
        $scope.dwtProfile.issuerName = issuerOption[0];
        if ($scope.editPage && !$sessionStorage.createData) {
          createEditDwtService
            .getselectedIssuerShareClass(
              $sessionStorage.userId,
              $scope.dwtProfile.issuerId
            )
            .then(
              function (response) {
                var issuerFilter = $filter('filter')(
                  response.data.commonData.issuers,
                  {
                    isBinder: $scope.dwtProfile.isBinder,
                  },
                  true
                );
                $scope.isins = issuerFilter[0].shareClassVOs;
                $scope.issuerFilterData = issuerFilter[0];
                $scope.isins_temp = issuerFilter[0].shareClassVOs;
                if ($scope.dwtProfile.issuerName && $scope.isins_temp) {
                  var isinOptions = $filter('filter')($scope.isins_temp, {
                    isin: dataObject.dwtEvent.isin,
                  });
                  if (undefined !== isinOptions[0]) {
                    $scope.dwtProfile.isin = isinOptions[0];
                    $scope.dwtProfile.shareclassId =
                      isinOptions[0].shareClassId;
                    $scope.shareClassNames =
                      isinOptions[0].shareClassNameVoList;
                    if (undefined !== isinOptions[0].shareClassNameVoList[0]) {
                      $scope.dwtProfile.shareClassName =
                        isinOptions[0].shareClassNameVoList[0].shareClassName;
                    } else {
                      $scope.dwtProfile.isin = null;
                      $scope.dwtProfile.shareClassName = null;
                    }
                    createEditDwtService
                      .getPEShareHolderDetails(
                        $scope.dwtProfile.issuerId,
                        'DWT',
                        $sessionStorage.userId
                      )
                      .then(function (response) {
                        $scope.peShareholderDetails =
                          response.data.shareHolderViewVO;
                        if (
                          undefined !== $scope.dwtProfile.isin ||
                          null !== $scope.dwtProfile.isin
                        ) {
                          $scope.isinFilteredShareHolders = $filter('filter')(
                            $scope.peShareholderDetails,
                            {
                              isin: $scope.dwtProfile.isin.isin,
                            }
                          );
                          $scope.dummyItems = $scope.isinFilteredShareHolders;
                          $scope.setPage(1);
                        }
                      });
                  } else {
                    $scope.dwtProfile.isin = null;
                    $scope.dwtProfile.shareClassName = null;
                    $scope.dwtProfile.newISIN = dataObject.dwtEvent.isin;
                    $scope.dwtProfile.newSecurityClassName =
                      dataObject.dwtEvent.shareClassName;
                  }
                }
              },
              function (error) {
                $scope.loading = false;
                return error;
              }
            );
        }
        if ($scope.dwtProfile.issuerName && $scope.isins_temp) {
          createEditDwtService
            .getselectedIssuerShareClass(
              $sessionStorage.userId,
              $scope.dwtProfile.issuerId
            )
            .then(
              function (response) {
                var issuerFilter = $filter('filter')(
                  response.data.commonData.issuers,
                  {
                    isBinder: $scope.dwtProfile.isBinder,
                  },
                  true
                );
                $scope.issuerFilterData = issuerFilter[0];
              },
              function (error) {
                $scope.loading = false;
                return error;
              }
            );

          var isinOptions = $filter('filter')($scope.isins_temp, {
            isin: dataObject.dwtEvent.isin,
          });
          if (undefined !== isinOptions[0]) {
            $scope.dwtProfile.isin = isinOptions[0];
            $scope.dwtProfile.shareclassId = isinOptions[0].shareClassId;
            $scope.shareClassNames = isinOptions[0].shareClassNameVoList;
            if (undefined !== isinOptions[0].shareClassNameVoList[0]) {
              $scope.dwtProfile.shareClassName =
                isinOptions[0].shareClassNameVoList[0].shareClassName;
            } else {
              $scope.dwtProfile.isin = null;
              $scope.dwtProfile.shareClassName = null;
            }
            createEditDwtService
              .getPEShareHolderDetails(
                $scope.dwtProfile.issuerId,
                'DWT',
                $sessionStorage.userId
              )
              .then(function (response) {
                $scope.peShareholderDetails = response.data.shareHolderViewVO;
                if (
                  undefined !== $scope.dwtProfile.isin ||
                  null !== $scope.dwtProfile.isin
                ) {
                  $scope.isinFilteredShareHolders = $filter('filter')(
                    $scope.peShareholderDetails,
                    {
                      isin: $scope.dwtProfile.isin.isin,
                    }
                  );
                  $scope.dummyItems = $scope.isinFilteredShareHolders;
                  $scope.setPage(1);
                }
              });
          } else {
            $scope.dwtProfile.isin = null;
            $scope.dwtProfile.shareClassName = null;
            $scope.dwtProfile.newISIN = dataObject.dwtEvent.isin;
            $scope.dwtProfile.newSecurityClassName =
              dataObject.dwtEvent.shareClassName;
          }
        }
      }
      $scope.currencyDivType();
    };
    $scope.currencyDivType = function () {
      if ($scope.eventStatus === 'L') {
        $timeout(function () {
          if ($scope.dwtProfile.dividendType.dividendDescription === 'Stock') {
            document.getElementById(
              'label_DWTPercentagePerSecurity'
            ).disabled = true;
            document.getElementById(
              'label_nominalWithholdingTaxPerShare'
            ).disabled = true;
          } else if (
            $scope.dwtProfile.dividendType.dividendDescription === 'Cash'
          ) {
            document.getElementById(
              'label_DWTPercentagePerSecurity'
            ).disabled = true;
            document.getElementById(
              'label_dividendPerSecurityGross'
            ).disabled = true;
            document.getElementById(
              'label_dividendPerSecurityNet'
            ).disabled = true;
            document.getElementById('label_DWTperSecurity').disabled = true;
          }
        }, 0);
      }
    };

    if ($scope.editPage && !$sessionStorage.createData) {
      //Edit DWT page logic
      createEditDwtService.getCreateDwtData($sessionStorage.userId).then(
        function (response) {
          $scope.currencyOptions = response.data.commonData.currencyCodes;
          $scope.dividendOptions = response.data.commonData.dividendTypeCodes;
          $scope.issuers = response.data.commonData.issuers;
          createEditDwtService.getDwtEventDetails($stateParams.eventId).then(
            function (resp) {
              $scope.setDwtProfileObject(resp.data);
              $scope.loading = false;
              $scope.showPEData = true;
            },
            function (error) {
              $scope.loading = false;
              return error;
            }
          );
        },
        function (error) {
          $scope.loading = false;
          return error;
        }
      );
    } else {
      $scope.createDwtOnLoad();
    }

    $scope.getPEShareholderDataDetails = function () {
      createEditDwtService
        .getPEShareHolderDetails(
          $scope.dwtProfile.issuerName.issuerId,
          'DWT',
          $sessionStorage.userId
        )
        .then(function (response) {
          $scope.peShareholderDetails = response.data.shareHolderViewVO;
          if (
            undefined !== $scope.dwtProfile.isin ||
            null !== $scope.dwtProfile.isin
          ) {
            $scope.isinFilteredShareHolders = $filter('filter')(
              $scope.peShareholderDetails,
              {
                isin: $scope.dwtProfile.isin.isin,
              }
            );
            $scope.dummyItems = $scope.isinFilteredShareHolders;
            $scope.setPage(1);
          }
        });
    };

    // Below function is used for pagination
    $scope.setPage = function (page) {
      $scope.pager = [];
      if (page < 1 || page > $scope.pager.totalPages) {
        return;
      }
      // get pager object from service
      if ($scope.dummyItems != null) {
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
        $log.debug($scope.pager);
        $scope.peShareholderDatas = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
      }
    };

    //Method to populate QRF and PE start dates based on dividend record date input
    $scope.populateStartDates = function () {
      $scope.dwtProfile.qrfStartDate = new Date(
        $scope.dwtProfile.dividendRecordDate
      ).setDate(new Date($scope.dwtProfile.dividendRecordDate).getDate() + 1);
      if ($scope.dwtProfile.peReclaim === true) {
        $scope.dwtProfile.peStartDate = $scope.dwtProfile.qrfStartDate;
      }
    };

    //Method to populate QRF and PE start dates based on dividend record date input
    $scope.populateEndDates = function () {
      $scope.dwtProfile.qrfEndDate = new Date(
        $scope.dwtProfile.dividendPaymentDate
      ).setDate(new Date($scope.dwtProfile.dividendPaymentDate).getDate() + 21);
      if ($scope.dwtProfile.peReclaim === true) {
        $scope.dwtProfile.peEndDate = $scope.dwtProfile.qrfEndDate;
      }
      $scope.getTaxDeclarationNumber();
    };

    //Method to validate all the date inputs
    $scope.dateValidation = function () {
      $scope.errorMessage = null;
      $scope.validationErrorFlag = false;
      $scope.validationErrorMsg = '';
      $scope.qrfStartvalErrorFlag = false;
      $scope.qrfStartvalErrorMsg = '';
      $scope.qrfStartvalWarningFlag = false;
      $scope.qrfStartvalWarningMsg = '';
      $scope.qrfEndvalErrorFlag = false;
      $scope.qrfEndvalErrorMsg = '';
      $scope.qrfEndvalWarningFlag = false;
      $scope.qrfEndvalWarningMsg = '';
      $scope.peStartvalErrorFlag = false;
      $scope.peStartvalErrorMsg = '';
      $scope.peStartvalWarningFlag = false;
      $scope.peStartvalWarningMsg = '';
      $scope.peEndvalErrorFlag = false;
      $scope.peEndvalErrorMsg = '';
      $scope.peEndvalWarningFlag = false;
      $scope.peEndvalWarningMsg = '';
      $scope.taSubmissionErrorFlag = false;
      $scope.taSubmissionErrorMsg = '';
      if (
        new Date($scope.dwtProfile.dividendRecordDate) >
        new Date($scope.dwtProfile.dividendPaymentDate)
      ) {
        $scope.validationErrorFlag = true;
        $scope.validationErrorMsg = $filter('translate')(
          'label_error_dwtPaymentDate'
        );
      }

      if (
        new Date($scope.dwtProfile.qrfStartDate) <
        new Date($scope.dwtProfile.dividendRecordDate)
      ) {
        $scope.qrfStartvalErrorFlag = true;
        $scope.qrfStartvalErrorMsg = $filter('translate')(
          'label_error_dwtQRFStartDate'
        );
      } else if (new Date($scope.dwtProfile.qrfStartDate) <= new Date()) {
        $scope.qrfStartvalWarningFlag = true;
        $scope.qrfStartvalWarningMsg = $filter('translate')(
          'label_error_pastDate'
        );
      }

      if (
        new Date($scope.dwtProfile.qrfEndDate) <
        new Date($scope.dwtProfile.qrfStartDate)
      ) {
        $scope.qrfEndvalErrorFlag = true;
        $scope.qrfEndvalErrorMsg = $filter('translate')(
          'label_error_dwtQRFEndDate1'
        );
      } else if (
        new Date($scope.dwtProfile.qrfEndDate) <
        new Date($scope.dwtProfile.dividendRecordDate)
      ) {
        $scope.qrfEndvalErrorFlag = true;
        $scope.qrfEndvalErrorMsg = $filter('translate')(
          'label_error_dwtQRFEndDate2'
        );
      } else if (
        new Date($scope.dwtProfile.qrfEndDate) <
        new Date($scope.dwtProfile.dividendPaymentDate)
      ) {
        $scope.qrfEndvalErrorFlag = true;
        $scope.qrfEndvalErrorMsg = $filter('translate')(
          'label_error_dwtQRFEndDate3'
        );
      } else if (new Date($scope.dwtProfile.qrfEndDate) <= new Date()) {
        $scope.qrfEndvalWarningFlag = true;
        $scope.qrfEndvalWarningMsg = $filter('translate')(
          'label_error_pastDate'
        );
      }

      if (
        new Date($scope.dwtProfile.peStartDate) <
        new Date($scope.dwtProfile.dividendRecordDate)
      ) {
        $scope.peStartvalErrorFlag = true;
        $scope.peStartvalErrorMsg = $filter('translate')(
          'label_error_dwtPEStartDate'
        );
      } else if (new Date($scope.dwtProfile.peStartDate) <= new Date()) {
        $scope.peStartvalWarningFlag = true;
        $scope.peStartvalWarningMsg = $filter('translate')(
          'label_error_pastDate'
        );
      }

      if (
        new Date($scope.dwtProfile.peEndDate) <
        new Date($scope.dwtProfile.peStartDate)
      ) {
        $scope.peEndvalErrorFlag = true;
        $scope.peEndvalErrorMsg = $filter('translate')(
          'label_error_dwtPEEndDate1'
        );
      } else if (
        new Date($scope.dwtProfile.peEndDate) <
        new Date($scope.dwtProfile.dividendRecordDate)
      ) {
        $scope.peEndvalErrorFlag = true;
        $scope.peEndvalErrorMsg = $filter('translate')(
          'label_error_dwtPEEndDate2'
        );
      } else if (
        new Date($scope.dwtProfile.peEndDate) <
        new Date($scope.dwtProfile.dividendPaymentDate)
      ) {
        $scope.peEndvalErrorFlag = true;
        $scope.peEndvalErrorMsg = $filter('translate')(
          'label_error_dwtPEEndDate3'
        );
      } else if (new Date($scope.dwtProfile.peEndDate) <= new Date()) {
        $scope.peEndvalWarningFlag = true;
        $scope.peEndvalWarningMsg = $filter('translate')(
          'label_error_pastDate'
        );
      }

      if (
        new Date($scope.dwtProfile.taSubmissionDate) <
        new Date($scope.dwtProfile.qrfEndDate)
      ) {
        $scope.taSubmissionErrorFlag = true;
        $scope.taSubmissionErrorMsg = $filter('translate')(
          'label_error_dwtTaxSubmissionDate'
        );
      } else if ($scope.dwtProfile.peReclaim) {
        if (
          new Date($scope.dwtProfile.taSubmissionDate) <
          new Date($scope.dwtProfile.peEndDate)
        ) {
          $scope.taSubmissionErrorFlag = true;
          $scope.taSubmissionErrorMsg = $filter('translate')(
            'label_error_dwtTaxSubmissionDate'
          );
        }
      }
    };
    $scope.changeDirctDebitPeClaim = function () {
      if (!$scope.dwtProfile.peReclaim) {
        $scope.dwtProfile.directDebitPe = false;
      }
    };
    $scope.getTaxDeclarationNumber = function () {
      if (
        $scope.dwtProfile.dividendPaymentDate &&
        $scope.dwtProfile.rsin &&
        $scope.dwtProfile.issuerName
      ) {
        $scope.dividendPayDate = $filter('date')(
          $scope.dwtProfile.dividendPaymentDate,
          'yyyy-MM-dd HH:mm:ss'
        );
        var req = {
          rsin: $scope.dwtProfile.rsin,
          dividendPaymentDate: $scope.dividendPayDate,
          issuerId: $scope.dwtProfile.issuerName.issuerId,
        };
        createEditDwtService.getTaxDeclarationNumber(req, 'secureToken').then(
          function (response) {
            $scope.dwtProfile.tdNumber = response.data.dwtEvent.tdNumber;
          },
          function (error) {
            $log.debug(error);
            $scope.dwtProfile.tdNumber = '';
          }
        );
      }
    };

    $scope.calculateDivindendPerSecurity = function () {
      if ($scope.dwtProfile.dwtPercent > 100.0) {
        $scope.dividendPercentMaxError = true;
      } else {
        $scope.dividendPercentMaxError = false;
      }
      if (
        $scope.dwtProfile.dividendGross > 1000.0 ||
        ($scope.dwtProfile.dividendGross <= 0 &&
          $scope.dwtProfile.dividendType.dividendDescription === 'Cash')
      ) {
        $scope.dividendGrossMaxError = true;
      } else {
        $scope.dividendGrossMaxError = false;
      }
      if ($scope.dwtProfile.dividendGross) {
        $scope.dwtProfile.dividendNet = parseFloat(
          $scope.dwtProfile.dividendGross *
            (1 - $scope.dwtProfile.dwtPercent / 100)
        ).toFixed(10);
        $scope.dwtProfile.dwtPerSecurity = parseFloat(
          $scope.dwtProfile.dividendGross - $scope.dwtProfile.dividendNet
        ).toFixed(10);
      }
    };

    $scope.calculateDivindendPerSecurityFromNet = function () {
      if ($scope.dwtProfile.dividendGross && $scope.dwtProfile.dividendNet) {
        $scope.dwtProfile.dwtPerSecurity = parseFloat(
          $scope.dwtProfile.dividendGross - $scope.dwtProfile.dividendNet
        ).toFixed(10);
      }
    };

    $scope.calNominalWithholdingTax = function () {
      if ($scope.dwtProfile.nwt > 1000.0) {
        $scope.nwtMaxError = true;
      } else {
        $scope.nwtMaxError = false;
      }
    };
    $scope.changeNewIsinFlag = function () {
      if ($scope.dwtProfile.newISIN !== '') {
        $scope.isinSet = false;
      }
    };

    //         Method called on change of isin drop down to populate the share class name
    $scope.changeIssuerIsin = function () {
      if ($scope.dwtProfile.isin === '' || $scope.dwtProfile.isin === null) {
        $scope.dwtProfile.shareClassName = '';
        $scope.dwtProfile.isin = null;
        $scope.dwtProfile.newISIN = '';
        $scope.dwtProfile.newSecurityClassName = '';
        if ($scope.dwtProfile.newISIN) {
          $scope.taxProfile.newISIN.$setPristine(false);
        }
        if ($scope.dwtProfile.newSecurityClassName) {
          $scope.taxProfile.newSecurityClassName.$setPristine(false);
        }
      } else {
        var isinsFilter = $filter('filter')($scope.isins, {
          isin: $scope.dwtProfile.isin.isin,
        });
        $scope.dwtProfile.shareclassId = isinsFilter[0].shareClassId;
        $scope.shareClassNames = isinsFilter[0].shareClassNameVoList;
        if (isinsFilter[0].shareClassNameVoList[0].shareClassName) {
          $scope.dwtProfile.shareClassName =
            isinsFilter[0].shareClassNameVoList[0].shareClassName;
        } else {
          $scope.dwtProfile.shareClassName = '';
        }
      }
      if (
        undefined !== $scope.peShareholderDetails &&
        null !== $scope.peShareholderDetails &&
        $scope.dwtProfile.isin
      ) {
        $scope.isinFilteredShareHolders = $filter('filter')(
          $scope.peShareholderDetails,
          {
            isin: $scope.dwtProfile.isin.isin,
          }
        );
        $scope.dummyItems = $scope.isinFilteredShareHolders;
        $scope.setPage(1);
      }
    };

    $scope.dateErrorValidation = function () {
      $scope.noDateErrorFlag = false;
      if (
        $scope.validationErrorFlag === false &&
        $scope.qrfStartvalErrorFlag === false &&
        $scope.qrfEndvalErrorFlag === false &&
        $scope.peStartvalErrorFlag === false &&
        $scope.peEndvalErrorFlag === false &&
        $scope.taSubmissionErrorFlag === false
      ) {
        // No date validation error
        $scope.noDateErrorFlag = true;
      } else {
        $scope.noDateErrorFlag = false;
      }
    };

    $scope.successCallback = function (response) {
      $scope.message = response.data.message;
      $scope.failure = false;
      $scope.success = true;
      $scope.isinSet = true;
      $scope.loading = false;
      if (undefined !== response.data.dwtEvent) {
        $scope.setDwtProfileObject(response.data);
        $sessionStorage.createData = null;
      }
      if ($scope.sendToIssuer === true) {
        $scope.sendForApproval();
      }
    };
    $scope.errorCallback = function (error) {
      $scope.sendToIssuer = false;
      $sessionStorage.createData = null;
      $scope.isinSet = false;
      $scope.loading = false;
      if (
        ($scope.dwtProfile.tempStatus === 'Draft' ||
          $scope.dwtProfile.tempStatus === 'Rejected') &&
        $scope.editPage
      ) {
        $scope.dwtProfile.status = $scope.dwtProfile.tempStatus;
      } else if (!$scope.editPage) {
        $scope.dwtProfile.status = 'Draft';
      }
      $scope.dwtProfile.dividendRecordDate = $scope.dividendRecordDateTemp;
      $scope.dwtProfile.dividendPaymentDate = $scope.dividendPaymentDateTemp;
      $scope.dwtProfile.qrfStartDate = $scope.qrfStartDateTemp;
      $scope.dwtProfile.qrfEndDate = $scope.qrfEndDateTemp;
      $scope.dwtProfile.peStartDate = $scope.peStartDateTemp;
      $scope.dwtProfile.peEndDate = $scope.peEndDateTemp;
      $scope.dwtProfile.taSubmissionDate = $scope.taSubmissionDateTemp;
      var dataObject = { dwtEvent: $scope.dwtProfile };
      $scope.setDwtProfileObject(dataObject);
      $scope.failure = true;
      $scope.success = false;
      angular.forEach(error.data.dwtEvent.errors, function (value, key) {
        if (error.data.dwtEvent.errors[key].errorCode === 'ibanQrf') {
          $scope.taxProfile.IBANDirectDebitOfQRF.$setDirty();
          $scope.taxProfile.IBANDirectDebitOfQRF.$setValidity('pattern', false);
        }
        if (error.data.dwtEvent.errors[key].errorCode === 'ibanPe') {
          $scope.taxProfile.IBANDirectDebitOfPE.$setDirty();
          $scope.taxProfile.IBANDirectDebitOfPE.$setValidity('pattern', false);
        }
      });
      $scope.errorMessage = error.data.dwtEvent.errors[0].errorMessage;
    };

    //Method called to save the dwt event
    $scope.createDwtEvent = function (val) {
      //--------------------- re-setting the validation error's start
      $scope.taxProfile.dwtIssuerName.$setPristine();
      $scope.taxProfile.eventName.$setPristine();
      $scope.taxProfile.dwtCurrency.$setPristine();
      $scope.taxProfile.dwtDividentType.$setPristine();
      if ($scope.dwtProfile.peReclaim) {
        $scope.taxProfile.PEStartDate.$setPristine();
        $scope.taxProfile.PEEndDate.$setPristine();
      }
      $scope.taxProfile.rsin.$setValidity('required', true);
      if ($scope.dwtProfile.directDebitQrf) {
        $scope.taxProfile.IBANDirectDebitOfQRF.$setPristine();
      }
      if ($scope.dwtProfile.directDebitPe) {
        $scope.taxProfile.IBANDirectDebitOfPE.$setPristine();
      }
      $scope.taxProfile.dividendRecordDate.$setPristine();
      $scope.taxProfile.dividendPaymentDate.$setPristine();
      $scope.taxProfile.QRFStartDate.$setPristine();
      $scope.taxProfile.QRFEndDate.$setPristine();
      $scope.taxProfile.dateOfSubmission.$setPristine();
      $scope.taxProfile.tdNumber.$setPristine();
      $scope.taxProfile.tdNumber.$setPristine();
      $scope.taxProfile.DWTPercentagePerSecurity.$setValidity('required', true);
      if (!$scope.dwtProfile.isin && !$scope.dwtProfile.newISIN) {
        $scope.taxProfile.newISIN.$setPristine();
      }
      if (
        !$scope.dwtProfile.shareClassName &&
        !$scope.dwtProfile.newSecurityClassName
      ) {
        $scope.taxProfile.newSecurityClassName.$setPristine();
      }
      if ($scope.dwtProfile.dividendType.dividendDescription === 'Cash') {
        $scope.taxProfile.dividendPerSecurityGross.$setPristine();
        $scope.taxProfile.dividendPerSecurityNet.$setPristine();
        $scope.taxProfile.DWTperSecurity.$setPristine();
      }
      if ($scope.dwtProfile.dividendType.dividendDescription === 'Stock') {
        $scope.taxProfile.nominalWithholdingTaxPerShare.$setPristine();
      }
      //--------------- re-setting the validation error's ends

      $scope.continueSaveEvent = true;
      $scope.success = false;
      $scope.errorMessage = null;
      $scope.loading = true;
      if (!$scope.dwtProfile.issuerName) {
        $scope.failure = true;
        $scope.errorMessage = $filter('translate')(
          'label_createDwtIssuerNameReq'
        );
        $scope.continueSaveEvent = false;
      } else if (!$scope.dwtProfile.isin && !$scope.dwtProfile.newISIN) {
        $scope.failure = true;
        $scope.continueSaveEvent = false;
        if (
          !$scope.dwtProfile.shareClassName &&
          !$scope.dwtProfile.newSecurityClassName
        ) {
          $scope.taxProfile.newSecurityClassName.$setDirty();
          $scope.taxProfile.newSecurityClassName.$setValidity(
            'required',
            false
          );
          $scope.errorMessage = $filter('translate')(
            'label_createDwtNewIsinAndShareClassReq'
          );
        } else {
          $scope.errorMessage = $filter('translate')(
            'label_createDwtNewIsinReq'
          );
        }
        $scope.taxProfile.newISIN.$setDirty();
        $scope.taxProfile.newISIN.$setValidity('required', false);
      } else if (
        !$scope.dwtProfile.shareClassName &&
        !$scope.dwtProfile.newSecurityClassName
      ) {
        $scope.failure = true;
        $scope.taxProfile.newSecurityClassName.$setDirty();
        $scope.taxProfile.newSecurityClassName.$setValidity('required', false);
        $scope.errorMessage = $filter('translate')(
          'label_createDwtShareClassNameReq'
        );
        $scope.continueSaveEvent = false;
      }

      $scope.dateErrorValidation();
      if ($scope.noDateErrorFlag === false) {
        $scope.failure = true;
        $scope.errorMessage = $filter('translate')('label_createDwtSubmitErr');
        $scope.continueSaveEvent = false;
      }
      if ($scope.continueSaveEvent === true) {
        $scope.dwtProfile.issuerId = $scope.dwtProfile.issuerName.issuerId;
        $scope.dwtProfile.issuerName = $scope.dwtProfile.issuerName.issuerName;
        if ($scope.dwtProfile.isin) {
          $scope.dwtProfile.isin = $scope.dwtProfile.isin.isin;
        }
        $scope.dwtProfile.currency = $scope.dwtProfile.currency.isoCurrencyCode;
        if ($scope.dwtProfile.currency === 'Stock') {
          $scope.dwtProfile.dividendGross = 0;
          $scope.dwtProfile.dividendNet = 0;
          $scope.dwtProfile.dwtPerSecurity = 0;
        }
        $scope.dwtProfile.dividendType =
          $scope.dwtProfile.dividendType.dividendDescription;
        if ($scope.dwtProfile.dividendRecordDate) {
          $scope.dividendRecordDateTemp = $scope.dwtProfile.dividendRecordDate;
          $scope.dwtProfile.dividendRecordDate = $filter('date')(
            $scope.dwtProfile.dividendRecordDate,
            'yyyy-MM-dd HH:mm:ss'
          );
        } else {
          $scope.dividendRecordDateTemp = '';
        }
        if ($scope.dwtProfile.dividendPaymentDate) {
          $scope.dividendPaymentDateTemp =
            $scope.dwtProfile.dividendPaymentDate;
          $scope.dwtProfile.dividendPaymentDate = $filter('date')(
            $scope.dwtProfile.dividendPaymentDate,
            'yyyy-MM-dd HH:mm:ss'
          );
        } else {
          $scope.dividendPaymentDateTemp = '';
        }
        if ($scope.dwtProfile.qrfStartDate) {
          $scope.qrfStartDateTemp = $scope.dwtProfile.qrfStartDate;
          $scope.dwtProfile.qrfStartDate = $filter('date')(
            $scope.dwtProfile.qrfStartDate,
            'yyyy-MM-dd HH:mm:ss'
          );
        } else {
          $scope.qrfStartDateTemp = '';
        }
        if ($scope.dwtProfile.qrfEndDate) {
          $scope.qrfDate = new Date($scope.dwtProfile.qrfEndDate);
          $scope.qrfDate.setMinutes($scope.myQrfEndTime.getMinutes());
          $scope.qrfDate.setHours($scope.myQrfEndTime.getHours());
          $scope.qrfEndDateTemp = $scope.qrfDate;
          $scope.dwtProfile.qrfEndDate = $filter('date')(
            $scope.qrfDate,
            'yyyy-MM-dd HH:mm:ss'
          );
        } else {
          $scope.qrfEndDateTemp = '';
        }
        if ($scope.dwtProfile.peReclaim === true) {
          if ($scope.dwtProfile.peStartDate) {
            $scope.peStartDateTemp = $scope.dwtProfile.peStartDate;
            $scope.dwtProfile.peStartDate = $filter('date')(
              $scope.dwtProfile.peStartDate,
              'yyyy-MM-dd HH:mm:ss'
            );
          }
          if ($scope.dwtProfile.peEndDate) {
            $scope.peDate = new Date($scope.dwtProfile.peEndDate);
            $scope.peDate.setMinutes($scope.myPeEndTime.getMinutes());
            $scope.peDate.setHours($scope.myPeEndTime.getHours());
            $scope.peEndDateTemp = $scope.peDate;
            $scope.dwtProfile.peEndDate = $filter('date')(
              $scope.peDate,
              'yyyy-MM-dd HH:mm:ss'
            );
          }
        } else {
          $scope.dwtProfile.peStartDate = '';
          $scope.dwtProfile.peEndDate = '';
          $scope.dwtProfile.ibanPe = '';
          $scope.peEndDateTemp = '';
          $scope.peStartDateTemp = '';
        }
        if ($scope.dwtProfile.taSubmissionDate) {
          $scope.taSubmissionDateTemp = $scope.dwtProfile.taSubmissionDate;
          $scope.dwtProfile.taSubmissionDate = $filter('date')(
            $scope.dwtProfile.taSubmissionDate,
            'yyyy-MM-dd HH:mm:ss'
          );
        } else {
          $scope.taSubmissionDateTemp = '';
        }
        if (
          $scope.dwtProfile.nwt &&
          $scope.dwtProfile.dividendType === 'Stock'
        ) {
          $scope.dwtProfile.dwtPerSecurity = $scope.dwtProfile.nwt;
        }
        if ($scope.dwtProfile.directDebitQrf === false) {
          $scope.dwtProfile.ibanQrf = '';
        }
        if ($scope.dwtProfile.directDebitPe === false) {
          $scope.dwtProfile.ibanPe = '';
        }

        //Add a new ISIN service logic
        if (
          $scope.dwtProfile.newSecurityClassName &&
          $scope.dwtProfile.newISIN &&
          $scope.isinSet === false
        ) {
          $scope.requestData = {
            isin: $scope.dwtProfile.newISIN,
            prefix: '',
            shareclassName: $scope.dwtProfile.newSecurityClassName,
            langCode: 'EN',
            issuerId: $scope.dwtProfile.issuerId,
            userId: $sessionStorage.userId,
            productCode: 'DWT',
            showWarningMsg: false,
            isOverwrite: 'N',
          };
          createEditDwtService
            .createNewISIN($scope.requestData, 'secureToken')
            .then(
              function (responseISIN) {
                if (val === 'sToIss') {
                  $scope.dwtProfile.status = 'Pending_For_Approval';
                } else if (val === 'Draft') {
                  $scope.dwtProfile.status = 'Draft';
                }
                createEditDwtService
                  .getselectedIssuerShareClass(
                    $sessionStorage.userId,
                    $scope.dwtProfile.issuerId
                  )
                  .then(
                    function (response) {
                      var issuerFilter = $filter('filter')(
                        response.data.commonData.issuers,
                        {
                          isBinder: $scope.dwtProfile.isBinder,
                        },
                        true
                      );
                      $scope.isins_temp = issuerFilter[0].shareClassVOs;
                      $scope.isins = issuerFilter[0].shareClassVOs;
                      $scope.dwtProfile.isin =
                        responseISIN.data.shareclassReturnVO.isin;
                      $scope.dwtProfile.shareClassName =
                        responseISIN.data.shareclassReturnVO.shareclassName;
                      $scope.dwtProfile.shareclassId =
                        responseISIN.data.shareclassReturnVO.shareclassId;
                      $scope.isinSet = true;
                      $scope.dwtProfile.createDwtEvent(
                        $scope.successCallback,
                        $scope.errorCallback
                      );
                    },
                    function (error) {
                      $scope.loading = false;
                      return error;
                    }
                  );
              },
              function (error) {
                $scope.message = null;
                $scope.failure = true;
                $scope.success = false;
                $scope.loading = false;
                if (undefined !== error.data.errors) {
                  $scope.errorMessage = error.data.errors[0].errorMessage;
                } else {
                  $scope.errorMessage = error.data.message;
                }
                $scope.sendToIssuer = false;
                $sessionStorage.createData = null;
                $scope.isinSet = false;
                if (
                  ($scope.dwtProfile.tempStatus === 'Draft' ||
                    $scope.dwtProfile.tempStatus === 'Rejected') &&
                  $scope.editPage
                ) {
                  $scope.dwtProfile.status = $scope.dwtProfile.tempStatus;
                } else if (!$scope.editPage) {
                  $scope.dwtProfile.status = 'Draft';
                }
                $scope.dwtProfile.dividendRecordDate =
                  $scope.dividendRecordDateTemp;
                $scope.dwtProfile.dividendPaymentDate =
                  $scope.dividendPaymentDateTemp;
                $scope.dwtProfile.qrfStartDate = $scope.qrfStartDateTemp;
                $scope.dwtProfile.qrfEndDate = $scope.qrfEndDateTemp;
                $scope.dwtProfile.peStartDate = $scope.peStartDateTemp;
                $scope.dwtProfile.peEndDate = $scope.peEndDateTemp;
                $scope.dwtProfile.taSubmissionDate =
                  $scope.taSubmissionDateTemp;
                var dataObject = { dwtEvent: $scope.dwtProfile };
                $scope.setDwtProfileObject(dataObject);
              }
            );
        } else {
          if (val === 'sToIss') {
            $scope.dwtProfile.status = 'Pending_For_Approval';
          } else if (val === 'Draft') {
            $scope.dwtProfile.status = 'Draft';
          }
          $scope.dwtProfile.createDwtEvent(
            $scope.successCallback,
            $scope.errorCallback
          );
        }
      }
    };

    $scope.sendToIssuerModalWindow = function (val) {
      $scope.message = null;
      $scope.success = false;
      $scope.mandateFlag = false;
      $scope.dividentFlag = false;
      $scope.qrfFlag = false;
      $scope.peFlag = false;
      $scope.isinFlag = false;
      $scope.allMandateFieldsFlag = false;
      $scope.showModalWindow = false;
      $scope.onLoadSpinner = true;
      $scope.sendToIssuer = true;
      $scope.sendToIssuerReqFields = true;
      if ($scope.dwtProfile.issuerName) {
        $scope.dwtProfile.isBinder = $scope.dwtProfile.issuerName.isBinder;
      }
      if ($scope.dwtProfile.directDebitQrf === false) {
        $scope.dwtProfile.ibanQrf = '';
      }
      //---------------field validation starts
      if (!$scope.dwtProfile.issuerName) {
        $scope.taxProfile.dwtIssuerName.$setDirty();
        $scope.taxProfile.dwtIssuerName.$setValidity('required', false);
      }
      if (!$scope.dwtProfile.eventName) {
        $scope.taxProfile.eventName.$setDirty();
        $scope.taxProfile.eventName.$setValidity('required', false);
      }
      if (!$scope.dwtProfile.currency) {
        $scope.taxProfile.dwtCurrency.$setDirty();
        $scope.taxProfile.dwtCurrency.$setValidity('required', false);
      }
      if (!$scope.dwtProfile.dividendType) {
        $scope.taxProfile.dwtDividentType.$setDirty();
        $scope.taxProfile.dwtDividentType.$setValidity('required', false);
      }
      if ($scope.dwtProfile.peReclaim && !$scope.dwtProfile.peStartDate) {
        $scope.taxProfile.PEStartDate.$setDirty();
        $scope.taxProfile.PEStartDate.$setValidity('required', false);
      }
      if ($scope.dwtProfile.peReclaim && !$scope.dwtProfile.peEndDate) {
        $scope.taxProfile.PEEndDate.$setDirty();
        $scope.taxProfile.PEEndDate.$setValidity('required', false);
      }
      if (!$scope.dwtProfile.rsin) {
        $scope.taxProfile.rsin.$setDirty();
        $scope.taxProfile.rsin.$setValidity('required', false);
      }
      if ($scope.dwtProfile.directDebitQrf && !$scope.dwtProfile.ibanQrf) {
        $scope.taxProfile.IBANDirectDebitOfQRF.$setDirty();
        $scope.taxProfile.IBANDirectDebitOfQRF.$setValidity('required', false);
      }
      if (
        $scope.dwtProfile.peReclaim &&
        $scope.dwtProfile.directDebitPe &&
        !$scope.dwtProfile.ibanPe
      ) {
        $scope.taxProfile.IBANDirectDebitOfPE.$setDirty();
        $scope.taxProfile.IBANDirectDebitOfPE.$setValidity('required', false);
      }
      if (!$scope.dwtProfile.dividendRecordDate) {
        $scope.taxProfile.dividendRecordDate.$setDirty();
        $scope.taxProfile.dividendRecordDate.$setValidity('required', false);
      }
      if (!$scope.dwtProfile.dividendPaymentDate) {
        $scope.taxProfile.dividendPaymentDate.$setDirty();
        $scope.taxProfile.dividendPaymentDate.$setValidity('required', false);
      }
      if (!$scope.dwtProfile.qrfStartDate) {
        $scope.taxProfile.QRFStartDate.$setDirty();
        $scope.taxProfile.QRFStartDate.$setValidity('required', false);
      }
      if (!$scope.dwtProfile.qrfEndDate) {
        $scope.taxProfile.QRFEndDate.$setDirty();
        $scope.taxProfile.QRFEndDate.$setValidity('required', false);
      }
      if (!$scope.dwtProfile.taSubmissionDate) {
        $scope.taxProfile.dateOfSubmission.$setDirty();
        $scope.taxProfile.dateOfSubmission.$setValidity('required', false);
      }
      if (!$scope.dwtProfile.tdNumber || $scope.dwtProfile.tdNumber === ' ') {
        $scope.taxProfile.tdNumber.$setDirty();
        $scope.taxProfile.tdNumber.$setValidity('required', false);
      }
      if (!$scope.dwtProfile.dwtPercent) {
        $scope.taxProfile.DWTPercentagePerSecurity.$setDirty();
        $scope.taxProfile.DWTPercentagePerSecurity.$setValidity(
          'required',
          false
        );
      }
      if ($scope.dwtProfile.dividendType.dividendDescription === 'Cash') {
        if (!$scope.dwtProfile.dividendGross) {
          $scope.taxProfile.dividendPerSecurityGross.$setDirty();
          $scope.taxProfile.dividendPerSecurityGross.$setValidity(
            'required',
            false
          );
        }
        if (!$scope.dwtProfile.dividendNet) {
          $scope.taxProfile.dividendPerSecurityNet.$setDirty();
          $scope.taxProfile.dividendPerSecurityNet.$setValidity(
            'required',
            false
          );
        }
        if (!$scope.dwtProfile.dwtPerSecurity) {
          $scope.taxProfile.DWTperSecurity.$setDirty();
          $scope.taxProfile.DWTperSecurity.$setValidity('required', false);
        }
      }
      if ($scope.dwtProfile.dividendType.dividendDescription === 'Stock') {
        if (!$scope.dwtProfile.nwt) {
          $scope.taxProfile.nominalWithholdingTaxPerShare.$setDirty();
          $scope.taxProfile.nominalWithholdingTaxPerShare.$setValidity(
            'required',
            false
          );
        }
      }
      if (!$scope.dwtProfile.isin && !$scope.dwtProfile.newISIN) {
        $scope.taxProfile.newISIN.$setDirty();
        $scope.taxProfile.newISIN.$setValidity('required', false);
      }
      if (
        !$scope.dwtProfile.shareClassName &&
        !$scope.dwtProfile.newSecurityClassName
      ) {
        $scope.taxProfile.newSecurityClassName.$setDirty();
        $scope.taxProfile.newSecurityClassName.$setValidity('required', false);
      }
      //----------------field validation ends
      if ($scope.dwtProfile.directDebitPe === false) {
        $scope.dwtProfile.ibanPe = '';
      }
      if (
        $scope.dwtProfile.issuerName &&
        $scope.dwtProfile.eventName &&
        $scope.dwtProfile.rsin &&
        $scope.dwtProfile.tdNumber &&
        $scope.dwtProfile.dividendRecordDate &&
        $scope.dwtProfile.dividendPaymentDate &&
        $scope.dwtProfile.qrfStartDate &&
        $scope.dwtProfile.qrfEndDate &&
        $scope.dwtProfile.taSubmissionDate
      ) {
        if ($scope.dwtProfile.dividendType.dividendDescription === 'Cash') {
          if (
            $scope.dwtProfile.dwtPerSecurity &&
            $scope.dwtProfile.dividendGross &&
            $scope.dwtProfile.dividendNet &&
            $scope.dwtProfile.dwtPercent
          ) {
            $scope.dividentFlag = true;
          }
        } else if (
          $scope.dwtProfile.dividendType.dividendDescription === 'Stock'
        ) {
          if ($scope.dwtProfile.dwtPercent && $scope.dwtProfile.nwt) {
            $scope.dividentFlag = true;
          }
        }
        if (
          $scope.dwtProfile.directDebitQrf === true &&
          $scope.dwtProfile.ibanQrf
        ) {
          $scope.qrfFlag = true;
        } else if (
          $scope.dwtProfile.directDebitQrf === false &&
          $scope.dwtProfile.ibanQrf === ''
        ) {
          $scope.qrfFlag = true;
        }
        if (
          $scope.dwtProfile.directDebitPe === true &&
          $scope.dwtProfile.ibanPe
        ) {
          $scope.peFlag = true;
        } else if (
          $scope.dwtProfile.directDebitPe === false &&
          $scope.dwtProfile.ibanPe === ''
        ) {
          $scope.peFlag = true;
        }
        if (
          $scope.dwtProfile.directDebitQrf === false &&
          $scope.dwtProfile.directDebitPe === false
        ) {
          $scope.qrfFlag = true;
          $scope.peFlag = true;
        }
        if (
          $scope.dwtProfile.peReclaim === true &&
          $scope.dwtProfile.peStartDate &&
          $scope.dwtProfile.peEndDate
        ) {
          $scope.mandateFlag = true;
        } else if ($scope.dwtProfile.peReclaim === false) {
          $scope.mandateFlag = true;
        }
        if ($scope.dwtProfile.isin) {
          $scope.isinFlag = true;
        } else if (
          $scope.dwtProfile.newISIN &&
          $scope.dwtProfile.newSecurityClassName
        ) {
          $scope.isinFlag = true;
        }
      }
      $scope.dateErrorValidation();
      if (
        $scope.mandateFlag === true &&
        $scope.dividentFlag === true &&
        $scope.qrfFlag === true &&
        $scope.peFlag === true &&
        $scope.isinFlag === true &&
        $scope.noDateErrorFlag === true
      ) {
        $scope.allMandateFieldsFlag = true;
      }
      if (!$scope.allMandateFieldsFlag) {
        $scope.failure = true;
        $scope.success = false;
        $scope.errorFlag = true;
        $scope.taxProfile.dividendRecordDate.$setTouched();
        $scope.errorMessage = $filter('translate')('label_fillMandatoryInput');
        $scope.sendToIssuer = false;
      } else if ($scope.allMandateFieldsFlag) {
        $scope.failure = false;
        $scope.errorMessage = null;
        $scope.count = $scope.count + 1;
        $scope.request = null;
        $timeout(function () {
          if (val === 'I') {
            //send to issuer
            if (!$scope.issuerFilterData.activeMembers) {
              $scope.modalHeader = $filter('translate')(
                'label_createReqModalSendToIssuer'
              );
              $scope.modalBody = $filter('translate')(
                'label_no_active_user_for_issuer'
              );
              $scope.modalFooter = '';
              $scope.modalLeftButton = $filter('translate')('label_yes');
              $scope.modalRightButton = $filter('translate')('label_no');
            } else {
              $scope.modalHeader = $filter('translate')(
                'label_createReqModalSendToIssuer'
              );
              $scope.modalBody = $filter('translate')(
                'label_dwtModalConfirmCreateTemplate'
              );
              $scope.modalFooter = '';
              $scope.modalLeftButton = $filter('translate')('label_confirm');
              $scope.modalRightButton = $filter('translate')('label_cancel');
            }
            $timeout(function () {
              $('#issuerModal').trigger('click');
            }, 100);
            $scope.invokingMethod = 'I';
          } else if (val === 'A') {
            //send for approval
            $timeout(function () {
              $('#trail').trigger('click');
            }, 100);
            $scope.invokingMethod = 'A';
            $scope.modalHeader = $filter('translate')(
              'label_createReqModalApprove'
            );
            $scope.modalBody = $filter('translate')(
              'label_dwtModalApproveCreateTemplate'
            );
          }
          $('.modal-backdrop').remove();
        }, 1000);
      }
      // workaround to make the error condition work
      $timeout(function () {
        if (!$scope.allMandateFieldsFlag) {
          $scope.failure = true;
          $scope.success = false;
          $scope.errorFlag = true;
          $scope.errorMessage = $filter('translate')(
            'label_fillMandatoryInput'
          );
          $scope.sendToIssuer = false;
        }
      }, 700);
      // work around to make the success condition work with single click of send to issuer
      if ($scope.count === 1 && $scope.errorFlag === true) {
        if (val === 'I') {
          $('#sendToIssuerButton').click();
        } else if (val === 'A') {
          $('#sendToApprove').click();
        }
      }
    };

    $scope.sendForApproval = function () {
      if ($scope.invokingMethod === 'I') {
        //send to issuer
        $scope.request = {
          loggedInUserId: $sessionStorage.userId,
          eventId: $scope.dwtProfile.eventId,
          status: 'Pending_For_Approval',
          workstation: $sessionStorage.usertype,
        };
      } else if ($scope.invokingMethod === 'A') {
        //send for approval
        $scope.request = {
          loggedInUserId: $sessionStorage.userId,
          eventId: $scope.dwtProfile.eventId,
          status: 'Approved',
          workstation: $sessionStorage.usertype,
        };
      }
      if ($scope.success === true && null !== $scope.request) {
        createEditDwtService
          .dwtEventOperations(
            $scope.dwtProfile.eventId,
            $scope.request,
            'secureToken'
          )
          .then(
            function (response) {
              $scope.message = response.data.message;
              $scope.success = false;
              $scope.sendToIssuer = false;
              $('#successMsg').modal('show');
              $timeout(function () {
                $('#successMsg').modal('hide');
                $('.modal-backdrop').remove();
                if (response.data.dwtEvent.status === 'Draft') {
                  $sessionStorage.selectedButtonIndex = 0;
                } else if (
                  response.data.dwtEvent.status === 'Pending_For_Approval'
                ) {
                  $sessionStorage.selectedButtonIndex = 1;
                } else if (
                  response.data.dwtEvent.status === 'To_Be_Published'
                ) {
                  $sessionStorage.selectedButtonIndex = 2;
                } else if (response.data.dwtEvent.status === 'Approved') {
                  $sessionStorage.selectedButtonIndex = 3;
                }
                $sessionStorage.navFromCreateDwt = true;
                $state.go('taxReclaimHome', {
                  selectedIndex: $sessionStorage.taxReclaimIndexId,
                });
              }, 3000);
            },
            function (error) {
              $log.debug(error);
              $scope.message = null;
              $scope.failure = true;
              $scope.success = false;
              $scope.errorMessage = error.data.errors[0].errorMessage;
            }
          );
      } else {
        $scope.failure = true;
        $scope.success = false;
      }
    };

    $scope.cancelEvent = function () {
      $scope.sendToIssuer = false;
      $scope.count = 0;
    };

    $scope.addPESharholderNav = function () {
      $sessionStorage.createData = $scope.dwtProfile;
      if ($scope.editPage) {
        $state.go('editDwtAddPeShareholder', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          userGroupId: $scope.dwtProfile.issuerId,
          shareclassId: $scope.dwtProfile.shareclassId,
          eventId: $scope.dwtProfile.eventId,
          navFrom: 'eventPage',
        });
      } else {
        $state.go('createDwtAddPeShareholder', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          userGroupId: $scope.dwtProfile.issuerId,
          shareclassId: $scope.dwtProfile.shareclassId,
          eventId: $scope.dwtProfile.eventId,
          navFrom: 'eventPage',
        });
      }
    };
    $scope.editPESharholderNav = function (shareHolderId) {
      $sessionStorage.createData = $scope.dwtProfile;
      if ($scope.editPage) {
        $state.go('editDwtEditPeShareholder', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          userGroupId: $scope.dwtProfile.issuerId,
          shareholderId: shareHolderId,
          navFrom: 'eventPage',
          eventId: $scope.dwtProfile.eventId
        });
      } else {
        $state.go('createDwtEditPeShareholder', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          userGroupId: $scope.dwtProfile.issuerId,
          shareholderId: shareHolderId,
          navFrom: 'eventPage',
        });
      }
    };
    $scope.viewPESharholderNav = function (shareHolderId) {
      $sessionStorage.createData = $scope.dwtProfile;
      if ($scope.editPage) {
        $state.go('editDwtViewPeShareholder', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          userGroupId: $scope.dwtProfile.issuerId,
          shareholderId: shareHolderId,
        });
      } else {
        $state.go('createDwtViewPeShareholder', {
          selectedIndex: $sessionStorage.taxReclaimIndexId,
          userGroupId: $scope.dwtProfile.issuerId,
          shareholderId: shareHolderId,
        });
      }
    };
    $scope.showHideDetails = function (peDetails) {
      peDetails.showDetails = !peDetails.showDetails;
    };
    // function to update modal delete PE shareholders
    $scope.removePEShareholder = function (shareholderId, shareClassId) {
      $scope.deleteModalWindow = true;
      $scope.errorMessage = true;
      $scope.peShareholderId = shareholderId;
      $scope.peShareclassId = shareClassId;
      $scope.modalHeader = $filter('translate')('label_modalConfirmAction');
      $scope.modalBody = $filter('translate')('label_delete_confirmation');
    };
    // function call to Delete PE Shareholders
    $scope.deleteShareholder = function (shareholderId, shareClassId) {
      userService
        .deleteShareholder(
          shareholderId,
          shareClassId,
          $scope.userId,
          'secureToken'
        )
        .then(
          function (response) {
            $scope.deleteModalWindow = false;
            $scope.errorMessage = null;
            $scope.message = response.data.message;
            $('#successMsg').modal('show');
            $timeout(function () {
              $('#successMsg').modal('hide');
              $sessionStorage.createData = $scope.dwtProfile;
              $state.reload();
              $('.modal-backdrop').remove();
            }, 2000);
          },
          function (error) {
            $scope.loading = false;
            $scope.deleteModalWindow = false;
            $scope.errorMessage = error.data.errors[0].errorMessage;
          }
        );
    };
  },
]);
