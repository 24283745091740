import { formatDateTime } from '@/lib/date';

/**
 * @ngdoc controller
 * @name actionHomeModule.issuerActionHomeController
 * @description This controller is used for action home.
 * @requires issuerGmActionService for rest calls related to general meeting
 * @requires voteCollectorService for rest calls related to vote collector
 *           services
 * @requires $scope
 * @requires $log for debug logs
 * @requires $state for routing
 * @requires $sessionStorage
 * @requires $stateParams
 * @requires $filter
 */

angular.module('actionHomeModule').controller('issuerActionHomeController', [
  '$scope',
  '$log',
  '$state',
  '$stateParams',
  '$sessionStorage',
  'voteCollectorService',
  '$filter',
  'issuerGmActionService',
  function (
    $scope,
    $log,
    $state,
    $stateParams,
    $sessionStorage,
    voteCollectorService,
    $filter,
    issuerGmActionService
  ) {
    $scope.userType = $sessionStorage.usertype;
    $scope.agentName = $sessionStorage.userName;
    $scope.userId = $sessionStorage.userId;
    $scope.productTypes = $sessionStorage.productTypes;
    $scope.eventId = $stateParams.eventId;
    $scope.isAdmin = $sessionStorage.isAdmin;
    $scope.pageSize = appConstant.PAGE_SIZE;
    $scope.pageNumber = 1;
    $scope.count = '';
    $scope.showGMActions = false;
    $scope.languageCode = $sessionStorage.workstationData.selectedLang;
    $scope.formatDateTime = formatDateTime;

    //To show Gm action if Gm product available
    if (
      $scope.productTypes.length > 0 &&
      $scope.productTypes.indexOf('GM') !== -1
    ) {
      $scope.showGMActions = true;
    }

    var workStation = $scope.userType;
    var screenName = appConstant.IS_HOME_PAGE;
    var screenLocation = appConstant.BODY;
    var langCode = appConstant.OPTED_EN;
    voteCollectorService
      .getVCStaticText(workStation, screenName, screenLocation, langCode)
      .query()
      .$promise.then(
        function (data) {
          $scope.landingPageText = data.templateList[0].templateText;
          $log.debug(data);
        },
        function (error) {
          $log.debug(error);
        }
      );

    //on page load
    function init() {
      getGeneralMeetingsIssuerActionItem($scope.pageNumber, $scope.pageSize);
    }

    function getGeneralMeetingsIssuerActionItem(pageNumber, pageSize) {
      $scope.pageNumber = pageNumber;
      $scope.loadingGm = true;
      issuerGmActionService
        .getIssuerGeneralMeetingsActions(pageNumber, pageSize)
        .get()
        .$promise.then(
          function (data) {
            $scope.loadingGm = false;
            $scope.generalMeeting = data.list;
            $scope.count = data.totalElements;
          },
          function (error) {
            $scope.loadingGm = false;
            return error;
          }
        );
    }

    //pagination function
    $scope.getByPage = function (page) {
      getGeneralMeetingsIssuerActionItem(page, $scope.pageSize);
    };

    // handle user clicked on "Download registration list"
    $scope.onDownloadRegistrationList = function (meeting) {
      $sessionStorage.currentTab = 'registrations';
      $sessionStorage.actionFor = 'regList';
      var gmDateTime = $filter('cbpDate')(
        meeting.meetingDate,
        meeting.meetingTimeZone,
        true
      );
      $state.go('generalDetailsView', {
        meetingId: meeting.meetingId,
        gmDateTime: gmDateTime,
        status: meeting.meetingStatus,
        flag: 1,
        breadCrumbDetails: [meeting.meetingName],
      });
    };

    // if clicked on "Review/Approve GM"
    $scope.goToReviewApproveGm = function (meeting) {
      $sessionStorage.currentTab = null;
      var gmDateTime = $filter('cbpDate')(
        meeting.meetingDate,
        meeting.meetingTimeZone,
        true
      );
      $state.go('generalDetailsView', {
        meetingId: meeting.meetingId,
        gmDateTime: gmDateTime,
        status: meeting.meetingStatus,
        flag: 1,
        breadCrumbDetails: [meeting.meetingName],
      });
    };

    // if clicked on Approve Extension Request
    $scope.approveExtensionRequest = function (meeting) {
      var gmDateTime = $filter('cbpDate')(
        meeting.meetingDate,
        meeting.meetingTimeZone,
        true
      );
      $state.go('createIssuerExtensionRequest', {
        gmName: meeting.meetingName,
        gmDateTime: gmDateTime,
        generalMeetingId: meeting.meetingId,
        extReqRejected: true,
        gmStatus: meeting.meetingStatus,
      });
    };
    init();
  },
]);
