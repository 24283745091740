/**
 * @ngdoc controller
 * @name userMaintenanceModule.AddIssuerGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with adding new issuer group.
 * @requires userGroupService and fileUploadSvc are the service which are being
 *           used to interact with REST
 * @requires $scope for storing information in scope
 * @requires $state for state routing
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for fetching logged in userId from session storage
 * @requires $filter for message translation from locale file
 */

angular
  .module('userMaintenanceDwtModule')
  .controller('AddIssuerGroupDwtController', [
    '$scope',
    '$log',
    '$state',
    '$sessionStorage',
    '$filter',
    '$timeout',
    'userGroupService',
    'fileUploadSvc',
    'DwtIssuerGroup',
    'AG_IssuerRepository',
    function (
      $scope,
      $log,
      $state,
      $sessionStorage,
      $filter,
      $timeout,
      userGroupService,
      fileUploadSvc,
      DwtIssuerGroup,
      issuerRepository
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.comboboxOptions = [];
      $scope.issuerFilterOptions = [];
      $scope.comboboxText = '';
      $scope.issuerFilterValue = '';
      $scope.userGroupName = 'issuer';
      $scope.issuerGroupNameEmpty = false;
      var userId = $sessionStorage.userId;
      $scope.prodType = $sessionStorage.productType;
      $scope.dwtIssuerGroup = new DwtIssuerGroup(
        undefined,
        userId,
        $scope.prodType
      );

      this.$onInit = async () => {
        $scope.issuerFilterOptions = [];
        await this.fetchIssuers();
      };

      $scope.handleComboboxTextChange = (text) => {
        if (!text) {
          $scope.comboboxOptions = [];
        } else {
          $scope.comboboxOptions = $scope.issuerFilterOptions.filter((option) =>
            option.name.toLowerCase().includes(text.toLowerCase())
          );
        }
        $scope.issuerFilterValue = text;
      };

      $scope.handleComboboxClear = () => {
        $scope.issuerFilterValue = '';
        $scope.comboboxText = '';
      };

      $scope.handleOptionSelected = (option) => {
        $scope.comboboxText = option.name;
        $scope.comboboxOptions = [];
        $scope.issuerFilterValue = option.name;
      };

      function compareIssuers(a, b) {
        return a.id === b.id;
      }

      this.fetchIssuers = async () => {
        this.isFetchingIssuers = true;
        try {
          let issuers = await issuerRepository.listAll();

          issuers = issuers.filter((obj, index, self) => {
            return self.findIndex((t) => compareIssuers(t, obj)) === index;
          });

          $scope.issuerFilterOptions = [
            ...issuers.map((i) => ({
              name: i.name,
              value: i.id,
            })),
          ];

          if (this.issuerFilterValue) {
            this.comboboxText =
              $scope.issuerFilterOptions &&
              $scope.issuerFilterOptions.find(
                (option) => option.value === this.issuerFilterValue
              )?.name;
          }
        } finally {
          this.isFetchingIssuers = false;
          // $scope.$apply();
        }
      };

      /* date picker */
      $scope.openStartDate = function () {
        $scope.startDate.opened = true;
      };
      $scope.openEndDate = function () {
        $scope.endDate.opened = true;
      };
      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };
      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.startDate = {
        opened: false,
      };
      $scope.endDate = {
        opened: false,
      };
      /* date picker end */
      $scope.successCallback = function (data) {
        if (data.status === 200) {
          $scope.success = true;
          $scope.errorMsg = null;
          $scope.message = data.data.message;
          $scope.issuerGrpId = data.data.groupId;
          $('#successMsg').modal('show');
        } else {
          $scope.message = null;
          $scope.error = true;
          $scope.errorMsg = data.data.errors[0].errorMessage;
          if ('CBP_ERR_494' === data.data.errors[0].errorCode) {
            $scope.ibanPEClass = 'validation ng-invalid ng-dirty';
          } else {
            $scope.ibanPEClass = 'validation';
          }
          if ('CBP_ERR_495' === data.data.errors[0].errorCode) {
            $scope.ibanQRFClass = 'validation ng-invalid ng-dirty';
          } else {
            $scope.ibanQRFClass = 'validation';
          }
        }
      };
      $scope.callAddUser = function () {
        $state.go('userMaintenanceForDWT.addIssuerDwt', {
          userGroupId: $scope.issuerGrpId,
        });
      };
      $scope.callUserList = function () {
        $state.go('userMaintenanceForDWT.issuerGroupDwt', {});
      };
      $scope.errorCallback = function (error) {
        $scope.message = null;
        $scope.error = true;
        $scope.errorMsg = error.data.errors[0].errorMessage;
      };
      // The below function call is to store the data from UI
      // and pass it to
      // REST call for adding new group
      $scope.addGroupUser = function () {
        if ($scope.issuerFilterValue === '') {
          $scope.issuerGroupNameEmpty = true;
          return;
        }

        var uploadUrl = 'v1/issuers';
        $scope.error = false;
        if (
          ($scope.logoFile === null || $scope.logoFile === undefined) &&
          ($scope.agreeFile === null || $scope.agreeFile === undefined)
        ) {
          $scope.dwtIssuerGroup.addGroup(
            null,
            null,
            uploadUrl,
            userId,
            $scope.issuerFilterValue,
            $scope.successCallback,
            $scope.errorCallback
          );
        } else if (
          $scope.logoFile !== null &&
          $scope.logoFile !== undefined &&
          ($scope.agreeFile === null || $scope.agreeFile === undefined)
        ) {
          if ($scope.logoFile !== null && $scope.logoFile !== undefined) {
            if (
              'image' !== $scope.logoFile.type.substring(0, 5) ||
              $scope.logoFile.size > 256000
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('imgfileSize_Error');
            } else {
              $scope.error = false;
              $scope.dwtIssuerGroup.addGroup(
                $scope.logoFile,
                null,
                uploadUrl,
                userId,
                $scope.issuerFilterValue,
                $scope.successCallback,
                $scope.errorCallback
              );
            }
          }
        } else if (
          ($scope.logoFile === null || $scope.logoFile === undefined) &&
          $scope.agreeFile !== null &&
          $scope.agreeFile !== undefined
        ) {
          if (
            'Invalid Date' === $scope.dwtIssuerGroup.startDate ||
            'Invalid Date' === $scope.dwtIssuerGroup.endDate ||
            '' === $scope.dwtIssuerGroup.startDate ||
            '' === $scope.dwtIssuerGroup.endDate ||
            null === $scope.dwtIssuerGroup.startDate ||
            null === $scope.dwtIssuerGroup.endDate
          ) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('selectDate_error');
          } else {
            if (
              'pdf' !== $scope.agreeFile.type.slice(-3) ||
              $scope.agreeFile.size > 1000000
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('fileSize_1mbError');
            } else {
              if (
                new Date().getTime() > $scope.dwtIssuerGroup.endDate.getTime()
              ) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')(
                  'label_error_agrPastDate'
                );
              } else if (
                $scope.dwtIssuerGroup.startDate.getTime() >
                $scope.dwtIssuerGroup.endDate.getTime()
              ) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')(
                  'label_error_agrDateRange'
                );
              } else {
                $scope.error = false;
                $scope.dwtIssuerGroup.addGroup(
                  null,
                  $scope.agreeFile,
                  uploadUrl,
                  userId,
                  $scope.issuerFilterValue,
                  $scope.successCallback,
                  $scope.errorCallback
                );
              }
            }
          }
        } else if (
          $scope.logoFile !== null &&
          $scope.logoFile !== undefined &&
          $scope.agreeFile !== null &&
          $scope.agreeFile !== undefined
        ) {
          if (
            'image' !== $scope.logoFile.type.substring(0, 5) ||
            $scope.logoFile.size > 256000
          ) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('imgfileSize_Error');
          } else {
            if (
              'Invalid Date' === $scope.dwtIssuerGroup.startDate ||
              'Invalid Date' === $scope.dwtIssuerGroup.endDate ||
              '' === $scope.dwtIssuerGroup.startDate ||
              '' === $scope.dwtIssuerGroup.endDate ||
              null === $scope.dwtIssuerGroup.startDate ||
              null === $scope.dwtIssuerGroup.endDate
            ) {
              $scope.error = true;
              $scope.errorMsg = $filter('translate')('selectDate_error');
            } else {
              if (
                'pdf' !== $scope.agreeFile.type.slice(-3) ||
                $scope.agreeFile.size > 1000000
              ) {
                $scope.error = true;
                $scope.errorMsg = $filter('translate')('fileSize_1mbError');
              } else {
                if (
                  new Date().getTime() > $scope.dwtIssuerGroup.endDate.getTime()
                ) {
                  $scope.error = true;
                  $scope.errorMsg = $filter('translate')(
                    'label_error_agrPastDate'
                  );
                } else if (
                  $scope.dwtIssuerGroup.startDate.getTime() >
                  $scope.dwtIssuerGroup.endDate.getTime()
                ) {
                  $scope.error = true;
                  $scope.errorMsg = $filter('translate')(
                    'label_error_agrDateRange'
                  );
                } else {
                  $scope.error = false;
                  $scope.dwtIssuerGroup.addGroup(
                    $scope.logoFile,
                    $scope.agreeFile,
                    uploadUrl,
                    userId,
                    $scope.issuerFilterValue,
                    $scope.successCallback,
                    $scope.errorCallback
                  );
                }
              }
            }
          }
        }
      };
    },
  ]);
