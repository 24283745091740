/**
 * @ngdoc controller
 * @name loginModule.LoginCtrl
 * @description This controller is used for login related functionalities for
 *              all workstations
 * @requires AuthService is the service which is being used to interact with
 *           REST
 * @requires $scope,$rootScope, $sessionStorage for storing/passing information
 *           in scope/state/session
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $location
 * @requires TokenSvc
 * @requires menuService,voteInstructionService,attendanceRequestService
 * @requires $filter for translate
 */

export default [
  '$scope',
  '$rootScope',
  '$state',
  '$sessionStorage',
  '$log',
  'generalMeetingService',
  '$location',
  'AuthService',
  'TokenSvc',
  '$timeout',
  'menuService',
  '$filter',
  '$window',
  'voteInstructionService',
  'attendanceRequestService',
  'shareHolderService',
  'userGroupService',
  '$attrs',
  'User',
  function (
    $scope,
    $rootScope,
    $state,
    $sessionStorage,
    $log,
    generalMeetingService,
    $location,
    AuthService,
    TokenSvc,
    $timeout,
    menuService,
    $filter,
    $window,
    voteInstructionService,
    attendanceRequestService,
    shareHolderService,
    userGroupService,
    $attrs,
    User
  ) {
    $sessionStorage.usertype = $attrs.usertype;
    //SFCBP-2622
    if (
      $location &&
      $location.path() === '/shareplan' &&
      $location.search().token !== undefined &&
      $location.search().token !== null
    ) {
      $scope.loading = true;
      $sessionStorage.gmId = $location.search().gmid;
      $sessionStorage.issuerId = $location.search().isid;
      $sessionStorage.navToAttend = $location.search().ATTD;
      $sessionStorage.usertype = 'SP';
      $sessionStorage.navToVote = $location.search().VI;
      $sessionStorage.scid = $location.search().scid;

      TokenSvc.getPasswordResetFlag($location.search().token).then(
        function (resp) {
          $scope.loading = true;
          $scope.linkValidationProgress = false;
          $log.debug('resp', resp);
          /**
           * If reset token is valid, user navigates to
           * reset password page for entering new password
           */
          if (resp && resp.data && resp.data.pwdFlag === 'N') {
            $log.debug(resp.data);
            $timeout(function () {
              TokenSvc.checkPasswordResetUrl($location.search().token).then(
                function (response) {
                  $scope.loading = true;
                  if (response && response.data && response.data.emailAddress) {
                    $state.go('resetPassword', {
                      selectedIndex: 0,
                      passwordResetData: response.data,
                      location: $location,
                    });
                  }
                },
                function (err) {
                  $log.debug(err);
                }
              );
            }, 1000);
          }
        },
        function (err) {
          $log.debug(err);
        }
      );
      $timeout(function () {
        $scope.loading = false;
      }, 2000);
    }

    $scope.validate = () => {
      if (!$scope.username || !$scope.password) {
        $scope.errorMessage = $filter('translate')('label_loginFormEmpty');
        return false;
      } else {
        $scope.errorMessage = null;
        return true;
      }
    };

    $scope.login = function () {
      $scope.loading = true;
      var formData = {
        username: $scope.username,
        password: $scope.password,
        userType: $scope.usertype,
      };
      $sessionStorage.workstation = $scope.workstation;
      $sessionStorage.gmId = $location.search().gmid;
      $sessionStorage.issuerId = $location.search().isid;
      $sessionStorage.eventId = $location.search().eventId;
      $sessionStorage.intermediaryDeadlineDateStr = $location.search().inDeadlineDtStr;
      $sessionStorage.meetingName = $location.search().meetingName;
      $sessionStorage.allExtReqsRejected = $location.search().allExtReqsRejected;
      $sessionStorage.generalMeetingDateStr = $location.search().gmDateStr;
      $sessionStorage.status = $location.search().gmStatus;
      $sessionStorage.issuerName = $location.search().isName;
      $sessionStorage.viType = $location.search().type;
      $scope.sharePlanloginShareClassId = $location.search().scid;
      $scope.navToAttend = $location.search().ATTD;
      $scope.navToRegistrations = $location.search().RT;
      $scope.navToVote = $location.search().VI;
      $scope.navToEventDetails = $location.search().action;
      $scope.votingInstructionId = null;
      $scope.votingStatus = null;
      $sessionStorage.virtualReqId = $location.search().vpReqId;
      $sessionStorage.RequestType = $location.search().reqType;

      // following is a service call to authenticate the logged in user.
      AuthService.login(formData).then(
        async function (data) {
          if (data.status === 200) {
            // All the session variables which will be used in other modules are set here
            $scope.loading = false;
            $log.debug('Authentication is Successful');
            $sessionStorage.usertype = $scope.usertype;
            $sessionStorage.userId = data.data.userId;
            $sessionStorage.proxyType = data.data.proxyType;
            $sessionStorage.isPublicPage = false;
            if (!data.data.firstName) {
              $sessionStorage.userName = data.data.lastName;
            } else {
              $sessionStorage.userName =
                data.data.firstName + ' ' + data.data.lastName;
            }
            $sessionStorage.productType = data.data.prodType;
            var prodTypeArray = [];
            if (data.data.productTypes) {
              for (var i = 0; i < data.data.productTypes.length; i++) {
                prodTypeArray[i] = data.data.productTypes[i].productType;
                if ('DWT' === data.data.productTypes[i].productType) {
                  $sessionStorage.isAdminDWT =
                    data.data.productTypes[i].isAmdin;
                } else if ('GM' === data.data.productTypes[i].productType) {
                  $sessionStorage.isAdminGM = data.data.productTypes[i].isAmdin;
                }
              }
            }
            $sessionStorage.productTypes = prodTypeArray;
            $sessionStorage.agentId = data.data.userId;
            $sessionStorage.groupId = data.data.userGroupId;
            $sessionStorage.emailAddress = data.data.emailAddress;
            $sessionStorage.taxReclaimIndexId = menuService.findSelectedIndexId(
              $sessionStorage.productTypes,
              $sessionStorage.isAdminDWT
            );
            $sessionStorage.taxAuthorityUser = menuService.findTaxAuthorityUser(
              $sessionStorage.productTypes,
              $sessionStorage.isAdminDWT
            );
            var selectedlang;
            if (!$sessionStorage.workstationData) {
              selectedlang = $sessionStorage.selectedLang
                ? $sessionStorage.selectedLang
                : 'EN';
            } else {
              selectedlang = $sessionStorage.workstationData.selectedLang;
            }

            const currentUser = await User.getMeForWorkstation(
              $sessionStorage.usertype
            );

            if ($sessionStorage.usertype === 'IS') {
              $sessionStorage.issuerId = currentUser.issuer.issuerId;
            }

            var menuData = menuService.getMenuItemsByWorkStationId(
              $sessionStorage.usertype,
              selectedlang,
              $sessionStorage.gmId,
              $sessionStorage.issuerId,
              $sessionStorage.productTypes,
              $sessionStorage.isAdminDWT
            );

            $rootScope.$broadcast('onLogin', menuData);

            if ($sessionStorage.usertype === 'VC') {
              // If the logged in user is vote collector user, he is redirected to general meeting home page instead of action home page
              $state.go('generalMeetingHome', {
                selectedIndex: 0,
              });
            } else if (
              $sessionStorage.usertype === 'IS' &&
              $sessionStorage.gmId &&
              $sessionStorage.issuerId &&
              $scope.navToRegistrations
            ) {
              // when issuer uses link to directly login to registration's tab
              $sessionStorage.currentTab = 'registrations';
              $state.go('generalDetailsView', {
                meetingId: $sessionStorage.gmId,
                issuerId: $sessionStorage.issuerId,
              });
            } else if (
              $sessionStorage.usertype === 'AG' &&
              $sessionStorage.gmId
            ) {
              // when agent uses link to directly login to gm details tab
              $state.go('generalDetailsView', {
                meetingId: $sessionStorage.gmId,
                fromUrl: true,
              });
            } else if (
              $sessionStorage.usertype === 'IN' &&
              $sessionStorage.gmId &&
              $sessionStorage.issuerId &&
              $sessionStorage.viType == 'VI'
            ) {
              voteInstructionService
                .getIntermediaryVIDetails(
                  $sessionStorage.userId,
                  $sessionStorage.gmId
                )
                .query()
                .$promise.then(
                  function (data) {
                    $state.go('votingInfo', {
                      type: 'vote',
                      gmId: $sessionStorage.gmId,
                      issuerId: $sessionStorage.issuerId,
                      intermediaryDateTimeStamp: data.intermediaryDeadlineDate,
                      intermediaryDate: data.intermediaryDeadlineDateStr,
                      intermediaryAccessDate: data.intermediaryAccessDate,
                      intermediaryAccessDateStr: data.intermediaryAccessDateStr,
                      newEndDateTimeStamp: data.newEndDateTime,
                      issuerName: data.issuerName,
                      gmDate: data.generalMeetingDateStr,
                      allExtnReqRejected: data.allExtReqsRejected,
                      langCode: 'EN',
                      gmStatus: data.status,
                      breadCrumbDetails: [data.meetingName],
                    });
                  },
                  function (error) {
                    $scope.loading = false;
                    return error;
                  }
                );
            } else if (
              $sessionStorage.usertype === 'IN' &&
              $sessionStorage.gmId &&
              $sessionStorage.issuerId &&
              $sessionStorage.viType == 'AR'
            ) {
              voteInstructionService
                .getIntermediaryVIDetails(
                  $sessionStorage.userId,
                  $sessionStorage.gmId
                )
                .query()
                .$promise.then(
                  function (data) {
                    $state.go('attendanceReqMeetingInfo', {
                      type: 'attend',
                      gmId: $sessionStorage.gmId,
                      issuerId: $sessionStorage.issuerId,
                      intermediaryDateTimeStamp: data.intermediaryDeadlineDate,
                      intermediaryDate: data.intermediaryDeadlineDateStr,
                      intermediaryAccessDate: data.intermediaryAccessDate,
                      intermediaryAccessDateStr: data.intermediaryAccessDateStr,
                      newEndDateTimeStamp: data.newEndDateTime,
                      issuerName: data.issuerName,
                      gmDate: data.generalMeetingDateStr,
                      allExtnReqRejected: data.allExtReqsRejected,
                      langCode: 'EN',
                      gmStatus: data.status,
                      breadCrumbDetails: [data.meetingName],
                    });
                  },
                  function (error) {
                    $scope.loading = false;
                    return error;
                  }
                );
            } else if (
              $sessionStorage.usertype === 'IN' &&
              $sessionStorage.gmId &&
              $sessionStorage.issuerId &&
              $sessionStorage.viType == 'VA'
            ) {
              voteInstructionService
                .getIntermediaryVIDetails(
                  $sessionStorage.userId,
                  $sessionStorage.gmId
                )
                .query()
                .$promise.then(
                  function (data) {
                    $state.go('virtualParticipationView', {
                      gmId: $sessionStorage.gmId,
                      issuerId: $sessionStorage.issuerId,
                      issuerName: data.issuerName,
                      generalMeetingDateMillis: data.generalMeetingDateMillis,
                      intermediaryDeadlineDateMillis:
                        data.intermediaryDeadlineDateMiilis,
                      intermediaryAccessDateMillis:
                        data.intermediaryAccessDateMiilis,
                      timeZone: data.timeZone,
                      breadCrumbDetails: [data.meetingName],
                    });
                  },
                  function (error) {
                    $scope.loading = false;
                    return error;
                  }
                );
            } else if (
              $sessionStorage.usertype === 'IN' &&
              $sessionStorage.gmId
            ) {
              // when intermediary uses link to directly login to view Extension request screen
              $state.go('createIntermediaryExtensionRequest', {
                generalMeetingId: $sessionStorage.gmId,
                intermediaryDeadlineDateStr:
                  $sessionStorage.intermediaryDeadlineDateStr,
                gmName: $sessionStorage.meetingName,
                extReqRejected: true,
                allExtnReqRejected: $sessionStorage.allExtReqsRejected,
                gmDateTime: $sessionStorage.generalMeetingDateStr,
                issuerId: $sessionStorage.issuerId,
                userType: $sessionStorage.usertype,
                gmStatus: $sessionStorage.status,
                breadCrumbDetails: [$sessionStorage.meetingName],
                issuerName: $sessionStorage.issuerName,
                fromUrl: true,
              });
            } else if ($sessionStorage.usertype === 'SP') {
              $scope.loading = true;
              if (
                $sessionStorage.gmId &&
                $sessionStorage.issuerId &&
                $scope.navToVote
              ) {
                // If the logged in user is shareplan user, he is redirected to voting instruction page instead
                // of action home page
                voteInstructionService
                  .getShareClassList(
                    $sessionStorage.gmId,
                    $sessionStorage.groupId,
                    'EN',
                    $sessionStorage.usertype
                  )
                  .query()
                  .$promise.then(
                    function (data) {
                      $state.go('votingInstructions', {
                        selectedIndex: 0,
                        meetingId: $sessionStorage.gmId,
                        issuerId: $sessionStorage.issuerId,
                        shareHolderId: $sessionStorage.groupId,
                        sharePlanloginShareClassId:
                          $scope.sharePlanloginShareClassId,
                        votingInstDetails: data,
                        fromPage: 'throughUrl',
                        breadCrumbDetails: [],
                      });
                    },
                    function (error) {
                      $scope.loading = false;
                      return error;
                    }
                  );
              } else if (
                $sessionStorage.gmId &&
                $sessionStorage.issuerId &&
                $scope.navToAttend
              ) {
                attendanceRequestService
                  .getAttendanceRequestView(
                    $sessionStorage.gmId,
                    'EN',
                    $sessionStorage.groupId,
                    $sessionStorage.usertype
                  )
                  .query()
                  .$promise.then(
                    function (data) {
                      // Below is the function used to fetch issuer name and navigation - SFCBP-2622
                      userGroupService.getIssuerGroupLogo($scope.issuerId).then(
                        function (response) {
                          $scope.issuerGroupDetails = response.data;
                          $state.go('attendanceRequest', {
                            issuerId: $sessionStorage.issuerId,
                            gmId: $sessionStorage.gmId,
                            sharePlanId: $sessionStorage.groupId,
                            issuerName: response.data.issuerName,
                            selectedIndex: 0,
                            attendanceDataDeatils: data,
                            fromPage: 'throughUrl',
                            breadCrumbDetails: [data.meetingName],
                          });
                        },
                        function (error) {
                          $log.debug(error);
                        }
                      );
                    },
                    function (error) {
                      $scope.loading = false;
                      return error;
                    }
                  );
              } else {
                shareHolderService
                  .getSHGeneralMeetings()
                  .query()
                  .$promise.then(
                    function (data) {
                      $scope.generalMeetinglist = data;
                      if ($scope.generalMeetinglist.length === 0) {
                        $state.go('shMyVotes', {
                          type: 'attend',
                          radioVal: 'confvotes',
                          dropdownVal: 'L',
                          selectedIndex: 1,
                        });
                      } else if ($scope.generalMeetinglist.length > 0) {
                        generalMeetingService
                          .getCurrentCETTime()
                          .query()
                          .$promise.then(
                            function (data) {
                              var cetDate = $filter('filter')(
                                data,
                                { timezone: 'CET' },
                                true
                              );
                              var gmtDate = $filter('filter')(
                                data,
                                { timezone: 'GMT' },
                                true
                              );
                              $scope.generalMeetingSPlist = [];
                              var curDate = '';
                              angular.forEach(
                                $scope.generalMeetinglist,
                                function (item) {
                                  if (
                                    item.generalMeetingDateStr.indexOf('CET') >
                                    -1
                                  ) {
                                    curDate = cetDate[0].currentMillis;
                                  } else {
                                    curDate = gmtDate[0].currentMillis;
                                  }
                                  if (
                                    item.shareholderAccessDateMiilis <
                                      curDate &&
                                    item.shareholderDeadlineDateMiilis >
                                      curDate &&
                                    item.status === 'L'
                                  ) {
                                    $scope.generalMeetingSPlist.push(item);
                                  }
                                }
                              );
                              if ($scope.generalMeetingSPlist.length === 1) {
                                var shareholderId = $sessionStorage.groupId;
                                var languageCode = 'EN';
                                voteInstructionService
                                  .getShareClassList(
                                    $scope.generalMeetingSPlist[0]
                                      .generalMeetingId,
                                    shareholderId,
                                    languageCode,
                                    $sessionStorage.usertype
                                  )
                                  .query()
                                  .$promise.then(
                                    function (data) {
                                      if (data.length > 0) {
                                        $state.go('votingInstructions', {
                                          selectedIndex: 0,
                                          meetingId:
                                            $scope.generalMeetingSPlist[0]
                                              .generalMeetingId,
                                          issuerId:
                                            $scope.generalMeetingSPlist[0]
                                              .issuerId,
                                          issuerName:
                                            $scope.generalMeetingSPlist[0]
                                              .issuerName,
                                          shareHolderId:
                                            $sessionStorage.groupId,
                                          votingInstDetails: data,
                                          status:
                                            $scope.generalMeetingSPlist[0]
                                              .status,
                                          intermediaryDateTimeStamp:
                                            $scope.generalMeetingSPlist[0]
                                              .intermediaryDeadlineDate,
                                          intermediaryDate:
                                            $scope.generalMeetingSPlist[0]
                                              .intermediaryDeadlineDateStr,
                                          breadCrumbDetails: [],
                                        });
                                      } else {
                                        $state.go('shMyVotes', {
                                          type: 'attend',
                                          radioVal: 'confvotes',
                                          dropdownVal: 'L',
                                          selectedIndex: 1,
                                        });
                                      }
                                    },
                                    function (error) {
                                      return error;
                                    }
                                  );
                              } else if (
                                $scope.generalMeetingSPlist.length > 1
                              ) {
                                $state.go('gmList', {
                                  selectedIndex: 0,
                                });
                              } else {
                                $state.go('shMyVotes', {
                                  type: 'attend',
                                  radioVal: 'confvotes',
                                  dropdownVal: 'L',
                                  selectedIndex: 1,
                                });
                              }
                            },
                            function (error) {
                              $log.debug(error);
                            }
                          );
                      }
                    },
                    function (error) {
                      $scope.onLoadSpinner = false;
                      return error;
                    }
                  );
              }
            } else if ($sessionStorage.usertype === 'SH') {
              if ($sessionStorage.voteObject) {
                /*
                 * If the logged in user is shareholder user, he is redirected to voting instruction page instead of
                 * action home page. This also depends on the availability of vote object
                 */
                voteInstructionService
                  .getShareClassList(
                    $sessionStorage.voteObject.meetingId,
                    $sessionStorage.groupId,
                    'EN',
                    $sessionStorage.usertype
                  )
                  .query()
                  .$promise.then(
                    function (data) {
                      $state.go(
                        'shGMVotingInstructions',
                        {
                          selectedIndex: 1,
                          issuerName: $sessionStorage.voteObject.issuerName,
                          meetingId: $sessionStorage.voteObject.meetingId,
                          issuerId: $sessionStorage.voteObject.issuerId,
                          shareHolderId: $sessionStorage.groupId,
                          virtualMeetingFlag:
                            $sessionStorage.voteObject.virtualMeetingFlag,
                          votingInstDetails: data,
                          breadCrumbDetails: [data[0].meetingName],
                        },
                        {
                          location: true,
                        }
                      );
                    },
                    function (error) {
                      return error;
                    }
                  );
              } else if (
                $sessionStorage.attendanceRequestObject &&
                $sessionStorage.attendanceRequestObject.buttonFlag === 'AR'
              ) {
                /*
                 * If the logged in user is shareholder user, he is redirected to attendance request page
                 * instead of action home page. This also depends on the availability of attendance request
                 * object
                 */
                attendanceRequestService
                  .getAttendanceRequestView(
                    $sessionStorage.attendanceRequestObject.gmId,
                    'EN',
                    $sessionStorage.attendanceRequestObject.sharePlanId,
                    $sessionStorage.usertype
                  )
                  .query()
                  .$promise.then(
                    function (data) {
                      $state.go(
                        'shGMAttendanceRequest',
                        {
                          selectedIndex: 1,
                          issuerName:
                            $sessionStorage.attendanceRequestObject.issuerName,
                          issuerId:
                            $sessionStorage.attendanceRequestObject.issuerId,
                          gmId: $sessionStorage.attendanceRequestObject.gmId,
                          sharePlanId:
                            $sessionStorage.attendanceRequestObject.sharePlanId,
                          attendanceDataDeatils: data,
                          breadCrumbDetails: [data.meetingName],
                          virtualMeetingFlag:
                            $sessionStorage.attendanceRequestObject
                              .virtualMeetingFlag,
                        },
                        {
                          location: true,
                        }
                      );
                    },
                    function (error) {
                      return error;
                    }
                  );
              } else if (
                $sessionStorage.attendanceRequestObject &&
                $sessionStorage.attendanceRequestObject.buttonFlag === 'VR'
              ) {
                /*
                 * If the logged in user is shareholder user, he is redirected to virtualparticipation request page
                 * instead of action home page. This also depends on the availability of Virtual
                 * object
                 */
                $state.go(
                  'vaRequestStep1',
                  {
                    selectedIndex: 1,
                    issuerId: $sessionStorage.attendanceRequestObject.issuerId,
                    gmId: $sessionStorage.attendanceRequestObject.gmId,
                  },
                  {
                    location: true,
                  }
                );
              } else if (
                $sessionStorage.gmId &&
                $sessionStorage.virtualReqId &&
                $sessionStorage.RequestType === 'VP'
              ) {
                /*
                 * If the logged in user is shareholder user, he is redirected to 2FA Authentication page
                 *  If virtual meeting is enable
                 */
                shareHolderService
                  .joinMeetingStatus(
                    $sessionStorage.gmId,
                    $sessionStorage.virtualReqId
                  )
                  .then(
                    function (response) {
                      $state.go(
                        '2FAuthentication',
                        {
                          selectedIndex: 0,
                        },
                        {
                          location: true,
                        }
                      );
                    },
                    function (error) {
                      $state.go(
                        'shMyVirtualParticipation',
                        {
                          selectedIndex: 0,
                          selectedRequestStatus: 'ALL',
                        },
                        {
                          location: true,
                        }
                      );
                    }
                  );
              } else {
                /*
                 * If the logged in user is shareholder user, he is redirected to shareholder my events page instead
                 * of action home page. This happens only if vote object and attendance objects are not available.
                 */
                $state.go(
                  'shMyVirtualParticipation',
                  {
                    selectedIndex: 0,
                    selectedRequestStatus: 'ALL',
                  },
                  {
                    location: true,
                  }
                );
              }
            } else if (
              $sessionStorage.eventId &&
              $sessionStorage.issuerId &&
              !$scope.navToEventDetails
            ) {
              $state.go('dwtSingleExtensionRequest', {
                selectedIndex: $sessionStorage.taxReclaimIndexId,
                eventId: $sessionStorage.eventId,
                issuerId: $sessionStorage.issuerId,
              });
            } else if (
              $sessionStorage.gmId &&
              $sessionStorage.issuerId &&
              $sessionStorage.usertype === 'IS'
            ) {
              $state.go('createIssuerExtensionRequest', {
                intermediaryDeadlineDateStr:
                  $sessionStorage.intermediaryDeadlineDateStr,
                gmName: $sessionStorage.meetingName,
                gmDateTime: $sessionStorage.generalMeetingDateStr,
                generalMeetingId: $sessionStorage.gmId,
                extReqRejected: true,
                issuerId: $sessionStorage.issuerId,
                userType: $sessionStorage.usertype,
                gmStatus: $sessionStorage.status,
                breadCrumbDetails: [$sessionStorage.meetingName],
              });
            } else if (
              $sessionStorage.eventId &&
              $sessionStorage.issuerId &&
              $scope.navToEventDetails
            ) {
              $state.go('dwtDetails', {
                selectedIndex: $sessionStorage.taxReclaimIndexId,
                eventId: $sessionStorage.eventId,
                issuerId: $sessionStorage.issuerId,
                eventStatus: 'P',
              });
            } else if (
              $sessionStorage.taxReclaimIndexId === 0 &&
              $sessionStorage.isAdminDWT === 'R' &&
              $sessionStorage.taxAuthorityUser === true
            ) {
              $state.go('taxReclaimHome', {
                selectedIndex: $sessionStorage.taxReclaimIndexId,
              });
            } else if ($sessionStorage.usertype === 'IS') {
              $state.go('issuerActionHome', {
                selectedIndex: 0,
              });
            } else {
              // in all other workstations, logged in user is redirected to action home page
              $state.go('actionHome', {
                selectedIndex: 0,
              });
            }
          } else {
            // If the authentication fails
            $scope.loading = false;
            $log.debug('Authentication is failed');
            $scope.errorMessage = $filter('translate')(
              'label_enterValidCredentials'
            );
          }
        },
        function (error) {
          $scope.loading = false;
          $log.debug('Entered into error block');
          $log.debug(error.data.errorMessage);
          $log.debug(error.errorMessage);
          var errordata = JSON.stringify(error.data);
          $log.debug('errordata:', errordata);
          if (
            errordata.indexOf('CBP_ERR_62') !== -1 ||
            errordata.indexOf('INVALID_EMAIL') !== -1
          ) {
            $scope.errorMessage = $filter('translate')(
              'label_enterValidCredentials'
            );
          }
          if (errordata.indexOf('CBP_ERR_488') !== -1) {
            $scope.errorMessage = $filter('translate')('label_accountLocked');
          }
          if (errordata.indexOf('CBP_ERR_63') !== -1) {
            $scope.errorMessage = $filter('translate')('label_technicalError');
          }
          if (errordata.indexOf('SESSION_INVALID') !== -1) {
            $scope.errorMessage = $filter('translate')('label_sessionExpired');
          }
          if (errordata.indexOf('MIN_LEN_PASSWORD') !== -1) {
            $scope.errorMessage = $filter('translate')(
              'label_enterValidCredentials'
            );
          }
          if (errordata.indexOf('CBP_ERR_568') !== -1) {
            $state.go('changePassword', {
              selectedIndex: 0,
              passwordResetData: formData,
              passwordExpired: true,
            });
          }
        }
      );
    };

    $scope.$on(
      'onLanguageSwitch',
      function (event) {
        $scope.errorMessage = '';
      },
      true
    );

    $rootScope.$emit('$rootScope.isLoggedIn', function () {
      $rootScope.render = 'yes';
    });

    $scope.handleChange = function (name, value) {
      $scope[name] = value;
    };

    $scope.userType = $sessionStorage.usertype;
    $scope.iconMap = {
      AG: 'pr_profiles_wealth',
      IS: 'pr_building_appartment',
      IN: 'pr_addressbooks_addressbook_open_lines',
      SH: 'pr_investments_securities',
      SP: 'pr_paper',
      VC: 'pr_insurances_vault',
    };

    // To handle the forgot password flow
    $scope.requestForgotPassword = function () {
      if ($scope.username) {
        $scope.pwrdErrorMessage = '';
        $scope.resetPasswordProgress = true;
        TokenSvc.passwordResetRequest($scope.username, $scope.usertype).then(
          function (resp) {
            $log.debug(resp);
            $scope.resetPasswordProgress = false;
            $state.go('forgotPassword');
          },
          function (err) {
            $log.debug(err);
            $scope.pwrdErrorMessage = err.data.errors[0].errorMessage;
            $scope.resetPasswordProgress = false;
          }
        );
      } else {
        /**
         * Show error please enter your user id to initialize reset process
         */
        $scope.pwrdErrorMessage = $filter('translate')(
          'label_enterUserNameToPasswordReset'
        );
      }
    };
    $scope.register = function () {
      $state.go('registerSH');
    };
  },
];
