import getTermsOfUseUrl from '../../../lib/getTermsOfUseUrl';

/**
 * @ngdoc controller
 * @name generalMeetingHomeModule.AttendanceRequestMeetingController
 * @description This controller is used for attendance request meeting related
 *              functionalities
 * @requires $scope,$stateParams,$sessionStorage for storing/passing information
 *           in scope/state/session
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 * @requires attendanceRequestService, userGroupService, voteInstructionServicefor REST service
 *           calls
 * @requires PaginationSvc pagination service
 * @requires extReqResults for REST service calls
 * @requires ModelWindowService for modal window
 */
angular
  .module('generalMeetingHomeModule')
  .controller('AttendanceRequestMeetingController', [
    '$scope',
    '$sessionStorage',
    '$stateParams',
    '$filter',
    '$log',
    '$state',
    'attendanceRequestService',
    'PaginationSvc',
    'extReqResults',
    'ModelWindowService',
    'ExtensionRequestService',
    'generalMeetingService',
    'voteInstructionService',
    'userGroupService',
    'fileDownloadSvc',
    'cbpDownloadBlob',
    '$timeout',
    function (
      $scope,
      $sessionStorage,
      $stateParams,
      $filter,
      $log,
      $state,
      attendanceRequestService,
      PaginationSvc,
      extReqResults,
      ModelWindowService,
      ExtensionRequestService,
      generalMeetingService,
      voteInstructionService,
      userGroupService,
      fileDownloadSvc,
      cbpDownloadBlob,
      $timeout
    ) {
      $scope.gmId = $stateParams.gmId;
      $scope.type = $stateParams.type;
      $scope.issuerId = $stateParams.issuerId;
      $scope.userId = $sessionStorage.userId;
      $scope.userGroupId = $sessionStorage.groupId;
      $scope.issuerName = $stateParams.issuerName;
      $scope.gmDate = $stateParams.gmDate;
      $scope.intermediaryDate = $stateParams.intermediaryDate;
      $scope.langCode = $stateParams.langCode;
      $scope.allExtnReqRejected = $stateParams.allExtnReqRejected;
      $scope.userType = $sessionStorage.usertype;
      $scope.editRTSattend = $sessionStorage.editRTSattend;
      $scope.langCode = 'EN';
      $scope.eventType = 'Footer Term of Use';
      $scope.extnRequestModelConfig = {};
      $scope.shareClassModelConfig = {};
      $scope.NoOfSharesErrorConfig = {};
      $scope.statusType = 'P';
      $scope.gmStatus = $stateParams.gmStatus;
      $scope.pageNo = 1;
      $scope.disableExtReq = false;
      $scope.hasApprovedExtnRequest = false;
      //Below value is hard coded to GM because VI and AR functionalities will be there only for GM users.
      //Using productType array from session storage was causing issue in case of integrated users, so it is relpaced with below value.
      $scope.productTypeToU = 'GM';
      //initializing search flag as false, and search string as an empty string
      $scope.sf = false;
      $scope.ss = '';
      var todaysDate = '';
      $scope.currentDate = '';
      generalMeetingService
        .getCurrentCETTime()
        .query()
        .$promise.then(
          function (data) {
            if ($stateParams.intermediaryDate.indexOf('CET') > -1) {
              todaysDate = $filter('filter')(data, { timezone: 'CET' }, true);
            } else if ($stateParams.intermediaryDate.indexOf('GMT') > -1) {
              todaysDate = $filter('filter')(data, { timezone: 'GMT' }, true);
            }
            $scope.currentDate = todaysDate[0].currentMillis;
            if (
              $stateParams.intermediaryDateTimeStamp <
              todaysDate[0].currentMillis
            ) {
              $scope.displayCreateExtn = true;
              $scope.displayFlag = true;
            } else {
              $scope.displayCreateExtn = false;
            }
            //Service call for fetching extension request status is move inside timestamp service as there is dependency on currentDate
            ExtensionRequestService.getIntermediaryExtnReqStatus(
              $scope.gmId,
              $sessionStorage.groupId
            )
              .query()
              .$promise.then(
                function (data) {
                  var dateArr = [];
                  if ($scope.gmDate.indexOf('GMT') > -1) {
                    dateArr = $scope.gmDate.split('GMT');
                  }
                  if ($scope.gmDate.indexOf('CET') > -1) {
                    dateArr = $scope.gmDate.split('CET');
                  }
                  if (dateArr[0] !== undefined) {
                    if (
                      data.isApprovedER === 'false' &&
                      $stateParams.intermediaryDateTimeStamp <
                        $scope.currentDate &&
                      new Date(dateArr[0]).getTime() > $scope.currentDate
                    ) {
                      $scope.hasApprovedExtnRequest = false;
                      $scope.displayCreateExtn = true;
                      $scope.disableExtReq = false;
                    } else {
                      $scope.disableExtReq = true;
                      $scope.displayCreateExtn = false;
                      $scope.hasApprovedExtnRequest = true;
                    }
                  } else {
                    if (
                      data.isApprovedER === 'false' &&
                      $stateParams.intermediaryDateTimeStamp <
                        $scope.currentDate &&
                      new Date($scope.gmDate).getTime() > $scope.currentDate
                    ) {
                      $scope.disableExtReq = false;
                      $scope.displayCreateExtn = true;
                      $scope.hasApprovedExtnRequest = false;
                    } else {
                      $scope.disableExtReq = true;
                      $scope.displayCreateExtn = false;
                      $scope.hasApprovedExtnRequest = true;
                    }
                  }
                },
                function (error) {
                  $scope.disableExtReq = false;
                  $log.debug(error);
                }
              );
          },
          function (error) {
            $log.debug(error);
          }
        );

      if (extReqResults.code) {
        $scope.extReqStatus = extReqResults.code;
      } else {
        $scope.extReqStatus = extReqResults.data.errors[0].errorCode;
      }
      if ($sessionStorage.selectedLang === 'nl') {
        $scope.statusButtons = [
          'nl_Pending',
          'nl_Accepted',
          'nl_Rejected',
          'nl_Cancelled',
        ];
      } else {
        $scope.statusButtons = ['Pending', 'Accepted', 'Rejected', 'Cancelled'];
      }

      if ($sessionStorage.usertype === 'SP') {
        $scope.shareplanId = $sessionStorage.groupId;
        $scope.shareholderId = $sessionStorage.groupId;
      } else if ($sessionStorage.usertype === 'SH') {
        $scope.shareplanId = 0;
        $scope.shareholderId = $sessionStorage.groupId;
      } else {
        $scope.shareplanId = 0;
        $scope.shareholderId = 0;
      }

      $scope.extnStatusButton = ['P', 'A', 'R', 'C'];

      // Below is the function call for pagination
      $scope.setPage = function (page) {
        $scope.pager = [];
        if (
          $scope.attReqCount_0 / (page * 10) > 1 &&
          page % 10 === 0 &&
          $scope.statusType === 'P'
        ) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          $scope.pageNo = $scope.pageNo + 1;
          if ($scope.pageNo <= Math.ceil($scope.attReqCount_0 / 100)) {
            attendanceRequestService
              .getshareholderRequest(
                $scope.type,
                $scope.statusType,
                $scope.userGroupId,
                $scope.gmId,
                $scope.langCode,
                $scope.pageNo,
                $scope.sf,
                $scope.ss
              )
              .query()
              .$promise.then(
                function (data) {
                  $scope.attendanceRequestList = $scope.attendanceRequestList.concat(
                    data
                  );
                  $scope.attendanceRequest = $scope.attendanceRequestList;
                  $scope.dummyItems = $scope.attendanceRequest;
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );
                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                  $sessionStorage.intRequestsSelectedPage =
                    $scope.pager.currentPage;
                },
                function (error) {
                  $log.debug(error);
                }
              );
          }
        } else if (
          $scope.attReqCount_1 / (page * 10) > 1 &&
          page % 10 === 0 &&
          $scope.statusType === 'A'
        ) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          $scope.pageNo = $scope.pageNo + 1;
          if ($scope.pageNo <= Math.ceil($scope.attReqCount_1 / 100)) {
            attendanceRequestService
              .getshareholderRequest(
                $scope.type,
                $scope.statusType,
                $scope.userGroupId,
                $scope.gmId,
                $scope.langCode,
                $scope.pageNo,
                $scope.sf,
                $scope.ss
              )
              .query()
              .$promise.then(
                function (data) {
                  $scope.attendanceRequestList = $scope.attendanceRequestList.concat(
                    data
                  );
                  $scope.attendanceRequest = $scope.attendanceRequestList;
                  $scope.dummyItems = $scope.attendanceRequest;
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );
                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                  $sessionStorage.intRequestsSelectedPage =
                    $scope.pager.currentPage;
                },
                function (error) {
                  $log.debug(error);
                }
              );
          }
        } else if (
          $scope.attReqCount_2 / (page * 10) > 1 &&
          page % 10 === 0 &&
          $scope.statusType === 'R'
        ) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          $scope.pageNo = $scope.pageNo + 1;
          if ($scope.pageNo <= Math.ceil($scope.attReqCount_2 / 100)) {
            attendanceRequestService
              .getshareholderRequest(
                $scope.type,
                $scope.statusType,
                $scope.userGroupId,
                $scope.gmId,
                $scope.langCode,
                $scope.pageNo,
                $scope.sf,
                $scope.ss
              )
              .query()
              .$promise.then(
                function (data) {
                  $scope.attendanceRequestList = $scope.attendanceRequestList.concat(
                    data
                  );
                  $scope.attendanceRequest = $scope.attendanceRequestList;
                  $scope.dummyItems = $scope.attendanceRequest;
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );
                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                  $sessionStorage.intRequestsSelectedPage =
                    $scope.pager.currentPage;
                },
                function (error) {
                  $log.debug(error);
                }
              );
          }
        } else if (
          $scope.attReqCount_3 / (page * 10) > 1 &&
          page % 10 === 0 &&
          $scope.statusType === 'C'
        ) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          $scope.pageNo = $scope.pageNo + 1;
          if ($scope.pageNo <= Math.ceil($scope.attReqCount_3 / 100)) {
            attendanceRequestService
              .getshareholderRequest(
                $scope.type,
                $scope.statusType,
                $scope.userGroupId,
                $scope.gmId,
                $scope.langCode,
                $scope.pageNo,
                $scope.sf,
                $scope.ss
              )
              .query()
              .$promise.then(
                function (data) {
                  $scope.attendanceRequestList = $scope.attendanceRequestList.concat(
                    data
                  );
                  $scope.attendanceRequest = $scope.attendanceRequestList;
                  $scope.dummyItems = $scope.attendanceRequest;
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );
                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                  $sessionStorage.intRequestsSelectedPage =
                    $scope.pager.currentPage;
                },
                function (error) {
                  $log.debug(error);
                }
              );
          }
        }
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }
        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
        // get current page of items
        $scope.items = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
        $sessionStorage.intRequestsSelectedPage = $scope.pager.currentPage;
      };

      //below is the function call to get issuer Logo
      $scope.getIssuerLogo = function (issuerId) {
        userGroupService.getIssuerGroupLogo(issuerId).then(
          function (response) {
            $scope.issuerLogoPresent = false;
            $scope.issuerGroupDetails = response.data;
            // Do not change the below condition. It is for issuer logo display fix. REST response has ""
            if ($scope.issuerGroupDetails.logoBaseStr) {
              $scope.issuerLogoPresent = true;
            }
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      //Below function is to sort records
      $scope.onColumnClick = function (newValue) {
        if (newValue === '' || !newValue) {
          return;
        }
        if ($scope.oldValue === newValue) {
          $scope.sortType = $scope.oldValue;
          $scope.dummyItems = $filter('orderBy')(
            $scope.dummyItems,
            newValue,
            true
          );
          $scope.sortReverse = !$scope.sortReverse;
          newValue = '';
        } else {
          $scope.sortReverse = false;
          $scope.sortType = newValue;
          $scope.dummyItems = $filter('orderBy')($scope.dummyItems, newValue);
        }
        $scope.pager = {};
        $scope.setPage($scope.pageNo);
        $scope.oldValue = newValue;
      };
      //Below is the function call to search when Enter key is pressed. and to set the search flag
      $scope.onEnterKey = function ($event) {
        if ($scope.ss) {
          $scope.sf = true;
        } else {
          $scope.sf = false;
        }
        if ($event.keyCode === 13) {
          $scope.getshareholderRequest($scope.statusType, 1);
        }
      };
      $scope.searchAttendanceVote = function () {
        if ($scope.ss) {
          $scope.sf = true;
        } else {
          $scope.sf = false;
        }
        $scope.getshareholderRequest($scope.statusType, 1);
      };
      // Below is the function call to get share holder attendance
      // or voting request
      $scope.getStatusCount = function () {
        attendanceRequestService
          .getshareholderRequestCount(
            $scope.type,
            $scope.userGroupId,
            $scope.gmId,
            $scope.langCode,
            $scope.sf,
            $scope.ss
          )
          .then(
            function (response) {
              $scope.attReqCount_1 = response.data.approve;
              $scope.attReqCount_0 = response.data.pending;
              $scope.attReqCount_3 = response.data.cancel;
              $scope.attReqCount_2 = response.data.reject;
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      $scope.getshareholderRequest = function (statusType, pageNo, successMsg) {
        $scope.fileloading = true;
        attendanceRequestService
          .getshareholderRequest(
            $scope.type,
            statusType,
            $scope.userGroupId,
            $scope.gmId,
            $scope.langCode,
            pageNo,
            $scope.sf,
            $scope.ss
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.attendanceRequestList = data;
              $scope.getStatusCount();
              $scope.attendanceRequest = $scope.attendanceRequestList;
              $sessionStorage.intSelectedButtonIndex =
                $scope.selectedButtonIndex;
              $scope.dummyItems = $scope.attendanceRequest;
              $scope.pager = {};
              $scope.setPage(1);
              if (successMsg) {
                $scope.success = true;
                $scope.errorMessage = '';
                $scope.successMessage = successMsg;
              } else {
                $scope.success = false;
              }
              $scope.fileloading = false;
            },
            function (error) {
              $log.debug(error);
              $scope.fileloading = false;
            }
          );
      };
      // Below is the function call to filter the data based on status
      $scope.buttonClicked = function ($index) {
        if ($index === 0) {
          $scope.statusType = 'P';
        } else if ($index === 1) {
          $scope.statusType = 'A';
        } else if ($index === 2) {
          $scope.statusType = 'R';
        } else if ($index === 3) {
          $scope.statusType = 'C';
        }
        $scope.pageNo = 1;
        $scope.getshareholderRequest($scope.statusType, $scope.pageNo);
        $scope.selectedButtonIndex = $index;
        $sessionStorage.intSelectedButtonIndex = $scope.selectedButtonIndex;
      };
      //Below code changes are done to retain the page in the active button and active page when we click on go back
      if (
        $sessionStorage.intSelectedButtonIndex &&
        $sessionStorage.intSelectedButtonIndex >= 0
      ) {
        $scope.selectedButtonIndex = $sessionStorage.intSelectedButtonIndex;
        $scope.selectedButton = $scope.buttonClicked(
          $scope.selectedButtonIndex
        );
      } else {
        $scope.selectedButtonIndex = 0;
      }

      $scope.updateModalARBulkUpload = function (
        reqId,
        shareholderId,
        registrationNo,
        boFileFlag,
        boFileName
      ) {
        generalMeetingService
          .getCurrentCETTime()
          .query()
          .$promise.then(
            function (data) {
              if ($stateParams.intermediaryDate.indexOf('CET') > -1) {
                todaysDate = $filter('filter')(data, { timezone: 'CET' }, true);
              } else if ($stateParams.intermediaryDate.indexOf('GMT') > -1) {
                todaysDate = $filter('filter')(data, { timezone: 'GMT' }, true);
              }
              if ($scope.disableExtReq == false) {
                $scope.displayCreateExtn = true;
                ModelWindowService.showModelWindow('extReqAlertBox');
                $scope.extnRequestModelConfig.modalBody = $filter('translate')(
                  'label_modalAttReqCreatErrMsg'
                );
              } else {
                if (
                  $stateParams.intermediaryDateTimeStamp <
                    todaysDate[0].currentMillis &&
                  ($scope.extReqStatus === 'CBP_MSG_51' ||
                    $scope.extReqStatus === 'CBP_MSG_43' ||
                    extReqResults.status === '422' ||
                    ($scope.extReqStatus === 'CBP_ERR_134' &&
                      !$scope.hasApprovedExtnRequest))
                ) {
                  $scope.displayCreateExtn = true;
                  ModelWindowService.showModelWindow('extReqAlertBox');
                  $scope.extnRequestModelConfig.modalBody = $filter(
                    'translate'
                  )('label_modalAttReqCreatErrMsg');
                }
              }
              if ($scope.displayCreateExtn === false) {
                $state.go('editARBulkUpload', {
                  gmId: $scope.gmId,
                  reqId: reqId,
                  issuerId: $scope.issuerId,
                  shareholderId: shareholderId,
                  gmDate: $scope.gmDate,
                  status: $scope.statusType,
                  registrationNo: registrationNo,
                  boFileFlag: boFileFlag,
                  boFileName: boFileName,
                  issuerName: $scope.issuerName,
                });
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      $scope.updateModalVIBulkUpload = function (
        selectedIndex,
        meetingId,
        shareholderId,
        issuerId,
        shareclassId,
        shareclassName,
        intermediaryId,
        instructionId,
        langCode,
        userType,
        status,
        numberOfSecurities,
        createdBy
      ) {
        generalMeetingService
          .getCurrentCETTime()
          .query()
          .$promise.then(
            function (data) {
              if ($stateParams.intermediaryDate.indexOf('CET') > -1) {
                todaysDate = $filter('filter')(data, { timezone: 'CET' }, true);
              } else if ($stateParams.intermediaryDate.indexOf('GMT') > -1) {
                todaysDate = $filter('filter')(data, { timezone: 'GMT' }, true);
              }
              if ($scope.disableExtReq == false) {
                $scope.displayCreateExtn = true;
                ModelWindowService.showModelWindow('extReqAlertBox');
                $scope.extnRequestModelConfig.modalBody = $filter('translate')(
                  'label_modalAttReqCreatErrMsg'
                );
              } else {
                if (
                  $stateParams.intermediaryDateTimeStamp <
                    todaysDate[0].currentMillis &&
                  ($scope.extReqStatus === 'CBP_MSG_51' ||
                    $scope.extReqStatus === 'CBP_MSG_43' ||
                    extReqResults.status === '422' ||
                    ($scope.extReqStatus === 'CBP_ERR_134' &&
                      !$scope.hasApprovedExtnRequest))
                ) {
                  $scope.displayCreateExtn = true;
                  ModelWindowService.showModelWindow('extReqAlertBox');
                  $scope.extnRequestModelConfig.modalBody = $filter(
                    'translate'
                  )('label_modalAttReqCreatErrMsg');
                }
              }
              if ($scope.displayCreateExtn === false) {
                attendanceRequestService
                  .validateIntermediary(
                    $scope.userGroupId,
                    $sessionStorage.usertype
                  )
                  .query()
                  .$promise.then(
                    function (response) {
                      $scope.validate = response.httpStatusCode;
                      voteInstructionService
                        .getShareClassList(
                          $scope.gmId,
                          $scope.shareholderId,
                          $scope.langCode,
                          $sessionStorage.usertype
                        )
                        .query()
                        .$promise.then(
                          function (data) {
                            if (data.length > 0) {
                              $state.go('editVotingReqIntermediary', {
                                selectedIndex: selectedIndex,
                                meetingId: meetingId,
                                shareholderId: shareholderId,
                                issuerId: issuerId,
                                issuerName: $scope.issuerName,
                                shareclassId: shareclassId,
                                shareclassName: shareclassName,
                                intermediaryId: intermediaryId,
                                instructionId: instructionId,
                                votingInstDetails: data,
                                langCode: langCode,
                                userType: userType,
                                status: status,
                                numberOfSecurities: numberOfSecurities,
                                createdBy: createdBy,
                              });
                            } else {
                              $scope.noVote = true;
                              $scope.shareClassModelConfig.modalHeader = $filter(
                                'translate'
                              )('label_votingInsCreatErr');
                              $scope.shareClassModelConfig.modalBody = $filter(
                                'translate'
                              )('label_modalShareVotingInsCreatErrMsg');
                              ModelWindowService.showModelWindow(
                                'shareClassAlertBox'
                              );
                            }
                          },
                          function (error) {
                            return error;
                          }
                        );
                    },
                    function (error) {
                      $scope.extnRequestModelConfig.modalHeader = $filter(
                        'translate'
                      )('label_votingInsCreatErr');
                      $scope.extnRequestModelConfig.modalBody =
                        error.data.errors[0].errorMessage;
                      ModelWindowService.showModelWindow('extReqAlertBox');
                      return error;
                    }
                  );
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      // Below is the method to update the texts of modal window based on Accept or Reject of request
      $scope.updateModal = function (
        type,
        reqId,
        status,
        numberOfSecurities,
        currentStatus,
        rightToSpeakForVote,
        rightToSpeakForAr,
        shareholderId,
        shareClassId
      ) {
        //First if condition added to check intermediary access date
        if (!numberOfSecurities) {
          numberOfSecurities = '0';
        }
        generalMeetingService
          .getCurrentCETTime()
          .query()
          .$promise.then(
            function (data) {
              if ($stateParams.intermediaryDate.indexOf('CET') > -1) {
                todaysDate = $filter('filter')(data, { timezone: 'CET' }, true);
              } else if ($stateParams.intermediaryDate.indexOf('GMT') > -1) {
                todaysDate = $filter('filter')(data, { timezone: 'GMT' }, true);
              }
              if ($scope.disableExtReq == false) {
                $scope.displayCreateExtn = true;
              } else {
                if (
                  $stateParams.intermediaryDateTimeStamp <
                  todaysDate[0].currentMillis
                ) {
                  $scope.displayCreateExtn = true;
                } else {
                  $scope.displayCreateExtn = false;
                }
              }
              if (
                $stateParams.intermediaryAccessDate >
                todaysDate[0].currentMillis
              ) {
                $scope.extnRequestModelConfig.modalHeader = '';
                $scope.extnRequestModelConfig.modalBody =
                  $filter('translate')('label_youCanAttend') +
                  $stateParams.intermediaryAccessDateStr;
                ModelWindowService.showModelWindow('extReqAlertBox');
              } else if (
                $scope.displayCreateExtn === true &&
                ($scope.extReqStatus === 'CBP_MSG_51' ||
                  $scope.extReqStatus === 'CBP_MSG_43' ||
                  extReqResults.status === '422' ||
                  ($scope.extReqStatus === 'CBP_ERR_134' &&
                    !$scope.hasApprovedExtnRequest))
              ) {
                $scope.extReqRejected = true;
                $scope.extnRequestModelConfig.modalHeader = $filter(
                  'translate'
                )('label_modalAttReqActionErrMsg');
                if (type === 'attend' && rightToSpeakForAr === true) {
                  $scope.extnRequestModelConfig.modalBody = $filter(
                    'translate'
                  )('label_modalRtsCancelErrMsg');
                } else if (type === 'vote') {
                  $scope.extnRequestModelConfig.modalBody = $filter(
                    'translate'
                  )('label_modalVotingInsCreatErrMsg');
                } else {
                  $scope.extnRequestModelConfig.modalBody = $filter(
                    'translate'
                  )('label_modalAttReqCreatErrMsg');
                }
                ModelWindowService.showModelWindow('extReqAlertBox');
              } else if (
                (!numberOfSecurities ||
                  numberOfSecurities === '0' ||
                  numberOfSecurities === 0) &&
                status !== 'R'
              ) {
                $scope.NoOfSharesErrorConfig.modalBody = $filter('translate')(
                  'label_noOfSharesError'
                );
                ModelWindowService.showModelWindow('noOfSharesBox');
              } else {
                $scope.type = type;
                $scope.reqId = reqId;
                $scope.status = status;
                $scope.currentStatus = currentStatus;
                $scope.shareholderId = shareholderId;
                $scope.shareClassId = shareClassId;
                $scope.noOfSecurities = numberOfSecurities;
                $scope.rightToSpeakForVote = rightToSpeakForVote;
                if (status === 'A') {
                  $scope.statusAction = $filter('translate')('label_accept');
                  if (
                    $scope.type === 'attend' ||
                    $scope.issuerName !== 'Telenet Group Holding NV' ||
                    $scope.issuerName === 'Greenyard N.V.'
                  ) {
                    $scope.showTelenetTerms = false;
                  }
                  if ($scope.currentStatus && $scope.currentStatus === 'X') {
                    $scope.showTerms4 = false;
                  } else {
                    $scope.showTerms4 = true;
                  }

                  $scope.termsOfUseUrl = getTermsOfUseUrl(
                    'GM',
                    $sessionStorage.workstationData.selectedLang
                  );

                  if (
                    $scope.issuerName === 'Telenet Group Holding NV' ||
                    $scope.issuerName === 'Greenyard N.V.'
                  ) {
                    voteInstructionService
                      .getIssuerPolicyText(
                        $scope.gmId,
                        $scope.langCode,
                        $scope.userType
                      )
                      .then(
                        function (response) {
                          if (response.status == 204) {
                            $scope.showTelenetTerms = false;
                            $scope.showTcModal = true;
                          } else {
                            $scope.showTelenetTerms = true;
                            $scope.modalBodyTelenet = response.data.policyText;
                            $scope.showTcModal = true;
                          }
                        },
                        function (error) {
                          $log.debug(error);
                          return error;
                        }
                      );
                  } else {
                    $scope.showTcModal = true;
                  }
                } else if (status === 'RA') {
                  $scope.statusAction = $filter('translate')('label_reject');
                  $scope.modalBody = '';
                  $scope.modalHeader = $filter('translate')(
                    'label_modalConfirmAction'
                  );
                  $scope.modalFooter = '';
                  $scope.modalLeftButton = $filter('translate')(
                    'label_confirm'
                  );
                  $scope.modalRightButton = $filter('translate')(
                    'label_cancel'
                  );
                  $scope.modalBody1 = $filter('translate')(
                    'intermediary_modalcheck1'
                  );
                  $scope.modalBody2 = $filter('translate')(
                    'intermediary_modalcheck2'
                  );
                  $scope.modalBody3 = $filter('translate')(
                    'intermediary_AR_modalcheck3'
                  );
                  $scope.modalBody4 = $filter('translate')(
                    'intermediary_AR_reject_modalcheck4'
                  );
                  $scope.modalBody5 = $filter('translate')(
                    'intermediaryAcceptVoteCheckbox5'
                  );
                  $scope.modalBody6 = $filter('translate')('label_termsUse');
                  $scope.modalBody7 = $filter('translate')(
                    'intermediaryAcceptVoteCheckbox6'
                  );
                  ModelWindowService.showModelWindow('rejectAlertBoxWithToU');
                } else if (status === 'R') {
                  $scope.statusAction = $filter('translate')('label_reject');
                  $scope.modalLeftButton = $filter('translate')(
                    'label_confirm'
                  );
                  $scope.modalRightButton = $filter('translate')(
                    'label_cancel'
                  );
                  $scope.modalBody = '';
                  $scope.modalHeader = $filter('translate')(
                    'label_modalConfirmAction'
                  );
                  ModelWindowService.showModelWindow('rejectAlertBox');
                }
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      //Below is the function call to show modal window to select language for download
      $scope.downloadRegistrationCertificateAlert = function (reqId) {
        $scope.modalBody = '';
        $scope.reqId = reqId;
        ModelWindowService.showModelWindow('selectLanguageToDownload');
      };

      //Below is the function call to show modal window to select language for Send Reg Ticket
      $scope.sendRegistrationTktAlert = function (
        shareholderId,
        gmId,
        reqId,
        emailSentFlag,
        intermedairyId,
        shareClassId
      ) {
        $scope.modalBody = '';
        $scope.shareholderIdSendReg = shareholderId;
        $scope.gmIdSendReg = gmId;
        $scope.reqIdSendReg = reqId;
        $scope.emailSentFlagSendReg = emailSentFlag;
        $scope.intermedairyIdSendReg = intermedairyId;
        $scope.shareClassIdSendReg = shareClassId;
        ModelWindowService.showModelWindow('selectLanguageToSendreg');
      };

      // Below is the method to update the status of the request
      $scope.sendRegTicket = function (
        shareholderId,
        gmId,
        reqId,
        emailSentFlag,
        intermedairyId,
        shareClassId
      ) {
        attendanceRequestService
          .sendResendRegTkt(
            shareholderId,
            reqId,
            gmId,
            shareClassId,
            intermedairyId,
            $sessionStorage.languageSelected,
            emailSentFlag
          )
          .then(
            function (response) {
              $scope.success = true;
              $scope.errorMessage = '';
              $scope.successMessage = response.data.message;
              $('#successPopUp').modal('show');
              $timeout(function () {
                $('#successPopUp').modal('hide');
                $scope.success = false;
                $scope.successMessage = '';
              }, 3000);
              $scope.getshareholderRequest(
                $scope.statusType,
                $scope.pageNo,
                response.data.message
              );
            },
            function (error) {
              $log.debug(error);
              $scope.error = true;
              $scope.errorMessage = error.data.errors[0].errorMessage;
              $timeout(function () {
                $scope.error = false;
                $scope.errorMessage = '';
              }, 8000);
            }
          );
      };

      // Below is the method to update the status of the request
      $scope.updateShareholderReq = function (
        type,
        reqId,
        status,
        noOfSecurities,
        currentStatus,
        rightToSpeakForVote,
        shareholderId,
        shareClassId
      ) {
        $scope.type = type;
        $scope.requestId = reqId;
        if (currentStatus === 'X' && $scope.status === 'A') {
          $scope.status = 'C';
        } else if (
          currentStatus === 'X' &&
          ($scope.status === 'R' || $scope.status === 'RA')
        ) {
          $scope.status = 'A';
        } else {
          $scope.status = status;
        }
        var shareHolderdata = {
          meetingId: $scope.gmId,
          requestId: $scope.requestId,
          status: $scope.status,
          issuerId: $scope.issuerId,
          issueName: $scope.issuerName,
          gmDate: $scope.gmDate,
          rejectionReason: $sessionStorage.rejectReason,
          langCode: $scope.langCode,
          loginUserId: $scope.userId,
          noOfSecurities: noOfSecurities,
          currentStatus: currentStatus,
          rightToSpeakForVote: rightToSpeakForVote,
        };
        if ($scope.type === 'vote') {
          attendanceRequestService
            .updateShareholderVoteRequest(shareHolderdata, 'secureToken')
            .then(
              function (response) {
                $scope.pageNo = 1;
                $scope.getshareholderRequest($scope.statusType, $scope.pageNo);
                if (406 === response.data.httpStatusCode) {
                  $scope.success = false;
                  $scope.error = true;
                  $scope.errorMessage = response.data.message;
                } else {
                  $scope.success = true;
                  $scope.error = false;
                  $scope.errorMessage = '';
                  $scope.successMessage = response.data.message;
                }
              },
              function (error) {
                $log.debug(error);
                $scope.error = true;
                $scope.errorMessage = error.data.errors[0].errorMessage;
                //Below service call is added to send email to agent when the number of shares entered exceeds the total number of securities available
                sendEmailToAgent(
                  error,
                  'vote',
                  'IN',
                  shareClassId,
                  shareholderId
                );
              }
            );
        } else {
          attendanceRequestService
            .updateShareholderAttendRequest(shareHolderdata, 'secureToken')
            .then(
              function (response) {
                $scope.pageNo = 1;
                $scope.getshareholderRequest($scope.statusType, $scope.pageNo);
                if (406 === response.data.httpStatusCode) {
                  $scope.success = false;
                  $scope.error = true;
                  $scope.errorMessage = response.data.message;
                } else {
                  $scope.success = true;
                  $scope.error = false;
                  $scope.errorMessage = '';
                  $scope.successMessage = response.data.message;
                }
              },
              function (error) {
                $log.debug(error);
                $scope.error = true;
                $scope.errorMessage = error.data.errors[0].errorMessage;
                //Below service call is added to send email to agent when the number of shares entered exceeds the total number of securities available
                sendEmailToAgent(
                  error,
                  'attend',
                  'IN',
                  shareClassId,
                  shareholderId
                );
              }
            );
        }
      };

      var sendEmailToAgent = function (
        error,
        requestType,
        workstationType,
        shareClassId,
        shareholderId
      ) {
        if (
          error.data.errors[0].errorCode === 'CBP_ERR_520' &&
          $scope.userType === 'IN'
        ) {
          $scope.EmailData = {
            votingInstType: requestType,
            languageCode: 'EN',
            workStationType: workstationType,
            meetingId: $stateParams.gmId,
            shareClassId: shareClassId,
            intermediaryId: $scope.userGroupId,
            issuerId: $stateParams.issuerId,
            loginUserId: shareholderId,
          };
          voteInstructionService
            .sendSharesExceededMail($scope.EmailData, 'secureToken')
            .then(
              function (response) {
                $log.debug(response);
              },
              function (error) {
                $log.debug(error);
              }
            );
        }
      };

      // Below is the method to hide or show accordian
      $scope.showHideDetails = function (attnRequest) {
        attnRequest.showDetails = !attnRequest.showDetails;
      };
      // Below is the method to download the registration certificate
      $scope.downloadRegistrationCertificate = function (reqId) {
        $scope.fileloading = true;
        $scope.downloadRegistrationCerti =
          '/v2/gm/intermediary' +
          `/registration-certificates/${reqId}` +
          `?langCode=${$sessionStorage.languageSelected}`;
        fileDownloadSvc.downloadFile($scope.downloadRegistrationCerti).then(
          function (response) {
            cbpDownloadBlob(response.file, response.fileName);
            $scope.downloadRegistrationCerti = '';
            $scope.fileloading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.fileloading = false;
          }
        );
      };
      // Below is the method to download the registration certificate
      $scope.downloadVotingInstructions = function (reqId) {
        $scope.fileloading = true;
        let downloadUrl =
          '/v2/gm/intermediary' +
          `/voting-instructions/${reqId}` +
          `/vi-document?langCode=${
            $sessionStorage.selectedLang !== undefined
              ? $sessionStorage.selectedLang
              : 'EN'
          }`;
        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            cbpDownloadBlob(response.file, response.fileName);
            $scope.fileloading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.fileloading = false;
          }
        );
      };
      // Below is the function to validate whether the user can vote the meeting and navigation to voting instruction page
      $scope.noVote = false;
      $scope.votingInstructionView = function (action) {
        //First if condition added to check intermediary access date
        generalMeetingService
          .getCurrentCETTime()
          .query()
          .$promise.then(
            function (data) {
              if ($stateParams.intermediaryDate.indexOf('CET') > -1) {
                todaysDate = $filter('filter')(data, { timezone: 'CET' }, true);
              } else if ($stateParams.intermediaryDate.indexOf('GMT') > -1) {
                todaysDate = $filter('filter')(data, { timezone: 'GMT' }, true);
              }

              if ($scope.disableExtReq == false) {
                $scope.displayCreateExtn = true;
              } else {
                if (
                  $stateParams.intermediaryDateTimeStamp <
                  todaysDate[0].currentMillis
                ) {
                  $scope.displayCreateExtn = true;
                } else {
                  $scope.displayCreateExtn = false;
                }
              }
              if (
                $stateParams.intermediaryAccessDate >
                todaysDate[0].currentMillis
              ) {
                $scope.extnRequestModelConfig.modalHeader = '';
                $scope.extnRequestModelConfig.modalBody =
                  $filter('translate')('label_youCanAttend') +
                  $stateParams.intermediaryAccessDateStr;
                ModelWindowService.showModelWindow('extReqAlertBox');
              } else if ($scope.displayCreateExtn === true) {
                if (
                  $scope.extReqStatus === 'CBP_MSG_51' ||
                  $scope.extReqStatus === 'CBP_MSG_43' ||
                  extReqResults.status === '422' ||
                  ($scope.extReqStatus === 'CBP_ERR_134' &&
                    !$scope.hasApprovedExtnRequest)
                ) {
                  $scope.extReqRejected = true;
                  if (action === 'vote') {
                    $scope.extnRequestModelConfig.modalHeader = $filter(
                      'translate'
                    )('label_votingInsCreatErr');
                    $scope.extnRequestModelConfig.modalBody = $filter(
                      'translate'
                    )('label_modalVotingInsCreatErrMsg');
                  } else if (
                    action === 'bulkUploadVI' ||
                    action === 'bulkUploadVIBO'
                  ) {
                    $scope.extnRequestModelConfig.modalHeader = $filter(
                      'translate'
                    )('label_bulkUploadCreatErr');
                    $scope.extnRequestModelConfig.modalBody = $filter(
                      'translate'
                    )('label_modalBulkUploadCreateErrMsg');
                  }
                  ModelWindowService.showModelWindow('extReqAlertBox');
                } else {
                  $scope.extReqRejected = false;
                  if (action === 'vote') {
                    attendanceRequestService
                      .validateIntermediary(
                        $scope.userGroupId,
                        $sessionStorage.usertype
                      )
                      .query()
                      .$promise.then(
                        function (response) {
                          $scope.validate = response.httpStatusCode;
                          voteInstructionService
                            .getShareClassList(
                              $scope.gmId,
                              $scope.shareholderId,
                              $scope.langCode,
                              $sessionStorage.usertype
                            )
                            .query()
                            .$promise.then(
                              function (data) {
                                if (data.length > 0) {
                                  $state.go('votingInstructionsIntermediary', {
                                    issuerName: $scope.issuerName,
                                    meetingId: $scope.gmId,
                                    issuerId: $scope.issuerId,
                                    shareHolderId: $scope.shareholderId,
                                    votingInstDetails: data,
                                    breadCrumbDetails: [
                                      $stateParams.breadCrumbDetails[0],
                                    ],
                                  });
                                } else {
                                  $scope.noVote = true;
                                  $scope.shareClassModelConfig.modalHeader = $filter(
                                    'translate'
                                  )('label_votingInsCreatErr');
                                  $scope.shareClassModelConfig.modalBody = $filter(
                                    'translate'
                                  )('label_modalShareVotingInsCreatErrMsg');
                                  ModelWindowService.showModelWindow(
                                    'shareClassAlertBox'
                                  );
                                }
                              },
                              function (error) {
                                return error;
                              }
                            );
                        },
                        function (error) {
                          $scope.extnRequestModelConfig.modalHeader = $filter(
                            'translate'
                          )('label_votingInsCreatErr');
                          $scope.extnRequestModelConfig.modalBody =
                            error.data.errors[0].errorMessage;
                          ModelWindowService.showModelWindow('extReqAlertBox');
                          return error;
                        }
                      );
                  } else if (action === 'bulkUploadVI' && !$scope.noVote) {
                    $state.go('bulkUploadVI', {
                      gmId: $scope.gmId,
                      issuerId: $scope.issuerId,
                      type: $scope.type,
                      issuerName: $scope.issuerName,
                      gmDate: $scope.gmDate,
                      langCode: $scope.langCode,
                      gmName: $stateParams.breadCrumbDetails[0],
                    });
                  } else {
                    $scope.extnRequestModelConfig.modalHeader = $filter(
                      'translate'
                    )('label_bulkUploadCreatErr');
                    $scope.extnRequestModelConfig.modalBody = $filter(
                      'translate'
                    )('label_modalBulkUploadCreatErrMsg');
                    ModelWindowService.showModelWindow('extReqAlertBox');
                  }
                }
              } else {
                $scope.extReqRejected = false;
                if (action === 'vote') {
                  attendanceRequestService
                    .validateIntermediary(
                      $scope.userGroupId,
                      $sessionStorage.usertype
                    )
                    .query()
                    .$promise.then(
                      function (response) {
                        $scope.validate = response.httpStatusCode;
                        voteInstructionService
                          .getShareClassList(
                            $scope.gmId,
                            $scope.shareholderId,
                            $scope.langCode,
                            $sessionStorage.usertype,
                            $scope.votingInstructionId,
                            $scope.votingStatus
                          )
                          .query()
                          .$promise.then(
                            function (data) {
                              if (data.length > 0) {
                                $state.go('votingInstructionsIntermediary', {
                                  issuerName: $scope.issuerName,
                                  meetingId: $scope.gmId,
                                  issuerId: $scope.issuerId,
                                  shareHolderId: $scope.shareholderId,
                                  votingInstDetails: data,
                                  breadCrumbDetails: [
                                    $stateParams.breadCrumbDetails[0],
                                  ],
                                });
                              } else {
                                $scope.noVote = true;
                                $scope.shareClassModelConfig.modalHeader = $filter(
                                  'translate'
                                )('label_votingInsCreatErr');
                                $scope.shareClassModelConfig.modalBody = $filter(
                                  'translate'
                                )('label_modalShareVotingInsCreatErrMsg');
                                ModelWindowService.showModelWindow(
                                  'shareClassAlertBox'
                                );
                              }
                            },
                            function (error) {
                              return error;
                            }
                          );
                      },
                      function (error) {
                        $scope.extnRequestModelConfig.modalHeader = $filter(
                          'translate'
                        )('label_votingInsCreatErr');
                        $scope.extnRequestModelConfig.modalBody =
                          error.data.errors[0].errorMessage;
                        ModelWindowService.showModelWindow('extReqAlertBox');
                        return error;
                      }
                    );
                } else if (action === 'bulkUploadVI' && !$scope.noVote) {
                  $state.go('bulkUploadVI', {
                    gmId: $scope.gmId,
                    issuerId: $scope.issuerId,
                    type: $scope.type,
                    issuerName: $scope.issuerName,
                    gmDate: $scope.gmDate,
                    langCode: $scope.langCode,
                    gmName: $stateParams.breadCrumbDetails[0],
                  });
                } else {
                  $scope.extnRequestModelConfig.modalHeader = $filter(
                    'translate'
                  )('label_votingInsCreatErr');
                  $scope.extnRequestModelConfig.modalBody = $filter(
                    'translate'
                  )('label_modalBulkUploadCreatErrMsg');
                  ModelWindowService.showModelWindow('extReqAlertBox');
                }
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      // Below is the function to validate whether the user can attend the meeting and navigation to Attendance request page
      $scope.noAttend = false;
      $scope.attendanceRequestView = function (action) {
        //First if condition added to check intermediary access date

        generalMeetingService
          .getCurrentCETTime()
          .query()
          .$promise.then(
            function (data) {
              if ($stateParams.intermediaryDate.indexOf('CET') > -1) {
                todaysDate = $filter('filter')(data, { timezone: 'CET' }, true);
              } else if ($stateParams.intermediaryDate.indexOf('GMT') > -1) {
                todaysDate = $filter('filter')(data, { timezone: 'GMT' }, true);
              }
              if ($scope.disableExtReq == false) {
                $scope.displayCreateExtn = true;
              } else {
                if (
                  $stateParams.intermediaryDateTimeStamp <
                  todaysDate[0].currentMillis
                ) {
                  $scope.displayCreateExtn = true;
                } else {
                  $scope.displayCreateExtn = false;
                }
              }
              if (
                $stateParams.intermediaryAccessDate >
                todaysDate[0].currentMillis
              ) {
                $scope.extnRequestModelConfig.modalHeader = '';
                $scope.extnRequestModelConfig.modalBody =
                  $filter('translate')('label_youCanAttend') +
                  $stateParams.intermediaryAccessDateStr;
                ModelWindowService.showModelWindow('extReqAlertBox');
              } else if ($scope.displayCreateExtn === true) {
                if (
                  $scope.extReqStatus === 'CBP_MSG_51' ||
                  $scope.extReqStatus === 'CBP_MSG_43' ||
                  extReqResults.status === '422'
                ) {
                  $scope.extReqRejected = true;
                  if (action === 'attend') {
                    $scope.extnRequestModelConfig.modalHeader = $filter(
                      'translate'
                    )('label_modalAttReqCreatErr');
                    $scope.extnRequestModelConfig.modalBody = $filter(
                      'translate'
                    )('label_modalAttReqCreatErrMsg');
                  } else if (
                    action === 'bulkUploadAttendReq' ||
                    action === 'bulkUploadVIBO'
                  ) {
                    $scope.extnRequestModelConfig.modalHeader = $filter(
                      'translate'
                    )('label_bulkUploadCreatErr');
                    $scope.extnRequestModelConfig.modalBody = $filter(
                      'translate'
                    )('label_modalBulkUploadAttendCreateErrMsg');
                  }
                  ModelWindowService.showModelWindow('extReqAlertBox');
                } else {
                  $scope.extReqRejected = false;
                  if (action === 'attend') {
                    attendanceRequestService
                      .getAttendanceRequestView(
                        $scope.gmId,
                        $scope.langCode,
                        $scope.shareplanId,
                        $sessionStorage.usertype
                      )
                      .query()
                      .$promise.then(
                        function (data) {
                          if (data && data.shareClassISINVO.length > 0) {
                            $state.go('attendanceRequest', {
                              gmId: $scope.gmId,
                              issuerId: $scope.issuerId,
                              issuerName: $scope.issuerName,
                              sharePlanId: $scope.shareplanId,
                              attendanceDataDeatils: data,
                              gmDate: $scope.gmDate,
                              breadCrumbDetails: [
                                $stateParams.breadCrumbDetails[0],
                              ],
                            });
                          } else {
                            $scope.noAttend = true;
                            showAttendDisabledPopup($scope.issuerName);
                          }
                        },
                        function (error) {
                          return error;
                        }
                      );
                  } else if (
                    action === 'bulkUploadAttendReq' &&
                    !$scope.noVote
                  ) {
                    attendanceRequestService
                      .getAttendanceRequestView(
                        $scope.gmId,
                        $scope.langCode,
                        $scope.shareplanId,
                        $sessionStorage.usertype
                      )
                      .query()
                      .$promise.then(function (data) {
                        if (data && data.shareClassISINVO.length > 0) {
                          $state.go('bulkUploadAttendReq', {
                            gmId: $scope.gmId,
                            issuerId: $scope.issuerId,
                            type: $scope.type,
                            issuerName: $scope.issuerName,
                            gmDate: $scope.gmDate,
                            langCode: $scope.langCode,
                            gmName: $stateParams.breadCrumbDetails[0],
                          });
                        } else {
                          $scope.noAttend = true;
                          showAttendDisabledPopup($scope.issuerName);
                        }
                      });
                  } else {
                    $scope.extnRequestModelConfig.modalHeader = $filter(
                      'translate'
                    )('label_bulkUploadCreatErr');
                    $scope.extnRequestModelConfig.modalBody = $filter(
                      'translate'
                    )('label_modalBulkUploadAttendCreatErrMsg');
                    ModelWindowService.showModelWindow('extReqAlertBox');
                  }
                }
              } else {
                $scope.extReqRejected = false;
                if (action === 'attend') {
                  attendanceRequestService
                    .getAttendanceRequestView(
                      $scope.gmId,
                      $scope.langCode,
                      $scope.shareplanId,
                      $sessionStorage.usertype
                    )
                    .query()
                    .$promise.then(
                      function (data) {
                        if (data && data.shareClassISINVO.length > 0) {
                          $state.go('attendanceRequest', {
                            gmId: $scope.gmId,
                            issuerId: $scope.issuerId,
                            issuerName: $scope.issuerName,
                            sharePlanId: $scope.shareplanId,
                            attendanceDataDeatils: data,
                            breadCrumbDetails: [
                              $stateParams.breadCrumbDetails[0],
                            ],
                          });
                        } else {
                          $scope.noAttend = true;
                          showAttendDisabledPopup($scope.issuerName);
                        }
                      },
                      function (error) {
                        return error;
                      }
                    );
                } else if (action === 'bulkUploadAttendReq' && !$scope.noVote) {
                  attendanceRequestService
                    .getAttendanceRequestView(
                      $scope.gmId,
                      $scope.langCode,
                      $scope.shareplanId,
                      $sessionStorage.usertype
                    )
                    .query()
                    .$promise.then(function (data) {
                      if (data && data.shareClassISINVO.length > 0) {
                        $state.go('bulkUploadAttendReq', {
                          gmId: $scope.gmId,
                          issuerId: $scope.issuerId,
                          type: $scope.type,
                          issuerName: $scope.issuerName,
                          gmDate: $scope.gmDate,
                          langCode: $scope.langCode,
                          gmName: $stateParams.breadCrumbDetails[0],
                        });
                      } else {
                        $scope.noAttend = true;
                        showAttendDisabledPopup($scope.issuerName);
                      }
                    });
                } else {
                  $scope.extnRequestModelConfig.modalHeader = $filter(
                    'translate'
                  )('label_bulkUploadCreatErr');
                  $scope.extnRequestModelConfig.modalBody = $filter(
                    'translate'
                  )('label_modalBulkUploadAttendCreatErrMsg');
                  ModelWindowService.showModelWindow('extReqAlertBox');
                }
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };

      function showAttendDisabledPopup(issuerName) {
        $scope.shareClassModelConfig.modalHeader = $filter('translate')(
          'label_modalAttReqCreatErr'
        );
        $scope.shareClassModelConfig.modalBody =
          $filter('translate')(
            'label_attendDisabled1_intermediary'
          ).toString() +
          ' ' +
          issuerName +
          ' ' +
          $filter('translate')(
            'label_attendDisabled2_intermediary'
          ).toString() +
          ' ' +
          $filter('translate')('label_attendNotEnabled2').toString() +
          '.';
        ModelWindowService.showModelWindow('shareClassAlertBox');
      }

      $scope.createRightToSpeak = function (
        selectedIndex,
        meetingId,
        shareholderId,
        issuerId,
        shareclassName,
        intermediaryId,
        instructionId,
        userType,
        numberOfSecurities,
        isin,
        shareclassId,
        rightToSpeakForVote,
        type,
        attendanceRequestId,
        registrationNo
      ) {
        //Below code is added to validated intermediary access date and deadline date - SFCBP-2294
        generalMeetingService
          .getCurrentCETTime()
          .query()
          .$promise.then(
            function (data) {
              if ($stateParams.intermediaryDate.indexOf('CET') > -1) {
                todaysDate = $filter('filter')(data, { timezone: 'CET' }, true);
              } else if ($stateParams.intermediaryDate.indexOf('GMT') > -1) {
                todaysDate = $filter('filter')(data, { timezone: 'GMT' }, true);
              }
              if ($scope.disableExtReq == false) {
                $scope.displayCreateExtn = true;
              } else {
                if (
                  $stateParams.intermediaryDateTimeStamp <
                  todaysDate[0].currentMillis
                ) {
                  $scope.displayCreateExtn = true;
                } else {
                  $scope.displayCreateExtn = false;
                }
              }
              if (
                $stateParams.intermediaryAccessDate >
                todaysDate[0].currentMillis
              ) {
                $scope.extnRequestModelConfig.modalHeader = '';
                $scope.extnRequestModelConfig.modalBody =
                  $filter('translate')('label_youCanAttend') +
                  $stateParams.intermediaryAccessDateStr;
                ModelWindowService.showModelWindow('extReqAlertBox');
              } else if (
                $scope.displayCreateExtn === true &&
                ($scope.extReqStatus === 'CBP_MSG_51' ||
                  $scope.extReqStatus === 'CBP_MSG_43' ||
                  extReqResults.status === '422' ||
                  ($scope.extReqStatus === 'CBP_ERR_134' &&
                    !$scope.hasApprovedExtnRequest))
              ) {
                $scope.extReqRejected = true;
                $scope.extnRequestModelConfig.modalHeader = $filter(
                  'translate'
                )('label_modalAttReqActionErrMsg');
                $scope.extnRequestModelConfig.modalBody = $filter('translate')(
                  'label_modalRtsCreatErrMsg'
                );
                ModelWindowService.showModelWindow('extReqAlertBox');
              } else {
                if (rightToSpeakForVote === true && type === 'vote') {
                  $state.go('editVIRightToSpeak', {
                    selectedIndex: selectedIndex,
                    meetingId: meetingId,
                    shareholderId: shareholderId,
                    issuerId: issuerId,
                    shareclassName: shareclassName,
                    intermediaryId: intermediaryId,
                    issuerName: $scope.issuerName,
                    instructionId: instructionId,
                    userType: userType,
                    numberOfSecurities: numberOfSecurities,
                    isin: isin,
                    shareclassId: shareclassId,
                    rightToSpeakForVote: rightToSpeakForVote,
                    attendanceRequestId: attendanceRequestId,
                    registrationNo: registrationNo,
                    type: type,
                  });
                } else if (rightToSpeakForVote === true && type === 'attend') {
                  $state.go('editARRightToSpeak', {
                    selectedIndex: selectedIndex,
                    meetingId: meetingId,
                    shareholderId: shareholderId,
                    issuerId: issuerId,
                    shareclassName: shareclassName,
                    intermediaryId: intermediaryId,
                    instructionId: instructionId,
                    issuerName: $scope.issuerName,
                    userType: userType,
                    numberOfSecurities: numberOfSecurities,
                    isin: isin,
                    shareclassId: shareclassId,
                    rightToSpeakForVote: rightToSpeakForVote,
                    attendanceRequestId: attendanceRequestId,
                    registrationNo: registrationNo,
                    type: type,
                  });
                } else {
                  $state.go('createRightToSpeak', {
                    selectedIndex: selectedIndex,
                    meetingId: meetingId,
                    shareholderId: shareholderId,
                    issuerId: issuerId,
                    shareclassName: shareclassName,
                    issuerName: $scope.issuerName,
                    intermediaryId: intermediaryId,
                    instructionId: instructionId,
                    userType: userType,
                    numberOfSecurities: numberOfSecurities,
                    isin: isin,
                    shareclassId: shareclassId,
                    rightToSpeakForVote: rightToSpeakForVote,
                    type: type,
                  });
                }
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      //Below function call is for customising goback navigation to general meeting overview page
      $scope.goToGMPage = function () {
        $sessionStorage.editRTSattend = null;
        $state.go('generalMeetingHome');
      };
      $scope.viewExtensionRequest = function () {
        $scope.extReqRejected = true;
        $state.go('createIntermediaryExtensionRequest', {
          intermediaryDeadlineDateStr: $scope.intermediaryDate,
          gmName: $stateParams.breadCrumbDetails[0],
          gmDateTime: $scope.gmDate,
          generalMeetingId: $scope.gmId,
          extReqRejected: $scope.extReqRejected,
          issuerId: $scope.issuerId,
          userType: $scope.userType,
          gmStatus: $scope.gmStatus,
          allExtnReqRejected: $scope.allExtnReqRejected,
          breadCrumbDetails: [$stateParams.breadCrumbDetails[0]],
          issuerName: $scope.issuerName,
        });
      };

      $scope.disableAction = function () {
        if ($scope.disableExtReq === false) {
          return true;
        } else {
          return false;
        }
      };

      /* download BO File starts here */
      $scope.downloadBOFile = function (requestId, type) {
        var requestType = '';
        if (type === 'vote') {
          requestType = 'voting-instructions';
        } else if (type === 'attend') {
          requestType = 'attendance-requests';
        }
        var downloadUrl =
          '/v2/gm/intermediary/' +
          requestType +
          '/' +
          requestId +
          '/bo-document';
        var a = document.createElement('a');
        document.body.appendChild(a);
        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            let fileVO = response;
            if (window.navigator.msSaveOrOpenBlob) {
              a.onclick = function () {
                window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
              };
              a.click();
            } else {
              var fileURL = URL.createObjectURL(fileVO.file);
              a.href = fileURL;
              a.download = fileVO.fileName;
              a.click();
            }
            downloadUrl = '';
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      /* download BO File ends here */

      // call the function for first time
      $scope.getshareholderRequest($scope.statusType, $scope.pageNo);
      $scope.getIssuerLogo($scope.issuerId);
    },
  ]);
