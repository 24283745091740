/**
 * @ngDoc controller
 * @name dwtApproveRejectExtensionModule.DwtExtController
 * @description This controller is used for extension request functionality.
 * @requires $scope
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 * @requires PaginationSvc for pagination
 */
'use strict';

angular
  .module('dwtApproveRejectExtensionModule')
  .controller('DwtExtController', [
    '$scope',
    '$sessionStorage',
    '$filter',
    '$log',
    '$timeout',
    '$state',
    'PaginationSvc',
    'ViewDwtExtensionReqService',
    'DwtApproveRejectExtn',
    function (
      $scope,
      $sessionStorage,
      $filter,
      $log,
      $timeout,
      $state,
      PaginationSvc,
      ViewDwtExtensionReqService,
      DwtApproveRejectExtn
    ) {
      $scope.extensionRequests = '';
      $scope.dummyItems = [];
      $scope.taxAuthAgentFlag = $sessionStorage.isAdmin;
      var count = null;
      var pageNo = 1;
      //for status buttons
      if ($sessionStorage.selectedLang === 'nl') {
        $scope.statusButtons = ['nl_Pending', 'nl_Accepted', 'nl_Rejected'];
      } else {
        $scope.statusButtons = ['Pending', 'Accepted', 'Rejected'];
      }

      $scope.extenLoadingFlag = true;
      $scope.extnStatusButton = ['P', 'A', 'R'];

      $scope.getExtensionRequests = function () {
        //to fetch the extension request list
        $scope.extenLoadingFlag = true;
        ViewDwtExtensionReqService.getDwtExtensionRequests(pageNo)
          .query()
          .$promise.then(
            function (data) {
              $scope.extensionRequestsList = data;
              $scope.extensionRequests = $filter('filter')(
                $scope.extensionRequestsList,
                {
                  status: $scope.extnStatusButton[0],
                }
              );
              $scope.extReqCount_0 = $filter('filter')(
                $scope.extensionRequestsList,
                {
                  status: $scope.extnStatusButton[0],
                }
              ).length;
              $scope.extReqCount_1 = $filter('filter')(
                $scope.extensionRequestsList,
                {
                  status: $scope.extnStatusButton[1],
                }
              ).length;
              $scope.extReqCount_2 = $filter('filter')(
                $scope.extensionRequestsList,
                {
                  status: $scope.extnStatusButton[2],
                }
              ).length;
              $scope.selectedButtonIndex = 0;
              if (data.length > 0) {
                count = data[0].count;
                count = count - 500;
              }
              $scope.dummyItems = $scope.extensionRequests;
              $scope.pager = {};
              $scope.setPage(1);
              $scope.extenLoadingFlag = false;
            },
            function (error) {
              $scope.extReqCount_0 = 0;
              $scope.extReqCount_1 = 0;
              $scope.extReqCount_2 = 0;
              $scope.extenLoadingFlag = false;
            }
          );
      };

      $scope.setPage = function (page) {
        $scope.pager = [];
        if ($scope.selectedButtonIndex === 0) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          if ($scope.extReqCount_0 / (page * 10) < 1 && count >= 1) {
            pageNo = pageNo + 1;
            $scope.extenLoadingFlag = true;
            ViewDwtExtensionReqService.getDwtExtensionRequests(pageNo)
              .query()
              .$promise.then(
                function (data) {
                  $scope.extensionRequestsList = $scope.extensionRequestsList.concat(
                    data
                  );
                  $scope.extensionRequests = $filter(
                    'filter'
                  )($scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[0],
                  });
                  $scope.extReqCount_0 = $filter(
                    'filter'
                  )($scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[0],
                  }).length;
                  $scope.extReqCount_1 = $filter(
                    'filter'
                  )($scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[1],
                  }).length;
                  $scope.extReqCount_2 = $filter(
                    'filter'
                  )($scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[2],
                  }).length;
                  count = count - 500;
                  $scope.dummyItems = $scope.extensionRequests;
                  // get pager object from service
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );
                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                  $scope.extenLoadingFlag = false;
                },
                function (error) {
                  $scope.extReqCount_0 = 0;
                  $scope.extReqCount_1 = 0;
                  $scope.extReqCount_2 = 0;
                  $scope.extenLoadingFlag = false;
                }
              );
          }
        } else if ($scope.selectedButtonIndex === 1) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          if ($scope.extReqCount_1 / (page * 10) < 1 && count >= 1) {
            pageNo = pageNo + 1;
            $scope.extenLoadingFlag = true;
            ViewDwtExtensionReqService.getDwtExtensionRequests(pageNo)
              .query()
              .$promise.then(
                function (data) {
                  $scope.extensionRequestsList = $scope.extensionRequestsList.concat(
                    data
                  );
                  $scope.extensionRequests = $filter(
                    'filter'
                  )($scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[1],
                  });
                  $scope.extReqCount_0 = $filter(
                    'filter'
                  )($scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[0],
                  }).length;
                  $scope.extReqCount_1 = $filter(
                    'filter'
                  )($scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[1],
                  }).length;
                  $scope.extReqCount_2 = $filter(
                    'filter'
                  )($scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[2],
                  }).length;
                  count = count - 500;
                  $scope.dummyItems = $scope.extensionRequests;
                  // get pager object from service
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );
                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                  $scope.extenLoadingFlag = false;
                },
                function (error) {
                  $scope.extReqCount_0 = 0;
                  $scope.extReqCount_1 = 0;
                  $scope.extReqCount_2 = 0;
                  $scope.extenLoadingFlag = false;
                }
              );
          }
        } else if ($scope.selectedButtonIndex === 2) {
          if (page < 1 || page > $scope.pager.totalPages) {
            return;
          }
          if ($scope.extReqCount_2 / (page * 10) < 1 && count >= 1) {
            pageNo = pageNo + 1;
            $scope.extenLoadingFlag = true;
            ViewDwtExtensionReqService.getDwtExtensionRequests(pageNo)
              .query()
              .$promise.then(
                function (data) {
                  $scope.extensionRequestsList = $scope.extensionRequestsList.concat(
                    data
                  );
                  $scope.extensionRequests = $filter(
                    'filter'
                  )($scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[2],
                  });
                  $scope.extReqCount_0 = $filter(
                    'filter'
                  )($scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[0],
                  }).length;
                  $scope.extReqCount_1 = $filter(
                    'filter'
                  )($scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[1],
                  }).length;
                  $scope.extReqCount_2 = $filter(
                    'filter'
                  )($scope.extensionRequestsList, {
                    status: $scope.extnStatusButton[2],
                  }).length;
                  count = count - 500;
                  $scope.dummyItems = $scope.extensionRequests;
                  // get pager object from service
                  $scope.pager = PaginationSvc.GetPager(
                    $scope.dummyItems.length,
                    page
                  );

                  // get current page of items
                  $scope.items = $scope.dummyItems.slice(
                    $scope.pager.startIndex,
                    $scope.pager.endIndex + 1
                  );
                  $scope.extenLoadingFlag = false;
                },
                function (error) {
                  $scope.extReqCount_0 = 0;
                  $scope.extReqCount_1 = 0;
                  $scope.extReqCount_2 = 0;
                  $scope.extenLoadingFlag = false;
                }
              );
          }
        }
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }

        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);

        // get current page of items
        $scope.items = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
      };
      //on click of button, display the corresponding list based on status
      $scope.buttonClicked = function ($index) {
        $scope.selectedButtonIndex = $index;
        $scope.extensionRequests = $filter('filter')(
          $scope.extensionRequestsList,
          {
            status: $scope.extnStatusButton[$index],
          }
        );
        $scope.dummyItems = $scope.extensionRequests;
        $scope.pager = {};
        $scope.setPage(1);
      };
      //accept or reject extension request modal window
      $scope.approveReject = function (action, extReqId) {
        $scope.action = action;
        $scope.extReqId = extReqId;
        if ($scope.action === 'Accept') {
          $scope.modalHeader = $filter('translate')('label_extReq');
          $scope.modalBody = $filter('translate')('label_acceptExtnReqConfirm');
          $scope.modalFooter = '';
          $scope.modalLeftButton = $filter('translate')('label_accept');
          $scope.modalRightButton = $filter('translate')('label_cancel');
        } else if ($scope.action === 'Reject') {
          $scope.modalHeader = $filter('translate')('label_extReq');
          $scope.modalBody = $filter('translate')('label_rejectExtnReqConfirm');
          $scope.modalFooter = '';
          $scope.modalLeftButton = $filter('translate')('label_reject');
          $scope.modalLeftButton2 = $filter('translate')('label_rejectAll');
          $scope.modalRightButton = $filter('translate')('label_cancel');
        }
      };
      //reject single extension request
      $scope.approveRejectExtn = function (action, extReqId) {
        $scope.extenLoadingFlag = true;
        $scope.approveRejectExtnModel = new DwtApproveRejectExtn(
          extReqId,
          action,
          'ONE',
          $sessionStorage.usertype
        );
        $scope.approveRejectExtnModel.dwtApproveReject(
          $scope.successCallback,
          $scope.errorCallback
        );
      };
      //reject all extension requests belonging to an issuer.
      $scope.approveRejectAllExtn = function (action, extReqId) {
        $scope.extenLoadingFlag = true;
        $scope.approveRejectExtnModel = new DwtApproveRejectExtn(
          extReqId,
          action,
          null,
          $sessionStorage.usertype
        );
        $scope.approveRejectExtnModel.dwtApproveReject(
          $scope.successCallback,
          $scope.errorCallback
        );
      };
      $scope.successCallback = function (response) {
        $scope.response = response.data.message;
        $scope.extenLoadingFlag = false;
        $('#successMsg').modal('show');
        $timeout(function () {
          $('#successMsg').modal('hide');
          $('.modal-backdrop').remove();
          $scope.getExtensionRequests();
        }, 2000);
      };
      $scope.errorCallback = function (error) {
        $scope.extenLoadingFlag = false;
      };
      //fetch the list for the first time
      $scope.getExtensionRequests();
    },
  ]);
