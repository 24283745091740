/**
 * @ngdoc controller
 * @name userMaintenanceModule.UserMaintenanceController
 * @description This controller is used for user maintenance module. This is the
 *              main controller.
 * @requires $state for state routing.
 */

angular.module('userMaintenanceModule').controller('UserMaintenanceController',['$state', '$scope',
  function($state, $scope) {
    $scope.handleButtonClick = function () {
      $state.go('agentUserManagement.shareholders.searchShareholder');
    };

    // Activates the first tab
    if ($state.current.name === 'userMaintenance') {
      $state.go('userMaintenance.agentGroup');
    }
  }]);