/**
 * @ngdoc controller
 * @name userMaintenanceModule.ViewIntermediaryDwtController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the individual user details of an intermediary group.
 * @requires userService is the service which is being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $state for state routing
 */

angular.module('userMaintenanceDwtModule').controller(
  'ViewIntermediaryDwtController',
  [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$sessionStorage',
    'userService',
    function($scope, $log, $state, $stateParams, $sessionStorage,
      userService) {

      $scope.userId = $stateParams.userId;
      $scope.userType = $stateParams.userType;
      $scope.groupId = $stateParams.groupId;
      $scope.prodType = $sessionStorage.productType;
      $scope.user = 'intermediary';
      //Below is the call to interact with service and get user details.
      userService.getViewUser($scope.userId, $scope.userType,
        $scope.groupId, $scope.prodType).query().$promise.then(
        function(data) {
          $log.debug('success user view');
          $scope.viewUserDetails = data;
        }, function(error) {
          $log.debug(error);
        });

    }]);
