import getTermsOfUseUrl from '../../../lib/getTermsOfUseUrl';

/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.EditARBulkUploadController
 * @description This controller is used for attendance requests. This mainly
 *              deals with creating attendance request.
 * @requires attendanceRequestService,fileUploadSvc are the service which is
 *           being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires $filter to translate messages which are declared in locale files
 * @requires userGroupService
 */
angular
  .module('generalMeetingHomeModule')
  .controller('EditARBulkUploadController', [
    '$scope',
    '$sessionStorage',
    '$stateParams',
    '$log',
    'attendanceRequestService',
    'fileUploadSvc',
    '$filter',
    '$timeout',
    'voteInstructionService',
    'fileDownloadSvc',
    'userGroupService',
    'ModelWindowService',
    function (
      $scope,
      $sessionStorage,
      $stateParams,
      $log,
      attendanceRequestService,
      fileUploadSvc,
      $filter,
      $timeout,
      voteInstructionService,
      fileDownloadSvc,
      userGroupService,
      ModelWindowService
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;
      /**
       * set language code from session storage, if not
       * available, mark it as English
       */
      $scope.languageCode =
        $sessionStorage.workstationData.selectedLang || 'EN';
      // Below is the function used to fetch issuer logo
      userGroupService.getIssuerGroupLogo($stateParams.issuerId).then(
        function (response) {
          $scope.issuerLogoPresent = false;
          $scope.issuerGroupDetails = response.data;
          // Do not change the below condition. It is for issuer logo display fix. REST response has ""
          if ($scope.issuerGroupDetails.logoBaseStr) {
            $scope.issuerLogoPresent = true;
          }
        },
        function (error) {
          $log.debug(error);
        }
      );
      // declare variables.
      $scope.createProxy = false;
      $scope.selectProxy = false;
      $scope.editProxy = false;
      $scope.duplicateRequest = false;
      $scope.proxyProvided = '';
      $scope.issuerName = $stateParams.issuerName;
      $scope.userType = $sessionStorage.usertype;
      $scope.proxyVO = {};
      $scope.shareHolderVO = {};
      $scope.securities = {};
      $scope.proxyDetails = [];
      $scope.proxyDefaultDetails = false;
      $scope.selectedShareClassId = null;
      $scope.loading = false;
      $scope.loadingSpin = false;
      $scope.loadingFileSpin = false;
      $scope.eventType = 'Footer Term of Use';
      $scope.statusAR = $stateParams.status;
      $scope.boFileFlag = $stateParams.boFileFlag;
      $scope.uploadedFileName = $stateParams.boFileName;
      $scope.reqId = $stateParams.reqId;
      $scope.proxyType = $sessionStorage.proxyType;
      $scope.linkedIntError = '';
      $scope.newFileUploadedAR = false;
      //Below code is added for ForFarmer default proxy chnages
      $scope.isForFarmer = false;
      var farmerProxySelected = false;
      if ($scope.issuerName.toLowerCase() === 'forfarmers n.v.') {
        $scope.isForFarmer = true;
      }
      var defautProxy = {
        proxyCity: 'Den Haag',
        proxyCountry: 'NLD',
        proxyInitial: null,
        proxyName: 'Elke Vertegenwoordiger Van De VEB',
        proxyType: 'LGL',
        proxyTitle: null,
        proxyId: null,
        representativeName: null,
        legalIdentifier: null,
        proxyProvided: null,
        createNewProxy: null,
      };
      //proxyForFarmers - is added for ForFarmer default proxy chnages
      var proxyForFarmers = {
        proxyCity: 'Lochem',
        proxyCountry: 'NLD',
        proxyInitial: null,
        proxyName: 'Coöperatie FromFarmers UA',
        proxyType: 'LGL',
        proxyTitle: null,
        proxyId: null,
        representativeName:
          'Iedere vertegenwoordiger van de Cöoperatie FromFarmers',
        legalIdentifier: null,
        proxyProvided: null,
        createNewProxy: null,
      };
      //Below value is hard coded to GM because VI and AR functionalities will be there only for GM users.
      //Using productType array from session storage was causing issue in case of integrated users, so it is relpaced with below value.
      $scope.productTypeToU = 'GM';
      // Below method is used for change of shareclass in drop down
      $scope.onShareClassChange = function (selectedShareClass) {
        $scope.selectedShareClass = selectedShareClass;
        $scope.isin = $scope.selectedShareClass.isin;
      };
      //Below is the function call to fetch the linked intermediaries for a proxy intermediary and populate the select intermediary dropdown. - SFCBP-2488
      $scope.fetchLinkedIntermediaries = function (linkedIntName) {
        if ($sessionStorage.proxyType === 'P') {
          $scope.prxoyIntermediaryFlag = 'Y';
          voteInstructionService
            .getLinkedIntermediaries($sessionStorage.groupId)
            .query()
            .$promise.then(
              function (data) {
                $scope.linkedIntermediaryList = data;
                $scope.selectedLinkedInt = $filter('filter')(
                  $scope.linkedIntermediaryList,
                  { intermediaryName: linkedIntName },
                  true
                )[0];
              },
              function (error) {
                $log.debug(error);
              }
            );
        }
      };

      /**
       * Below method is used to change the owne type in drop down.
       * If the Owne type is NOMI or TRUS, then the shareholder
       * type should be Legal Person.
       */
      $scope.onOwneChange = function (selectedOwneType) {
        $scope.selectedOwneType = selectedOwneType;
        if (
          $scope.selectedOwneType.userType === 'NOMI' ||
          $scope.selectedOwneType.userType === 'TRUS'
        ) {
          $scope.selectedShareholderType = $scope.attendanceData.proxyTypeVO[0];
          /**
           * If the proxy is not provided while creating the AR,
           * then it is mandatory for NOMI and TRUS to create a proxy while
           * submitting the edited request.
           */
          if ($scope.submittedBulkUploadDetails.proxyProvided === 'N') {
            $scope.proxyProvidedValue = 'proxyCreate';
            $scope.createProxy = true;
            $scope.selectProxy = false;
            $scope.editProxy = 'N';
            $scope.createNewProxy = 'Y';
            $scope.proxyProvided = 'Y';
            $scope.proxyName = null;
            $scope.proxyCity = null;
            $scope.representativeName = null;
          }
        }
      };
      /**
       * Below method is used for shareholder type change in dropdown
       */
      $scope.onShareholderTypeChange = function (selectedShareholderType) {
        $scope.selectedShareholderType = selectedShareholderType;
        if ($scope.submittedBulkUploadDetails.proxyProvided === 'N') {
          $scope.proxyProvidedValue = 'proxyCreate';
          $scope.createProxy = true;
          $scope.selectProxy = false;
          $scope.editProxy = 'N';
          $scope.createNewProxy = 'Y';
          $scope.proxyProvided = 'Y';
          $scope.proxyName = null;
          $scope.proxyCity = null;
          $scope.representativeName = null;
        }
      };
      //to change the country in dropdown
      $scope.onCountrySelect = function (selectedCountry) {
        $scope.selectedCountry = selectedCountry;
      };
      //to change the title in dropdown
      $scope.onTitleChange = function (selectedTitle) {
        $scope.selectedTitle = selectedTitle;
      };
      //to change the proxy in dropdown
      $scope.onProxyChange = function (selectedProxy) {
        $scope.selectedProxyType = selectedProxy;
      };
      //to change the proxy title in dropdown
      $scope.onProxyTitleChange = function (selectedProxyTitle) {
        $scope.selectedProxyTitle = selectedProxyTitle;
      };
      //to change the proxy country in dropdown
      $scope.onProxyCountryChange = function (selectedProxyCountry) {
        $scope.selectedProxyCountry = selectedProxyCountry;
      };
      //ALM - 10528 autopopulating field when 'veb'  is keyed in for name filed for legal person
      $scope.autoPopulate = function () {
        if (
          $scope.selectedShareholderType.proxyTypeCode === 'LGL' &&
          ($scope.lastName.toLowerCase() === 'veb' ||
            $scope.lastName
              .toLowerCase()
              .indexOf('vereniging van effectenbezitters') > -1)
        ) {
          $scope.address = 'Amaliastraat 7';
          $scope.emailAddress = 'info@veb.net';
          $scope.city = 'Den Haag';
          $scope.value = 'proxySelect';
          $scope.selectedRadio($scope.value);
          $scope.selectedProxyCode = $filter('filter')(
            $scope.attendanceData.proxyTypeVO,
            { proxyTypeCode: $scope.selectedShareholderType.proxyTypeCode },
            true
          );
          $scope.selectedProxyCountry = $filter('filter')(
            $scope.attendanceData.countryCodeVO,
            { isoCountryCode: 'NLD' },
            true
          );
          $scope.selectedCountry = $filter('filter')(
            $scope.attendanceData.countryCodeVO,
            {
              isoCountryCode: 'NLD',
            },
            true
          );
        }
      };
      //to submit the edited attendance request
      $scope.getSubmittedDetails = function () {
        attendanceRequestService
          .getBulkUploadARDetails(
            $stateParams.reqId,
            $scope.languageCode,
            $sessionStorage.usertype
          )
          .query()
          .$promise.then(
            function (data) {
              //initialize all the variables to be populated in UI
              $scope.attendanceData = data;
              //To get linked intermediary list and to select linked intermediary by default
              $scope.fetchLinkedIntermediaries(data.linkedIntermediaryName);
              //below varibale is for differentiating the requests
              $scope.registrationSrc = data.registrationSrc;
              //$scope.submittedBulkUploadDetails gives the submitted details while creating AR
              $scope.submittedBulkUploadDetails = data.sharePlanHolderVO;
              $scope.meetingName = $scope.attendanceData.meetingName;
              $scope.lastName = $scope.submittedBulkUploadDetails.lastName;
              //Defect fix-first name not displaying
              $scope.firstName = $scope.submittedBulkUploadDetails.firstName;
              $scope.contactPerson =
                $scope.submittedBulkUploadDetails.contactPerson;
              $scope.legalEntityIdentifier =
                $scope.submittedBulkUploadDetails.legalEntityIdentifier;
              $scope.emailAddress =
                $scope.submittedBulkUploadDetails.emailAddress;
              $scope.address = $scope.submittedBulkUploadDetails.address;
              $scope.phoneNumber =
                $scope.submittedBulkUploadDetails.phoneNumber;
              $scope.postalCode = $scope.submittedBulkUploadDetails.postalCode;
              $scope.city = $scope.submittedBulkUploadDetails.city;
              $scope.numberOfShares = $scope.submittedBulkUploadDetails.number;
              $scope.dob = $scope.submittedBulkUploadDetails.dob;
              $scope.initials = $scope.submittedBulkUploadDetails.initials;
              $scope.legalIdentifier =
                $scope.submittedBulkUploadDetails.legalIdentifier;
              $scope.representativeName =
                $scope.submittedBulkUploadDetails.representativeName;
              //To display security account number in edit page
              $scope.securityAccountNumber =
                $scope.submittedBulkUploadDetails.securityAccountNumber;
              //if the proxy was provided while creating the AR, then preselect
              //edit proxy radio button, else select none radio button.
              if ($scope.submittedBulkUploadDetails.proxyProvided === 'Y') {
                $scope.proxyProvidedValue = 'proxyEdit';
                $scope.createProxy = false;
                $scope.selectProxy = false;
                $scope.editProxy = 'Y';
                $scope.createNewProxy = 'N';
                $scope.proxyProvided = 'Y';
                $scope.proxyInitials =
                  $scope.submittedBulkUploadDetails.proxyInitials;
                $scope.proxyName = $scope.submittedBulkUploadDetails.proxyName;
                $scope.proxyCity = $scope.submittedBulkUploadDetails.proxyCity;
              } else {
                $scope.selectProxy = false;
                $scope.editProxy = 'N';
                if (
                  $scope.submittedBulkUploadDetails.shareHolderType ===
                  'Legal Person'
                ) {
                  $scope.proxyProvidedValue = 'proxyCreate';
                  $scope.createProxy = true;
                  $scope.proxyProvided = 'Y';
                  $scope.createNewProxy = 'Y';
                  //To select Netherlands by default
                  angular.forEach(
                    $scope.attendanceData.countryCodeVO,
                    function (item4) {
                      if (angular.equals('NLD', item4.isoCountryCode)) {
                        $scope.onProxyCountryChange(item4);
                      }
                    }
                  );
                } else {
                  $scope.proxyProvidedValue = 'none';
                  $scope.createProxy = false;
                  $scope.proxyProvided = 'N';
                  $scope.createNewProxy = 'N';
                }
              }

              // if the shareclass is more than one go into this IF condition
              if ($scope.attendanceData.shareClassISINVO.length > 1) {
                $scope.moreThanOneShareClass = true;
                angular.forEach(
                  $scope.attendanceData.shareClassISINVO,
                  function (item) {
                    //preselect the submitted shareclass detail in the dropdown.
                    if (
                      angular.equals(
                        $scope.submittedBulkUploadDetails.shareclassName,
                        item.shareClassName
                      )
                    ) {
                      $scope.selectedShareClass = item;
                      $scope.isin = $scope.selectedShareClass.isin;
                    }
                  }
                );
              } else {
                //if there is only one shareclass, do not show dropdown. Show the shareclass
                //selected while creating AR
                $scope.moreThanOneShareClass = false;
                $scope.selectedShareClass =
                  $scope.attendanceData.shareClassISINVO[0];
                $scope.isin = $scope.attendanceData.shareClassISINVO[0].isin;
              }
              //preselect the ownetype with the submitted owne type
              angular.forEach($scope.attendanceData.owneTypeVO, function (
                item2
              ) {
                if (
                  angular.equals(
                    $scope.submittedBulkUploadDetails.owneType,
                    item2.userType
                  )
                ) {
                  $scope.selectedOwneType = item2;
                }
              });
              //preselect the proxy type with the submitted proxy type
              angular.forEach($scope.attendanceData.proxyTypeVO, function (
                item3
              ) {
                if (
                  angular.equals(
                    $scope.submittedBulkUploadDetails.shareHolderType,
                    item3.proxyType
                  )
                ) {
                  $scope.selectedShareholderType = item3;
                }
              });
              //preselect the country
              angular.forEach($scope.attendanceData.countryCodeVO, function (
                item4
              ) {
                if (
                  angular.equals(
                    $scope.submittedBulkUploadDetails.country,
                    item4.countryName
                  )
                ) {
                  $scope.selectedCountry = item4;
                }
                if (
                  angular.equals(
                    $scope.submittedBulkUploadDetails.proxyCountry,
                    item4.countryName
                  )
                ) {
                  $scope.selectedProxyCountry = item4;
                }
              });
              //preselect shareholder title
              angular.forEach(
                $scope.attendanceData.shareHolderTitleVO,
                function (item5) {
                  if (
                    angular.equals(
                      $scope.submittedBulkUploadDetails.title,
                      item5.title
                    )
                  ) {
                    $scope.selectedTitle = item5;
                  }
                  if (
                    angular.equals(
                      $scope.submittedBulkUploadDetails.proxyTitle,
                      item5.title
                    )
                  ) {
                    $scope.selectedProxyTitle = item5;
                  }
                }
              );
              //preselect proxytype
              angular.forEach($scope.attendanceData.proxyTypeVO, function (
                item6
              ) {
                if (
                  angular.equals(
                    $scope.submittedBulkUploadDetails.proxyType,
                    item6.proxyType
                  )
                ) {
                  $scope.selectedProxyType = item6;
                }
              });
            },
            function (error) {
              $log.debug(error);
            }
          );
      };

      $scope.onProxyNameChange = function (selectedProxyList) {
        //Below code is added for ForFarmer default proxy chnages
        farmerProxySelected = false;
        if (
          $scope.selectedProxyList[0].proxyName === 'Coöperatie FromFarmers UA'
        ) {
          farmerProxySelected = true;
        }
        $scope.selectedProxyInitial = selectedProxyList[0].proxyInitial;
        $scope.selectedProxyCountry = $filter('filter')(
          $scope.attendanceData.countryCodeVO,
          { isoCountryCode: selectedProxyList[0].proxyCountry },
          true
        );
        $scope.selectedProxyRepresentativeName =
          selectedProxyList[0].representativeName;
        $scope.selectedProxyLegalIdentifier =
          selectedProxyList[0].legalIdentifier;
        $scope.selectedProxyCity = selectedProxyList[0].proxyCity;
        $scope.selectedProxyTitle = $filter('filter')(
          $scope.attendanceData.shareHolderTitleVO,
          {
            title: selectedProxyList[0].proxyTitle,
          },
          true
        );
        $scope.selectedProxyCode = $filter('filter')(
          $scope.attendanceData.proxyTypeVO,
          { proxyTypeCode: selectedProxyList[0].proxyType },
          true
        );
      };
      // Below is the function call on selection radio buttons
      // to hide and show particular section

      $scope.selectedRadio = function (value) {
        switch (value) {
          case 'proxyCreate':
            $scope.createProxy = true;
            $scope.selectProxy = false;
            $scope.editProxy = 'N';
            $scope.createNewProxy = 'Y';
            $scope.proxyProvidedValue = value;
            $scope.proxyProvided = 'Y';
            $scope.proxyName = null;
            $scope.proxyCity = null;
            $scope.proxyInitials = null;
            $scope.representativeName = null;
            //To select Netherlands by default
            angular.forEach($scope.attendanceData.countryCodeVO, function (
              item4
            ) {
              if (angular.equals('NLD', item4.isoCountryCode)) {
                $scope.onProxyCountryChange(item4);
              }
            });
            break;
          case 'proxyEdit':
            $scope.createProxy = false;
            $scope.selectProxy = false;
            $scope.editProxy = 'Y';
            $scope.createNewProxy = 'N';
            $scope.proxyProvided = 'Y';
            $scope.proxyProvidedValue = value;
            $scope.proxyInitials =
              $scope.submittedBulkUploadDetails.proxyInitials;
            $scope.proxyName = $scope.submittedBulkUploadDetails.proxyName;
            $scope.proxyCity = $scope.submittedBulkUploadDetails.proxyCity;
            $scope.legalIdentifier =
              $scope.submittedBulkUploadDetails.legalIdentifier;
            $scope.representativeName =
              $scope.submittedBulkUploadDetails.representativeName;
            //To select country by default
            angular.forEach($scope.attendanceData.countryCodeVO, function (
              item4
            ) {
              if (
                angular.equals(
                  $scope.submittedBulkUploadDetails.proxyCountryCode,
                  item4.isoCountryCode
                )
              ) {
                $scope.selectedProxyCountry = item4;
              }
            });
            break;
          case 'proxySelect':
            $scope.createProxy = false;
            $scope.selectProxy = true;
            $scope.editProxy = 'N';
            $scope.createNewProxy = 'Y';
            $scope.proxyProvided = 'Y';
            $scope.proxyProvidedValue = value;
            if ($scope.isForFarmer) {
              $scope.selectedProxyList = $filter('filter')(
                $scope.proxyDetails,
                { proxyName: 'Coöperatie FromFarmers UA' },
                true
              );
            } else {
              $scope.selectedProxyList = $filter('filter')(
                $scope.proxyDetails,
                {
                  proxyName: 'Elke Vertegenwoordiger Van De VEB',
                },
                true
              );
            }
            $scope.onProxyNameChange($scope.selectedProxyList);
            break;
          default:
            $scope.createProxy = false;
            $scope.selectProxy = false;
            $scope.editProxy = 'N';
            $scope.proxyProvided = 'N';
            $scope.createNewProxy = 'N';
            $scope.proxyProvidedValue = 'none';
            break;
        }
      };
      //to be checked. This has to be used to fetch the details of proxy
      //available for a shareholder. Unsure about its usage for intermediary
      attendanceRequestService
        .getAvailableProxyList($sessionStorage.groupId)
        .then(
          function (response) {
            if (response.data.length === 0) {
              if ($scope.isForFarmer) {
                $scope.proxyDetails = $scope.proxyDetails.concat(
                  proxyForFarmers
                );
              }
              $scope.proxyDetails = $scope.proxyDetails.concat(defautProxy);
            } else {
              $scope.checkForDefualtProxy = $filter('filter')(
                response.data,
                {
                  proxyName: 'Elke Vertegenwoordiger Van De VEB',
                },
                true
              );
              $scope.checkForFarmerProxy = $filter('filter')(
                response.data,
                {
                  proxyName: 'Coöperatie FromFarmers UA',
                },
                true
              );
              $scope.proxyDetails = response.data;
              if (
                $scope.checkForFarmerProxy.length === 0 &&
                $scope.isForFarmer
              ) {
                $scope.proxyDetails = $scope.proxyDetails.concat(
                  proxyForFarmers
                );
              }
              if ($scope.checkForDefualtProxy.length === 0) {
                $scope.proxyDetails = $scope.proxyDetails.concat(defautProxy);
              }
            }
            if ($scope.isForFarmer) {
              $scope.selectedProxyList = $filter('filter')(
                $scope.proxyDetails,
                {
                  proxyName: 'Coöperatie FromFarmers UA',
                },
                true
              );
              $scope.selectedProxyCity = 'Lochem';
            } else {
              $scope.selectedProxyList = $filter('filter')(
                $scope.proxyDetails,
                {
                  proxyName: 'Elke Vertegenwoordiger Van De VEB',
                },
                true
              );
              $scope.selectedProxyCity = 'Den Haag';
            }
          },
          function (error) {
            $log.debug(error);
          }
        );

      /* date picker */
      $scope.inlineOptions = {
        customClass: getDayClass,
      };

      $scope.open1 = function () {
        $scope.popup1.opened = true;
      };

      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };

      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];

      $scope.popup1 = {
        opened: false,
      };
      function disabled(data) {
        var date, mode;
        date = data.date;
        mode = data.mode;
        $log.debug(date);
        $log.debug(mode);
      }
      $scope.dateOptions = {
        dateDisabled: disabled,
        formatYear: 'yy',
        maxDate: new Date(),
        //removed min validation for date picker -SFCBP-1977
        showWeeks: false,
      };

      function getDayClass(data) {
        var date = data.date,
          mode = data.mode;
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(
              0,
              0,
              0,
              0
            );
            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }
        return '';
      }
      $scope.dateEnter = function (dob) {
        var currentdate = new Date().setHours(0, 0, 0, 0);
        if (dob > currentdate) {
          $scope.dobError = true;
        } else {
          $scope.dobError = false;
        }
      };
      /* date picker end */

      $scope.termsOfUseUrl = getTermsOfUseUrl(
        'GM',
        $sessionStorage.workstationData.selectedLang
      );

      // Below method call from HTML on click of upload BO flie
      $scope.onFileSelect = function (e) {
        $scope.fileStatus = '';
        $scope.loadingFileSpin = true;
        var filesList = e.files;
        var fd = new FormData();
        fd.append('file', filesList[0]);
        $scope.fileType = filesList[0].type;
        voteInstructionService.uploadBOFile(fd, 'secureToken').then(
          function (response) {
            $scope.newFileUploadedAR = true;
            $scope.loadingFileSpin = false;
            if (response.status === 200) {
              $scope.saveDisabled = false;
              $scope.filePath = response.data.filePath;
              $scope.fileStatus = response.data.fileStatus;
              $scope.fileName = response.data.fileName;
              $scope.saveStatus = '';
              $scope.errorHere = false;
              $scope.errorMsg = '';
            } else {
              $scope.errorHere = true;
              $scope.errorMsg = response.data.fileStatus;
              $scope.saveDisabled = true;
              $scope.fileName = response.data.fileName;
              $scope.fileStatus = response.data.fileStatus;
              $log.debug('error');
              $scope.saveStatus = '';
            }
          },
          function (error) {
            $scope.loadingFileSpin = false;
            $scope.newFileUploadedAR = true;
            $scope.errorHere = true;
            $scope.errorMsg = $filter('translate')('label_file_format_xlsx');
            $scope.saveDisabled = true;
            $scope.fileName = '';
            $scope.fileStatus = error.fileStatus;
            $log.debug('error');
            $scope.saveStatus = '';
          }
        );
      };
      //ALM-10534 to continue AR on click of continue button in dupicate warning modal window
      $scope.continueAttend = function () {
        $scope.showTcModal = true;
      };
      $scope.checkShareclassModal = function () {
        //Check if selected linked intermediary is Expired or Non Expired. For expired , throw error message
        if (
          $sessionStorage.proxyType === 'P' &&
          $scope.selectedLinkedInt.invalidPOAcandidates === 'Y'
        ) {
          $scope.linkedIntError = $filter('translate')('label_linkedIntError');
        } else {
          $scope.intermediaryConfirmAR();
        }
      };
      $scope.intermediaryConfirmAR = function () {
        $scope.duplicateRequest = false;
        var proxySelectedName = null;
        if (
          $scope.proxyProvidedValue &&
          $scope.proxyProvidedValue !== 'none' &&
          $scope.proxyName
        ) {
          proxySelectedName = $scope.proxyName;
        } else if (
          $scope.proxyProvidedValue &&
          $scope.proxyProvidedValue !== 'none' &&
          $scope.selectedProxyList[0].proxyName
        ) {
          proxySelectedName = $scope.selectedProxyList[0].proxyName;
        }
        if (
          $scope.selectedOwneType.userType === 'NOMI' ||
          $scope.selectedOwneType.userType === 'TRUS'
        ) {
          if (
            $scope.filePath === null ||
            ($scope.filePath === undefined && $scope.newFileUploadedAR)
          ) {
            $scope.errorHere = true;
            $scope.errorMsg = $filter('translate')('uploadFile_error');
          } else {
            $scope.errorHere = false;
            if (
              $scope.fileType !==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
              $scope.newFileUploadedAR
            ) {
              $scope.errorHere = true;
              $scope.errorMsg = $filter('translate')('label_file_format_xlsx');
            } else {
              $scope.errorHere = false;
              //Service call to check whether the attendance request is already submitted for the current combination of share class, intermediary and number of shares
              voteInstructionService
                .checkAttendRegistrationList(
                  $stateParams.gmId,
                  $scope.selectedShareClass.shareclassId,
                  $scope.lastName,
                  $scope.numberOfShares,
                  proxySelectedName,
                  $stateParams.reqId
                )
                .then(
                  function (response) {
                    $log.debug('success user view');
                    if (response.data.length > 0) {
                      $scope.duplicateRequest = true;
                      $scope.modalBody =
                        'There is already an attendance request for ' +
                        $scope.lastName +
                        ' for ' +
                        $scope.numberOfShares +
                        ' securities. Are you sure you want to continue as this might be a duplicate entry?';
                      $scope.showAlertBox = true;
                    } else {
                      if ($scope.statusAR === 'A') {
                        $scope.submitAttendanceRequest();
                      } else {
                        $scope.continueAttend();
                      }
                    }
                  },
                  function (error) {
                    $log.debug(error);
                  }
                );
            }
          }
        } else {
          $scope.errorHere = false;
          //Service call to check whether the voting instruction is already submitted for the current combination of share class, intermediary and number of shares
          voteInstructionService
            .checkAttendRegistrationList(
              $stateParams.gmId,
              $scope.selectedShareClass.shareclassId,
              $scope.lastName,
              $scope.numberOfShares,
              proxySelectedName,
              $stateParams.reqId
            )
            .then(
              function (response) {
                $log.debug('success user view');
                if (response.data.length > 0) {
                  $scope.duplicateRequest = true;
                  $scope.modalBody =
                    'There is already an attendance request for ' +
                    $scope.lastName +
                    ' for ' +
                    $scope.numberOfShares +
                    ' securities. Are you sure you want to continue as this might be a duplicate entry?';
                  $scope.showAlertBox = true;
                } else {
                  if ($scope.statusAR === 'A') {
                    $scope.submitAttendanceRequest();
                  } else {
                    $scope.continueAttend();
                  }
                }
              },
              function (error) {
                $log.debug(error);
              }
            );
        }
      };

      // Below is the function to submit attendance request

      $scope.submitAttendanceRequest = function () {
        $scope.response = null;
        $scope.error = null;
        $scope.loadingSpin = true;
        //farmerProxySelected and $scope.isForFarmer - is added for ForFarmer default proxy chnages
        if ($scope.selectProxy && farmerProxySelected) {
          $scope.proxyCity = 'Lochem';
          $scope.isoCountrycode = 'NLD';
          $scope.proxyTypeCode = 'LGL';
          $scope.proxyName = 'Coöperatie FromFarmers UA';
          $scope.representativeName =
            'Iedere vertegenwoordiger van de Cöoperatie FromFarmers';
          $scope.legalEntityIdentifier = null;
        } else if ($scope.selectProxy && !farmerProxySelected) {
          $scope.proxyCity = 'Den Haag';
          $scope.isoCountrycode = 'NLD';
          $scope.proxyTypeCode = 'LGL';
          $scope.proxyName = 'Elke Vertegenwoordiger Van De VEB';
          $scope.representativeName = null;
          $scope.legalEntityIdentifier = null;
        }
        if ($scope.createProxy) {
          $scope.isoCountrycode = $scope.selectedProxyCountry.isoCountryCode;
          $scope.proxyTypeCode = $scope.selectedProxyType.proxyTypeCode;
        }
        if (isNaN($scope.dob) && $scope.dob !== undefined) {
          $scope.dob = $scope.dob.getTime();
        }
        if ($scope.selectedProxyTitle && !$scope.selectProxy) {
          $scope.proxyTitle = $scope.selectedProxyTitle.title;
        } else {
          $scope.proxyTitle = null;
        }
        if (
          $scope.selectedTitle &&
          $scope.selectedShareholderType.proxyTypeCode !== 'LGL'
        ) {
          $scope.shareholderTitle = $scope.selectedTitle.title;
        } else {
          $scope.shareholderTitle = null;
        }
        //SFCBP-2488 confirm/save button not enabled for normal intermediary
        var linkedIntermediaryId = '';
        var intermediaryName = '';
        if ($scope.selectedLinkedInt) {
          linkedIntermediaryId = $scope.selectedLinkedInt.intermediaryId;
          intermediaryName = $scope.selectedLinkedInt.intermediaryName;
        }
        //ShareholderId resetting to null - Code here is removed to avoid generic processing error in duplicate AR edit
        $scope.intermediaryId = $sessionStorage.groupId;

        let attendanceRequestData = {
          workStationType: $scope.userType,
          meetingId: $stateParams.gmId,
          langCode: $scope.languageCode,
          issuerName: $scope.issuerGroupDetails.issuerName,
          issuerId: $stateParams.issuerId,
          loginUserId: $sessionStorage.userId,
          linkedIntermediaryId: linkedIntermediaryId,
          intermediaryName: intermediaryName,
          prxoyIntermediaryFlag: $scope.prxoyIntermediaryFlag,
          intermediaryId: $scope.intermediaryId,
          noOfSecurities: $scope.numberOfShares,
          attendanceId: $stateParams.reqId,
          isin: $scope.isin,
          initiatedThruShrhWrkst: 'N',
          filePath: $scope.filePath,
          fileStatus: $scope.fileStatus,
          fileName: $scope.fileName,
          registrationNo: $stateParams.registrationNo,
          gmDate: $stateParams.gmDate,
          registrationSrc: $scope.registrationSrc,
          securityAccNo: $scope.securityAccountNumber,
          shareHolderVOList: [
            {
              isSharePlanUser: 'N',
              shareholderId: $stateParams.shareholderId,
              shareclassId: $scope.selectedShareClass.shareclassId,
              shareHolderType: $scope.selectedShareholderType.proxyTypeCode,
              address: $scope.address,
              city: $scope.city,
              country: $scope.selectedCountry.isoCountryCode,
              emailAddress: $scope.emailAddress,
              firstName: $scope.firstName,
              initials: $scope.initials,
              lastName: $scope.lastName,
              contactPerson: $scope.contactPerson,
              legalEntityIdentifier: $scope.legalEntityIdentifier,
              owneType: $scope.selectedOwneType.userType,
              // if the phoneNumber variable is falsy set to undefined to not send it to the backend at all
              // otherwise it will fail validation there and block this request
              phoneNumber: $scope.phoneNumber ? $scope.phoneNumber : undefined,
              postalCode: $scope.postalCode,
              securityAccountNumber: $scope.securityAccountNumber,
              dob: $scope.dob,
              title: $scope.shareholderTitle,
              isin: $scope.isin,
            },
          ],
        };

        if ($scope.proxyProvided === 'Y') {
          attendanceRequestData.shareHolderVOList[0].proxyProvided =
            $scope.proxyProvided;
          attendanceRequestData.shareHolderVOList[0].createNewProxy =
            $scope.createNewProxy;
          attendanceRequestData.shareHolderVOList[0].proxyEdit =
            $scope.editProxy;
          attendanceRequestData.shareHolderVOList[0].proxyCity =
            $scope.proxyCity;
          attendanceRequestData.shareHolderVOList[0].proxyInitials =
            $scope.proxyInitials;
          attendanceRequestData.shareHolderVOList[0].proxyName =
            $scope.proxyName;
          attendanceRequestData.shareHolderVOList[0].proxyTitle =
            $scope.proxyTitle;
          attendanceRequestData.shareHolderVOList[0].representativeName =
            $scope.representativeName;
          attendanceRequestData.shareHolderVOList[0].legalIdentifier =
            $scope.legalIdentifier;
        }

        if ($scope.proxyProvided === 'Y' && $scope.createNewProxy === 'Y') {
          //for create and select proxy
          attendanceRequestData.shareHolderVOList[0].proxyCountry =
            $scope.isoCountrycode;
          attendanceRequestData.shareHolderVOList[0].proxyType =
            $scope.proxyTypeCode;
        } else if ($scope.proxyProvided === 'Y' && $scope.editProxy === 'Y') {
          //for edit proxy
          attendanceRequestData.shareHolderVOList[0].proxyId =
            $scope.submittedBulkUploadDetails.proxyId;
          attendanceRequestData.shareHolderVOList[0].proxyCountry =
            $scope.selectedProxyCountry.isoCountryCode;
          attendanceRequestData.shareHolderVOList[0].proxyType =
            $scope.selectedProxyType.proxyTypeCode;
        } else {
          //for no proxy
          attendanceRequestData.shareHolderVOList[0].proxyProvided = 'N';
          attendanceRequestData.shareHolderVOList[0].createNewProxy = 'N';
        }
        //submit AR request
        attendanceRequestService
          .saveAttendanceRequestIntermediary(
            attendanceRequestData,
            'secureToken'
          )
          .then(
            function (response) {
              $log.debug(response.data.message);
              $scope.loadingSpin = false;
              $timeout(function () {
                document.getElementById('backButton').click();
              }, 2000);
            },
            function (error) {
              $scope.loadingSpin = false;
              $scope.error = error.data.errors[0].errorMessage;
              //Below service call is added to send email to agent when the number of shares entered exceeds the total number of securities available
              if (
                error.data.errors[0].errorCode === 'CBP_ERR_520' &&
                $scope.userType === 'IN'
              ) {
                $scope.EmailData = {
                  votingInstType: 'attend',
                  languageCode: 'EN',
                  workStationType: $scope.userType,
                  meetingId: $stateParams.gmId,
                  shareClassId: $scope.selectedShareClass.shareclassId,
                  intermediaryId: $scope.intermediaryId,
                  issuerId: $stateParams.issuerId,
                  loginUserId: $sessionStorage.userId,
                };
                voteInstructionService
                  .sendSharesExceededMail($scope.EmailData, 'secureToken')
                  .then(
                    function (response) {
                      $log.debug(response);
                    },
                    function (error) {
                      $log.debug(error);
                    }
                  );
              }
            }
          );
      };
      /* download BO File starts here */
      $scope.downloadBOFile = function () {
        var downloadUrl =
          '/v2/gm/intermediary/attendance-requests/' +
          $scope.reqId +
          '/bo-document';
        var a = document.createElement('a');
        document.body.appendChild(a);
        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            let fileVO = response;
            if (window.navigator.msSaveOrOpenBlob) {
              a.onclick = function () {
                window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
              };
              a.click();
            } else {
              var fileURL = URL.createObjectURL(fileVO.file);
              a.href = fileURL;
              a.download = fileVO.fileName;
              a.click();
            }
            downloadUrl = '';
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      /* download BO File ends here */
      $scope.getSubmittedDetails();
    },
  ]);
