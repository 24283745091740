import htmlDecode from '@/lib/htmlDecode';
import { formatDateTime } from '@/lib/date';

/**
 * @ngDoc controller
 * @name shareHolderModule.VaRequestStep1Controller
 * @description This controller is used to register step1 details for Virtual Attendance Request
 * @requires
 */
angular.module('shareHolderModule').controller('VaRequestStep1Controller', [
  '$scope',
  '$stateParams',
  '$log',
  '$state',
  '$filter',
  'shareHolderService',
  'VaRequestStateService',
  'userGroupService',
  '$sessionStorage',
  'entitlementsService',
  'AuthService',
  '$timeout',
  '$window',
  function (
    $scope,
    $stateParams,
    $log,
    $state,
    $filter,
    shareHolderService,
    VaRequestStateService,
    userGroupService,
    $sessionStorage,
    entitlementsService,
    AuthService,
    $timeout,
    $window
  ) {
    $scope.securityAccountValidation = validationRegex.SECURITY_ACCOUNT_NR;
    $scope.meetingId = '';
    $scope.shareClassList = [];
    $scope.vaStep1FormData = {};

    $scope.formatDate = formatDateTime;

    var meetingId;
    var issuerId = '';

    const fakeIntermediaryId = 'tgmdneIWDJYlC9mgLTE=';

    //Below function is to validate SAN and navigating to next page
    $scope.next = function () {
      $scope.errorMsg = '';

      let selectedIntermediaryId = null;
      if ($scope.selectedIntermediary) {
        selectedIntermediaryId = $scope.selectedIntermediary.intermediaryId;
      } else {
        // shareplans do not require a intermediary, a random
        // value will be set here to work around the id decoder
        selectedIntermediaryId = fakeIntermediaryId;
      }

      if ($sessionStorage.usertype === 'SH') {
        var validateReqData = {
          securityAccountNumber: $scope.vaStep1FormData.securityAccountNumber,
          loginUserId: $sessionStorage.userId,
          shareholderId: $sessionStorage.groupId,
          languageCode: $sessionStorage.selectedLang,
          noOfSecurities: $scope.vaStep1FormData.numberOfShares,
          meetingId: meetingId,
          issuerId: issuerId,
          shareClassId: $scope.selectedShareClass.shareclassId,
          intermediaryId: selectedIntermediaryId,
          workStationType: $sessionStorage.usertype,
        };
        shareHolderService
          .validateVirtualParticipationRequest(validateReqData)
          .then(
            function () {
              $scope.gotoStep2(meetingId, issuerId, selectedIntermediaryId);
            },
            function (error) {
              if (error.data.errors) {
                $scope.errorCode = error.data.errors[0].errorCode;
                $scope.errorMsg = error.data.errors[0].errorMessage;
              } else {
                $scope.errorCode = error.data.httpStatusCode;
                $scope.errorMsg = error.statusText;
              }
            }
          );
      } else {
        // go directly to step 2 without SAN validation if SP
        $scope.gotoStep2(meetingId, issuerId, selectedIntermediaryId);
      }
    };

    // when language changes, following piece of code is executed from header-controller.js
    $scope.$on('onLanguageChangeShareHolder', function (event, lang) {
      $scope.shareClassList = [];
      if ($sessionStorage.usertype === 'SP') {
        $scope.fetchEntitlements(lang);
      } else {
        getShareClassList(lang);
      }
    });

    //Below function is to get share class list of respective language
    function getShareClassList(lang) {
      $scope.onLoadSpinner = true;
      shareHolderService
        .getShareholderShareClassList(meetingId, lang)
        .query()
        .$promise.then(
          function (response) {
            $scope.shareClassList.push(...response.shareClassISINVO);
            $scope.selectedShareClass = response.shareClassISINVO[0];
            $scope.meetingDate = response.generalMeetingDateMiilis;
            $scope.meetingTime = response.generalMeetingDate.split(' ')[3];
            $scope.timezoneStr = response.timezoneStr;
            $scope.shPhoneNumber = response.sharePlanHolderVO.phoneNumber;
            $scope.shPhoneCountryCode = {
              dialCode: response.sharePlanHolderVO.phoneCountryCode,
            };
            VaRequestStateService.setMeetingData(
              $scope.meetingDate,
              $scope.meetingTime,
              $scope.timezoneStr,
              null,
              meetingId
            );
            $scope.selectedIntermediary = $filter('filter')(
              $scope.selectedShareClass.intermediaryVO,
              {
                intermediaryId:
                  $scope.selectedShareClass.intermediaryVO[0].intermediaryId,
              }
            )[0];
            $scope.onLoadSpinner = false;
          },
          function (error) {
            $scope.onLoadSpinner = false;
            return error;
          }
        );
    }

    function init() {
      if ($stateParams.fromPageStep2 !== 'vaRequestStep2') {
        VaRequestStateService.resetData(); // when not coming from step to assume a new request is started.
        meetingId = $stateParams.gmId;
        issuerId = $stateParams.issuerId;

        if ($sessionStorage.usertype === 'SP') {
          $scope.fetchEntitlements($sessionStorage.selectedLang);
        } else {
          getShareClassList($sessionStorage.selectedLang);
        }

        // Below is the function used to fetch issuer logo
        userGroupService.getIssuerGroupLogo(issuerId).then(
          function (response) {
            $scope.issuerGroupDetails = response.data;
            VaRequestStateService.setIssuer($scope.issuerGroupDetails);
          },
          function (error) {
            $log.debug(error);
          }
        );
      } else {
        //when come back from step2 page
        var meetingData = VaRequestStateService.getMeetingData();
        $scope.selectedLang = $sessionStorage.selectedLang;
        $scope.meetingDate = meetingData.gmDateMillis;
        $scope.meetingTime = meetingData.meetingTime;
        $scope.timezoneStr = meetingData.timezoneStr;
        $scope.meetingDateTime = meetingData.meetingDateTime;
        meetingId = meetingData.meetingId;
        $scope.issuerGroupDetails = meetingData.issuerDetails;
        var formData = VaRequestStateService.getFormData('vaRequestStep1');
        if (formData) {
          issuerId = formData.issuerId;
          $scope.shareClassList = formData.shareClassList;
          $scope.selectedShareClass = $filter('filter')($scope.shareClassList, {
            shareclassId: formData.shareClassId,
          })[0];
          $scope.vaStep1FormData.holding = formData.holding;
          $scope.vaStep1FormData.numberOfShares = formData.numberOfShares;
          $scope.vaStep1FormData.securityAccountNumber =
            formData.securityAccountNumber;
          if ($scope.selectedShareClass.isShareplanShareclass) {
            $scope.selectedIntermediaryId = fakeIntermediaryId;
          } else {
            $scope.selectedIntermediary = $filter('filter')(
              $scope.selectedShareClass.intermediaryVO,
              {
                intermediaryId: formData.intermediaryId,
              }
            )[0];
          }
        }
      }
    }

    $scope.fetchEntitlements = (lang) => {
      $scope.onLoadSpinner = true;
      try {
        entitlementsService.list(meetingId, lang).then((result) => {
          let response = result.data;

          $scope.shareClassList = [];
          for (var i = 0; i < response.entitlements.length; ++i) {
            var shareclass = {
              shareclassId: response.entitlements[i].shareclass.shareclassId,
              shareClassName:
                response.entitlements[i].shareclass.shareclassName,
              meetingName: response.generalMeeting.meetingName,
              isin: response.entitlements[i].shareclass.isin,

              isShareplanShareclass: true,
              position: response.entitlements[i].position,
              securityAccountNumber: response.shareholder.securityAccountNumber,
            };
            $scope.shareClassList.push(shareclass);
          }
          if (response.entitlements.length > 0) {
            $scope.selectedShareClass = $scope.shareClassList[0];
            $scope.vaStep1FormData.numberOfShares =
              $scope.selectedShareClass.position;
            $scope.vaStep1FormData.securityAccountNumber =
              $scope.selectedShareClass.securityAccountNumber;
            $scope.selectedIntermediary = null;

            $scope.meetingDateTime = response.generalMeeting.meetingDate;
            $scope.timezoneStr = response.generalMeeting.meetingTimeZone;

            $scope.shPhoneNumber = '';
            $scope.shPhoneCountryCode = {
              dialCode: '',
            };
            VaRequestStateService.setMeetingData(
              null,
              null,
              $scope.timezoneStr,
              $scope.meetingDateTime,
              response.generalMeeting.generalMeetingId
            );
          }
        });
      } catch (err) {
        $scope.onLoadSpinner = false;
      } finally {
        $scope.onLoadSpinner = false;
      }
    };

    $scope.onSelectIntermediary = function (selectedIntermediary) {
      $scope.selectedIntermediary = selectedIntermediary;
      var intermediaryName = selectedIntermediary.intermediaryName;
      //TODO a flag needs to be added for intermediary object representing the attend/vote is allowed
      if (
        intermediaryName === appConstant.INTERMEDIARY_BINCKBANK ||
        intermediaryName === appConstant.INTERMEDIARY_ALEX
      ) {
        $scope.modalHeader = $filter('translate')('label_votingInsCreatErr');
        $scope.modalLeftButton = $filter('translate')('label_cancel');
        $scope.modalRightButton =
          $filter('translate')('label_proceedToLogout') + intermediaryName;
        $scope.modalBody =
          $filter('translate')('label_asACustomerOf') +
          intermediaryName +
          $filter('translate')('label_voteViaIntermediary') +
          intermediaryName;
        $('#redirectToBickAlex').modal('show');
      }
    };

    $scope.onSelectShareclass = function (selectedShareClass) {
      $scope.selectedShareClass = selectedShareClass;

      if ($scope.isShareplanShareclassSelected()) {
        $scope.vaStep1FormData.numberOfShares = selectedShareClass.position;
        $scope.vaStep1FormData.securityAccountNumber =
          selectedShareClass.securityAccountNumber;
      } else {
        $scope.selectedIntermediary = $filter('filter')(
          $scope.selectedShareClass.intermediaryVO,
          {
            intermediaryId:
              $scope.selectedShareClass.intermediaryVO[0].intermediaryId,
          }
        )[0];

        $scope.vaStep1FormData.numberOfShares = null;
        $scope.vaStep1FormData.securityAccountNumber = null;
      }
    };

    /**
     * Below function is for logout process and reset the
     * session storage and workstation related data
     * and navigates to the login page
     */
    var logout = function (website) {
      AuthService.logout().then(function (data) {
        if (data.status === 200) {
          $timeout(function () {
            $window.location.href = website;
          }, 100);
          $sessionStorage.workstationData = {};
          $sessionStorage.$reset();
        } else {
          $log.debug(data.data.errorMessage);
        }
      });
    };

    //Below method will logout and redirect the user to particular intermediary websites
    $scope.logoutAndRedirect = function (intermediaryName) {
      if (intermediaryName === appConstant.INTERMEDIARY_BINCKBANK) {
        logout(appConstant.INTERMEDIARY_BINCKBANK_WEBISTE);
      } else if (intermediaryName === appConstant.INTERMEDIARY_ALEX) {
        logout(appConstant.INTERMEDIARY_ALEX_WEBISTE);
      }
    };

    //Below function is to reset the intermediary dropdown
    $scope.resetIntermediary = function () {
      $scope.selectedIntermediary = '';
    };

    $scope.shCustodianMessage = () =>
      htmlDecode($filter('translate')('label_sh_different_custodian_message'));

    $scope.isShareplanShareclassSelected = () => {
      if (
        $scope.selectedShareClass &&
        $scope.selectedShareClass.isShareplanShareclass
      ) {
        return $scope.selectedShareClass.isShareplanShareclass;
      }
      return false;
    };

    $scope.isFormCompleted = () => {
      if ($scope.isShareplanShareclassSelected()) {
        // for shareplans, all fields are pre-filled
        return true;
      }

      if (
        $scope.vaStep1FormData.holding &&
        $scope.vaStep1FormData.holding === 'partial'
      ) {
        return $scope.vaStep1FormData.numberOfShares > 0;
      }

      return true;
    };

    $scope.cancel = function () {
      if ($scope.isShareplanShareclassSelected()) {
        $state.go('gmList', {
          selectedIndex: 0,
        });
      } else {
        $state.go('shGeneralMeetings', {
          selectedIndex: 0,
        });
      }
    };

    $scope.gotoStep2 = function (meetingId, issuerId, selectedIntermediaryId) {
      VaRequestStateService.setFormData('vaRequestStep1', {
        shareClassId: $scope.selectedShareClass.shareclassId,
        intermediaryId: selectedIntermediaryId,
        holding: $scope.vaStep1FormData.holding,
        numberOfShares: $scope.vaStep1FormData.numberOfShares,
        securityAccountNumber: $scope.vaStep1FormData.securityAccountNumber,
        shareClassList: $scope.shareClassList,
        selectedIntermediary: $scope.selectedIntermediary,
        issuerId: issuerId,
        meetingId: meetingId,
      });
      $state.go('vaRequestStep2', {
        selectedIndex: 1,
        phoneCountryCode: $scope.shPhoneCountryCode,
        phoneNumber: $scope.shPhoneNumber,
      });
    };

    //call the below function on load
    init();
  },
]);
