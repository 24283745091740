/**
 * @ngdoc controller
 * @name userMaintenanceModule.IntermediaryUserListController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the list of users belonging to particular
 *              intermediary group
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter the firstName and to translate messages from
 *           locale file
 * @requires PaginationSvc for pagination
 */

angular
  .module('userMaintenanceDwtModule')
  .controller('IntermediaryUserListDwtController', [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$sessionStorage',
    '$filter',
    'userService',
    'PaginationSvc',
    '$timeout',
    function (
      $scope,
      $log,
      $state,
      $stateParams,
      $sessionStorage,
      $filter,
      userService,
      PaginationSvc,
      $timeout
    ) {
      $scope.loggedInUserId = $sessionStorage.userId;
      $scope.groupId = $stateParams.groupId;
      $scope.groupStatus = $stateParams.status;
      $scope.message = $stateParams.successMsg;
      $scope.showDisabledItems = 'N';
      $scope.user = 'intermediary';
      $scope.prodType = $sessionStorage.productType;
      // $scope.loading is used for spinner
      $scope.loading = true;
      // call getUserList from userService to fetch user list
      // belonging to the particular intermediary group
      $scope.getIntermediaryList = function (evnt) {
        $scope.message = null;
        $scope.errorMessage = null;
        userService
          .getUserList($scope.groupId, 'IN', $scope.prodType)
          .query()
          .$promise.then(
            function (data) {
              $log.debug('success user view');
              $scope.userData = data;

              var userDataEnabled = [];
              var userDataDisabled = [];
              $scope.userData.forEach(function (item) {
                if ('Disabled' !== item.status) {
                  userDataEnabled.push(item);
                } else {
                  userDataDisabled.push(item);
                }
              });
              if ('N' === evnt) {
                $scope.userDataEnabled = userDataEnabled;
                $scope.dummyItems = userDataEnabled;
              } else {
                $scope.dummyItems = userDataDisabled;
              }
              $scope.pager = {};
              $scope.setPage(1);
              if (null != $scope.message) {
                $scope.success = true;
              } else {
                $scope.success = false;
              }
              $scope.loading = false;
            },
            function (error) {
              $scope.loading = false;
              return error;
            }
          );
      };
      // Below function is to search the users related to entry
      // in that search box
      $scope.onFirstNameChange = function (val, evnt) {
        if ('N' === evnt) {
          $scope.dummyItems = $filter('filter')($scope.userDataEnabled, {
            name: val,
          });
        } else {
          $scope.dummyItems = $filter('filter')($scope.userData, {
            name: val,
          });
        }
        $scope.pager = {};
        $scope.setPage(1);
      };

      $scope.sendResetPasswordLink = function (userId, groupId, status) {
        var statusCode = '';
        if (status === 'Enabled') {
          statusCode = 'E';
        } else if (status === 'Pending') {
          statusCode = 'P';
        } else if (status === 'Locked') {
          statusCode = 'L';
        }
        var requestData = {
          userId: userId,
          userGroupId: groupId,
          userType: 'IN',
          status: statusCode,
          prodType: $scope.prodType,
          loggedInUserId: $scope.loggedInUserId,
        };
        userService.sendResetPwdLink(requestData, 'secureToken').then(
          function (response) {
            $scope.success = true;
            $scope.failure = false;
            $scope.response = response.data.message;
            $('#successMsg').modal('show');
            $timeout(function () {
              $('#successMsg').modal('hide');
              $('.modal-backdrop').remove();
            }, 2000);
            $scope.message = response.message;
          },
          function (error) {
            $scope.failure = true;
            $scope.success = false;
            $scope.errorMessage = error.data.errors[0].errorMessage;
          }
        );
      };

      // Below function is for pagination, this calls from HTML
      // on clicking of each page
      $scope.setPage = function (page) {
        $scope.pager = [];
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }

        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
        $log.debug($scope.pager);

        // get current page of items
        $scope.viewUserListDwt = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
      };
      //Below is function call from HTML for modal window.
      $scope.deleteModal = function (userId, status) {
        if (
          status === 'Enabled' ||
          status === 'Pending' ||
          status === 'Locked'
        ) {
          $scope.statusAction = 'disable';
          $scope.updatedStatus = 'delete';
        } else if (status === 'Disabled') {
          $scope.statusAction = 'enable';
          $scope.updatedStatus = 'reinstate';
        }
        $scope.modalHeader =
          $filter('translate')('label_confirm') +
          ' ' +
          $scope.updatedStatus +
          ' ' +
          $scope.user +
          '!';
        $scope.modalBody =
          $filter('translate')('label_areYouSureModal') +
          $scope.updatedStatus +
          $filter('translate')('label_thisUser');
        $scope.modalFooter = '';
        $scope.modalLeftButton = $filter('translate')('label_confirm');
        $scope.modalRightButton = $filter('translate')('label_cancel');
        $scope.userId = userId;
        $scope.status = status;
      };
      //Below is the function call for deleting an intermediary user.
      $scope.deleteUserList = function (userId) {
        $log.debug('delete intermediary user');
        userService.disableUser(
          userId,
          $scope.prodType,
          'IN',
          $scope.groupId,
          $scope.loggedInUserId,
          'secureToken',
          function (response) {
            $scope.user = 'intermediary';
            $scope.getIntermediaryList($scope.showDisabledItems);
            $scope.message = response.message;
          },
          function (error) {
            $scope.failure = true;
            $scope.errorMessage = error.errors[0].errorMessage;
          }
        );
      };
      // call the function for first time
      $scope.getIntermediaryList('N');
    },
  ]);
