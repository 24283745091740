/**
 * @ngdoc controller
 * @name generalMeetingHomeModule.ViewShareplanController
 * @description This controller is used for general meeting home module. This
 *              mainly deals with sending share plan invitation to shareholders
 *              from agent.
 * @requires AgentService,PaginationSvc and userGroupService  are the services which are
 *           being used to interact with REST
 * @requires $state for state routing
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for fetching the logged in user details from
 *           session storage
 */

'use strict';
angular
  .module('generalMeetingHomeModule')
  .controller('ViewShareplanController', [
    '$scope',
    'generalMeetingService',
    '$stateParams',
    'userGroupService',
    'PaginationSvc',
    '$state',
    '$timeout',
    '$log',
    '$sessionStorage',
    '$element',
    'cbpToast',
    function (
      $scope,
      generalMeetingService,
      $stateParams,
      userGroupService,
      PaginationSvc,
      $state,
      $timeout,
      $log,
      $sessionStorage,
      $element,
      cbpToast
    ) {
      $scope.meetingId = $stateParams.meetingId;
      $scope.currentShareClass = $stateParams.shareclassId;
      $scope.status = $sessionStorage.status;
      $scope.gmDateTime = $stateParams.gmDateTime;
      var pageNumber = 1;
      $scope.selectedSharePlanList = [];
      $scope.uncheckedSharePlanList = [];
      $scope.selectAllFlagSet = false;
      $scope.searchData = '';
      $scope.selectAllSharePlan = false;
      $scope.singleSelect = false;

      $scope.canEditNumberOfShares = function (id) {
        return id === $scope.shareholderToBeUpdated;
      };
      var count = 0;
      $scope.getSharePlanHolders = function (
        searchData,
        pageNumber,
        selectAllFlagSet,
        searchEvent
      ) {
        $scope.searchData = searchData;
        if (searchEvent === true) {
          $scope.selectedSharePlanList = [];
          $scope.selectAllSharePlan = false;
        }
        $scope.loading = true;
        $scope.selectAllFlagSet = selectAllFlagSet;
        //Service call to fetch the ShareplanHolders
        generalMeetingService
          .getGMSharePlanHolders(
            $scope.currentShareClass,
            $scope.meetingId,
            pageNumber,
            appConstant.PAGE_SIZE,
            $scope.searchData,
            'secureToken'
          )
          .then(
            function (response) {
              $scope.gmSharePlanHoldersList = response.data.shareholderVOList;
              $scope.totalRecordsPage = response.data.count;
              $scope.totalRecordCount = $scope.totalRecordsPage;
              if ($scope.gmSharePlanList && !searchEvent) {
                $scope.gmSharePlanList = $scope.gmSharePlanList.concat(
                  $scope.gmSharePlanHoldersList
                );
              } else {
                $scope.gmSharePlanList = $scope.gmSharePlanHoldersList;
              }
              $scope.dummyItems = $scope.gmSharePlanList;
              $scope.pager = {};
              $scope.setCustomPage(pageNumber, $scope.parameter);
              $scope.loading = false;
            },
            function (error) {
              $scope.loading = false;
              $log.debug(error);
            }
          );
        $timeout(function () {
          $scope.selectAllSharePlanHolders();
          $scope.retainSharePlanSelect();
        }, 1000);
      };
      // Below method is used for custom pagination
      $scope.setCustomPage = function (page) {
        // Below is the function to set pagination
        $scope.pager = [];
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }
        if (page !== pageNumber) {
          pageNumber = page;
          $scope.getSharePlanHolders(
            $scope.searchData,
            pageNumber,
            $scope.selectAllFlagSet,
            false
          );
        }
        // get pager object from service
        if ($scope.totalRecordCount) {
          $scope.pager = PaginationSvc.GetPager(
            $scope.totalRecordCount,
            pageNumber
          );
        } else {
          $scope.pager = [];
        }

        // get current page of items
        if ($scope.dummyItems) {
          $scope.items = $scope.dummyItems.slice(
            $scope.pager.startIndex,
            $scope.pager.endIndex + 1
          );
          //following check is to display the correct records in pages when random page number is clicked.
          if ($scope.items.length === 0) {
            if ($scope.dummyItems.length - 10 >= 0) {
              $scope.pager.startIndex = $scope.dummyItems.length - 10;
            } else {
              $scope.pager.startIndex = 0;
            }
            $scope.pager.endIndex = $scope.dummyItems.length - 1;
            $scope.items = $scope.dummyItems.slice(
              $scope.pager.startIndex,
              $scope.pager.endIndex
            );
          }
        } else {
          $scope.items = [];
        }
      };

      //get the count of ShareplanHolders, issuer name, shareclass name, gm name, gm date etc
      generalMeetingService
        .getGmDetails($scope.meetingId, $scope.currentShareClass)
        .then(
          function (response) {
            $scope.loading = false;
            $scope.meetingName = response.data.meetinName;
            $scope.issuerName = response.data.issuerName;
            $scope.gmDate = response.data.gmDate;
            $scope.shareClassName = response.data.shareClassName;
            $scope.emailLogId = response.data.emailLogId;
            $scope.issuerId = response.data.issuerId;
          },
          function (error) {
            $scope.loading = false;
            $log.debug(error);
          }
        );

      //Method call on search
      $scope.onEnterKey = function ($event, searchData, pageNumber) {
        if ($event.keyCode === 13) {
          $scope.getSharePlanHolders(searchData, pageNumber, false, true);
        }
      };

      //Method call to select all the Share planholders for resend invite
      $scope.selectAllSharePlanHolders = function () {
        if ($scope.selectAllSharePlan) {
          if (!$scope.selectAllFlagSet) {
            $scope.selectedSharePlanList = [];
            generalMeetingService
              .getGMSharePlanHolders(
                $scope.currentShareClass,
                $scope.meetingId,
                pageNumber,
                $scope.totalRecordCount,
                $scope.searchData,
                'secureToken'
              )
              .then(
                function (response) {
                  $scope.selectAllDataList = response.data.shareholderVOList;
                  count = $scope.totalRecordCount;
                  $scope.selectAllFlagSet = true;
                  angular.forEach($scope.selectAllDataList, function (
                    selectedSharePlan
                  ) {
                    $scope.selectedSharePlanList.push(
                      selectedSharePlan.emailAddress
                    );
                  });
                },
                function (error) {
                  $scope.loading = false;
                  $log.debug(error);
                }
              );
          }
          angular.forEach($scope.items, function (item) {
            item.select = true;
          });
        } else if (!$scope.selectAllSharePlan) {
          $scope.selectAllFlagSet = false;
          angular.forEach($scope.items, function (selectedSharePlan) {
            selectedSharePlan.select = false;
          });
          if ($scope.singleSelect == false) {
            $scope.selectedSharePlanList = [];
          }
        }
      };

      //Method call to retain the checked list
      $scope.retainSharePlanSelect = function () {
        angular.forEach($scope.selectedSharePlanList, function (emailAddress) {
          angular.forEach($scope.items, function (displayItem) {
            if (emailAddress === displayItem.emailAddress) {
              displayItem.select = true;
            }
          });
        });
        angular.forEach($scope.uncheckedSharePlanList, function (emailAddress) {
          angular.forEach($scope.items, function (displayItem) {
            if (emailAddress === displayItem.emailAddress) {
              displayItem.select = false;
            }
          });
        });
      };

      $scope.updateShareplanHolderPosition = function () {
        if (
          $scope.shareholderToBeUpdated == null ||
          $scope.noSharesToBeUpdated == null
        ) {
          return;
        }

        const data = {
          encodedMeetingId: $scope.meetingId,
          encodedShareclassId: $stateParams.shareclassId,
          encodedShareholderId: $scope.shareholderToBeUpdated,
          numberOfShares: $scope.noSharesToBeUpdated,
        };

        generalMeetingService.updateShareplanHolderPosition(data).then(
          function (response) {
            $element[0]
              .querySelector('#updateModal')
              .closeModal(new Event('click'));
            $state.reload();
          },
          function (error) {
            if (error.data.errors[0].errorCode) {
              cbpToast.show(error.data.errors[0].errorCode, 'negative');
            } else {
              cbpToast.show('An unexpected error has occurred', 'negative');
            }
          }
        );
      };

      $scope.toggleCanEditNumberOfShares = (id) => {
        if (id === $scope.shareholderToBeUpdated) {
          $scope.shareholderToBeUpdated = null;
          $state.reload();
          return false;
        } else if (id && $scope.shareholderToBeUpdated) {
          $state.reload();
        }
        $scope.shareholderToBeUpdated = id;
        return true;
      };

      $scope.closeModal = () => {
        this.setAction = null;
        const root = $element[0];
        const modal = root.querySelector('#updateModal');
        modal.closeModal(new Event('click'));
      };

      $scope.showUpdateNoSharesModal = (shareholderId, noShares) => {
        $scope.shareholderToBeUpdated = shareholderId;
        $scope.noSharesToBeUpdated = noShares;
        const root = $element[0];
        const modal = root.querySelector('#updateModal');
        modal.openModal();
      };

      //Method call to select sharePlanHolder and populate the list
      $scope.selectedSharePlanHolder = function (selectedSharePlan) {
        if (selectedSharePlan.select) {
          count++;
          $scope.selectedSharePlanList.push(selectedSharePlan.emailAddress);
        } else {
          if (count > 0) {
            count--;
          }
          angular.forEach($scope.selectedSharePlanList, function (
            emailAddress
          ) {
            if (emailAddress === selectedSharePlan.emailAddress) {
              selectedSharePlan.select = false;
              var index = $scope.selectedSharePlanList.indexOf(emailAddress);
              $scope.selectedSharePlanList.splice(index, 1);
              $scope.uncheckedSharePlanList.push(emailAddress);
            }
          });
        }
        if (count == 0) {
          $scope.singleSelect = false;
        } else {
          $scope.singleSelect = true;
        }
      };

      //Method call to resend single share plan reinvite
      $scope.resend = function (selectedSharePlan) {
        $state.go('resendSharePlanInvite', {
          emailLogId: $scope.emailLogId,
          resendEmailAddress: selectedSharePlan.emailAddress,
          meetingId: $scope.meetingId,
          meetingName: $scope.meetingName,
          gmDate: $scope.gmDate,
          issuerId: $scope.issuerId,
          gmDateTime: $scope.gmDateTime,
          breadCrumbDetails: [$scope.meetingName],
        });
      };

      //Method call to resend multiple share plan reinvite
      $scope.resendSelected = function (selectedSharePlan) {
        $scope.loading = true;
        $timeout(function () {
          $scope.loading = false;
          $state.go('resendSharePlanInvite', {
            emailLogId: $scope.emailLogId,
            selectedSharePlanList: $scope.selectedSharePlanList,
            meetingId: $scope.meetingId,
            meetingName: $scope.meetingName,
            gmDate: $scope.gmDate,
            issuerId: $scope.issuerId,
            gmDateTime: $scope.gmDateTime,
            breadCrumbDetails: [$scope.meetingName],
          });
        }, 3000);
      };

      //Below is the method to show/hide accordion
      $scope.showHideDetails = function (sharePlanHolder) {
        sharePlanHolder.showDetails = !sharePlanHolder.showDetails;
      };
      //On load method call
      $scope.getSharePlanHolders('', pageNumber, false, false);
    },
  ]);
