('use strict');
/**
 * @ngdoc controller
 * @name commonModule.footerCtrl
 * @description This controller is the main controller for application. This
 *              mainly deals with login, logout functionalities and switching
 *              between the menus
 * @requires $scope for storing scope variables
 */

import './footer.scss';

angular.module('commonModule').controller('footerCtrl', [
  '$scope',
  'RecaptchaService',
  '$location',
  '$sessionStorage',
  'AuthService',
  function (
    $scope,
    RecaptchaService,
    $location,
    $sessionStorage,
    AuthService
  ) {
    const privacyUrls = {
      EN: 'https://www.abnamro.com/en/home/information/privacy-statement',
      FR: 'https://www.abnamro.com/en/home/information/privacy-statement',
      NL: 'https://www.abnamro.com/nl/home/informatie/privacyverklaring',
      DE: 'https://www.abnamro.com/en/home/information/privacy-statement',
      ES: 'https://www.abnamro.com/en/home/information/privacy-statement'
    };

    const cookieUrls = {
      EN: 'https://www.abnamro.com/en/footer/cookies.html',
      FR: 'https://www.abnamro.com/en/footer/cookies.html',
      NL: 'https://www.abnamro.com/nl/footer/cookies.html',
      DE: 'https://www.abnamro.com/en/footer/cookies.html',
      ES: 'https://www.abnamro.com/en/footer/cookies.html'
    };

    var path = $location.$$path;
    $scope.usertype = path.substring(1);
    $scope.$watch(function () {
      if (AuthService.publicURL()) {
        // Only SH has public pages
        $scope.showTermsAndConditons = true;
      } else if ($sessionStorage.productTypes !== undefined) {
        // Logged in
        $scope.showTermsAndConditons = true;
      } else {
        $scope.showTermsAndConditons = false;
      }

      $scope.cookieLink = cookieUrls[$sessionStorage.selectedLang];
      $scope.privacyLink = privacyUrls[$sessionStorage.selectedLang];

      var pathList = window.location.pathname.split('/');
      if (pathList[1] === 'taxReclaimHome') {
        $sessionStorage.qrfPage = null;
        $sessionStorage.pePage = null;
        $sessionStorage.selectedClaimStatus = null;
        $sessionStorage.dwtCheckEventStatus = null;
        $sessionStorage.createData = null;
      }
    });

    // ng-init execute only once
    $scope.init = function () {
      $scope.captchainfo = {};
      // call the captcha rest service and construct the model data
      RecaptchaService.getKeys().then(function (data) {
        $sessionStorage.siteKey = data.siteKey;
      });
    };
  },
]);
