import ctrl from './login.controller';
import workstationSelection from './workstation-selection.component';
import notFoundPage from './notFoundPage/notFoundPage.component';
import './login.scss';

('use strict');
angular.module('loginModule', []).controller('LoginCtrl', ctrl);
//following function redirects to login page based on different workstations
angular
  .module('loginModule')
  .directive('loginForm', [
    function () {
      return {
        link: function (scope, element, attrs) {
          scope.usertype = attrs.usertype;
          scope.workstation = attrs.workstation;
        },
        restrict: 'E',
        controller: 'LoginCtrl',
        templateUrl: 'features/modules/login/login.html',
      };
    },
  ])
  .component({ workstationSelection, notFoundPage });
