/**
 * @ngdoc controller
 * @name userMaintenanceModule.AddPermissionController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the list permissions and downloading forms.
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires PaginationSvc for pagination
 */

angular.module('userMaintenanceModule').controller(
  'AddPermissionController',
  [
    '$scope',
    '$log',
    '$stateParams',
    'userGroupService',
    'PaginationSvc',
    '$sessionStorage',
    function($scope, $log, $stateParams, userGroupService,
      PaginationSvc, $sessionStorage) {

      $scope.groupId = $stateParams.groupId;
      $scope.productCode = $stateParams.productCode;
      // Below is the function call to service to interact with
      // service and get list of permissions available.
      userGroupService.getPermissions($scope.groupId,
        $sessionStorage.userId, 'GM').query().$promise.then(
        function(data) {
          $log.debug('success permissions view', data);
          $scope.dummyItems = data;
          $scope.pager = {};
          $scope.setPage(1);
          $scope.loading = false;
        }, function(error) {
          $scope.loading = false;
          return error;
        });
      // Below is the function for pagination
      $scope.setPage = function(page) {
        $scope.pager = [];
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }

        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length,page);
        $log.debug($scope.pager);

        // get current page of items
        $scope.permissions = $scope.dummyItems.slice(
          $scope.pager.startIndex, $scope.pager.endIndex + 1);
      };
      // Below is the function call from HTML to download agreement form
      /* download registration form starts here */
      $scope.downloadRegistrationForm = function(agreementId,
        productCode) {
        var pathList = window.location.pathname.split('/');
        if (pathList[1] === 'cbp') {
          $scope.downloadRegistrationFormPdf = window.location.origin
                          + '/' + pathList[1]
                          + '/v1/intermediaries/agreementforms/' + agreementId
                          + '/' + productCode + '/download' + '?authid='
                          + $sessionStorage.userId;
        } else {
          $scope.downloadRegistrationFormPdf = window.location.origin
                          + '/v1/intermediaries/agreementforms/' + agreementId
                          + '/' + productCode + '/download' + '?authid='
                          + $sessionStorage.userId;
        }
      };
      /* download registration form ends here */

    }]);
