/**
 * @ngdoc controller
 * @name userMaintenanceModule.EditVCGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with editing details of particular user group
 * @requires userGroupService is the service which is being used to interact
 *           with REST
 * @requires userGroupDetails a variable passed from userMaintenanceModule where
 *           there is a service call to userGroupService
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter country
 * @requires $sessionStorage to get logged in userId from session storage
 * @requires $timeout
 * @requires $uibModal
 */

angular.module('userMaintenanceModule').controller('EditUserGroupController', [
  '$scope',
  '$log',
  '$state',
  '$sessionStorage',
  'userGroupDetails',
  'userGroupService',
  '$filter',
  'fileUploadSvc',
  '$timeout',
  '$uibModal',
  function (
    $scope,
    $log,
    $state,
    $sessionStorage,
    userGroupDetails,
    userGroupService,
    $filter,
    fileUploadSvc,
    $timeout,
    $uibModal
  ) {
    $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

    $state.go('userMaintenance.editAgentGroup');
    $scope.userGroup = 'agent';
    $scope.modalBody = $filter('translate')('confirmGroup_editDetails');
    $scope.modalLeftButton = $filter('translate')('label_confirm');
    $scope.modalRightButton = $filter('translate')('label_cancel');
    // Below is the service call to get list of countries
    userGroupService
      .getCountryList('EN')
      .query()
      .$promise.then(
        function (countries) {
          $log.debug('success user view');
          $scope.countries = countries.countries;
          $scope.selectedCountry = $filter('filter')(
            $scope.countries,
            {
              countryName: userGroupDetails.country,
            },
            true
          );
        },
        function (error) {
          $log.debug(error);
        }
      );
    // The below function call is to store the data from UI
    // and pass it to REST call for updating user group
    $scope.updateUserGroup = function () {
      var documentFormData = new FormData();
      if ($scope.logoFile === null || $scope.logoFile === undefined) {
        documentFormData.append('groupName', $scope.userGroupDetails.username);
        documentFormData.append(
          'userVO.workPhone',
          $scope.userGroupDetails.personalPhone
        );
        documentFormData.append('userId', $sessionStorage.userId);
        documentFormData.append('groupId', 1);
        documentFormData.append(
          'groupEmail',
          $scope.userGroupDetails.emailAddress
        );
        documentFormData.append('city', $scope.userGroupDetails.city);
        documentFormData.append(
          'country',
          $scope.selectedCountry[0].isoCountryCode
        );
        documentFormData.append('productCode', 'GM');
        $scope.error = false;
        var file = $scope.logoFile;
        var uploadUrl = 'v1/agents/' + $sessionStorage.groupId;
        /*
         * Call the upload directive to upload file and
         * FormData
         */
        fileUploadSvc.uploadFileToUrl(file, uploadUrl, documentFormData).then(
          function (data) {
            if (
              data.data.httpStatusCode !== 200 &&
              data.data.errors.length > 0
            ) {
              $scope.error = true;
              $scope.errorMsg = data.data.errors[0].errorMessage;
            } else {
              $scope.success = true;
              $scope.timedout = $uibModal.open({
                templateUrl: 'success-dialog.html',
              });
              $timeout(function () {
                $scope.timedout.close();
                $scope.timedout = null;
                $state.go('userMaintenance.agentGroup');
              }, 2000);
            }
          },
          function (error) {
            $log.debug(error);
            $scope.error = true;
            $scope.errorMsg = error.errors[0].errorMessage;
          }
        );
      } else {
        if ('image' !== $scope.logoFile.type.substring(0, 5)) {
          $scope.error = true;
          $scope.errorMsg = $filter('translate')('label_errorImgFile');
        } else if ($scope.logoFile.size > 256000) {
          $scope.error = true;
          $scope.errorMsg = $filter('translate')('label_errorFileSize');
        } else {
          documentFormData.append(
            'groupName',
            $scope.userGroupDetails.username
          );
          documentFormData.append(
            'userVO.workPhone',
            $scope.userGroupDetails.personalPhone
          );
          documentFormData.append('userId', 1);
          documentFormData.append('groupId', 1);
          documentFormData.append(
            'groupEmail',
            $scope.userGroupDetails.emailAddress
          );
          documentFormData.append('city', $scope.userGroupDetails.city);
          documentFormData.append(
            'country',
            $scope.selectedCountry[0].isoCountryCode
          );
          documentFormData.append('productCode', 'GM');
          documentFormData.append('logo', $scope.logoFile);
          $scope.error = false;
          var file1 = $scope.logoFile;
          var uploadUrl1 = 'v1/agents/' + $sessionStorage;
          /* Call the upload directive to upload file and FormData */
          fileUploadSvc
            .uploadFileToUrl(file1, uploadUrl1, documentFormData)
            .then(
              function (data) {
                if (
                  data.data.httpStatusCode !== 200 &&
                  data.data.errors.length > 0
                ) {
                  $scope.error = true;
                  $scope.timedout = $uibModal.open({
                    templateUrl: 'success-dialog.html',
                  });
                  $timeout(function () {
                    $scope.timedout.close();
                    $scope.timedout = null;
                    $state.go('userMaintenance.agentGroup');
                  }, 2000);
                } else {
                  $scope.success = true;
                  $scope.message = data.data.message;
                  $log.debug(' $scope.message', $scope.message.message);
                }
              },
              function (error) {
                $log.debug(error);
                $scope.error = true;
                $scope.errorMsg = error.errors[0].errorMessage;
              }
            );
        }
      }
    };
    return userGroupDetails;
  },
]);
