/**
 * @ngdoc controller
 * @name userMaintenanceDwtModule.EditUserGroupDwtController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with editing details of particular user group
 * @requires userGroupService is the service which is being used to interact with REST
 * @requires userGroupDetailsDwt a variable passed from userMaintenanceModule where there is a service call to userGroupService
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter to filter country
 * @requires $sessionStorage to get logged in userId from session storage
 */

angular
  .module('userMaintenanceDwtModule')
  .controller('EditAgentGroupDwtController', [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$sessionStorage',
    'userGroupService',
    '$filter',
    'DwtAgentGroup',
    function (
      $scope,
      $log,
      $state,
      $stateParams,
      $sessionStorage,
      userGroupService,
      $filter,
      DwtAgentGroup
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $state.go('userMaintenanceForDWT.editagentGroupDwt');
      $scope.prodType = $stateParams.productCode;
      $scope.userGroup = 'agent';
      userGroupService
        .getAgentGroup('1', $scope.prodType, 'EN')
        .query()
        .$promise.then(
          function (data) {
            $scope.userGroupDetailsDwt = new DwtAgentGroup(data);
          },
          function (error) {
            return error;
          }
        );

      // The below function call is to store the data from UI and pass it to
      // REST call for updating user group
      $scope.updateUserGroup = function () {
        var file = $scope.logoFile;
        var uploadUrl = 'v1/agents/' + $sessionStorage.groupId;
        var userId = $sessionStorage.userId;
        $scope.error = false;
        if ($scope.logoFile === null || $scope.logoFile === undefined) {
          $scope.userGroupDetailsDwt.updateGroup(
            file,
            uploadUrl,
            userId,
            $scope.successCallback,
            $scope.errorCallback,
            $scope.prodType
          );
        } else {
          if ('image' !== $scope.logoFile.type.substring(0, 5)) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('label_errorImgFile');
          } else if ($scope.logoFile.size > 256000) {
            $scope.error = true;
            $scope.errorMsg = $filter('translate')('label_errorFileSize');
          } else {
            $scope.userGroupDetailsDwt.updateGroup(
              file,
              uploadUrl,
              userId,
              $scope.successCallback,
              $scope.errorCallback
            );
          }
        }
      };
      $scope.successCallback = function (data) {
        if (data.data.httpStatusCode !== 200 && data.data.errors.length > 0) {
          $scope.error = true;
          $scope.errorMsg = data.data.errors[0].errorMessage;
        } else {
          $scope.success = true;
          $scope.message = data.data.message;
          $log.debug(' $scope.message', $scope.message.message);
        }
      };
      $scope.errorCallback = function (error) {
        $log.debug(error);
        $scope.error = true;
        $scope.errorMsg = error.data.errors[0].errorMessage;
      };
    },
  ]);
