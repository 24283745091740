/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.intermediaryGeneralMeetingCtrl
 * @description This controller deals with fetching general meeting list
 * @requires $scope for storing information in scope
 */
'use strict';

angular
  .module('intermediaryModule')
  .controller('intermediaryGeneralMeetingCtrl', [
    '$scope',
    '$log',
    '$sessionStorage',
    'intermediaryService',
    '$state',
    'ModelWindowService',
    'fileDownloadSvc',
    'cbpDownloadBlob',
    function (
      $scope,
      $log,
      $sessionStorage,
      intermediaryService,
      $state,
      ModelWindowService,
      fileDownloadSvc,
      cbpDownloadBlob
    ) {
      $scope.userType = $sessionStorage.usertype;
      $scope.isLoading = true;
      $scope.pageSize = appConstant.PAGE_SIZE;
      $scope.searchParam = '';
      $scope.pageNumber = 1;
      $scope.status = appConstant.LIVE;
      $scope.sortDirection = '';
      $scope.sortBy = '';
      $scope.count = '';
      $scope.langCode = 'EN';
      $scope.sortBy = appConstant.GENERAL_MEETING_DATE;
      $scope.sortDirection = appConstant.ASCENDING;

      // set and get functions of gmParams and gmSortParams
      var loadParamsFromSessionStorage = function () {
        if (
          $sessionStorage.intGmParams !== null &&
          $sessionStorage.intGmParams
        ) {
          if (
            $sessionStorage.intGmParams.status &&
            $sessionStorage.intGmParams.status !== null
          ) {
            $scope.status = $sessionStorage.intGmParams.status;
          }
          if (
            $sessionStorage.intGmParams.pageNumber &&
            $sessionStorage.intGmParams.pageNumber !== null
          ) {
            $scope.pageNumber = $sessionStorage.intGmParams.pageNumber;
          }
          if (
            $sessionStorage.intGmParams.searchParam &&
            $sessionStorage.intGmParams.searchParam !== null
          ) {
            $scope.searchParam = $sessionStorage.intGmParams.searchParam;
          }
          if (
            $sessionStorage.intGmParams.sortDirection &&
            $sessionStorage.intGmParams.sortDirection !== null
          ) {
            $scope.sortDirection = $sessionStorage.intGmParams.sortDirection;
          }
          if (
            $sessionStorage.intGmParams.sortBy &&
            $sessionStorage.intGmParams.sortBy !== null
          ) {
            $scope.sortBy = $sessionStorage.intGmParams.sortBy;
          }
          if (
            $sessionStorage.intGmParams.totalLiveRecordCount &&
            $sessionStorage.intGmParams.totalLiveRecordCount !== null
          ) {
            $scope.totalLiveRecordCount =
              $sessionStorage.intGmParams.totalLiveRecordCount;
          }
          if (
            $sessionStorage.intGmParams.pageSize &&
            $sessionStorage.intGmParams.pageSize !== null
          ) {
            $scope.pageSize = $sessionStorage.intGmParams.pageSize;
          }
        } else {
          $sessionStorage.intGmParams = {};
        }
      };
      var storeSortParamsInSessionStorage = function () {
        $sessionStorage.intGmParams.pageNumber = $scope.pageNumber;
        $sessionStorage.intGmParams.searchParam = $scope.searchParam;
        $sessionStorage.intGmParams.totalLiveRecordCount =
          $scope.totalLiveRecordCount;
        $sessionStorage.intGmParams.status = $scope.status;
        $sessionStorage.intGmParams.pageSize = $scope.pageSize;
        $sessionStorage.intGmParams.sortDirection = $scope.sortDirection;
        $sessionStorage.intGmParams.sortBy = $scope.sortBy;
      };

      //on page load
      var init = function () {
        loadParamsFromSessionStorage();
        getGeneralMeetings(
          $scope.pageNumber,
          $scope.pageSize,
          $scope.sortDirection,
          $scope.sortBy,
          $scope.searchParam
        );
      };

      //Serive call to get generalMeeting details
      function getGeneralMeetings(
        pageNumber,
        pageSize,
        sortDirection,
        sortBy,
        searchParam
      ) {
        searchParam = searchParam === undefined ? '' : searchParam;
        $scope.searchParam = searchParam;
        $scope.pageNumber = pageNumber;
        storeSortParamsInSessionStorage();
        $scope.isLoading = true;
        intermediaryService
          .getIntermediaryGeneralMeetings(
            $scope.pageNumber,
            $scope.pageSize,
            $scope.status,
            $scope.sortDirection,
            $scope.sortBy,
            $scope.searchParam
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.intermediaryGeneralMeetingData = data.list;
              if ($scope.status === 'LIVE') {
                $scope.totalLiveRecordCount = data.totalElements;
              }
              $scope.isLoading = false;
              $scope.count = data.totalElements;
            },
            function (error) {
              $scope.isLoading = false;
              $log.debug(error);
            }
          );
      }

      //get general meeting data according to the status(LIVE/COMPLETED)
      $scope.getByStatus = function (status) {
        $scope.status = status;
        getGeneralMeetings(
          1,
          $scope.pageSize,
          $scope.sortDirection,
          $scope.sortBy,
          $scope.searchParam,
          false
        );
      };

      $scope.setSortBy = function (column, sortDirection) {
        $scope.sortDirection = sortDirection;
        $scope.sortBy = column;
        storeSortParamsInSessionStorage();
        getGeneralMeetings(
          $scope.pageNumber,
          $scope.pageSize,
          sortDirection,
          column,
          $scope.searchParam,
          false
        );
      };
      //Below function is for sorting, this will be called on click of column name
      $scope.sortByColumn = function (column) {
        $scope.sortItem = column;
        if (column === '' || !column) {
          return;
        }
        if (
          $scope.sortType === column &&
          $scope.sortDirection === appConstant.ASCENDING
        ) {
          $scope.sortDirection = appConstant.DESCENDING;
        } else {
          $scope.sortDirection = appConstant.ASCENDING;
        }
        $scope.sortType = column;
        $scope.setSortBy($scope.sortType, $scope.sortDirection);
      };
      //pagination function
      $scope.getByPage = function (page) {
        getGeneralMeetings(
          page,
          $scope.pageSize,
          $scope.sortDirection,
          $scope.sortBy,
          $scope.searchParam,
          false
        );
      };
      //search by issuer function
      $scope.searchMeetings = function (searchParam) {
        getGeneralMeetings(
          1,
          $scope.pageSize,
          appConstant.ASCENDING,
          appConstant.GENERAL_MEETING_DATE,
          searchParam,
          true
        );
      };
      $scope.searchOnEnterKey = function ($event, searchParam) {
        if ($event.keyCode === 13) {
          $scope.searchMeetings(searchParam);
        }
      };

      // login to display extension request
      $scope.extensionRequest = function (intermediaryGmData) {
        var todaysDate = intermediaryGmData.cetMillis;
        var intDeadLineDate = intermediaryGmData.intermediaryDeadlineDate;
        if (intDeadLineDate < todaysDate) {
          $scope.isCreateExtn = true;
        } else {
          $scope.isCreateExtn = false;
        }
        return $scope.isCreateExtn;
      };

      //function on click on action button
      $scope.performAction = function (event, generalMeeting) {
        switch (event) {
          case 'vote':
            actionVote(generalMeeting);
            break;
          case 'attend':
            actionAttend(generalMeeting);
            break;
          case 'attendVirtually':
            actionAttendVirtually(generalMeeting);
            break;
          case 'report':
            actionDownloadAcceptedInstructions(generalMeeting);
            break;
          case 'download':
            actionDownloadCertificates(generalMeeting);
            break;
          case 'ShareholderMail':
            actionShareholderEmail(generalMeeting);
            break;
          case 'createExtensionRequest':
            actionCreateExtensionRequest(generalMeeting);
            break;
          default:
            break;
        }
      };
      //navigation to voting page
      function actionVote(generalMeeting) {
        $state.go('votingInfo', {
          type: 'vote',
          gmId: generalMeeting.generalMeetingId,
          issuerId: generalMeeting.issuerId,
          intermediaryDateTimeStamp: generalMeeting.intermediaryDeadlineDate,
          intermediaryDate: generalMeeting.intermediaryDeadlineDateStr,
          intermediaryAccessDate: generalMeeting.intermediaryAccessDate,
          intermediaryAccessDateStr: generalMeeting.intermediaryAccessDateStr,
          newEndDateTimeStamp: generalMeeting.newEndDateTime,
          issuerName: generalMeeting.issuerName,
          gmDate: generalMeeting.generalMeetingDateStr,
          allExtnReqRejected: generalMeeting.allExtReqsRejected,
          langCode: $scope.langCode,
          gmStatus: generalMeeting.status,
          breadCrumbDetails: [generalMeeting.meetingName],
        });
      }
      //navigation to attendance page
      function actionAttend(generalMeeting) {
        $state.go('attendanceReqMeetingInfo', {
          type: 'attend',
          gmId: generalMeeting.generalMeetingId,
          issuerId: generalMeeting.issuerId,
          issuerName: generalMeeting.issuerName,
          intermediaryDateTimeStamp: generalMeeting.intermediaryDeadlineDate,
          intermediaryDate: generalMeeting.intermediaryDeadlineDateStr,
          intermediaryAccessDate: generalMeeting.intermediaryAccessDate,
          intermediaryAccessDateStr: generalMeeting.intermediaryAccessDateStr,
          newEndDateTimeStamp: generalMeeting.newEndDateTime,
          gmDate: generalMeeting.generalMeetingDateStr,
          allExtnReqRejected: generalMeeting.allExtReqsRejected,
          langCode: $scope.langCode,
          gmStatus: generalMeeting.status,
          breadCrumbDetails: [generalMeeting.meetingName],
        });
      }
      //navigation to virtual participation page
      function actionAttendVirtually(generalMeeting) {
        $state.go('virtualParticipationView', {
          issuerId: generalMeeting.issuerId,
          issuerName: generalMeeting.issuerName,
          gmId: generalMeeting.generalMeetingId,
          generalMeetingDateMillis: generalMeeting.generalMeetingDateMiilis,
          intermediaryDeadlineDateMillis:
            generalMeeting.intermediaryDeadlineDateMiilis,
          intermediaryAccessDateMillis:
            generalMeeting.intermediaryAccessDateMiilis,
          timeZone: generalMeeting.timeZone,
          breadCrumbDetails: [generalMeeting.meetingName],
        });
      }

      // download Intermediary registration certificate and instruction
      function download(downloadUrl) {
        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            cbpDownloadBlob(response.file, response.fileName);
            $scope.isLoading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.isLoading = false;
          }
        );
      }

      // TO download intermediary accept instruction
      function actionDownloadAcceptedInstructions(generalMeeting) {
        $scope.isLoading = true;

        let downloadUrl =
          '/v2/gm/intermediary' +
          `/general-meetings/${generalMeeting.generalMeetingId}` +
          '/accepted-registrations';

        download(downloadUrl);
      }

      //Navigation to Shareholder email page
      function actionShareholderEmail(generalMeeting) {
        $state.go('shareholderEmail', {
          meetingId: generalMeeting.generalMeetingId,
          issuerName: generalMeeting.issuerName,
          gmDateTime: generalMeeting.generalMeetingDateStr,
          gmDateTimeStamp: generalMeeting.generalMeetingDate,
          meetingType: generalMeeting.meetingType,
          breadCrumbDetails: [generalMeeting.meetingName],
        });
      }
      //Navigation to create extension page
      function actionCreateExtensionRequest(generalMeeting) {
        $scope.extReqRejected = true;
        $state.go('createIntermediaryExtensionRequest', {
          intermediaryDeadlineDateStr:
            generalMeeting.intermediaryDeadlineDateStr,
          gmName: generalMeeting.meetingName,
          allExtnReqRejected: generalMeeting.allExtReqsRejected,
          gmDateTime: generalMeeting.generalMeetingDateStr,
          generalMeetingId: generalMeeting.generalMeetingId,
          extReqRejected: $scope.extReqRejected,
          issuerId: generalMeeting.issuerId,
          userType: $scope.userType,
          gmStatus: generalMeeting.status,
          breadCrumbDetails: [generalMeeting.meetingName],
          issuerName: generalMeeting.issuerName,
        });
      }

      // TO download intermediary certificate
      function actionDownloadCertificates(generalMeeting) {
        $scope.downloadIntCertificate = function () {
          $scope.isLoading = true;

          let downloadUrl =
            '/v2/gm/intermediary' +
            `/general-meetings/${generalMeeting.generalMeetingId}` +
            `/registration-certificates?language=${$sessionStorage.languageSelected}`;

          download(downloadUrl);
        };

        ModelWindowService.showModelWindow('downloadCertificateIntermediary');
      }

      // to navigate to the generalMeeting details page
      $scope.goToGeneralMeetingDetails = function (intermediaryGmData) {
        $state.go('generalMeetings', {
          selectedIndex: 1,
          meetingId: intermediaryGmData.generalMeetingId,
          issuerId: intermediaryGmData.issuerId,
          issuerName: intermediaryGmData.issuerName,
          gmDateTimeMilli: intermediaryGmData.generalMeetingDate,
          gmDateTime: intermediaryGmData.generalMeetingDateStr,
          status: intermediaryGmData.status,
          intermediaryDateTimeStamp:
            intermediaryGmData.intermediaryDeadlineDate,
          intermediaryDate: intermediaryGmData.intermediaryDeadlineDateStr,
          extReqRejected: intermediaryGmData.extReqRejected,
          sendRegViEuroclear: intermediaryGmData.sendRegViEuroclear,
          newEndDateTime: intermediaryGmData.newEndDateTime,
          allExtnReqRejected: intermediaryGmData.allExtReqsRejected,
          breadCrumbDetails: [intermediaryGmData.meetingName],
        });
      };

      init();
    },
  ]);
