/**
 * @ngdoc controller
 * @name userMaintenanceModule.AddIntermediaryProxyController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with linking proxy intermediary.
 * @requires userService and fileUploadSvc are the service which are being used
 *           to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $filter for date conversion and translate message from locale file
 */

angular
  .module('userMaintenanceModule')
  .controller('AddIntermediaryProxyController', [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$timeout',
    '$filter',
    'userGroupService',
    'fileUploadSvc',
    '$uibModal',
    function (
      $scope,
      $log,
      $state,
      $stateParams,
      $timeout,
      $filter,
      userGroupService,
      fileUploadSvc,
      $uibModal
    ) {
      $scope.modalBody = $filter('translate')('confirm_proxyLinking');
      $scope.modalLeftButton = $filter('translate')('label_confirm');
      $scope.modalRightButton = $filter('translate')('label_cancel');
      var readFile = this;
      readFile.documents = [
        {
          fileVo: [
            {
              file: '',
              fileName: '',
            },
          ],
        },
      ];
      $scope.proxyId = $stateParams.proxyId;
      // Below is the service call to interact with REST and get
      // the list of intermediary groups. // SFCBP-3025 changes
      userGroupService
        .getIntermediaryGroupList('GM', null)
        .query()
        .$promise.then(
          function (data) {
            $log.debug('success intermediary view');
            $scope.intermediaryNames = data;
          },
          function (error) {
            $log.debug(error);
          }
        );

      /* date picker */
      $scope.inlineOptions = {
        customClass: getDayClass,
      };
      $scope.open1 = function () {
        $scope.popup1.opened = true;
      };
      $scope.open2 = function () {
        $scope.popup2.opened = true;
      };
      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };
      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.popup1 = {
        opened: false,
      };
      $scope.popup2 = {
        opened: false,
      };
      function getDayClass(data) {
        var date = data.date,
          mode = data.mode;
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(
              0,
              0,
              0,
              0
            );
            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }
        return '';
      }
      /* date picker */

      /* time picker */
      $scope.example1 = {
        value: new Date(1970, 0, 1, 14, 57, 0),
      };
      $scope.example2 = {
        value: new Date(1970, 0, 1, 14, 57, 0),
      };
      /* time picker */
      // Below is the function call from HTML on change of
      // option in intermediary list drop down
      $scope.getIntermediaryId = function (intermediaryName) {
        $scope.intermediaryId = intermediaryName;
      };
      // Below is the function call from HTML while linking
      // intermediary to proxy group
      $scope.addIntermediaryProxyLink = function () {
        if (
          !$scope.processVotingInstruction ||
          $scope.processVotingInstruction === undefined
        ) {
          $scope.processVotingInstruction = false;
          $scope.processVotingInstructionValue = 'N';
        } else {
          $scope.processVotingInstruction = true;
          $scope.processVotingInstructionValue = 'Y';
        }

        var startDate = $filter('date')(
          $scope.startDateModified,
          'yyyy-MM-dd HH:mm:ss'
        );
        // set till end of the day to include the day that was selected itself
        $scope.endDateModified.setHours(23);
        $scope.endDateModified.setMinutes(59);
        $scope.endDateModified.setSeconds(59);

        var endDate = $filter('date')(
          $scope.endDateModified,
          'yyyy-MM-dd HH:mm:ss'
        );

        var documentFormData = new FormData();
        documentFormData.append(
          'intermediaryId',
          $scope.intermediaryName.intermediaryName.intermediaryId
        );
        documentFormData.append('proxyId', $scope.proxyId);
        documentFormData.append(
          'intermediaryName',
          $scope.intermediaryName.intermediaryName.intermediaryName
        );
        documentFormData.append(
          'processVotingInstruction',
          $scope.processVotingInstructionValue
        );
        if ($scope.poaFormFile) {
          documentFormData.append('agreementForm', $scope.poaFormFile);
          $log.debug('file', $scope.poaFormFile);
          documentFormData.append('agreementFormName', $scope.poaFormFile.name);
        } else {
          $scope.error = true;
          $scope.errorMsg = $filter('translate')('uploadFile_error');
          return;
        }

        documentFormData.append('effectiveStartDate', startDate);
        documentFormData.append('effectiveEndDate', endDate);
        /* upload file starts here */
        $log.debug('Uploading file with FormData');
        var file = $scope.poaFormFile;
        var uploadUrl = 'v1/intermediaries/link';
        /* Call the upload directive to upload file and FormData */
        fileUploadSvc.uploadFileToUrl(file, uploadUrl, documentFormData).then(
          function (data) {
            $scope.message = data.data.message;
            if (
              data.data.message ===
              'Intermediary linked with proxy successfully'
            ) {
              $scope.timedout = $uibModal.open({
                templateUrl: 'success-dialog.html',
              });
              $timeout(function () {
                $scope.timedout.close();
                $scope.timedout = null;
                $state.go('userMaintenance.linkedIntermediaries', {
                  groupId: $scope.proxyId,
                  breadCrumbDetails: $stateParams.breadCrumbDetails,
                });
              }, 2000);
            } else if (data.data.errors.length > 0) {
              $scope.message = data.data.errors[0].errorMessage;
            }
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
    },
  ]);
