/**
 * @ngdoc controller
 * @name userMaintenanceModule.EditUserController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with edit user details.
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 */

angular
  .module('userMaintenanceDwtModule')
  .controller('EditAgentDwtController', [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$sessionStorage',
    '$timeout',
    'userService',
    'DwtUser',
    function (
      $scope,
      $log,
      $state,
      $stateParams,
      $sessionStorage,
      $timeout,
      userService,
      DwtUser
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      var userId = $stateParams.userId;
      $scope.prodType = $sessionStorage.productType;
      $scope.user = 'agent';
      // Below is the service call to interact with REST and get the
      // user details
      userService
        .getViewUser(userId, $stateParams.userType, '1', 'DWT')
        .query()
        .$promise.then(
          function (data) {
            $log.debug('success user view');
            $scope.editUserDetails = new DwtUser(
              data,
              $stateParams.userType,
              $stateParams.groupId,
              $sessionStorage.userId,
              $scope.prodType
            );
          },
          function (error) {
            $log.debug(error);
          }
        );

      $scope.successCallback = function (response) {
        if (200 === response.httpStatusCode) {
          $scope.editUserSuccess = true;
          $scope.editUserSuccessMsg = response.message;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
            $state.go('userMaintenanceForDWT.agentUserListDwt', {
              groupId: '1',
            });
          }, 2000);
        } else {
          $scope.editUserSuccess = false;
          $scope.editUserErrorMsg = response.message;
        }
      };
      $scope.errorCallback = function (error) {
        $scope.editUserSuccess = false;
        $scope.editUserErrorMsg = error.data.errors[0].errorMessage;
      };
    },
  ]);
