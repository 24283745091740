/**
 * @ngDoc controller
 * @name generalMeetingMainModule.GeneralDetailsController
 * @description This controller is used general meetings home page
 * @requires generalMeetingService,voteCollectorService are the services which are being used to interact with REST
 * @requires GeneralMeetingProgressService is used for progress chart
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for fetching logged in user details from session storage
 * @requires $state for state routing
 * @requires $filter to filter general meetings to translate messages which are declared in locale files
 * @requires PaginationSvc for pagination
 */
angular
  .module('generalMeetingMainModule')
  .controller('GeneralMeetingHomeController', [
    '$scope',
    '$sessionStorage',
    '$filter',
    '$log',
    '$state',
    'PaginationSvc',
    'generalMeetingService',
    'voteCollectorService',
    function (
      $scope,
      $sessionStorage,
      $filter,
      $log,
      $state,
      PaginationSvc,
      generalMeetingService,
      voteCollectorService,
      generalMeetingData
    ) {
      $scope.userType = $sessionStorage.usertype;
      $scope.responseMessage = '';
      if ($scope.userType === 'VC') {
        $scope.genMeetingStatusButtons = ['BI', 'AI', 'C'];
        $scope.generalMeetingHome = [];
        $scope.generalMeetingsCompleted = [];
        $scope.generalMeetingsAfterIntermediary = [];
        $scope.generalMeetingsBeforeIntermediary = [];
      } else if ($scope.userType === 'IS') {
        $scope.genMeetingStatusButtons = ['P', 'T', 'L', 'M', 'C'];
      } else {
        $scope.genMeetingStatusButtons = ['D', 'P', 'T', 'L', 'M', 'C'];
      }

      $scope.agentName = $sessionStorage.userName;
      $scope.userId = $sessionStorage.userId;
      $scope.userName = $sessionStorage.userName;
      $scope.loading = true;

      $scope.generalMeetingView = {};
      $scope.generalMeeting = '';
      $scope.generalMeetingHome = '';
      $scope.generalMeetingHomeList = '';
      $scope.selectedButtonIndex = 0;
      if ($scope.userType === 'IN') {
        $scope.selectedButtonIndex = 3;
      }
      $scope.agentUserList = '';
      $scope.issuerDropDown = '';
      $scope.intermediaryDropDown = '';
      $scope.voteCollectorList = [];
      $scope.issuerId = '';
      $scope.intermediaryId = '';

      $scope.filteredResults = true;
      $scope.query = {};
      $scope.queryBy = '$';
      // Testing purpose

      $scope.generalMeetingData = generalMeetingData;
      // end
      //Below function is to get general meeting list available for that particular user in
      //vote collector Workstation
      $scope.getVCGeneralMeetings = function () {
        generalMeetingService
          .getGeneralMeetingsHome()
          .query()
          .$promise.then(
            function (data) {
              $scope.loading = false;
              $scope.generalMeetingHome = data;
              $scope.genMeetCount = [];
              angular.forEach(data, function (value, key) {
                if (value.status === 'M') {
                  $scope.generalMeetingsCompleted.push(data[key]);
                } else if (value.status === 'AI') {
                  $scope.generalMeetingsAfterIntermediary.push(data[key]);
                } else if (value.status === 'BI') {
                  $scope.generalMeetingsBeforeIntermediary.push(data[key]);
                }
              });
              $scope.tableData = $scope.generalMeetingsBeforeIntermediary;
              $scope.genMeetCount.push(
                '(' + $scope.generalMeetingsBeforeIntermediary.length + ')'
              );
              $scope.genMeetCount.push(
                '(' + $scope.generalMeetingsAfterIntermediary.length + ')'
              );
              $scope.genMeetCount.push(
                '(' + $scope.generalMeetingsCompleted.length + ')'
              );
            },
            function (error) {
              $log.debug(error);
            }
          );
      };
      //Below function is for pagination and this function calls on clicking of each function
      $scope.setPage = function (page) {
        $scope.pager = [];
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }

        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);

        // get current page of items
        $scope.tableData = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
      };
      //Below function is for searching general meeting based on the issuer name
      $scope.onIssuerSearchChange = function (val) {
        $scope.dummyItems = $filter('filter')($scope.generalMeetingHome, {
          issuerName: val,
        });
        $scope.pager = {};
        $scope.setPage(1);
      };
      //Below function is for pagination of agent home page
      $scope.setPageAgentHome = function (page) {
        $scope.pagerAgentHome = [];
        if (page < 1 || page > $scope.pagerAgentHome.totalPages) {
          return;
        }

        // get pager object from service
        $scope.pagerAgentHome = PaginationSvc.GetPager(
          $scope.dummyItemsAgentHome.length,
          page
        );

        // get current page of items
        $scope.tableData = $scope.dummyItemsAgentHome.slice(
          $scope.pagerAgentHome.startIndex,
          $scope.pagerAgentHome.endIndex + 1
        );
      };
      //The below function is to fetch general meeting list.
      $scope.getGeneralMeetingsHome = function () {
        $log.debug('GeneralMeetingsHome');
        $scope.searchViewPage = false;
        $scope.sortType = 'issuer';
        $scope.sortReverse = false;
        generalMeetingService
          .getGeneralMeetingsHome()
          .query()
          .$promise.then(
            function (data) {
              $scope.generalMeetingHomeList = data;
              var index = $scope.selectedButtonIndex;
              $scope.generalMeetingHome = $filter('filter')(
                $scope.generalMeetingHomeList,
                {
                  issuerName: $scope.issuerName,
                  status: $scope.genMeetingStatusButtons[index],
                },
                $scope.startsWith
              );
              $scope.dummyItems = $scope.generalMeetingHome;
              $scope.regenerateCount($scope.issuerName);
              $scope.modalBody = $filter('translate')('areSureDelete_modal');
              $scope.modalLeftButton = $filter('translate')('label_confirm');
              $scope.modalRightButton = $filter('translate')('label_cancel');

              $scope.pager = {};
              $scope.setPage(1);

              $log.debug('success');
              $scope.loading = false;
            },
            function (error) {
              $scope.loading = false;
              $log.debug(error);
            }
          );
      };
      //Below function is called to filter the general meeting based on the status
      $scope.buttonClicked = function ($index) {
        $log.debug($scope.issuerName);
        $scope.selectedButtonIndex = $index;
        if ($scope.userType === 'IN') {
          $scope.selectedButtonIndex = $index + 3;
        }
        if ($scope.userType === 'VC') {
          if ($index === 0) {
            $scope.generalMeetingHome =
              $scope.generalMeetingsBeforeIntermediary;
          } else if ($index === 1) {
            $scope.generalMeetingHome = $scope.generalMeetingsAfterIntermediary;
          } else if ($index === 2) {
            $scope.generalMeetingHome = $scope.generalMeetingsCompleted;
          }
          $scope.tableData = $scope.generalMeetingHome;
        } else {
          $scope.generalMeetingHome = $filter('filter')(
            $scope.generalMeetingHomeList,
            {
              issuerName: $scope.issuerName,
              status: $scope.genMeetingStatusButtons[$index],
            },
            $scope.startsWith
          );
          $scope.dummyItems = $scope.generalMeetingHome;
          $scope.regenerateCount($scope.issuerName);

          $scope.pager = {};
          $scope.setPage(1);
        }
      };
      //Below function is to get the count of general meetings for the particular status
      $scope.regenerateCount = function (val) {
        var array = {};
        array[$scope.selectedButtonIndex] = $scope.issuerName;
        $scope.genMeetCount = [];
        $scope.generalMeetingHome = $filter('filter')(
          $scope.generalMeetingHomeList,
          {
            issuerName: $scope.issuerName,
            status: $scope.genMeetingStatusButtons[$scope.selectedButtonIndex],
          }
        );
        $scope.dummyItems = $filter('filter')(
          $scope.generalMeetingHome,
          {
            issuerName: val,
          },
          $scope.startsWith
        );
        $scope.genMeetCount.push(
          '(' +
            $filter('filter')(
              $scope.generalMeetingHomeList,
              {
                issuerName: $scope.issuerName,
                status: $scope.genMeetingStatusButtons[0],
              },
              $scope.startsWith
            ).length +
            ')'
        );
        $scope.genMeetCount.push(
          '(' +
            $filter('filter')(
              $scope.generalMeetingHomeList,
              {
                issuerName: $scope.issuerName,
                status: $scope.genMeetingStatusButtons[1],
              },
              $scope.startsWith
            ).length +
            ')'
        );
        $scope.genMeetCount.push(
          '(' +
            $filter('filter')(
              $scope.generalMeetingHomeList,
              {
                issuerName: $scope.issuerName,
                status: $scope.genMeetingStatusButtons[2],
              },
              $scope.startsWith
            ).length +
            ')'
        );
        $scope.genMeetCount.push(
          '(' +
            $filter('filter')(
              $scope.generalMeetingHomeList,
              {
                issuerName: $scope.issuerName,
                status: $scope.genMeetingStatusButtons[3],
              },
              $scope.startsWith
            ).length +
            ')'
        );
      };

      $scope.startsWith = function (actual, expected) {
        var lowerStr = (actual + '').toLowerCase();
        return lowerStr.indexOf(expected.toLowerCase()) === 0;
      };
      //Below function is to search general meeting based on the issuer name entered
      $scope.onIssuerSearchChange = function (val) {
        $scope.issuerName = val;
        $scope.regenerateCount(val);
        $scope.pager = {};
        $scope.setPage(1);
      };

      $scope.search = function () {
        $scope.query = '';
        $scope.filteredResults = false;
      };

      $scope.showResults = function () {
        $scope.filteredResults = false;
      };
      if ($scope.userType === 'VC') {
        $scope.getVCGeneralMeetings();
      } else {
        $scope.getGeneralMeetingsHome();
      }
      //Below function is to navigate to edit general meeting page
      $scope.editGeneralMeeting = function (generalMeetingId) {
        $state.go('editGM', {
          selectedIndex: 1,
          meetingStatus: generalMeetingId,
          tabId: null,
        });
      };
      //Below function calls from HTML to approve/reject from vote collector WS for particular general meeting
      $scope.gmVCApproveReject = function (meetingId, decision) {
        voteCollectorService.gmVCApproveReject(
          meetingId,
          decision,
          'secureToken',
          function (response) {
            $log.debug(response);
            $scope.responseMessage = response;
            $scope.getVCGeneralMeetings();
          }
        );
      };
    },
  ]);
