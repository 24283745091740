/**
 * @ngdoc controller
 * @name userMaintenanceDwtModule.AddIntermediaryDwtController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with adding new intermediary user.
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 */

angular
  .module('userMaintenanceDwtModule')
  .controller('AddPeShareclassDwtController', [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$timeout',
    '$sessionStorage',
    'userService',
    'DwtShareclassUser',
    function (
      $scope,
      $log,
      $state,
      $stateParams,
      $timeout,
      $sessionStorage,
      userService,
      DwtShareclassUser
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;
      $scope.groupId = $stateParams.groupId;
      $scope.userId = $sessionStorage.userId;
      $log.debug('groupId state param', $scope.groupId);
      $scope.callOnLoad = function () {
        $scope.addUserDetails = new DwtShareclassUser(
          undefined,
          $scope.groupId,
          $scope.userId
        );
      };
      $scope.successCallback = function (response) {
        $scope.failure = false;
        $scope.message = response.data.message;
        $('#successMsg').modal('show');
        $timeout(function () {
          $('#successMsg').modal('hide');
          $('.modal-backdrop').remove();
          $state.go('userMaintenanceForDWT.peShareclassListDwt', {
            groupId: $scope.groupId,
          });
        }, 2000);
      };
      $scope.errorCallback = function (error) {
        $scope.errorMessage = error.data.message;
        if ('CBP_MSG_271' === error.data.code) {
          $scope.failure = false;
          $('#confirmationModal').modal('show');
        } else {
          $scope.failure = true;
        }
      };

      $scope.callOnLoad();
    },
  ]);
