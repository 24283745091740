import './header.scss';

/**
 * @ngdoc controller
 * @name commonModule.headerCtrl
 * @description This controller is the main controller for application. This
 *              mainly deals with login, logout functionalities and switching
 *              between the menus
 * @requires AuthService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage to fetch the logged in user details from session
 *           storage
 * @requires $state for state routing
 */
('use strict');

angular.module('commonModule').controller('headerCtrl', [
  '$scope',
  '$state',
  '$sessionStorage',
  'AuthService',
  '$stateParams',
  '$log',
  'GeneralMeetingDataLanguageService',
  'LanguagePreferenceService',
  'userGroupService',
  'Idle',
  'Keepalive',
  '$uibModal',
  '$timeout',
  '$filter',
  'UserNavigationHistory',
  'menuService',
  '$translate',
  '$rootScope',
  '$transitions',
  'User',
  function (
    $scope,
    $state,
    $sessionStorage,
    AuthService,
    $stateParams,
    $log,
    GeneralMeetingDataLanguageService,
    LanguagePreferenceService,
    userGroupService,
    Idle,
    Keepalive,
    $uibModal,
    $timeout,
    $filter,
    UserNavigationHistory,
    menuService,
    $translate,
    $rootScope,
    $transitions,
    User
  ) {
    var deregisterNListener1 = null;
    var deregisterNListener2 = null;
    var deregisterNListener3 = null;
    $scope.timeoutConfig = {};
    $scope.groupName = $sessionStorage.groupName;
    $scope.jobData = [];
    $scope.jobStatus = {};
    $scope.showProgressBar = false;
    $scope.showFeedBackTaskBar = false;
    $scope.jobserviceurl = '';
    $scope.selectedIndex = $stateParams.selectedIndex
      ? $stateParams.selectedIndex
      : 0;
    if ($sessionStorage.workstationData) {
      $scope.selectedLang = $sessionStorage.workstationData.selectedLang;
    }
    $scope.userType = $sessionStorage.usertype;
    if (
      $sessionStorage.usertype !== '' &&
      $sessionStorage.usertype !== 'SH' &&
      !$sessionStorage.thankYouPage
    ) {
      $sessionStorage.isPublicPage = false;
    } else if ($sessionStorage.thankYouPage) {
      $sessionStorage.isPublicPage = true;
      $sessionStorage.userId = '';
    }
    $scope.isPublicPage = $sessionStorage.isPublicPage;
    $scope.showLink = false;
    // Below condition is to set the language to English if it
    // is not selected.
    if (!$scope.selectedLang) {
      $scope.selectedLang = 'EN';
      $sessionStorage.selectedLang = $scope.selectedLang;
    }

    $scope.workstationLabelMap = {
      AG: 'Agent workstation',
      IN: 'Intermediary workstation',
      VC: 'Vote collector workstation',
    };

    /*
     * session timeout related warnings and redirections start
     * here
     */
    function refresh() {
      AuthService.sessionReload()
        .query()
        .$promise.then(
          function (data) {
            $log.debug('success', data);
          },
          function (error) {
            $log.debug(error);
          }
        );
    }

    function closeModals() {
      if ($scope.warning) {
        $scope.warning.close();
        $scope.warning = null;
      }
    }

    function start() {
      $log.debug('Idle Watch started');
      closeModals();
      Idle.watch();
      $scope.started = true;
    }

    function stop() {
      closeModals();
      Idle.unwatch();
      $scope.started = false;
      $log.debug('Idle Watch ended');
    }

    $scope.$on('IdleStart', function () {
      $log.debug('Idle Event started');
      closeModals();
      if (!$sessionStorage.isPublicPage) {
        $scope.warning = $uibModal.open({
          templateUrl: 'warning-dialog.html',
        });
      }
    });

    $scope.$on('IdleEnd', function () {
      $log.debug('Idle Event ended');
      closeModals();
      refresh();
    });

    $scope.$on('IdleTimeout', function () {
      $log.debug('IdleTimeout');
      closeModals();
      if (!$sessionStorage.isPublicPage) {
        $scope.timedout = $('#globalTimeoutError').modal('show');
        $scope.logout($scope.workstationData.menuData.logOut);
      }
    });

    $scope.$on('Keepalive', function () {
      refresh();
    });

    $scope.mouseenter = function () {
      closeModals();
      refresh();
    };
    /*
     * session timeout related warnings and redirections end
     * here
     */
    $scope.$on('onMinimize', function (event, jobData) {
      var filterData = $filter('filter')($scope.jobData, {
        jobExecutionId: jobData.jobExecutionId,
      });
      $scope.showProgressBar = false;
      if (filterData && filterData.length === 0) {
        jobData.minimized = true;
        $scope.jobData.push(jobData);
      } else {
        var index = -1;
        for (var k = 0; k < $scope.jobData.length; k++) {
          if ($scope.jobData[k].jobExecutionId === jobData.jobExecutionId) {
            index = k;
          }
        }
        $scope.jobData[index] = jobData;
        $scope.jobData[index].minimized = true;
      }
    });
    $scope.$on('onProgressBarClose', function (
      event,
      jobData,
      inviteSharePlanSuccess
    ) {
      var index = -1;
      for (var i = 0; i < $scope.jobData.length; i++) {
        if ($scope.jobData[i].jobExecutionId === jobData.jobExecutionId) {
          index = i;
        }
      }
      $scope.jobData.splice(index, 1);
      $scope.showProgressBar = false;
      if (inviteSharePlanSuccess) {
        var history = UserNavigationHistory.getPreviousState();
        if (history) {
          $state.go(history.fromState.name, history.fromStateParams, {
            location: true,
          });
        }
      }
    });

    $scope.$on('onMaximizeFeedback', function (event, flag) {
      $scope.showFeedBackTaskBar = flag;
    });

    $scope.onMaximize = function (jobData) {
      jobData.minimized = false;
      $scope.showProgressBar = true;
      var fileNameStr = jobData.fileName;
      var fileNameExt = fileNameStr.replace('.', '&middot');
      $scope.jobserviceurl =
        'v1/jobs/' + jobData.jobExecutionId + '/' + fileNameExt;
      $scope.jobStatus = jobData;
    };

    $scope.onMaximizeFeedback = function () {
      $scope.showFeedBackTaskBar = false;
      $scope.$root.$broadcast('onMinimizeFeedback', true);
    };

    $scope.onGlobalErrorClose = function () {
      $rootScope.globalErrorOpened = false;
    };

    // Below function executes while loading the Menu to scope
    $scope.$on(
      'onMenuLoad',
      function (event, menudata) {
        $scope.workstationData.menuData = menudata;
        $scope.workstationData.workstationId = $sessionStorage.usertype;
        $sessionStorage.workstationData = $scope.workstationData;
        $scope.$storage = $sessionStorage;
      },
      true
    );

    // Below function executes while user login to the application
    $scope.$on(
      'onLogin',
      function (event, menudata) {
        // for session timeout
        start();
        $scope.workstationData.menuData = menudata;
        $scope.workstationData.workstationId = $sessionStorage.usertype;
        if ($sessionStorage.usertype === 'IS') {
          // Below is the function used to fetch issuer logo
          userGroupService.getIssuerGroupLogo($sessionStorage.groupId).then(
            function (response) {
              $sessionStorage.issuerGroupDetails = response.data.logoBaseStr;
              $scope.workstationData.issuerLogo =
                $sessionStorage.issuerGroupDetails;
              $scope.workstationData.userName = $sessionStorage.userName;
            },
            function (error) {
              $log.debug(error);
            }
          );
        } else {
          $scope.workstationData.loggedUser = $sessionStorage.emailAddress;
          $scope.workstationData.taxAuthorityUser =
            $sessionStorage.taxAuthorityUser;
        }
        $sessionStorage.workstationData = $scope.workstationData;
        $scope.$storage = $sessionStorage;
      },
      true
    );

    //listen for page refresh event to restore menu
    $scope.$on(
      'onRefresh',
      async function (event, workstationData) {
        start();
        if (workstationData != null) {
          $scope.workstationData = workstationData;

          $scope.workstationData.menuData = menuService.getMenuItemsByWorkStationId(
            $sessionStorage.usertype,
            $sessionStorage.selectedLang,
            $sessionStorage.gmId,
            $sessionStorage.issuerId,
            $sessionStorage.productTypes
          );
          $scope.workstationData.selectedLang = workstationData.selectedLang;
          $scope.workstationData.workstationId = $sessionStorage.usertype;
          $translate.use($sessionStorage.selectedLang);
        }
        //history can be maintained without having workstation data as well
        UserNavigationHistory.setHistoryStack(
          $sessionStorage.historyStack || []
        );
        UserNavigationHistory.setHistoryItems(
          $sessionStorage.historyItems || {}
        );
      },
      true
    );

    if (
      $sessionStorage.workstationData === undefined ||
      $sessionStorage.workstationData.menuData === undefined ||
      $sessionStorage.workstationData.menuData === null
    ) {
      $sessionStorage.emailAddress = '';
      $sessionStorage.usertype = '';
      $scope.userType = '';
    }

    $scope.workstationData = {
      selectedTab: $scope.selectedIndex,
      menuData: $sessionStorage.menuData,
      selectedLang: $scope.selectedLang,
      workstationId: $scope.userType,
      loggedUser: $sessionStorage.emailAddress,
      showLink: $scope.showLink,
      taxAuthorityUser: $sessionStorage.taxAuthorityUser,
    };

    // Below function executes while switching between the
    // header menu
    $scope.$watch(
      '$storage.workstationData',
      function (newVal, oldVal) {
        if (AuthService.publicURL() && !AuthService.userLoggedIn()) {
          $scope.isPublicPage = true;
          $sessionStorage.userId = '';
        }
        if (
          !angular.equals(newVal, oldVal) &&
          $scope.isPublicPage &&
          !$sessionStorage.userId
        ) {
          if (newVal) {
            $scope.workstationData = newVal;
          } else {
            $scope.workstationData = {};
            $scope.workstationData.selectedTab = 0;
            $scope.workstationData.selectedLang = 'EN';
            $scope.workstationData.showLink = true;
          }
        } else if (!newVal && $scope.isPublicPage && !$sessionStorage.userId) {
          $scope.workstationData = {};
          $scope.workstationData.selectedTab = 0;
          $scope.workstationData.selectedLang = 'EN';
          $scope.workstationData.showLink = true;
        } else {
          if ($sessionStorage.userId) {
            $scope.workstationData.showLink = false;
          } else if (!$scope.isPublicPage && !$sessionStorage.userId) {
            $scope.workstationData = {};
            $scope.workstationData.selectedTab = 0;
            $scope.workstationData.selectedLang = 'EN';
            $scope.workstationData.showLink = false;
          }
        }
      },
      true
    );
    $transitions.onSuccess(
      {
        to: function (state, transition) {
          // If it is a anchor # navigation then don't go continue the transition call back,
          // This is to replicate angular-ui-router 0.2.x behaviour
          return transition.to().url !== transition.from().url;
        },
      },
      function (transition) {
        let toState = transition.to();
        let fromState = transition.from();
        let toParams = transition.params('to');
        let fromParams = transition.params('from');

        $sessionStorage.toState = toState;
        $sessionStorage.toParams = toParams;
        // changes related to back button
        if ($rootScope.refreshtriggered) {
          $sessionStorage.fromState = toState;
          $sessionStorage.fromParms = toParams;
          $rootScope.refreshtriggered = false;
        } else {
          if (
            ('dwtDetails.dwtEventClaims' === fromState.name ||
              'dwtDetails.dwtToBeAuthorized' === fromState.name) &&
            !fromParams.eventId
          ) {
            $sessionStorage.dwtEvtFlagForBack = true;
          } else {
            $sessionStorage.fromState = fromState;
            $sessionStorage.fromParms = fromParams;
            $sessionStorage.dwtEvtFlagForBack = false;
          }
        }
        $scope.toState = toState.url;
        $scope.fromState = fromState.url;
        $sessionStorage.historyStack = UserNavigationHistory.getHistoryStack();
        $sessionStorage.historyItems = UserNavigationHistory.getHistoryItems();
        if (undefined !== $sessionStorage.toState) {
          if (
            ('/agent' === $sessionStorage.toState.url ||
              '/issuer' === $sessionStorage.toState.url ||
              '/intermediary' === $sessionStorage.toState.url ||
              '/shareholderlogin' === $sessionStorage.toState.url ||
              '/shareholder' === $sessionStorage.toState.url ||
              '/shareplan' === $sessionStorage.toState.url ||
              '/votecollector' === $sessionStorage.toState.url) &&
            '/shGeneralMeetings' !== fromState.url &&
            '/registerSH' !== fromState.url
          ) {
            $scope.showFeedBackTaskBar = false;
            var lang = $scope.workstationData.selectedLang;
            $scope.workstationData = {};
            $scope.workstationData.selectedTab = 0;
            $scope.workstationData.selectedLang = lang;
            if (
              '/shareholderlogin' === $sessionStorage.toState.url ||
              '/shareholder' === $sessionStorage.toState.url ||
              '/shareholderplan' === $sessionStorage.toState.url
            ) {
              $scope.workstationData.showLink = true;
              if (
                '/shareholder' === $sessionStorage.toState.url ||
                ('/shareholderlogin' === $sessionStorage.toState.url &&
                  '/shareholder' === fromState.url)
              ) {
                $scope.workstationData.menuData = menuService.getMenuItemsByWorkStationId(
                  'PB',
                  $scope.workstationData.selectedLang
                );
              }
            } else {
              $scope.workstationData.showLink = false;
            }
            $scope.jobData = [];
            stop();
            delete $sessionStorage.groupName;
            // to check availability of vote and attend object for redirection to the page post login
            if ($sessionStorage.voteObject) {
              var voteObject = $sessionStorage.voteObject;
            }
            if ($sessionStorage.attendanceRequestObject) {
              var attendanceRequestObject =
                $sessionStorage.attendanceRequestObject;
            }
            // default code
            if ($sessionStorage.isPublicPage) {
              var isPublicPage = $sessionStorage.isPublicPage;
            }
            $sessionStorage.$reset();
            if (
              isPublicPage ||
              $scope.workstationData.workstationId === 'SH' ||
              $sessionStorage.userType === 'SH'
            ) {
              $sessionStorage.selectedLang = lang;
            } else {
              $sessionStorage.selectedLang = 'EN';
            }
            if (isPublicPage) {
              $sessionStorage.isPublicPage = isPublicPage;
            }
            // set the object for redirection post login.
            if (voteObject) {
              $sessionStorage.voteObject = voteObject;
            }
            if (attendanceRequestObject) {
              $sessionStorage.attendanceRequestObject = attendanceRequestObject;
            }
          }
        }
      }
    );

    /**
     * Below function is for logout process and reset the
     * session storage and workstation related data
     * and navigates to the login page
     */
    $scope.logout = function (reloadUrl) {
      $log.debug('Logout');
      $scope.showFeedBackTaskBar = false;
      $scope.workstationData = {};
      $scope.workstationData.selectedTab = 0;
      $scope.workstationData.selectedLang = 'EN';
      $scope.jobData = [];
      stop();
      delete $sessionStorage.groupName;
      $sessionStorage.$reset();

      AuthService.logout().then(function (data) {
        $log.debug('Logout is Called');
        if (data.status === 200) {
          $state.go(
            reloadUrl.url,
            {},
            {
              reload: true,
            }
          );

          $log.debug('Logout Successful');
        } else {
          $log.debug(data.data.errorMessage);
        }
      });
    };
    /**
     * Function to handle the language toggle and load the
     * multiple-language data for shareholder WS.
     */
    $scope.$watch('workstationData.selectedLang', function (newVal, oldVal) {
      if (newVal !== oldVal) {
        GeneralMeetingDataLanguageService.setLanguage(newVal);
        LanguagePreferenceService.invoke(newVal);
        // Invoke language specific data switch in view GM screen of SH.
        $scope.$root.$broadcast('onLanguageChangeShareHolder', newVal);
        $scope.$root.$broadcast('onLanguageChangeVotingInstruction', newVal);
        $scope.$root.$broadcast('onLanguageMyvotes', newVal);
        $scope.$root.$broadcast('onLanguageMyaccounts', newVal);
      }
    });
    /**
     * Breadcrumblist to be displayed}
     */
    $scope.breadcrumbList = ['label_home'];

    /**
     * Registers a listener to add the value of breadcrumb
     */
    deregisterNListener1 = $scope.$on('$navigationChangeAdd', function (
      newNavigationItem
    ) {
      $scope.breadcrumbList.push(newNavigationItem);
    });

    /**
     * Registers a listener to remove the value from
     * breadcrumbList
     */
    deregisterNListener2 = $scope.$on('$navigationChangeRemove', function () {
      $scope.breadcrumbList.pop();
    });

    /**
     * Registers a listener to replace all the value of
     * breadcrumbList
     */
    deregisterNListener3 = $scope.$on('$navigationChangeAddList', function (
      navigationList
    ) {
      $scope.breadcrumbList = navigationList;
    });

    $scope.$on('$destroy', function () {
      if (deregisterNListener1) {
        deregisterNListener1();
      }
      if (deregisterNListener2) {
        deregisterNListener2();
      }
      if (deregisterNListener3) {
        deregisterNListener3();
      }
    });

    $scope.$watch('workstationData.menuData', function (newVal, oldVal) {
      if (undefined !== newVal && undefined === newVal.menus) {
        var lang = 'EN';
        if ($scope.workstationData.selectedLang) {
          lang = $scope.workstationData.selectedLang;
        }
        $scope.workstationData.menuData = menuService.getMenuItemsByWorkStationId(
          $sessionStorage.usertype,
          lang,
          $sessionStorage.gmId,
          $sessionStorage.issuerId,
          $sessionStorage.productTypes
        );
      } else if (
        undefined === newVal &&
        $scope.workstationData.showLink === true
      ) {
        $scope.workstationData.menuData = menuService.getMenuItemsByWorkStationId(
          $sessionStorage.usertype,
          lang,
          $sessionStorage.gmId,
          $sessionStorage.issuerId,
          $sessionStorage.productTypes
        );
      }
    });

    $scope.$watch('workstationData.selectedLang', function (newVal, oldVal) {
      if (newVal !== oldVal) {
        $rootScope.$broadcast('onLanguageSwitch');
      }
    });
  },
]);
