import formatLocationString from '@/lib/formatLocationString';

/**
 * @ngDoc controller
 * @name shareHolderModule.ViewVotingInstructionController
 * @description This controller is used to view the voting instruction.
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires userGroupService
 * @requires voteInstructionService
 */
angular
  .module('shareHolderModule')
  .controller('viewVotingReqIntermediaryController', [
    '$scope',
    '$filter',
    '$sessionStorage',
    '$stateParams',
    'GeneralMeetingDataLanguageService',
    'userGroupService',
    'fileDownloadSvc',
    'voteInstructionService',
    function (
      $scope,
      $filter,
      $sessionStorage,
      $stateParams,
      GeneralMeetingDataLanguageService,
      userGroupService,
      fileDownloadSvc,
      voteInstructionService
    ) {
      $scope.userType = $sessionStorage.usertype;
      $scope.intermediaryId = $stateParams.intermediaryId;
      $scope.createdBy = $stateParams.createdBy;
      // Below is the function used to fetch issuer logo
      userGroupService.getIssuerGroupLogo($stateParams.issuerId).then(
        function (response) {
          $scope.issuerLogoPresent = false;
          $scope.issuerGroupDetails = response.data;
          // Do not change the below condition. It is for
          // issuer logo display fix. REST response has ""
          if ($scope.issuerGroupDetails.logoBaseStr) {
            $scope.issuerLogoPresent = true;
          }
        },
        function (error) {
          $scope.issuerLogoError = error;
        }
      );
      $scope.radioVal = $stateParams.radioVal;
      $scope.dropdownVal = $stateParams.dropdownVal;
      if ($stateParams.dropdownVal && $stateParams.radioVal) {
        $sessionStorage.dropdownVal = $stateParams.dropdownVal;
        $sessionStorage.radioVal = $stateParams.radioVal;
      } else {
        $sessionStorage.dropdownVal = 'ALL';
        $sessionStorage.radioVal = 'voteIns';
      }
      // Below if condition is added to display hard coded proxy
      // text for telenet issuer
      $scope.showAllFor = true;
      if ($stateParams.issuerName) {
        $scope.issuerName = $stateParams.issuerName.toLowerCase();
        if ($scope.issuerName == 'shell plc') {
          $scope.showAllFor = false;
        }
      }
      // when language changes, following piece of code is
      // executed from header-controller.js
      $scope.languageCode =
        $sessionStorage.workstationData.selectedLang || 'EN';
      $scope.$on('onLanguageChangeVotingInstruction', function (event, lang) {
        $scope.languageCode = lang;
        $scope.onLoadVI();
      });
      // On load of page, the following function is called,
      // which makes service call to get shareclass details.
      $scope.onLoadVI = function () {
        voteInstructionService
          .getShareClassListWithVotes(
            $stateParams.meetingId,
            $stateParams.shareholderId,
            $scope.languageCode,
            $sessionStorage.usertype,
            $stateParams.instructionId,
            $stateParams.status
          )
          .query()
          .$promise.then(
            function (data) {
              // if chosen language does not have
              // data, then switch to English, else
              // fetch the existing details.
              if (data.length <= 0 && $scope.languageCode !== 'EN') {
                $scope.languageCode = 'EN';
                $scope.onLoadVI();
              } else {
                $scope.noOfSecurities = data[0].noOfSecurities;
                $scope.votingInstructionDetails = GeneralMeetingDataLanguageService.getLanguageSpecificData(
                  data,
                  $scope.languageCode
                );
                angular.forEach($scope.votingInstructionDetails, function (
                  item
                ) {
                  if (item.selectedShareClassId === item.shareclassIdWOSalt) {
                    $scope.votingInstDetails = item;
                    $scope.shareClassId = item.shareclassId;
                    angular.forEach(item.intermediaryVO, function (item2) {
                      if (
                        item.selectedIntermediaryId ===
                        item2.intermediaryIdWoSalt
                      ) {
                        $scope.intermediaryId = item2.intermediaryId;
                      }
                    });
                    angular.forEach(item.vcShareclassAssocVO, function (item3) {
                      if (item.selectedVCUserId === item3.vcUserIdWoSalt) {
                        $scope.selectedVC = item3;
                      }
                    });
                  }
                });
              }
              // SFCBP-2919 : not to call agenda service
              if ($scope.createdBy === 'SH') {
                $scope.getShareHolderDetailsVI();
              } else if ($scope.isAllowedToSeeAgenda()) {
                $scope.agenda();
              }
            },
            function (error) {
              $scope.error = error;
            }
          );
      };

      $scope.isAllowedToSeeAgenda = function () {
        return (
          $scope.createdBy === 'MI' ||
          $scope.createdBy === 'IA' ||
          $scope.createdBy === 'IB' ||
          $scope.createdBy === 'PI' ||
          $scope.createdBy === 'SP'
        );
      };

      $scope.getProxyTextParagraph2 = function () {
        if ($scope.issuerName === 'telenet group holding nv') {
          return $filter('translate')(
            'vi_shareholder_disclaimer8_with_right_of_substitution'
          );
        } else {
          return $filter('translate')('vi_shareholder_disclaimer8');
        }
      };

      // Below is the function call to fetch agenda items
      $scope.agenda = function () {
        voteInstructionService
          .getAgendaDetails(
            $stateParams.meetingId,
            $scope.languageCode,
            $stateParams.shareholderId,
            $scope.shareClassId,
            $scope.intermediaryId,
            $stateParams.userType
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.agendaCollection = data;
              // if chosen language does not have
              // data, then switch to English, else
              // fetch the existing details.
              if (data.length <= 0 && $scope.languageCode !== 'EN') {
                $scope.languageCode = 'EN';
                $scope.agenda();
              } else {
                angular.forEach($scope.agendaCollection, function (item) {
                  $scope.entireHolding = item.entireHolding;
                  $scope.registrationNo = item.registrationNo;
                  item.totalSplitVotes = item.noOfSecurities;
                  if (
                    item.splitVoteAllwdFrmInter === 'Y' &&
                    item.splitVote === 'Y'
                  ) {
                    item.splitVotes = true;
                    item.disableSplitVote = true;
                  } else {
                    item.splitVotes = false;
                    item.disableSplitVote = false;
                    item.disableRadio = true;
                    if (item.voteFor !== 0) {
                      item.selectedVote = 'for';
                    } else {
                      item.voteFor = 0;
                    }
                    if (item.voteAgainst !== 0) {
                      item.selectedVote = 'against';
                    } else {
                      item.voteAgainst = 0;
                    }
                    if (item.voteAbstain !== 0) {
                      item.selectedVote = 'abstained';
                    } else {
                      item.voteAbstain = 0;
                    }
                  }
                });
                angular.forEach($scope.agendaCollection, function (key2) {
                  if (key2.isVotable === 'Y') {
                    $scope.isVotableFlag = true;
                  }
                  return $scope.isVotableFlag;
                });
              }
              $scope.getShareHolderDetailsVI();
            },
            function (error) {
              $scope.error = error;
            }
          );
      };
      $scope.getShareHolderDetailsVI = function () {
        voteInstructionService
          .getShareHolderDetailsIntWS(
            $stateParams.shareholderId,
            $stateParams.instructionId,
            $stateParams.userType
          )
          .then(
            function (response) {
              $scope.attendanceReqDetails = response.data;
            },
            function (error) {}
          );
      };
      /* download BO File starts here */
      $scope.downloadBOFile = function () {
        var downloadUrl =
          '/v2/gm/intermediary/voting-instructions/' +
          $stateParams.instructionId +
          '/bo-document';
        var a = document.createElement('a');
        document.body.appendChild(a);
        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            let fileVO = response;
            if (window.navigator.msSaveOrOpenBlob) {
              a.onclick = function () {
                window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
              };
              a.click();
            } else {
              var fileURL = URL.createObjectURL(fileVO.file);
              a.href = fileURL;
              a.download = fileVO.fileName;
              a.click();
            }
            downloadUrl = '';
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      /* download BO File ends here */

      $scope.onLoadVI();

      $scope.formattedLocationString = () => {
        return formatLocationString([
          $scope.votingInstDetails?.locationOnTicket,
          $scope.votingInstDetails?.street,
          $scope.votingInstDetails?.postalCode,
          $scope.votingInstDetails?.city,
        ]);
      };
    },
  ]);
