import './share-holder-gm.scss';
import { getVirtualParticipationButtonLabel } from '@/lib/labels';
import { formatDateTime } from '@/lib/date';
import moment from 'moment-timezone';

/**
 * @ngdoc shareHolderGMController
 * @description This controller is used for shareholder module.
 * @requires shareHolderGMDetails contains details to display in shareholder
 *           screen
 * @requires $scope
 * @requires $state to route
 * @requires $sessionStorage to store/pass data in session
 * @requires $filter
 * @requires menuService,voteInstructionService,
 *           attendanceRequestService,ModelWindowService
 */

angular.module('shareHolderModule').controller('shareHolderGMController', [
  '$log',
  '$scope',
  '$sessionStorage',
  '$state',
  '$filter',
  '$stateParams',
  'menuService',
  'shareHolderService',
  'voteInstructionService',
  'attendanceRequestService',
  'ModelWindowService',
  'generalMeetingService',
  'issuerLogo',
  'GeneralMeetingDataLanguageService',
  'AuthService',
  function (
    $log,
    $scope,
    $sessionStorage,
    $state,
    $filter,
    $stateParams,
    menuService,
    shareHolderService,
    voteInstructionService,
    attendanceRequestService,
    ModelWindowService,
    generalMeetingService,
    issuerLogo,
    GeneralMeetingDataLanguageService,
    AuthService
  ) {
    $scope.getVirtualParticipationButtonLabel = getVirtualParticipationButtonLabel;
    $scope.upcomingGMList = '';
    $scope.upcomingGMLists = [[], [], []];
    $scope.gmDeadlineModelConfig = {};
    $scope.shareClassModelConfig = {};
    $scope.flag = $stateParams.flag;
    $scope.logosList = null;

    $scope.formatDateTime = formatDateTime;
    $scope.populateCategories = function () {
      $scope.upcomingGMLists = [[], [], []];
      angular.forEach($scope.upcomingGMList, function (item) {
        const shareholderAccessDateTime = moment(
          item.shareholderAccessDate
        ).valueOf();
        const shareholderDeadlineDateTime = moment(
          item.shareholderDeadlineDate
        ).valueOf();
        const currentTime = new Date().getTime();
        if (currentTime < shareholderAccessDateTime) {
          $scope.upcomingGMLists[2].push(item);
        } else if (currentTime > shareholderDeadlineDateTime) {
          $scope.upcomingGMLists[1].push(item);
        } else {
          $scope.upcomingGMLists[0].push(item);
        }
      });
    };

    $scope.checkForDeadlineDate = function () {
      if ($scope.upcomingGMList && $scope.upcomingGMList.length > 0) {
        angular.forEach($scope.upcomingGMList, function (item) {
          var todaysDate = new Date();
          if (item.shareholderAccessDate > todaysDate.getTime()) {
            item.yetToStart = true;
            item.notAbleToVoteAttend = false;
            item.showVoteAttend = false;
          } else if (
            item.shareholderAccessDate <= todaysDate.getTime() &&
            item.shareholderDeadlineDate >= todaysDate.getTime()
          ) {
            item.yetToStart = false;
            item.notAbleToVoteAttend = false;
            item.showVoteAttend = true;
          } else if (item.shareholderDeadlineDate < todaysDate.getTime()) {
            item.yetToStart = false;
            item.notAbleToVoteAttend = true;
            item.showVoteAttend = false;
          }
        });
      }
    };
    $sessionStorage.isPublicPage = false;
    if (AuthService.publicURL() && !AuthService.userLoggedIn()) {
      var selectedlang;
      if ($sessionStorage.workstationData) {
        selectedlang = $sessionStorage.workstationData.selectedLang
          ? $sessionStorage.workstationData.selectedLang
          : 'EN';
      } else {
        selectedlang = $sessionStorage.selectedLang;
      }
      var workstationId = 'PB';
      $sessionStorage.isPublicPage = true;
      $sessionStorage.userId = '';
      var menuData = menuService.getMenuItemsByWorkStationId(
        workstationId,
        selectedlang
      );
      $scope.$root.$broadcast('onMenuLoad', menuData);
      $scope.onLoadSpinner = true;
      if ($scope.flag === 1) {
        // flag =  1: means shareholder home/landing page (where a table of upcoming meetings is shown)

        shareHolderService
          .getUpcomingMeetings(10)
          .query()
          .$promise.then(
            function (data) {
              $scope.shareHolderGMDetailsMaster = data;
              $scope.shareHolderGMDetails = data;
              $scope.GMList = $scope.shareHolderGMDetails;
              $scope.upcomingGMList = $scope.shareHolderGMDetails;
              $scope.filterDataGMList = $scope.shareHolderGMDetails;
              $scope.checkForDeadlineDate();
              $scope.populateCategories();
              $scope.onLoadSpinner = false;
            },
            function (error) {
              $log.debug(error);
              $scope.onLoadSpinner = false;
            }
          );
      } else {
        shareHolderService
          .getUpcomingMeetings(100)
          .query()
          .$promise.then(
            function (data) {
              $scope.shareHolderGMDetailsMaster = data;
              $scope.shareHolderGMDetails = data;
              $scope.GMList = $scope.shareHolderGMDetails;
              $scope.upcomingGMList = $scope.shareHolderGMDetails;
              $scope.filterDataGMList = $scope.shareHolderGMDetails;
              $scope.checkForDeadlineDate();
              $scope.populateCategories();
              $scope.onLoadSpinner = false;
            },
            function (error) {
              $log.debug(error);
              $scope.onLoadSpinner = false;
            }
          );
      }
    } else if ($scope.flag === 1 && $sessionStorage.userId) {
      // flag =  1: means shareholder home/landing page (where a table of upcoming meetings is shown)
      $sessionStorage.isPublicPage = false;
      $scope.onLoadSpinner = true;
      shareHolderService
        .getUpcomingMeetings(10)
        .query()
        .$promise.then(
          function (data) {
            $scope.shareHolderGMDetailsMaster = data;
            $scope.shareHolderGMDetails = data;
            $scope.GMList = $scope.shareHolderGMDetails;
            $scope.upcomingGMList = $scope.shareHolderGMDetails;
            $scope.filterDataGMList = $scope.shareHolderGMDetails;
            $scope.checkForDeadlineDate();
            $scope.populateCategories();
            $scope.onLoadSpinner = false;
          },
          function (error) {
            $log.debug(error);
            $scope.onLoadSpinner = false;
          }
        );
    } else {
      // flag != 1: means shareholder meetings page where meetings are shown in a grid
      $sessionStorage.isPublicPage = false;
      $scope.onLoadSpinner = true;
      shareHolderService
        .getUpcomingMeetings(100)
        .query()
        .$promise.then(
          function (data) {
            $scope.shareHolderGMDetailsMaster = data;
            $scope.shareHolderGMDetails = data;
            $scope.GMList = $scope.shareHolderGMDetails;
            $scope.upcomingGMList = $scope.shareHolderGMDetails;
            $scope.filterDataGMList = $scope.shareHolderGMDetails;
            $scope.checkForDeadlineDate();
            $scope.populateCategories();
            $scope.onLoadSpinner = false;
          },
          function (error) {
            $log.debug(error);
            $scope.onLoadSpinner = false;
          }
        );
    }

    $scope.languageCode = $sessionStorage.workstationData.selectedLang;
    if ($sessionStorage.usertype === 'SP') {
      $scope.gmId = $sessionStorage.gmId;
      $scope.shareplanId = $sessionStorage.groupId;
      $scope.shareholderId = $sessionStorage.groupId;
    } else if (
      $sessionStorage.usertype === 'SH' &&
      !$sessionStorage.isPublicPage
    ) {
      $scope.shareplanId = 0;
      $scope.shareholderId = $sessionStorage.groupId;
    } else {
      $scope.shareplanId = null;
      $scope.shareholderId = 0;
    }

    $scope.getIssuerLogos = function (pageNo) {
      if (pageNo > 1) {
        var pageNumber = pageNo;
        shareHolderService.getIssuerLogos(pageNumber).then(
          function (data) {
            if (!$scope.logosList) {
              $scope.logosList = [];
            }
            $scope.logosList = [
              ...$scope.logosList,
              ...data.data.issuetLogoList,
            ];

            if (data.data.issuetLogoList.length === 40) {
              // There might still be more logo's
              pageNumber += 1;
              $scope.getIssuerLogos(pageNumber);
            } else {
              /*testImagesProd will have all the issuer logo's,
                              and the images are grouped for each slide based on screen size.*/
              $scope.testImagesProd = $scope.logosList;
              $scope.displayImageList = [];
              $scope.displayImageListXs = [];
              $scope.displayImageListSm = [];
              $scope.dispCount = 0;
              var imagePerSlide = 6;
              var imagePerSlideXs = 2;
              var imagePerSlideSm = 4;
              //below code is for grouping the images per slide.
              for (
                var i = 0;
                i < $scope.testImagesProd.length;
                i += imagePerSlide
              ) {
                $scope.displayImageList.push(
                  $scope.testImagesProd.slice(i, i + imagePerSlide)
                );
              }
              for (
                var j = 0;
                j < $scope.testImagesProd.length;
                j += imagePerSlideXs
              ) {
                $scope.displayImageListXs.push(
                  $scope.testImagesProd.slice(j, j + imagePerSlideXs)
                );
              }
              for (
                var k = 0;
                k < $scope.testImagesProd.length;
                k += imagePerSlideSm
              ) {
                $scope.displayImageListSm.push(
                  $scope.testImagesProd.slice(k, k + imagePerSlideSm)
                );
              }
            }
          },
          function (error) {
            return error;
          }
        );
      } else {
        $scope.showIssuerLogo = false;
      }
    };
    if ($stateParams.flag === 1 && issuerLogo !== null) {
      if (issuerLogo.data.count > 0) {
        $scope.logosList = issuerLogo.data.issuetLogoList;
        $scope.showIssuerLogo = true;
        $scope.getIssuerLogos(2);
      } else {
        $scope.showIssuerLogo = false;
      }
    } else {
      $scope.showIssuerLogo = false;
    }

    $scope.onShareHolderMeetingNameSearch = function (val) {
      if (val === '') {
        $scope.upcomingGMList = $scope.GMList;
      } else {
        $scope.upcomingGMList = $filter('filter')($scope.filterDataGMList, {
          issuerName: val,
        });
      }
      $scope.populateCategories();
    };

    //Attendance request
    $scope.attendanceRequestView = function (meeting, fromPage) {
      let issuerId = meeting.issuerId;
      let issuerName = meeting.issuerName;
      let gmId = meeting.meetingId;
      let meetingName = meeting.meetingName;
      let virtualMeetingFlag = meeting.virtualParticipationAllowed;

      // check if User is logged in or not
      if ($sessionStorage.userId) {
        attendanceRequestService
          .getAttendanceRequestView(
            gmId,
            $scope.languageCode,
            $scope.shareholderId,
            'SH'
          )
          .query()
          .$promise.then(
            function (data) {
              if (data && data.shareClassISINVO.length > 0) {
                if (fromPage === 'GM') {
                  $state.go('shGMAttendanceRequest', {
                    issuerId: issuerId,
                    issuerName: issuerName,
                    gmId: gmId,
                    sharePlanId: $scope.shareplanId,
                    selectedIndex: 1,
                    attendanceDataDeatils: data,
                    breadCrumbDetails: [data.meetingName],
                    virtualMeetingFlag: virtualMeetingFlag,
                  });
                } else if (fromPage === 'Home') {
                  $state.go('shHomeAttendanceRequest', {
                    issuerId: issuerId,
                    issuerName: issuerName,
                    gmId: gmId,
                    sharePlanId: $scope.shareplanId,
                    selectedIndex: 1,
                    attendanceDataDeatils: data,
                    breadCrumbDetails: [data.meetingName],
                    virtualMeetingFlag: virtualMeetingFlag,
                  });
                }
              } else if (
                data.shareClassISINVO.length <= 0 &&
                $scope.languageCode !== 'EN'
              ) {
                $scope.languageCode = 'EN';
                $scope.attendanceRequestView(
                  issuerId,
                  issuerName,
                  gmId,
                  meetingName,
                  fromPage
                );
              } else {
                showAttendDisabledPopup(issuerName);
              }
            },
            function (error) {
              return error;
            }
          );
      } else {
        $sessionStorage.attendanceRequestObject = {
          issuerName: issuerName,
          issuerId: issuerId,
          gmId: gmId,
          sharePlanId: $scope.shareplanId,
          meetingName: meetingName,
          buttonFlag: 'AR',
          virtualMeetingFlag: virtualMeetingFlag,
        };
        // redirect to Login Page
        $state.go('shareholderlogin', {});
      }
    };

    //Virtual participation request
    $scope.virtualAttendanceRequestView = function (meeting) {
      let issuerId = meeting.issuerId;
      let gmId = meeting.meetingId;

      // check if User is logged in or not
      if ($sessionStorage.userId) {
        shareHolderService
          .getShareholderShareClassList(gmId, $scope.languageCode)
          .query()
          .$promise.then(
            function (data) {
              if (data && data.shareClassISINVO.length > 0) {
                $state.go('vaRequestStep1', {
                  issuerId: issuerId,
                  gmId: gmId,
                  selectedIndex: 1,
                });
              } else if (
                data.shareClassISINVO.length <= 0 &&
                $scope.languageCode !== 'EN'
              ) {
                $scope.languageCode = 'EN';
                $scope.virtualAttendanceRequestView(issuerId, gmId);
              } else {
                $scope.shareClassModelConfig.modalHeader = $filter('translate')(
                  'label_votingInsCreatErr'
                );
                $scope.shareClassModelConfig.modalBody = $filter('translate')(
                  'label_attendNotPossible'
                );
                ModelWindowService.showModelWindow('shareClassAlertBox');
              }
            },
            function (error) {
              return error;
            }
          );
      } else {
        // unlike AR and VI, the VP button only shows when
        // virtual participation is allowed, so we can go directly
        // to the login page when the user is not logged in
        $state.go('shareholderlogin', {});
      }
    };

    // voting instruction
    $scope.votingInstructionView = function (meeting, fromPage) {
      let meetingId = meeting.meetingId;
      let issuerId = meeting.issuerId;
      let meetingName = meeting.meetingName;
      let issuerName = meeting.issuerName;
      let virtualMeetingEnable = meeting.virtualParticipationAllowed;

      // check if User is logged in or not
      if ($sessionStorage.userId) {
        voteInstructionService
          .getShareClassList(
            meetingId,
            $scope.shareholderId,
            $scope.languageCode,
            'SH'
          )
          .query()
          .$promise.then(
            function (data) {
              if (data.length > 0) {
                if (fromPage === 'GM') {
                  $state.go('shGMVotingInstructions', {
                    meetingId: meetingId,
                    issuerId: issuerId,
                    issuerName: issuerName,
                    shareHolderId: $scope.shareholderId,
                    selectedIndex: 1,
                    votingInstDetails: data,
                    virtualMeetingFlag: virtualMeetingEnable,
                    action: 'create'
                  });
                }
              } else if (data.length <= 0 && $scope.languageCode !== 'EN') {
                $scope.languageCode = 'EN';
                $scope.votingInstructionView(
                  meetingId,
                  issuerId,
                  meetingName,
                  fromPage,
                  issuerName,
                  virtualMeetingEnable
                );
              } else {
                $scope.shareClassModelConfig.modalHeader = $filter('translate')(
                  'label_votingInsCreatErr'
                );
                $scope.shareClassModelConfig.modalBody = $filter('translate')(
                  'label_voteNotPossible'
                );
                ModelWindowService.showModelWindow('shareClassAlertBox');
              }
            },
            function (error) {
              return error;
            }
          );
      } else {
        $sessionStorage.voteObject = {
          issuerName: issuerName,
          meetingId: meetingId,
          issuerId: issuerId,
          meetingName: meetingName,
          virtualMeetingFlag: virtualMeetingEnable,
        };
        $state.go('shareholderlogin', {});
      }
    };

    // when language changes
    $scope.$on('onLanguageChangeShareHolder', function (event, lang) {
      if (
        $sessionStorage.workstationData &&
        $sessionStorage.workstationData.selectedLang
      ) {
        $scope.languageCode = $sessionStorage.workstationData.selectedLang;
      } else {
        $scope.languageCode = lang;
      }
    });

    $scope.goMeetingDetails = function (meeting) {
      let showHideFlag =
        $scope.hasParticipationMethods(meeting) &&
        $scope.isAccessibleByShareholder(meeting);

      $state.go('shHomeGeneralMeetings', {
        selectedIndex: 0,
        meetingId: meeting.meetingId,
        flag: 1,
        showHideFlag: showHideFlag,
      });
    };

    $scope.isShareholderAccessInTheFuture = function (meeting) {
      let shAccessDate = new Date(meeting.shareholderAccessDate);
      let now = new Date();
      return shAccessDate > now;
    };

    $scope.isShareholderDeadlinePassed = function (meeting) {
      let shDeadlineDate = new Date(meeting.shareholderDeadlineDate);
      let now = new Date();
      return shDeadlineDate <= now;
    };

    $scope.isAccessibleByShareholder = function (meeting) {
      let shAccessDate = new Date(meeting.shareholderAccessDate);
      let shDeadlineDate = new Date(meeting.shareholderDeadlineDate);
      let now = new Date();
      return shAccessDate < now && shDeadlineDate >= now;
    };

    $scope.hasParticipationMethods = function (meeting) {
      return (
        meeting.virtualParticipationAllowed == true ||
        meeting.attendanceRequestAllowed == true ||
        meeting.votingInstructionAllowed == true
      );
    };

    $scope.attendanceRequestAllowed = function (meeting) {
      return (
        $scope.isAccessibleByShareholder(meeting) &&
        meeting.attendanceRequestAllowed
      );
    };

    $scope.votingInstructionAllowed = function (meeting) {
      return (
        $scope.isAccessibleByShareholder(meeting) &&
        meeting.votingInstructionAllowed
      );
    };

    $scope.virtualParticipationAllowed = function (meeting) {
      return (
        $scope.isAccessibleByShareholder(meeting) &&
        meeting.virtualParticipationAllowed
      );
    };

    /**
     * Angular-translate escapes all htmlentities, so if we want to
     * render html in the popup modal, we need to decode them again
     *
     * See {@link @/lib/htmlDecode.js}
     */
    function showAttendDisabledPopup(issuerName) {
      $scope.shareClassModelConfig.modalHeader = $filter('translate')(
        'label_modalAttReqCreatErr'
      );

      $scope.shareClassModelConfig.modalBody =
        $filter('translate')('label_attendNotEnabled1').toString() +
        ' ' +
        issuerName +
        '. ' +
        $filter('translate')('label_attendNotEnabled2').toString() +
        '.';
      ModelWindowService.showModelWindow('shareClassAlertBox');
    }
  },
]);
