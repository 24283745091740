/**
 * @ngdoc controller
 * @name liveMeetingOverviewCtrl
 * @description This controller controls the load of live meeting overview data
 *
 */
'use strict';
angular.module('commonModule').controller('liveMeetingOverviewCtrl', [
  '$scope',
  '$state',
  '$sessionStorage',
  '$filter',
  '$log',
  'generalMeetingService',
  'fileDownloadSvc',
  function (
    $scope,
    $state,
    $sessionStorage,
    $filter,
    $log,
    generalMeetingService,
    fileDownloadSvc
  ) {
    $scope.pageSize = appConstant.PAGE_SIZE;
    $scope.pageNumber = 1;
    $scope.count = 0;
    $scope.gmLiveMeetingData = [];
    $scope.isLoading = true;
    $scope.searchParam = '';

    //on click on page number in pagination button
    $scope.getByPage = function (page) {
      $scope.pageNumber = page;
      getLiveMeetingData(page, $scope.pageSize);
    };

    $scope.listStatsForIssuerName = function () {
      $scope.pageNumber = 1;
      getLiveMeetingData($scope.pageNumber, $scope.pageSize);
    };

    $scope.searchOnEnterKey = function ($event) {
      if ($event.keyCode === 13) {
        $scope.listStatsForIssuerName();
      }
    };

    //to download total pending instruction
    $scope.downloadTotalPendingInstruction = function (meetingId) {
      $scope.isLoading = true;
      let pathList = window.location.pathname.split('/');
      if (pathList[1] === 'cbp') {
        downloadFile(
          window.location.origin +
            '/' +
            pathList[1] +
            '/v1/generalmeeting/registrationlist/pending/' +
            meetingId
        );
      } else {
        downloadFile(
          window.location.origin +
            '/v1/generalmeeting/registrationlist/pending/' +
            meetingId
        );
      }
    };

    // TODO: copied from registration-tab-controller.js, need to find out this construction is used and refactor it
    function downloadFile(file) {
      let a = document.createElement('a');
      document.body.appendChild(a);
      fileDownloadSvc.downloadFile(file).then(
        function (response) {
          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            let fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          $scope.downloadExcel = '';
          $scope.isLoading = false;
        },
        function (error) {
          $log.debug(error);
          $scope.isLoading = false;
        }
      );
    }

    /**
     * Service call to get Live meeting overview data
     */
    function getLiveMeetingData(pageNumber, pageSize) {
      $scope.isLoading = true;
      generalMeetingService
        .getLiveMeetingStatistics(pageNumber, pageSize, $scope.searchParam)
        .then(
          function (response) {
            $scope.isLoading = false;
            $scope.count = response.data.totalElements;
            $scope.gmLiveMeetingData = response.data.list;
          },
          function (error) {
            $scope.isLoading = false;
            $log.debug(error);
          }
        );
    }

    //on page load
    getLiveMeetingData($scope.pageNumber, $scope.pageSize);
  },
]);
