/**
 * @ngdoc controller
 * @name userMaintenanceModule.AddVCGroupController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with adding new vote collector group.
 * @requires userGroupService is the service which is being used to interact
 *           with REST
 * @requires $scope for storing information in scope
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 * @requires $sessionStorage for fetching logged in userId from session storage
 * @requires $uibModal
 * @requires $timeout
 * @requires $stateParams
 */

angular.module('userMaintenanceModule').controller('AddVCGroupController', [
  '$scope',
  '$log',
  '$state',
  '$sessionStorage',
  'userGroupService',
  '$timeout',
  '$uibModal',
  '$stateParams',
  function (
    $scope,
    $log,
    $state,
    $sessionStorage,
    userGroupService,
    $timeout,
    $uibModal,
    $stateParams
  ) {
    $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

    $scope.userGroup = 'vote collector group';
    // Below is the service call to interact with REST and get list of countries
    userGroupService
      .getCountryList('EN')
      .query()
      .$promise.then(
        function (countries) {
          $log.debug('success user view');
          $scope.countries = countries.countries;
        },
        function (error) {
          $log.debug(error);
        }
      );
    // Below is the function call from HTML on change of option in country drop down.
    $scope.onCountrySelect = function (selectedCountry) {
      $scope.country = selectedCountry;
      $log.debug('country code', $scope.country.isoCountryCode);
    };
    // The below function call is to store the data from UI
    // and pass it to REST call for adding new group
    $scope.addGroupUser = function () {
      if (!$scope.isDirectDebit || $scope.isDirectDebit === undefined) {
        $scope.isDirectDebit = 'N';
      } else {
        $scope.isDirectDebit = 'Y';
      }

      $log.debug('$scope.username', $scope.userName);

      var addUserGroupData = {
        voteCollectorName: $scope.userName,
        userId: $sessionStorage.userId,
        city: $scope.city,
        country: $scope.country.isoCountryCode,
        productCode: 'GM',
        isDirectDebit: $scope.isDirectDebit,
        ibanNumber: $scope.ibanNumber,
      };

      // The createNewUser in userService calls the REST url
      // for adding new user
      userGroupService.createVCGroup(addUserGroupData).then(
        function (response) {
          $scope.success = true;
          $scope.successMsg = response;
          $scope.timedout = $uibModal.open({
            templateUrl: 'success-dialog.html',
          });
          $timeout(function () {
            $scope.timedout.close();
            $scope.timedout = null;
            $state.go('userMaintenance.voteCollectorGroup', {
              pageNo: $stateParams.pageNo,
            });
          }, 2000);
        },
        function (error) {
          $scope.error = true;
          $scope.errorMsg = error.data.errors[0].errorMessage;
        }
      );
    };
  },
]);
