/**
 * @ngDoc controller
 * @name shareHolderModule.MyVotesController
 * @description This controller is used to view submitted attendance request and
 *              voting instructions
 * @requires myVoteService is the service which is being used to interact with
 *           REST
 * @requires $scope
 * @requires $stateParams used for storing/passing information in
 *           scope/state
 * @requires $sessionStorage
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires $filter to translate messages which are declared in locale files
 * @requires PaginationSvc is for pagination of 5 records
 * @requires voteInstructionService
 * @requires ModelWindowService
 */
angular.module('shareHolderModule').controller('MyVotesController', [
  '$scope',
  '$state',
  '$sessionStorage',
  'myVoteService',
  '$filter',
  '$log',
  '$stateParams',
  'PaginationSvc',
  'voteInstructionService',
  'ModelWindowService',
  'fileDownloadSvc',
  'GeneralMeetingDataLanguageService',
  'generalMeetingService',
  function (
    $scope,
    $state,
    $sessionStorage,
    myVoteService,
    $filter,
    $log,
    $stateParams,
    PaginationSvc,
    voteInstructionService,
    ModelWindowService,
    fileDownloadSvc,
    GeneralMeetingDataLanguageService,
    generalMeetingService
  ) {
    $scope.userType = $sessionStorage.usertype;
    $scope.languageCode = $sessionStorage.workstationData.selectedLang || 'EN';
    $scope.shareClassModelConfig = {};
    $scope.prevGMRequests = [];
    $scope.loading = true;
    var type = $stateParams.type;
    if ($scope.userType === 'SH') {
      $scope.selectedIndex = 1;
    } else if ($scope.userType === 'SP') {
      $scope.selectedIndex = 1;
    }
    /**
     * Below conditions added for redirection on proper page
     * after voting instruction and attendance requests are submitted.
     * In shareholder ws, if a voting instruction or attendance request is submitted,
     * then the my votes page should be loaded with status 'P'.
     * Else it would load with ALL data.
     * In shareplan ws, the status would be auto approved.
     */
    if ($sessionStorage.dropdownVal && $sessionStorage.radioVal) {
      $scope.generalMeetingStatusVal = $sessionStorage.dropdownVal;
      $scope.content = $sessionStorage.radioVal;
    } else if ($stateParams.radioVal && $stateParams.dropdownVal) {
      $scope.content = $stateParams.radioVal;
      $scope.generalMeetingStatusVal = $stateParams.dropdownVal;
    } else if (
      $stateParams.landingThrough === 'addVote' ||
      (type && type === 'vote')
    ) {
      $scope.content = 'voteIns';
      $scope.generalMeetingStatusVal = 'P';
    } else if ($stateParams.landingThrough === 'addAttend' || type) {
      $scope.content = 'attendReq';
      $scope.generalMeetingStatusVal = 'P';
    } else {
      $scope.content = 'attendReq';
      if ($scope.userType === 'SP') {
        $scope.generalMeetingStatusVal = 'A';
      } else {
        $scope.generalMeetingStatusVal = 'ALL';
      }
    }
    /**
     * Below popup is for cancel confirmation of vote/attend events
     */
    $scope.openPopup = function (identificationNumber, type) {
      $scope.modalHeader = $filter('translate')(
        'label_confirmVI_registration_header'
      );
      $scope.modalFooter = '';
      $scope.modalLeftButton = $filter('translate')('label_ok');
      $scope.modalRightButton = $filter('translate')('label_cancel');
    };
    // when language changes, following piece of code is executed from header-controller.js
    $scope.$on('onLanguageMyvotes', function (event, lang) {
      $scope.languageCode = lang;
    });
    /**
     * Attendance request records:
     * To check if the shareholder deadline date is greater than today's date.
     * If yes, then do not show the cancel button for pending items
     */
    $scope.checkForAttdDeadlineDate = function () {
      var todaysDate = '';
      generalMeetingService
        .getCurrentCETTime()
        .query()
        .$promise.then(
          function (data) {
            $scope.todaysDate = data;
          },
          function (error) {
            $log.debug(error);
          }
        );
      if (
        $scope.attendanceRequestList &&
        $scope.attendanceRequestList.length >= 0
      ) {
        angular.forEach($scope.attendanceRequestList, function (item) {
          if (item.generalMeetingDateStr.indexOf('CET') > -1) {
            todaysDate = $filter('filter')(
              $scope.currentDateInGMTCET,
              { timezone: 'CET' },
              true
            );
          } else if (item.generalMeetingDateStr.indexOf('GMT') > -1) {
            todaysDate = $filter('filter')(
              $scope.currentDateInGMTCET,
              { timezone: 'GMT' },
              true
            );
          }
          if (item.shDeadlineDate >= todaysDate[0].currentMillis) {
            item.disableAttdCancel = true;
          } else {
            item.disableAttdCancel = false;
          }
        });
      }
    };
    /**
     * Voting instruction records:
     * To check if the shareholder deadline date is greater than today's date.
     * If yes, then do not show the cancel button for pending items
     */
    $scope.checkForVoteDeadlineDate = function () {
      var todaysDate = '';
      generalMeetingService
        .getCurrentCETTime()
        .query()
        .$promise.then(
          function (data) {
            $scope.todaysDate = data;
          },
          function (error) {
            $log.debug(error);
          }
        );
      if (
        $scope.votingInstructionList &&
        $scope.votingInstructionList.length > 0
      ) {
        angular.forEach($scope.votingInstructionList, function (item) {
          if (item.generalMeetingDateStr.indexOf('CET') > -1) {
            todaysDate = $filter('filter')(
              $scope.currentDateInGMTCET,
              { timezone: 'CET' },
              true
            );
          } else if (item.generalMeetingDateStr.indexOf('GMT') > -1) {
            todaysDate = $filter('filter')(
              $scope.currentDateInGMTCET,
              { timezone: 'GMT' },
              true
            );
          }
          if (item.shDeadlineDate >= todaysDate[0].currentMillis) {
            item.disableVoteCancel = true;
          } else {
            item.disableVoteCancel = false;
          }
        });
      }
    };
    /**
     * To get the intermediary details to be displayed in modal window
     */
    $scope.intermediaryDetails = function (identificationNumber, type) {
      var reqType = type;
      myVoteService
        .getIntermediaryDetails(identificationNumber, type)
        .query()
        .$promise.then(
          function (data) {
            $scope.intermediaryData = data;
            var phoneNo = data.intermediaryPhone;
            var intermediaryName = data.intermediaryName;
            var intEmailAdress = data.emailAddress;
            if (data.agentAsIntermediay === 'Y') {
              $scope.modalBody =
                $filter('translate')('callIntermediary_SorryModalbody1') +
                phoneNo +
                $filter('translate')('callIntermediary_SorryModalbody2');
              $scope.modalBody1 = ' ';
              $scope.modalBody2 = ' ';
              $scope.modalBody3 = ' ';
              $scope.modalBody4 = ' ';
              $scope.modalBody5 = ' ';
              $scope.modalBody6 = ' ';
              $scope.modalBody7 = ' ';
            } else {
              if (reqType === 'vote') {
                $scope.modalBody = $filter('translate')(
                  'label_callIntermediary_VI_registration'
                );
              } else {
                $scope.modalBody = $filter('translate')(
                  'label_callIntermediary_AR_registration'
                );
              }
              if (identificationNumber) {
                $scope.modalBody1 =
                  $filter('translate')('label_ask_provideIdentification_VI') +
                  ' ' +
                  identificationNumber;
              }
              $scope.modalBody2 = $filter('translate')(
                'label_sh_vi_modalBody2'
              );
              $scope.modalBody3 = $filter('translate')(
                'label_sh_vi_modalBody3'
              );
              $scope.modalBody4 =
                $filter('translate')('lable_contactDetails') +
                $filter('translate')('label_colon');
              if (intermediaryName) {
                $scope.modalBody5 =
                  $filter('translate')('label_intermediary') +
                  $filter('translate')('label_colon') +
                  ' ' +
                  intermediaryName;
              }
              if (intEmailAdress) {
                $scope.modalBody6 =
                  $filter('translate')('label_addresseeEmail') +
                  $filter('translate')('label_colon') +
                  ' ' +
                  intEmailAdress;
              }
              if (phoneNo) {
                $scope.modalBody7 =
                  $filter('translate')('label_telephoneNumber') +
                  $filter('translate')('label_colon') +
                  ' ' +
                  phoneNo;
              }
            }
          },
          function (error) {
            $log.debug(error);
          }
        );
    };
    $scope.shEditAttendance = function (
      meetingId,
      issuerId,
      meetingName,
      attendanceId,
      gmDate,
      registrationNo,
      intermediaryName,
      issuerName,
      isVirtualMeetingAllowed
    ) {
      $state.go('shEditAttendance', {
        gmId: meetingId,
        reqId: attendanceId,
        issuerId: issuerId,
        shareholderId: $sessionStorage.groupId,
        registrationNo: registrationNo,
        gmDate: gmDate,
        intermediaryName: intermediaryName,
        issuerName: issuerName,
        breadCrumbDetails: [],
        isVirtualMeetingAllowed: isVirtualMeetingAllowed,
      });
    };
    /**
     * To get the intermediary details to be displayed in modal window while shareholder submits cancellation request for approved VI
     */
    $scope.intermediaryDetailsForCancellation = function (
      reqId,
      reqType,
      status,
      langCode,
      identificationNumber,
      type
    ) {
      $scope.reqId = reqId;
      $scope.reqType = reqType;
      $scope.status = status;
      $scope.langCode = langCode;
      $scope.identificationNumber = identificationNumber;
      $scope.modalLeftButton = $filter('translate')('label_confirm');
      $scope.modalRightButton = $filter('translate')('label_cancel');
      $scope.modalHeader = $filter('translate')(
        'label_cancelRequestHeaderModal'
      );
      myVoteService
        .getIntermediaryDetails(identificationNumber, type)
        .query()
        .$promise.then(
          function (data) {
            $scope.intermediaryData = data;
            if (data.agentAsIntermediay === 'Y') {
              $scope.modalBody = $filter('translate')(
                'label_viCancellationText1'
              );
              $scope.modalBody1 =
                $filter('translate')('callIntermediary_SorryModalbody1') +
                data.intermediaryPhone +
                $filter('translate')('callIntermediary_SorryModalbody2');
              $scope.modalBody2 = '';
              $scope.modalBody3 = '';
              $scope.modalBody4 = '';
              $scope.modalBody5 = '';
              $scope.modalBody6 = '';
              $scope.modalBody7 = '';
            } else {
              $scope.modalBody = $filter('translate')(
                'label_viCancellationText1'
              );
              $scope.modalBody1 =
                $filter('translate')('label_ask_provideIdentification') +
                identificationNumber;
              $scope.modalBody2 = $filter('translate')(
                'label_sh_vi_modalBody2'
              );
              $scope.modalBody3 = $filter('translate')(
                'label_sh_vi_modalBody3'
              );
              $scope.modalBody4 =
                $filter('translate')('lable_contactDetails') +
                $filter('translate')('label_colon');
              $scope.modalBody5 =
                $filter('translate')('label_intermediary') +
                $filter('translate')('label_colon') +
                ' ' +
                data.intermediaryName;
              $scope.modalBody6 =
                $filter('translate')('label_addresseeEmail') +
                $filter('translate')('label_colon') +
                ' ' +
                data.emailAddress;
              $scope.modalBody7 =
                $filter('translate')('label_teleNumber') +
                $filter('translate')('label_colon') +
                ' ' +
                data.intermediaryPhone;
            }
          },
          function (error) {
            $log.debug(error);
          }
        );
    };
    /**
     * Below function is used to fetch the submitted attendance request and voting instructions
     * type && type === 'vote' - for delete and reload voting instruction radio tab
     * type && type === 'attend' - for delete and reload attendance req radio tab
     * last else with switch is for back button load
     */
    $scope.getRequests = function () {
      generalMeetingService
        .getCurrentCETTime()
        .query()
        .$promise.then(
          function (data) {
            $scope.currentDateInGMTCET = data;
          },
          function (error) {
            $log.debug(error);
          }
        );
      myVoteService
        .getMyVoteList($scope.languageCode)
        .query()
        .$promise.then(
          function (response) {
            if (
              response.attendanceRequestVOList &&
              response.attendanceRequestVOList.length > 0
            ) {
              angular.forEach(response.attendanceRequestVOList, function (
                item
              ) {
                var todaysDate = '';
                if (item != null) {
                  if (item.gmDate.indexOf('CET') > -1) {
                    todaysDate = $filter('filter')(
                      $scope.currentDateInGMTCET,
                      { timezone: 'CET' },
                      true
                    );
                  } else if (item.gmDate.indexOf('GMT') > -1) {
                    todaysDate = $filter('filter')(
                      $scope.currentDateInGMTCET,
                      { timezone: 'GMT' },
                      true
                    );
                  }
                  if (item.shAccessDate > todaysDate[0].currentMillis) {
                    item.yetToStart = true;
                    item.notAbleToVoteAttend = false;
                    item.showVoteAttend = false;
                  } else if (
                    item.shAccessDate <= todaysDate[0].currentMillis &&
                    item.shDeadlineDate >= todaysDate[0].currentMillis
                  ) {
                    item.yetToStart = false;
                    item.notAbleToVoteAttend = false;
                    item.showVoteAttend = true;
                  } else if (
                    item.shDeadlineDate < todaysDate[0].currentMillis
                  ) {
                    item.yetToStart = false;
                    item.notAbleToVoteAttend = true;
                    item.showVoteAttend = false;
                  }
                }
              });
              $scope.attendReqData = GeneralMeetingDataLanguageService.getLangSpecificDates(
                response.attendanceRequestVOList,
                $scope.languageCode
              );
            }
            if (response.instructionVOList.length > 0) {
              angular.forEach(response.instructionVOList, function (item) {
                var todaysDate = '';
                if (item != null) {
                  if (item.gmDate.indexOf('CET') > -1) {
                    todaysDate = $filter('filter')(
                      $scope.currentDateInGMTCET,
                      { timezone: 'CET' },
                      true
                    );
                  } else if (item.gmDate.indexOf('GMT') > -1) {
                    todaysDate = $filter('filter')(
                      $scope.currentDateInGMTCET,
                      { timezone: 'GMT' },
                      true
                    );
                  }
                  item.disableVoteCancel = true;
                  if (item.shAccessDate > todaysDate[0].currentMillis) {
                    item.yetToStart = true;
                    item.notAbleToVoteAttend = false;
                    item.showVoteAttend = false;
                  } else if (
                    item.shAccessDate <= todaysDate[0].currentMillis &&
                    item.shDeadlineDate >= todaysDate[0].currentMillis
                  ) {
                    item.yetToStart = false;
                    item.notAbleToVoteAttend = false;
                    item.showVoteAttend = true;
                  } else if (
                    item.shDeadlineDate < todaysDate[0].currentMillis
                  ) {
                    item.yetToStart = false;
                    item.notAbleToVoteAttend = true;
                    item.showVoteAttend = false;
                    item.disableVoteCancel = false;
                  }
                }
              });
              $scope.instructionVOList = response.instructionVOList;
              $scope.votingInstructionData = response.instructionVOList;
            }
            $scope.prevGMRequests = response.confirmedVotesList;
            $scope.votingInstructionData = GeneralMeetingDataLanguageService.getLangSpecificDates(
              $scope.votingInstructionData,
              $scope.languageCode
            );
            if ($scope.prevGMRequests) {
              $scope.prevGMRequests = GeneralMeetingDataLanguageService.getLangSpecificDates(
                $scope.prevGMRequests,
                $scope.languageCode
              );
            }
            $scope.dummyItemsPrevGM = $scope.prevGMRequests;
            if (type && type === 'vote') {
              if ($scope.userType === 'SH') {
                if ($scope.generalMeetingStatusVal === 'P') {
                  $scope.votingInstructionList = $filter('filter')(
                    $scope.votingInstructionData,
                    {
                      status: 'P',
                    }
                  );
                } else if ($scope.generalMeetingStatusVal === 'ALL') {
                  $scope.votingInstructionList = $scope.votingInstructionData;
                } else {
                  $scope.votingInstructionListA = $filter('filter')(
                    $scope.votingInstructionData,
                    {
                      status: 'A',
                    }
                  );
                  $scope.votingInstructionListX = $filter('filter')(
                    $scope.votingInstructionData,
                    {
                      status: 'X',
                    }
                  );
                  $scope.votingInstructionList = $scope.votingInstructionListA.concat(
                    $scope.votingInstructionListX
                  );
                }
                $scope.dummyItems = $scope.votingInstructionList;
              } else {
                $scope.dummyItems = $scope.votingInstructionData;
              }
            } else if (type && type === 'attend') {
              if ($scope.userType === 'SH') {
                if ($scope.generalMeetingStatusVal === 'P') {
                  $scope.attendanceRequestList = $filter('filter')(
                    response.attendanceRequestVOList,
                    {
                      status: 'P',
                    }
                  );
                } else if ($scope.generalMeetingStatusVal === 'ALL') {
                  $scope.attendanceRequestList =
                    response.attendanceRequestVOList;
                } else {
                  $scope.attendanceRequestListA = $filter('filter')(
                    response.attendanceRequestVOList,
                    {
                      status: 'A',
                    }
                  );
                  $scope.attendanceRequestListX = $filter('filter')(
                    response.attendanceRequestVOList,
                    {
                      status: 'X',
                    }
                  );
                  $scope.attendanceRequestList = $scope.attendanceRequestListA.concat(
                    $scope.attendanceRequestListX
                  );
                }
                $scope.dummyItems = $scope.attendanceRequestList;
              } else {
                $scope.dummyItems = response.attendanceRequestVOList;
              }
            } else if ($scope.userType === 'SP') {
              $scope.votingInstructionList = $scope.votingInstructionData;
              $scope.attendanceRequestList = response.attendanceRequestVOList;
              if ($stateParams.radioVal === 'voteIns') {
                $scope.dummyItems = $scope.votingInstructionList;
              } else {
                $scope.dummyItems = $scope.attendanceRequestList;
              }
            } else {
              switch ($scope.generalMeetingStatusVal) {
                case 'C':
                  $scope.attendanceRequestList = $filter('filter')(
                    response.attendanceRequestVOList,
                    {
                      status: 'C',
                    }
                  );
                  $scope.votingInstructionList = $filter('filter')(
                    $scope.votingInstructionData,
                    {
                      status: 'C',
                    }
                  );
                  break;
                case 'P':
                  $scope.attendanceRequestList = $filter('filter')(
                    response.attendanceRequestVOList,
                    {
                      status: 'P',
                    }
                  );
                  $scope.votingInstructionList = $filter('filter')(
                    $scope.votingInstructionData,
                    {
                      status: 'P',
                    }
                  );
                  break;
                case 'R':
                  $scope.attendanceRequestList = $filter('filter')(
                    response.attendanceRequestVOList,
                    {
                      status: 'R',
                    }
                  );
                  $scope.votingInstructionList = $filter('filter')(
                    $scope.votingInstructionData,
                    {
                      status: 'R',
                    }
                  );
                  break;
                case 'A':
                  $scope.attendanceRequestListA = $filter('filter')(
                    response.attendanceRequestVOList,
                    {
                      status: 'A',
                    }
                  );
                  $scope.attendanceRequestListX = $filter('filter')(
                    response.attendanceRequestVOList,
                    {
                      status: 'X',
                    }
                  );
                  $scope.votingInstructionListA = $filter('filter')(
                    $scope.votingInstructionData,
                    {
                      status: 'A',
                    }
                  );
                  $scope.votingInstructionListX = $filter('filter')(
                    $scope.votingInstructionData,
                    {
                      status: 'X',
                    }
                  );
                  $scope.attendanceRequestList = $scope.attendanceRequestListA.concat(
                    $scope.attendanceRequestListX
                  );
                  $scope.votingInstructionList = $scope.votingInstructionListA.concat(
                    $scope.votingInstructionListX
                  );
                  break;
                default:
                  $scope.attendanceRequestList =
                    response.attendanceRequestVOList;
                  $scope.votingInstructionList = $scope.votingInstructionData;
                  break;
              }
              if ($scope.content === 'attendReq') {
                $scope.dummyItems = $scope.attendanceRequestList;
              } else {
                $scope.dummyItems = $scope.votingInstructionList;
              }
            }
            $scope.attendanceTemp = $scope.attendanceRequestList;
            $scope.setPagePrevGM(1);
            $scope.votingTemp = $scope.votingInstructionList;
            $scope.prevGmTemp = $scope.prevGMRequests;
            $scope.setPage(1);
            $sessionStorage.dropdownVal = null;
            $sessionStorage.radioVal = null;
            $scope.loading = false;
          },
          function (error) {
            $scope.loading = false;
            $log.debug(error);
          }
        );
    };
    // Below function is used for pagination
    $scope.setPage = function (page) {
      $scope.pager = [];
      if (page < 1 || page > $scope.pager.totalPages) {
        return;
      }
      // get pager object from service
      if ($scope.dummyItems != null) {
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
        $log.debug($scope.pager);
      }
      // get current page of items
      if ($scope.content === 'attendReq' && $scope.dummyItems !== undefined) {
        $scope.attendanceRequestList = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
      } else {
        if ($scope.dummyItems !== undefined) {
          $scope.votingInstructionList = $scope.dummyItems.slice(
            $scope.pager.startIndex,
            $scope.pager.endIndex + 1
          );
        } else {
          $scope.votingInstructionList = [];
        }
      }
      $scope.checkForAttdDeadlineDate();
    };
    // Below function is used for pagination of confirmed votes of previous general meeting
    $scope.setPagePrevGM = function (page) {
      $scope.pager1 = [];
      if (page < 1 || page > $scope.pager1.totalPages) {
        return;
      }
      // get pager object from service
      if ($scope.dummyItemsPrevGM != null) {
        $scope.pager1 = PaginationSvc.GetPager(
          $scope.dummyItemsPrevGM.length,
          page
        );
        $log.debug($scope.pager1);
      }
      // get current page of items
      $scope.prevGMRequests = $scope.dummyItemsPrevGM.slice(
        $scope.pager1.startIndex,
        $scope.pager1.endIndex + 1
      );
    };
    $scope.registrationCertificateAlert = function (meetingId, registrationNo) {
      $scope.meetingIdLang = meetingId;
      $scope.registrationNoLang = registrationNo;
      $scope.modalBody = '';
      ModelWindowService.showModelWindow('myVotesToDownload');
    };

    // Below function is used to download registration certificate
    $scope.registrationCertificate = function (meetingId, registrationNo) {
      $scope.fileloading = true;
      let downloadUrl =
        '/v2/gm/shareholder/general-meetings/' +
        meetingId +
        '/registration-certificates/' +
        registrationNo +
        '?language=' +
        $sessionStorage.languageSelected;
      var a = document.createElement('a');
      document.body.appendChild(a);
      fileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          if (!$scope.fileloading) {
            return;
          }

          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            var fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          downloadUrl = '';
          $scope.fileloading = false;
        },
        function (error) {
          $scope.fileloading = false;
          $log.debug(error);
        }
      );
    };
    //Below is the function to download voting confirmation pdf.
    $scope.downloadVotingConfirmPdf = function (
      meetingId,
      shareClassId,
      instructionId
    ) {
      var pathList = window.location.pathname.split('/');
      if (pathList[1] === 'cbp') {
        $scope.votingConfirmPdf =
          window.location.origin +
          '/' +
          pathList[1] +
          '/v1/shareholder/votingconfirmations/' +
          meetingId +
          '/' +
          shareClassId +
          '/' +
          instructionId +
          '/' +
          $sessionStorage.userId +
          '/' +
          $sessionStorage.usertype +
          '/EN' +
          '?authid=' +
          $sessionStorage.userId;
      } else {
        $scope.votingConfirmPdf =
          window.location.origin +
          '/v1/shareholder/votingconfirmations/' +
          meetingId +
          '/' +
          shareClassId +
          '/' +
          instructionId +
          '/' +
          $sessionStorage.userId +
          '/' +
          $sessionStorage.usertype +
          '/EN' +
          '?authid=' +
          $sessionStorage.userId;
      }
    };
    /**
     * Below is the function call on click of radio button to
     * show only attendance request data or voting instruction
     * data. val=attendReq is for attendance request data, else block will fetch the voting instruction
     * related data.
     * generalMeetingStatusVal:
     * P - Pending
     * A - Approved
     * R - Rejected
     * C - Cancelled
     * X - Approved, but cancellation requested
     * Default section will fetch all data for either attendance request or voting instruction ignoring the status.
     **/
    //SFCBP-2568 confirmed votes display after login to SP WS when there are no live meetings
    $scope.showConfirmedVotes = true;
    if ($scope.content === 'confvotes') {
      $scope.showConfirmedVotes = false;
    }
    //SFCBP-2568 END
    $scope.myVotesRadio = function (val) {
      if (val === 'virtualParticipation') {
        let indexOfSelectedTab = 0;
        if ($scope.userType === 'SP') {
          indexOfSelectedTab = 1;
        }
        $state.go('shMyVirtualParticipation', {
          selectedIndex: indexOfSelectedTab,
          selectedRequestStatus: $scope.generalMeetingStatusVal,
        });
      }

      if (val === 'confvotes') {
        $scope.showConfirmedVotes = false;
      } else {
        $scope.showConfirmedVotes = true;
      }

      if (val === 'attendReq' && $scope.userType === 'SP') {
        $scope.generalMeetingName = '';
        $scope.dummyItems = $scope.attendReqData;
      } else if (val === 'voteIns' && $scope.userType === 'SP') {
        $scope.generalMeetingName = '';
        $scope.dummyItems = $scope.votingInstructionData;
      } else if (val === 'attendReq') {
        switch ($scope.generalMeetingStatusVal) {
          case 'P':
            $scope.dummyItems = $filter('filter')($scope.attendReqData, {
              status: 'P',
            });
            break;
          case 'A':
            $scope.dummyItems = $filter('filter')(
              $scope.attendReqData,
              function (value) {
                return value.status === 'X' || value.status === 'A';
              }
            );
            break;
          case 'R':
            $scope.dummyItems = $filter('filter')($scope.attendReqData, {
              status: 'R',
            });
            break;
          case 'C':
            $scope.dummyItems = $filter('filter')($scope.attendReqData, {
              status: 'C',
            });
            break;
          default:
            $scope.dummyItems = $scope.attendReqData;
        }
      } else {
        switch ($scope.generalMeetingStatusVal) {
          case 'P':
            $scope.dummyItems = $filter('filter')(
              $scope.votingInstructionData,
              {
                status: 'P',
              }
            );
            break;
          case 'A':
            $scope.dummyItems = $filter('filter')(
              $scope.votingInstructionData,
              function (value) {
                return value.status === 'X' || value.status === 'A';
              }
            );
            break;
          case 'R':
            $scope.dummyItems = $filter('filter')(
              $scope.votingInstructionData,
              {
                status: 'R',
              }
            );
            break;
          case 'C':
            $scope.dummyItems = $filter('filter')(
              $scope.votingInstructionData,
              {
                status: 'C',
              }
            );
            break;
          default:
            $scope.dummyItems = $scope.votingInstructionData;
        }
      }
      $scope.pager = {};
      $scope.setPage(1);
    };
    // Below is the function to search the general meeting
    // based on the issuer name
    $scope.onGeneralMeetingSearch = function (val, status) {
      if ('ALL' === status) {
        if ($scope.content === 'attendReq') {
          $scope.dummyItems = $filter('filter')($scope.attendanceTemp, {
            issuerName: val,
          });
        } else {
          $scope.dummyItems = $filter('filter')($scope.votingTemp, {
            issuerName: val,
          });
        }
      } else {
        if ($sessionStorage.usertype === 'SP') {
          if ($scope.content === 'attendReq') {
            $scope.dummyItems = $filter('filter')($scope.attendanceTemp, {
              issuerName: val,
            });
          } else {
            $scope.dummyItems = $filter('filter')($scope.votingTemp, {
              issuerName: val,
            });
          }
        } else {
          if ($scope.content === 'attendReq') {
            $scope.dummyItems = $filter('filter')($scope.attendanceTemp, {
              issuerName: val,
              status: status,
            });
          } else {
            $scope.dummyItems = $filter('filter')($scope.votingTemp, {
              issuerName: val,
              status: status,
            });
          }
        }
      }
      $scope.dummyItemsPrevGM = $filter('filter')($scope.prevGmTemp, {
        issuerName: val,
      });
      $scope.pager = {};
      $scope.setPage(1);
      $scope.setPagePrevGM(1);
    };
    // Below is the method to hide or show accordian
    $scope.showHideDetails = function (item) {
      item.showDetails = !item.showDetails;
    };
    /**
     * onGeneralMeetingStatusSelection is the function call on change of drop-down to
     * filter the data
     * content=attendReq is for attendance request data, else block will fetch the voting instruction
     * related data.
     * generalMeetingStatusVal:
     * P - Pending
     * A - Approved
     * R - Rejected
     * C - Cancelled
     * X - Approved, but cancellation requested
     * Default section will fetch all data for either attendance request or voting instruction ignoring the status.
     **/
    $scope.onGeneralMeetingStatusSelection = function () {
      $scope.generalMeetingName = '';
      switch ($scope.generalMeetingStatusVal) {
        case 'P':
          if ($scope.content === 'attendReq') {
            $scope.attendanceTemp = $filter('filter')($scope.attendReqData, {
              status: 'P',
            });
            $scope.dummyItems = $scope.attendanceTemp;
          } else {
            $scope.votingTemp = $filter('filter')(
              $scope.votingInstructionData,
              {
                status: 'P',
              }
            );
            $scope.dummyItems = $scope.votingTemp;
          }
          break;
        case 'A':
          if ($scope.content === 'attendReq') {
            $scope.attendanceTempA = $filter('filter')($scope.attendReqData, {
              status: 'A',
            });
            $scope.attendanceTempX = $filter('filter')($scope.attendReqData, {
              status: 'X',
            });
            $scope.attendanceTemp = $scope.attendanceTempA.concat(
              $scope.attendanceTempX
            );
            $scope.dummyItems = $scope.attendanceTempA.concat(
              $scope.attendanceTempX
            );
          } else {
            $scope.votingTempA = $filter('filter')(
              $scope.votingInstructionData,
              {
                status: 'A',
              }
            );
            $scope.votingTempX = $filter('filter')(
              $scope.votingInstructionData,
              {
                status: 'X',
              }
            );
            $scope.votingTemp = $scope.votingTempA.concat($scope.votingTempX);
            $scope.dummyItems = $scope.votingTempA.concat($scope.votingTempX);
          }
          break;
        case 'R':
          if ($scope.content === 'attendReq') {
            $scope.attendanceTemp = $filter('filter')($scope.attendReqData, {
              status: 'R',
            });
            $scope.dummyItems = $scope.attendanceTemp;
          } else {
            $scope.votingTemp = $filter('filter')(
              $scope.votingInstructionData,
              {
                status: 'R',
              }
            );
            $scope.dummyItems = $scope.votingTemp;
          }
          break;
        case 'C':
          if ($scope.content === 'attendReq') {
            $scope.attendanceTemp = $filter('filter')($scope.attendReqData, {
              status: 'C',
            });
            $scope.dummyItems = $scope.attendanceTemp;
          } else {
            $scope.votingTemp = $filter('filter')(
              $scope.votingInstructionData,
              {
                status: 'C',
              }
            );
            $scope.dummyItems = $scope.votingTemp;
          }
          break;
        default:
          if ($scope.content === 'attendReq') {
            $scope.attendanceTemp = $scope.attendReqData;
            $scope.dummyItems = $scope.attendanceTemp;
          } else {
            $scope.votingTemp = $scope.votingInstructionData;
            $scope.dummyItems = $scope.votingTemp;
          }
      }
      $scope.pager = {};
      $scope.setPage(1);
    };

    if (
      $sessionStorage.usertype === 'SP' ||
      $sessionStorage.usertype === 'SH'
    ) {
      $scope.shareholderId = $sessionStorage.groupId;
    } else {
      $scope.shareholderId = 0;
    }
    $scope.updateModal = function (
      reqId,
      reqType,
      status,
      langCode,
      identificationNumber
    ) {
      $scope.reqId = reqId;
      $scope.reqType = reqType;
      $scope.status = status;
      $scope.langCode = langCode;
      $scope.identificationNumber = identificationNumber;
      $scope.modalLeftButton = $filter('translate')('label_confirm');
      $scope.modalRightButton = $filter('translate')('label_cancel');
      $scope.modalBody = $filter('translate')('label_cancelRequestBodyModal');
      $scope.modalHeader = $filter('translate')(
        'label_cancelRequestHeaderModal'
      );
    };
    $scope.cancelRequest = function (reqId, reqType, status, langCode) {
      $scope.loading = true;
      if ($sessionStorage.usertype === 'SP') {
        status = 'C';
      }
      myVoteService
        .cancelRequest(reqId, reqType, status, langCode, 'secureToken')
        .then(
          function (response) {
            $scope.response = response.data.message;
            if ($scope.content === 'voteIns') {
              type = 'vote';
            } else {
              type = 'attend';
            }
            $scope.getRequests();
            $scope.loading = false;
          },
          function (error) {
            $scope.loading = false;
            $scope.error = error.data.errors[0].errorMessage;
          }
        );
    };

    $scope.onColumnClick = function (newValue) {
      if (newValue === '' || !newValue) {
        return;
      }
      if ($scope.oldValue === newValue) {
        $scope.sortType = $scope.oldValue;
        $scope.dummyItems = $filter('orderBy')(
          $scope.dummyItems,
          newValue,
          true
        );
        $scope.sortReverse = !$scope.sortReverse;
        newValue = '';
      } else {
        $scope.sortReverse = false;
        $scope.sortType = newValue;
        $scope.dummyItems = $filter('orderBy')($scope.dummyItems, newValue);
      }
      $scope.pager = {};
      $scope.setPage($scope.currentPage);
      $scope.oldValue = newValue;
    };
    $scope.votingInstructionView = function (
      meetingId,
      issuerId,
      meetingName,
      instructionId,
      status,
      generalMeetingStatusVal,
      numberOfSecurities,
      action,
      issuerName,
      isVirtualMeetingAllowed
    ) {
      var selectedIndex = 2;
      // check if User is logged in or not
      if (action === 'edit') {
        $scope.stateUrl = 'shVotingInstructionsEdit';
      } else {
        $scope.stateUrl = 'shVotingInstructionsView';
      }
      if (!$scope.action) {
        $scope.action = action;
      }
      if ($scope.userType === 'SP') {
        selectedIndex = 1;
      }
      voteInstructionService
        .getShareClassListWithVotes(
          meetingId,
          $scope.shareholderId,
          $scope.languageCode,
          $scope.userType,
          instructionId,
          status
        )
        .query()
        .$promise.then(
          function (data) {
            if (data.length > 0) {
              if ($sessionStorage.userId) {
                $state.go($scope.stateUrl, {
                  meetingId: meetingId,
                  issuerId: issuerId,
                  shareHolderId: $scope.shareholderId,
                  selectedIndex: selectedIndex,
                  votingInstDetails: data,
                  fromPage: 'GM',
                  disabled: true,
                  radioVal: 'voteIns',
                  issuerName: issuerName,
                  dropdownVal: generalMeetingStatusVal,
                  landingThrough: 'myVotes',
                  action: $scope.action,
                  status: status,
                  numberOfSecurities: numberOfSecurities,
                  breadCrumbDetails: [data[0].meetingName],
                  virtualMeetingFlag: isVirtualMeetingAllowed,
                  instructionId: instructionId
                });
              } else {
                // redirect to Login Page
                $sessionStorage.voteObject = {
                  issuerName: issuerName,
                  meetingId: meetingId,
                  issuerId: issuerId,
                  meetingName: meetingName,
                };
                $state.go('shareholderlogin', {});
              }
            } else if (data.length <= 0 && $scope.languageCode !== 'EN') {
              $scope.languageCode = 'EN';
              $scope.votingInstructionView(
                meetingId,
                issuerId,
                meetingName,
                instructionId,
                status,
                generalMeetingStatusVal,
                numberOfSecurities,
                $scope.action,
                issuerName
              );
            } else {
              $scope.shareClassModelConfig.modalHeader = $filter('translate')(
                'label_votingInsCreatErr'
              );
              $scope.shareClassModelConfig.modalBody = $filter('translate')(
                'label_modalShareVotingInsCreatErrMsg'
              );
              ModelWindowService.showModelWindow('shareClassAlertBox');
            }
          },
          function (error) {
            return error;
          }
        );
    };
    // when language changes
    $scope.$on('onLanguageChangeShareHolder', function (event, lang) {
      $scope.attendanceRequestListData = GeneralMeetingDataLanguageService.getLangSpecificDates(
        $scope.attendanceRequestList,
        lang
      );
      $scope.attendanceRequestList = $scope.attendanceRequestListData;
      $scope.votingInstructionList = GeneralMeetingDataLanguageService.getLangSpecificDates(
        $scope.votingInstructionList,
        lang
      );
      if ($scope.prevGMRequests) {
        $scope.prevGMRequests = GeneralMeetingDataLanguageService.getLangSpecificDates(
          $scope.prevGMRequests,
          lang
        );
      }
    });
    $scope.getRequests();
  },
]);
