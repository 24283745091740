/**
 * @ngDoc controller
 * @name generalMeetingMainModule.EuroclearController
 * @description This controller is used to send reports via email to euroclear.
 * @requires generalMeetingServiceare the service which is being used to interact with REST
 * @requires $scope for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 */
'use strict';

angular.module('generalMeetingMainModule').controller('EuroclearController', [
  '$scope',
  '$state',
  '$sessionStorage',
  'generalMeetingService',
  '$filter',
  '$log',
  'fileDownloadSvc',
  '$timeout',
  function (
    $scope,
    $state,
    $sessionStorage,
    generalMeetingService,
    $filter,
    $log,
    fileDownloadSvc,
    $timeout
  ) {
    $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

    $sessionStorage.currentTab = null;
    $scope.languageCode = $scope.languageCode ? $scope.languageCode : 'EN';
    $scope.emailBody = '';
    $scope.sendButtonDisable = false;
    $scope.errorMessage = null;
    //Below is the function call to download the euroclear reports
    $scope.downloadEuroclearReport = function () {
      $scope.fileloading = true;
      $scope.downloadReport =
        '/v1/generalmeeting/issuerreports/euroclear/' +
        $scope.meetingId +
        '/' +
        $scope.languageCode +
        '/' +
        $sessionStorage.usertype +
        '/download';
      var a = document.createElement('a');
      document.body.appendChild(a);
      fileDownloadSvc.downloadFile($scope.downloadReport).then(
        function (response) {
          let fileVO = response;
          if (window.navigator.msSaveOrOpenBlob) {
            a.onclick = function () {
              window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
            };
            a.click();
          } else {
            var fileURL = URL.createObjectURL(fileVO.file);
            a.href = fileURL;
            a.download = fileVO.fileName;
            a.click();
          }
          $scope.downloadExcel = '';
          $scope.fileloading = false;
        },
        function (error) {
          $log.debug(error);
          $scope.fileloading = false;
        }
      );
    };
    //Below is the function to get email template to populate on page on load
    generalMeetingService
      .getEuroclearEmailTemplate($scope.meetingId, $scope.languageCode)
      .then(
        function (response) {
          $scope.emailSentDate = response.data.emailSentDate;
          $scope.emainSentStatus = response.data.emailSendFlag;
          $scope.subject = response.data.template.emailSubject;
          $scope.emailBody = response.data.template.emailBody;
          $scope.emailAddress = response.data.toEuroClear;
          $scope.emailAddressCc = response.data.ccToIssuerUser;
          $log.debug(response);
        },
        function (error) {
          $log.debug(error);
        }
      );
    //
    $scope.sendMailToEuroclear = function () {
      $scope.errorMessage = null;
      if ($scope.emailAddressCc && angular.isString($scope.emailAddressCc)) {
        $scope.emailAddressCc = $scope.emailAddressCc.split(',');
      }
      //$scope.emailAddressCc  value should not be empty string
      if (!$scope.emailAddressCc) {
        $scope.emailAddressCc = [];
      }
      $scope.sendEmailData = {
        toEuroClear: $scope.emailAddress,
        ccToIssuerUser: $scope.emailAddressCc,
        emailSubject: $scope.subject,
        emailBody: $filter('htmlDecode')($scope.emailBody).toString(),
        meetingId: $scope.meetingId,
        langCode: $scope.languageCode,
        loginUserId: $sessionStorage.userId,
      };
      $scope.sendButtonDisable = true;
      generalMeetingService
        .sendMailEuroclear($scope.sendEmailData, 'secureToken')
        .then(
          function (response) {
            $scope.successMessage = response.data.message;
            $('#successPopUp').modal('show');
            $timeout(function () {
              $('#successPopUp').modal('hide');
              $scope.successMessage = '';
            }, 3000);
            $log.debug(response);
          },
          function (error) {
            $scope.sendButtonDisable = false;
            if (error.data.message) {
              $scope.errorMessage = error.data.message;
            } else {
              $scope.errorMessage = error.data.errors[0].errorMessage;
            }
            $log.debug(error);
          }
        );
    };
    // Below is the function to navigate back
    $scope.goBack = function () {
      if ($scope.flag) {
        $state.go('actionHome');
      } else {
        $state.go('agentGeneralMeetingHome');
      }
    };
  },
]);
