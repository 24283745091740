/**
 * @ngDoc controller
 * @name DWTNotificationController
 * @description This controller is used for attendance request meeting related functionalities
 * @requires $scope,$stateParams,$sessionStorage for storing/passing information in scope/state/session
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 * @requires DWTNotificationService for REST service calls
 */
angular
  .module('dwtNotificationModule')
  .controller('DWTNotificationController', [
    '$scope',
    '$sessionStorage',
    '$stateParams',
    '$log',
    '$state',
    '$filter',
    'DWTNotificationService',
    'dwtFileDownloadSvc',
    function (
      $scope,
      $sessionStorage,
      $stateParams,
      $log,
      $state,
      $filter,
      DWTNotificationService,
      dwtFileDownloadSvc
    ) {
      $scope.modalHeader = $filter('translate')('notificationModal_header');
      $scope.modalBody = $filter('translate')('notificationModal_body');
      $scope.modalFooter = '';
      $scope.modalLeftButton = $filter('translate')('label_yes');
      $scope.modalRightButton = $filter('translate')(
        'notificationModal_rightButton'
      );
      $scope.dwtEventId = $stateParams.eventId;
      $log.debug('meetingID', $scope.gmMeetingId);
      $scope.notificationShareClass = [];
      $scope.notificationDocumentList = [];
      $scope.languageCode = 'EN';
      $scope.selectedRecipient = '';
      // service load on page load.
      DWTNotificationService.getNotificationData($scope.dwtEventId).then(
        function (response) {
          $scope.Adnl_Inf_Noti_Of_Prt =
            response.data.dwtEventNotification.additionalInformation;
          $scope.selectedRecipient =
            response.data.dwtEventNotification.dwtEvent.shareClassName;
          $scope.emailTemplateIntermediary =
            response.data.dwtEventNotification.emailTemplate.description2;
        },
        function (error) {
          $log.debug(error);
        }
      );

      //Below is the function to download the notification sheet
      $scope.notificationPreview = function (dwtEventId, Adnl_Inf_Noti_Of_Prt) {
        var downloadUrl =
          'v1/dwtevents/' +
          dwtEventId +
          '/notification/preview?msgOne=' +
          Adnl_Inf_Noti_Of_Prt;
        var a = document.createElement('a');
        document.body.appendChild(a);
        dwtFileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            let fileVO = response;
            if (window.navigator.msSaveOrOpenBlob) {
              a.onclick = function () {
                window.navigator.msSaveOrOpenBlob(fileVO.file, fileVO.fileName);
              };
              a.click();
            } else {
              var fileURL = URL.createObjectURL(fileVO.file);
              a.href = fileURL;
              a.download = fileVO.fileName;
              a.click();
            }
            downloadUrl = '';
          },
          function (error) {
            $log.debug(error);
          }
        );
      };

      //Below is the function to update the customized message for future use
      $scope.updateMessageInDB = function () {
        $scope.serviceReqData = {
          updateAdditionalInformation: true,
          additionalInformation: $scope.Adnl_Inf_Noti_Of_Prt,
        };
        DWTNotificationService.dwtNotificationSave(
          $scope.dwtEventId,
          $scope.serviceReqData
        ).then(
          function () {
            $scope.successMsg = $filter('translate')(
              'notification_successMsg1'
            );
          },
          function (error) {
            $log.debug(error);
          }
        );
      };

      //Below is the function to update the customized message for only one instance
      $scope.updateMessageInSheet = function () {
        $scope.serviceReqData = {
          updateAdditionalInformation: false,
          additionalInformation: $scope.Adnl_Inf_Noti_Of_Prt,
        };
        DWTNotificationService.dwtNotificationSave(
          $scope.dwtEventId,
          $scope.serviceReqData
        ).then(
          function () {
            $scope.successMsg = $filter('translate')(
              'notification_successMsg1'
            );
          },
          function (error) {
            $log.debug(error);
          }
        );
      };

      //Below is the function call to send notification
      $scope.notificationGeneralMeeting = function () {
        $scope.errorMessage = '';
        $scope.responseMessage = '';
        $scope.disableSendBtn = true;
        $scope.serviceReqData = {
          additionalInformation: $scope.Adnl_Inf_Noti_Of_Prt,
          updateAdditionalInformation: false,
          dwtEvent: {
            eventId: $scope.dwtEventId,
            loggedInUserId: $sessionStorage.userId,
            workstation: $sessionStorage.usertype,
          },
        };
        DWTNotificationService.dwtNotificationSend(
          $scope.dwtEventId,
          $scope.serviceReqData
        ).then(
          function () {
            $scope.disableSendBtn = false;
            $scope.responseMessage = $filter('translate')(
              'notification_successMsg3'
            );
          },
          function (error) {
            $scope.disableSendBtn = false;
            $scope.errorMsg = true;
            $scope.errorMessage = error.data.errors[0].errorMessage;
            $log.debug(error);
          }
        );
      };
    },
  ]);
