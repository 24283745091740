import getTermsOfUseUrl from '../../../lib/getTermsOfUseUrl';

/**
 * @ngDoc controller
 * @name generalMeetingHomeModule.RightToSpeakController
 * @description This controller is used for right to speak functionality.
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is
 *           being fetched be service
 * @requires $state for state routing
 * @requires userGroupService
 * @requires voteInstructionService
 * @requires attendanceRequestService
 * @requires $filter
 * @requires ModelWindowService
 * @requires $timeout
 * @requires generalMeetingService
 * @requires $state
 */
angular
  .module('generalMeetingHomeModule')
  .controller(
    'RightToSpeakController',
    [
      '$scope',
      '$sessionStorage',
      '$stateParams',
      'voteInstructionService',
      '$log',
      'userGroupService',
      'attendanceRequestService',
      '$filter',
      'ModelWindowService',
      '$timeout',
      'generalMeetingService',
      '$state',
      function($scope, $sessionStorage, $stateParams,
        voteInstructionService, $log, userGroupService,
        attendanceRequestService, $filter,ModelWindowService,$timeout,generalMeetingService,$state) {
        $scope.userType = $sessionStorage.usertype;
        $scope.shareClassName = $stateParams.shareclassName;
        $scope.intermediaryId = $sessionStorage.groupId;
        $scope.shareholderId = $stateParams.shareholderId;
        $scope.issuerName = $stateParams.issuerName;
        $sessionStorage.editRTSattend = null;
        $scope.attendanceId = null;
        $scope.registrationNo = null;
        $scope.editRTS = $stateParams.editRTS;
        if($stateParams.editRTS && $stateParams.type === 'vote'){
          $scope.attendanceId = $stateParams.attendanceRequestId;
          $scope.registrationNo = $stateParams.registrationNo;
        }else if($stateParams.editRTS && $stateParams.type === 'attend'){
          $scope.attendanceId = $stateParams.instructionId;
          $scope.registrationNo = $stateParams.registrationNo;
        }
        $scope.instructionId = $stateParams.instructionId;
        $scope.numberOfSecurities = $stateParams.numberOfSecurities;
        $scope.isin = $stateParams.isin;
        $scope.proxyDetails = [];
        var fromPage = $stateParams.type;
        var defautProxy = {
          'proxyCity': 'Den Haag',
          'proxyCountry': 'NLD',
          'proxyInitial': null,
          'proxyName': 'Elke Vertegenwoordiger Van De VEB',
          'proxyType': 'LGL',
          'proxyTitle': null,
          'proxyId': null,
          'representativeName': null,
          'legalIdentifier': null,
          'proxyProvided': null,
          'createNewProxy': null
        };
        //Below code is added for ForFarmer default proxy chnages
        $scope.isForFarmer = false;
        var farmerProxySelected = false;
        if($scope.issuerName.toLowerCase() === 'forfarmers n.v.'){
          $scope.isForFarmer = true;
        }
        var proxyForFarmers = {
          'proxyCity': 'Lochem',
          'proxyCountry': 'NLD',
          'proxyInitial': null,
          'proxyName': 'Coöperatie FromFarmers UA',
          'proxyType': 'LGL',
          'proxyTitle': null,
          'proxyId': null,
          'representativeName': 'Iedere vertegenwoordiger van de Cöoperatie FromFarmers',
          'legalIdentifier': null,
          'proxyProvided': null,
          'createNewProxy': null
        };
        $scope.setDefaultProxy = function(){
          //Below if condition is added to make different service call for request is from vote and attend
          if(fromPage === 'vote'){
            voteInstructionService.getShareHolderDetailsIntWS(
              $stateParams.shareholderId,
              $stateParams.instructionId,
              $stateParams.userType).then(
              function(response) {
                $scope.viDetails = response.data;
                if($scope.viDetails.proxyProvided === 'Y'){
                  $scope.attendProxyBy = 'proxyEdit';
                }else if ($scope.viDetails.shType === 'LGL') {
                  $scope.attendProxyBy = 'proxySelect';
                  $scope.shTitleRequired = false;
                  $scope.createProxy = false;
                  $scope.selectProxy = true;
                  $scope.proxyProvided = 'Y';
                  $scope.createNewProxy = 'N';
                  $scope.editProxy = 'N';
                  $scope.proxyName = 'Elke Vertegenwoordiger Van De VEB';
                  $scope.proxyCity = 'Den Haag';
                  $scope.isoCountrycode = 'NLD';
                } else {
                  $scope.attendProxyBy = 'none';
                  $scope.createProxy = false;
                  $scope.selectProxy = false;
                  $scope.proxyProvided = 'N';
                  $scope.createNewProxy = 'N';
                  $scope.editProxy = 'N';
                }
              });
          }else{
            attendanceRequestService.getBulkUploadARDetails(
              $stateParams.instructionId, $scope.languageCode,
              $sessionStorage.usertype).query().$promise
              .then(function(data) {
                $scope.viDetails = data.sharePlanHolderVO;
                $scope.isin = data.shareClassISINVO[0].isin;
                if(data.sharePlanHolderVO.shareHolderType === 'Legal Person'){
                  $scope.viDetails.shType = 'LGL';
                }else{
                  $scope.viDetails.shType = 'NAT';
                }
                if($scope.viDetails.proxyProvided === 'Y'){
                  $scope.attendProxyBy = 'proxyEdit';
                }else if ($scope.viDetails.shType === 'LGL') {
                  $scope.attendProxyBy = 'proxySelect';
                  $scope.shTitleRequired = false;
                  $scope.createProxy = false;
                  $scope.selectProxy = true;
                  $scope.proxyProvided = 'Y';
                  $scope.createNewProxy = 'N';
                  $scope.editProxy = 'N';
                  $scope.proxyName = 'Elke Vertegenwoordiger Van De VEB';
                  $scope.proxyCity = 'Den Haag';
                  $scope.isoCountrycode = 'NLD';
                } else {
                  $scope.attendProxyBy = 'none';
                  $scope.createProxy = false;
                  $scope.selectProxy = false;
                  $scope.proxyProvided = 'N';
                  $scope.createNewProxy = 'N';
                  $scope.editProxy = 'N';
                }
              }, function(error) {
                $log.debug(error);
              });
          }
        };
        // Below is the function used to fetch issuer logo
        userGroupService
          .getIssuerGroupLogo($stateParams.issuerId)
          .then(function(response) {
            $scope.issuerLogoPresent = false;
            $scope.issuerGroupDetails = response.data;
            // Do not change the below condition. It is for issuer logo display fix. REST response has ""
            if ($scope.issuerGroupDetails.logoBaseStr) {
              $scope.issuerLogoPresent = true;
            }
          }, function(error) {
            $log.debug(error);
          });
        $scope.loading = false;
        $scope.loadingSpin = false;
        $scope.duplicateRequest = false;
        $scope.eventType = 'Footer Term of Use';
        $scope.languageCode = $sessionStorage.workstationData.selectedLang || 'EN';
        $scope.productTypeToU = 'GM';
        $scope.getARData = function () {
          attendanceRequestService.getAttendanceRequestView(
            $stateParams.meetingId, $scope.languageCode,
            null, $sessionStorage.usertype).query().$promise
            .then(function(data) {
              $scope.attendanceData = data;
              $scope.getAttendanceProxyList();
            });
        };

        $scope.getAttendanceProxyList = function() {
          attendanceRequestService
            .getAvailableProxyList($sessionStorage.groupId)
            .then(
              function(response) {
                //Below changes are added to remove default proxy value for shareholder WS
                if (response.data.length === 0 && ($sessionStorage.usertype === 'IN' || $scope.isForFarmer)) {
                  //Below code is added for ForFarmer default proxy chnages
                  if($scope.isForFarmer){
                    $scope.proxyDetails = $scope.proxyDetails
                      .concat(proxyForFarmers);
                  }
                  if($sessionStorage.usertype === 'IN'){
                    $scope.proxyDetails.push(defautProxy);
                    $scope.selectedProxyList = $filter('filter')($scope.proxyDetails,
                      {
                        proxyName: 'Elke Vertegenwoordiger Van De VEB'
                      }, true);
                  }
                  $scope.selectedCountry = $filter('filter')(
                    $scope.attendanceData.countryCodeVO, {
                      isoCountryCode: 'NLD'
                    }, true);
                } else {
                  $scope.checkForDefualtProxy = $filter(
                    'filter')(response.data,
                    {
                      proxyName: 'Elke Vertegenwoordiger Van De VEB'
                    }, true);
                  $scope.proxyDetails = response.data;
                  //Below code is added for ForFarmer default proxy chnages
                  if($scope.isForFarmer){
                    $scope.checkForFarmerProxy = $filter(
                      'filter')(response.data,
                      {
                        proxyName: 'Coöperatie FromFarmers UA'
                      }, true);
                    if ($scope.checkForFarmerProxy.length === 0 && ($sessionStorage.usertype === 'IN' || $scope.isForFarmer)) {
                      $scope.proxyDetails = $scope.proxyDetails
                        .concat(proxyForFarmers);
                    }
                  }
                  if ($scope.checkForDefualtProxy.length === 0 && $sessionStorage.usertype === 'IN') {
                    $scope.proxyDetails = $scope.proxyDetails
                      .concat(defautProxy);
                  }
                }
                //Below changes are added to remove default proxy value for shareholder WS
                if($sessionStorage.usertype === 'IN' && !$scope.isForFarmer){
                  $scope.selectedProxyList = $filter(
                    'filter')($scope.proxyDetails,
                    {
                      proxyName: 'Elke Vertegenwoordiger Van De VEB'
                    }, true);
                  $scope.selectedProxyCountry = $filter(
                    'filter')($scope.attendanceData.countryCodeVO,
                    {
                      isoCountryCode: 'NLD'
                    }, true);
                  $scope.selectedProxyCity = 'Den Haag';
                  $scope.selectedProxyCode = $filter(
                    'filter')($scope.attendanceData.proxyTypeVO,
                    {
                      proxyTypeCode: 'LGL'
                    }, true);
                }else if($scope.isForFarmer){
                  farmerProxySelected = true;
                  $scope.selectedProxyList = $filter(
                    'filter')($scope.proxyDetails,
                    {
                      proxyName: 'Coöperatie FromFarmers UA'
                    }, true);
                  $scope.selectedProxyCountry = $filter(
                    'filter')($scope.attendanceData.countryCodeVO,
                    {
                      isoCountryCode: 'NLD'
                    }, true);
                  $scope.selectedProxyCity = 'Lochem';
                  $scope.selectedProxyRepresentativeName = 'Iedere vertegenwoordiger van de Cöoperatie FromFarmers';
                  $scope.selectedProxyCode = $filter(
                    'filter')($scope.attendanceData.proxyTypeVO,
                    {
                      proxyTypeCode: 'LGL'
                    }, true);
                }
                $scope.setDefaultProxy();
              }, function(error) {
                $log.debug(error);
              });
        };
        $scope.getARData();

        $scope.onProxyNameChange = function(selectedProxyList) {
          //farmerProxySelected - is added for ForFarmer default proxy chnages
          farmerProxySelected = false;
          if($scope.selectedProxyList[0].proxyName==='Coöperatie FromFarmers UA'){
            farmerProxySelected = true;
          }
          $scope.selectedProxyInitial = selectedProxyList[0].proxyInitial;
          $scope.selectedProxyCountry = $filter('filter')($scope.attendanceData.countryCodeVO,
            {isoCountryCode: selectedProxyList[0].proxyCountry
            }, true);
          $scope.selectedProxyRepresentativeName = selectedProxyList[0].representativeName;
          $scope.selectedProxyLegalIdentifier = selectedProxyList[0].legalIdentifier;
          $scope.selectedProxyCity = selectedProxyList[0].proxyCity;
          $scope.selectedProxyTitle = $filter('filter')(
            $scope.attendanceData.shareHolderTitleVO, {
              title: selectedProxyList[0].proxyTitle
            }, true);
          $scope.selectedProxyCode = $filter('filter')(
            $scope.attendanceData.proxyTypeVO, {
              proxyTypeCode: selectedProxyList[0].proxyType
            }, true);
        };
        $scope.$watch('attendProxyBy', function(value) {
          if (value === 'proxyCreate') {
            $scope.createProxy = true;
            $scope.selectProxy = false;
            $scope.proxyProvided = 'Y';
            $scope.createNewProxy = 'Y';
            $scope.editProxy = 'N';
            $scope.proxyName = null;
            $scope.proxyCity = null;
            $scope.attendProxyBy = 'proxyCreate';
            // To select country by default
            $scope.selectedProxyCountry = $filter('filter')(
              $scope.attendanceData.countryCodeVO, {
                isoCountryCode: 'NLD'
              }, true)[0];
          } else if (value === 'proxySelect') {
            $scope.createProxy = false;
            $scope.selectProxy = true;
            $scope.proxyProvided = 'Y';
            $scope.createNewProxy = 'N';
            $scope.editProxy = 'N';
            $scope.attendProxyBy = 'proxySelect';
            //farmerProxySelected - is added for ForFarmer default proxy chnages
            farmerProxySelected = false;
            //Below changes are added to remove default proxy value for shareholder WS
            if($sessionStorage.usertype === 'IN' && !$scope.isForFarmer){
              $scope.selectedProxyList = $filter('filter')($scope.proxyDetails,
                {proxyName: 'Elke Vertegenwoordiger Van De VEB'
                }, true);
              $scope.onProxyNameChange($scope.selectedProxyList);
            }else if($scope.isForFarmer){
              farmerProxySelected = true;
              $scope.selectedProxyList = $filter('filter')($scope.proxyDetails,
                {proxyName: 'Coöperatie FromFarmers UA'
                }, true);
              $scope.onProxyNameChange($scope.selectedProxyList);
            }else{
              $scope.selectedProxyList = [$scope.proxyDetails[0]];
              $scope.onProxyNameChange($scope.proxyDetails);
            }
          }else if(value === 'proxyEdit'){
            $scope.createProxy = false;
            $scope.selectProxy = false;
            $scope.editProxy = 'Y';
            $scope.createNewProxy = 'N';
            $scope.proxyProvided = 'Y';
            $scope.attendProxyBy = 'proxyEdit';
            $scope.selectedProxyInitial = $scope.viDetails.proxyInitials;
            $scope.proxyName = $scope.viDetails.proxyName;
            $scope.selectedProxyCity = $scope.viDetails.proxyCity;
            $scope.legalIdentifier = $scope.viDetails.legalIdentifier;
            $scope.representativeName = $scope.viDetails.representativeName;
            //To select country by default
            angular.forEach($scope.attendanceData.countryCodeVO,
              function(item4) {
                if (angular.equals($scope.viDetails.proxyCountryCode,
                  item4.isoCountryCode)) {
                  $scope.selectedProxyCountry = item4;
                }
              });
            $scope.selectedProxyTitle = $filter('filter')(
              $scope.attendanceData.shareHolderTitleVO, {
                title: $scope.viDetails.proxyTitle
              }, true)[0];
            $scope.selectedProxyType = $filter(
              'filter')($scope.attendanceData.proxyTypeVO,
              {
                proxyTypeCode: $scope.viDetails.proxyCodeType
              }, true)[0];
            $scope.selectedProxyInitial = $scope.viDetails.proxyInitials;
            $scope.selectedProxy();

          } else {
            $scope.createProxy = false;
            $scope.selectProxy = false;
            $scope.proxyProvided = 'N';
            $scope.createNewProxy = 'N';
            $scope.editProxy = 'N';
          }
        });
        $scope.autoPopulate = function() {
          if ($scope.shareHolderType[0].proxyTypeCode === 'LGL'
                                && ($scope.viDetails.lastName
                                  .toLowerCase() === 'veb' || $scope.viDetails.lastName
                                  .toLowerCase()
                                  .indexOf(
                                    'vereniging van effectenbezitters') > -1)) {
            $scope.profileDetails.address = 'Amaliastraat 7';
            $scope.profileDetails.emailAddress = 'info@veb.net';
            $scope.profileDetails.city = 'Den Haag';
            $scope.selectedCountry = $filter('filter')(
              $scope.attendanceData.countryCodeVO, {
                isoCountryCode: 'NLD'
              }, true);
            $scope.attendProxyBy = 'proxySelect';
          }
        };
        $scope.selectedProxy = function() {
          if ($scope.selectedProxyType.proxyTypeCode === 'LGL') {
            $scope.titleRequired = false;
          } else {
            $scope.titleRequired = true;
          }
        };
        $scope.selectProxyTitle = function(selectedProxyTitle) {
          $scope.selectedProxyTitle = selectedProxyTitle;
        };
                      
        $scope.termsOfUseUrl = getTermsOfUseUrl('GM', $sessionStorage.workstationData.selectedLang);

        $scope.submitRTS = function() {
          $scope.modalWindowUpdate();
          ModelWindowService.showModelWindow('tcModal');
        };
        /**
                       * Below function call is to invoke modal window for confirmation while submitting voting instruction from
                       * intermediary workstation
                       */
        $scope.modalWindowUpdate = function() {
          $scope.modalHeader = $filter('translate')(
            'label_modalConfirmAction');
          $scope.modalFooter = '';
          $scope.modalLeftButton = $filter('translate')(
            'label_confirm');
          $scope.modalRightButton = $filter('translate')(
            'label_cancel');
          $scope.modalBody = '';
          $scope.modalBody8 = $filter('translate')(
            'intermediary_modalcheck1');
          $scope.modalBody1 = $filter('translate')(
            'intermediary_modalcheck2');
          $scope.modalBody2 = $filter('translate')(
            'intermediary_AR_modalcheck3');
          $scope.modalBody3 = $filter('translate')(
            'intermediary_modalcheck6');
          $scope.modalBody4 = $filter('translate')(
            'intermediary_modalcheck4');
          $scope.modalBody5 = $filter('translate')(
            'intermediaryAcceptVoteCheckbox5');
          $scope.modalBody6 = $filter('translate')(
            'label_termsUse');
          $scope.modalBody7 = $filter('translate')(
            'intermediaryAcceptVoteCheckbox6');
        };
        // Below is the function to submit attendance request in different workstations
        $scope.submitAttendanceRequest = function() {
          if ($scope.titleRequired) {
            $scope.proxyTitle = $scope.selectedProxyTitle.title;
          } else {
            $scope.proxyTitle = null;
          }
          if ($scope.shTitleRequired) {
            $scope.shTitle = $scope.viDetails.title;
          } else {
            $scope.shTitle = null;
          }
          if ($scope.viDetails.phoneNumber !=='' && $scope.viDetails.phoneNumber) {
            $scope.phoneNumber = $scope.viDetails.phoneNumber;
          } else {
            $scope.phoneNumber = null;
          }
          $scope.response = null;
          $scope.error = null;
          $scope.errorCode = null;
          $scope.loadingSpin = true;
          //Below changes are added to remove default proxy value for shareholder WS
          if ($scope.selectProxy && ($sessionStorage.usertype === 'IN' || $scope.isForFarmer) && !$scope.selectedProxyList[0].proxyId) {
            //farmerProxySelected - is added for ForFarmer default proxy chnages
            $scope.createNewProxy = 'Y';
            $scope.legalEntityIdentifier = null;
            $scope.isoCountrycode = 'NLD';
            $scope.proxyTypeCode = 'LGL';
            if(farmerProxySelected){
              $scope.proxyCity = 'Lochem';
              $scope.proxyName = 'Coöperatie FromFarmers UA';
              $scope.representativeName = 'Iedere vertegenwoordiger van de Cöoperatie FromFarmers';
            }else{
              $scope.proxyCity = 'Den Haag';
              $scope.proxyName = 'Elke Vertegenwoordiger Van De VEB';
              $scope.representativeName = null;

            }
          }
          if ($scope.createProxy || $scope.editProxy === 'Y') {
            $scope.isoCountrycode = $scope.selectedProxyCountry.isoCountryCode;
            $scope.proxyTypeCode = $scope.selectedProxyType.proxyTypeCode;
          }
          if($scope.proxyTypeCode === 'LGL'){
            $scope.selectedProxyInitial = null;
          }
          $scope.intermediaryId = $sessionStorage.groupId;
          if ($scope.proxyProvided === 'Y'
                                  && $scope.createNewProxy === 'Y') {
            var attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $stateParams.meetingId,
              langCode: $scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $stateParams.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              noOfSecurities: $scope.numberOfSecurities,
              attendanceId: $scope.attendanceId,
              isin: $scope.isin,
              initiatedThruShrhWrkst: 'N',
              filePath: $scope.filePath,
              fileStatus: $scope.fileStatus,
              fileName: $scope.fileName,
              gmDate: $stateParams.gmDate,
              instructionId: $scope.instructionId,
              registrationNo : $scope.registrationNo,
              rightToSpeakForVote: true,
              shareHolderVOList: [{
                isSharePlanUser: 'N',
                proxyProvided: $scope.proxyProvided,
                createNewProxy: $scope.createNewProxy,
                shareholderId: $scope.shareholderId,
                shareclassId: $stateParams.shareclassId,
                shareHolderType: $scope.viDetails.shType,
                address: $scope.viDetails.address,
                city: $scope.viDetails.city,
                country: $scope.viDetails.isoCountryCode,
                emailAddress: $scope.viDetails.emailAddress,
                firstName: $scope.viDetails.firstName,
                initials: $scope.viDetails.initials,
                lastName: $scope.viDetails.lastName,
                contactPerson: $scope.viDetails.contactPerson,
                legalEntityIdentifier: $scope.viDetails.legalEntityIdentifier,
                owneType: $scope.viDetails.owneType,
                phoneNumber: $scope.phoneNumber,
                postalCode: $scope.viDetails.postalCode,
                securityAccountNumber: $scope.viDetails.securityAccountNumber,
                dob: $scope.viDetails.dob,
                title: $scope.viDetails.title,
                isin: $scope.isin,
                proxyCity: $scope.selectedProxyCity,
                proxyCountry: $scope.isoCountrycode,
                proxyInitials: $scope.selectedProxyInitial,
                proxyType: $scope.proxyTypeCode,
                proxyName: $scope.proxyName,
                proxyTitle: $scope.proxyTitle,
                representativeName: $scope.representativeName,
                legalIdentifier: $scope.legalIdentifier
              }]
            };
          } else if ($scope.proxyProvided === 'Y'
                              && $scope.editProxy === 'Y') {
            attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $stateParams.meetingId,
              langCode: $scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $stateParams.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              noOfSecurities: $scope.numberOfSecurities,
              attendanceId: $scope.attendanceId,
              isin: $scope.isin,
              initiatedThruShrhWrkst: 'N',
              filePath: $scope.filePath,
              fileStatus: $scope.fileStatus,
              fileName: $scope.fileName,
              gmDate: $stateParams.gmDate,
              instructionId: $scope.instructionId,
              registrationNo : $scope.registrationNo,
              rightToSpeakForVote: true,
              shareHolderVOList: [{
                isSharePlanUser: 'N',
                proxyProvided: $scope.proxyProvided,
                createNewProxy: $scope.createNewProxy,
                proxyEdit: $scope.editProxy,
                shareholderId: $scope.shareholderId,
                shareclassId: $stateParams.shareclassId,
                shareHolderType: $scope.viDetails.shType,
                address: $scope.viDetails.address,
                city: $scope.viDetails.city,
                country: $scope.viDetails.isoCountryCode,
                emailAddress: $scope.viDetails.emailAddress,
                firstName: $scope.viDetails.firstName,
                initials: $scope.viDetails.initials,
                lastName: $scope.viDetails.lastName,
                owneType: $scope.viDetails.owneType,
                phoneNumber: $scope.phoneNumber,
                postalCode: $scope.viDetails.postalCode,
                securityAccountNumber: $scope.viDetails.securityAccountNumber,
                dob: $scope.viDetails.dob,
                title: $scope.viDetails.title,
                isin: $scope.isin,
                proxyId: $scope.viDetails.proxyId,
                proxyCity: $scope.selectedProxyCity,
                proxyCountry: $scope.isoCountrycode,
                proxyInitials: $scope.selectedProxyInitial,
                proxyType: $scope.proxyTypeCode,
                proxyName: $scope.proxyName,
                proxyTitle: $scope.proxyTitle,
                representativeName: $scope.representativeName,
                legalIdentifier: $scope.legalIdentifier
              }]
            };
          }else if ($scope.proxyProvided === 'Y'
                                  && $scope.createNewProxy === 'N') {
            attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $stateParams.meetingId,
              langCode: $scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $stateParams.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              noOfSecurities: $scope.numberOfSecurities,
              attendanceId: $scope.attendanceId,
              isin: $scope.isin,
              initiatedThruShrhWrkst: 'N',
              filePath: $scope.filePath,
              fileStatus: $scope.fileStatus,
              fileName: $scope.fileName,
              gmDate: $stateParams.gmDate,
              instructionId: $scope.instructionId,
              registrationNo : $scope.registrationNo,
              rightToSpeakForVote: true,
              shareHolderVOList: [{
                isSharePlanUser: 'N',
                proxyProvided: $scope.proxyProvided,
                createNewProxy: $scope.createNewProxy,
                shareholderId: $scope.shareholderId,
                shareclassId: $stateParams.shareclassId,
                shareHolderType: $scope.viDetails.shType,
                address: $scope.viDetails.address,
                city: $scope.viDetails.city,
                country: $scope.viDetails.isoCountryCode,
                emailAddress: $scope.viDetails.emailAddress,
                firstName: $scope.viDetails.firstName,
                initials: $scope.viDetails.initials,
                lastName: $scope.viDetails.lastName,
                owneType: $scope.viDetails.owneType,
                phoneNumber: $scope.phoneNumber,
                postalCode: $scope.viDetails.postalCode,
                securityAccountNumber: $scope.viDetails.securityAccountNumber,
                dob: $scope.viDetails.dob,
                title: $scope.viDetails.title,
                isin: $scope.isin,
                proxyId: $scope.selectedProxyList[0].proxyId,
                proxyCity: $scope.selectedProxyCity,
                proxyCountry: $scope.isoCountrycode,
                proxyInitials: $scope.selectedProxyInitial,
                proxyType: $scope.proxyTypeCode,
                proxyName: $scope.selectedProxyList[0].proxyName,
                proxyTitle: $scope.proxyTitle,
                representativeName: $scope.selectedProxyList[0].representativeName,
                legalIdentifier: $scope.selectedProxyList[0].legalIdentifier
              }]
            };
          } else {
            attendanceRequestData = {
              workStationType: $scope.userType,
              meetingId: $stateParams.meetingId,
              langCode: $scope.languageCode,
              issuerName: $scope.issuerName,
              issuerId: $stateParams.issuerId,
              loginUserId: $sessionStorage.userId,
              intermediaryId: $scope.intermediaryId,
              noOfSecurities: $scope.numberOfSecurities,
              attendanceId: $scope.attendanceId,
              isin: $scope.isin,
              initiatedThruShrhWrkst: 'N',
              filePath: $scope.filePath,
              fileStatus: $scope.fileStatus,
              fileName: $scope.fileName,
              gmDate: $stateParams.gmDate,
              rightToSpeakForVote: true,
              registrationNo : $scope.registrationNo,
              instructionId: $scope.instructionId,
              shareHolderVOList: [{
                isSharePlanUser: 'N',
                proxyProvided: 'N',
                createNewProxy: 'N',
                shareholderId: $scope.shareholderId,
                shareclassId: $stateParams.shareclassId,
                shareHolderType: $scope.viDetails.shType,
                address: $scope.viDetails.address,
                city: $scope.viDetails.city,
                country: $scope.viDetails.isoCountryCode,
                emailAddress: $scope.viDetails.emailAddress,
                firstName: $scope.viDetails.firstName,
                initials: $scope.viDetails.initials,
                lastName: $scope.viDetails.lastName,
                owneType: $scope.viDetails.owneType,
                phoneNumber: $scope.phoneNumber,
                postalCode: $scope.viDetails.postalCode,
                securityAccountNumber: $scope.viDetails.securityAccountNumber,
                dob: $scope.viDetails.dob,
                title: $scope.viDetails.title,
                isin: $scope.isin
              }]
            };
          }
          attendanceRequestService.saveAttendanceRequestIntermediary(attendanceRequestData, 'secureToken')
            .then(function(response) {
              $log.debug(response.data.message);
              //$scope.getGMdata();
              // Below is the service call to fetch all the data
              // related to general meeting on load of the page
              generalMeetingService.getGeneralMeetingView($stateParams.meetingId)
                .query().$promise
                .then(
                  function(data) {
                    $scope.generalMeetingView = data;
                    $scope.loadingSpin = false;
                    if($scope.editRTS){
                      $sessionStorage.editRTSattend = true;
                      document.getElementById('backButton').click();
                    }else{
                      $state.go('attendanceReqMeetingInfo',
                        {
                          type:'attend',
                          gmId:$stateParams.meetingId,
                          issuerId:$stateParams.issuerId,
                          intermediaryDateTimeStamp:$scope.generalMeetingView.gmScheduleVO.intermediaryDeadlineDateMiilis,
                          intermediaryDate:$scope.generalMeetingView.gmScheduleVO.intermediaryDeadlineDate+' '+$scope.generalMeetingView.gmScheduleVO.timezoneStr,
                          intermediaryAccessDate:$scope.generalMeetingView.gmScheduleVO.intermediaryAccessDateMiilis,
                          intermediaryAccessDateStr:$scope.generalMeetingView.gmScheduleVO.intermediaryAccessDate+' '+$scope.generalMeetingView.gmScheduleVO.timezoneStr,
                          issuerName:$scope.generalMeetingView.issuerName,
                          gmDate:$scope.generalMeetingView.gmScheduleVO.generalMeetingDate,
                          langCode:$scope.langCode,
                          gmStatus:$stateParams.status,
                          breadCrumbDetails: [$stateParams.breadCrumbDetails[0]]
                        });
                    }
                  });
            },
            function(error) {
              $scope.loadingSpin = false;
              $scope.error = error.data.errors[0].errorMessage;
              //Below service call is added to send email to agent when the number of shares entered exceeds the total number of securities available
              if(error.data.errors[0].errorCode === 'CBP_ERR_520' && $scope.userType === 'IN'){
                $scope.EmailData = {
                  votingInstType: 'attend',
                  languageCode: 'EN',
                  workStationType: $scope.userType,
                  meetingId: $stateParams.meetingId,
                  shareClassId: $stateParams.shareclassId,
                  intermediaryId: $scope.intermediaryId,
                  issuerId: $stateParams.issuerId,
                  loginUserId:$sessionStorage.userId
                };
                voteInstructionService.sendSharesExceededMail($scope.EmailData,'secureToken').then(
                  function(response) {
                    $log.debug(response);
                  },
                  function(error) {
                    $log.debug(error);
                  });
              }
            });
        };
      }]);
