/**
 * @ngdoc controller
 * @name userMaintenanceDwtModule.EditIssuerDwtController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with edit issuer user details.
 * @requires userService is the service which is being used to interact with
 *           REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $state for state routing
 */

angular
  .module('userMaintenanceDwtModule')
  .controller('EditIssuerDwtController', [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$sessionStorage',
    '$timeout',
    'userService',
    'DwtIssuerUser',
    function (
      $scope,
      $log,
      $state,
      $stateParams,
      $sessionStorage,
      $timeout,
      userService,
      DwtIssuerUser
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.loggedInId = $sessionStorage.userId;
      $scope.groupId = $stateParams.groupId;
      $scope.prodType = $sessionStorage.productType;
      // Below is the service call to interact with REST and get the
      // user details
      userService
        .getViewUser($stateParams.userId, 'IS', $scope.groupId, $scope.prodType)
        .query()
        .$promise.then(
          function (data) {
            $log.debug('success issuer view');
            $scope.editUserDetails = new DwtIssuerUser(
              data,
              $scope.groupId,
              $stateParams.userId,
              $scope.loggedInId,
              null,
              $scope.prodType
            );
          },
          function (error) {
            $log.debug(error);
          }
        );
      $scope.callOnChange = function (accType) {
        if ('D' === accType) {
          $scope.editUserDetails.title = null;
          $scope.editUserDetails.firstName = null;
        }
      };
      $scope.successCallback = function (response) {
        if (200 === response.httpStatusCode) {
          $scope.editUserSuccess = true;
          $scope.editUserErrorMsg = null;
          $scope.editUserSuccessMsg = response.message;
          $('#successMsg').modal('show');
          $timeout(function () {
            $('#successMsg').modal('hide');
            $('.modal-backdrop').remove();
            $state.go('userMaintenanceForDWT.issuerUserListDwt', {
              groupId: $scope.groupId,
            });
          }, 2000);
        } else {
          $scope.editUserError = true;
          $scope.editUserSuccessMsg = null;
          $scope.editUserErrorMsg = response.message;
        }
      };
      $scope.errorCallback = function (error) {
        $scope.editUserError = true;
        $scope.editUserSuccessMsg = null;
        $scope.editUserErrorMsg = error.data.errors[0].errorMessage;
      };
    },
  ]);
