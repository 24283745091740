/**
 * @ngdoc controller
 * @name myAccountsModule.MyAccountController
 * @description This controller is used for my accounts module. This controller
 *              mainly deals with viewing and editing the profile details of
 *              users .This also deals with changing password.
 * @requires MyAccountsService,shareHolderService,userGroupService,$http are the
 *           services which are being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be
 *           enabled/disabled based on need.
 * @requires $filter to translate messages which are declared in locale files
 * @requires $sessionStorage for fetching the logged in user details from
 *           session storage
 * @requires $timeout for error message vanishing after some period of time
 * @requires $uibModal
 * @requires $state
 */
'use strict';

angular
  .module('generalMeetingMainModule')
  .controller('MyAccountController', [
    '$scope',
    '$log',
    '$sessionStorage',
    'MyAccountsService',
    'resolveProfileData',
    'shareHolderService',
    '$http',
    'userGroupService',
    'userService',
    'PaginationSvc',
    '$filter',
    '$timeout',
    '$uibModal',
    '$state',
    'fileDownloadSvc',
    'cbpDownloadBlob',
    function (
      $scope,
      $log,
      $sessionStorage,
      MyAccountsService,
      resolveProfileData,
      shareHolderService,
      $http,
      userGroupService,
      userService,
      PaginationSvc,
      $filter,
      $timeout,
      $uibModal,
      $state,
      fileDownloadSvc,
      cbpDownloadBlob
    ) {
      $scope.emailPattern = appConstant.EMAIL_REGEX_PATTERN;

      $scope.securityAccountValidation = validationRegex.SECURITY_ACCOUNT_NR;
      $scope.workStation = $sessionStorage.usertype;

      $scope.isAdminDWT = $sessionStorage.isAdminDWT;
      $scope.isAdminGM = $sessionStorage.isAdminGM;
      if ('R' === $scope.isAdminDWT) {
        $scope.isAdmin = $scope.isAdminDWT;
      } else {
        $scope.isAdmin = $scope.isAdminGM;
      }
      $scope.languageCode =
        $sessionStorage.workstationData.selectedLang || 'EN';
      $scope.productTypes = $sessionStorage.productTypes;
      $scope.userId = $sessionStorage.userId;
      $scope.productCodeFlag = false;
      $scope.singleGroup = false;
      $scope.gmGroupFlag = false;
      $scope.dwtGroupFlag = false;
      $scope.dwtGroupDummyFlag = false;
      $scope.gmGroupDummyFlag = false;
      $scope.productList = ['GM', 'DWT'];
      $scope.selectedName = $scope.productList[0];
      $scope.dwtgGroupListFlag = false;
      $scope.gmgGroupListFlag = false;
      // Below is the condition to check the workstation and get
      // the profile details.
      if ($scope.workStation !== 'SH' && $scope.workStation !== 'SP') {
        $scope.profileContent = resolveProfileData;
      }
      if (
        $scope.productTypes.length > 0 &&
        $scope.productTypes.indexOf('GM') !== -1 &&
        $scope.productTypes.indexOf('DWT') !== -1
      ) {
        $scope.productCode = 'GM';
        $scope.productCodeFlag = true;
      } else if (
        $scope.productTypes.length > 0 &&
        $scope.productTypes.indexOf('DWT') !== -1
      ) {
        $scope.productCode = 'DWT';
      } else if (
        $scope.productTypes.length > 0 &&
        $scope.productTypes.indexOf('GM') !== -1
      ) {
        $scope.productCode = 'GM';
      }

      if ($scope.productTypes.length === 1) {
        if (
          (name === 'GM' && $scope.productTypes.indexOf('GM') === -1) ||
          (name === 'DWT' && $scope.productTypes.indexOf('DWT') !== -1)
        ) {
          $scope.showGroupDtlsFlag = false;
        } else {
          $scope.showGroupDtlsFlag = true;
        }
      } else {
        $scope.showGroupDtlsFlag = true;
      }

      $scope.issuerGroupDetails = {};
      $scope.editGroup = false;
      $scope.groupDetails = '';
      var model = this;
      var filesList = [];
      $scope.profileData = true;

      // Below function calls when user switch between the tabs.
      $scope.changePasswordPage = function (val) {
        $scope.value = val;
        if (val === false) {
          $scope.modalHeader = $filter('translate')('label_updatePwd');
          $scope.modalBody = $filter('translate')('modal_areYousure_changePwd');
          $scope.modalLeftButton = $filter('translate')('label_confirm');
          $scope.modalRightButton = $filter('translate')('label_cancel');
        } else if (val !== 'IS') {
          $scope.modalHeader = $filter('translate')(
            'label_update_accountDetails'
          );
          $scope.modalBody = $filter('translate')(
            'modal_areYouSure_updateDetails'
          );
          $scope.modalLeftButton = $filter('translate')('label_confirm');
          $scope.modalRightButton = $filter('translate')('label_cancel');
        }
        $scope.editGroup = false;
        $scope.successMessage = '';
        $scope.errorMessage = '';
        $scope.pwrdErrorMessage = '';
        if (val === 'IS') {
          $scope.groupDetails = 'IS';
          $scope.modalHeader = $filter('translate')(
            'label_update_accountDetails'
          );
          $scope.modalBody = $filter('translate')(
            'modal_areYouSure_updateDetails'
          );
          $scope.modalLeftButton = $filter('translate')('label_confirm');
          $scope.modalRightButton = $filter('translate')('label_cancel');
          $scope.getIssuerGroupDetails();
        } else if (val === 'IN') {
          $scope.groupDetails = 'IN';
          $scope.getIntermediaryGroupDetails();
        } else if (val === 'AG') {
          $scope.groupDetails = 'AG';
          $scope.getAgentGroupDetails();
        } else if (val === false) {
          $scope.passwordMandatoryMessage = false;
          $scope.groupDetails = '';
          $scope.profileData = val;
          if (undefined !== $scope.myForm.password) {
            $scope.myForm.password.$setPristine();
          }
          if (undefined !== $scope.myForm.oldPassword) {
            $scope.myForm.oldPassword.$setPristine();
          }
          if (undefined !== $scope.myForm.confirmPassword) {
            $scope.myForm.confirmPassword.$setPristine();
          }
          $scope.oldPassword = null;
          $scope.newPassword = null;
          $scope.confirmPassword = null;
        } else {
          $scope.passwordMandatoryMessage = false;
          $scope.groupDetails = '';
          $scope.profileData = val;
        }
      };
      model.message = '';
      model.user = {
        password: null,
        confirmPassword: null,
      };
      $scope.getIntermediaryData = function (intermediaryId) {
        MyAccountsService.getIntermediaryData(intermediaryId)
          .query()
          .$promise.then(function (data) {
            $scope.SHprofileContent.intermediaryEmail = data.intermediaryEmail;
            $scope.SHprofileContent.intermediaryName = data.intermediaryName;
            $scope.SHprofileContent.intermediaryPhone = data.intermediaryPhone;
            $scope.SHprofileContent.intermediaryDepartment =
              data.intermediaryDepartment;
          });
      };
      /* date picker */
      $scope.inlineOptions = {
        customClass: getDayClass,
      };

      $scope.open1 = function () {
        $scope.popup1.opened = true;
      };

      $scope.setDate = function (year, month, day) {
        $scope.dt = new Date(year, month, day);
      };

      $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
      $scope.format = $scope.formats[0];
      $scope.altInputFormats = ['M!/d!/yyyy'];

      $scope.popup1 = {
        opened: false,
      };
      function disabled(data) {
        var date, mode;
        date = data.date;
        mode = data.mode;
        $log.debug(date);
        $log.debug(mode);
      }
      $scope.dateOptions = {
        dateDisabled: disabled,
        formatYear: 'yy',
        maxDate: new Date(),
        //removed min validation for date picker -SFCBP-1977
        showWeeks: false,
      };

      function getDayClass(data) {
        var date = data.date,
          mode = data.mode;
        if (mode === 'day') {
          var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

          for (var i = 0; i < $scope.events.length; i++) {
            var currentDay = new Date($scope.events[i].date).setHours(
              0,
              0,
              0,
              0
            );
            if (dayToCheck === currentDay) {
              return $scope.events[i].status;
            }
          }
        }
        return '';
      }
      /* date picker end */
      $scope.changePasswordPage(true);
      // Below is the function call to get issuer group details
      $scope.getIssuerGroupDetails = function () {
        userGroupService
          .getViewIssuerGroup($sessionStorage.groupId, $scope.productCode, 'EN')
          .query()
          .$promise.then(
            function (data) {
              $scope.dwtIssuerGroup = data;
              if (
                null !== $scope.dwtIssuerGroup.logoBaseStr &&
                '' !== $scope.dwtIssuerGroup.logoBaseStr
              ) {
                $scope.showLogo = true;
              } else {
                $scope.showLogo = false;
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
        if ($scope.productCodeFlag !== true) {
          userService
            .getUserList($sessionStorage.groupId, 'IS', $scope.productCode)
            .query()
            .$promise.then(
              function (data) {
                $log.debug('success user view');
                if ($scope.productCode === $scope.productList[0]) {
                  $scope.selectedName = $scope.productList[0];
                  $scope.gmgGroupListFlag = true;
                  $scope.gmGroupFlag = false;
                  $scope.dwtGroupFlag = false;
                } else {
                  $scope.selectedName = $scope.productList[1];
                  $scope.dwtgGroupListFlag = true;
                  $scope.gmGroupFlag = false;
                  $scope.dwtGroupFlag = false;
                }
                $scope.singleGroup = true;
                $scope.userData = data;
                $scope.dummyItems = data;
                $scope.pager = {};
                $scope.setPage(1);
                $scope.updateGroup($scope.selectedName);
              },
              function (error) {
                return error;
              }
            );
        } else {
          userService
            .getUserList($sessionStorage.groupId, 'IS', 'GM')
            .query()
            .$promise.then(
              function (data) {
                $log.debug('success user view');
                $scope.gmGroupFlag = true;
                $scope.gmGroupDummyFlag = true;
                $scope.userData = data;
                $scope.dummyItemsGM = data;
                $scope.pager = {};
                $scope.setPage(1);
              },
              function (error) {
                return error;
              }
            );
          userService
            .getUserList($sessionStorage.groupId, 'IS', 'DWT')
            .query()
            .$promise.then(
              function (data) {
                $log.debug('success user view');
                $scope.dwtGroupFlag = true;
                $scope.dwtGroupDummyFlag = true;
                $scope.userData = data;
                $scope.dummyItemsDWT = data;
                $scope.dwtPager = {};
                $scope.setPageDWT(1);
                $scope.updateGroup($scope.selectedName);
              },
              function (error) {
                return error;
              }
            );
        }
      };

      $scope.getIntermediaryGroupDetails = function () {
        userGroupService
          .getViewIntermediaryGroup(
            $sessionStorage.groupId,
            $scope.productCode,
            'EN'
          )
          .query()
          .$promise.then(
            function (data) {
              $scope.userGroupDetails = data;
              if (
                null !== $scope.userGroupDetails.logoBaseStr &&
                '' !== $scope.userGroupDetails.logoBaseStr
              ) {
                $scope.showLogo = true;
              } else {
                $scope.showLogo = false;
              }
            },
            function (error) {
              $log.debug(error);
            }
          );
        if ($scope.productCodeFlag !== true) {
          userService
            .getUserList($sessionStorage.groupId, 'IN', $scope.productCode)
            .query()
            .$promise.then(
              function (data) {
                $log.debug('success user view');
                if ($scope.productCode === $scope.productList[0]) {
                  $scope.selectedName = $scope.productList[0];
                  $scope.gmgGroupListFlag = true;
                  $scope.gmGroupFlag = false;
                  $scope.dwtGroupFlag = false;
                } else {
                  $scope.selectedName = $scope.productList[1];
                  $scope.dwtgGroupListFlag = true;
                  $scope.gmGroupFlag = false;
                  $scope.dwtGroupFlag = false;
                }
                $scope.singleGroup = true;
                $scope.userData = data;
                $scope.dummyItems = data;
                $scope.pager = {};
                $scope.setPage(1);
                $scope.updateGroup($scope.selectedName);
              },
              function (error) {
                return error;
              }
            );
        } else {
          userService
            .getUserList($sessionStorage.groupId, 'IN', 'GM')
            .query()
            .$promise.then(
              function (data) {
                $log.debug('success user view');
                $scope.gmGroupFlag = true;
                $scope.gmGroupDummyFlag = true;
                $scope.userData = data;
                $scope.dummyItemsGM = data;
                $scope.pager = {};
                $scope.setPage(1);
              },
              function (error) {
                return error;
              }
            );
          userService
            .getUserList($sessionStorage.groupId, 'IN', 'DWT')
            .query()
            .$promise.then(
              function (data) {
                $log.debug('success user view');
                $scope.dwtGroupFlag = true;
                $scope.dwtGroupDummyFlag = true;
                $scope.userData = data;
                $scope.dummyItemsDWT = data;
                $scope.dwtPager = {};
                $scope.setPageDWT(1);
                $scope.updateGroup($scope.selectedName);
              },
              function (error) {
                return error;
              }
            );
        }
      };
      $scope.getAgentGroupDetails = function () {
        userGroupService
          .getAgentGroup('1', 'DWT', 'EN')
          .query()
          .$promise.then(
            function (data) {
              $scope.userGroupDetails = data;
            },
            function (error) {
              $log.debug(error);
            }
          );

        if ($scope.productCodeFlag !== true) {
          userService
            .getUserList('1', 'AG', $scope.productCode)
            .query()
            .$promise.then(
              function (data) {
                $scope.userData = data;
                $scope.dummyItems = data;
                $scope.pager = {};
                if ($scope.productCode === $scope.productList[0]) {
                  $scope.selectedName = $scope.productList[0];
                  $scope.gmgGroupListFlag = true;
                  $scope.gmGroupFlag = false;
                  $scope.dwtGroupFlag = false;
                } else {
                  $scope.selectedName = $scope.productList[1];
                  $scope.dwtgGroupListFlag = true;
                  $scope.gmGroupFlag = false;
                  $scope.dwtGroupFlag = false;
                }
                $scope.singleGroup = true;
                $scope.setPage(1);
                $scope.updateGroup($scope.selectedName);
              },
              function (error) {
                $scope.loading = false;
                return error;
              }
            );
        } else {
          userService
            .getUserList('1', 'AG', 'GM')
            .query()
            .$promise.then(
              function (data) {
                $scope.userData = data;
                $scope.dummyItemsGM = data;
                $scope.gmGroupFlag = true;
                $scope.gmGroupDummyFlag = true;
                $scope.pager = {};
                $scope.setPage(1);
              },
              function (error) {
                $scope.loading = false;
                return error;
              }
            );
          userService
            .getUserList('1', 'AG', 'DWT')
            .query()
            .$promise.then(
              function (data) {
                $scope.userData = data;
                $scope.dummyItemsDWT = data;
                $scope.dwtGroupDummyFlag = true;
                $scope.dwtGroupFlag = true;
                $scope.dwtPager = {};
                $scope.setPageDWT(1);
                $scope.updateGroup($scope.selectedName);
              },
              function (error) {
                $scope.loading = false;
                return error;
              }
            );
        }
      };

      $scope.updateGroup = function (name) {
        if ('AG' === $scope.workStation) {
          userGroupService
            .getAgentGroup('1', name, 'EN')
            .query()
            .$promise.then(
              function (data) {
                $scope.userGroupDetails = data;
              },
              function (error) {
                $log.debug(error);
              }
            );
        } else if ('IS' === $scope.workStation) {
          userGroupService
            .getViewIssuerGroup($sessionStorage.groupId, name, 'EN')
            .query()
            .$promise.then(
              function (data) {
                $scope.dwtIssuerGroup = data;
                if (
                  null !== $scope.dwtIssuerGroup.logoBaseStr &&
                  '' !== $scope.dwtIssuerGroup.logoBaseStr
                ) {
                  $scope.showLogo = true;
                } else {
                  $scope.showLogo = false;
                }
              },
              function (error) {
                $log.debug(error);
              }
            );
        } else if ('IN' === $scope.workStation) {
          userGroupService
            .getViewIntermediaryGroup($sessionStorage.groupId, name, 'EN')
            .query()
            .$promise.then(
              function (data) {
                $scope.userGroupDetails = data;
                if (
                  null !== $scope.userGroupDetails.logoBaseStr &&
                  '' !== $scope.userGroupDetails.logoBaseStr
                ) {
                  $scope.showLogo = true;
                } else {
                  $scope.showLogo = false;
                }
              },
              function (error) {
                $log.debug(error);
              }
            );
        }
        if ($scope.productTypes.length === 1) {
          if (
            (name === 'GM' && $scope.productTypes.indexOf('GM') === -1) ||
            (name === 'DWT' && $scope.productTypes.indexOf('DWT') === -1)
          ) {
            $scope.showGroupDtlsFlag = false;
          } else {
            $scope.showGroupDtlsFlag = true;
          }
        } else {
          $scope.showGroupDtlsFlag = true;
        }

        if ($scope.productCodeFlag === true) {
          if (name === 'GM') {
            $scope.gmGroupFlag = true;
            $scope.dwtGroupFlag = false;
          } else {
            $scope.gmGroupFlag = false;
            $scope.dwtGroupFlag = true;
          }
        } else {
          $scope.selectedName = name;
          if ($scope.productTypes[0] === name) {
            $scope.singleGroup = true;
            $scope.gmGroupFlag = false;
            $scope.dwtGroupFlag = false;
          } else {
            if ($scope.dwtgGroupListFlag === true) {
              $scope.singleGroup = false;
              $scope.gmGroupFlag = true;
              $scope.dwtGroupFlag = false;
            }
            if ($scope.gmgGroupListFlag === true) {
              $scope.singleGroup = false;
              $scope.gmGroupFlag = false;
              $scope.dwtGroupFlag = true;
            }
          }
        }
      };
      // Below function is for pagination, this calls from HTML
      // on clicking of each page
      $scope.setPage = function (page) {
        $scope.pager = [];
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }
        $log.debug($scope.pager);
        if ($scope.gmGroupDummyFlag === true) {
          // get pager object from service
          $scope.pager = PaginationSvc.GetPager(
            $scope.dummyItemsGM.length,
            page
          );
          $scope.viewUserListGM = $scope.dummyItemsGM.slice(
            $scope.pager.startIndex,
            $scope.pager.endIndex + 1
          );
        } else {
          $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
          $scope.viewUserList = $scope.dummyItems.slice(
            $scope.pager.startIndex,
            $scope.pager.endIndex + 1
          );
        }
      };
      // Below function is for pagination, this calls from HTML
      // on clicking of each page
      $scope.setPageDWT = function (dwtPage) {
        $scope.currentPageDwt = dwtPage;
        $scope.dwtPager = $scope.dwtPager || [];
        if (dwtPage < 1 || dwtPage > $scope.dwtPager.totalPages) {
          return;
        }
        if ($scope.dwtGroupDummyFlag === true) {
          // get pager object from service
          $scope.dwtPager = PaginationSvc.GetPager(
            $scope.dummyItemsDWT.length,
            dwtPage
          );
          $scope.viewUserListDWT = $scope.dummyItemsDWT.slice(
            $scope.dwtPager.startIndex,
            $scope.dwtPager.endIndex + 1
          );
          $scope.beginIndex = $scope.dwtPager.startIndex;
          $scope.pageNo = dwtPage;
        }
      };
      /* download registration form starts here */
      $scope.downloadRegistrationForm = function () {
        let downloadUrl;
        if ($scope.userGroupDetails.productCode === 'DWT') {
          downloadUrl = '/v1/intermediaries/intermediaryRegistration/download';
        } else {
          downloadUrl = '/v1/intermediaries/agreementforms/download';
        }
        try {
          fileDownloadSvc.downloadFile(downloadUrl).then((response) => {
            cbpDownloadBlob(response.file, response.fileName);
          });
        } catch (err) {
          $log.debug(err);
        }
      };
      // when language changes, following piece of code is executed from header-controller.js
      $scope.$on('onLanguageMyaccounts', function (event, lang) {
        $scope.languageCode = lang;
        $scope.getSHprofileDetails($scope.languageCode);
        $scope.changePasswordPage($scope.value);
      });
      // Below is the condition to check the workstation and function call for first time
      if ($scope.workStation === 'SH' || $scope.workStation === 'SP') {
        $scope.getSHprofileDetails = function () {
          shareHolderService
            .getSHProfileData($scope.languageCode)
            .query()
            .$promise.then(
              function (response) {
                $scope.SHprofileContent = response;
                $scope.selectedIntermediary = {
                  id: $scope.SHprofileContent.intermediaryId,
                };
                if ($scope.SHprofileContent.phoneCountryCode) {
                  $scope.countryDialCode = {
                    dialCode: $scope.SHprofileContent.phoneCountryCode,
                  };
                }
                $scope.selectedType = $filter('filter')(
                  $scope.SHprofileContent.shareHolderTypeVO,
                  {
                    description: $scope.SHprofileContent.type,
                  },
                  true
                );
                $scope.selectedCountry = $filter('filter')(
                  $scope.SHprofileContent.countryList,
                  {
                    isoCountryCode: $scope.SHprofileContent.country,
                  },
                  true
                );
                $scope.selectedTitle = $filter('filter')(
                  $scope.SHprofileContent.titleList,
                  {
                    title: $scope.SHprofileContent.title,
                  },
                  true
                );
                if ($scope.SHprofileContent.gmNotice === 'Y') {
                  $scope.gmNoticeCheckbox = true;
                } else {
                  $scope.gmNoticeCheckbox = false;
                }
              },
              function (error) {
                $log.debug(error);
              }
            );
        };
        $scope.getSHprofileDetails();

        $scope.updateSHProfileData = function (SHProfileData) {
          $scope.errorMessage = null;

          if ($scope.gmNoticeCheckbox === true) {
            $scope.gmNotice = 'Y';
          } else {
            $scope.gmNotice = 'N';
          }

          if (
            !SHProfileData.lastName ||
            !$scope.selectedTitle[0] ||
            !SHProfileData.emailAddress
          ) {
            $scope.errorMessage = $filter('translate')(
              'label_mandatoryFieldError'
            );
            $scope.successMessage = null;
          } else if (
            !$scope.countryDialCode.dialCode &&
            SHProfileData.phoneNumber
          ) {
            $scope.errorMessage = $filter('translate')(
              'label_country_code_error'
            );
            $scope.successMessage = null;
          } else {
            if (!SHProfileData.securityAccNo) {
              SHProfileData.securityAccNo = null;
            }
            if (!SHProfileData.postalCode) {
              SHProfileData.postalCode = null;
            }
            if (!SHProfileData.phoneNumber) {
              SHProfileData.phoneNumber = null;
            }
            if (!SHProfileData.initials) {
              SHProfileData.initials = null;
            }
            if (!SHProfileData.city) {
              SHProfileData.city = null;
            }
            if (!SHProfileData.address) {
              SHProfileData.address = null;
            }
            var intId = null;
            if ($scope.selectedIntermediary) {
              intId = $scope.selectedIntermediary.id;
            }
            var updateShareHolderProfileRequest = {
              shareholderId: $sessionStorage.groupId,
              title: $scope.selectedTitle[0].title,
              initials: SHProfileData.initials,
              name: SHProfileData.name,
              middleName: SHProfileData.middleName,
              lastName: SHProfileData.lastName,
              phoneNumber: SHProfileData.phoneNumber,
              phoneCountryCode: $scope.countryDialCode.dialCode,
              type: $scope.SHprofileContent.type,
              securityAccNo: SHProfileData.securityAccNo,
              emailAddress: SHProfileData.emailAddress,
              address: SHProfileData.address,
              postalCode: SHProfileData.postalCode,
              city: SHProfileData.city,
              country: $scope.selectedCountry[0].isoCountryCode,
              gmNotice: $scope.gmNotice,
              intermediaryId: intId,
            };
            shareHolderService
              .updateSHProfileData(updateShareHolderProfileRequest)
              .then(
                function (response) {
                  $scope.errorMessage = null;
                  $scope.successMessage = response.data.message;
                  $scope.timedout = $uibModal.open({
                    templateUrl: 'success-dialog.html',
                  });
                  $timeout(function () {
                    $scope.timedout.close();
                    $scope.timedout = null;
                    $state.go('shMyVotes', { selectedIndex: 0 });
                  }, 2000);
                },
                function (error) {
                  if (error.data.message) {
                    $scope.errorMessage = error.data.message;
                  } else {
                    $scope.errorMessage = error.data.errors[0].errorMessage;
                  }
                  $log.debug(error);
                }
              );
          }
        };
      }
      // Below is the function call from HTML to navigate to
      // edit issuer group section and populate the data.
      $scope.displayEditGroupDetails = function () {
        $scope.successMessage = '';
        $scope.errorMessage = '';
        userGroupService
          .getCountryList('EN')
          .query()
          .$promise.then(
            function (data) {
              $log.debug('success user view');
              $scope.countryList = data;
              angular.forEach($scope.countryList, function (value, key) {
                var country = data[key].countryName;
                if (
                  country === $scope.selectedCountry ||
                  country === $scope.selectedCountry.country
                ) {
                  $scope.selectedCountry = value;
                }
              });
            },
            function (error) {
              $log.debug(error);
            }
          );
        $scope.editGroup = true;
      };
      // Below is the function call from HTML on change of
      // option in country dropdown.
      $scope.changeCountry = function (changed) {
        $scope.selectedCountry = changed;
      };

      // opt out of tax reclaim emails logic
      if ($sessionStorage.productTypes) {
        $scope.optOutofDwtCheckbox =
          $sessionStorage.productTypes.indexOf('DWT') > -1;
      }
      // Below is the function call from HTML to update the
      // profile details.
      $scope.updateProfileData = function (profileContent) {
        $log.debug(profileContent.title, profileContent.initials);
        if ($scope.profileData && $scope.groupDetails !== 'IS') {
          $scope.updateProfileRequest = {
            userId: $sessionStorage.userId,
            title: profileContent.title,
            initials: profileContent.initials,
            firstName: profileContent.firstName,
            lastName: profileContent.lastName,
            workPhone: profileContent.workPhone,
            emailAddress: profileContent.emailAddress,
            gmEmailEnabled: profileContent.gmEmailEnabled,
            dwtEmailEnabled: profileContent.dwtEmailEnabled,
            userType: $sessionStorage.usertype,
          };
          if (
            profileContent.lastName &&
            profileContent.workPhone &&
            profileContent.emailAddress
          ) {
            MyAccountsService.updateProfileData(
              $sessionStorage.userId,
              $sessionStorage.usertype,
              $scope.updateProfileRequest,
              'secureToken'
            ).then(
              function (response) {
                $log.debug(response);
                $scope.errorMessage = null;
                $scope.successMessage = response.data.message;
                if (!profileContent.firstName) {
                  $sessionStorage.userName = profileContent.lastName;
                } else {
                  $sessionStorage.userName =
                    profileContent.firstName + ' ' + profileContent.lastName;
                }
              },
              function (error) {
                $scope.successMessage = '';
                if (error.data.errors) {
                  $scope.errorMessage = error.data.errors[0].errorMessage;
                } else {
                  $scope.errorMessage = error.data.message;
                }
              }
            );
          } else {
            $scope.errorMessage = $filter('translate')(
              'label_mandatoryFieldError'
            );
            $scope.successMessage = '';
          }
        }
      };
      // Below is the function call from HTML to change the
      // password
      $scope.updatePassword = function (
        oldPassword,
        password,
        confirmPassword
      ) {
        if (oldPassword && password && confirmPassword) {
          $scope.passwordMandatoryMessage = false;
          $scope.passwordError = false;
          if (oldPassword === password) {
            $scope.pwrdErrorMessage = $filter('translate')(
              'newPwd_oldPwdError'
            );
            $scope.successMessage = '';
            $scope.passwordError = true;
          } else if (password === confirmPassword) {
            $scope.updatePasswordRequest = {
              userId: $sessionStorage.userId,
              password: oldPassword,
              newPassword: password,
              confirmPassword: confirmPassword,
            };
            MyAccountsService.updatePassword(
              $sessionStorage.userId,
              $scope.updatePasswordRequest,
              'secureToken'
            ).then(
              function (response) {
                $log.debug(response);
                $scope.successMessage = response.data.message;
                $scope.pwrdErrorMessage = '';
                $scope.passwordError = false;
                $timeout(function () {
                  $scope.successMessage = false;
                }, 10000);
              },
              function (error) {
                $log.debug(error);
                if (error.data.httpStatusCode === 400) {
                  $scope.pwrdErrorMessage = error.data.errors[0].errorMessage;
                } else if (error.data.code === 'CBP_ERR_107') {
                  $scope.pwrdErrorMessage = $filter('translate')(
                    'label_oldPassIncorrect'
                  );
                } else if (error.data.code === 'CBP_ERR_95') {
                  $scope.pwrdErrorMessage = $filter('translate')(
                    'label_existLastPassList'
                  );
                } else if (error.data.code === 'CBP_ERR_225') {
                  $scope.pwrdErrorMessage = $filter('translate')(
                    'label_passLengthIncorrect'
                  );
                } else if (error.data.code === 'CBP_ERR_67') {
                  $scope.pwrdErrorMessage = $filter('translate')(
                    'label_dataNotFound'
                  );
                } else if (error.data.code === 'CBP_ERR_84') {
                  $scope.pwrdErrorMessage = $filter('translate')(
                    'label_passNotMatch'
                  );
                } else {
                  $scope.pwrdErrorMessage = error.data.message;
                }
                $scope.successMessage = '';
                $scope.passwordError = true;
                $timeout(function () {
                  $scope.passwordError = false;
                }, 10000);
              }
            );
          } else {
            $scope.pwrdErrorMessage = $filter('translate')(
              'lable_newReenterError'
            );
            $scope.successMessage = '';
            $scope.passwordError = true;
            $timeout(function () {
              $scope.passwordError = false;
            }, 10000);
          }
        } else {
          $scope.passwordMandatoryMessage = true;
        }
      };
      // Below is the function call from HTML on select of file
      // for logo.
      $scope.onFileSelect = function (e) {
        filesList = e.files;
        $log.debug(filesList[0]);
        var fd = new FormData();
        fd.append('file', filesList[0]);
        $scope.formDataVC = fd;
        $log.debug(fd);
        $log.debug(filesList[0].size);
        $log.debug(filesList[0].fileName);
        $scope.uploadedLogoFile = filesList[0].name;
        $timeout(function () {
          $scope.$apply();
        });
      };
      // Below is the function call from HTML to edit the issuer
      // group details.
      $scope.editGroupDetails = function () {
        if (
          $scope.issuerGroupDetails.groupName &&
          $scope.issuerGroupDetails.groupEmail
        ) {
          $scope.editgroupLoading = true;
          var documentFormData = new FormData();
          documentFormData.append('groupId', $sessionStorage.groupId);
          documentFormData.append(
            'groupName',
            $scope.issuerGroupDetails.groupName
          );
          documentFormData.append(
            'groupEmail',
            $scope.issuerGroupDetails.groupEmail
          );
          documentFormData.append(
            'country',
            $scope.selectedCountry.isoCountryCode
          );
          documentFormData.append('city', $scope.issuerGroupDetails.city);
          documentFormData.append('productCode', 'GM');
          documentFormData.append('status', $scope.issuerGroupDetails.status);
          documentFormData.append('userId', $sessionStorage.userId);
          if (filesList.length > 0) {
            if ('image' !== filesList[0].type.substring(0, 5)) {
              $scope.editgroupLoading = false;
              $scope.successMessage = '';
              $scope.errorMessage = $filter('translate')('label_errorImgFile');
              return;
            } else if (filesList[0].size > 256000) {
              $scope.editgroupLoading = false;
              $scope.successMessage = '';
              $scope.errorMessage = $filter('translate')('label_errorFileSize');
              return;
            } else {
              documentFormData.append('logo', filesList[0]);
            }
          }
          $http
            .post(
              'v1/issuers/' + $sessionStorage.groupId + '/profile',
              documentFormData,
              {
                transformRequest: angular.identity,
                headers: {
                  'Content-Type': undefined,
                },
              }
            )
            .then(
              function (response) {
                $log.debug(response);
                $scope.getIssuerGroupDetails();
                $scope.editgroupLoading = false;
                $scope.successMessage = $filter('translate')(
                  'issuer_groupUpdated_success'
                );
                $scope.editGroup = false;
              },
              function (error) {
                $log.debug('error');
                $scope.editgroupLoading = false;
                $scope.successMessage = '';
                $scope.errorMessage = error.data.errors[0].errorMessage;
              }
            );
        } else {
          $scope.successMessage = '';
        }
      };
      //Below is the function call from HTML on submitting/saving the My Accounts HTML form.
      $scope.submitMyAccount = function () {
        if (!$scope.profileData) {
          $scope.updatePassword(
            $scope.oldPassword,
            $scope.newPassword,
            $scope.confirmPassword
          );
        } else if ($scope.groupDetails === 'IS') {
          $scope.editGroupDetails();
        }
      };
      //Get intermediary List to display in intermediary drop down
      MyAccountsService.getIntermediaries('GM', 'N').then(
        function (response) {
          $scope.IntermediaryList = response.data;
        },
        function (error) {
          $log.debug(error);
        }
      );
    },
  ])
  .directive('compareTo', function () {
    return {
      require: 'ngModel',
      scope: {
        otherModelValue: '=compareTo',
      },
      link: function (scope, element, attributes, ngModel) {
        ngModel.$validators.compareTo = function (modelValue) {
          return modelValue === scope.otherModelValue;
        };

        scope.$watch('otherModelValue', function () {
          ngModel.$validate();
        });
      },
    };
  });
angular.module('generalMeetingMainModule').directive('oldPass', function () {
  return {
    require: 'ngModel',
    scope: {
      otherModelValue: '=oldPass',
    },
    link: function (scope, element, attributes, ngModel) {
      ngModel.$validators.oldPass = function (modelValue) {
        return modelValue !== scope.otherModelValue;
      };

      scope.$watch('otherModelValue', function () {
        ngModel.$validate();
      });
    },
  };
});
