/**
 * @ngdoc controller
 * @name userMaintenanceModule.LinkedIntermediariesController
 * @description This controller is used for user maintenance module. This mainly
 *              deals with viewing the list of linked intermediaries and for delinking.
 * @requires userService is the service which is being used to interact with REST
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $state for state routing
 * @requires PaginationSvc for pagination
 * @requires $filter to translate messages from locale file
 */

angular.module('userMaintenanceModule').controller(
  'LinkedIntermediariesController',
  [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$filter',
    'userGroupService',
    'PaginationSvc',
    function($scope, $log, $state, $stateParams, $filter,
      userGroupService, PaginationSvc) {

      $scope.groupId = $stateParams.groupId;
      $scope.groupName = $stateParams.breadCrumbDetails[0];
      //Below is the function call to get list of linked intermediaries.
      $scope.getDeLinkList = function() {
        userGroupService.getLinkedIntermediaries($scope.groupId)
          .query().$promise.then(function(data) {
            $log.debug('success permissions view');
            $scope.dummyItems = data;
            $scope.pager = {};
            $scope.setPage(1);
            $scope.loading = false;
          }, function(error) {
            $scope.loading = false;
            return error;
          });
      };
      //Below is the function for pagination
      $scope.setPage = function(page) {
        $scope.pager = [];
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }
        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length,page);
        $log.debug($scope.pager);

        // get current page of items
        $scope.linkedIntermediaries = $scope.dummyItems.slice(
          $scope.pager.startIndex, $scope.pager.endIndex + 1);
      };
      //Below is the function call from HTML for modal window
      $scope.delinkProxyIntermediaryModal = function(intermediaryId) {
        $scope.modalHeader = $filter('translate')('confrim_delink');
        $scope.modalBody = $filter('translate')('delink_confirmMsg');
        $scope.modalFooter = '';
        $scope.modalLeftButton = $filter('translate')('label_confirm');
        $scope.modalRightButton = $filter('translate')('label_cancel');
        $scope.intermediaryId = intermediaryId;
      };
      //Below is the function call to delink the Proxy intermediary
      $scope.delinkProxyIntermediary = function(intermediaryId) {
        userGroupService.delinkProxyAndIntermediary(intermediaryId,
          $scope.groupId, 'secureToken', function() {
            $scope.getDeLinkList();
          }, function(errors) {
            $log.debug(errors);
          });
      };
      // call the function for first time
      $scope.getDeLinkList();
    }]);
